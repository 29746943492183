







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import moment from "moment";
import Datepicker from "../components/Datepicker.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import log from "../helpers/log";
import axios from "axios";
import directives from "../helpers/directives";
import Pagination from "../components/Pagination.vue";
import { validateFn } from "../helpers";
import { UserAPIRepo, Filters, Sort, SortField } from "../repos/UserAPIRepo";
import { notifier } from "../models/common";
import ConfirmRemoveAccountModal from "../components/confirmCopyAccountsModal.vue";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import SecurityMultiSelect from "../components/SecurityMultiSelect.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import commissionUpdate from "../components/CommissionEditAndDeleteModal.vue";
import editcommission from "../components/EditCommissionToolTip.vue";
import remainingCommissions from "../components/remainingComissionModel.vue";
import AccountComp from "../components/editAccountCommnission.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import ConfirmRemoveAllComm from "../components/ConfirmRemoveItemModal.vue";
import { ErrorBag } from "vee-validate";
import PhoneInput from "../components/PhoneInput.vue";
import DropdownControl from "../components/DropdownControl.vue";
import USState from "../static/USstates.json";
import PageTitle from "../components/pageTitle.vue";
import {
  colsResizeable,
  colsResizeableReset,
  getDateRangeFilter,
  getEUAddress
} from "@/helpers/ApiHelper";
import { OrderStatus } from "@/repos/OrderAPIRepo";
import draggable from "vuedraggable";
import InfoTooltip from "@/components/InfoTooltip.vue";
import SecurityGroupRowTooltip from "@/components/SecurityGroupRowTooltip.vue";
import CustomerSearchTooltip from "@/components/CustomerSearchTooltip.vue";
import FilterCurrencyBox from "@/components/FilterCurrencyBox.vue";
import { ApiHelper } from "@/helpers/all";
import HardwareDetails from "@/components/Hardware/HardwareDetails.vue";
import HardwareStatusComponent, {
  HardwareStatusValue
} from "@/components/HardwareStatus.vue";

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

const userRepo = new UserAPIRepo();
@Component({
  inheritAttrs: false,
  components: {
    FilterCurrencyBox,
    CustomerSearchTooltip,
    SecurityGroupRowTooltip,
    InfoTooltip,
    draggable,
    ConfirmRemoveAccountModal,
    AccountMultiselect,
    SecurityMultiSelect,
    EmployeeMultiselect,
    commissionUpdate,
    LaddaButton,
    Pagination,
    editcommission,
    remainingCommissions,
    AccountComp,
    ConfirmRemoveItemModal,
    ConfirmRemoveAllComm,
    PhoneInput,
    DropdownControl,
    PageTitle,
    HardwareDetails,
    Datepicker,
    HardwareStatusComponent
  },
  directives,
  methods: {
    getEUAddress
  }
})
export default class HardwareUserProfile extends TSXComponent<void> {
  showAddressFields: boolean = false;
  lastLoginOverYear: boolean = false;
  pageNumber = 1;
  editableRows = false;
  warningMessage = "";
  inputField = "";
  categories: any = {};
  dateError = false;
  isportal = 0;
  hideCommission = 0;
  portalCustomer = "";
  saving: any = false;
  selectedUserIds: (number | undefined)[] = [];
  selectedGroupIds: (number | undefined)[] = [];
  selectedName = [];
  editCommissionAccId = 0;
  commissionInput = 0;
  remainingCommissionVisible = false;
  balanceCommissions = [];
  editCommissionShowIndex = -1;
  isActive = "userDetailTab";
  confirmRemoveModalVisible = false;
  confirmCommissionRemoveModalVisible = false;
  editAccountModelVisible = false;
  isConfirmChecked = true;
  buttonVisible = true;
  selectedID = [];
  linkpaymentIds = "";
  currentRole = sessionStorage.getItem("userRole");
  updateCommisionModal = 0;
  userCommission = [];
  userCommissionPaging = { currentPage: 1, totalPages: 0 };
  commissionUpdateInfo: {
    accID: number;
    userId: number;
    action: string;
    accName: string;
    percentage: number;
    businessLineIdLists: any;
  } = {
    accID: 0,
    userId: 0,
    action: "update",
    accName: "",
    percentage: 0,
    businessLineIdLists: -1
  };
  userlist: {
    accID?: number;
    USERID?: number;
    disabled?: boolean;
    UFULLNAME?: string;
    class?: string;
  }[] = [];
  accList: {
    AID?: number;
    USERID?: number;
    disabled?: boolean;
    ANAME?: string;
    class?: string;
  }[] = [];
  secList: {
    AID?: number;
    USERID?: number;
    disabled?: boolean;
    SECURITYGROUPNAME?: string;
    SECURITYGROUPID?: number;
    class?: string;
  }[] = [];
  filters: any = {
    status: [1, 2],
    statusNames: ["Active", "Retired"],
    lifeCycles: [],
    lifeCycleNames: [],
    searchAll: "",
    searchId: "",
    searchName: ""
  };
  searchFilters: any = {
    status: [1, 2],
    statusNames: ["Active", "Retired"],
    lifeCycles: [],
    lifeCycleNames: [],
    searchAll: "",
    searchId: "",
    searchName: ""
  };
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Percent]: 1
    }
  };
  details: any = {
    TOTALPAGES: 0,
    UserSelectedTotal: 0,
    UserSelectedList: this.userlist,
    USERLIST: this.userlist
  };
  options = this.accList;
  items: any = {
    ACCOUNTLIST: this.accList,
    ALLACCOUNTS: this.accList,
    NewPassword: "",
    UFNAME: "",
    ULNAME: "",
    ACCOUNTTOTAL: 0,
    USERID: 0,
    USERSTATE: "",
    CITY: "",
    ZIPCODE: "",
    STATE: "",
    ADDRESS1: "",
    ADDRESS2: "",
    UPHONEEXT: ""
  };
  logs: any = {
    LOGDATE: "",
    RECORDCOUNT: 0,
    CITY: "",
    STATE: ""
  };
  products: any = {
    HDESCRIPTION: "",
    VARID: "",
    USERID: "",
    CATEGORYID: "",
    PRICE: "",
    ASSETTAG: "",
    TRACKINGNUMBER: "",
    PURCHASEID: "",
    UPDATEDON: "",
    POLIID: "",
    WARRENTYEXP: "",
    HARDWARESKU: "",
    HARDWAREID: "",
    CATEGORY: "",
    HDATETIMECREATION: "",
    ASSETNUMBER: "",
    CARRIER: "",
    HARDWAREIMAGES: "",
    HSTATUS: ""
  };
  primary = 0;
  userName = "";
  loading = false;
  ALLSECURITYGROUPS = this.secList;
  selectedGroups: {
    USERID?: number;
    SECURITYGROUPNAME?: string;
    SECURITYGROUPID?: number;
  }[] = [];
  submitHide = false;
  EXCEEDED = false;
  isEmailExists = false;
  confirmRemoveAllComm = false;
  fromPortals = 0;
  stateList: any = [];
  selectedState: string[] = [];
  businessLineId = 0;
  locationLoading: boolean = false;
  $refs!: {
    drodown: DropdownControl;
    confirmRemoveAllComm: ConfirmRemoveAllComm;
    updateCommForAllAccts: remainingCommissions;
  };
  hasChangedStatusFilters: boolean = false;
  editedItem: number = -1;
  hardwareLifecycles: any = [];
  hasChangedLifeCycleFilters: boolean = false;

  mounted() {
    if (window.location.href.indexOf("?tab=") > -1) {
      var activeTab = window.location.href.split("?tab=");
      this.isActive = activeTab[1];
    }
    if (window.location.href.indexOf("?portals=") > -1) {
      this.fromPortals = 1;
    }
  }
  @Watch("balanceCommissions", { deep: true })
  commissionConfirm(newV, old) {
    if (!newV.length) {
      this.remainingCommissionVisible = false;
      this.confirmCommissionRemoveModalVisible = false;
    }
  }
  async created() {
    if (
      typeof this.$route.query.portalUser === "string" &&
      typeof this.$route.query.cutsomer === "string"
    ) {
      this.isportal = parseInt(this.$route.query.portalUser);
      this.portalCustomer = this.$route.query.cutsomer;
    }
    for (const i in USState) {
      this.stateList.push({
        ID: USState[i].Abbr,
        TEXT: USState[i].Abbr,
        data: USState[i]
      });
    }
    await this.fetchData();
  }

  async fetchData() {
    this.hasChangedStatusFilters = false;
    this.hasChangedLifeCycleFilters = false;
    const objId =
      this.$route.params.id && parseInt(this.$route.params.id) > 0
        ? this.$route.params.id
        : sessionStorage.getItem("userId");
    const query = this.$route.query;
    this.loading = true;
    this.hasChangedStatusFilters = false;
    const dataObj = {
      controller: "VARHardwareUsers",
      FunctionName: "Edit",
      subsystem: "VAR360",
      AccountID: this.filters.searchId,
      aName: this.filters.searchName,
      userId: objId,
      aId: query.aId || 0,
      purchaseId: query.purchaseId,
      euName: query.euName || "",
      euFullAddress: query.euFullAddress || "",
      lifeCycles: this.filters.lifeCycles.length ? this.filters.lifeCycles.join(",") : "-1",
      selectedStatus: this.filters.status && this.filters.status.length ? this.filters.status.join(",") : "-1",
      localTime: moment()
    };

    if (this.sort.field) {
      dataObj["order"] = this.sort.field;
      dataObj["direction"] = this.sort.direction[this.sort.field].toString();
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    try {

      if (response.data.STATUS == 1) {
        this.loading = false;
        const categories = response.data.CATEGORIES || [];
        if (categories.length) {
          categories.push({ ID: 0, TEXT: "Unknown" });
          this.categories = categories;
        }
        this.selectedState = [response.data.USERDETAIL[0].STATE];
        this.items = response.data.USERDETAIL[0];
        this.logs = response.data.USERDETAIL;
        this.userName = this.items.EUNAME;
        this.items.USERSTATE = this.items.STATE;
        this.products = response.data.USERPRODUCTS;
        this.primary = response.data.PRIMARYUNITS;
        this.hardwareLifecycles = response.data.HARDWARELIFECYCLES || [];
        if (this.filters.lifeCycles.length == 0 && this.filters.lifeCycleNames.length == 0) {
          this.filters.lifeCycles = response.data.HARDWARELIFECYCLES.map(item => item.LIFECYCLEID);
          this.filters.lifeCycleNames = response.data.HARDWARELIFECYCLES.map(item => item.LIFECYCLENAME);
        }

        const responseData = response.data || {};

        // append custom fields to custom columns list
        // this.listCustomFields = responseData.euFields || [];
        this.listCustomFields = responseData.listCustomFields || [];
        for (const item of this.listCustomFields) {
          const field = `customData${item.CUSTOMFIELDID}`;
          let existedName = false;
          for (const header of this.pageHeaders) {
            if (
              header.key.indexOf("customData") != -1 &&
              header.value.toLowerCase() == item.CUSTOMFIELDNAME.toLowerCase()
            ) {
              existedName = true;
              break;
            }
          }
          if (!existedName) {
            if (
              (
                this.pageHeaders.find(header => header.key == field) || {
                  key: "",
                  value: ""
                }
              ).value == ""
            ) {
              this.pageHeaders.push({
                key: field,
                value: item.CUSTOMFIELDNAME
              });
            }
            if (!this.headerOrder.includes(field)) {
              this.headerOrder.push(field);
            }
          }
        }

        // selected hardware headers
        const savedHeaders = responseData.userHardwareEndUserHeaders || [];
        if (savedHeaders.length) {
          this.selectedheaders = [];
          for (const item of savedHeaders) {
            const inList = this.pageHeaders.find(
              t => t.key.toLowerCase() == item.toLowerCase()
            );
            if (inList) {
              // if found in main columns list
              this.selectedheaders.push(inList.key);
            }
          }
          if (!this.selectedheaders.length) {
            this.setDefaultSelectedHeaders();
          }
        } else {
          this.setDefaultSelectedHeaders();
        }

        // if sorted header
        const savedSorting = responseData.userHardwareEndUserColumnOrder || [];
        if (savedSorting.length) {
          let newSorting: any = [];
          for (const header of savedSorting) {
            const inList = this.pageHeaders.find(item => item.key == header);
            if (inList) {
              newSorting.push(inList);
            }
          }
          const newItems = this.pageHeaders.filter(
            item => !savedSorting.includes(item.key)
          );
          newSorting = [...newSorting, ...newItems];
          this.pageHeaders = newSorting;

          // need to sort selectedHeaders
          this.sortSelectedHeader();
        }
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      } else if (response.data.MESSAGE) {
        notifier.alert(response.data.MESSAGE);
      }
    } catch (err) {
      // handle error
      console.log(err);
    }
  }
  listPageRedirection() {
    if (this.fromPortals) {
      this.$router.push({
        name: "UpdatePortal",
        params: { id: this.items.ACCOUNTID }
      });
    } else if (this.isportal) {
      this.$router.push({
        name: "ViewCustomer",
        params: { id: this.portalCustomer },
        query: { tab: "advancedDet" }
      });
    } else {
      // this.$router.push({ name: "Users" });
      //To go previous page
      window.history.back();
    }
  }
  listPageCustomerRedirection() {
    // this.$router.push({ name: "customerList" });
    //To go previous page
    window.history.back();
  }
  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (
      this.pageNumber <= this.userCommissionPaging.totalPages &&
      this.pageNumber >= 1
    ) {
      await this.loadCommission();
    }

    if (this.pageNumber == 0) {
      this.pageNumber = 1;
    }
    if (this.pageNumber > this.userCommissionPaging.totalPages) {
      this.pageNumber = this.userCommissionPaging.totalPages;
    }
  }
  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.loadCommission();
  }

  async sortingById() {
    this.sortBy(SortField.Id);
  }

  async sortingByName() {
    this.sortBy(SortField.Name);
  }

  async sortingByPercent() {
    this.sortBy(SortField.Percent);
  }
  showButton() {
    // if($('#confirmPassword').val() == '' && $('#upassword').val() == '') {
    //   this.buttonVisible = true;
    // }
    // else {
    // this.buttonVisible = false;
    var pwd = $("#confirmPassword").val();
    var repwd = $("#upassword").val();

    if ($.trim(pwd).length > 0) {
      if ($.trim(pwd) == $.trim(repwd)) {
        $(".validationMatch")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationMatch").hasClass("fail")) {
          $(".validationMatch")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/[A-Z]/)) {
        $(".validationUppercase")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationUppercase").hasClass("fail")) {
          $(".validationUppercase")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).length >= 8) {
        $(".validationLength")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationLength").hasClass("fail")) {
          $(".validationLength")
            .addClass("fail")
            .removeClass("success");
        }
      }

      if ($.trim(pwd).match(/\d/)) {
        $(".validationNumber")
          .removeClass("fail")
          .addClass("success");
      } else {
        if (!$(".validationNumber").hasClass("fail")) {
          $(".validationNumber")
            .addClass("fail")
            .removeClass("success");
        }
      }
    } else {
      $(".passResetRequirements .validation")
        .removeClass("success")
        .addClass("fail");
    }
    // }
  }
  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchId == "") {
      return;
    } else {
      await this.loadCommission();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.searchName == "") {
      return;
    } else {
      await this.loadCommission();
    }
  }

  async resetSearchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = "";
    await this.loadCommission();
  }

  async resetSearchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = "";
    await this.loadCommission();
  }

  // methods: {
  // 	validateFn,
  validateBeforeSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isEmailExists = false;
    this.$validator.validateAll().then(result => {
      // $(".errorMsg").html("");
      //make sure an account is selected
      // if(this.aID == "" && this.customer != "") {
      //     $("#customer").parent().find(".errorMsg").html("Please select a customer from suggested list.");
      //     result = false;
      // }

      //validate for password
      var errorTab = 0;
      if (this.isActive === "pwdResetTab") {
        if (
          $(".passResetRequirements .validation.validationLength").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationUppercase").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationNumber").hasClass(
            "fail"
          ) ||
          $(".passResetRequirements .validation.validationMatch").hasClass(
            "fail"
          )
        ) {
          result = false;
          errorTab = 2;
        } else {
          result = true;
        }
      }
      // if (
      //   $("#confirmPassword").val() != "" &&
      //   $("#upassword").val() != "" &&
      //   $("#confirmPassword").val() != $("#upassword").val()
      // ) {
      //   result = false;
      //   $("#pwdError").show();
      //   $("#pwdError").html("Passwords should match");
      //   // console.log('err',$("#pwdError").html())
      //   errorTab = 2;
      // }
      // if ($("#confirmPassword").val() != "" && $("#upassword").val() == "") {
      //   result = false;
      //   $(".errorMsg.upassword").html("Please enter the confirm new password.");
      //   errorTab = 2;
      // } else {
      //   $(".errorMsg.upassword").html("");
      // }
      // if ($("#confirmPassword").val() == "" && $("#upassword").val() != "") {
      //   result = false;
      //   $(".errorMsg.confirmPassword").html("Please enter the confirm password.");
      //   errorTab = 2;
      // } else {
      //   $(".errorMsg.confirmPassword").html("");
      // }
      if (
        !$("#uemail")
          .val()
          .match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}")
      ) {
        result = false;
        errorTab = 1;
      }
      if ($("#uaddress").val() == "") {
        $(".errorMsg.address").html("Please enter a address");
        result = false;
        errorTab = 1;
      }

      // if (
      //   !$("#uphone")
      //     .val()
      //     .match(
      //       "^[\\(\\)\\.\\- ]{0,}[0-9]{3}[\\(\\)\\.\\- ]{0,}[0-9]{3}[\\(\\)\\.\\- ]{0,}[0-9]{4}[\\(\\)\\.\\- ]{0,}$"
      //     )
      // ) {
      //   $(".errorMsg.phone").html("Please enter a valid phone number");
      //   result = false;
      //   errorTab = 1;
      // }
      // if ($("#ustate").val() != "") {
      //   if (
      //     !$("#ustate")
      //       .val()
      //       .match("^[a-zA-Z ]*$")
      //   ) {
      //     $(".errorMsg.state").html("Please Enter Valid text format");
      //     result = false;
      //     errorTab = 1;
      //   }
      // }
      if ($("#ucity").val() != "") {
        if (
          !$("#ucity")
            .val()
            .match("^[a-zA-Z ]*$")
        ) {
          $(".errorMsg.city").html("Please Enter Valid text format");
          result = false;
          errorTab = 1;
        }
      }
      if (
        !$("#uzip")
          .val()
          .match("^[0-9]{5}$")
      ) {
        $(".errorMsg.zip").html("Please enter a valid Zip code");
        result = false;
        errorTab = 1;
      }
      //validate data input
      if (
        $("#ufname").val() == "" ||
        $("#ulname").val() == "" ||
        $("#ucity").val() == "" ||
        // $("#ustate").val() == "" ||
        $("#uzip").val() == ""
      ) {
        $("#errorMsgLineQuote").html(
          "Please make sure all your personl information is filled out!"
        );
        result = false;
        errorTab = 1;
      }
      if (this.isEmailExists) {
        result = false;
        errorTab = 1;
      }
      var htmlCount = 0;
      if ($("#ufname").val() != "") {
        if (htmlCheck($("#ufname").val())) {
          this.items.UFNAME = htmlParse($("#ufname").val());
          htmlCount++;
        }
      }
      if ($("#ulname").val() != "") {
        if (htmlCheck($("#ulname").val())) {
          this.items.ULNAME = htmlParse($("#ulname").val());
          htmlCount++;
        }
      }
      if ($("#uaddress").val() != "") {
        if (htmlCheck($("#uaddress").val())) {
          this.items.UADDRESS = htmlParse($("#uaddress").val());
          htmlCount++;
        }
      }
      if (htmlCount != 0) {
        notifier.alert("HTML content found. This content has been removed!");
        result = false;
      }
      if (result) {
        //return this.saveFn(this.items);
        if (this.isActive != "userCommTab") {
          this.saveUser(this.items);
        }
      } else {
        if (this.isActive == "pwdResetTab" && errorTab == 2) {
          notifier.alert("Error: Password Criteria not met");
        }
        if (
          this.$validator.errors.first("ufname") ||
          this.$validator.errors.first("zip") ||
          this.$validator.errors.first("ulname") ||
          this.$validator.errors.first("uemail") ||
          this.$validator.errors.first("uaddress") ||
          this.$validator.errors.first("city") ||
          this.$validator.errors.first("state") ||
          this.$validator.errors.first("phone")
        ) {
          errorTab = 1;
        }
        if (errorTab) {
          var tabErr = ["userDetailTab", "pwdResetTab"];
          this.toggleActive(tabErr[errorTab - 1]);
        }
      }
    });
  }
  updateAccountList(type, index, aId = 0) {
    if (type == "delete") {
      this.$delete(this.selectedUserIds, index);
      this.$delete(this.items.ACCOUNTLIST, index);
      this.items.ACCOUNTTOTAL = this.selectedUserIds.length;

      const inList = this.items.ALLACCOUNTS.find(item => item.AID == aId);
      if (inList) {
        inList.selected = false;
      }
    }
  }
  // updateUserList(type, index, aid) {

  //   if (type == "delete") {
  //     this.$delete(this.details.UserSelectedList, index);
  //     this.details.UserSelectedTotal = this.details.UserSelectedList.length;
  //     $(".addUser option[val=" + aid + "]").attr("disabled", false);
  //     $(".addUser option[val=" + aid + "]").attr("class", "removelight");
  //     this.selectedName.pop(
  //       $(".addUser option:selected span").attr("data-name")
  //     );
  //   }
  // }
  updateSGList(type, index, id) {
    if (type == "delete" && this.selectedGroups.length > 1) {
      this.$delete(this.selectedGroups, index);
      this.$delete(this.selectedGroupIds, index);
      $(".addSG option[val=" + id + "]").attr("disabled", false);
      $(".addSG option[val=" + id + "]").attr("class", "removelight");
    } else if (type == "delete" && this.selectedGroups.length == 1) {
      this.selectedGroups[0].SECURITYGROUPID = this.details.BasicUserSG.SECURITYGROUPID;
      this.selectedGroups[0].SECURITYGROUPNAME = this.details.BasicUserSG.SECURITYGROUPNAME;
      this.selectedGroupIds[0] = this.details.BasicUserSG.SECURITYGROUPID;

      $(".addSG option").attr("disabled", false);
      $(".addSG option").attr("class", "removelight");
    }
    this.$forceUpdate();
  }
  allAccounts(e) {
    if (this.options.length) {
      e.preventDefault();
      this.options = this.items.ALLACCOUNTS;
      $(".js-example-tags.form-control").click();
    }
  }
  onChange() {
    var included = 0;
    const acctList = this.items.ACCOUNTLIST;
    for (var i = 0; i < acctList.length; i++) {
      if (
        acctList[i].AID == $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.items.ACCOUNTLIST.push({
        USERID: $(".addAccount option:selected span").attr("data-user"),
        ANAME: $(".addAccount option:selected span").attr("data-name"),
        AID: $(".addAccount option:selected span").attr("data-id")
      });
      this.items.ACCOUNTTOTAL = this.items.ACCOUNTLIST.length;
    }

    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected');
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").attr("class", "highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }
  showConfirmRemoveItemModal() {
    this.confirmRemoveModalVisible = true;
  }
  showUserList() {
    $(".copyButton").attr("disabled", true);
    $(".addUser").val("default");
    $(".copyUser").removeClass("displayNone");
    $("#profile-account-list, #profile-sg-list").addClass("displayNone");
    this.submitHide = true;
  }
  showSecurityGroup() {
    this.loading = false;
    var userId = this.$route.params.id;
    this.$router.push({
      name: "SecurityGroupAdd",
      params: { userId: userId, msg: "success" }
    });
  }
  hideUserList() {
    $(".copyUser").addClass("displayNone");
    $("#profile-account-list, #profile-sg-list").removeClass("displayNone");
    this.submitHide = false;
  }
  onChangeUser() {
    var included = 0;
    const userList = this.details.UserSelectedList;
    $(".copyButton").attr("disabled", false);
    for (var i = 0; i < userList.length; i++) {
      if (
        userList[i].USERID == $(".addUser option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.details.UserSelectedList.push({
        USERID: $(".addUser option:selected span").attr("data-user"),
        UFULLNAME: $(".addUser option:selected span").attr("data-name")
      });
      this.selectedName = $(".addUser option:selected span").attr("data-name");

      this.details.UserSelectedTotal = this.details.UserSelectedList.length;
    }
  }
  //when click on security groups dropdown
  onChangeSGList() {
    var included = 0;
    const selectedList = this.selectedGroups;
    for (var i = 0; i < selectedList.length; i++) {
      if (
        selectedList[i].SECURITYGROUPID ==
        $(".addSG option:selected span").attr("data-id")
      ) {
        included = 1;
        break;
      }
    }

    if (included != 1) {
      this.selectedGroups.push({
        SECURITYGROUPNAME: $(".addSG option:selected span").attr("data-name"),
        SECURITYGROUPID: $(".addSG option:selected span").attr("data-id")
      });
    }

    const numb = $(".addSG option:selected span").attr("data-id");
    $(".addSG option[val=" + numb + "]").attr("disabled", true);
    $(".addSG option[val=" + numb + "]").attr("class", "highlight");
    $(".addSG option:first-child").prop("selected", true);
    this.$forceUpdate();
  }
  copyFunctions() {
    var comInstance = this;
    comInstance.saving = true;
    var userList: (number | undefined)[] = [];
    $.each(comInstance.details.UserSelectedList, function(i, val) {
      userList.push(val.USERID);
    });
    getRouteData({
      controller: "VARUsers",
      FunctionName: "Update",
      subsystem: "VAR360",
      userid:
        comInstance.$route.params.id &&
        parseInt(comInstance.$route.params.id) > 0
          ? comInstance.$route.params.id
          : sessionStorage.getItem("userId"),
      copy: "yes",
      userList: userList.join(",")
    }).then(function(response, statusText, jqXHR) {
      if (response.data.COPYSTATUS == 1) {
        comInstance.items.ACCOUNTLIST = response.data.ACCOUNTLIST;
        comInstance.items.ACCOUNTTOTAL = response.data.ACCOUNTLIST.length;
        comInstance.selectedUserIds = response.data.ACCOUNTID;
        comInstance.saving = false;
        comInstance.confirmRemoveModalVisible = false;
        $(".copyUser").addClass("displayNone");
        comInstance.details.UserSelectedList = response.data.USERSELECTEDLIST;
        comInstance.selectedName = [];
        comInstance.hideUserList();
      } else {
        comInstance.saving = "error";
      }
    });
  }
  async saveUser(data) {
    this.saving = true;
    var comInstance = this;
    await getRouteData({
      controller: "VARUsers",
      FunctionName: "CheckEmailExists",
      subsystem: "VAR360",
      uemail: data.UEMAIL,
      domain: data.DOMAIN,
      userid: data.USERID
    }).then(function(response, statusText, jqXHR) {
      if (!response.data.RECORDCOUNT) {
        //detect selected accounts
        var accountsList: (number | undefined)[] = [];
        $.each(comInstance.items.ACCOUNTLIST, function(i, val) {
          accountsList.push(val.AID);
        });
        accountsList = comInstance.selectedUserIds;

        //detect selected security groups
        var sgList: (number | undefined)[] = [];
        $.each(comInstance.selectedGroups, function(i, val) {
          sgList.push(val.SECURITYGROUPID);
        });
        sgList = comInstance.selectedGroupIds;
        const requestObject: any = {
          controller: "VARUsers",
          FunctionName: "Update",
          subsystem: "VAR360",
          userid:
            comInstance.$route.params.id &&
            parseInt(comInstance.$route.params.id) > 0
              ? comInstance.$route.params.id
              : sessionStorage.getItem("userId"),
          accountid: data.ACCOUNTID,
          active: data.ACTIVE,
          ufname: data.UFNAME,
          ulname: data.ULNAME,
          uemail: data.UEMAIL,
          upassword: data.NewPassword,
          uaddress: data.UADDRESS,
          ucity: data.UCITY,
          ustate: data.USERSTATE,
          uzip: data.UZIP,
          uphone: data.UPHONE,
          uPhoneExt: data.UPHONEEXT,
          companyName: data.ACCOUNTNAME,
          accountsList: accountsList.join(","),
          sgList: sgList.join(",")
        };
        // use different api route for customer users
        if (comInstance.currentRole == "Customer" || comInstance.fromPortals) {
          requestObject.FunctionName = "UpdateCustomerUser";
          if (comInstance.fromPortals) {
            requestObject.varUpdateCustomerUser = true;
          }
        }

        getRouteData(requestObject).then(function(response, statusText, jqXHR) {
          if (response.data.STATUS == 1) {
            if (comInstance.$route.params.id) {
              notifier.success(response.data.MESSAGE);
              $(".errorMsg").html("");
              comInstance.isEmailExists = false;

              // John comment - some indicator showing it saved and then stay on the same page and don't refresh page.
              // router.push({ name: "Users" });
              //   comInstance.toggleActive("userDetailTab");
            }
          } else {
            console.log(response.data.MESSAGE);
            comInstance.saving = "error";
          }
        });
        comInstance.saving = false;
      } else {
        //!response.data.RECORDCOUNT
        // notifier.alert(response.data.MESSAGE);
        comInstance.isEmailExists = true;
        comInstance.saving = "error";
      }
      // comInstance.saving = false;
    });
  }
  toggleActive(div) {
    this.isActive = div;

    if (div == "userCommTab") {
      //load data
      this.loadCommission();
    }
  }
  toggleSelectedUserId(userId, accountName) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    const inList = this.items.ALLACCOUNTS.find(item => item.AID == userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.items.ACCOUNTLIST.push({
        USERID: this.items.USERID,
        ANAME: accountName,
        AID: userId
      });
      this.items.ACCOUNTTOTAL = this.items.ACCOUNTLIST.length;

      // mark selected in all accounts list
      if (inList) {
        inList.selected = true;
      }

      return;
    }

    this.selectedUserIds.splice(index, 1);
    this.$delete(this.items.ACCOUNTLIST, index);
    if (inList) {
      inList.selected = false;
    }
  }

  selectAllUsers() {
    this.resetUsers();
    var thisUserID = this.items.USERID;
    var $this = this;
    this.selectedUserIds = this.items.ALLACCOUNTS.map(u => u.AID);

    $.each(this.items.ALLACCOUNTS, function(i, val) {
      $this.items.ACCOUNTLIST.push({
        USERID: thisUserID,
        ANAME: val.ANAME,
        AID: val.AID
      });
    });
  }

  resetUsers() {
    this.selectedUserIds = [];
    this.items.ACCOUNTLIST = [];
  }

  toggleSelectedGroupId(userId, accountName) {
    const index = this.selectedGroupIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedGroupIds.push(userId);
      this.selectedGroups.push({
        USERID: this.items.USERID,
        SECURITYGROUPNAME: accountName,
        SECURITYGROUPID: userId
      });

      // auto select accounts this group handles
      const related: any = this.ALLSECURITYGROUPS.find(
        item => item.SECURITYGROUPID == userId
      );
      const aIds = related ? related.AIDS || "" : "";
      if (aIds != "") {
        this.autoSelectAccounts(aIds);
      }

      return;
    }

    this.selectedGroupIds.splice(index, 1);
    this.$delete(this.selectedGroups, index);
  }

  selectAllGroups() {
    this.resetGroups();
    var thisUserID = this.items.USERID;
    var $this = this;
    this.selectedGroupIds = this.ALLSECURITYGROUPS.map(u => u.SECURITYGROUPID);

    $.each(this.ALLSECURITYGROUPS, function(i, val) {
      $this.selectedGroups.push({
        USERID: thisUserID,
        SECURITYGROUPNAME: val.SECURITYGROUPNAME,
        SECURITYGROUPID: val.SECURITYGROUPID
      });
      $this.autoSelectAccounts(val.AIDS || "");
    });
  }

  resetGroups() {
    this.selectedGroupIds = [];
    this.selectedGroups = [];
  }
  toggleSelectedCopyUserId(userId, accountName) {
    const index = this.selectedGroupIds.findIndex(id => id === userId);
    // To select single Account
    this.details.UserSelectedList = [];
    if (index === -1) {
      // this.selectedGroupIds.push(userId);
      this.details.UserSelectedList.push({
        USERID: userId,
        UFULLNAME: accountName
      });
      this.selectedName = accountName;
      $(".copyButton").attr("disabled", false);
      return;
    }
  }

  showCommissionEditModal(accountId, userId, percent, aName) {
    this.$router.push({
      name: "ViewCustomer",
      params: { id: accountId },
      query: {
        tab: "commission"
      }
    });
  }

  editCommission(accountId, userId) {
    var dataObj = {
      controller: "VARUsers",
      FunctionName: "Edit",
      subsystem: "VAR360",
      from: "editUserCommission",
      userId: userId,
      accountId: accountId
    };
    var self = this;
    getRouteData(dataObj).then(function(response) {
      console.log("editCommission", response);
    });
  }

  deleteCommission(accountId, userId, percent, aName, businessLineIdLists) {
    this.commissionUpdateInfo.action = "delete";
    // this.accountId = accountId;
    // this.userId = userId;
    this.updateCommisionModal = 1;
    this.commissionUpdateInfo.percentage = percent;
    this.commissionUpdateInfo.accID = accountId;
    this.commissionUpdateInfo.userId = userId;
    this.commissionUpdateInfo.accName = aName;
    this.commissionUpdateInfo.businessLineIdLists = businessLineIdLists.length
      ? businessLineIdLists
      : -1;
    // this.showCommissionEditModal(accountId, userId,percent,aName);
  }

  async modalClose(t) {
    this.updateCommisionModal = 0;
    this.commissionUpdateInfo.action = "update";
    this.loadCommission(1);
  }
  async EnableEditCommission(i) {
    this.editCommissionShowIndex = i;
  }
  openAccounts(aId) {
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }
  editAccountCommission(acc) {
    this.editCommissionAccId = acc;
    this.remainingCommissionVisible = !this.remainingCommissionVisible;
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }

  openCommssionModel() {
    this.remainingCommissionVisible = !this.remainingCommissionVisible;
    this.editAccountModelVisible = !this.editAccountModelVisible;
  }

  confirmRemoveCommission() {
    this.confirmCommissionRemoveModalVisible = false;
    this.balanceCommissions = [];
    this.remainingCommissionVisible = false;
    this.isConfirmChecked = false;
  }

  async loadCommission(page = 0) {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "VARUsers",
        FunctionName: "AllCommissions",
        subsystem: "VAR360",
        userId: this.$route.params.id,
        pageNumber: page > 0 ? page : this.pageNumber,
        AccountID: this.filters.searchId,
        aName: this.filters.searchName,
        order: this.sort.field != null ? this.sort.field : 0,
        direction:
          this.sort.field != null
            ? this.sort.direction[this.sort.field].toString()
            : 1
      });

      if (response.data.ERROR) {
        log.error(response.data.ERROR);
        return false;
      }
      if (response.data.STATUS !== 1) {
        log.error(response.data.STATUSMESSAGE);
        return false;
      }

      this.userCommission = response.data.USERCOMMISSION;
      this.userCommissionPaging = {
        currentPage: response.data.CURRENTPAGE,
        totalPages: response.data.TOTALPAGES
      };
    } catch (e) {
      log.debug("loadCommission", e);
    } finally {
      this.loading = false;
    }
  }

  reloadComm() {
    var updateCommForAllAccts: any = this.$refs.updateCommForAllAccts;
    if (updateCommForAllAccts.isDataChanged) {
      this.loadCommission(1);
    }
  }

  async removeAllComm() {
    var refModal: any = this.$refs.confirmRemoveAllComm;
    refModal.deleting = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Users",
        FunctionName: "AccountCommission",
        action: "remove_all_comm_for_user",
        forUserID: this.$route.params.id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.confirmRemoveAllComm = false;
        this.loadCommission(1);
      }
    } catch (err) {
      console.log(err);
      refModal.deleting = "error";
    } finally {
      refModal.deleting = false;
      this.confirmRemoveAllComm = false;
    }
  }

  updateUserState(selectedID) {
    this.selectedState = [selectedID];
    this.items.USERSTATE = selectedID;
  }
  close() {
    this.$refs.drodown.dropdownVisible = false;
  }

  getExt(e) {
    if (e != undefined) {
      return e.split(" ")[0];
    } else {
      return "US";
    }
  }
  countryChanged(obj) {
    this.items.UPHONEEXT = obj.countryCode + " " + "+" + obj.countryCallingCode;
  }

  autoSelectAccounts(aIds = "") {
    if (aIds == "") return;
    for (const aId of aIds.split(",")) {
      const inList = this.items.ALLACCOUNTS.find(item => item.AID == aId);
      if (inList && !inList.selected) {
        // auto select
        this.toggleSelectedUserId(inList.AID, inList.ANAME);
      }
    }
  }

  pageHeaders = [
    { key: "HARDWAREID", value: "Hardware ID" },
    { key: "HARDWARESKU", value: "SKU" },
    { key: "HDESCRIPTION", value: "Description" },
    { key: "CATEGORYNAME", value: "Category" },
    { key: "ASSETNUMBER", value: "Serial #" },
    { key: "STATUS", value: "Status" },
    { key: "LOGTS", value: "Last Online" },
    { key: "POID", value: "Customer PO" },
    { key: "PURCHASEDON", value: "Order Date" },
    { key: "HDATETIMECREATIONFORMATTED", value: "Creation Date" },
    { key: "WARRANTYNAME", value: "Warranty Name" },
    { key: "STARTDATEFORMATTED", value: "Warranty Start Date" },
    { key: "WARRENTYEXPFORMATTED", value: "Warranty End Date" },
    { key: "LASTCHECKEDFORMATTED", value: "Warranty Last Checked" },
    { key: "DEPLOYMENTNAME", value: "Deployment" },
    { key: "LIFECYCLE", value: "Lifecycle" }
  ];
  listCustomFields: any = [];
  searchHeader = "";
  selectedheaders = [
    "HARDWAREID",
    "HARDWARESKU",
    "HDESCRIPTION",
    "CATEGORYNAME",
    "ASSETNUMBER",
    "STATUS",
    "LOGTS"
  ];

  headerOrder = [
    "HARDWAREID",
    "HARDWARESKU",
    "HDESCRIPTION",
    "CATEGORYNAME",
    "ASSETNUMBER",
    "STATUS",
    "LOGTS",
    "POID",
    "PURCHASEDON",
    "HDATETIMECREATIONFORMATTED",
    "WARRANTYNAME",
    "STARTDATEFORMATTED",
    "WARRENTYEXPFORMATTED",
    "LASTCHECKEDFORMATTED",
    "DEPLOYMENTNAME",
    "LIFECYCLE"
  ];

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  setDefaultSelectedHeaders() {
    this.selectedheaders = [
      "HARDWAREID",
      "HARDWARESKU",
      "HDESCRIPTION",
      "CATEGORYNAME",
      "ASSETNUMBER",
      "STATUS",
      "LOGTS"
    ];
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedheaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedheaders = newSelectedHeaders;
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortListColumns",
      page: "HardwareEndUser",
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    await this.fetchData();
  }

  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;
    this.addHeader(x);
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      ret = (
        this.pageHeaders.find(item => item.key == key) || { key: "", value: "" }
      ).value;
    }
    return ret;
  }

  searchByHeader() {
    const filteredHeader = this.pageHeaders.filter(
      (item: any) =>
        item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1
    );
    return filteredHeader;
  }

  async addHeader(c) {
    let reloadList = false;
    const addedCol = this.selectedheaders.includes(c);
    colsResizeableReset();
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedheaders.includes(valIdx);
      if (i) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedheaders = sortedHeaders;

    // data to save selected headers
    const saveHeaders: string[] = [];
    for (const fieldId of this.selectedheaders) {
      saveHeaders.push(fieldId);

      // select all custom fields have same name
      const fieldName = (
        this.pageHeaders.find(header => header.key == fieldId) || {
          key: "",
          value: ""
        }
      ).value;
      if (fieldId.indexOf("customData") != -1 && fieldName) {
        const relatedFields = this.listCustomFields.filter(
          t =>
            t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
            !saveHeaders.includes(`customData${t.CUSTOMFIELDID}`)
        );
        for (const t of relatedFields) {
          saveHeaders.push(`customData${t.CUSTOMFIELDID}`);
        }
      }
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: saveHeaders.join(","),
      from: "hardwareEndUser"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    if (!this.selectedheaders.includes("HDESCRIPTION")) {
      this.filters.searchName = this.searchFilters.searchName = "";
    }

    if (
      !this.selectedheaders.includes("HARDWAREID") ||
      !this.selectedheaders.includes("HARDWARESKU") ||
      !this.selectedheaders.includes("HDESCRIPTION") ||
      !this.selectedheaders.includes("CATEGORYNAME") ||
      !this.selectedheaders.includes("ASSETNUMBER") ||
      !this.selectedheaders.includes("LOGTS")
    ) {
      this.pageNumber = 1;
      this.selectedID = [];
      // reloadList = true;
    }

    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      reloadList = true;
    }

    if (reloadList) {
      await this.fetchData();
    }
    colsResizeable();
  }

  async resetColumn(header) {
    switch (header) {
      case "MS_OPP_ID":
        this.resetSearchById();
        break;
      case "STATUS":
        this.resetStatusList();
        break;
      case "LIFECYCLE":
        this.resetLifeCycle();
        break;
      default:
        break;
    }
  }

  getFilterValue(header) {
    let filter = "";
    let searchKey: string | undefined = "";
    switch (header) {
      case "POID":
        searchKey = this.filters.searchId;
        break;
      case "STATUS":
        searchKey = this.searchFilters.statusNames.join(", ");
        // clear if default (active and retired)
        if (
          this.searchFilters.status.length == 2 &&
          this.searchFilters.status.includes(1) &&
          this.searchFilters.status.includes(2)
        ) {
          searchKey = "";
        } else if (this.searchFilters.status.length == 0) {
          searchKey = "None";
        }
        break;
      case "LIFECYCLE":
        if (this.filters.lifeCycleNames.length != this.hardwareLifecycles.length) {
          searchKey = this.filters.lifeCycleNames.join(', ');
        }
        break;
      default:
        // nothing
        break;
    }
    if (!this.selectedheaders.includes(header)) {
      filter = "";
    }
    return searchKey;
  }

  getCustomDataField(item, header) {
    // specify correct field by using listCustomFields
    const fieldName = (
      this.pageHeaders.find(headerItem => headerItem.key == header) || {
        key: "",
        value: ""
      }
    ).value;
    const relatedField = this.listCustomFields.find(
      t =>
        t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
        (t.AID || 0) == (item.AID || 0)
    );
    if (relatedField) {
      return `CUSTOMDATA${relatedField.CUSTOMFIELDID}`;
    }

    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  modalHardwareDetails: {
    show: boolean;
    isLoading: boolean;
    data: any;
    reset: Function;
  } = {
    show: false,
    isLoading: false,
    data: {},
    reset: () => {
      this.modalHardwareDetails.data = {
        id: 0,
        name: "",
        imageURL: "",
        hardwareImages: [],
        sku: "",
        statusName: "",
        statusId: 0,
        order: {
          purchaseId: 0,
          poId: "N/A"
        },
        customer: {
          aId: 0,
          aName: "N/A",
          accountId: ""
        },
        deployment: {
          deploymentId: 0,
          deploymentName: "N/A"
        },
        categoryId: 0,
        categoryName: "",
        carrier: "",
        trackingNumber: "N/A",
        endUser: {
          userId: 0,
          name: "N/A",
          addr: "",
          cityZip: ""
        },
        identifiers: [
          {
            name: "VAR360 Hardware ID",
            type: "hardware_id",
            value: "N/A"
          },
          {
            name: "Serial Number",
            type: "asset_number",
            value: ""
          },
          {
            name: "Asset Tag",
            type: "asset_tag",
            value: ""
          }
        ],
        logs: [],
        warranty: [
          {
            name: "Warranty Name",
            type: "warranty_name",
            value: "N/A"
          },
          {
            name: "Start Date",
            type: "start_date",
            value: ""
          },
          {
            name: "End Date",
            type: "end_date",
            value: ""
          },
          {
            name: "Last Checked",
            type: "last_checked",
            value: ""
          }
        ],
        warrantyResponse: ""
      };
    }
  };

  async showModalDetails(
    item,
    preventClick = false,
    options: any = {},
    e: any
  ) {
    if (preventClick || this.editableRows == true) return;
    if (e && $(e.target).hasClass("eu-hardware-link")) {
      return;
    }

    this.modalHardwareDetails.isLoading = true;
    this.modalHardwareDetails.reset();
    const response = await ApiHelper.callApi("post", {
      controller: "Hardware",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: item.HARDWAREID,
      aId: item.AID || 0
    });
    this.modalHardwareDetails.show = true;
    if (response.STATUS != 1) {
      notifier.alert(response.STATUSMESSAGE);
      this.modalHardwareDetails.show = false;
      return;
    }

    const hardwareData = response.hardware || {};
    const hardwareLogs: any = response.hardwareLogs || [];

    // @Todo() Update product details data
    this.modalHardwareDetails.data = {
      ...this.modalHardwareDetails.data,
      id: hardwareData.HARDWAREID || 0,
      hardwareUUID: hardwareData.HARDWAREUUID || "",
      imageURL: hardwareData.IMAGEURL || "",
      hardwareImages:
        (hardwareData.HARDWAREIMAGES || []).map(
          hImage => `${dataURL}/${hImage}`
        ) || [],
      name: hardwareData.HDESCRIPTION || "",
      sku: hardwareData.HARDWARESKU || "",
      statusName: hardwareData.HSTATUS || "",
      categoryId: hardwareData.CATEGORYID || 0,
      categoryName: hardwareData.CATEGORYNAME || "",
      categories: hardwareData.categories || [],
      order: {
        purchaseId: hardwareData.PURCHASEID || 0,
        poId: hardwareData.POID || "N/A"
      },
      customer: {
        aId: hardwareData.AID || 0,
        aName: hardwareData.ANAME || "N/A",
        accountId: hardwareData.ACCOUNTID || ""
      },
      deployment: {
        deploymentId: hardwareData.DEPLOYMENTID || 0,
        deploymentName: hardwareData.DEPLOYMENTNAME || "N/A"
      },
      endUser: {
        euID: hardwareData.EUID || 0,
        fname: hardwareData.UFNAME || "N/A",
        lname: hardwareData.ULNAME || "",
        phone: hardwareData.UPHONE || "",
        account: hardwareData.UACCOUNT || "",
        lastonline: hardwareData.LOGTS || "",
        address1: hardwareData.ADDRESS1 || "",
        address2: hardwareData.ADDRESS2 || "",
        city: hardwareData.CITY || "",
        state: hardwareData.STATE || "",
        zip: hardwareData.ZIPCODE || "",
        email: hardwareData.EMAIL || "",
        contactEuId: hardwareData.CONTACTEUID || 0
      },
      logs: {
        mostRecent: hardwareData.LOGS.length
          ? new Date(
              hardwareData.LOGS[hardwareData.LOGS.length - 1].LOGTS
            ).toLocaleString()
          : "",
        last10: hardwareData.LOGS.map(log => ({
          ...log,
          LOGTS: new Date(log.LOGTS).toLocaleString()
        }))
      },
      carrier: hardwareData.CARRIER || "",
      trackingNumber: hardwareData.TRACKINGNUMBER || "N/A",
      warrantyResponse: hardwareData.WARRANTYRESPONSE || ""
    };
    // identifiers data
    for (const item of this.modalHardwareDetails.data.identifiers) {
      if (item.type == "hardware_id") {
        item.value = hardwareData.HARDWAREID || 0;
      } else if (item.type == "asset_number") {
        item.value = hardwareData.ASSETNUMBER || "";
      } else if (item.type == "asset_tag") {
        item.value = hardwareData.ASSETTAG || "";
      }
    }
    // warranty data
    for (const item of this.modalHardwareDetails.data.warranty) {
      if (item.type == "warranty_name") {
        item.value = hardwareData.WARRANTYNAME || "";
      } else if (item.type == "start_date") {
        item.value = hardwareData.STARTDATEFORMATTED || "";
      } else if (item.type == "end_date") {
        item.value = hardwareData.WARRENTYEXPFORMATTED || "";
      } else if (item.type == "last_checked") {
        item.value = hardwareData.LASTCHECKEDFORMATTED || "";
      }
    }
    setTimeout(() => {
      this.modalHardwareDetails.isLoading = false;
    }, 500);
  }

  footerClickOutside(e) {
    // if ($(e.target).closest(".hardware-assign-eu-modal").length) {
    //   return;
    // }
    // this.modalHardwareDetails.show = false;
  }

  updateList(item) {
    const inList = this.products.find(t => t.HARDWAREID == item.hardwareId);
    if (inList) {
      if (item.type == "asset_tag") {
        inList.ASSETTAG = item.value;
      } else if (item.type == "asset_number") {
        inList.ASSETNUMBER = item.value;
      } else if (item.type == "warranty_name") {
        inList.WARRANTYNAME = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "start_date") {
        inList.STARTDATEFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "end_date") {
        inList.WARRENTYEXPFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      }
    }
  }

  async UpdateAddress() {
    var result = await this.$validator.validateAll();
    if (!result) {
      return;
    }
    this.locationLoading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Hardware",
      FunctionName: "UpdateUser",
      userId: this.$route.params.id,
      aId: this.$route.query.aId,
      addr1: this.items.ADDR1,
      addr2: this.items.ADDR2,
      city: this.items.CITY,
      state: this.items.EUSTATE,
      zip: this.items.ZIP,
      phone: this.items.PHONE,
      uPhoneExt: this.items.UPHONEEXT,
      email: this.items.EMAIL
    });

    if (response.data.STATUS === 1) {
      if (typeof response.data.LATITUDE != "undefined") {
        this.items.LATITUDE = response.data.LATITUDE;
      }
      if (typeof response.data.LONGITUDE != "undefined") {
        this.items.LONGITUDE = response.data.LONGITUDE;
      }
    }
    this.locationLoading = false;
  }

  async RefreshLocation() {
    this.locationLoading = true;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Hardware",
      FunctionName: "GetLocation",
      userId: this.$route.params.id,
      aId: this.$route.query.aId,
      addr1: this.items.ADDR1,
      addr2: this.items.ADDR2,
      city: this.items.CITY,
      state: this.items.EUSTATE,
      zip: this.items.ZIP
    });
    if (response.data.STATUS === 1) {
      if (typeof response.data.LATITUDE != "undefined") {
        this.items.LATITUDE = response.data.LATITUDE;
      }
      if (typeof response.data.LONGITUDE != "undefined") {
        this.items.LONGITUDE = response.data.LONGITUDE;
      }
    }
    this.locationLoading = false;
  }

  isRecent(logts) {
    let logDate: any = new Date(logts);
    let now: any = new Date();
    let diffInHours = Math.abs(now - logDate) / 36e5; // divide by (1000*60*60) to get difference in hours
    return diffInHours < 8;
  }

  updateCategory(categoryID, hardwareID) {
    const index = this.products.findIndex(item => item.HARDWAREID === hardwareID);
    const categoryName = this.categories.filter(item => item.ID === categoryID);

    if (index !== -1) {
      this.$set(this.products[index], "CATEGORYID", categoryID);
      this.$set(this.products[index], "CATEGORYNAME", categoryName[0].TEXT);
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  updateStatus(statusID, hardwareID) {
    const index = this.products.findIndex(item => item.HARDWAREID === hardwareID);

    if (index !== -1) {
      const statusMap = {
        0: "Deleted",
        1: "Active",
        2: "Retired"
      };

      // console.log(statusID, statusMap);

      this.$set(this.products[index], "STATUS", statusMap[statusID] || "Unknown");
      this.$set(this.products[index], "ISACTIVE", HardwareStatusValue[statusID]);
      this.$set(this.products[index], "ISACTIVETEXT", HardwareStatusValue[statusID]);
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  toggleEdit() {
    if (this.editableRows === true) {
      this.editableRows = false;
    } else {
      this.editableRows = true;
    }
  }

  async saveHardware(index) {
    const item = this.products[index];
    // if (item.ASSETNUMBER == "") {
    //   notifier.alert("Serial Number cannot be empty");
    //   return;
    // }
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      action: "inlineEdit",
      hardware: item,
      aId: item.AID || 0
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      Vue.set(
        this.products[index],
        "LASTCHECKEDFORMATTED",
        mm + "/" + dd + "/" + yyyy
      );
    } else if (response.data.STATUS == 0) {
      notifier.alert(response.data.STATUSMESSAGE);
    }
    await this.fetchData();
  }

  checkDuplicateExists(header, item) {
    if (item[header] != "") {
      const response = axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        header: header,
        value: item[header],
        hardwareId: item.HARDWAREID,
        action: "checkDuplicate"
      });
      response.then(response => {
        this.warningMessage = response.data.STATUSMESSAGE;
        this.inputField = response.data.header;
      });
    }
  }

  getStartDate(item, header) {
    if (header == "WARRENTYEXPFORMATTED") {
      return item.STARTDATEFORMATTED;
    }
  }

  checkDate(item, index) {
    if (item.WARRENTYEXPFORMATTED && new Date(item.STARTDATEFORMATTED) > new Date(item.WARRENTYEXPFORMATTED)) {
      this.warningMessage = "Start date must be lesser than end date";
      this.dateError = true;
    } else {
      this.dateError = false;
      this.saveHardware(index);
    }
  }

  checkWarrantyDate(item, index) {
    if (item.STARTDATEFORMATTED && moment(item.STARTDATEFORMATTED, "MM/DD/YYYY", true).isValid()) {
      this.checkDate(item, index);
    }
  }

  checkEndWarrantyDate(item, index) {
    if (
      item.WARRENTYEXPFORMATTED &&
      moment(item.WARRENTYEXPFORMATTED, "MM/DD/YYYY", true).isValid()
    ) {
      this.checkDate(item, index);
    }
  }

  async checkStatusList(id, name) {
    if (this.filters.status != undefined) {
      const findIndex = this.filters.status.findIndex(status => status === id);
      if (findIndex === -1) {
        this.filters.status.push(id);
        this.searchFilters.status.push(id);
      } else {
        this.filters.status.splice(findIndex, 1);
        this.searchFilters.status.splice(findIndex, 1);
      }
    }
    if (this.filters.statusNames != undefined) {
      const findIndex = this.filters.statusNames.findIndex(
        status => status === name
      );
      if (findIndex === -1) {
        this.filters.statusNames.push(name);
        this.searchFilters.statusNames.push(name);
      } else {
        this.filters.statusNames.splice(findIndex, 1);
        this.searchFilters.statusNames.splice(findIndex, 1);
      }
    }
    this.hasChangedStatusFilters = true;
  }

  async uncheckAllStatusList() {
    this.filters.status = this.searchFilters.status = [];
    this.filters.statusNames = this.searchFilters.statusNames = [];
    await this.fetchData();
  }

  async resetStatusList() {
    this.filters.status = this.searchFilters.status = [1, 2];
    this.filters.statusNames = this.searchFilters.statusNames = ["Active", "Retired"];
    await this.fetchData();
  }

  async checkLifecycle(id, name) {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.filters.lifeCycleNames.findIndex(item => item === "None") !== -1 && this.filters.lifeCycles.findIndex(item => item === "-1") !== -1) {
      this.filters.lifeCycles = [];
      this.filters.lifeCycleNames = [];
    }
    if (this.filters.lifeCycles != undefined) {
      const findIndex = this.filters.lifeCycles.findIndex(item => item === id);
      if (findIndex === -1) {
        this.filters.lifeCycles.push(id);
      } else {
        this.filters.lifeCycles.splice(findIndex, 1);
      }
    }
    if (this.filters.lifeCycleNames != undefined) {
      const findIndex = this.filters.lifeCycleNames.findIndex(
        item => item === name
      );
      if (findIndex === -1) {
        this.filters.lifeCycleNames.push(name);
      } else {
        this.filters.lifeCycleNames.splice(findIndex, 1);
      }
    }
    if (this.filters.lifeCycles.length == 0 && this.filters.lifeCycleNames == 0) {
      this.filters.lifeCycles = ["-1"];
      this.filters.lifeCycleNames = ["None"];
    }
    this.hasChangedLifeCycleFilters = true;
  }

  async uncheckAllLifeCycle() {
    this.selectedID = [];
    this.filters.lifeCycles = ["-1"];
    this.filters.lifeCycleNames = ["None"];
    await this.fetchData();
  }

  async resetLifeCycle() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.lifeCycles = this.hardwareLifecycles.map(item => item.LIFECYCLEID);
    this.filters.lifeCycleNames = this.hardwareLifecycles.map(item => item.LIFECYCLENAME);
    await this.fetchData();
  }

  // getRealTimeText(logts) {
  //   if (!logts) {
  //     return 'Inactive';
  //   }

  //   this.lastLoginOverYear = false;
  //   const date1 = moment(logts);
  //   const date2 = moment();

  //   const isSameDay = date1.isSame(date2, 'day');
  //   const isYesterday = date1.isSame(date2.clone().subtract(1, 'day'), 'day');

  //   const totalHours = date2.diff(date1, 'hours');
  //   const totalDays = date2.diff(date1, 'days');
  //   const totalWeeks = Math.floor(totalDays / 7);
  //   const totalMonths = date2.diff(date1, 'months');

  //   let timeText = '';

  //   if (totalHours < 1) {
  //     timeText = 'Online';
  //   } else if (isSameDay) {
  //     timeText = 'Today';
  //   } else if (isYesterday) {
  //     timeText = 'Yesterday';
  //   } else if (totalDays < 7) {
  //     timeText = `${totalDays} day${totalDays > 1 ? 's' : ''} ago`;
  //   } else if (totalWeeks === 1) {
  //     timeText = '1 Week Ago';
  //   } else if (totalWeeks >= 1 && totalWeeks < 4) {
  //     timeText = `${totalWeeks} Weeks Ago`;
  //   } else if (totalMonths === 1) {
  //     timeText = '1 Month Ago';
  //   } else if (totalMonths >= 1 && totalMonths < 12) {
  //     timeText = `${totalMonths} Months Ago`;
  //   } else {
  //     timeText = date1.format('MMM DD, YYYY h:mm A');
  //     this.lastLoginOverYear = true;
  //   }

  //   return timeText;
  // }
}
