


























































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {
    DropdownControl
  }
})
export default class Billing extends TSXComponent<Props, Events> {
  $parent: any;
  billingSelect = 0;
  billingRequiredSelect = 0;

  async created() {
    await this.$parent.getSavedSettings();
    const billingOn = this.$parent.savedSettings.billingOn || "";
    const billingReqOn = this.$parent.savedSettings.billingReqOn || "";
    if (billingOn.trim().toLowerCase() == "selected") {
      this.billingSelect = 1;
    }
    if (billingReqOn.trim().toLowerCase() == "selected") {
      this.billingRequiredSelect = 1;
    }
  }

  collectData() {
    return {
      status: 1,
      data: {
        billing: `${this.billingSelect}`,
        billing2: `${this.billingRequiredSelect}`
      }
    };
  }
}
