

































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { removeHTML } from "@/helpers/ApiHelper";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl }
})
export default class Timezone extends TSXComponent<Props, Events> {
  $parent: any;
  selectedTimezone = "";
  timeZonesList = [];

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.timeZonesList = Object.values(saved.timeZonesList || {});
    const currentTimeZone = saved.currentTimeZone || {};
    if (currentTimeZone.name || "") {
      this.selectedTimezone = `${currentTimeZone.name} - ${currentTimeZone.gmtAdjustment}`;
    }
  }

  collectData() {
    if (!this.selectedTimezone) return;

    const [name, gmtAdjustment] = this.selectedTimezone.split(" - ");
    let value = "";
    for (const key in this.$parent.savedSettings.timeZonesList) {
      const item = this.$parent.savedSettings.timeZonesList[key];
      if (item.name === name && item.gmtAdjustment === gmtAdjustment) {
        value = `${key}_${item.value}`;
        break;
      }
    }
    if (!value) return;

    return {
      status: 1,
      data: {
        timezoneSettings: "1",
        selectedZone: value
      }
    };
  }
}
