



































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import { htmlParse } from '../helpers/htmlHelpers';

interface Props {
  options: any[];
  groupOptions: any[];
  selectedIDs: number[];
  selectedGroupIDs: number[];
  label: string;
  placeholderText: string;
  multiSelect: boolean;
  inputText: string;
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class AccountSelector extends TSXComponent<Props> {
  @Prop({ required: true })
  options!: any[];

  @Prop({ required: true })
  groupOptions!: any[];

  @Prop({ required: true })
  selectedIDs!: number[];

  @Prop({ required: true })
  selectedGroupIDs!: number[];

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: "Search" })
  placeholderText!: string;

  @Prop({ required: false, default: false })
  hideToggle!: boolean;

  @Prop({ required: false, default: false })
  contractDesc!: boolean;

  @Prop({ required: false, default: true })
  multiSelect!: boolean;

  @Prop({ required: false, default: "Please make a selection" })
  inputText!: string;

  @Prop({ required: false, default: "No Accounts/Groups Found" })
  noDataMessage!: string;

  @Prop({ required: false, default: false })
  hideSearch?: boolean;

  @Prop({ required: false, default: false })
  hideSelectAll!: boolean;

  @Prop({ required: false, default: false })
  hideShowSelected?: boolean;

  dropdownVisible = false;
  search = '';
  showOnlySelected: boolean = false;
  creating: any = false;
  activeDropdown: string = 'accountGroups'

  combinedOptions = {
    groupOptions: [] as any[],
    accountOptions: [] as any[]
  };

  @Watch('groupOptions', { immediate: true })
  @Watch('options', { immediate: true })
  onOptionsChange() {
    this.initializeCombinedOptions();
  }

  mounted() {
    this.initializeCombinedOptions();
  }

  initializeCombinedOptions() {
    this.combinedOptions = {
      groupOptions: [...this.groupOptions],
      accountOptions: [...this.options]
    };
  }

  filterOnKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }

    let groupOptions = [...this.groupOptions];
    let accountOptions = [...this.options];

    if (this.search) {
      groupOptions = groupOptions.filter(option =>
        `${option.TEXT}`.toLowerCase().includes(this.search.toLowerCase())
      );
      accountOptions = accountOptions.filter(option =>
        `${option.CUSTOMFIELDOPTIONNAME}`.toLowerCase().includes(this.search.toLowerCase())
      );
    }

    if (this.showOnlySelected) {
      groupOptions = groupOptions.filter(option =>
        this.selectedGroupIDs.includes(option.ID)
      );
      accountOptions = accountOptions.filter(option =>
        this.selectedIDs.includes(option.CUSTOMFIELDOPTIONID)
      );
    }

    this.combinedOptions = {
      groupOptions,
      accountOptions
    };
  }

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
    if (this.dropdownVisible) {
      setTimeout(() => {
        $(".global-tags #autoExpand").focus();
      }, 300);
    }
  }

  clickOutside() {
    this.dropdownVisible = false;
  }

  isChecked(id: any): boolean {
    return this.selectedIDs.includes(id);
  }

  isGroupChecked(id: any): boolean {
    return this.selectedGroupIDs.includes(id);
  }

  selectAll() {
    this.$emit('selectAll');
  }

  reset() {
    this.$emit('reset');
  }

  removeHTML(txt: string) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }
    return txt;
  }

  getSelectedOption() {
    if (this.selectedIDs.length) {
      const selected = this.options.find(
        item => item.CUSTOMFIELDOPTIONID === this.selectedIDs[0]
      );
      return selected
        ? selected.CUSTOMFIELDOPTIONDESC !== ''
          ? `${selected.CUSTOMFIELDOPTIONNAME}: ${selected.CUSTOMFIELDOPTIONDESC}`
          : selected.CUSTOMFIELDOPTIONNAME
        : '';
    }
    return '';
  }

  checkToHide() {
    if (!this.multiSelect) {
      this.dropdownVisible = false;
    }
  }
}
