var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$parent.loading)?_c('div',[_c('div',{staticClass:"profile-row"},[(_vm.templateParamNamesData.length)?[_c('div',{staticClass:"half left"},[(_vm.$parent.savedSettings.showBetaFunctions)?[_c('div',{staticClass:"config-item"},[_c('DropdownControl',{attrs:{"id":_vm.defaultStoreTemplate,"name":_vm.defaultStoreTemplate,"options":_vm.$parent.savedSettings.SupportedTemplates.map(function (t) { return ({
                  ID: t.ID,
                  TEXT: t.NAME
                }); }),"selectedIDs":[_vm.defaultStoreTemplate],"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"placeholderText":'Select'},on:{"check":function (id) {
                  _vm.defaultStoreTemplate = id;
                }},model:{value:(_vm.defaultStoreTemplate),callback:function ($$v) {_vm.defaultStoreTemplate=$$v},expression:"defaultStoreTemplate"}}),_c('label',{staticClass:"up",attrs:{"for":_vm.defaultStoreTemplate}},[_c('span',[_vm._v("Select template")])])],1)]:_vm._e(),_vm._l((_vm.templateParamNamesData),function(item,index){return [(item.OLMS_DISPLAY_TYPE == 1)?_c('div',{key:index,staticClass:"config-item"},[_c('DropdownControl',{attrs:{"id":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID)),"name":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID)),"options":item.currentTemplateOptionData.map(function (t) { return ({
                  ID: t.OLMS_TEMPLATEOPTION_VALUE,
                  TEXT: t.OLMS_TEMPLATEOPTION_LABEL
                }); }),"selectedIDs":[item.selected],"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"placeholderText":'Select'},on:{"check":function (id) {
                  item.selected = id;
                }},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}),_c('label',{staticClass:"up",attrs:{"for":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID))}},[_c('span',[_vm._v(_vm._s(item.OLMS_TEMPLATEPARAM_NAME))])])],1):_c('div',{key:index,staticClass:"config-item"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.selected),expression:"item.selected",modifiers:{"trim":true}}],staticClass:"validateHtml",attrs:{"id":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID)),"name":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID)),"type":"text"},domProps:{"value":(item.selected)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "selected", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"up",attrs:{"for":("templateSettingOption" + (item.OLMS_TEMPLATEPARAM_ID))}},[_c('span',[_vm._v(_vm._s(item.OLMS_TEMPLATEPARAM_NAME))])])])]})],2),_c('div',{staticClass:"half left"},[_c('div',{staticClass:"config-item"},[_c('DropdownControl',{attrs:{"id":_vm.quoteFormatTemplate,"name":_vm.quoteFormatTemplate,"options":_vm.$parent.savedSettings.quoteFormatsList.map(function (t) { return ({
                ID: t.id,
                TEXT: t.name
              }); }),"selectedIDs":[_vm.quoteFormatTemplate],"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"placeholderText":'Select'},on:{"check":function (id) {
                _vm.quoteFormatTemplate = id;
              }},model:{value:(_vm.quoteFormatTemplate),callback:function ($$v) {_vm.quoteFormatTemplate=$$v},expression:"quoteFormatTemplate"}}),_c('label',{staticClass:"up",attrs:{"for":_vm.quoteFormatTemplate}},[_c('span',[_vm._v("Quote Format")])])],1),_c('div',{staticClass:"config-item"},[_c('DropdownControl',{attrs:{"id":_vm.mailerTemplate,"name":_vm.mailerTemplate,"options":_vm.$parent.savedSettings.mailersList.map(function (t) { return ({
                ID: t.id,
                TEXT: t.name
              }); }),"selectedIDs":[_vm.mailerTemplate],"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"placeholderText":'Select'},on:{"check":function (id) {
                _vm.mailerTemplate = id;
              }},model:{value:(_vm.mailerTemplate),callback:function ($$v) {_vm.mailerTemplate=$$v},expression:"mailerTemplate"}}),_c('label',{staticClass:"up",attrs:{"for":_vm.mailerTemplate}},[_c('span',[_vm._v("Mailers")])])],1)])]:_vm._e()],2),_c('div',{staticClass:"clearfix"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }