import { render, staticRenderFns } from "./logo.vue?vue&type=template&id=1d951a96&scoped=true"
import script from "./logo.vue?vue&type=script&lang=tsx"
export * from "./logo.vue?vue&type=script&lang=tsx"
import style0 from "./logo.vue?vue&type=style&index=0&id=1d951a96&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d951a96",
  null
  
)

export default component.exports