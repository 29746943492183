






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import SortIndicator from "../components/SortIndicator.vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { notifier, printHtml, wait, downloadFileUrl } from "../models/common";
import { validateFn } from "../helpers";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
import { ApiHelper } from "@/helpers/all";
import HardwareDetails from "@/components/Hardware/HardwareDetails.vue";
import RefreshStore from "../components/RefreshStore.vue";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import AssignToEndUserModal from "../components/AssignToEndUserModal.vue";
import HardwareUpdateFields from "../components/HardwareUpdateFields.vue";
import ConfirmYesNoTooltip from "../components/ConfirmYesNoTooltip.vue";
import Datepicker from "../components/Datepicker.vue";
import DropdownControl from "../components/DropdownControl.vue";
import HardwareDevicesComponent from "../components/HardwareDevices.vue";
import moment from "moment";
import HardwareStatusComponent, {
  HardwareStatusValue
} from "@/components/HardwareStatus.vue";
import AssignToDeploymentModal from "../components/AssignToDeploymentModal.vue";

import {
  getRangeFilter,
  colsResizeable,
  colsResizeableReset,
  colsResizeableUpdate,
  getDateRangeFilter,
  getEUAddress
} from "@/helpers/ApiHelper";
import draggable from "vuedraggable";
import PhoneInput from "../components/PhoneInput.vue";
import Inputmask from "inputmask";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    HardwareDetails,
    Pagination,
    draggable,
    SortIndicator,
    RefreshStore,
    ExportModal,
    ConfirmRemoveItemModal,
    AssignToEndUserModal,
    HardwareUpdateFields,
    ConfirmYesNoTooltip,
    Datepicker,
    DropdownControl,
    HardwareDevicesComponent,
    PhoneInput,
    HardwareStatusComponent,
    AssignToDeploymentModal
  },
  beforeRouteUpdate(to, from, next) {
    const self: any = this as any;
    const query = to.query;
    const fromQuery = from.query;
    self.filters.sku = self.searchFilters.sku = query.sku;
    self.filters.customerPO = self.searchFilters.customerPO = query.poId || "";
    self.filters.endUser = self.searchFilters.endUser = query.endUser || "";
    self.filters.category = self.searchFilters.category = query.category || "";
    const view = query.view || "";
    const viewtype = query.viewtype || "";
    if (view == "All" || view == "Group") {
      self.viewType = view;
    } else if (viewtype == "EndUser") {
      self.viewType = viewtype;
    }

    if (to.name == "Hardware" && to.name == from.name) {
      if (query.hardwareid) {
        self.filters.hardwareId = self.searchFilters.hardwareId =
          query.hardwareid;
      } else if (fromQuery.hardwareid) {
        self.filters.hardwareId = self.searchFilters.hardwareId =
          fromQuery.hardwareid;
      }
    }
    self.pageNumber = 1;
    // self.setDefaultSelectedHeaders();
    self.fetchData(self.filters.hardwareId);
    next();
  },
  beforeDestroy() {
    $("body").removeClass("stop-scrolling");
  },
  methods: {
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    },
    getEUAddress
  }
})
export default class Hardwares extends TSXComponent<void> {
  // only got data if open from hardware import page
  @Prop({ required: false, default: [] })
  importHardwareIds?: any[];
  lastLoginOverYear: boolean = false;
  editableRows = false;
  isSaving = false;
  currentRole = sessionStorage.getItem("userRole");
  selectedID: any = [];
  selectedHardware: any = [];
  refreshStoreModalVisible = false;
  saving = false;
  loading = false;
  hardwareCheckAll = false;
  selectedAll = false;
  selectPageAll = false;
  newPageHardware: (number | undefined)[] = [];
  totalHardwares: (number | undefined)[] = [];
  excludedIDs: number[] = [];
  totalHardwareItems = 0;
  totalAll = 0;
  items: any = {};
  categories: any = [];
  status: any = [
    { ID: "Deleted", TEXT: "Deleted" },
    { ID: "Active", TEXT: "Active" },
    { ID: "Retired", TEXT: "Retired" }
  ];
  showDeletedFlg: boolean = false;
  warningMessage = "";
  inputField = "";
  // deploymentDetails = {
  //   id: 0,
  //   name: '',
  // };
  details: any = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1,
    TOTALALERTS: 0,
    searchByAsset: "",
    hardwareSKU: "",
    userHardwareHeaders: [],
    ISSEARCH: 0
  };
  filters: any = {
    status: [1, 2],
    lifeCycles: [],
    hardwareId: "",
    customerPO: "",
    sku: "",
    category: "",
    categoryIds: [],
    categoryNames: [],
    assetNumber: "",
    assetTag: "",
    customer: "",
    endUser: "",
    endUserNotNull: false,
    warrantyName: "",
    fromStartDate: "",
    endStartDate: "",
    fromExpDate: "",
    endExpDate: "",
    fromPurchasedOn: "",
    endPurchasedOn: "",
    fromEuCreationDate: "",
    endEuCreationDate: "",
    fromLastCheckDate: "",
    endLastCheckDate: "",
    fromLastOnline: "",
    endLastOnline: "",
    lastOnlineNotNull: false,
    purchaseIds: [],
    deploymentId: "",
    purchaseId: "",
    productLine: "",
    statusNames: ["Active", "Retired"],
    lifeCycleNames: [],
    selectedAccs: [],
    selectedAccountsName: [],
    deploymentName: ""
  };
  pageNumber = 1;
  sort: any = {
    field: null,
    direction: {
      sku: 1,
      category: 1,
      price: 1,
      poId: 1,
      assetNumber: 1,
      status: 1,
      accountName: 1,
      purchasedOn: 1,
      endUser: 1,
      lastOnline: 1,
      warrantyName: 1,
      startDate: 1,
      warrentyExp: 1,
      lastChecked: 1,
      purchaseId: 1,
      productLine: 1,
      deploymentName: 1,
      lifeCycle: 1
    }
  };
  directionField = "";

  pageHeaders: any = [
    { key: "HARDWAREID", value: "Hardware Id" },
    { key: "SKU", value: "Sku" },
    { key: "HDESCRIPTION", value: "Description" },
    { key: "ASSETNUMBER", value: "Serial Number" },
    { key: "CATEGORYNAME", value: "Category" },
    { key: "PURCHASEID", value: "VAR360 Order" },
    { key: "PRODUCTLINE", value: "Product Line" },
    { key: "POID", value: "Customer PO" },
    { key: "HSTATUS", value: "Status" },
    { key: "PURCHASEDON", value: "Created Date" },
    { key: "EUCREATIONDATE", value: "End User Creation Date" },
    { key: "DEVICES", value: "Devices" },
    { key: "ASSETTAG", value: "Asset Tag" },
    { key: "ENDUSER", value: "End User" },
    { key: "ADDRESS", value: "User Location" },
    { key: "PHONE", value: "Phone Number" },
    { key: "EMAIL", value: "Email" },
    { key: "LASTONLINE", value: "Last Online" },
    { key: "LASTSEEN", value: "Last Location" },
    { key: "WARRANTYNAME", value: "Warranty Name" },
    { key: "STARTDATEFORMATTED", value: "Warranty Start Date" },
    { key: "WARRENTYEXPFORMATTED", value: "Warranty End Date" },
    { key: "LASTCHECKEDFORMATTED", value: "Warranty Last Checked" },
    { key: "MAINMODEL", value: "Main Model" },
    { key: "DEPLOYMENTNAME", value: "Deployment" },
    { key: "LIFECYCLE", value: "Lifecycle" }
  ];

  headerCustomer = [
    "HARDWAREID",
    "SKU",
    "HDESCRIPTION",
    "ASSETNUMBER",
    "CATEGORYNAME",
    /* "PRICEFORMATTED", */
    "PURCHASEID",
    "PRODUCTLINE",
    "POID",
    "HSTATUS",
    "ACCOUNTNAME",
    "PURCHASEDON",
    "EUCREATIONDATE",
    "DEVICES",
    "ASSETTAG",
    "ENDUSER",
    "ADDRESS",
    "PHONE",
    "EMAIL",
    "LASTONLINE",
    "LASTSEEN",
    "WARRANTYNAME",
    "STARTDATEFORMATTED",
    "WARRENTYEXPFORMATTED",
    "LASTCHECKEDFORMATTED",
    "MAINMODEL",
    "DEPLOYMENTNAME",
    "LIFECYCLE"
  ];
  selectedHeaders: string[] = [];
  // By default, we should be in the "grouped" view for hardware
  viewType = "Group";
  selectedView = false;
  assignEndUsersModalVisible = false;
  // last requestData for the list
  lastRequestData: any = {
    All: null,
    Group: null,
    EndUser: null
  };

  searchFilters: any = {
    status: [1, 2],
    hardwareId: "",
    customerPO: "",
    sku: "",
    category: "",
    categoryIds: [],
    categoryNames: [],
    assetNumber: "",
    assetTag: "",
    customer: "",
    endUser: "",
    warrantyName: "",
    fromStartDate: "",
    endStartDate: "",
    fromPurchasedOn: "",
    endPurchasedOn: "",
    fromEuCreationDate: "",
    endEuCreationDate: "",
    fromLastOnline: "",
    endLastOnline: "",
    fromExpDate: "",
    endExpDate: "",
    fromLastCheckDate: "",
    endLastCheckDate: "",
    purchaseIds: [],
    deploymentId: "",
    purchaseId: "",
    productLine: "",
    statusNames: ["Active", "Retired"],
    selectedAccs: [],
    selectedAccountsName: [],
    deploymentName: ""
  };

  listCustomFields: any = [];

  headerOrder = [
    "HARDWAREID",
    "SKU",
    "HDESCRIPTION",
    "ASSETNUMBER",
    "CATEGORYNAME",
    /* "PRICEFORMATTED", */
    "PURCHASEID",
    "PRODUCTLINE",
    "POID",
    "HSTATUS",
    "ACCOUNTNAME",
    "PURCHASEDON",
    "EUCREATIONDATE",
    "DEVICES",
    "ASSETTAG",
    "ENDUSER",
    "ADDRESS",
    "PHONE",
    "EMAIL",
    "LASTONLINE",
    "LASTSEEN",
    "WARRANTYNAME",
    "STARTDATEFORMATTED",
    "WARRENTYEXPFORMATTED",
    "LASTCHECKEDFORMATTED",
    "MAINMODEL",
    "DEPLOYMENTNAME"
  ];
  allowEndUserAll = false;
  withIdentifiersOnly = false;
  exportModalVisible = false;
  confirmRemoveModalVisible = false;
  confirmRestoreModalVisible = false;
  searchHeader = "";
  selectedItems: any = [];
  isLoaded = false;
  processed = {
    showVar360Order: false
  };
  confirmAssignVisible = false;
  dateError = false;
  checkingWarranties = false;
  progressBarWidth = 0;
  totalWarranties = 0;
  checkedWarranties = 0;
  cancelCurrentAnimation = false;
  animationFrameId: any = null;
  deletedView: boolean = false;
  editedItem: number = -1;
  confirmRemoveSerialNumber: boolean = false;
  updateFieldsModalVisible = false;
  assignToDeploymentVisible = false;
  configAccountID: string = "";
  isAllowAccountConfig: boolean = false;
  confirmCustomerConfigModalVisible: boolean = false;
  $router: any;
  hardwareLifecycles: any = [];
  viewFrom: string = "";

  $refs!: {
    inputMask: HTMLInputElement;
    hardwareDetails: HardwareDetails;
  };

  @Watch("selectedHeaders")
  colsChange(val: any) {
    colsResizeable();
  }

  async created() {
    if (this.currentRole === "Reseller" || this.currentRole === "Customer") {
      this.pageHeaders.push({ key: "ACCOUNTNAME", value: "Customer" });
    }

    const urlParams = window.location.hash;

    if (
      urlParams == "#/hardware?viewtype=EndUser" ||
      urlParams.startsWith("#/hardware?viewtype=EndUser")
    ) {
      this.viewType = "EndUser";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?sku=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?deploymentPurchaseId=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else if (urlParams.startsWith("#/hardware?searchAccountName=")) {
      this.viewType = "All";
      this.allowEndUserAll = true;
    } else {
      this.viewType = "Group";
    }

    // init params
    const query = this.$route.query;
    if ((query.sku || "") != "") {
      this.filters.sku = this.searchFilters.sku = query.sku;
    }
    if (query.fromOrderHardware || false) {
      this.filters.status = this.searchFilters.status = [1, 2];
      this.filters.statusNames = this.searchFilters.statusNames = [
        "Active",
        "Retired"
      ];
    }
    if ((query.poId || "") != "") {
      this.filters.customerPO = this.searchFilters.customerPO = query.poId;
    }
    if ((query.searchAccountName || "").length) {
      this.filters.customer = this.searchFilters.customer =
        query.searchAccountName;
    }
    if ((query.purchaseId || "") != "") {
      this.filters.purchaseId =
        this.searchFilters.purchaseId = `${query.purchaseId}`;
    }
    const currentQueryParam = { ...query };
    if (query.deploymentId) {
      if (typeof query.deploymentId === "string") {
        this.filters.deploymentId = query.deploymentId;
        this.$delete(currentQueryParam, "deploymentId");
      }
    }
    if (query.deploymentName) {
      if (typeof query.deploymentName === "string") {
        // this.deploymentDetails.name = query.deploymentName;
        this.filters.deploymentName = query.deploymentName;
        this.searchFilters.deploymentName = query.deploymentName;
        this.$delete(currentQueryParam, "deploymentName");
      }
    }
    // if (query.deploymentPurchaseId) {
    //   if (typeof query.deploymentPurchaseId === "string") {
    //     this.filters.purchaseIds = [parseInt(query.deploymentPurchaseId)];
    //   } else if (Array.isArray(query.deploymentPurchaseId)) {
    //     this.filters.purchaseIds = query.deploymentPurchaseId.map(s =>
    //       parseInt(s || "0")
    //     );
    //   }

    //   this.$delete(currentQueryParam, "deploymentPurchaseId");
    //   this.$delete(currentQueryParam, "view");
    //   this.$router.replace({ query: currentQueryParam });
    // }

    if (query.hardwareid || query.viewFrom) {
      this.filters.hardwareId = this.searchFilters.hardwareId =
        this.$route.query.hardwareid;
      const currentQueryParam = { ...query };
      if (query.viewFrom) {
        this.viewFrom = String(this.$route.query.viewFrom);
        this.filters.status = this.searchFilters.status = [0, 1, 2];
        this.filters.statusNames = this.searchFilters.statusNames = ["Active", "Deleted", "Retired"];
        this.$delete(currentQueryParam, "viewFrom");
      }
      this.$delete(currentQueryParam, "hardwareid");
      this.$router.replace({ query: currentQueryParam });
    }
    const view = query.view || "";
    if (view == "All" || view == "Group") {
      this.viewType = view;
    }

    if (query.pageName && query.pageName === "HardwareDashboard") {
      this.viewType = "All";
      this.withIdentifiersOnly = true;
      this.filters.endUserNotNull = true;
      this.allowEndUserAll = true;
    }

    this.setDefaultSelectedHeaders();
    this.accsLoad();
    await this.fetchData();

    if (
      !this.processed.showVar360Order &&
      (query.showVar360Order || "") != ""
    ) {
      // auto select var360 order column
      if (!this.selectedHeaders.includes("PURCHASEID")) {
        this.selectedHeaders.push("PURCHASEID");
        this.sortSelectedHeader();
        this.addHeader("PURCHASEID");
      }
      this.processed.showVar360Order = true;
    }

    // auto add filter for custom fields
    for (const item of this.listCustomFields) {
      this.$set(this.filters, `customData${item.CUSTOMFIELDID}`, "");
      this.$set(this.searchFilters, `customData${item.CUSTOMFIELDID}`, "");
      if (item.ISDATE || 0) {
        this.$set(this.filters, `customData${item.CUSTOMFIELDID}_fromDate`, "");
        this.$set(this.filters, `customData${item.CUSTOMFIELDID}_toDate`, "");
        this.$set(
          this.searchFilters,
          `customData${item.CUSTOMFIELDID}_fromDate`,
          ""
        );
        this.$set(
          this.searchFilters,
          `customData${item.CUSTOMFIELDID}_toDate`,
          ""
        );
      }
    }
  }

  setDefaultSelectedHeaders() {
    this.selectedHeaders = [
      "HARDWAREID",
      "SKU",
      "HDESCRIPTION",
      "ASSETNUMBER",
      "CATEGORYNAME",
      "PURCHASEID",
      "POID",
      "HSTATUS"
    ];
  }

  isRecent(logts) {
    let logDate: any = new Date(logts);
    let now: any = new Date();
    let diffInHours = Math.abs(now - logDate) / 36e5; // divide by (1000*60*60) to get difference in hours
    return diffInHours < 8;
  }

  // getRealTimeText(logts) {
  //   if (!logts) {
  //     return 'Inactive';
  //   }

  //   this.lastLoginOverYear = false;
  //   const date1 = moment(logts);
  //   const date2 = moment();

  //   const isSameDay = date1.isSame(date2, 'day');
  //   const isYesterday = date1.isSame(date2.clone().subtract(1, 'day'), 'day');

  //   const totalHours = date2.diff(date1, 'hours');
  //   const totalDays = date2.diff(date1, 'days');
  //   const totalWeeks = Math.floor(totalDays / 7);
  //   const totalMonths = date2.diff(date1, 'months');

  //   let timeText = '';

  //   if (totalHours < 1) {
  //     timeText = 'Online';
  //   } else if (isSameDay) {
  //     timeText = 'Today';
  //   } else if (isYesterday) {
  //     timeText = 'Yesterday';
  //   } else if (totalDays < 7) {
  //     timeText = `${totalDays} day${totalDays > 1 ? 's' : ''} ago`;
  //   } else if (totalWeeks === 1) {
  //     timeText = '1 Week Ago';
  //   } else if (totalWeeks >= 1 && totalWeeks < 4) {
  //     timeText = `${totalWeeks} Weeks Ago`;
  //   } else if (totalMonths === 1) {
  //     timeText = '1 Month Ago';
  //   } else if (totalMonths >= 1 && totalMonths < 12) {
  //     timeText = `${totalMonths} Months Ago`;
  //   } else {
  //     timeText = date1.format('MMM DD, YYYY h:mm A');
  //     this.lastLoginOverYear = true;
  //   }

  //   return timeText;
  // }

  hasFilterCondition() {
    if (this.viewType === "EndUser") {
      if (
        this.filters.endUser ||
        this.filters.customerPO ||
        this.filters.customer ||
        this.filters.purchaseId
      ) {
        return true;
      }
    } else if (this.viewType === "Group") {
      if (
        this.filters.customerPO ||
        this.filters.sku ||
        this.filters.category ||
        this.filters.fromPurchasedOn ||
        this.filters.endPurchasedOn ||
        this.filters.purchaseId ||
        this.filters.assetNumber
      ) {
        return true;
      }
    } else if (this.viewType === "All") {
      if (
        this.filters.hardwareId ||
        this.filters.customer ||
        this.filters.customerPO ||
        this.filters.endUser ||
        this.filters.sku ||
        this.filters.category ||
        this.filters.assetNumber ||
        this.filters.assetTag ||
        this.filters.warrantyName ||
        this.filters.fromStartDate ||
        this.filters.endStartDate ||
        this.filters.fromExpDate ||
        this.filters.endExpDate ||
        this.filters.fromPurchasedOn ||
        this.filters.endPurchasedOn ||
        this.filters.purchaseId ||
        this.filters.endUser ||
        this.filters.status.length ||
        this.filters.purchaseIds.length ||
        this.filters.productLine ||
        this.filters.fromLastCheckDate ||
        this.filters.endLastCheckDate
      ) {
        return true;
      }
    }

    return false;
  }

  allHardwareHeaders = {};
  isFirstLoad = true;
  async fetchData(pHardwareid: string = "") {
    this.hasChangedAccountFilters = false;
    this.hasChangedStatusFilters = false;
    this.hasChangedLifeCycleFilters = false;
    this.hasChangedCategoryFilters = false;
    const urlParams = window.location.hash;
    this.loading = true;
    let showDeleted = false;

    if (this.importHardwareIds && this.importHardwareIds.length > 0) {
      this.viewType = "All";
      this.selectedView = true;
    }

    // || this.filters.status != ''
    if (this.deletedView) {
      showDeleted = true;
    } else {
      showDeleted = false;
    }

    try {
      let requestData: any = {
        controller: "Hardware",
        FunctionName: "List",
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        pageNumber: this.pageNumber,
        order: this.sort.field ? this.sort.field : 0,
        direction: this.sort.field
          ? this.sort.direction[this.directionField]
          : 0,
        viewType:
          showDeleted ||
          (this.importHardwareIds && this.importHardwareIds.length > 0)
            ? "All"
            : this.viewType,
        showDeletedFlg: showDeleted ? 1 : 0,
        endUserNotNull: this.filters.endUserNotNull ? 1 : 0,
        lastOnlineNotNull: this.filters.lastOnlineNotNull ? 1 : 0,
        fromLastOnline: this.filters.fromLastOnline,
        endLastOnline: this.filters.endLastOnline,
        selectedView: this.selectedView,
        allowEndUserAll: this.allowEndUserAll,
        withIdentifiersOnly: this.withIdentifiersOnly,
        purchaseIds: this.filters.purchaseIds.join(","),
        importHardwareIds: this.importHardwareIds
          ? this.importHardwareIds.join()
          : "",
        purchaseId: this.filters.purchaseId || "",
        deploymentId: this.filters.deploymentId || "",
        customer: this.filters.customer || "",
        customerPO: this.filters.customerPO || "",
        status: this.filters.status.length
          ? this.filters.status.join(",")
          : "-1",
        lifeCycles: this.filters.lifeCycles.length
          ? this.filters.lifeCycles.join(",")
          : "-1",
        selectedAccountDs: this.filters.selectedAccs.join(","),
        viewFrom: this.viewFrom,
        localTime: moment()
      };
      switch (this.viewType) {
        case "EndUser": {
          requestData = {
            ...requestData,
            endUser: this.filters.endUser,
            fromEuCreationDate: this.filters.fromEuCreationDate,
            endEuCreationDate: this.filters.endEuCreationDate
          };
          // not get delete hardware for end user view
          requestData.status = requestData.status
            .split(",")
            .filter(t => parseInt(t) != 0)
            .join(",");

          // append custom fields search
          const customData = {};
          for (const i in this.filters) {
            if (i.startsWith("customData") && this.filters[i] != "") {
              customData[i] = this.filters[i];
            }
          }
          requestData.customData = customData;
          break;
        }
        case "Group":
          requestData = {
            ...requestData,
            customerPO: this.filters.customerPO,
            sku: this.filters.sku,
            category: this.filters.category,
            fromPurchasedOn: this.filters.fromPurchasedOn,
            categoryIds: this.isFirstLoad
              ? "-1"
              : this.filters.categoryIds.join(","),
            endPurchasedOn: this.filters.endPurchasedOn,
            assetNumber: this.filters.assetNumber
          };
          break;
        default: {
          requestData = {
            ...requestData,
            hardwareId: pHardwareid ? pHardwareid : this.filters.hardwareId,
            customer: this.filters.customer,
            customerPO: this.filters.customerPO,
            endUser: this.filters.endUser,
            sku: this.filters.sku,
            category: this.filters.category,
            categoryIds: this.isFirstLoad
              ? "-1"
              : this.filters.categoryIds.join(","),
            assetNumber: this.filters.assetNumber,
            assetTag: this.filters.assetTag,
            warrantyName: this.filters.warrantyName,
            fromStartDate: this.filters.fromStartDate,
            endStartDate: this.filters.endStartDate,
            fromExpDate: this.filters.fromExpDate,
            endExpDate: this.filters.endExpDate,
            fromPurchasedOn: this.filters.fromPurchasedOn,
            endPurchasedOn: this.filters.endPurchasedOn,
            fromLastCheckDate: this.filters.fromLastCheckDate,
            endLastCheckDate: this.filters.endLastCheckDate,
            productLine: this.filters.productLine,
            deploymentName: this.filters.deploymentName
          };

          // append custom fields search
          const customData = {};
          for (const i in this.filters) {
            if (i.startsWith("customData") && this.filters[i] != "") {
              customData[i] = this.filters[i];
            }
          }
          requestData.customData = customData;

          break;
        }
      }
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );

      // update searchFilters
      this.searchFilters.status = [...this.filters.status];
      this.searchFilters.statusNames = [...this.filters.statusNames];

      // clear after search by imported hardwares
      if (this.importHardwareIds && this.importHardwareIds.length > 0) {
        this.importHardwareIds = [];
      }

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        // save last request data per view
        // this.lastRequestData[this.viewType] = { ...requestData };

        this.allHardwareHeaders = response.data.allHardwareHeaders || {};
        this.items = response.data.HARDWARES || [];
        const categories = response.data.CATEGORIES || [];
        if (categories.length) {
          categories.push({ ID: 0, TEXT: "Unknown" });
          this.categories = categories;
        }
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
          this.filters.categoryIds = this.categories.map(item => item.ID);
          this.filters.categoryNames = this.categories.map(item => item.TEXT);
        }
        this.totalHardwareItems = response.data.HARDWARES.length;
        this.totalAll = response.data.TOTAL;
        this.items.forEach(item => {
          item.NEW_PHONE = item.PHONE || "";
          item.NEW_EMAIL = item.EMAIL || "";
          item.NEW_ASSETNUMBER = item.ASSETNUMBER || "";
          item.NEW_ASSETTAG = item.ASSETTAG || "";
          item.NEW_WARRENTYEXPFORMATTED = item.WARRENTYEXPFORMATTED || "";
          item.NEW_STARTDATEFORMATTED = item.STARTDATEFORMATTED || "";
          if (item.LOGTS.length) {
            let parsedDate = this.parseCustomDate(item.LOGTS);
            item.LOGTS = this.formatDateAndTime(parsedDate);
          }
        });

        this.details = response.data || {};

        // set selected status
        // const checkAllPages = this.newPageHardware.includes(this.pageNumber);
        // if (checkAllPages)  {
        //   this.totalHardwares = [];
        //   for (const val of this.items) {
        //     if (!this.excludedIDs.includes(val.HARDWAREID)) {
        //       this.totalHardwares.push(val.PLID);
        //     }
        //   }
        //   const intersectingArrays = this.getArraysIntersection(this.totalHardwares, this.selectedID);
        //   if (intersectingArrays.length == this.items.length) {
        //     this.hardwareCheckAll = true;
        //   } else {
        //     this.hardwareCheckAll = false;
        //   }
        // }
        // else {
        //   this.hardwareCheckAll = false;
        // }
        if (this.selectedAll === true) {
          this.selectAll(true);
        }
        this.totalHardwares = [];
        for (const item of this.items) {
          const val =
            this.viewType == "EndUser" ? item.LINEKEY : item.HARDWAREID;
          if (this.selectedID.includes(val)) {
            this.totalHardwares.push(val);
          }
        }
        if (this.items.length == this.totalHardwares.length) {
          this.hardwareCheckAll = true;
          this.selectPageAll = true;
        } else {
          this.hardwareCheckAll = false;
          this.selectPageAll = false;
        }

        if (
          !this.selectedView &&
          this.details["currentView"] &&
          !urlParams.startsWith("#/hardware?sku=") &&
          !urlParams.startsWith("#/hardware?viewtype=EndUser")
        ) {
          this.selectedView = true;
          this.viewType = this.details["currentView"];
        }
        // if (this.viewType == "Group") {
        //   this.selectedHeaders = [
        //     "SKU",
        //     "HDESCRIPTION",
        //     "DEVICES",
        //     "POID",
        //     "CATEGORYNAME",
        //     /* "PRICEFORMATTED", */
        //     "ASSETNUMBER",
        //     "PURCHASEDON"
        //   ];
        // } else if (this.viewType == "EndUser") {
        //   this.selectedHeaders = [
        //     "ENDUSER",
        //     "ADDRESS",
        //     "PHONE",
        //     "MAINMODEL",
        //     "EMAIL",
        //     "DEVICES",
        //     "LASTONLINE",
        //     "LASTSEEN"
        //   ];
        // } else {
        // complete view
        // selected hardware headers

        // append custom fields to custom colums list
        this.listCustomFields = this.details.listCustomFields || [];
        for (const item of this.listCustomFields) {
          const field = `customData${item.CUSTOMFIELDID}`;
          let existedName = false;
          for (const header of this.pageHeaders) {
            if (
              header.key.indexOf("customData") != -1 &&
              header.value.toLowerCase() == item.CUSTOMFIELDNAME.toLowerCase()
            ) {
              existedName = true;
              break;
            }
          }
          if (!existedName) {
            if (
              (
                this.pageHeaders.find(header => header.key == field) || {
                  key: "",
                  value: ""
                }
              ).value == ""
            ) {
              this.pageHeaders.push({
                key: field,
                value: item.CUSTOMFIELDNAME
              });
            }
            if (!this.headerCustomer.includes(field)) {
              this.headerCustomer.push(field);
            }
          }
        }

        this.hardwareLifecycles = this.details.HARDWARELIFECYCLES || [];
        if (this.filters.lifeCycles.length == 0 && this.filters.lifeCycleNames.length == 0) {
          this.filters.lifeCycles = this.details.HARDWARELIFECYCLES.map(item => item.LIFECYCLEID);
          this.filters.lifeCycleNames = this.details.HARDWARELIFECYCLES.map(item => item.LIFECYCLENAME);
        }

        const savedHeaders = this.details.userHardwareHeaders || [];
        if (savedHeaders.length) {
          this.selectedHeaders = [];
          for (const item of savedHeaders) {
            const inList = this.pageHeaders.find(
              t => t.key.toLowerCase() == item.toLowerCase()
            );
            if (inList) {
              // if found in main columns list
              this.selectedHeaders.push(inList.key);
            }
          }
          if (
            this.$route.query.endUser &&
            !this.selectedHeaders.find(item => item == "ENDUSER")
          ) {
            this.selectedHeaders.push("ENDUSER");
          }
          if (!this.selectedHeaders.length) {
            this.setDefaultSelectedHeaders();
          }
        } else {
          this.setDefaultSelectedHeaders();
        }

        if (
          this.filters.deploymentName.length &&
          !this.selectedHeaders.includes("DEPLOYMENTNAME")
        ) {
          this.selectedHeaders.push("DEPLOYMENTNAME");
        }

        // if sorted header
        const savedSorting = this.details.userHardwareColumnOrder || [];
        if (savedSorting.length) {
          let newSorting: any = [];
          for (const header of savedSorting) {
            const inList = this.pageHeaders.find(item => item.key == header);
            if (inList) {
              newSorting.push(inList);
            }
          }
          const newItems = this.pageHeaders.filter(
            item => !savedSorting.includes(item.key)
          );
          newSorting = [...newSorting, ...newItems];
          this.pageHeaders = newSorting;

          // need to sort selectedHeaders
          this.sortSelectedHeader();
        }

        // save last request data per view
        this.lastRequestData[this.viewType] = { ...requestData };
      }
      colsResizeableUpdate({ ms: 300 });
      // }
      this.isLoaded = true;
      this.checkColsWidth();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  parseCustomDate(dateString) {
    try {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      let [monthStr, day, year, time, offset] = dateString.split(" ");
      let [hours, minutes, seconds] = time.split(":");
      let month = months.indexOf(monthStr.replace(",", ""));
      return new Date(
        Date.UTC(year, month, day, hours, minutes, seconds) -
          parseInt(offset) * 36
      );
    } catch (e) {
      console.log("parseCustomDate:err", dateString);
      return new Date();
    }
  }

  formatDateAndTime(dateObj) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    let month = months[dateObj.getMonth()];
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();

    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  }

  showModal() {
    if (this.isAllowAccountConfig) {
      this.confirmCustomerConfigModalVisible = true;
    } else {
      this.refreshStoreModalVisible = true;
    }
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedHeaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedHeaders = newSelectedHeaders;
  }

  getExportCondition() {
    let exportCondition: any = {};
    // exportCondition.allowEndUserAll = this.allowEndUserAll;
    // exportCondition.withIdentifiersOnly = this.withIdentifiersOnly;

    // switch (this.viewType) {
    //   case "EndUser":
    //     exportCondition = {
    //       ...exportCondition,
    //       customerPO: this.filters.customerPO || "",
    //       customer: this.filters.customer || "",
    //       purchaseId: this.filters.purchaseId || "",
    //       endUser: this.filters.endUser
    //     };

    //     // collect selected info for EndUser view type
    //     if (this.selectedID.length) {
    //       const selectedEUs: any = [];
    //       for (const item of this.selectedID) {
    //         const itemDetails = item.split("||");
    //         selectedEUs.push({
    //           endUserFullName: itemDetails[0],
    //           endUserFullAddress: itemDetails[1],
    //           aID: itemDetails[2]
    //         });
    //       }
    //       exportCondition.selectedEUs = selectedEUs;
    //     }
    //     break;
    //   case "Group":
    //     exportCondition = {
    //       ...exportCondition,
    //       customerPO: this.filters.customerPO,
    //       sku: this.filters.sku,
    //       category: this.filters.category,
    //       fromPurchasedOn: this.filters.fromPurchasedOn || "",
    //       endPurchasedOn: this.filters.endPurchasedOn || "",
    //       purchaseId: this.filters.purchaseId || "",
    //       assetNumber: this.filters.assetNumber
    //     };
    //     break;
    //   default:
    //     exportCondition = {
    //       ...exportCondition,
    //       hardwareId: this.filters.hardwareId,
    //       customer: this.filters.customer,
    //       customerPO: this.filters.customerPO,
    //       endUser: this.filters.endUser,
    //       sku: this.filters.sku,
    //       category: this.filters.category,
    //       assetNumber: this.filters.assetNumber,
    //       assetTag: this.filters.assetTag,
    //       warrantyName: this.filters.warrantyName,
    //       fromStartDate: this.filters.fromStartDate,
    //       endStartDate: this.filters.endStartDate,
    //       fromExpDate: this.filters.fromExpDate,
    //       endExpDate: this.filters.endExpDate,
    //       fromLastCheckDate: this.filters.fromLastCheckDate,
    //       fromPurchasedOn: this.filters.fromPurchasedOn || "",
    //       endPurchasedOn: this.filters.endPurchasedOn || "",
    //       productLine: this.filters.productLine || "",
    //       status: this.filters.status.length
    //         ? this.filters.status.join(",")
    //         : undefined,
    //       purchaseIds: this.filters.purchaseIds.join(","),
    //       purchaseId: this.filters.purchaseId || "",
    //       endLastCheckDate: this.filters.endLastCheckDate
    //     };
    //     break;
    // }

    const lastRequestData = JSON.parse(JSON.stringify(this.lastRequestData[this.viewType]));
    lastRequestData.selectedID = this.selectedID.join(",");
    lastRequestData.selectedAll = this.selectedAll;
    if (this.selectedAll) {
      lastRequestData.excludedIDs = this.excludedIDs.join(",");
    }
    // collect selected info for EndUser view type
    if (this.viewType == "EndUser") {
      if (this.selectedID.length) {
        const selectedEUs: any = [];
        for (const item of this.selectedID) {
          const itemDetails = item.split("||");
          selectedEUs.push({
            endUserFullName: itemDetails[0],
            endUserFullAddress: itemDetails[1],
            aID: itemDetails[2]
          });
        }
        lastRequestData.selectedEUs = selectedEUs;
      }
    }

    exportCondition.lastRequestData = lastRequestData;

    return exportCondition;
  }

  async pdfDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "PDF";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    exportObj["order"] = this.sort.field ? this.sort.field : 0;
    exportObj["direction"] = this.sort.field
      ? this.sort.direction[this.directionField]
      : 0;
    exportObj["localTime"] = moment();
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async csvDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "CSV";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    exportObj["order"] = this.sort.field ? this.sort.field : 0;
    exportObj["direction"] = this.sort.field
      ? this.sort.direction[this.directionField]
      : 0;
    exportObj["localTime"] = moment();
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async htmlDownLoadFn(selectedID) {
    let exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "HTML";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["selectedAll"] = this.selectedAll;
    exportObj["order"] = this.sort.field ? this.sort.field : 0;
    exportObj["direction"] = this.sort.field
      ? this.sort.direction[this.directionField]
      : 0;
    exportObj["localTime"] = moment();
    const exportCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...exportCondition
    };

    await this.downloadProcess(exportObj);
  }

  async performExport(formats: ExportFormat[]) {
    this.loading = true;
    this.exportModalVisible = false;

    if (formats.includes(ExportFormat.csv)) {
      await this.csvDownLoadFn(this.selectedID);
    }

    if (formats.includes(ExportFormat.html)) {
      await this.htmlDownLoadFn(this.selectedID);
    }

    if (formats.includes(ExportFormat.pdf)) {
      await this.pdfDownLoadFn(this.selectedID);
    }

    this.loading = false;
  }

  printFn(selectedID) {
    var exportObj = {
      controller: "Hardware",
      FunctionName: "Export",
      uploadPath: "Inbound/apiFiles/",
      keyName: "Inbound/apiFiles/",
      userRole: sessionStorage.getItem("userRole"),
      withIdentifiersOnly: this.withIdentifiersOnly
    };
    exportObj["ExportType"] = "PDF";
    exportObj["viewType"] = this.viewType;
    exportObj["selectedID"] = selectedID.join(",");
    exportObj["Print"] = "Yes";
    exportObj["order"] = this.sort.field ? this.sort.field : 0;
    exportObj["direction"] = this.sort.field
      ? this.sort.direction[this.directionField]
      : 0;
    exportObj["localTime"] = moment();

    const printCondition = this.getExportCondition();

    exportObj = {
      ...exportObj,
      ...printCondition
    };

    this.printProcess(exportObj);
  }

  async printProcess(data) {
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", data);
      if (response.data.STATUS) {
        this.loading = false;
        let html = response.data.HTMLDATA;
        if (!html) return;

        printHtml(html);
      } else {
        if (response.data.STATUSMESSAGE) {
          notifier.alert(response.data.STATUSMESSAGE);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async deleteOrRestoreSelectedHardwares(isActive = 0) {
    this.confirmRemoveModalVisible = false;
    this.confirmRestoreModalVisible = false;

    try {
      this.loading = true;

      // group selected items by aId
      const toggleItems: any = [];
      for (const item of this.selectedItems) {
        const inList = toggleItems.find((t: any) => t.AID == item.AID);
        if (!inList) {
          toggleItems.push({
            AID: item.AID,
            HARDWAREID: this.selectedItems
              .filter((t: any) => t.AID == item.AID)
              .map(t => t.HARDWAREID)
          });
        }
      }

      let requestData: any = {
        controller: "Hardware",
        FunctionName: "DeleteRestore",
        userID: sessionStorage.getItem("userId"),
        hardwareIDs: this.selectedID,
        viewType: this.viewType,
        showDeletedFlg: this.showDeletedFlg ? 1 : 0,
        isActive: isActive, // delete if isActive = 0, restore if isActive = 1
        selectedAll: this.selectedAll,
        excludedIDs: this.excludedIDs,
        toggleItems,
        selectedItems: this.selectedItems,
        selectedTotal: this.selectedTotal,
        lastRequestData: this.lastRequestData[this.viewType]
      };
      // assign filter data for delete/restore functionality
      requestData = {
        ...requestData,
        hardwareId: this.filters.hardwareId || "",
        endUserNotNull: this.filters.endUserNotNull ? 1 : 0,
        lastOnlineNotNull: this.filters.lastOnlineNotNull ? 1 : 0,
        fromLastOnline: this.filters.fromLastOnline || "",
        endLastOnline: this.filters.endLastOnline || "",
        withIdentifiersOnly: this.withIdentifiersOnly,
        purchaseIds: this.filters.purchaseIds
          ? this.filters.purchaseIds.join(",")
          : "-1",
        purchaseId: this.filters.purchaseId || "",
        status: this.filters.status ? this.filters.status.join(",") : "-1",
        selectedAccountDs: this.filters.selectedAccs
          ? this.filters.selectedAccs.join(",")
          : "-1",
        deploymentId: this.filters.deploymentId || "",
        customer: this.filters.customer || "",
        customerPO: this.filters.customerPO || "",
        endUser: this.filters.endUser || "",
        fromEuCreationDate: this.filters.fromEuCreationDate || "",
        endEuCreationDate: this.filters.endEuCreationDate || "",
        sku: this.filters.sku || "",
        category: this.filters.category || "",
        categoryIds: this.filters.categoryIds
          ? this.filters.categoryIds.join(",")
          : "-1",
        assetNumber: this.filters.assetNumber || "",
        assetTag: this.filters.assetTag || "",
        warrantyName: this.filters.warrantyName || "",
        fromStartDate: this.filters.fromStartDate || "",
        endStartDate: this.filters.endStartDate || "",
        fromExpDate: this.filters.fromExpDate || "",
        endExpDate: this.filters.endExpDate || "",
        fromPurchasedOn: this.filters.fromPurchasedOn || "",
        endPurchasedOn: this.filters.endPurchasedOn || "",
        fromLastCheckDate: this.filters.fromLastCheckDate || "",
        endLastCheckDate: this.filters.endLastCheckDate || "",
        productLine: this.filters.productLine || "",
        deploymentName: this.filters.deploymentName || ""
      };
      // append custom fields search
      const customData = {};
      for (const i in this.filters) {
        if (i.startsWith("customData") && this.filters[i] != "") {
          customData[i] = this.filters[i];
        }
      }
      requestData.customData = customData;
      const response = await axios.post(
        dataURL + "?ReturnType=JSON",
        requestData
      );

      if (response.data.STATUS == 1) {
        this.selectedID = [];
        this.selectedItems = [];
        this.selectedAll = false;
        this.selectPageAll = false;
        this.excludedIDs = [];
        this.totalHardwares = [];
        this.hardwareCheckAll = false;
        this.totalHardwareItems = 0;
        this.newPageHardware = [];

        if (isActive === 1) {
          await this.fetchData(); // reload deleted data
        } else {
          await this.fetchData(); // reload data
        }
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message) {
          notifier.alert(message);
        }
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      this.loading = false;
    }
  }

  searchBy(field) {
    const words = field.trim().split(/\s+/);
    if (words.length === 1) {
      if (this.filters[field] == "") {
        return;
      }
      if (this.filters[field].length) {
        this.searchFilters[field] = this.filters[field];
      }
      this.pageNumber = 1;
      this.fetchData();
    } else {
      // this.filters.endUser = "notnull";
      this.fetchData();
    }
  }

  resetBy(field) {
    if (field == "all") {
      this.$router.replace({
        path: this.$route.path,
        query: {} // Empty the query object
      });

      this.fetchData();
    } else {
      if (this.filters[field] == "") {
        return;
      }
      if (this.searchFilters[field].length) {
        this.searchFilters[field] = "";
      }
      this.pageNumber = 1;
      this.filters[field] = "";
      this.fetchData();
    }
  }

  resetDeploymentName() {
    this.pageNumber = 1;
    this.filters.deploymentName = "";
    this.searchFilters.deploymentName = "";
    this.filters.deploymentId = 0;
    this.searchFilters.deploymentId = 0;
    this.fetchData();
  }

  updateValue(field) {
    if (this.filters[field] == "") {
      this.pageNumber = 1;
      this.fetchData();
    }
  }

  async downloadProcess(data) {
    // const downLoadRes = getRouteData(data);
    // const response = await downLoadRes;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", data);
      if (response.data.STATUS) {
        downloadFileUrl(response.data.S3URL);
      }
      if (response.data.STATUS !== 1) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
      if (response.data.ERROR) {
        console.log(response.data.ERROR);
      }
    } catch (err) {
      console.log(err);
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber < 1) {
      this.pageNumber = 1;
      return;
    }

    if (this.pageNumber <= this.details.TOTALPAGES && this.pageNumber >= 1) {
      if (this.showDeletedFlg) {
        this.fetchData();
      } else {
        this.fetchData();
      }
    }
  }

  // allowRemoveColumn(header) {
  //   // if (this.currentRole != "Reseller") {
  //   //   return false;
  //   // }
  //   let ret = false;
  //   if (
  //     this.viewType == "All" &&
  //     ![
  //       "HARDWAREID",
  //       "SKU",
  //       "HDESCRIPTION",
  //       "ASSETNUMBER",
  //       "CATEGORYNAME",
  //       "POID",
  //       "HSTATUS"
  //     ].includes(header)
  //   ) {
  //     ret = true;
  //   } else if (
  //     this.viewType == "EndUser" &&
  //     !["ENDUSER", "DEVICES"].includes(header)
  //   ) {
  //     ret = true;
  //   } else if (
  //     this.viewType == "Group" &&
  //     !["SKU", "HDESCRIPTION"].includes(header)
  //   ) {
  //     ret = true;
  //   }

  //   return ret;
  // }

  removeColumn(header) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerCustomer) {
      const valIdx: string = this.headerCustomer[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader(header);
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      // for (var prop in this.pageHeaders) {
      //   if (this.pageHeaders[prop] === key) {
      //     // or do whatever you need with the value
      //     ret = this.pageHeaders[prop];
      //   }
      // }
      const inList = this.pageHeaders.find(
        item => item.key.toLowerCase() == key.toLowerCase()
      );
      if (inList) {
        ret = inList.value;
      }
    }
    return ret;
  }

  async sortBy(field, directionField) {
    this.sort.field = field;
    this.sort.direction[directionField] =
      this.sort.direction[directionField] == 1 ? 2 : 1;
    this.directionField = directionField;

    //reset
    for (const dirField in this.sort.direction) {
      if (dirField === directionField) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async addHeader(c) {
    const addedCol = this.selectedHeaders.includes(c);
    colsResizeableReset();
    var sortedHeaders: string[] = [];
    for (var customerHeader in this.headerCustomer) {
      var customerIndex: string = this.headerCustomer[customerHeader];
      const selectedIndex = this.selectedHeaders.includes(customerIndex);
      if (selectedIndex) {
        sortedHeaders.push(customerIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.sortSelectedHeader();
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "hardware",
      viewType: this.viewType
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchData();
    }
    colsResizeable();
    if (!$("body").hasClass("expand-full")) {
      $(".tablesorter.hasResizable").attr(
        "data-width",
        $(".tablesorter.hasResizable").width()
      );
    }
    this.checkColsWidth();
  }

  hardwareCheckAllChange() {
    this.hardwareCheckAll = !this.hardwareCheckAll;
    if (!this.hardwareCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
    this.checkExcludedIDs(
      this.items,
      this.viewType == "EndUser" ? "LINEKEY" : "HARDWAREID"
    );
  }

  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    // this.showFilter = 0;

    if (this.selectPageAll == true) {
      const selectedPage = this.pageNumber;
      this.newPageHardware.push(selectedPage);
      for (const item of this.items) {
        const val = this.viewType == "EndUser" ? item.LINEKEY : item.HARDWAREID;
        if (!this.excludedIDs.includes(val)) {
          if (this.selectedID.indexOf(val) == -1) {
            this.selectedID.push(val);
            this.totalHardwares.push(val);
            this.updateSelected(item);
          }
        }
      }
    } else {
      for (const item of this.items) {
        const val = this.viewType == "EndUser" ? item.LINEKEY : item.HARDWAREID;
        let selectedIDIndex = this.selectedID.indexOf(val);
        let totalLedgersIndex = this.totalHardwares.indexOf(val);
        this.selectedID.splice(selectedIDIndex, 1);
        this.totalHardwares.splice(totalLedgersIndex, 1);
        this.updateSelected(item);
      }
      let newPageLedgerIndex = this.newPageHardware.indexOf(this.pageNumber);
      this.newPageHardware.splice(newPageLedgerIndex, 1);
    }
  }

  checkExcludedIDs(items, key, hardwareID = 0) {
    if (this.selectedAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.hardwareCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.totalHardwareItems) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if (hardwareID) {
      if (this.selectedID.includes(hardwareID)) {
        this.totalHardwares.push(hardwareID);
      } else {
        this.$delete(
          this.totalHardwares,
          this.totalHardwares.findIndex(id => id == hardwareID)
        );
      }
    }
    if (
      this.items.length == this.totalHardwares.length &&
      this.items.length != 0
    ) {
      this.hardwareCheckAll = true;
      this.selectPageAll = true;
    } else {
      this.hardwareCheckAll = false;
      this.selectPageAll = false;
    }
  }

  getSelectedTotal(total) {
    let ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }
    return ret;
  }

  get selectedTotal() {
    let ret = 0;
    if (this.selectedAll) {
      ret = this.getSelectedTotal(this.totalAll);
    } else if (this.selectedID.length) {
      ret = this.selectedID.length;
    }
    return ret;
  }

  async handleShowDeleted() {
    this.pageNumber = 1;
    this.viewType = "All";
    this.deletedView = true;
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.selectPageAll = false;
    this.excludedIDs = [];
    this.totalHardwares = [];
    this.hardwareCheckAll = false;
    this.totalHardwareItems = 0;
    this.newPageHardware = [];
    this.filters.status = [0];
    this.filters.statusNames = ["Deleted"];

    await this.fetchData();
    this.showDeletedFlg = true;
  }

  getArraysIntersection(getHardwaresOne, getHardwaresTwo) {
    return getHardwaresOne.filter(function (n) {
      return getHardwaresTwo.indexOf(n) !== -1;
    });
  }

  modalHardwareDetails: {
    show: boolean;
    isLoading: boolean;
    data: any;
    reset: Function;
  } = {
    show: false,
    isLoading: false,
    data: {},
    reset: () => {
      this.modalHardwareDetails.data = {
        id: 0,
        name: "",
        imageURL: "",
        hardwareImages: [],
        sku: "",
        statusName: "",
        statusId: 0,
        order: {
          purchaseId: 0,
          poId: "N/A"
        },
        customer: {
          aId: 0,
          aName: "N/A",
          accountId: ""
        },
        deployment: {
          deploymentId: 0,
          deploymentName: "N/A"
        },
        categoryId: 0,
        categoryName: "",
        carrier: "",
        trackingNumber: "N/A",
        endUser: {
          userId: 0,
          name: "N/A",
          addr: "",
          cityZip: ""
        },
        identifiers: [
          {
            name: "VAR360 Hardware ID",
            type: "hardware_id",
            value: "N/A"
          },
          {
            name: "Serial Number",
            type: "asset_number",
            value: ""
          },
          {
            name: "Asset Tag",
            type: "asset_tag",
            value: ""
          }
        ],
        logs: [],
        warranty: [
          {
            name: "Warranty Name",
            type: "warranty_name",
            value: "N/A"
          },
          {
            name: "Start Date",
            type: "start_date",
            value: ""
          },
          {
            name: "End Date",
            type: "end_date",
            value: ""
          },
          {
            name: "Last Checked",
            type: "last_checked",
            value: ""
          }
        ],
        warrantyResponse: ""
      };
    }
  };

  checkDuplicateExists(header, item, index) {
    this.editedItem = index;
    let tmpheader = "NEW_" + header;
    if (item[tmpheader] != item[header] && item[tmpheader] == "") {
      this.confirmRemoveSerialNumber = true;
      this.inputField = header;
      this.editedItem = index;
    } else if (item[tmpheader] != item[header] && item[tmpheader] != "") {
      const response = axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        header: header,
        value: item[tmpheader],
        hardwareId: item.HARDWAREID,
        action: "checkDuplicate"
      });
      response.then(response => {
        if (response.data.STATUS === 1) {
          this.saveHardware(index);
        } else {
          this.warningMessage = response.data.STATUSMESSAGE;
          this.inputField = response.data.header;
        }
      });
    }
  }

  async showModalDetails(
    item,
    preventClick = false,
    options: any = {},
    e: any
  ) {
    if (preventClick) return;
    if (e && $(e.target).hasClass("eu-hardware-link")) {
      return;
    }

    if (this.viewType == "Group" && (item.QUANTITY || 0) > 1) {
      // back to "complete view", filter by poId, sku
      // this.viewType = "All";
      // this.filters.sku = this.searchFilters.sku = item.SKU;
      // this.filters.customerPO = this.searchFilters.customerPO = item.POID;
      //this.fetchData();
      if (this.$route.query.sku) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            sku: item.SKU,
            poId: item.POID,
            view: "All",
            tok: ApiHelper.getRandomNumber(100000, 999999) + ""
          }
        });
      } else {
        this.$router.push({
          name: this.$route.name,
          query: {
            sku: item.SKU,
            poId: item.POID,
            view: "All",
            tok: ApiHelper.getRandomNumber(100000, 999999) + ""
          }
        });
      }

      return;
    } else if (this.viewType == "EndUser" && (item.QUANTITY || 0) > 1) {
      // this.viewType = "All";
      // this.filters.endUser = this.searchFilters.endUser = item.ENDUSERFULLNAME;
      // this.fetchData();
      const query: any = {
        endUser: item.ENDUSERFULLNAME,
        view: "All",
        purchaseIds: item.PURCHASEIDS || "",
        tok: ApiHelper.getRandomNumber(100000, 999999) + ""
      };
      if (options.filters && (options.filters.category || "") != "") {
        query.category = options.filters.category;
      }

      if (this.$route.query.endUser) {
        this.$router.replace({
          name: this.$route.name,
          query
        });
      } else {
        this.$router.push({
          name: this.$route.name,
          query
        });
      }
      return;
    }

    this.modalHardwareDetails.isLoading = true;
    this.modalHardwareDetails.reset();
    const response = await ApiHelper.callApi("post", {
      controller: "Hardware",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: item.HARDWAREID,
      aId: item.AID || 0
    });
    this.modalHardwareDetails.show = true;
    if (response.STATUS != 1) {
      notifier.alert(response.STATUSMESSAGE);
      this.modalHardwareDetails.show = false;
      return;
    }

    const hardwareData = response.hardware || {};
    const hardwareLogs: any = response.hardwareLogs || [];

    // @Todo() Update product details data
    this.modalHardwareDetails.data = {
      ...this.modalHardwareDetails.data,
      id: hardwareData.HARDWAREID || 0,
      hardwareUUID: hardwareData.HARDWAREUUID || "",
      imageURL: hardwareData.IMAGEURL || "",
      hardwareStatus:
        hardwareData.ISACTIVE === 0
          ? "Deleted"
          : hardwareData.ISACTIVE === 1
          ? "Active"
          : hardwareData.ISACTIVE === 2
          ? "Retired"
          : "N/A",
      hardwareImages:
        (hardwareData.HARDWAREIMAGES || []).map(
          hImage => `${dataURL}/${hImage}`
        ) || [],
      name: hardwareData.HDESCRIPTION || "",
      sku: hardwareData.HARDWARESKU || "",
      statusName: hardwareData.HSTATUS || "",
      categoryId: hardwareData.CATEGORYID || 0,
      categoryName: hardwareData.CATEGORYNAME || "",
      categories: hardwareData.categories || [],
      order: {
        purchaseId: hardwareData.PURCHASEID || 0,
        poId: hardwareData.POID || "N/A"
      },
      customer: {
        aId: hardwareData.AID || 0,
        aName: hardwareData.ANAME || "N/A",
        accountId: hardwareData.ACCOUNTID || ""
      },
      deployment: {
        deploymentId: hardwareData.DEPLOYMENTID || 0,
        deploymentName: hardwareData.DEPLOYMENTNAME || "N/A"
      },
      endUser: {
        euID: hardwareData.EUID || 0,
        fname: hardwareData.UFNAME || "N/A",
        lname: hardwareData.ULNAME || "",
        phone: hardwareData.UPHONE || "",
        account: hardwareData.UACCOUNT || "",
        lastonline: hardwareData.LOGTS || "",
        address1: hardwareData.ADDRESS1 || "",
        address2: hardwareData.ADDRESS2 || "",
        city: hardwareData.CITY || "",
        state: hardwareData.STATE || "",
        zip: hardwareData.ZIPCODE || "",
        email: hardwareData.EMAIL || "",
        contactEuId: hardwareData.CONTACTEUID || 0
      },
      logs: {
        mostRecent: hardwareData.LOGS.length
          ? new Date(
              hardwareData.LOGS[hardwareData.LOGS.length - 1].LOGTS
            ).toLocaleString()
          : "",
        last10: hardwareData.LOGS.map(log => ({
          ...log,
          LOGTS: new Date(log.LOGTS).toLocaleString()
        }))
      },
      carrier: hardwareData.CARRIER || "",
      trackingNumber: hardwareData.TRACKINGNUMBER || "N/A",
      warrantyResponse: hardwareData.WARRANTYRESPONSE || ""
    };
    // identifiers data
    for (const item of this.modalHardwareDetails.data.identifiers) {
      if (item.type == "hardware_id") {
        item.value = hardwareData.HARDWAREID || 0;
      } else if (item.type == "asset_number") {
        item.value = hardwareData.ASSETNUMBER || "";
      } else if (item.type == "asset_tag") {
        item.value = hardwareData.ASSETTAG || "";
      }
    }
    // warranty data
    for (const item of this.modalHardwareDetails.data.warranty) {
      if (item.type == "warranty_name") {
        item.value = hardwareData.WARRANTYNAME || "";
      } else if (item.type == "start_date") {
        item.value = hardwareData.STARTDATEFORMATTED || "";
      } else if (item.type == "end_date") {
        item.value = hardwareData.WARRENTYEXPFORMATTED || "";
      } else if (item.type == "last_checked") {
        item.value = hardwareData.LASTCHECKEDFORMATTED || "";
      }
    }
    setTimeout(() => {
      this.modalHardwareDetails.isLoading = false;
    }, 500);
  }

  // @Todo() update search function
  searchChange() {}

  toggleCustomView(currStatus) {
    this.showDeletedFlg = false;
    this.viewType = currStatus;
    this.deletedView = false;
    this.selectedView = true;
    this.pageNumber = 1;
    this.allowEndUserAll = false;
    this.selectedID = [];
    this.selectedItems = [];
    this.isFirstLoad = true;
    if (currStatus == "All") {
      this.filters.status = [1, 2];
      this.filters.statusNames = ["Active", "Retired"];
    }
    if (currStatus == "Group") {
      this.editableRows = false;
    }
    this.filters = {
      ...this.filters,
      hardwareId: "",
      customerPO: "",
      sku: "",
      category: "",
      assetNumber: "",
      assetTag: "",
      customer: "",
      endUser: "",
      endUserNotNull: false,
      warrantyName: "",
      fromStartDate: "",
      endStartDate: "",
      fromExpDate: "",
      endExpDate: "",
      fromPurchasedOn: "",
      endPurchasedOn: "",
      fromLastCheckDate: "",
      endLastCheckDate: "",
      fromLastOnline: "",
      endLastOnline: "",
      lastOnlineNotNull: false,
      purchaseIds: [],
      purchaseId: "",
      productLine: ""
    };

    this.searchFilters = {
      ...this.searchFilters,
      hardwareId: "",
      customerPO: "",
      sku: "",
      category: "",
      assetNumber: "",
      assetTag: "",
      customer: "",
      endUser: "",
      warrantyName: "",
      fromStartDate: "",
      endStartDate: "",
      fromPurchasedOn: "",
      endPurchasedOn: "",
      fromLastOnline: "",
      endLastOnline: "",
      fromExpDate: "",
      endExpDate: "",
      fromLastCheckDate: "",
      endLastCheckDate: "",
      purchaseIds: [],
      purchaseId: "",
      productLine: ""
    };
    if (currStatus == "EndUser") {
      const findSelectedHeaders = this.allHardwareHeaders[this.viewType] || "";
      if (findSelectedHeaders) {
        this.selectedHeaders = findSelectedHeaders.split(",");
      }
      this.sort.field = 0;
      this.directionField = "";
      this.$router.replace({
        name: this.$route.name,
        query: {
          viewtype: "EndUser",
          tok: ApiHelper.getRandomNumber(100000, 999999) + ""
        }
      });
    } else {
      this.fetchData();
    }
  }

  getFilterValue(header) {
    let searchKey: string | undefined = "";
    switch (header) {
      case "HARDWAREID":
        searchKey = this.searchFilters.hardwareId;
        break;
      case "SKU":
        searchKey = this.searchFilters.sku;
        break;
      case "ASSETNUMBER":
        searchKey = this.searchFilters.assetNumber;
        break;
      case "ASSETTAG":
        searchKey = this.searchFilters.assetTag;
        break;
      case "CATEGORYNAME":
        // searchKey = this.searchFilters.category;
        if (this.filters.categoryNames && !this.filters.categoryNames.length) {
          searchKey = "None";
        } else if (
          this.filters.categoryNames &&
          !(this.filters.categoryNames.length == this.categories.length)
        ) {
          searchKey = this.filters.categoryNames
            .map(item => item || "Unknown")
            .join(", ");
        } else {
          searchKey = "";
        }
        break;
      case "PURCHASEID":
        searchKey = this.searchFilters.purchaseId;
        break;
      case "POID":
        searchKey = this.searchFilters.customerPO;
        break;
      case "ACCOUNTNAME": {
        const arr: string[] = [];
        if (this.searchFilters.customer) {
          arr.push(`Customer: ${this.searchFilters.customer}`);
        }
        if (this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccountsName}`);
        }
        searchKey = arr.join("; ");
        break;
      }
      case "ENDUSER":
        {
          const tmpArr: string[] = [];
          if (this.filters.endUserNotNull) {
            tmpArr.push("Value Exists");
          }
          if (this.searchFilters.endUser) {
            tmpArr.push(this.searchFilters.endUser);
          }
          searchKey = tmpArr.join(" - ");
        }

        break;
      case "WARRANTYNAME":
        searchKey = this.searchFilters.warrantyName;
        break;
      case "STARTDATEFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromStartDate,
          this.searchFilters.endStartDate
        );
        break;
      case "WARRENTYEXPFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromExpDate,
          this.searchFilters.endExpDate
        );
        break;
      case "PURCHASEDON":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromPurchasedOn,
          this.searchFilters.endPurchasedOn
        );
        break;
      case "EUCREATIONDATE":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromEuCreationDate,
          this.searchFilters.endEuCreationDate
        );
        break;
      case "LASTONLINE":
        {
          const tmpArr: string[] = [];
          if (this.filters.lastOnlineNotNull) {
            tmpArr.push("Value Exists");
          }
          const tmpSearchKey = getDateRangeFilter(
            this.searchFilters.fromLastOnline,
            this.searchFilters.endLastOnline
          );
          if (tmpSearchKey) {
            tmpArr.push(tmpSearchKey);
          }
          searchKey = tmpArr.join(" - ");
        }
        break;
      case "LASTCHECKEDFORMATTED":
        searchKey = getDateRangeFilter(
          this.searchFilters.fromLastCheckDate,
          this.searchFilters.endLastCheckDate
        );
        break;
      case "PRODUCTLINE":
        searchKey = this.filters.productLine;
        break;
      case "HSTATUS":
        // if (this.filters.statusNames && !this.filters.statusNames.length)
        //   searchKey = "None";
        // else if (
        //   !this.deletedView &&
        //   this.filters.statusNames &&
        //   !(this.filters.statusNames.length == 3)
        // )
        //   searchKey = this.filters.statusNames
        //     .map(item => item || "Unknown")
        //     .join(", ");
        // else if (
        //   (this.deletedView && this.filters.statusNames.length != 1) ||
        //   !this.filters.statusNames.includes("Deleted")
        // )
        //   searchKey = this.filters.statusNames
        //     .map(item => item || "Unknown")
        //     .join(", ");

        searchKey = this.deletedView
          ? ""
          : this.searchFilters.statusNames.join(", ");
        // clear if default (active and retired)
        if (
          !this.deletedView &&
          this.searchFilters.status.length == 2 &&
          this.searchFilters.status.includes(1) &&
          this.searchFilters.status.includes(2)
        ) {
          searchKey = "";
        }
        break;
      case "DEPLOYMENTNAME":
        searchKey = this.searchFilters.deploymentName;
        break;
      case "LIFECYCLE":
        if (this.filters.lifeCycleNames.length != this.hardwareLifecycles.length) {
          searchKey = this.filters.lifeCycleNames.join(', ');
        }
        break;
      default: {
        // if is custom data filter
        if (
          header.indexOf("customData") != -1 &&
          (this.customFieldInfo(header).ISSEARCH || 0)
        ) {
          if (this.customFieldInfo(header).ISDATE || 0) {
            const fieldId = header.replace(/customData|_fromDate|_toDate/g, "");

            // is date
            searchKey = getDateRangeFilter(
              this.searchFilters[`customData${fieldId}_fromDate`] || "",
              this.searchFilters[`customData${fieldId}_toDate`] || ""
            );
          } else if (this.searchFilters[header]) {
            // text
            searchKey = this.searchFilters[header];
          }
        }
      }
    }

    return searchKey ? searchKey : "";
  }

  resetColumn(header) {
    this.loading = true;
    switch (header) {
      case "HARDWAREID":
        this.resetBy("hardwareId");
        break;
      case "SKU":
        this.resetBy("sku");
        break;
      case "ASSETNUMBER":
        this.resetBy("assetNumber");
        break;
      case "ASSETTAG":
        this.resetBy("assetTag");
        break;
      case "CATEGORYNAME":
        // this.resetBy("category");
        this.resetCategoryList();
        break;
      case "PURCHASEID":
        this.resetBy("purchaseId");
        break;
      case "POID":
        this.resetBy("customerPO");
        break;
      case "ACCOUNTNAME":
        this.filters.customer = this.searchFilters.customer = "";
        this.resetAcc(true);
        break;
      case "ENDUSER":
        this.filters.endUserNotNull = false;
        if (this.filters.endUser == "") {
          this.pageNumber = 1;
          this.fetchData();
        } else {
          this.resetBy("endUser");
        }
        break;
      case "WARRANTYNAME":
        this.resetBy("warrantyName");
        break;
      case "STARTDATEFORMATTED":
        this.resetSearchByDate("fromStartDate", "endStartDate");
        break;
      case "WARRENTYEXPFORMATTED":
        this.resetSearchByDate("fromExpDate", "endExpDate");
        break;
      case "PURCHASEDON":
        this.resetSearchByDate("fromPurchasedOn", "endPurchasedOn");
        break;
      case "EUCREATIONDATE":
        this.resetSearchByDate("fromEuCreationDate", "endEuCreationDate");
        break;
      case "LASTONLINE":
        this.filters.lastOnlineNotNull = false;
        this.resetSearchByDate("fromLastOnline", "endLastOnline");
        break;
      case "LASTCHECKEDFORMATTED":
        this.resetSearchByDate("fromLastCheckDate", "endLastCheckDate");
        break;
      case "PRODUCTLINE":
        this.resetBy("productLine");
        break;
      case "HSTATUS":
        this.resetStatusList();
        break;
      case "DEPLOYMENTNAME":
        this.resetDeploymentName();
        break;
      case "LIFECYCLE":
        this.resetLifeCycle();
        break;
      default: {
        this.loading = true;

        // if reset custom data filter
        if (header.indexOf("customData") != -1) {
          if (this.customFieldInfo(header).ISDATE || 0) {
            const fieldId = header.replace(/customData|_fromDate|_toDate/g, "");
            this.resetSearchByDate(
              `customData${fieldId}_fromDate`,
              `customData${fieldId}_toDate`
            );
          } else {
            this.resetBy(header);
          }
        }
        // break;
      }
    }
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortHardwareColumns",
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key)
    };
    await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }

  getCityZip(hardwareData) {
    let ret: string[] = [];
    if (hardwareData.UCITY) {
      ret.push(hardwareData.UCITY);
    }
    if (hardwareData.USTATE || hardwareData.UZIP) {
      ret.push(
        `${hardwareData.USTATE || ""} ${hardwareData.UZIP || ""}`.trim()
      );
    }

    return ret.join(", ");
  }

  updateList(item) {
    const inList = this.items.find(t => t.HARDWAREID == item.hardwareId);
    if (inList) {
      if (item.type == "asset_tag") {
        inList.ASSETTAG = item.value;
        inList.NEW_ASSETTAG = item.value;
      } else if (item.type == "asset_number") {
        inList.ASSETNUMBER = item.value;
        inList.NEW_ASSETNUMBER = item.value;
      } else if (item.type == "warranty_name") {
        inList.WARRANTYNAME = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "start_date") {
        inList.STARTDATEFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      } else if (item.type == "end_date") {
        inList.WARRENTYEXPFORMATTED = item.value;
        inList.LASTCHECKEDFORMATTED = item.lastChecked;
      }
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  async searchByDate(fromField, endField) {
    this.pageNumber = 1;
    if (
      (this.filters[fromField] == undefined || this.filters[fromField] == "") &&
      (this.filters[endField] == "" || this.filters[endField] == undefined)
    ) {
      return;
    } else {
      this.searchFilters[fromField] = this.filters[fromField];
      this.searchFilters[endField] = this.filters[endField];
      await this.fetchData();
    }
  }

  async resetSearchByDate(fromField, endField) {
    this.pageNumber = 1;
    this.filters[fromField] = this.searchFilters[fromField] = "";
    this.filters[endField] = this.searchFilters[endField] = "";
    await this.fetchData();
  }

  getRangeFilter(from, to) {
    const arr: string[] = [];
    if (from) {
      arr.push(`From: ${from}`);
    }
    if (to) {
      arr.push(`To: ${to}`);
    }
    return arr.join("; ");
  }

  async checkWithIdentifiersOnly() {
    this.withIdentifiersOnly = !this.withIdentifiersOnly;
    await this.fetchData();
  }

  searchByHeader() {
    let filteredHeader = this.pageHeaders.filter(
      (item: any) =>
        item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1
    );
    if (this.viewType != "EndUser") {
      filteredHeader = filteredHeader.filter(
        item =>
          !["mainmodel", "eucreationdate"].includes(item.key.toLowerCase())
      );
    }
    return filteredHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }

  updateSelected(item) {
    if (this.viewType != "All") return;

    if (this.selectedID.includes(item.HARDWAREID)) {
      // check
      const inList = this.items.find(t => t.HARDWAREID == item.HARDWAREID);
      this.selectedItems.push(inList);
    } else {
      // uncheck
      this.selectedItems = this.selectedItems.filter(
        t => t.HARDWAREID != item.HARDWAREID
      );
    }
  }

  checkColsWidth() {
    setTimeout(() => {
      if (
        (($(".tablesorter.hasResizable").attr("data-width") === "" &&
          $(".tablesorter.hasResizable").width() > 1440) ||
          $(".tablesorter.hasResizable").attr("data-width") > 1440) &&
        !$("body").hasClass("expand-full")
      ) {
        $("body").addClass("expand-full");
        $("span.expand-full").addClass("overflowed");
      } else if ($("body").hasClass("expand-full")) {
        if ($(".tablesorter.hasResizable").attr("data-width") > 1440) {
          $("body").addClass("expand-full");
          $("span.expand-full").addClass("overflowed");
        }
      }
    }, 1);
  }

  ifOverflowed() {
    if ($("body").hasClass("expand-full")) {
      setTimeout(() => {
        $("body").removeClass("expand-full");
        $("span.expand-full").removeClass("overflowed");
        if ($(".tablesorter.hasResizable").width() > 1440) {
          $("body").addClass("expand-full");
          $("span.expand-full").addClass("overflowed");
          notifier.alert(
            "Error: You have too many columns to return to standard view. Please remove columns to return to standard view"
          );
        }
      }, 1);
    }
  }

  showFilter = 0;
  selectAll(selectedAll = true) {
    this.hardwareCheckAll = selectedAll;
    this.excludedIDs = [];
    this.selectedAll = selectedAll;
    this.selectedID = [];
    this.selectedItems = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (const item of this.items) {
        const val = this.viewType == "EndUser" ? item.LINEKEY : item.HARDWAREID;
        if (!this.excludedIDs.includes(val)) {
          this.selectedID.push(val);
          this.updateSelected(item);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }

  toggleEdit() {
    if (this.editableRows === true) {
      this.editableRows = false;
    } else {
      this.editableRows = true;
      this.initPhoneValidation();
      this.maskPhone();
    }
  }

  updateCategory(categoryID, hardwareID) {
    const index = this.items.findIndex(item => item.HARDWAREID === hardwareID);
    const categoryName = this.categories.filter(item => item.ID === categoryID);

    if (index !== -1) {
      this.$set(this.items[index], "CATEGORYID", categoryID);
      this.$set(this.items[index], "CATEGORYNAME", categoryName[0].TEXT);
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  updateStatus(statusID, hardwareID) {
    // console.log(statusID, hardwareID);
    const index = this.items.findIndex(item => item.HARDWAREID === hardwareID);

    if (index !== -1) {
      this.$set(this.items[index], "ISACTIVE", HardwareStatusValue[statusID]);
      this.$set(
        this.items[index],
        "ISACTIVETEXT",
        HardwareStatusValue[statusID]
      );
    } else {
      console.log("No item found with HARDWAREID:", hardwareID);
    }
  }

  async saveHardware(index, type = "") {
    this.isSaving = true;
    this.confirmRemoveSerialNumber = false;
    const item = this.items[index];
    item.ASSETNUMBER = item.NEW_ASSETNUMBER;
    item.ASSETTAG = item.NEW_ASSETTAG;
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      action: "inlineEdit",
      hardware: item,
      aId: item.AID || 0
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.isSaving = false;

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      Vue.set(
        this.items[index],
        "LASTCHECKEDFORMATTED",
        mm + "/" + dd + "/" + yyyy
      );

      // sync category for same order sku
      if (type == "category" && (item.POLIID || 0)) {
        const inList = this.items.filter(
          t => (t.POLIID || 0) == item.POLIID && t.HARDWAREID != item.HARDWAREID
        );
        for (const t of inList) {
          this.updateCategory(item.CATEGORYID, t.HARDWAREID);
        }
      }
    } else if (response.data.STATUS == 0) {
      notifier.alert(response.data.STATUSMESSAGE);
      this.isSaving = false;
    }
  }

  validatePhone(phone) {
    let valid = true;
    const numbers = phone.replace(/\D/g, "");
    if (numbers) {
      this.warningMessage = "Invalid phone number";
      this.inputField = "PHONE";
      valid = numbers.length != 10 ? false : true;
    }
    return valid;
  }

  maskPhone() {
    this.$nextTick(() => {
      $(this.$refs.inputMask).each(function (i, obj) {
        Inputmask("(999) 999-9999", {}).mask(obj);
      });
    });
  }

  async saveUser(index, field = "") {
    const item = this.items[index];
    const tmpPhone = item.NEW_PHONE || "";
    const tmpEmail = item.NEW_EMAIL || "";
    let validPhone = tmpPhone.replace(/\D/g, "").length ? tmpPhone : "";
    this.editedItem = index;
    let isValid = false;
    var result = await this.$validator.validateAll();
    if (!result) {
      return;
    }
    let phoneValidation = this.validatePhone(tmpPhone);
    if (!phoneValidation) {
      return;
    }
    this.warningMessage = "";

    if (validPhone != item.PHONE) {
      isValid = true;
    }
    if (tmpEmail != item.EMAIL) {
      isValid = true;
    }
    if (!isValid) {
      return false;
    }
    // set new values
    item.PHONE = tmpPhone;
    item.EMAIL = tmpEmail;

    this.isSaving = true;
    const requestObj: any = {
      controller: "Deployments",
      FunctionName: "UpdateHardware",
      action: "saveEndUser",
      hardware: item,
      aId: item.AID || 0
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);
    if (response.data.STATUS == 1) {
      this.isSaving = false;
      ApiHelper.showSuccessMessage("Updated " + field + " successfully");
    } else if (response.data.STATUS == 0) {
      notifier.alert(response.data.STATUSMESSAGE);
      this.isSaving = false;
    }
  }

  updateProgressBar() {
    const newWidth = (this.checkedWarranties / this.totalWarranties) * 100;
    this.animateProgressBar(newWidth);
  }

  animateProgressBar(targetWidth) {
    const duration = 25000;
    const startTime = performance.now();
    const startWidth = this.progressBarWidth;

    // Cancel any ongoing animation and jump to the targetWidth
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId); // Cancel the previous animation
    }

    // Update immediately if we want to skip animation
    if (this.progressBarWidth === targetWidth) {
      return; // No need to animate if already at the target
    }

    const animate = currentTime => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      this.progressBarWidth =
        startWidth + (targetWidth - startWidth) * progress;

      if (progress < 1) {
        // Store the ID so we can cancel it if needed
        this.animationFrameId = requestAnimationFrame(animate);
      } else {
        // Animation finished
        this.animationFrameId = null;
      }
    };

    // Jump immediately if the function is called again before the animation finishes
    if (targetWidth !== this.progressBarWidth) {
      this.progressBarWidth = targetWidth;
    }

    // Start the new animation
    this.animationFrameId = requestAnimationFrame(animate);
  }

  async checkWarranties() {
    let error = 0;
    this.totalWarranties = this.selectedItems.length;
    this.checkingWarranties = true;

    for (var counter = 0; counter < this.totalWarranties; counter++) {
      this.checkedWarranties = counter + 1;
      this.updateProgressBar();

      let result = await this.checkWarranty(this.selectedItems[counter]);
      if (result != 1) {
        error++;
      }
    }

    this.checkingWarranties = false;

    await this.fetchData();
  }

  async checkWarranty(item) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "carepacks",
        serialNumber: item["ASSETNUMBER"],
        ProductSku: item["SKU"],
        hardwareID: item["HARDWAREID"]
      });

      if (
        response.data.return !== undefined &&
        response.data.return["Service type"] !== undefined &&
        response.data.return["Service type"] !== ""
      ) {
        const updateReturn = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Deployments",
          FunctionName: "UpdateHardware",
          action: "All",
          serialNumber: item["ASSETNUMBER"],
          ProductSku: item["SKU"],
          hardwareId: item["HARDWAREID"],
          hardwareUUID: item["HARDWAREUUID"],
          aId: item["AID"],
          warrantyName: response.data.return["Service type"],
          startDate: response.data.return["Start date"],
          endDate: response.data.return["End date"],
          warrantyResponse: response.data.return["apiResponse"] || []
        });

        if (
          this.modalHardwareDetails.show &&
          this.modalHardwareDetails.data.id &&
          item["HARDWAREID"] == this.modalHardwareDetails.data.id
        ) {
          const startDate = moment(response.data.return["Start date"]);
          const endDate = moment(response.data.return["End date"]);
          // warranty data
          for (const item of this.modalHardwareDetails.data.warranty) {
            if (item.type == "warranty_name") {
              item.value = response.data.return["Service type"] || "";
            } else if (item.type == "start_date") {
              item.value = startDate.isValid()
                ? startDate.format("MM/DD/YYYY")
                : "";
            } else if (item.type == "end_date") {
              item.value = endDate.isValid()
                ? endDate.format("MM/DD/YYYY")
                : "";
            } else if (item.type == "last_checked") {
              item.value = updateReturn.data.LASTCHECKEDFORMATTED || "";
            }
          }
          this.$refs.hardwareDetails.getHardwareLogs();
        }

        if (
          updateReturn.data.LASTCHECKEDFORMATTED &&
          updateReturn.data.LASTCHECKEDFORMATTED != "N/A"
        ) {
          this.modalHardwareDetails.data.warrantyResponse =
            response.data.return["apiResponse"] || [];
        }

        return updateReturn.data.STATUS;
      } else if (
        response.data.return !== undefined &&
        response.data.return["apiResponse"] !== undefined &&
        response.data.return["apiResponse"] !== ""
      ) {
        const warrantyResponse = await ApiHelper.callApi("post", {
          controller: "Deployments",
          FunctionName: "UpdateHardware",
          hardwareId: item["HARDWAREID"],
          aId: item["AID"],
          action: "updateWarrantyParams",
          warrantyResponse: response.data.return["apiResponse"] || []
        });

        if (warrantyResponse.LASTCHECKEDFORMATTED) {
          for (const item of this.modalHardwareDetails.data.warranty) {
            if (item.type == "last_checked") {
              item.value = warrantyResponse.LASTCHECKEDFORMATTED || "";
            }
          }
          if (warrantyResponse.LASTCHECKEDFORMATTED != "N/A") {
            this.modalHardwareDetails.data.warrantyResponse =
              response.data.return["apiResponse"] || [];
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  showAssignToEUModal() {
    // notify if assigned an end user before
    const tiedEUItems = this.selectedItems.find(item => item.EUID || 0);
    if (tiedEUItems) {
      this.confirmAssignVisible = true;
      return;
    }

    this.assignEndUsersModalVisible = true;
  }

  getPurchaseIds(item) {
    if ((this.viewType || "") == "EndUser") {
      return (item.PURCHASEIDS || "").split(",").join(", ");
    }

    return item.PURCHASEID;
  }

  get endUserColumns() {
    let ret = [
      "ENDUSER",
      "ADDRESS",
      "PHONE",
      "MAINMODEL",
      "PURCHASEID",
      "EUCREATIONDATE",
      "WARRANTYNAME",
      "STARTDATEFORMATTED",
      "WARRENTYEXPFORMATTED",
      "LASTCHECKEDFORMATTED",
      "DEPLOYMENTNAME",
      "POID",
      "ACCOUNTNAME",
      "EMAIL",
      "DEVICES",
      "LASTONLINE",
      "LASTSEEN"
      // "HARDWAREID",
      // "SKU",
      // "HDESCRIPTION",
      // "ASSETNUMBER",
      // "CATEGORYNAME",
      // "PRODUCTLINE",
      // "HSTATUS",
      // "PURCHASEDON",
      // "ASSETTAG"
    ];
    // if (this.currentRole == "Customer") {
    //   // ignore some columns
    //   ret = ret.filter(item => item != "ACCOUNTNAME");
    // }
    return ret;
  }

  footerClickOutside(e) {
    // if ($(e.target).closest(".hardware-assign-eu-modal").length) {
    //   return;
    // }
    // this.modalHardwareDetails.show = false;
  }

  // System filter
  hasChangedStatusFilters = false;
  hasChangedLifeCycleFilters: boolean = false;

  async checkStatusList(id, name) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.status != undefined) {
      const findIndex = this.filters.status.findIndex(status => status === id);
      if (findIndex === -1) {
        this.filters.status.push(id);
      } else {
        this.filters.status.splice(findIndex, 1);
      }
    }
    if (this.filters.statusNames != undefined) {
      const findIndex = this.filters.statusNames.findIndex(
        status => status === name
      );
      if (findIndex === -1) {
        this.filters.statusNames.push(name);
      } else {
        this.filters.statusNames.splice(findIndex, 1);
      }
    }
    this.hasChangedStatusFilters = true;
  }

  async checkLifecycle(id, name) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.lifeCycleNames.findIndex(item => item === "None") !== -1 && this.filters.lifeCycles.findIndex(item => item === "-1") !== -1) {
      this.filters.lifeCycles = [];
      this.filters.lifeCycleNames = [];
    }
    if (this.filters.lifeCycles != undefined) {
      const findIndex = this.filters.lifeCycles.findIndex(item => item === id);
      if (findIndex === -1) {
        this.filters.lifeCycles.push(id);
      } else {
        this.filters.lifeCycles.splice(findIndex, 1);
      }
    }
    if (this.filters.lifeCycleNames != undefined) {
      const findIndex = this.filters.lifeCycleNames.findIndex(
        item => item === name
      );
      if (findIndex === -1) {
        this.filters.lifeCycleNames.push(name);
      } else {
        this.filters.lifeCycleNames.splice(findIndex, 1);
      }
    }
    if (this.filters.lifeCycles.length == 0 && this.filters.lifeCycleNames == 0) {
      this.filters.lifeCycles = ["-1"];
      this.filters.lifeCycleNames = ["None"];
    }
    this.hasChangedLifeCycleFilters = true;
  }

  async resetStatusList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.filters.status = [1, 2];
    this.filters.statusNames = ["Active", "Retired"];
    this.selectedAll = false;
    this.excludedIDs = [];
    // this.checkAllStatusList(allowFetchData);
    await this.fetchData();
  }

  async resetLifeCycle() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.filters.lifeCycles = this.hardwareLifecycles.map(item => item.LIFECYCLEID);
    this.filters.lifeCycleNames = this.hardwareLifecycles.map(item => item.LIFECYCLENAME);
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllStatusList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.statusNames = this.deletedView
      ? ["Deleted"]
      : ["Active", "Deleted", "Retired"];
    this.filters.status = this.deletedView ? [0] : [0, 1, 2];
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async uncheckAllStatusList() {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.status = [];
    this.filters.statusNames = [];
    await this.fetchData();
  }
  async uncheckAllLifeCycle() {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.lifeCycles = ["-1"];
    this.filters.lifeCycleNames = ["None"];
    await this.fetchData();
  }
  // Category filter
  hasChangedCategoryFilters = false;
  async checkCategoryList(id, name) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.filters.categoryIds != undefined) {
      const findIndex = this.filters.categoryIds.findIndex(
        categoryId => categoryId === id
      );
      if (findIndex === -1) {
        this.filters.categoryIds.push(id);
      } else {
        this.filters.categoryIds.splice(findIndex, 1);
      }
    }
    if (this.filters.categoryNames != undefined) {
      const findIndex = this.filters.categoryNames.findIndex(
        catName => catName === name
      );
      if (findIndex === -1) {
        this.filters.categoryNames.push(name);
      } else {
        this.filters.categoryNames.splice(findIndex, 1);
      }
    }
    this.hasChangedCategoryFilters = true;
  }
  async resetCategoryList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedItems = [];
    this.filters.categoryIds = [];
    this.filters.categoryNames = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.checkAllCategoryList(allowFetchData);
  }
  async checkAllCategoryList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.categoryNames = this.categories.map(item => item.TEXT);
    this.filters.categoryIds = this.categories.map(item => item.ID);
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async uncheckAllCategoryList() {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.categoryIds = [];
    this.filters.categoryNames = [];
    await this.fetchData();
  }

  getStartDate(item, header) {
    if (header == "WARRENTYEXPFORMATTED") {
      return item.STARTDATEFORMATTED;
    }
  }

  checkDate(item, index) {
    this.editedItem = index;
    if (
      item.NEW_WARRENTYEXPFORMATTED != "" &&
      new Date(item.NEW_STARTDATEFORMATTED) >
        new Date(item.NEW_WARRENTYEXPFORMATTED)
    ) {
      this.warningMessage = "Start date must be lesser than end date";
      this.inputField = "STARTDATE";
      this.dateError = true;
    } else {
      this.dateError = false;
      item.WARRENTYEXPFORMATTED = item.NEW_WARRENTYEXPFORMATTED;
      item.STARTDATEFORMATTED = item.NEW_STARTDATEFORMATTED;
      this.saveHardware(index);
    }
  }

  checkWarrantyDate(item, index) {
    if (
      item.NEW_STARTDATEFORMATTED &&
      moment(item.NEW_STARTDATEFORMATTED, "MM/DD/YYYY", true).isValid()
    ) {
      this.checkDate(item, index);
    }
  }
  checkEndWarrantyDate(item, index) {
    if (
      item.NEW_WARRENTYEXPFORMATTED &&
      moment(item.NEW_WARRENTYEXPFORMATTED, "MM/DD/YYYY", true).isValid()
    ) {
      this.checkDate(item, index);
    }
  }

  getCustomDataField(item, header) {
    // specify correct field by using listCustomFields
    const fieldName = (
      this.pageHeaders.find(headerItem => headerItem.key == header) || {
        key: "",
        value: ""
      }
    ).value;
    const relatedField = this.listCustomFields.find(
      t =>
        t.CUSTOMFIELDNAME.toLowerCase() == fieldName.toLowerCase() &&
        (t.AID || 0) == (item.AID || 0)
    );
    if (relatedField) {
      return `CUSTOMDATA${relatedField.CUSTOMFIELDID}`;
    }

    return `CUSTOMDATA${header.replace("customData", "")}`;
  }

  initPhoneValidation() {
    $(document).on("keydown", ".phoneMask, input[type=tel]", function (e) {
      const key = e.charCode || e.keyCode || 0;
      const $phone = $(this);
      let $val: any = $phone.val();

      if ($val.length === 0) {
        $phone.val("(");
      } else if ($val.length > 0) {
        if ($val.indexOf("(") == -1) {
          $val = "(" + $val;
          $phone.val($val);
        }
      }

      // Auto-format- do not expose the mask as the user begins to type
      if (key !== 8 && key !== 9) {
        const phoneNumber = new String($phone.val() || "");
        if (phoneNumber.length === 4) {
          $phone.val(phoneNumber + ")");
        }
        if (phoneNumber.length === 5) {
          $phone.val(phoneNumber + " ");
        }
        if (phoneNumber.length === 9) {
          $phone.val(phoneNumber + "-");
        }
      }

      // Allow numeric (and tab, backspace, delete) keys only
      return (
        key == 8 ||
        key == 9 ||
        key == 46 ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105)
      );
    });

    $(document).on("paste", ".phoneMask, input[type=tel]", function (e: any) {
      const $phone = $(this);
      const phoneData: any = e.originalEvent.clipboardData.getData("Text");

      const number = phoneData.match(/\d/g, "");
      const joinedNumber = number.join("");
      const regex = /^(\d{3})(\d{3})(\d{4})$/;
      const final = joinedNumber.replace(regex, "($1) $2-$3");

      $phone.val(final);
    });

    $(document).on(
      "keydown.autocomplete",
      ".phoneMask, input[type=tel]",
      function (e) {
        const key = e.charCode || e.keyCode;
        const $phone = $(this);

        if (typeof key == "undefined") {
          setTimeout(function () {
            const phoneData: any = $phone.val();

            const number = phoneData.match(/\d/g, "");
            const joinedNumber = number.join("");
            const regex = /^(\d{3})(\d{3})(\d{4})$/;
            const final = joinedNumber.replace(regex, "($1) $2-$3");

            $phone.val(final);
          }, 10);
        }
      }
    );

    $(document).on("focus click", ".phoneMask, input[type=tel]", function (e) {
      const $phone: any = $(this);

      if ($phone.val() && $phone.val().length === 0) {
        $phone.val("(");
      } else {
        const val = $phone.val();
        $phone.val("").val(val); // Ensure cursor remains at the end
      }
    });

    $(document).on("blur", ".phoneMask, input[type=tel]", function (e) {
      const $phone = $(this);

      if ($phone.val() === "(") {
        $phone.val("");
      }
    });
  }

  // async doRemoveDeploymentFilter() {
  //   this.deploymentDetails.id = 0;
  //   this.deploymentDetails.name = '';
  //   this.filters.deploymentId = '';
  //   this.filters.purchaseIds = [];
  //   this.pageNumber = 1;
  //   const query = this.$route.query;
  //   const currentQueryParam = { ...query };
  //   this.$delete(currentQueryParam, "deploymentId");
  //   this.$delete(currentQueryParam, "deploymentName");
  //   this.$router.replace({ query: currentQueryParam });
  //   await this.fetchData();
  // }

  customFieldInfo(id) {
    const fieldId = `${id}`.replace("customData", "");
    if (!fieldId || !parseInt(fieldId)) {
      return {};
    }

    const inList = this.listCustomFields.find(
      t => t.CUSTOMFIELDID == parseInt(fieldId)
    );
    if (!inList) {
      return {};
    }

    return inList;
  }

  accountTags: any = [];
  searchTagsTxt = "";
  searchAccTxt = "";
  hasChangedAccountFilters = false;
  // tagIDs: number[] = [];
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  isActive = "customerAccounts";
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.filters.selectedAccountsName = this.varAccsList.map(val => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccountsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        FunctionName: "ListExt",
        controller: "Accounts",
        queryType: "top5UsedAcct",
        view: this.viewType,
        isHardware: 1
      });
      if (response.data.STATUS) {
        this.varAccs = response.data.ACCOUNTS;
        this.selectedAccs = response.data.VARACCOUNTSIDSRR;
        this.varAccsList = response.data.ACCOUNTS;
        if (this.searchAccTxt != "") {
          await this.filterAccsInBox();
        }
      }
    } catch (err) {
      // notifier.alert(err.message);
      console.log(err);
    }
  }
  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if (accountsName != undefined) {
      const accountsIndex = this.filters.selectedAccountsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccountsName.push(accountsName);
      } else {
        this.filters.selectedAccountsName.splice(accountsIndex, 1);
      }
    }
  }
  resetSelected() {
    this.selectedID = [];
    this.selectedItems = [];
    this.selectedAll = false;
    this.excludedIDs = [];
  }

  showUpdateFieldsModal() {
    if (
      !(
        this.currentRole === "Reseller" &&
        ["All"].includes(this.viewType) &&
        this.selectedID.length
      )
    ) {
      return;
    }

    // warning about total selected
    if (this.selectedAll && this.getSelectedTotal(this.totalAll) > 500) {
      notifier.warning(
        "You have selected more than 500 items. Please select less than 500 items to update fields."
      );
      return;
    }

    this.updateFieldsModalVisible = true;
  }

  disableSelectHeader(itemKey) {
    let ret = false;
    if (
      (this.selectedHeaders.includes(itemKey) &&
        ((this.viewType == "All" &&
          this.selectedHeaders.includes(itemKey) &&
          [
            "HARDWAREID",
            "SKU",
            "HDESCRIPTION",
            "ASSETNUMBER",
            "CATEGORYNAME",
            "POID",
            "ACCOUNTNAME",
            "HSTATUS",
            "ENDUSER"
          ].includes(itemKey)) ||
          (this.viewType == "Group" &&
            this.selectedHeaders.includes(itemKey) &&
            ["SKU", "HDESCRIPTION"].includes(itemKey)) ||
          (this.viewType == "EndUser" &&
            this.selectedHeaders.includes(itemKey) &&
            ["ENDUSER", "DEVICES"].includes(itemKey)))) ||
      (!this.selectedHeaders.includes(itemKey) &&
        ((this.viewType == "All" && this.selectedHeaders.length > 13) ||
          (this.viewType == "Group" &&
            this.selectedHeaders.filter(key =>
              [
                "SKU",
                "HDESCRIPTION",
                "DEVICES",
                "POID",
                "ACCOUNTNAME",
                "CATEGORYNAME",
                "ASSETNUMBER",
                "PURCHASEDON"
              ].includes(key)
            ).length > 13) ||
          (this.viewType == "EndUser" &&
            this.selectedHeaders.filter(
              key =>
                this.endUserColumns.includes(key) ||
                key.startsWith("customData")
            ).length > 13))) ||
      Boolean(this.getFilterValue(itemKey).length)
    ) {
      ret = true;
    }

    return ret;
  }

  confirmRemoveModal(type = "") {
    if (this.selectedTotal > 100) {
      notifier.warning(
        `You have selected more than 100 items. Please select less than 100 items to ${
          type == "delete" ? "delete" : "restore"
        }.`
      );
      return;
    }

    if (type == "delete") {
      this.confirmRemoveModalVisible = true;
    } else if (type == "restore") {
      this.confirmRestoreModalVisible = true;
    }
  }

  isManyLongHeaders() {
    let needFixHeaderWidth = false;
    let totalLongName = 0;

    let selectedHeaders = this.selectedHeaders;
    if (this.viewType == "Group") {
      selectedHeaders = this.selectedHeaders.filter(key =>
        [
          "SKU",
          "HDESCRIPTION",
          "DEVICES",
          "POID",
          "ACCOUNTNAME",
          "CATEGORYNAME",
          "ASSETNUMBER",
          "PURCHASEDON"
        ].includes(key)
      );
    } else if (this.viewType == "EndUser") {
      selectedHeaders = this.selectedHeaders.filter(
        key => this.endUserColumns.includes(key) || key.startsWith("customData")
      );
    }

    if (selectedHeaders.length < 12) {
      return false;
    }

    selectedHeaders.map(header => {
      const headerName = this.getListHeader(header);
      if (headerName.length > 10) {
        totalLongName = totalLongName + 1;
      }
    });
    if (totalLongName > 3) {
      needFixHeaderWidth = true;
    }
    return needFixHeaderWidth;
  }

  isRequiredHeader(itemKey) {
    let ret = false;
    if (!this.selectedHeaders.includes(itemKey)) {
      return false;
    }

    if (
      (this.viewType == "All" &&
        [
          "HARDWAREID",
          "SKU",
          "HDESCRIPTION",
          "ASSETNUMBER",
          "CATEGORYNAME",
          "POID",
          "ACCOUNTNAME",
          "HSTATUS",
          "ENDUSER"
        ].includes(itemKey)) ||
      (this.viewType == "Group" && ["SKU", "HDESCRIPTION"].includes(itemKey)) ||
      (this.viewType == "EndUser" && ["ENDUSER", "DEVICES"].includes(itemKey))
    ) {
      ret = true;
    }

    return ret;
  }

  allowAccountConfig(data) {
   if (data.configAccountID) {
    this.refreshStoreModalVisible = false;
    this.configAccountID = data.configAccountID;
    this.isAllowAccountConfig = true;
   }
  }

  configStorefront() {
    this.confirmCustomerConfigModalVisible = false;
    if (this.configAccountID) {
      this.$router.push({ 
        name: "ViewCustomer",
        params: {
          id: this.configAccountID
        },
        query: {
          tab: "settingDet"
        }
      });
    }
  }

  @Watch("selectedID", { deep: true })
  updateConfigStorefront() {
    this.isAllowAccountConfig = false;
    this.configAccountID = "";
  }
}
