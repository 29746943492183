







































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import UserAutoSuggestInput from "@/components/UserSuggestInput.vue";
import LaddaButton from "@/components/LaddaButton.vue";
import { yearOptions } from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";
import { notifier } from "@/models/common";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    UserAutoSuggestInput,
    LaddaButton
  },
  methods: {
    yearOptions
  }
})

export default class DuplicateCompPlanModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  duplicateCompPlans!: any;

  duplicateCompPlansBK: any = [];
  duplicating: boolean = false;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    this.duplicateCompPlansBK = JSON.parse(JSON.stringify(this.duplicateCompPlans));

    this.duplicateCompPlansBK = this.duplicateCompPlansBK.map((item: any) => ({
      ...item,
      OTEYEAR: new Date().getFullYear(),
      NEWEMPLOYEEID: item.EMPLOYEEID
    }));
    
  }

  async duplicate() {
    
    let duplicateCompPlans = this.duplicateCompPlansBK.filter((item: any) => item.NEWEMPLOYEEID);
    let valid = await this.$validator.validateAll();

    if (valid && duplicateCompPlans.length) {
      this.duplicating = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "EmployeePlanAdd",
        duplicateCompPlans: duplicateCompPlans,
        action: "duplicateCompPlan"
      });

      if (response.STATUS === 1) {
        notifier.success(response.STATUSMESSAGE);
        this.$emit("reload");
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
      this.duplicating = false;
    }

  }
}
