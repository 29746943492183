var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.showInformation),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":132,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":98,"column":11}}})) != null ? stack1 : "")
    + "    <table width=\"100%\" id=\"quoteDetails_notes\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOtherOptions : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":6},"end":{"line":116,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":6},"end":{"line":130,"column":13}}})) != null ? stack1 : "")
    + "    </table>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <table width=\"100%\" id=\"quoteDetails_info\" style=\"padding-left: 10px !important; padding-right: 10px !important;\">\n        <tr>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.billAddress),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":34,"column":17}}})) != null ? stack1 : "")
    + "          <td width=\"30%\" style=\"vertical-align: top;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.shipAddress),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":74,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n          <td width=\"40%\" style=\"vertical-align: top;\">\n            <table cellspacing=\"0\" cellpadding=\"0\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.invoiceId),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":14},"end":{"line":81,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.invDate),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":14},"end":{"line":85,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.dueDate),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":14},"end":{"line":89,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.invoiceTerms),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":14},"end":{"line":93,"column":21}}})) != null ? stack1 : "")
    + "            </table>\n          </td>\n        </tr>\n      </table>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <td width=\"30%\" style=\"vertical-align: top;\">\n            <table cellspacing=\"0\" cellpadding=\"0\">\n              <tr><td class=\"title\">BILL TO</td></tr>\n              <tr>\n                <td>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":29,"column":25}}})) != null ? stack1 : "")
    + "                </td>\n              </tr>\n            </table>\n          </td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return "                    "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLTOONETIMEADDRESSNAME), depth0))
    + "<br />\n                    "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGTO),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESSBILLING),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":28,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGTO), depth0))
    + "<br/>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESSBILLING), depth0))
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2BILLING),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":24,"column":29}}})) != null ? stack1 : "")
    + "                      "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ICITYBILLING), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ISTATEBILLING), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IZIPBILLING), depth0))
    + " <br/>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2BILLING), depth0))
    + "<br/>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.BILLINGADDR), depth0)) != null ? stack1 : "")
    + "<br/>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <table cellspacing=\"0\" cellpadding=\"0\">\n                <tr><td class=\"title\">SHIP TO</td></tr>\n                <tr>\n                  <td>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.AACCOUNTNAME), depth0))
    + "<br />\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":67,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (data && data.root)) && stack1.SHIPPEDDATE),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":70,"column":27}}})) != null ? stack1 : "")
    + "                  </td>\n                </tr>\n              </table>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":22},"end":{"line":45,"column":29}}})) != null ? stack1 : "")
    + "                      "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPTOONETIMEADDRESSNAME), depth0))
    + "<br />\n                      "
    + ((stack1 = alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPTOONETIMEADDRESS), depth0)) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME), depth0))
    + "<br />\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTO),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":22},"end":{"line":51,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":22},"end":{"line":54,"column":29}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":55,"column":22},"end":{"line":66,"column":29}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTO), depth0))
    + "<br/>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.EUNAME), depth0))
    + "<br/>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS), depth0))
    + "<br/>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":59,"column":31}}})) != null ? stack1 : "")
    + "                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ICITY), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.ISTATE), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IZIP), depth0))
    + " <br/>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IADDRESS2), depth0))
    + "<br/>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        \n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTOADDR),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":24},"end":{"line":65,"column":31}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                          "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.SHIPPINGTOADDR), depth0)) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                      SHIPPED DATE:"
    + container.escapeExpression(container.lambda(((stack1 = (data && data.root)) && stack1.SHIPPEDDATE), depth0))
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">INVOICE ID</td></tr>\n                <tr><td>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.INVOICEID), depth0))
    + "</td></tr>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">INVOICE DATE</td></tr>\n                <tr><td>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.IDATEFORMATTED), depth0))
    + "</td></tr>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">DUE DATE</td></tr>\n                <tr><td>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.DUEDATEFORMATTED), depth0))
    + "</td></tr>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <tr><td class=\"title\">TERMS</td></tr>\n                <tr><td>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.TERMS), depth0))
    + "</td></tr>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <tr style=\"border-top: 1px;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.customerPO),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":10},"end":{"line":104,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.BuilderTemplate),{"name":"unless","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":10},"end":{"line":114,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <td width=\"50%\"><span class=\"title\">Customer PO</span> <br/> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.POID), depth0))
    + "</td>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":107,"column":12},"end":{"line":113,"column":19}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<td><span class=\"title\">CONTRACT NUMBER</span> <br/>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.CUSTOMDATACONTRACTNUMBER), depth0))
    + "</td>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":14},"end":{"line":112,"column":21}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.NASPOCONTRACT),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":16},"end":{"line":111,"column":23}}})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<td><span class=\"title\">CONTRACT NUMBER</span> <br /> "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && data.root)) && stack1.INVOICEDETAILS)) && stack1.NASPOCONTRACT), depth0))
    + "</td>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isContractNo : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":8},"end":{"line":129,"column":15}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <tr>\n            "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.contractNumber),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data,"loc":{"start":{"line":121,"column":12},"end":{"line":127,"column":19}}})) != null ? stack1 : "")
    + "          </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.draggableOptions),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":133,"column":9}}})) != null ? stack1 : "");
},"useData":true});