var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",staticClass:"modal assign-order-deployment hasBackground",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered dialogBox",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[(_vm.loading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"deployments-list d-flex pt-4 pb-4 align-items-center"},[_c('div',{staticClass:"mr-4 d-flex align-items-center"},[_c('div',{staticClass:"mr-3"},[_vm._v("DEPLOYMENT:")]),_c('div',[_c('DropdownControl',{staticClass:"mt-0",attrs:{"options":_vm.deployments,"selectedIDs":[_vm.deploymentId],"placeholderText":'Select Deployment',"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"name":"deployment","data-vv-as":"deployment"},on:{"check":_vm.selectDeployment},model:{value:(_vm.deploymentId),callback:function ($$v) {_vm.deploymentId=$$v},expression:"deploymentId"}})],1)]),_c('div',[_vm._v(" Associated: "+_vm._s(_vm.editedLines.length)+" / "+_vm._s(_vm.editData.length)+" ")])]),[_c('perfect-scrollbar',{staticClass:"ps tab-edit-fields",attrs:{"options":{
              wheelPropagation: false,
              suppressScrollX: true,
              maxScrollbarLength: 50
            }}},[_c('table',{attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("Deployed SKU")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("Hardware Id")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("Var360 Order")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("SKU")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("Description")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v(" Serial Number / Asset Tag ")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("Deployment")])])]),_c('th',{staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow"},[_vm._v("End User")])])])])]),_c('tbody',_vm._l((_vm.editData),function(item,index){return _c('tr',{key:index},[_c('td',{attrs:{"valign":"top"}},[_c('DropdownControl',{staticClass:"mt-0 skus-list",class:{
                        'tooltip-top':
                          _vm.editData.length > 5 && _vm.editData.length - index < 4
                      },attrs:{"options":_vm.skusList.filter(
                          function (t) { return t.data.SKU.toLowerCase() ==
                              item.SKU.toLowerCase() ||
                            t.data.SKU.toLowerCase().split('#')[0] ==
                              item.SKU.toLowerCase().split('#')[0]; }
                        ),"selectedIDs":[item.deployedSKU],"placeholderText":'SKU...',"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"name":"deployedSKU","optionType":'html',"data-vv-as":"deployedSKU","customWidth":400,"notFoundMsg":'No Deployed SKU Found',"showTitleHover":true},on:{"check":function (id) { return _vm.selectDeployedSKU(id, item); }},model:{value:(item.deployedSKU),callback:function ($$v) {_vm.$set(item, "deployedSKU", $$v)},expression:"item.deployedSKU"}})],1),_c('td',{attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(item.HARDWAREID)+" ")]),_c('td',{attrs:{"valign":"top"}},[_vm._v(" "+_vm._s(item.PURCHASEID)+" "),(
                        item.mapTo.purchaseId &&
                        item.mapTo.purchaseId != item.PURCHASEID
                      )?_c('div',{staticClass:"mapInfo text-success",attrs:{"title":item.mapTo.purchaseId}},[_vm._v(" "+_vm._s(item.mapTo.purchaseId)+" ")]):_vm._e()]),_c('td',{attrs:{"valign":"top"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.SKU.toUpperCase())+" ")])]),_c('td',{staticClass:"col-desc",attrs:{"valign":"top"}},[_c('div',{staticClass:"textOverflow",attrs:{"title":item.HDESCRIPTION}},[_vm._v(" "+_vm._s(item.HDESCRIPTION)+" ")]),(
                        item.mapTo.productName &&
                        item.mapTo.productName != item.HDESCRIPTION
                      )?_c('div',{staticClass:"mapInfo text-success",attrs:{"title":item.mapTo.productName}},[_vm._v(" "+_vm._s(item.mapTo.productName)+" ")]):_vm._e()]),_c('td',{staticClass:"col-asset-number",attrs:{"valign":"top"}},[_c('div',{staticClass:"textOverflow",attrs:{"title":_vm.getAssetNumber(item)}},[_vm._v(" "+_vm._s(_vm.getAssetNumber(item))+" ")])]),_c('td',{staticClass:"col-deployment",attrs:{"valign":"top"}},[_c('div',{staticClass:"textOverflow",attrs:{"title":item.DEPLOYMENTNAME}},[_vm._v(" "+_vm._s(item.DEPLOYMENTNAME)+" ")]),(
                        item.mapTo.deploymentId &&
                        item.mapTo.deploymentId != item.DEPLOYMENTID
                      )?_c('div',{staticClass:"mapInfo text-nowrap text-success"},[_vm._v(" Deployment #"+_vm._s(item.mapTo.deploymentId)+" ")]):_vm._e()]),_c('td',{staticClass:"col-eu-name",attrs:{"valign":"top"}},[_c('div',{staticClass:"col-eu-name-content"},[_c('div',{staticClass:"eu-name textOverflow",attrs:{"title":item.EUNAME}},[_vm._v(" "+_vm._s(item.EUNAME)+" ")]),(
                          (item.mapTo.fullName &&
                            item.mapTo.fullName != item.EUNAME) ||
                          (item.mapTo.euAddr &&
                            item.mapTo.euAddr != item.euAddr)
                        )?_c('div',{staticClass:"mapInfo eu-change-info text-success"},[(
                            item.mapTo.fullName &&
                            item.mapTo.fullName != item.EUNAME
                          )?_c('span',{staticStyle:{"width":"80%"},attrs:{"title":item.mapTo.fullName}},[_vm._v(" "+_vm._s(item.mapTo.fullName)+" ")]):_vm._e(),(
                            item.mapTo.euAddr &&
                            item.mapTo.euAddr != item.euAddr
                          )?_c('div',{staticClass:"textOverflow",staticStyle:{"max-width":"80%"},attrs:{"title":item.mapTo.euAddr}},[_vm._v(" "+_vm._s(item.mapTo.euAddr)+" ")]):_vm._e()]):_vm._e()])])])}),0)])]),_c('div',{staticClass:"mt-5",staticStyle:{"display":"flex","justify-content":"flex-start"}},[_c('LaddaButton',{staticClass:"btn2025 btn2025-primary",attrs:{"type":"button","label":"Associate","loading":_vm.saving,"disabled":_vm.saving == true || !_vm.allowSaving},on:{"click":function($event){return _vm.associate(false)}}}),_c('input',{staticClass:"btn2025 btn2025-secondary ml-2",attrs:{"type":"button","name":"cancel","value":"Cancel"},on:{"click":function($event){return _vm.$emit('close')}}})],1)]],2)])]),(_vm.confirmUpdateModalVisible)?_c('ConfirmRemoveItemModal',{attrs:{"title":"Confirmation","customMessage":((_vm.editedLines.length) + " hardware items will be updated and associated with selected deployed SKU")},on:{"close":function($event){_vm.confirmUpdateModalVisible = false},"confirm":function($event){_vm.confirmUpdateModalVisible = false;
      _vm.associate(true);}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Associate To Deployment")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close","data-dismiss":"modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])}]

export { render, staticRenderFns }