

































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import { ApiHelper } from "@/helpers/all";
import directives from "@/helpers/directives";
import PageTitle from "@/components/pageTitle.vue";
import InfoTooltip from "@/components/InfoTooltip.vue";
import LogInfoModal from "@/components/LogInfoModal.vue";
import { notifier,downloadFileUrl } from "@/models/common";
import {dollarFormat} from "../helpers/ApiHelper";
import Footer from "../components/Footer.vue";
import AddStoresModal from "@/components/AddStoresModal.vue";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

@Component({
  methods: {dollarFormat},
  inheritAttrs: false,
  components: { PageTitle, InfoTooltip, LogInfoModal, AddStoresModal, ListHeaderActions, ConfirmRemoveItemModal },
  directives
})
export default class QuoteStandardDetail extends TSXComponent<void> {
  currentRole = sessionStorage.getItem("userRole");
  loading = false;
  isProcessing = false;
  selectID: any = [];
  details = {
    endOfLifeFormatted: '',
    createDateFormatted: '',
    updateDateFormatted: '',
    totalFormatted: '',
    totalPriceFormatted: '',
    standardName: '',
    specialPricingID: '',
    statusId: 0,
    statusName: '',
    totalDays: 0,
    allowQuotingEndOfLifeDate: 0,
    accounts: [],
    standardId: 0,
    logs: []
  };

  filters: any = {
    category: "",
    categoryIds: [],
    categoryNames: []
  };
  customerCount: number = 0;
  logoDetails: any = [];
  tooltipDetails: any = [];
  tmpStatusId = 0;
  accounts = [];
  items: any[] = [];
  hoveredIndex = null;
  parentLiId = 0;
  showAccountName: boolean = false;
  standardUUID: string = "";
  logModelVisible: boolean = false;
  storesCount: number = 0;
  showStores: boolean = false;
  showAddStoreModal: boolean = false;
  storeDetails: any = [];
  productChecked: boolean = false;
  hasChangedCategoryFilters: boolean = false;
  categories: any = [];
  allowFetchData: boolean = false;
  checkCategoryItems: boolean = true;
  confirmRemoveStore: boolean = false;
  removeStoreItem: any = [];

  activeRow(activeId) {
    if (this.parentLiId == activeId) {
      this.parentLiId = 0;
    } else {
      this.parentLiId = activeId;
    }
  }

  checkallLineItems() {
    if (this.selectID.length == 0) {
      this.items.map((item) => {
        this.selectID.push(parseInt(item.STANDARDLIID));
        item.SELECTEDLINEITEM = true;
      });
    } else {
      this.selectID = [];
      this.items.map((item) => {
        item.SELECTEDLINEITEM = false;
      });
    }
  }

  updateSelectedStandardID(event, Id, standarIdx) {
    const idx = this.selectID.indexOf(Id);
    if (event) {
      if (idx === -1) {
        this.selectID.push(Id);
        for (let i = standarIdx + 1; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.SINCLUDED) break;
          this.$set(item, 'SELECTEDLINEITEM', true);
          this.selectID.push(item.STANDARDLIID);
        }
      }
    } else {
      if (idx !== -1) {
        this.selectID.splice(idx, 1);
        for (let i = standarIdx + 1; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.SINCLUDED) break;
          this.$set(item, 'SELECTEDLINEITEM', false);
          this.selectID = this.selectID.filter(v => v != item.STANDARDLIID);
        }
      }
    }
  }

  getStatusName(statusId) {
    let statusName = '';
    switch (statusId) {
      case 0:
        statusName = 'Draft';
        break;
      case 1:
        statusName = 'Active';
        break;
      case 2:
        statusName = 'Waiting for Approval';
        break;
      case 3:
        statusName = 'Terminated';
        break;
    }
    return statusName;
  }

  async updateStatus(statusId) {
    this.tmpStatusId = this.details.statusId;
    this.details.statusName = this.getStatusName(statusId);
    this.isProcessing = true;
    const response = await ApiHelper.callApi(
      'post',
      {
        controller: "Quotes",
        FunctionName: "UpdateQuoteStandardStatus",
        selectedIDs: this.standardUUID,
        status: statusId
      }
    );
    this.isProcessing = false;

    if (response.STATUS) {
      this.details.statusId = statusId;
      await this.getDetails();
      notifier.success(`Status Updated Successfully`);
    } else {
      this.details.statusId = this.tmpStatusId;
      this.details.statusName = this.getStatusName(this.tmpStatusId);
      notifier.alert(response.STATUSMESSAGE || 'Cant update Standard Status');
    }
  }

  getSelectedAccounts(ids: string) {
    const selectedIds = (ids ? ids.split(',') : []).map((id) => Number.parseInt(id, 10));
    const selectedAccounts = this.accounts.filter((item: any) => selectedIds.includes(item.AID));
    return selectedAccounts;
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  async created() {
    // await this.getAccounts();
    this.standardUUID = this.$route.params.id || "";
    if (this.standardUUID) {
      await this.getDetails();
    }
  }
  includedPrice = false;
  async getDetails(loading = true) {
    this.loading = loading;
    this.hasChangedCategoryFilters = false;
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "StandardView",
      Content: "Detailed",
      categoryIds: this.filters.categoryIds.length ? this.filters.categoryIds.join(",") : "",
      ObjID: this.standardUUID
    });

    if (response.STATUS) {
      this.loading = false;
      const details = response.DETAILS || {};
      this.details.statusId = details.STANDARDSTATUS || 0;
      this.details.statusName = this.getStatusName(details.STANDARDSTATUS);
      this.details.standardName = details.STANDARDNAME || '';
      this.details.specialPricingID = details.SPECIALPRICINGID || '';
      this.details.endOfLifeFormatted = details.ENDOFLIFEFORMATTED || '';
      this.details.createDateFormatted = details.CREATEDATEFORMATTED || '';
      this.details.updateDateFormatted = details.UPDATEDATEFORMATTED || '';
      this.details.totalFormatted = ApiHelper.dollarFormat(details.STANDARDTOTAL || 0);
      this.details.totalPriceFormatted = details.TOTALPRICEFORMATTED || '';
      this.details.totalDays = details.TOTALDAYS || 0;
      this.details.allowQuotingEndOfLifeDate = details.ALLOWQUOTINGENDOFLIFEDATE || 0;
      this.details.logs = details.LOGS || [];
      this.details.standardId = details.STANDARDID || 0;

      const STANDARDACCOUNTIDS = details.STANDARDACCOUNTIDS || '';
      const selectedIds = (STANDARDACCOUNTIDS ? STANDARDACCOUNTIDS.split(',') : []).map((id) => Number.parseInt(id, 10));
      this.details.accounts = this.accounts.filter((item: any) => selectedIds.includes(item.AID));
      const items = (response.ITEMS || []);
      this.items = items.map((item) => {
        const price = item.SPRODPRICE || 0;
        const costPrice = item.SPRICEREG || 0;
        const quantity = item.SPRODQUANTITY || 0;
        item.SPRODPRICEFORMATTED = ApiHelper.dollarFormat(price);
        item.SPRICEREGFORMATTED = ApiHelper.dollarFormat(costPrice);
        item.TOTALFORMATTED = ApiHelper.dollarFormat(price * quantity);
        item.INCLUDEITEMCOUNT = items.filter((item2) => item2.PARENTLIID == item.STANDARDLIID).length;
        return item;
      });
      const params = JSON.parse(details.STANDARDPARAMS || '{}');
      this.includedPrice = params.includedPrice;
      this.customerCount = response.CUSTOMERCOUNT || 0;
      const accountData = details.ACCOUNTDATA || [];
      this.logoDetails = accountData.filter((item : any, index : number) => index < 5);
      this.tooltipDetails = accountData.filter((item: any, index: number) => index > 4);

      this.storeDetails = response.STOREDETAILS || [];
      this.storesCount =  response.STORECOUNT || 0;
      const categories = response.CATEGORIES || [];
      if (categories.length) {
        categories.push({ ID: 0, TEXT: "Unknown" });
        this.categories = categories;
      }
      if (this.checkCategoryItems) {
        this.checkCategoryItems = false;
        this.filters.categoryIds = this.categories.map(item => item.ID);
        this.filters.categoryNames = this.categories.map(item => item.TEXT);
      }

    } else {
      ApiHelper.showErrorMessage(response.STATUSMESSAGE || 'Cant get Quote Standard');
      this.$router.replace({
        name: 'Standards',
      });
    }

  }

  // async getAccounts() {
  //   this.loading = true;
  //   const response = await ApiHelper.callApi("post", {
  //     controller: "Accounts",
  //     FunctionName: "List",
  //     getAll: 1,
  //   });
  //   this.loading = false;
  //   if (response.STATUS) {
  //     const data = response || {};
  //     this.accounts = (data.ACCOUNTS || []);
  //   } else {
  //     ApiHelper.showErrorMessage('Cant get accounts');
  //   }
  // }

  doUpload() {
    ApiHelper.showSuccessMessage('Coming soon', 'Upload');
  }

  showLogs() {
    this.logModelVisible = true;
  }

  async doDownload() {
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "StandardCSVExport",
      Content: "Detailed",
      ObjID: this.standardUUID
    });
    if (response.STATUS) {
      downloadFileUrl(response.S3URL);
    } else {
      notifier.alert("Export CSV Error: " + response.STATUSMESSAGE)
    }
  }

  getBaseTotal(item, index) {
    const ret = {
      price: 0,
      customerPrice: 0,
      total: 0
    };
    const configItems = this.getIncludedItems(item, index).filter(t => t.ISCONFIG || 0);
    for(const t of configItems) {
      ret.price += parseFloat((t.SPRICEREG || 0).toFixed(2));
      ret.price = parseFloat(ret.price.toFixed(2));
      ret.customerPrice += parseFloat((t.SPRODPRICE || 0).toFixed(2));
      ret.customerPrice = parseFloat(ret.customerPrice.toFixed(2));
      // total
      ret.total += parseFloat(((t.SPRODPRICE * t.SPRODQUANTITY) || 0).toFixed(2));
      ret.total = parseFloat(ret.total.toFixed(2));
    }
    // append base cost per/price per
    ret.price += parseFloat((item.SPRICEREG || 0).toFixed(2));
    ret.price = parseFloat(ret.price.toFixed(2));
    ret.customerPrice += parseFloat((item.SPRODPRICE || 0).toFixed(2));
    ret.customerPrice = parseFloat(ret.customerPrice.toFixed(2));
    ret.total += parseFloat(((item.SPRODPRICE * item.SPRODQUANTITY) || 0).toFixed(2));
    ret.total = parseFloat(ret.total.toFixed(2));
    // ret.total = parseFloat(
    //   (ret.customerPrice * (item.SPRODQUANTITY || 0)).toFixed(2)
    // );
    return ret;
  }

  getIncludedItems(item, index) {
    if(item.SINCLUDED) return [];

    const ret: any = [];
    const keys = Object.keys(this.items);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.items[keys[j]];
          if(!nextItem || !nextItem.SINCLUDED) break;

          if(nextItem.SINCLUDED) {
            nextItem.indexKey = keys[j];
            ret.push(nextItem);
          }
        }
        break;
      }
    }
    return ret;
  }

  hasConfigs(item, index) {
    if(item.SINCLUDED || 0) return false;

    let ret = false;
    const keys = Object.keys(this.items);
    for (let i = 0; i <= keys.length - 1; i++) {
      if (index == keys[i]) {
        for(let j = i + 1; j <= keys.length - 1; j++) {
          const nextItem = this.items[keys[j]];
          if(!nextItem) break;

          // check if this base has a config item
          if(!(nextItem.SINCLUDED || 0)) {
            // this is an other base
            break;
          } else if(nextItem.ISCONFIG || 0) {
            ret = true;
            break;
          }
        }

        break;
      }
    }
    return ret;
  }

  categoryImgLink(item) {
    const checkSku = item.sku != "" && !this.isFee(item);
    if (checkSku && (item.CATEGORYID || (item.ISCONFIG || 0))) {
      if (item.ISCONFIG || 0){
        // specify image for config item
        return require("@/assets/images/config-cat-ico.svg");
      }
      return require("@/assets/images/category_icon.svg");
    } else {
      return require("@/assets/images/category-not-available.svg");
    }
  }

  isFee(item) {
    return item.isFee || item.isContractFee || false;
  }

  getSKUCatTitle(item) {
    let ret = "";
    if((item.ISCONFIG || 0) || (item.CATEGORYNAME || "") == "Config") {
      ret = "";
    }else if(item.CATEGORYNAME || "") {
      ret = `Category: ${item.CATEGORYNAME}`;
    }else {
      ret = "Category not available";
    }
    if (ret.length) {
      ret += ", ";
    }
    if (item.PRODUCTLINE || "") {
      ret += `Product Line: ${item.PRODUCTLINE.toUpperCase()}`;
    } else {
      ret += "Product Line not available";
    }
    return ret;
  }
    // Category filter
  async checkCategoryList(id, name) {
    if (this.filters.categoryIds != undefined) {
      const findIndex = this.filters.categoryIds.findIndex(
        categoryId => categoryId === id
      );
      if (findIndex === -1) {
        this.filters.categoryIds.push(id);
      } else {
        this.filters.categoryIds.splice(findIndex, 1);
      }
    }
    if (this.filters.categoryNames != undefined) {
      const findIndex = this.filters.categoryNames.findIndex(
        catName => catName === name
      );
      if (findIndex === -1) {
        this.filters.categoryNames.push(name);
      } else {
        this.filters.categoryNames.splice(findIndex, 1);
      }
    }
    this.hasChangedCategoryFilters = true;
  }

  async resetCategoryList() {
    this.filters.categoryNames = [];
    this.filters.categoryIds = [];
    this.checkCategoryItems = true;
    await this.getDetails(false);
  }
  async uncheckAllCategoryList() {
    this.filters.categoryIds = ["-1"];
    this.filters.categoryNames = [];
    await this.getDetails(false);
  }

  async reload() {
    this.showAddStoreModal = false;
    await this.getDetails(false);
  }

  async checkProductStore(item) {
    item.ONSTORE = item.ONSTORE ? false : true;
    const response = await ApiHelper.callApi('post', {
      controller: "Quotes",
      FunctionName: "LinkStoresToStandard",
      productLineID: item.STANDARDLIID,
      onStore: item.ONSTORE,
      standardID: this.details.standardId
    });
    if (response.STATUS == 1) {
      notifier.success(response.STATUSMESSAGE);
    }
  }

  confirmRemoveStoreItem(item) {
    this.confirmRemoveStore = true;
    this.removeStoreItem = item;
  }
  
  async removeStore(item) {
    this.confirmRemoveStore = false;
    try {
      const response = await ApiHelper.callApi('post', {
        controller: "Quotes",
        FunctionName: "LinkStoresToStandard",
        standardID: this.details.standardId,
        removeStoreId: item.ACCOUNTID
      });
      if (response.STATUS == 1) {
        notifier.success(response.STATUSMESSAGE);
      }
    }
    catch (error) {
      notifier.alert(error.message);
    }
    await this.reload();
  }

  getFilterValue(header) {
    let filter = "";
    let searchKey: string | undefined = "";

    switch (header) {
      case "CATEGORY":
        if (this.filters.categoryNames && !this.filters.categoryNames.length && !this.checkCategoryItems) {
          searchKey = "None";
        } else if (
          this.filters.categoryNames &&
          !(this.filters.categoryNames.length == this.categories.length)
        ) {
          searchKey = this.filters.categoryNames
            .map(item => item || "Unknown")
            .join(", ");
        } else {
          searchKey = "";
        }
        break;
      default:
        // nothing
        break;
    }
    
    return (
      (filter ? `${filter}` : "") +
      (searchKey ? (filter ? " - " : "") + searchKey : "")
    );
  }
}
