









































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})

export default class WarrantyResponseModal extends TSXComponent<void> {

  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: [] })
  warrantyResponses!: any;

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  formatJson(json: any) {
    try {
      let result = JSON.stringify(json, null, 4);
      return result;
    } catch (e) {
      return json;
    }
  }
}
