






























































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import DropdownControl from "@/components/DropdownControl.vue";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle,
    DropdownControl
  }
})
export default class StoreCreate extends TSXComponent<void> {
  loading = false;
  saving: any = false;
  companyName = "";
  allCustomers: any = [];
  aId: number[] = [];
  storeId = 0;
  smartStoreAPIKey = "";
  storeURL = "";
  storeInfo: any = {};

  async created() {
    this.storeId = parseInt(this.$route.params.id) || 0;
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    try {
      this.getCustomersList();
      if (this.storeId) {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Stores",
          FunctionName: "GetStoreInfo",
          storeId: this.storeId
        });
        const storeInfo = response.data.storeInfo || {};
        this.storeInfo = storeInfo;
        this.aId = [storeInfo.AID];
        this.companyName = storeInfo.ACCOUNTNAME || "";
        this.storeURL = storeInfo.STOREURL || "";
        this.smartStoreAPIKey = storeInfo.SMARTSTOREAPIKEY || "";
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async getCustomersList() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Queries",
      FunctionName: "GetAllAccounts",
      subsystem: "Helpers"
    });
    this.allCustomers = response.data.ACCOUNTLIST || [];
  }

  //go to search page
  gotoSearch = function (this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  async saveStore() {
    // remove HTML
    this.companyName = this.removeHTML(this.companyName || "");

    const valid = await this.$validator.validateAll();
    if (!valid) {
      return;
    }

    try {
      this.saving = true;
      const reqData = {
        controller: "Stores",
        FunctionName: "Add",
        storeId: this.storeId,
        aId: this.aId[0],
        companyName: this.companyName,
        smartStoreAPIKey: this.smartStoreAPIKey,
        storeURL: this.storeURL
      };
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);
      const message = response.data.STATUSMESSAGE || "";
      if (response.data.STATUS == 1) {
        if (message) {
          notifier.success(message);
        }
        this.saving = false;
        this.$router.push({
          name: "StoreDetails",
          params: { id: response.data.storeId }
        });
      } else {
        if (message) {
          notifier.alert(message);
        }
        this.saving = "error";
      }
    } catch (err) {
      this.saving = "error";
    } finally {
      await this.$nextTick();
      this.saving = false;
    }
  }

  listPageRedirection() {
    if (this.storeId) {
      this.$router.push({
        name: "StoreDetails",
        params: { id: `${this.storeId}` }
      });
    } else {
      this.$router.push({ name: "CustomerPortals", query: { tab: "2" } });
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  selectCustomer(id) {
    const inList = this.allCustomers.find(item => item.AID == id);
    if (!inList) return;

    this.aId = [id];
    this.smartStoreAPIKey = inList.SMARTSTOREAPIKEY || "";
    if (!this.smartStoreAPIKey) {
      this.storeURL = "";
    }
    this.smartStoreAPIKeyChange();
  }

  async smartStoreAPIKeyChange() {
    if (!this.smartStoreAPIKey) return;

    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "GetStoreInfo",
        smartStoreAPIKey: this.smartStoreAPIKey
      });

      if (response.data.STATUS == 1) {
        const storeInfo = response.data.storeInfo || {};
        this.companyName = storeInfo.ACCOUNTFULLNAME || "";
        this.storeURL = storeInfo.STOREURL || "";
        if (this.storeURL) {
          notifier.success("SmartStore found for this API Key");
        } else {
          notifier.warning("SmartStore not found for this API Key");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
}
