














































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: UserStatusValue;
}

interface Events {
  onChangeStatus: UserStatusValue
}

export enum UserStatusValue {
  Open = 1,
  Close = 0
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class UserStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: UserStatusValue;

  @Prop({ required: false, default: false })
  shortStatusText?: boolean;

  @Prop({ required: false, default: true })
  showStatus?: boolean;

  popoverVisible = false
  optionsVisible = false

  get iconClass() {
    return this.status === UserStatusValue.Open ? 'green' : 'red'
  }

  get statusText() {
    return this.status === UserStatusValue.Open ? 'Active' : 'Inactive'
  }
  hideTooltip() {
    this.optionsVisible = false;
  }

  mouseover() {
    if (this.optionsVisible) {
      return
    }

    this.popoverVisible = true
  }

  mouseout() {
    this.popoverVisible = false
  }

  click() {
    this.popoverVisible = false
    
    this.optionsVisible = !this.optionsVisible
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false
    }
  }

  itemClick(status: UserStatusValue) {
    this.$emit('changeStatus', status)
    this.optionsVisible = false
  }
}
