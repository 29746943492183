var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$parent.loading)?_c('div',[_c('div',{staticClass:"profile-row"},[_c('div',{staticClass:"half left"},[_c('DropdownControl',{attrs:{"id":"requestTypeSelect","name":"requestTypeSelect","options":(_vm.$parent.savedSettings.orderSettings || []).map(function (item) { return ({
            ID: item.settingValue,
            TEXT: item.SiteStatus
          }); }),"selectedIDs":[_vm.requestTypeSelect],"hideSelectAll":true,"multiSelect":false,"hideSearch":true,"hideCheckMark":true},on:{"check":function (id) {
            _vm.requestTypeSelect = id;
          }},model:{value:(_vm.requestTypeSelect),callback:function ($$v) {_vm.requestTypeSelect=$$v},expression:"requestTypeSelect"}}),_vm._m(0)],1),_c('div',{staticClass:"half left"},[_c('DropdownControl',{attrs:{"id":"autoReleaseSelect","name":"autoReleaseSelect","options":[
          { ID: 0, TEXT: 'ON' },
          { ID: 1, TEXT: 'OFF' }
        ],"selectedIDs":[_vm.autoReleaseSelect],"hideSelectAll":true,"multiSelect":false,"hideSearch":true,"hideCheckMark":true},on:{"check":function (id) {
            _vm.autoReleaseSelect = id;
          }},model:{value:(_vm.autoReleaseSelect),callback:function ($$v) {_vm.autoReleaseSelect=$$v},expression:"autoReleaseSelect"}}),_vm._m(1)],1)]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"profile-row"},[(_vm.requestTypeSelect == 2)?_c('div',{staticClass:"half left mt-5"},[_c('label',{staticClass:"switch auto up"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.notifyEmail),expression:"notifyEmail"}],staticClass:"hasValue",attrs:{"type":"checkbox","id":"notifyEmail","name":"notifyEmail"},domProps:{"checked":Array.isArray(_vm.notifyEmail)?_vm._i(_vm.notifyEmail,null)>-1:(_vm.notifyEmail)},on:{"change":function($event){var $$a=_vm.notifyEmail,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.notifyEmail=$$a.concat([$$v]))}else{$$i>-1&&(_vm.notifyEmail=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.notifyEmail=$$c}}}}),_c('div',{staticClass:"slider round"})]),_c('label',{staticClass:"left up"},[_vm._v("Email Notification")])]):_vm._e()])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"up",attrs:{"for":"requestTypeSelect"}},[_c('span',[_vm._v("Select request type")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"up",attrs:{"for":"autoReleaseSelect"}},[_c('span',[_vm._v("Auto Release")])])}]

export { render, staticRenderFns }