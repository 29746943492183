import { render, staticRenderFns } from "./support-settings.vue?vue&type=template&id=e9e64be0&scoped=true"
import script from "./support-settings.vue?vue&type=script&lang=tsx"
export * from "./support-settings.vue?vue&type=script&lang=tsx"
import style0 from "./support-settings.vue?vue&type=style&index=0&id=e9e64be0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9e64be0",
  null
  
)

export default component.exports