























































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: true,
  components: {}
})

export default class TargetProfitTooltip extends TSXComponent<void> {

  @Prop({ required: false, default: "" })
  action!: string;

  @Prop({ required: false, default: []})
  empPlanAccountData!: any;

  @Prop({ required: false, default: {}})
  data!: any;

  @Prop({ required: false, default: [] })
  acceleratorData!: any;

  allTargetsTotal = '0.00';

  created() {
    if (this.empPlanAccountData.length && this.action == 'targetProfit') {
      this.calculateTotal();
    }
  }

  calculateTotal() {
    const total = this.empPlanAccountData.reduce((sum, {TARGET_PROFIT}) => {
      const numericValue = parseFloat(TARGET_PROFIT.toString().replace(/[^\d.-]/g, '')) || 0;
      return sum + numericValue;
    }, 0);

    this.allTargetsTotal = this.formatter(total);
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

}
