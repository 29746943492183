var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPPINGONETIMEADDRESS),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda;

  return "          <div>\n            <span class=\"element-header-type"
    + alias2(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":60}}}) : helper)))
    + "\">\n              <span class=\"element-header-content\">\n                Shipping Address: \n              </span>\n            </span>\n            "
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMEADDRESS1), depth0))
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMEADDRESS2),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":112}}})) != null ? stack1 : "")
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMEADDRESS2), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMECITY), depth0))
    + ", "
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMESTATE), depth0))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && data.root)) && stack1.QUOTEDETAILS)) && stack1.SHIPTOONETIMEZIP), depth0))
    + "\n          </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return ",";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.USERSHIPPINGINFO),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":25,"column":8}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <div>\n            <span class=\"element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":16,"column":44},"end":{"line":16,"column":60}}}) : helper)))
    + "\">\n              <span class=\"element-header-content\">\n                Shipping Address: \n              </span>\n            </span>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.USERSHIPPINGINFO),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n        ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              "
    + alias4(((helper = (helper = helpers.ACCOUNTSADDRESS_ADDRESS1 || (depth0 != null ? depth0.ACCOUNTSADDRESS_ADDRESS1 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTSADDRESS_ADDRESS1","hash":{},"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":42}}}) : helper)))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ACCOUNTSADDRESS_ADDRESS2 : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":42},"end":{"line":22,"column":82}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.ACCOUNTSADDRESS_ADDRESS2 || (depth0 != null ? depth0.ACCOUNTSADDRESS_ADDRESS2 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTSADDRESS_ADDRESS2","hash":{},"data":data,"loc":{"start":{"line":22,"column":83},"end":{"line":22,"column":111}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ACCOUNTSADDRESS_CITY || (depth0 != null ? depth0.ACCOUNTSADDRESS_CITY : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTSADDRESS_CITY","hash":{},"data":data,"loc":{"start":{"line":22,"column":112},"end":{"line":22,"column":136}}}) : helper)))
    + ", "
    + alias4(((helper = (helper = helpers.ACCOUNTSADDRESS_STATE || (depth0 != null ? depth0.ACCOUNTSADDRESS_STATE : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTSADDRESS_STATE","hash":{},"data":data,"loc":{"start":{"line":22,"column":138},"end":{"line":22,"column":163}}}) : helper)))
    + " "
    + alias4(((helper = (helper = helpers.ACCOUNTSADDRESS_ZIP || (depth0 != null ? depth0.ACCOUNTSADDRESS_ZIP : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ACCOUNTSADDRESS_ZIP","hash":{},"data":data,"loc":{"start":{"line":22,"column":164},"end":{"line":22,"column":187}}}) : helper)))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && data.root)) && stack1.USERBILLINGINFO),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <div style=\"margin-top: 12px;\">\n            <span class=\"element-header-type"
    + container.escapeExpression(((helper = (helper = helpers.templateType || (depth0 != null ? depth0.templateType : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"templateType","hash":{},"data":data,"loc":{"start":{"line":30,"column":44},"end":{"line":30,"column":60}}}) : helper)))
    + "\">\n              <span class=\"element-header-content\">\n                Billing Address: \n              </span>\n            </span>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (data && data.root)) && stack1.USERBILLINGINFO),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":37,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<table width=\"100%\" id=\"quoteDetails_items\" style=\"padding: 0; margin-top: 12px;\">\n  <tbody>\n    <td width=\"100%\" style=\"padding-left: 35px; padding-right: 35px; padding-top: 0;\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteShippingAddr),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":26,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (data && data.root)) && stack1.TemplateSetting)) && stack1.quoteBillingAddr),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":6},"end":{"line":40,"column":13}}})) != null ? stack1 : "")
    + "    </td>\n  </tbody>\n</table>\n";
},"useData":true});