



























import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import ScheduleDetailsModal from "@/components/Deployment/ScheduleDetailsModal.vue";

interface Props {}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    ScheduleDetailsModal
  }
})

export default class CalendarView extends TSXComponent<Props, Events> {
  @Prop({ required: false, default:[] })
  eventDetails!: any[];

  @Prop({ required: false, default:[] })
  timeSlots!: any[];

  @Prop({ required: false, default:[] })
  exceptions!: any[];

  @Prop({ required: false, default:{} })
  available!: any;

  @Prop({ required: false, default:0 })
  availabilityId!: number;

  @Prop({ required: false, default:0 })
  deploymentId!: number;

  scheduleDetails: any = {};
  scheduleDetailsModal: boolean = false;
  selectedUser: number = 0;

  $refs!: {
    calendarView: HTMLDivElement
    detailsModal: ScheduleDetailsModal
  }

  created() {
    this.showCalendar();
  }

  showCalendar() {
    var self = this;
    this.prepareCalendarData();
    this.$nextTick().then(() => {
      var calendarEl = this.$refs.calendarView;
      const calendar = new Calendar(calendarEl, {
        headerToolbar: { 
          start: 'prev',
          center: 'title',
          end: 'next today'
        },
        dayPopoverFormat: {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        },
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        displayEventTime: false,
        events: self.eventDetails,
        dayMaxEventRows: true,
        dayMaxEvents: 4,
        dayHeaderFormat: {
          weekday: 'long'
        },
        eventClick: function(info) {
          self.scheduleDetails = info.event.extendedProps;
          self.selectedUser = info.event.extendedProps.USERID;
          self.scheduleDetailsModal = true;
        },
      });
      calendar.render();

      this.$watch('eventDetails', (newEvents) => {
        calendar.removeAllEvents();
        calendar.addEventSource(newEvents);
      }, { deep: true });
    });
  }

  closeDetailsModal() {
    this.scheduleDetailsModal = false;
    this.scheduleDetails = {};
  }

  getCriteriaValues(endUserId = 0) {
    if (!endUserId) return "";

    let ret = [];
    const inList: any = this.eventDetails.find((item: any) => item.USERID == endUserId);
    if (inList) {
      ret = (inList.CRITERIA || []).filter(item => item.CUSTOMVALUE != "").map(item => `${item.CUSTOMFIELDNAME}: ${item.CUSTOMVALUE}`);
    }

    return ret;
  }

  prepareCalendarData() {
    this.eventDetails.forEach(element => {
      element.title = element.STARTTIME + element.STARTTIMEPERIOD + ' ' + element.EUNAME + ' ' + this.getCriteriaValues(element.USERID).join(', '),
      element.start = element.SCHEDULEDATEFORMATTED + 'T' + element.STARTTIMEFORMATTED,
      element.end = element.SCHEDULEDATEFORMATTED + 'T' + element.ENDTIMEFORMATTED
    });
  }

  updateCalendar(updatedSchedule) {
    let euIndex = this.eventDetails.findIndex(eu => eu.USERID == updatedSchedule.USERID);
    this.eventDetails[euIndex] = updatedSchedule;
    this.prepareCalendarData();
    this.showCalendar();
  }

  addSchedule(data) {
    this.$emit("addSchedule", data);
    let updatedEUSchedule = this.eventDetails.find( item => item.USERID == this.selectedUser);
    this.scheduleDetails = updatedEUSchedule;
  }

  dayChange(selected) {
    this.$emit("dayChange", selected);
  }
}
