





















































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { ConfirmRemoveItemModal }
})
export default class CartExperience extends TSXComponent<Props, Events> {
  $parent: any;
  type = "2";
  type2 = "1";
  type3 = false;
  hoverCart: string[] = [];
  completeButton: string[] = [];
  includedPriceDisplay = "0";
  pDetailsConfigMode = "1";
  sideCart = "0";
  confirmUpdateVisible = false;
  confirmUpdateMessage = "";

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    const BuyingExperienceType = saved.BuyingExperienceType || 0;
    const BaseUnitOnlyCatalogON = saved.BaseUnitOnlyCatalogON || 0;
    // cart experience
    this.type = BuyingExperienceType == "2" ? "2" : "1";
    // accessory mode
    if (this.type == "1") {
      this.type2 = "2";
    } else {
      if (BuyingExperienceType == "1" || BaseUnitOnlyCatalogON == "2") {
        this.type2 = "2";
      }
    }
    // Product Details View
    if (saved.Full_Screen_Accessories == true) {
      this.type3 = true;
    }
    // hover cart
    if (saved.hoverCart_print_quote == "1") {
      this.hoverCart.push("hoverCart_print_quote");
    }
    if (saved.hoverCart_email_quote == "1") {
      this.hoverCart.push("hoverCart_email_quote");
    }
    if (saved.hoverCart_save_quote == "1") {
      this.hoverCart.push("hoverCart_save_quote");
    }
    // Complete
    if (saved.completeButton_save_quote == "1") {
      this.completeButton.push("completeButton_save_quote");
    }
    if (saved.completeButton_complete_order == "1") {
      this.completeButton.push("completeButton_complete_order");
    }
    // Force Included Accessories
    if (saved.includedPriceDisplay == "1") {
      this.includedPriceDisplay = "1";
    }
    // Product Details Configuration Mode
    if (saved.pDetailsConfigMode == "2") {
      this.pDetailsConfigMode = "2";
    }
    // Side Cart
    if (saved.sideCart == "1") {
      this.sideCart = "1";
    }
  }

  collectData() {
    return {
      status: 1,
      data: {
        type: this.type,
        type2: this.type2,
        type3: this.type3 ? "true" : "false",
        hoverCart: this.hoverCart.join(","),
        completeButton: this.completeButton.join(","),
        includedPriceDisplay: this.includedPriceDisplay,
        pDetailsConfigMode: this.pDetailsConfigMode,
        sideCart: this.sideCart == "1" ? "1" : undefined
      }
    };
  }

  cartTypeChange() {
    if (this.type == "1") {
      this.type2 = "2";
    }
  }

  async confirmUpdate() {
    this.confirmUpdateVisible = false;
    await this.$parent.saveSettings({
      bypass: "1"
    });
  }
}
