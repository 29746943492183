











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { validateFn } from "../helpers";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import {
	notifier,
	downloadFile,
	printHtml,
	downloadFileUrl,
} from "../models/common";
import log from "../helpers/log";
import { DashboardAPIRepo } from "../repos/DashboardAPIRepo";
const template = require("../templates/dashboard/dashboard.handlebars");
import InfoTooltip from "../components/InfoTooltip.vue";
import ActivitiesViewTooltip from "../components/DataFilterTooltip.vue";
import Loading from "@/components/Loading.vue";
import moment from "moment";
import { dollarFormat } from "@/helpers/ApiHelper";
import { ApiHelper } from "@/helpers/all";

const dashboardRepo = new DashboardAPIRepo();

interface ILineChart extends TSXComponent<void> {
	renderLineChart();
}

interface apexchart extends TSXComponent<void> {}

interface IBarChart extends TSXComponent<void> {
	renderBarChart();
}
declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const require: any;
const allMonths = [
	"Jan", // "January",
	"Feb", // "February",
	"Mar", // "March",
	"Apr", // "April",
	"May",
	"Jun", // "June",
	"Jul", // "July",
	"Aug", // "August",
	"Sep", // "September",
	"Oct", // "October",
	"Nov", // "November",
	"Dec", // "December",
];
var braekmonth = 11 - new Date().getMonth() ;
var startPoint = new Date().getMonth();
var lastYear:string[] = [];
var Curryear:string[] = [];
var Currmon:string[] = [];
var lastmon:string[] = [];
for (var i = 0; i < 12; i++) {
	var mon = allMonths[i].substring(0,3);
	if(startPoint < i){
		lastYear.push(mon+", "+( new Date().getFullYear() - 1));
		lastmon.push(allMonths[i]);
	}
	else{
		Curryear.push(mon+", "+(new Date().getFullYear()));
		Currmon.push(allMonths[i]);
	}
}
const last12 = lastYear.concat(Curryear)
const last12months = lastmon.concat(Currmon)

@Component({
	inheritAttrs: false,
	components: {
    Loading,
		InfoTooltip,
		ActivitiesViewTooltip,
	},
})
export default class Dashboard extends TSXComponent<void> {
	$root: any;
	$allowedFunctions: any;
	$systemGroups: any;
	$userFunctions: any;
	currYear = new Date().getFullYear();
	loading = false;
	isInitialLoad = sessionStorage.getItem('isInitialLoad') ? true : false;
	CurrentView = "My Datapoints";
	role = sessionStorage.getItem("userRole");
	items: {
		sortingYear: number;
		salesLineChartData1?: null | number[];
		salesLineChartData2?: null | number[];
		salesLineChartData3?: null | number[];
		orderBarChartData?: null | number[];
		delvieryBarChartData?: null | number[];
		salesLineChartLabel?: null | string[];
		categoryTrend?: null | number[] | any;
		shipmentTrend?: { AVGDAYHOUR: string }[] | null;
		dataOrderTrend?: null | number[] | any;
		todaySales?: number;
		todaySalesFormatted?: string;
		todayProfit?: number;
		todayProfitFormatted?: string;
    activeDeployments: {
      total: number;
      percent: number;
    };
	} = {
		sortingYear: this.currYear,
		salesLineChartData1: null,
		salesLineChartData2: null,
		salesLineChartData3: null,
		orderBarChartData: null,
		delvieryBarChartData: null,
		salesLineChartLabel: null,
		categoryTrend: null,
		shipmentTrend: null,
    dataOrderTrend: {
      SALESTHISYEARFORMATTED: '',
      COMMISSION: '',
      SALESTHISYEARTREND: 0
    },
		todaySales: 0,
		todaySalesFormatted: dollarFormat(0),
		todayProfit: 0,
		todayProfitFormatted: dollarFormat(0),
    activeDeployments: {
      total: 0,
      percent: 0,
    }
	};
	salesLineChartData1 = [];
	salesLineChartData2 = [];
	salesLineChartData3 = [];
	salesLineChartLabel: string[] = [];
	orderBarChartData = [
		{
			label: "Units",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 2,
			data: [],
		},
	];
	orderBarChartLabel: string[] = [];
	delvieryBarChartData: {
		label: string;
		backgroundColor: string;
		data: string[];
	}[] = [
		{
			label: "Days",
			backgroundColor: "#009cff",
			data: [],
		},
	];
	deliveryBarChartLabel: string[] = [];
	salesLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#3c3564",
			borderWidth: 3,
			name: "sales",
			linetype: "Orders",
			fill: true,
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			name: "sales",
			linetype: "Orders",
			borderWidth: 3,
			fill: true,
			data: [],
		},
	];
	ordersLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#38c4c5",
			borderWidth: 3,
			lineTension: 0.1,
			fill: true,
			name: "orders",
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 3,
			name: "orders",
			fill: true,
			data: [],
		},
	];
	quotesLineChartData = [
		{
			label: "Prior Year",
			backgroundColor: "transparent",
			borderColor: "#38c4c5",
			borderWidth: 3,
			lineTension: 0.1,
			name: "quotes",
			fill: true,
			borderDash: [5, 5],
			data: [],
		},
		{
			label: "This Year",
			backgroundColor: "#E3F2FD",
			borderColor: "#009cff",
			borderWidth: 3,
			name: "quotes",
			fill: true,
			data: [],
		},
	];
	years = [];
	ApiMonths = [];
	dashView = "CurrentUsers";
	showView = 1;
	exportObj = {
		controller: "Dashboard",
		FunctionName: "Export",
		uploadPath: "Inbound/apiFiles/",
		keyName: "Inbound/apiFiles/",
		ExportType: "PDF",
		unit: "both",
		figure: "1,4,7",
		userRole: sessionStorage.getItem("userRole"),
		fYear: this.items.sortingYear,
	};
	totalSalesTTVisibleIndex = -1;
	totalCommTTVisibleIndex = -1;
	totalUnitTTVisibleIndex = -1;
	selectedView = false;
	accountTags: any = [];
	quoteChecked = false;
	oppChecked: boolean = false;
	activities: object[] = [];
	ActivitiesViewTooltipVisible = false;
	totalSalesVisible = false;
	totalProfitVisible = false;
	selectedFilters: any = [
		"Quote",
		"Order",
		"Invoice",
		"Account",
		"Support",
		"System",
	];
	activitiesFilters: any = [];
	selectedGlobalTag = false;
	tagIDs: number[] = [];
	gTagIDs: number[] = [];
	groupTagIDs: number[] = [];
	selectedTags = [];
	totalUserSalesTTVisibleIndex = -1;
	totalUserCommTTVisibleIndex = -1;
	totalUserSalesTTVisible = 1;
	totalUserCommTTVisible = 1;
	dashboardReportTitle = "Orders";
	YTDSalesPerTag: object[] = [];
	userRole = this.$root.getUserRole() === 'Reseller';	
	accountTagName: string[] = [];
	gTagName: string[] = [];
	groupName: string[] = [];
	isChecked = false;
	isDisabled = false;
  showDeployments = false;

	$refs!: {
		chart1: ILineChart;
		chart2: IBarChart;
		chart3: IBarChart;
    apex: any;
	};
  allLineSeries = [
    {
      name: "Prior Year Orders",
      data: [28, 29, 33, 36, 32, 32, 33],
    },
    {
      name: "This Year Orders",
      data: [412, 141, 514, 158, 167, 173, 183],
    },
    {
      name: "This Year Quotes",
      data: [412, 141, 514, 158, 167, 173, 183],
    },
    {
      name: "This Year Opportunities",
      data: [412, 141, 514, 158, 167, 173, 183],
    },
  ];
	salesLineSeries = [
		{
			name: "Prior Year",
			data: [28, 29, 33, 36, 32, 32, 33],
		},
		{
			name: "This Year",
			data: [412, 141, 514, 158, 167, 173, 183],
		},
	];
	saleOrderChartData = [
		{
			name: "Units",
			data: ["28", "29", 33, 36, 32, 32, 33],
		}
	];
	quoteOrderChartData = [
		{
			name: "Units",
			data: ["28", 29, 33, 36, 32, 32, 33],
		}
	];
	barOptions:any ={
		chart: {
			height: 250,
			defaultLocale: "en",
			type: "bar",
			stacked: true,
			dropShadow: {
				enabled: true,
				color: "#000",
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			animations: {
				enabled: true,
				easing: "linear",
				dynamicAnimation: {
					speed: 1000,
				},
			},
			toolbar: { show: false },
			zoom: { enabled: false },
		},
		colors: ["#0ea1ff"],
		dataLabels: {
			enabled: false,
		},
		xaxis: {
			categories: [],
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false
			},
			labels: {
				show: true,
				style: {
					colors:"#656468",
						fontFamily: 'Work Sans, sans-serif',
					fontWeight: 600,
					fontSize: "10px",
				},
			},
		},
		yaxis: {
			show: false,
		},
		tooltip: {
			x: {
				formatter: function (value) {
					return value;
				},
			},
			y: {
				formatter: function (value) {
					var formatedAmount = value;
					// if (!isNaN(value) && value != 0) {
					// 	value = value.toFixed(2);
					// 	formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
					// }
					return formatedAmount;
				},
			},
		},
		grid: { show: false },
	};
	showBusinessLine: boolean = false;
	businessLines: any = {};
	businessLineIds: string[] = [];
	businessLineName: string[] = [];
	boardList: any[] = [];
	showTotalBreakdown: boolean = false;
	breakDown: any[] = [];

	@Prop({ required: false, default: 0 })
	customerid!: number;
	hasBLFilterChange: boolean = false;

	async created() {
		this.buildActivityFilteredOptions();
		this.fetchDashboardData(this.$root, this.currYear);
	}

  chartStrokeDashArray: any[] = [];
  updateStrokeDashArray() {
    if (this.reportType == 0 || this.reportType == 1) {
      this.chartStrokeDashArray = [7, 0, 7, 0];
    } else {
      this.chartStrokeDashArray = [7, 0, 0, 0];
    }
  }

  chartColors: string[] = [];
  updateChartColors() {
    switch (this.reportType) {
      case 0:
        this.chartColors = this.userRole ? ["#e1e1e1", "#0ea1ff", "#b7b6ba", "#31BCBC"] : ["#e1e1e1", "#38c4c5", "#b7b6ba", "#31BCBC"];
        break;
      case 1:
        this.chartColors = this.userRole ? ["#e1e1e1", "#0ea1ff", "#b7b6ba", "#31BCBC"] : ["#e1e1e1", "#38c4c5", "#b7b6ba", "#31BCBC"];
        break;
      case 2:
        this.chartColors = this.userRole ? ["#e1e1e1", "#0ea1ff", "#31BCBC", "#31BCBC"] : ["#e1e1e1", "#38c4c5", "#31BCBC", "#31BCBC"];
        break;
      default:
        this.chartColors = this.userRole ? ["#e1e1e1", "#0ea1ff", "#b7b6ba", "#31BCBC"] : ["#e1e1e1", "#38c4c5", "#b7b6ba", "#31BCBC"];
        break;
    }

  }

	get chartOptions(){
		return {
			chart: {
				height: "350px",
				defaultLocale: "en",
				type: "line",
				// stacked: true,
				events: {
					click: (event, chartContext, config)  =>  {
						if(config.dataPointIndex >= 0 || config.seriesIndex >= 0){
							// The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
							this.saleRedirect(this.items.sortingYear,config.globals.categoryLabels[config.dataPointIndex],config.seriesIndex,config.dataPointIndex,config.globals.categoryLabels[config.dataPointIndex].split("-")[0])
						}
					}
				},
				dropShadow: {
					enabled: true,
					color: "#000",
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2,
				},
				animations: {
					enabled: true,
					easing: "linear",
					dynamicAnimation: {
						speed: 100,
					},
				},
				toolbar: { show: false },
				zoom: { enabled: false },
			},
			// colors: ["#3c3564", "#0ea1ff", "#b7b6ba"],
			colors: this.chartColors,
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
				width: 3,
				dashArray: this.chartStrokeDashArray,
			},
			markers: {
				size: 6,
				fillOpacity: 0,
			},
			xaxis: {
				categories: this.categories(),
				tooltip: {
					enabled: false,
				},
				crosshairs: {       
					stroke: {
						width: 0,
          			},
				},
				axisTicks: {
					show: true,
					height: 11,
				},
				labels: {
					show: true,
					style: {
						colors:"#656468",
						// fontFamily: 'Work Sans, sans-serif',
						fontWeight: 600,
						fontSize: "10px",
					},
				},
			},
			yaxis: {
				show: false,
			},
			tooltip: {
				x: {
					formatter: (value) => {
						if(isNaN(this.items.sortingYear)){
							// return "Month: " + last12months[value - 1];
							return last12months[value - 1];
						}
						else{
							// return "Month: " + allMonths[value - 1];
							return allMonths[value - 1];
						}
					},
				},
				y: {
					formatter: function (value) {
						var formatedAmount = value;
						if (!isNaN(value)) {
							value = value.toFixed(2);
							formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						}
						return formatedAmount;
					},
				},
			},
			grid: { show: false },
			legend: {
				show: true,
				horizontalAlign: "center",
				onItemClick: {
					toggleDataSeries: true,
				},
				position: "top",
				markers: {
					width: 40,
					height: 15,
					radius: 0,
					strokeWidth: 3,
					strokeColor: ["#3c3564", "#0ea1ff", "#b7b6ba", "#31BCBC"],
				},
			},
		}
	}

  isLoadingChart = false;

	@Watch("items", { deep: true })
	itemsUpdated(
		val: {
			salesLineChartLabel: string[];
			delvieryBarChartData: string[];
			orderBarChartData: string[];
			salesLineChartData2: number[];
			salesLineChartData3: number[];
			OrderLineChartData1: number[];
			OrderLineChartData2: number[];
			quotesLineChartData1: number[];
			quotesLineChartData2: number[];
			salesLineChartData1: number[];
		},
		oldVal: string
	) {
		try {
			// this.debounce(async function (val, oldVal) {
			// 	console.log('debounce watch');
			try {
				// console.log('label',val.salesLineChartLabel)
				this.salesLineChartLabel = val.salesLineChartLabel;
        /*
				if (this.quoteChecked) {
					this.salesLineChartData[2].data = val.salesLineChartData3.length
						? JSON.parse(JSON.stringify(val.salesLineChartData3))
						: [];
					if (this.salesLineSeries.length == 2) {
						this.salesLineSeries.push({
							name: "This Year Quotes",
							data: JSON.parse(JSON.stringify(val.salesLineChartData3)),
						});
					} else {
						// this.salesLineSeries[2].name = "This Year Quotes";
						this.salesLineSeries[2].data = JSON.parse(
							JSON.stringify(val.salesLineChartData3)
						);
					}
				} else {
					if (this.salesLineSeries.length > 2) {
						this.salesLineSeries.pop();
					}
				}
        */
				this.salesLineChartData[1].data = val.salesLineChartData1.length
					? JSON.parse(JSON.stringify(val.salesLineChartData1))
					: [];
				/*
        this.salesLineSeries[1].data = JSON.parse(
					JSON.stringify(val.salesLineChartData1)
				);
				this.salesLineSeries[0].data = JSON.parse(
					JSON.stringify(val.salesLineChartData2)
				);
        */
				this.salesLineChartData[0].data = val.salesLineChartData2.length
					? JSON.parse(JSON.stringify(val.salesLineChartData2))
					: [];
				// Orders
				this.ordersLineChartData[1].data = val.OrderLineChartData1.length
					? JSON.parse(JSON.stringify(val.OrderLineChartData1))
					: [];
				this.ordersLineChartData[0].data = val.OrderLineChartData2.length
					? JSON.parse(JSON.stringify(val.OrderLineChartData2))
					: [];
				// Quotes
				this.quotesLineChartData[1].data = val.quotesLineChartData1.length
					? JSON.parse(JSON.stringify(val.quotesLineChartData1))
					: [];
				this.quotesLineChartData[0].data = val.quotesLineChartData2.length
					? JSON.parse(JSON.stringify(val.quotesLineChartData2))
					: [];

        switch (this.reportType) {
          case 0:
            // Prior Year Orders
            this.allLineSeries[1].data = JSON.parse(
                JSON.stringify(val.salesLineChartData1)
            );
            // This Year Orders
            this.allLineSeries[0].data = JSON.parse(
                JSON.stringify(val.salesLineChartData2)
            );
            break;
          case 1:
            // This Year Quotes
            this.allLineSeries[2].data = val.salesLineChartData3.length
                ? JSON.parse(JSON.stringify(val.salesLineChartData3))
                : [];
            break;
          case 2:
            // This Year Quotes
            this.allLineSeries[2].data = val.salesLineChartData3.length
                ? JSON.parse(JSON.stringify(val.salesLineChartData3))
                : [];
            // This Year Opportunities
            this.allLineSeries[3].data = val.salesLineChartData1.length
                ? JSON.parse(JSON.stringify(val.salesLineChartData1))
                : [];
            break;
        }
        this.salesLineSeries = [this.allLineSeries[0], this.allLineSeries[1]];

        if([1].includes(this.reportType)) {
          this.salesLineSeries.push(this.allLineSeries[2]);
        }
        if(this.reportType == 2) {
          this.salesLineSeries.push(this.allLineSeries[3]);
        }

        this.updateStrokeDashArray();
        this.updateChartColors();

				this.orderBarChartLabel = val.salesLineChartLabel;
				this.orderBarChartData[0].data = JSON.parse(
					JSON.stringify(val.orderBarChartData)
				);
				// apex
				this.saleOrderChartData[0].data = JSON.parse(
					JSON.stringify(val.orderBarChartData)
				);
				this.deliveryBarChartLabel = val.salesLineChartLabel;
				this.delvieryBarChartData[0].data = val.delvieryBarChartData;
				// apex
				this.quoteOrderChartData[0].data = val.delvieryBarChartData;
				

				// await this.$nextTick()

				if (typeof this.$refs.chart1 != "undefined") {
					this.$refs.chart1.renderLineChart();
				}
				if (typeof this.$refs.chart2 != "undefined") {
					this.$refs.chart2.renderBarChart();
				}
				if (typeof this.$refs.chart3 != "undefined") {
					this.$refs.chart3.renderBarChart();
				}
        if (typeof this.$refs.apex != "undefined") {
          this.$refs.apex.renderLineChart();
          this.$refs.apex.updateSeries(this.salesLineSeries, false, true);
        }
			} catch (e) {
				// console.log("error in chart watch", e);
			}
			// }, 200)
		} catch (e) {
			// console.log("exception", e);
		}
    // this.isLoadingChart = true;
    // setTimeout(() => {
    //   this.isLoadingChart = false;
    // }, 1);
	}

	async pdfDownLoadFn() {
		this.exportObj["fYear"] = this.items.sortingYear;
		this.exportObj["functions"] = "DataOrderTrend,DataChart,CategoryTrend";
		this.exportObj["view"] = this.dashView;
		this.exportObj["Unit"] = "both";
		this.exportObj["selectedView"] = this.selectedView;
		this.exportObj["tagIDs"] = this.tagIDs.join();
		this.exportObj["gTagIDs"] = this.gTagIDs.join();
		this.exportObj["groupTagIDs"] = this.groupTagIDs.join();
		this.exportObj["businessLines"] = this.businessLineIds.join(',');

		var downLoadRes = getRouteData(this.exportObj);
		downLoadRes.then(function (response, statusText, jqXHR) {
			try {
				if (response.data.STATUS) {
					var link = document.createElement("a");
					link.download = "Quotes";
					link.href = response.data.S3URL;
					link.target = "_blank";
					link.click();
				} else if (response.data.ERROR) {
					// console.log(response.data.ERROR);
				}
			} catch (e) {
				//handle error
				// console.log("quotes.js : csvDownLoadFn error : ", e);
			}
		});
	}

	async printFn() {
		var exportObj = {
			controller: "Dashboard",
			FunctionName: "Export",
			uploadPath: "Inbound/apiFiles/",
			keyName: "Inbound/apiFiles/",
			ExportType: "PDF",
			Unit: "both",
			figure: "1,4,7",
			print: "yes",
			functions: "DataOrderTrend,DataChart,CategoryTrend,ShipmentTrend",
			userRole: sessionStorage.getItem("userRole"),
			fYear: this.items.sortingYear,
			view: this.dashView,
			selectedView: this.selectedView,
			tagIDs: this.tagIDs.join(),
			gTagIDs: this.gTagIDs.join(),
			groupTagIDs: this.groupTagIDs.join(),
			businessLines: this.businessLineIds.join(',')
		};

		var response = getRouteData(exportObj);
		response.then(function (response, statusText, jqXHR) {
			var supportList = response.data;
			var html = template(supportList);
			printHtml(html);
		});
	}

	yearSorting(year) {
		this.items.sortingYear = year;
		this.items["dataOrderTrend"] = {
      SALESTHISYEARFORMATTED: '',
      COMMISSION: '',
      SALESTHISYEARTREND: 0
    };
		this.fetchDashboardData(this.$root, year);
	}

  isFirstLoad = true;
	async fetchDashboardData(container, year) {
		this.hasBLFilterChange = false;
		var yearValue = year;
		if(yearValue == '12 Months') {
			this.totalUserSalesTTVisible = 0;
			this.totalUserCommTTVisible = 0;
		} else {
			this.totalUserSalesTTVisible = 1;
			this.totalUserCommTTVisible = 1;
		}
		if (this.quoteChecked) {
			this.salesLineChartData = [
				{
					label: "Prior Year",
					backgroundColor: "transparent",
					borderColor: "#3c3564",
					borderWidth: 3,
					name: "sales",
					linetype: "Orders",
					fill: true,
					borderDash: [5, 5],
					data: [],
				},
				{
					label: "This Year Orders",
					backgroundColor: "transparent",
					borderColor: "#009cff",
					linetype: "Orders",
					name: "sales",
					borderWidth: 3,
					fill: true,
					borderDash: [5, 5],
					data: [],
				},
				{
					label: "This Year Quotes",
					backgroundColor: "transparent",
					borderColor: "#3c3564",
					name: "sales",
					linetype: "Quotes",
					borderWidth: 3,
					fill: true,
					borderDash: [5, 5],
					data: [],
				},
			];
		} else {
			this.salesLineChartData = [
				{
					label: "Prior Year",
					backgroundColor: "transparent",
					borderColor: "##b7b6ba",
					borderWidth: 3,
					name: "sales",
					linetype: "Orders",
					fill: true,
					borderDash: [5, 5],
					data: [],
				},
				{
					label: "This Year",
					backgroundColor: "#E3F2FD",
					borderColor: "#009cff",
					name: "sales",
					linetype: "Orders",
					borderWidth: 3,
					fill: true,
					data: [],
				},
			];
		}
		this.loading = true;
		this.selectedFilters = [
			"Quote",
			"Order",
			"Invoice",
			"Account",
			"Support",
			"System",
		]; //reset selected filters for status updates
		try {

			if (this.quoteChecked) {
				this.quotesLineChartData[1].borderColor = "#b7b6ba";
			} else {
				this.quotesLineChartData[1].borderColor = "#009cff";
			}
			var dataChartObj = {
				// OrderData
				controller: "Dashboard",
				FunctionName: "Main",
				ReportType: "Orders",
				DataType: "1,2,3,4,5",
				view: this.dashView,
				Unit: "both",
				customerId: this.customerid,
				fyear: year,
				isQuote: this.quoteChecked ? 1 : 0,
				isOpportunities: this.oppChecked ? 1 : 0,

				// categoryTrendObj
				TopNCategory: "6",
				selectedView: this.selectedView,
				// selectedGlobalTag: this.selectedGlobalTag,
				tagIDs: this.tagIDs.join(),
				gTagIDs: this.gTagIDs.join(),
				groupTagIDs: this.groupTagIDs.join(),
				businessLines: this.businessLineIds.join(','),
				allowBoardDetails: true,
				BoardYear: year == '12 Months' ? this.currYear : year
			};

			// const resultOrderData = getRouteData(dataChartObj);
			const resultData = await dashboardRepo.fetchData(dataChartObj);

			this.years = resultData.YEARS;
			this.breakDown = resultData.BREAKDOWN;

      if (resultData.ALLOWEDFUNCTIONS.includes('59')) {
        this.showDeployments = true;
      }
			// this.resultOrderData = resultOrderData
			// resultOrderData.then(function (response, statusText, jqXHR) {
			try {

        this.items = {
          ...this.items,
          sortingYear: year,
          categoryTrend: [],
          shipmentTrend: [{ AVGDAYHOUR: "" }],
          // dataOrderTrend: [],
        };

        this.items["salesLineChartData1"] = [];
        this.items["salesLineChartData2"] = [];
        this.items["salesLineChartData3"] = [];
        this.items["orderBarChartData"] = [];
        this.items["salesLineChartLabel"] = [];
        this.items["OrderLineChartData1"] = [];
        this.items["OrderLineChartData2"] = [];
        this.items["quotesLineChartData1"] = [];
        this.items["quotesLineChartData2"] = [];

				var salesThisYear = resultData.MONTHDETAILSTHISYEAR;
				// var quotesThisYear = resultData.MONTHQUOTESTHISYEAR;
				var salesPrevYear = resultData.MONTHDETAILSLASTYEAR;

				var ShippmentThisYear = resultData.MONTHSHIPPEDDETAILS.length ? resultData.MONTHSHIPPEDDETAILS : [0,0,0,0,0,0,0,0,0,0,0,0];
				var orderThisYear = resultData.MONTHORDERSTHISYEAR;
				var quotesThisYear = resultData.MONTHQUOTESTHISYEAR;
				var orderLastYear = resultData.MONTHORDERSLASTYEAR;
				var quotesLastYear = resultData.MONTHQUOTESLASTYEAR;
				var monthNames: string[] = [];
        const totalActiveDeployments = resultData.TOTALACTIVEDEPLOYMENTS || 0;
        const avgPercentDeployments = resultData.AVGPERCENTDEPLOYMENTS || 0;
				if (year == "12 Months") {
					monthNames = resultData.MONTHNAMES;
					this.ApiMonths = resultData.MONTHNAMES;
				} else {
					monthNames = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
				}
				var salesLineChartData1: any[] = [];
				var salesLineChartData2: any = [];
				var salesLineChartData3: any = [];
				var OrderLineChartData1: any[] = [];
				var OrderLineChartData2: any[] = [];
				var quotesLineChartData1: any[] = [];
				var quotesLineChartData2: any[] = [];
				var orderBarChartData: any[] = [];
				var salesLineChartLabel: string[] = [];
				var delvieryBarChartData: any = [];
				var today = new Date();
				let priorYear = "";
				var fullMonths: string[] = [];
				for (const month in monthNames) {
					// if (parseInt(month) <= today.getMonth()) {
					for (const data in salesThisYear) {
						if (monthNames[month] == salesThisYear[data].MONTHSTRING) {
							salesLineChartData1[month] = salesThisYear[data].TOTALPRICE;
							year = salesThisYear[data].SALESYEAR;
							break;
						} else {
							salesLineChartData1[month] = 0;
						}
						// if (parseInt(month)+1 == salesThisYear[data].SALESMONTH) {
						//     salesLineChartData1[month] =  salesThisYear[data].TOTALPRICE;
						//   year = salesThisYear[data].SALESYEAR;
						//   break;
						// } else {
						//       salesLineChartData1[month] = 0;

						// }
					}

					for (const data in quotesThisYear) {
						if (monthNames[month] == quotesThisYear[data].MONTHSTRING) {
							salesLineChartData3[month] = quotesThisYear[data].TOTALPRICE;
							priorYear = quotesThisYear[data].SALESYEAR;
							break;
						} else {
							salesLineChartData3[month] = 0;
						}
						// if (parseInt(month) + 1 == salesPrevYear[data].SALESMONTH) {
						//   salesLineChartData2[month] = salesPrevYear[data].TOTALPRICE;
						//   break;
						// } else {
						//   salesLineChartData2[month] = 0;
						// }
					}

					for (const data in salesPrevYear) {
						if (monthNames[month] == salesPrevYear[data].MONTHSTRING) {
							salesLineChartData2[month] = salesPrevYear[data].TOTALPRICE;
							priorYear = salesPrevYear[data].SALESYEAR;
							break;
						} else {
							salesLineChartData2[month] = 0;
						}
						// if (parseInt(month) + 1 == salesPrevYear[data].SALESMONTH) {
						//   salesLineChartData2[month] = salesPrevYear[data].TOTALPRICE;
						//   break;
						// } else {
						//   salesLineChartData2[month] = 0;
						// }
					}

					for (const data in salesThisYear) {
						if (monthNames[month] == salesThisYear[data].MONTHSTRING) {
							orderBarChartData[month] = salesThisYear[data].TOTALQUANTITY;
							break;
						} else {
							orderBarChartData[month] = 0;
						}
						// if (parseInt(month) + 1 == salesThisYear[data].SALESMONTH) {
							//     orderBarChartData[month] = salesThisYear[data].TOTALQUANTITY;
						//   year = salesThisYear[data].SALESYEAR;
						//   break;
						// } else {
							//       orderBarChartData[month] = 0;
						// }
					}
					if (isNaN(this.items.sortingYear)) {
						fullMonths.push(monthNames[month] + "-" + last12[month].split(", ")[1]);
					} else {
						fullMonths.push(monthNames[month] + "-" + this.items.sortingYear);
					}
					// this.chartOptions.xaxis.categories = fullMonths;
					this.barOptions.xaxis.categories = fullMonths;
					

					for (const data in ShippmentThisYear) {
						if (parseInt(month) + 1 == ShippmentThisYear[data].MONTH) {
							delvieryBarChartData[month] = ShippmentThisYear[data].AVERAGEDAYS;
							break;
						} else {
							delvieryBarChartData[month] = 0;
						}
					}
					for (const data in orderThisYear) {
						if (monthNames[month] == orderThisYear[data].MONTHSTRING) {
							OrderLineChartData1[month] = orderThisYear[data].TOTALQUANTITY;
							year = orderThisYear[data].SALESYEAR;
							break;
						} else {
							OrderLineChartData1[month] = 0;
						}
					}
					for (const data in orderLastYear) {
						if (monthNames[month] == orderLastYear[data].MONTHSTRING) {
							OrderLineChartData2[month] = orderLastYear[data].TOTALQUANTITY;
							break;
						} else {
							OrderLineChartData2[month] = 0;
						}
					}
					for (const data in quotesThisYear) {
						if (monthNames[month] == quotesThisYear[data].MONTHSTRING) {
							quotesLineChartData1[month] = quotesThisYear[data].TOTALQUANTITY;
							year = quotesThisYear[data].SALESYEAR;
							break;
						} else {
							quotesLineChartData1[month] = 0;
						}
					}
					for (const data in quotesLastYear) {
						if (monthNames[month] == quotesLastYear[data].MONTHSTRING) {
							quotesLineChartData2[month] = quotesLastYear[data].TOTALQUANTITY;
							break;
						} else {
							quotesLineChartData2[month] = 0;
						}
					}
					// if(year == '12 Months') {
					//   year = today.getFullYear();
					// }
					salesLineChartLabel[month] = monthNames[month] + "-" + year;
					// }
				}
				this.items["salesLineChartData1"] = salesLineChartData1;
				this.items["salesLineChartData2"] = salesLineChartData2;
				this.items["salesLineChartData3"] = salesLineChartData3;
				this.items["OrderLineChartData1"] = OrderLineChartData1;
				this.items["OrderLineChartData2"] = OrderLineChartData2;
				this.items["quotesLineChartData1"] = quotesLineChartData1;
				this.items["quotesLineChartData2"] = quotesLineChartData2;
				this.items["orderBarChartData"] = orderBarChartData;
				this.items["delvieryBarChartData"] = delvieryBarChartData;
				this.items["salesLineChartLabel"] = salesLineChartLabel;
				this.items.todaySales = resultData.todaySales || 0;
				this.items.todaySalesFormatted = resultData.todaySalesFormatted || dollarFormat(0);
				this.items.todayProfit = resultData.todayProfit || 0;
				this.items.todayProfitFormatted = resultData.todayProfitFormatted || dollarFormat(0);
        this.items.activeDeployments.total = totalActiveDeployments;
        this.items.activeDeployments.percent = avgPercentDeployments.toFixed(2);
				if (
					!this.selectedView &&
					typeof resultData["userSelectedCurrentView"] != "undefined"
				) {
					this.selectedView = true;
					this.dashView = resultData["userSelectedCurrentView"];
					this.CurrentView =
						this.dashView == "All" ? "Company Datapoints" : "My Datapoints";
				}

				this.businessLines = resultData.BUSINESSLINES;
				if (typeof resultData.BUSINESSLINES != "undefined" && this.businessLineIds.length == 0) {
					for (let i = 0; i < resultData.BUSINESSLINES.length; i++) {
						this.businessLineIds.push(String(resultData.BUSINESSLINES[i].id));
						this.businessLineName.push(resultData.BUSINESSLINES[i].name);
					}
				}
				this.accountTags = resultData.accountTags.map((item) => {
          let tagName = item.TAGNAME;
          tagName = tagName.replace('Company Customers', 'Company Datapoints');
          tagName = tagName.replace('My Customers', 'My Datapoints');
          item.TAGNAME = tagName;
          return item;
        });
				this.isChecked = resultData.FILTEREDBYALLDATES || false;
			} catch (e) {
				//handle error
			}
			// });
			// resultCategoryTrend = getRouteData(categoryTrendObj);
			// resultCategoryTrend.then(function (response, statusText, jqXHR) {
			try {
				this.loading = false;
				this.isFirstLoad = false;
				this.items["categoryTrend"] = resultData.TOPNCATEGORYQTY;
				this.boardList = resultData.BOARDLIST || [];
			} catch (e) {
				//handle error
			}
			// });

			// resultShipmentTrend = getRouteData(ShipmentTrend);
			// resultShipmentTrend.then(function (response, statusText, jqXHR) {
			try {
				this.items["shipmentTrend"] = resultData.AVGSHIPPEDTIME;
			} catch (e) {
				//handle error
			}
			// });
			if (this.customerid == 0) {
				try {
					this.items["dataOrderTrend"] = resultData;
				} catch (e) {
					//handle error
				}
			}

			this.activities =
				typeof resultData.ACTIVITIES != "undefined"
					? resultData.ACTIVITIES
					: [];

			this.YTDSalesPerTag = typeof resultData.YTDSalesPerTag != "undefined" ? resultData.YTDSalesPerTag : [];
			// });
		} catch (err) {
			if(sessionStorage.getItem("userFname") != null){
				notifier.alert(err.message);
			}
		} finally {
			this.loading = false;
			if (this.isInitialLoad) {
				sessionStorage.removeItem('isInitialLoad');
				this.isInitialLoad = false;
			}
			setTimeout(() => {
				if($("#dashboardClone div").length === 0){
					var clonedDiv = $(".main.dashboard-page").clone();
					clonedDiv.hide();
					$("#dashboardClone").html(clonedDiv);
				}
			}, 100);
		}
	}

	async debounce(func, wait, immediate) {
		var timeout;
		return function (this: any) {
			var context = this,
				args = arguments;
			clearTimeout(timeout);
			timeout = setTimeout(function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			}, wait);
			if (immediate && !timeout) func.apply(context, args);
		};
	}

	selectView(item, e) {
		if (isNaN(item.ACCOUNTTAGID)) {
			// selected my customers / company customers
			this.tagIDs = [];
			this.gTagIDs = [];
			this.groupTagIDs = [];
			this.selectedTags = []; // reset
      let tagName = item.TAGNAME;
      tagName = tagName.replace('Company Customers', 'Company Datapoints');
      tagName = tagName.replace('My Customers', 'My Datapoints');
			this.CurrentView = tagName;
			this.dashView = item.ACCOUNTTAGID;
			// if(typeof item.ISGLOBALTAG != "undefined" && item.ISGLOBALTAG) {//selected a global tag
			//   this.dashView = item.GTAGID;
			//   this.selectedGlobalTag = true;
			// }else {
			//   this.dashView = item.ACCOUNTTAGID;
			//   this.selectedGlobalTag = false;
			// }
			this.selectedView = true;
			this.fetchDashboardData(this.$root, this.items.sortingYear);
		} else {
			e.stopPropagation();
		}
	}

	filterByTag() {
		// selected a tag/gtag
		this.tagIDs = [];
		this.gTagIDs = [];
		this.groupTagIDs = [];
		this.accountTagName = [];
		this.gTagName = [];
		this.groupName = [];
		if (this.selectedTags.length) {
			this.selectedView = true;
			this.CurrentView = this.getCurrentViewTitle();
			this.dashView = "1"; //just set a number
			for (let i in this.selectedTags) {
				let item: any = this.selectedTags[i];
				if (item.ISGLOBALTAG) {
					this.gTagIDs.push(item.GTAGID);
					this.gTagName.push(item.TAGNAME);
				} else if (item.ISGROUP) {
					this.groupTagIDs.push(item.ACCOUNTTAGID);
					this.groupName.push(item.TAGNAME);
				} else {
					this.tagIDs.push(item.ACCOUNTTAGID);
					this.accountTagName.push(item.TAGNAME);
				}
			}
		} else {
			// reset
			this.selectedView = false;
		}
		this.fetchDashboardData(this.$root, this.items.sortingYear);
	}

	getCurrentViewTitle() {
		if (this.selectedTags.length) {
			return this.selectedTags.map((tmp: any) => tmp.TAGNAME).join(", ");
		}

		return "";
	}
	categories(){
		var monthNames;
		monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		if(this.ApiMonths.length && isNaN(this.items.sortingYear)){
			monthNames = this.ApiMonths;
		}
		var fullMonths:string[] = [];
		for (const month in monthNames) {
			if (isNaN(this.items.sortingYear)) {
				// fullMonths.push(monthNames[month] + "-" + last12[month].split(", ")[1]);
				fullMonths.push(monthNames[month]);
			} else {
				// fullMonths.push(monthNames[month] + "-" + this.items.sortingYear);
				fullMonths.push(monthNames[month]);
			}
		}
		return fullMonths;
	}
	saleRedirect(year,monthtitle,index,itemIndex,monthStr){
		var monthasNum = ["01","02","03","04","05","06","07","08","09","10","11","12"];
	
		var monthNames = {
					Jan: "01",
					Feb: "02",
					Mar: "03",
					Apr: "04",
					May: "05",
					Jun: "06",
					Jul: "07",
					Aug: "08",
					Sep: "09",
					Oct: "10",
					Nov: "11",
					Dec: "12",
				};
		var month = monthasNum[itemIndex];
		var sDate;
		var eDate;
		
		if(isNaN(this.items.sortingYear)){
			month = monthNames[monthStr];
			let today = new Date();
			let currentMonth = today.getMonth() + 1;
			if (parseInt(month) > currentMonth) {
				year = today.getFullYear() - 1;
			} else {
				year = today.getFullYear();
			}
			if (itemIndex == 11) {
				sDate = year + "-" + month + "-01";
				eDate = moment().format("YYYY-MM-DD");
			} else {
				sDate = year + "-" + month + "-01";
				eDate = year + "-" + month + "-" + this.lastday(year, month);
			}
		} else {
			sDate = year + "-" + month + "-01";
			eDate = year + "-" + month + "-" + this.lastday(year, month);
		}
		
		// if (!index) {
		// 	year = year - 1;
		// }
		var filterView =
			this.gTagIDs.length || this.tagIDs.length || this.groupTagIDs.length ? 1 : this.dashView;
		var routeQuery: any = {
			sDate: sDate,
			eDate: eDate,
			filterView: filterView,
			viewFrom: "dashboard",
			tagIDs: this.tagIDs,
			gTagIDs: this.gTagIDs,
			businessLineId: this.businessLineIds.map(item => String(item)),
			businessLineName: this.businessLineName,
			groupIDs: this.groupTagIDs,
			viewPage: 'dashboard'
		};
		var orderIndex = [0, 1];
		var currItem = orderIndex.indexOf(index) != -1 || !this.quoteChecked ? "Orders" : "Quotes";
		if (this.oppChecked) {
			currItem = "Opportunities";
		}
		if(currItem == "Quotes") {
			routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7", "8"];
		} else if (currItem == "Orders") {
			routeQuery["sStatus"] = ["0","1","2","3","4","5","6","10","13"];
		}
		// if (currItem == "Orders") {
		// 	routeQuery["sStatus"] = ["0", "1", "2", "3", "4", "5"];
		// } else if (currItem == "Quotes") {
		// 	routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7", "8"];
		// }

		this.$router.push({
			name: currItem,
			query: routeQuery,
		});

	}

	totalSaleEnableClick(point, e, tooltipModel, data, tooltipObj) {
		if (e.length) {
			const item = e[0];
			var currItem =
				data.datasets[tooltipObj._active[0]._datasetIndex].linetype;
			if (typeof item != "undefined" && tooltipModel.body) {
				var titleLines = tooltipModel.title[0] || [];
				var titleLinesSplit = titleLines.split("-");
				var tooltip = tooltipModel.body[0].lines[0];
				var tooltipSplit = tooltip.split(" ");
				// Skip redirect for ordr Amount zero
				if (
					tooltipSplit.length &&
					parseFloat(tooltipSplit[tooltipSplit.length - 1]) == 0
				) {
					return;
				}

				var monthNames = {
					Jan: "01",
					Feb: "02",
					Mar: "03",
					Apr: "04",
					May: "05",
					Jun: "06",
					Jul: "07",
					Aug: "08",
					Sep: "09",
					Oct: "10",
					Nov: "11",
					Dec: "12",
				};

				var year = titleLinesSplit[1];
				var month = monthNames[titleLinesSplit[0]];

				if (tooltip.startsWith("Prior Year")) {
					year -= 1;
				}

				var sDate = year + "-" + month + "-01";
				var eDate = year + "-" + month + "-" + this.lastday(year, month);
				var filterView =
					this.gTagIDs.length || this.tagIDs.length || this.groupTagIDs.length ? 1 : this.dashView;

				//hide tooltip
				tooltipModel.opacity = 0;
				$("#chartjs-tooltip").css({ opacity: 0 });
				var routeQuery: any = {
					sDate: sDate,
					eDate: eDate,
					filterView: filterView,
					viewFrom: "dashboard",
					tagIDs: this.tagIDs,
					gTagIDs: this.gTagIDs,
					groupIDs: this.groupTagIDs,
					viewPage: 'dashboard'
				};

				// routeQuery["selectedGlobalTag"] = this.selectedGlobalTag;
				if (currItem == "Orders") {
					routeQuery["sStatus"] = ["0", "1", "2", "3", "4", "5"];
				} else if (currItem == "Quotes") {
					routeQuery["qOpenStatus"] = ["0", "1", "2", "5", "6", "7"];
				}

				this.$router.push({
					name: currItem,
					query: routeQuery,
				});
			}
		}
	}
	lastday(y, m) {
		return new Date(y, m, 0).getDate();
	}

	getSalesThisYearPercent(data) {
		var ret = data.SALESTHISYEARTRENDPERCENT;
		if (this.items.sortingYear == this.currYear) {
			//override for current year
			if (data.SALESLASTYEAR > 0) {
				ret =
					Math.round((data.SALESTHISYEAR / data.SALESLASTYEAR) * 100) +
					"% of Prior Year";
			} else if (data.SALESTHISYEAR > 0) {
				ret = "100% of Prior Year";
			}
		}
		return ret;
	}

	// getSalesThisYearPercent2(data) {
	// 	var ret = `<span>${data.SALESTHISYEARTRENDPERCENT}</span> <small>Prior Year</small>`;
	// 	if (this.items.sortingYear == this.currYear) {
	// 		//override for current year
	// 		if (data.SALESLASTYEAR > 0) {
  //       const percent = Math.round((data.SALESTHISYEAR / data.SALESLASTYEAR) * 100);
	// 			ret = `<span class="${percent >= 100 ? 'dashboardPositive' : ''}">${percent}%</span> <small>Prior Year</small>`;
	// 		} else if (data.SALESTHISYEAR > 0) {
	// 			ret = `<span class="dashboardPositive">100%</span> <small>Prior Year</small>`;
	// 		}
	// 	}
	// 	return ret;
	// }

	// getSalesThisYearPercentTotal(data) {
	// 	var ret = data.SALESTHISYEARTRENDPERCENT;
	// 	if (this.items.sortingYear == this.currYear) {
	// 		//override for current year
	// 		if (data.SALESLASTYEAR > 0) {
	// 			ret = `${Math.round((data.SALESTHISYEAR / data.SALESLASTYEAR) * 100)}%`;
	// 		} else if (data.SALESTHISYEAR > 0) {
	// 			ret = `100%`;
	// 		}
	// 	}
	// 	return ret;
	// }
	// getCommTrendPercent2(data) {
	// 	var ret = `<span>${data.COMMISSIONTRENDPERCENT}</span> <small>Prior Year</small>`;
	// 	if (
	// 		this.$root.getUserRole() === "Reseller" &&
	// 		this.items.sortingYear == this.currYear
	// 	) {
	// 		//override for current year
	// 		if (data.PREVCOMMISSIONVAL > 0) {
  //       const percent = Math.round((data.COMMISSIONVAL / data.PREVCOMMISSIONVAL) * 100);
  //       ret = `<span class="${percent >= 100 ? 'dashboardPositive' : ''}">${percent}%</span> <small>Prior Year</small>`;
	// 		} else if (data.COMMISSIONVAL > 0) {
  //       ret = `<span class="dashboardPositive">100%</span> <small>Prior Year</small>`;
	// 		}
	// 	}
	// 	return ret;
	// }
	// getCommTrendPercentTotal(data) {
	// 	var ret = data.COMMISSIONTRENDPERCENT;
	// 	if (
	// 		this.$root.getUserRole() === "Reseller" &&
	// 		this.items.sortingYear == this.currYear
	// 	) {
	// 		//override for current year
	// 		if (data.PREVCOMMISSIONVAL > 0) {
	// 			ret = `${Math.round((data.COMMISSIONVAL / data.PREVCOMMISSIONVAL) * 100)}%`;
	// 		} else if (data.COMMISSIONVAL > 0) {
	// 			ret = "100%";
	// 		}
	// 	}
	// 	return ret;
	// }
	getCommTrendPercent(data) {
		var ret = data.COMMISSIONTRENDPERCENT;
		if (
			this.$root.getUserRole() === "Reseller" &&
			this.items.sortingYear == this.currYear
		) {
			//override for current year
			if (data.PREVCOMMISSIONVAL > 0) {
				ret =
					Math.round((data.COMMISSIONVAL / data.PREVCOMMISSIONVAL) * 100) +
					"% of Prior Year";
			} else if (data.COMMISSIONVAL > 0) {
				ret = "100% of Prior Year";
			}
		}
		return ret;
	}

	buildActivitiesDetails(details) {
		var ret = details;
		if (Array.isArray(details)) {
			ret = details.join(". ");
		}

		return ret;
	}
	
	removeHTML(details){
		var ret = details;
		if (Array.isArray(details)) {
			ret = details.join(". ");
		}

		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = ret;
		ret = tempDiv.textContent || tempDiv.innerText || '';

		return ret;
	}

	getActivityType(typeID) {
		var ret = "";
		if ([1, 2, 3, 31].includes(typeID)) {
			ret = "order";
		} else if ([5, 6, 7].includes(typeID)) {
			ret = "quote";
		} else if ([9, 10, 11, 12].includes(typeID)) {
			ret = "account";
		} else if ([13, 14, 15].includes(typeID)) {
			ret = "invoice";
		} else if ([18, 19].includes(typeID)) {
			ret = "paycheck";
		} else if ([20].includes(typeID)) {
			ret = "user_login";
		} else if ([21, 22, 23].includes(typeID)) {
			ret = "support";
		}

		return ret;
	}

	activitiesLoading = false;
	// selectedActivityFilters = false;
	async selectedFiltersChange(selectedFilters) {
		this.selectedFilters = selectedFilters;
		// this.selectedActivityFilters = true;
		this.activitiesLoading = true;
		try {
			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller: "Queries",
				subsystem: "Helpers",
				FunctionName: "activities",
				limitActivities: 20,
				activityType: this.selectedFilters.join(","),
				view: this.dashView,
				fyear: this.items.sortingYear,
				tagIDs: this.tagIDs.join(),
				gTagIDs: this.gTagIDs.join(),
			});

			if (response.data.ERROR) {
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS !== 1 && response.data.STATUSMESSAGE) {
				throw new Error(response.data.STATUSMESSAGE);
			}

			if (response.data.STATUS == 1) {
				this.activities = response.data.ACTIVITIES;
			}
		} catch (err) {
			// console.log(err.message);
		} finally {
			this.activitiesLoading = false;
		}
	}

	// build activity filtered options
	buildActivityFilteredOptions() {
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Quote_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Quotes)
		) {
			this.activitiesFilters.push({ id: "Quote", text: "Quote" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Order_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Orders)
		) {
			this.activitiesFilters.push({ id: "Order", text: "Order" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Invoice_Details) &&
			this.$allowedFunctions.includes(this.$userFunctions.Invoices)
		) {
			this.activitiesFilters.push({ id: "Invoice", text: "Invoice" });
		}
		if (
			this.$allowedFunctions.includes(this.$userFunctions.Customers) ||
			this.$allowedFunctions.includes(
				this.$userFunctions.All_Milestone_Accounts
			)
		) {
			this.activitiesFilters.push({ id: "Account", text: "Account" });
		}
		if (this.$allowedFunctions.includes(this.$userFunctions.Support)) {
			this.activitiesFilters.push({ id: "Support", text: "Support" });
		}
		if (this.$systemGroups.includes("sys_admin")) {
			this.activitiesFilters.push({ id: "System", text: "System" });
		}
	}

	async formatLabel(am) {
		// console.log("am", am);
		return am;
	}

  reportType = 0;
	async dashboardReport(reportType) {
		/*
    if(reportType == 1) {
			this.dashboardReportTitle ="Quotes";
			this.quoteChecked = true;
		} else {
			this.dashboardReportTitle ="Orders";
			this.quoteChecked = false;
		}
    */
    this.reportType = reportType;
    this.oppChecked = false;	
    switch (reportType) {
      case 0:
        this.dashboardReportTitle = 'Orders';
        this.quoteChecked = false;
        break;
      case 1:
        this.dashboardReportTitle = 'Quotes';
        this.quoteChecked = true;
        break;
      case 2:
        this.dashboardReportTitle = 'Opportunities';
        this.quoteChecked = false;
        this.oppChecked = true;
        break;
    }
		this.fetchDashboardData(this,this.items.sortingYear)
	}

  getPercentValue(value) {
    const percent = Number.parseInt(`${value}`.replace('%', ''), 10);
    return percent ? percent : 0;
  }

  getDateFormatted(value) {
    const datetime = moment(value);
    return datetime.format('MM/DD/YY');
  }

  getInvoiceDate(year, type = '') {
    if (year != '12 Months') {
      switch (type) {
        case 'start':
          return `${year}-01-01`;
        default:
          return `${year}-12-31`;
      }
    } else {
      const endDate = moment();
      const startDate = endDate.clone().subtract(1, 'year');
      switch (type) {
        case 'start':
          return startDate.format('YYYY-MM-DD');
        default:
          return endDate.format('YYYY-MM-DD');
      }
    }
  }

	async handleSwitchChange() {
		this.isDisabled = true;
		let dataObj = {
			controller: "Users",
			FunctionName: "paramsUpdate",
			from: "dashboard",
			filteredByAllDates: this.isChecked
		};

		const response = await ApiHelper.callApi('post', dataObj);

		if (response.STATUS === 1) {
			notifier.success("Successfully updated the Filtered By Dates for this User");
		} else {
			notifier.alert(response.STATUSMESSAGE);
		}
		this.isDisabled = false;
	}

	async checkBusinessLineList(bLineId: string, name: string) {
		this.resetBLIdArr()
		if (this.businessLineIds != undefined && this.businessLineName != undefined) {
			const i = this.businessLineIds.findIndex(s => s === bLineId);
			// const bLineIndex = this.businessLineName.findIndex(
			// 	bLineValue => bLineValue === name
			// );
			if (i === -1) {
				this.businessLineIds.push(bLineId);
				this.businessLineName.push(name);
			} else {
				this.businessLineIds.splice(i, 1);
				this.businessLineName.splice(i, 1);
			}
			// if (bLineIndex === -1) {
			// 	this.businessLineName.push(name);
			// } else {
			// 	this.businessLineName.splice(bLineIndex, 1);
			// }
		}
		if (!this.businessLineIds.length) {
			this.uncheckBL()
		}
		this.hasBLFilterChange = true;
  	}
	uncheckAllBLFilter() {
		this.uncheckBL()
		this.fetchDashboardData(this.$root, this.items.sortingYear);
	}
	resetBLFilter() {
		this.resetBLIdArr()
		for (let i = 0; i < this.businessLines.length; i++) {
			const index = this.businessLineIds.findIndex(s => s === this.businessLines[i].id);
			if (index === -1) {
				this.businessLineIds.push(String(this.businessLines[i].id));
				this.businessLineName.push(this.businessLines[i].name);
			}
		}
		this.fetchDashboardData(this.$root, this.items.sortingYear);
  	}
	resetBLIdArr() {
		const index = this.businessLineIds.findIndex(s => s == '-1')
		if (index >= 0) {
			this.businessLineIds = [];
			this.businessLineName = [];
		}
	}
	uncheckBL() {
		this.businessLineIds = ['-1'];
		this.businessLineName = ['None'];
	}
	handleBLClick(e) {
		e.stopPropagation();
	}
}
