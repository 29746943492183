






















































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import StoresAutoSuggestInput from "@/components/StoresAutoSuggestInput.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";


declare const $: any

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    StoresAutoSuggestInput
  }
})
export default class AddFinancialBoardModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement
  }

  @Prop({ required: false, default: "Add Stores" })
  title!: string;

  @Prop({ required: false, default: 0 })
  standardID!: number;

  $validator: any;
  saving: any = false;
  selectedStore: any = [];


  mounted() {
    $(this.$refs.modal).modal('show');

    $(this.$refs.modal).on('hide.bs.modal', () => {
      this.$emit('close')
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal('hide')
  }

  updateStore(data) {
    if (data.stores) {
      const index = this.selectedStore.findIndex((item: any) => item.PORTALACCOUNTID === data.stores.PORTALACCOUNTID);
      if (index === -1) {
        this.selectedStore.push({
          ACCOUNTNAME: data.stores.ACCOUNTNAME || '',
          PORTALACCOUNTID: data.stores.PORTALACCOUNTID || 0
        });
      }
    }
  }
  
  updateStoreList(index, id) {
    this.$delete(this.selectedStore, index);
  }

  async save() {
    const valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    if (!this.selectedStore.length) {
      notifier.alert("Please select any one Store");
      return;
    }

    try {
      this.saving = true;
      let selectedStoreIds = this.selectedStore.map((item: any) => item.PORTALACCOUNTID);
      
      const response = await ApiHelper.callApi("post", {
        controller: "Quotes",
        FunctionName: "LinkStoresToStandard",
        standardID: this.standardID,
        selectedStoreIds: selectedStoreIds.join(),
      });

      if (response.STATUS === 1) {
        this.saving = false;
        notifier.success(response.STATUSMESSAGE);
        this.$emit('reload');
      } else {
        this.saving = 'error';
        notifier.alert(response.STATUSMESSAGE);
      }
    } catch (error) {
      // console.log('error ', error);
    }
  }
}
