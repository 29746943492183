var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-builder-details-page allow-expand-full",attrs:{"id":"container"}},[_c('add-header'),_c('div',{staticClass:"profile-header up",attrs:{"id":"page-header"}},[_c('div',{staticClass:"d-inline-flex align-items-center",attrs:{"id":"page-title-container"}},[_c('span',{staticClass:"page-title left"},[_c('span',{attrs:{"id":"previousIcon"},on:{"click":function($event){return _vm.$router.go(-1)}}})]),_c('span',{staticClass:"page-title left bg-transparent pl-0",attrs:{"data-type":""}},[_vm._v(" "+_vm._s(_vm.reportDetails.REPORTNAME)+" ")])]),(_vm.$root.getUserRole() === 'Reseller')?_c('div',{staticClass:"buttonContainer btnMd m-0"},[_c('span',{staticClass:"actionsBtn btn teal actions w-100 m-0"},[_vm._v("Actions")]),_c('ul',{staticClass:"menu"},[_c('div',[_c('li',[_c('router-link',{attrs:{"to":{
                name: 'ReportBuilder',
                params: {
                  id: parseInt(this.$route.params.id)
                },
                query: {
                  from: 'details'
                }
              }}},[_vm._v(" Edit ")])],1),_c('li',{on:{"click":function($event){_vm.confirmReportDelete = true}}},[_vm._v("Delete")]),_c('li',{on:{"click":function () {
            if (_vm.allowAddAccounts) {
              _vm.showAccountModal = true;
            } else {
              _vm.performExport();
            }
          }}},[_vm._v("Export")])])])]):_vm._e()]),_c('div',{staticClass:"page-list",attrs:{"data-totalall":this.totalAll}},[_c('div',{staticClass:"page-list-container"},[(_vm.loading)?_c('Loader'):_vm._e(),_c('span',{staticClass:"expand-full"}),(_vm.contentView == 'overview')?[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.showingColumns),function(item,index){return _c('th',{key:index,staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(item.header)+" ")]),_c('span',{staticClass:"filterContainer right clickbox"})])])}),0)]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},_vm._l((_vm.showingColumns),function(f,fIndex){return _c('td',{key:fIndex,class:{
                hasManyColumns: _vm.showingColumns.length > 12
              }},[(f.field != 'ACCOUNTNAME')?[(_vm.contentType == 'orders')?_c('router-link',{attrs:{"to":{
                      name: 'ViewOrder',
                      params: {
                        id: ("" + (item.PURCHASEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",class:{
                        'up' : f.field === 'PRODUCTLINENAME'
                      },attrs:{"title":[
                        ['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field)
                        ? _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR,
                            true
                          )
                        : item[f.field]
                      ]}},[(['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field))?_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR
                          )
                        )}}):(f.field.indexOf('CUSTOMDATA') != -1)?_c('div',{staticClass:"textOverflow",attrs:{"title":item[_vm.getCustomDataField(item, f.field, f.header)]}},[_vm._v(" "+_vm._s(item[_vm.getCustomDataField(item, f.field, f.header)])+" ")]):[_vm._v(" "+_vm._s(item[f.field])+" ")]],2)]):(_vm.contentType == 'invoices')?_c('router-link',{attrs:{"to":{
                      name: 'InvoiceDetails',
                      params: {
                        id: ("" + (item.INVOICEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",attrs:{"title":item[f.field]}},[_vm._v(" "+_vm._s(item[f.field])+" ")])]):(_vm.contentType == 'quotes')?_c('router-link',{attrs:{"to":{
                      name: 'ViewQuote',
                      params: {
                        id: ("" + (item.QUOTEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",class:{
                        'up' : f.field === 'PRODUCTLINENAME'
                      },attrs:{"title":[
                        ['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field)
                        ? _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR,
                            true
                          )
                        : item[f.field]
                      ]}},[(f.field == 'STATUSASTEXT')?_c('span',[_c('span',{staticClass:"mr-3 icon",class:item.STATUSCOLOR}),_vm._v(" "+_vm._s(item[f.field])+" ")]):(['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field))?_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR
                          )
                        )}}):(f.field.indexOf('CUSTOMDATA') != -1)?_c('div',{staticClass:"textOverflow",attrs:{"title":item[_vm.getCustomDataField(item, f.field, f.header)]}},[_vm._v(" "+_vm._s(item[_vm.getCustomDataField(item, f.field, f.header)])+" ")]):_c('span',[_vm._v(" "+_vm._s(item[f.field])+" ")])])]):_vm._e()]:_c('span',{staticClass:"textOverflow",attrs:{"title":item[f.field]}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"circle"},[(item.LOGOSRC.length)?_c('span',[_c('img',{staticClass:"circle-inner",attrs:{"src":item.LOGOSRC,"alt":"Logo"}})]):_c('span',[_c('span',{staticClass:"circle-initials"},[_vm._v(" "+_vm._s(_vm.getInitials(item.ACCOUNTNAME))+" ")])])]),_c('span',{staticClass:"customerName"},[_vm._v(_vm._s(item[f.field]))])])])],2)}),0)}),0)])]:_vm._e(),(_vm.contentView == 'detailed')?[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.showingColumns),function(item,index){return _c('th',{key:index,staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('div',{staticClass:"left"},[_vm._v(" "+_vm._s(item.header)+" ")]),_c('span',{staticClass:"filterContainer right clickbox"})])])}),0)]),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,attrs:{"data-poliid":item.POLIID}},_vm._l((_vm.showingColumns),function(f,fIndex){return _c('td',{key:fIndex,class:{
                hasManyColumns: _vm.showingColumns.length > 12
              }},[(f.field != 'ACCOUNTNAME')?[(_vm.contentType == 'orders')?_c('router-link',{attrs:{"to":{
                      name: 'ViewOrder',
                      params: {
                        id: ("" + (item.PURCHASEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",class:{
                        'up' : f.field === 'PRODUCTLINENAME'
                      },attrs:{"title":[
                        ['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field)
                        ? _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR,
                            true
                          )
                        : item[f.field]
                      ]}},[(f.header == 'Status')?[_c('span',[_vm._v(_vm._s(item[f.field]))]),(item.LISHIPPEDDATE)?_c('div',[_vm._v(" "+_vm._s(item.LISHIPPEDDATE)+" ")]):_vm._e()]:(['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field))?_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR
                          )
                        )}}):(f.field.indexOf('CUSTOMDATA') != -1)?_c('div',{staticClass:"textOverflow",attrs:{"title":item[_vm.getCustomDataField(item, f.field, f.header)]}},[_vm._v(" "+_vm._s(item[_vm.getCustomDataField(item, f.field, f.header)])+" ")]):_c('span',[_vm._v(_vm._s(item[f.field]))])],2)]):(_vm.contentType == 'invoices')?_c('router-link',{attrs:{"to":{
                      name: 'InvoiceDetails',
                      params: {
                        id: ("" + (item.INVOICEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",attrs:{"title":item[f.field]}},[_vm._v(" "+_vm._s(item[f.field])+" ")])]):(_vm.contentType == 'quotes')?_c('router-link',{attrs:{"to":{
                      name: 'ViewQuote',
                      params: {
                        id: ("" + (item.QUOTEID))
                      }
                    }}},[_c('div',{staticClass:"textOverflow",class:{
                        'up' : f.field === 'PRODUCTLINENAME'
                      },attrs:{"title":[
                        ['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field)
                        ? _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR,
                            true
                          )
                        : item[f.field]
                      ]}},[(f.field == 'STATUSASTEXT')?_c('span',[_c('span',{staticClass:"mr-3 icon",class:item.STATUSCOLOR}),_vm._v(" "+_vm._s(item[f.field])+" ")]):(['COSTPERFORMATTED','PRICEPERFORMATTED','TOTALFORMATTED','GRANDTOTALFORMATTED'].includes(f.field))?_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.priceTypeStr(
                            f.field === 'PRICEPERFORMATTED' && item.TOTALCHARGE
                              ? item.LEASEPRICEFORMATTED
                              : item[f.field],
                            item.ACCTPRICETYPESTR,
                            item.CUSTOMPRICETYPESTR
                          )
                        )}}):(f.field.indexOf('CUSTOMDATA') != -1)?_c('div',{staticClass:"textOverflow",attrs:{"title":item[_vm.getCustomDataField(item, f.field, f.header)]}},[_vm._v(" "+_vm._s(item[_vm.getCustomDataField(item, f.field, f.header)])+" ")]):_c('span',[_vm._v(" "+_vm._s(item[f.field])+" ")])])]):_vm._e()]:_c('span',{staticClass:"textOverflow",attrs:{"title":item[f.field]}},[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"circle"},[(item.LOGOSRC.length)?_c('span',[_c('img',{staticClass:"circle-inner",attrs:{"src":item.LOGOSRC,"alt":"Logo"}})]):_c('span',[_c('span',{staticClass:"circle-initials"},[_vm._v(" "+_vm._s(_vm.getInitials(item.ACCOUNTNAME))+" ")])])]),_c('span',{staticClass:"customerName"},[_vm._v(_vm._s(item[f.field]))])])])],2)}),0)}),0)])]:_vm._e(),(_vm.items.length === 0 && !_vm.loading)?_c('div',{staticClass:"noData"},[_vm._v(" No Records Found ")]):_vm._e()],2)]),_c('Pagination',{attrs:{"CURRENTPAGE":_vm.pageNumber,"TOTALPAGES":_vm.totalPages},on:{"loadPage":_vm.loadPage}}),(_vm.confirmReportDelete)?_c('ConfirmRemoveItemModal',{ref:"confirmRemoveModal",attrs:{"title":'Report Delete Confirmation',"customMessage":("Do you want to delete the Report <b>" + (this.reportDetails.REPORTNAME) + "</b>?"),"useLaddaButton":true},on:{"close":function($event){_vm.confirmReportDelete = false;},"confirm":function($event){return _vm.deleteReport()}}}):_vm._e(),(_vm.showAccountModal)?_c('AddAccountsModal',{attrs:{"accountsList":_vm.accountsList,"groupList":_vm.groupList},on:{"close":function($event){_vm.showAccountModal = false},"export":function (data) { return _vm.performExport(data); }}}):_vm._e(),_c('add-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }