
































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl }
})
export default class MultiCart extends TSXComponent<Props, Events> {
  $parent: any;
  multUsers = 0;

  async created() {
    await this.$parent.getSavedSettings();
    this.multUsers = this.$parent.savedSettings.multUsers || 0;
  }

  collectData() {
    return {
      status: 1,
      data: {
        multUsers: `${this.multUsers}`
      }
    };
  }
}
