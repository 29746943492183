










































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier } from "../models/common";
import TargetProfitTooltip from "@/components/TargetProfitTooltip.vue";
import ModalEditCommTarget from "@/components/ModalEditCommTarget.vue";
import AdjustmentsModal from "@/components/AdjustmentsModal.vue";
import PayrollBatchesModal from "@/components/PayrollBatchesModal.vue";
import ReimursementsModal from "@/components/EmployeePlan/ReimursementsModal.vue";
import AcceleratorsModal from "@/components/EmployeePlan/AcceleratorsModal.vue";
import SimModeModal from "@/components/EmployeePlan/SimModeModal.vue";
import InfoTooltip from "@/components/InfoTooltip.vue";
import ConfirmLockedMonthModal from "@/components/ConfirmRemoveItemModal.vue";
import { ApiHelper } from "@/helpers/all";

@Component({
  inheritAttrs: false,
  components: {
    TargetProfitTooltip,
    ModalEditCommTarget,
    AdjustmentsModal,
    PayrollBatchesModal,
    ReimursementsModal,
    AcceleratorsModal,
    InfoTooltip,
    SimModeModal,
    ConfirmLockedMonthModal
  }
})

export default class EmployeePlanDetails extends TSXComponent<void> {
  $route: any;
  $router: any;
  $employeePlanInfo: any;
  loading = false;
  employeePlanId = 0;
  details: any = {
    EMPLOYEENAME: "",
    EMPLOYEEID: 0,
    COMPID: 0,
    COMPUUID: 0,
    OTEBASE: 0,
    OTEBASE_FORMATTED: "$0.00",
    OTECOMM_FORMATTED: "$0.00",
    TOTALOTE_FORMATTED: "$0.00",
    COMP_TARGET_FORMATTED: "$0.00",
    OTEYEAR: ""
  };
  showTargetProfitTooltip: boolean = false;
  showCommTargetModal: boolean = false;
  showAccountProfitTooltipIndex = -1;
  empPlanAccountData: any = [];
  realizedProfitData: any = [];
  realizedAccountProfitData: any = [];
  commissionData: any = [];
  totalCompensationData: any = [];
  adjustmentData: any = [];
  reimursementData: any = [];
  realizedProfitTotal: string = "$0.00";
  remainingProfitTotal: string = "$0.00";
  commissionTotal: string = "$0.00";
  totalCompensationTotal: string = "$0.00";
  adjustmentsTotal: string = "$0.00";
  reimursementsTotal: string = "$0.00";
  showAdjustmentsModal: boolean = false;
  allTargetsTotal: number = 0;
  showPayrollBatchModal: boolean = false;
  payrollBatchesData: any = [];
  realizedProfitBatchesData: any = [];
  realizedProfitMonth: number = 0;
  showReimursementsModal: boolean = false;
  selectedAdjustmentMonth: number = 1;
  selectedReimursementMonth: number = 1;
  lockedMonthData: any = [];
  selectedAdjustmentMonthAsString: string = "";
  selectedReimursementMonthAsString: string = "";
  showAdjustmentMonthDropdown: boolean = false;
  showReimursementMonthDropdown: boolean = false;
  showAcceleratorsModal: boolean = false;
  acceleratorData: any = [];
  estUnrealizedCommInfoTooltipVisibleIndex: boolean = false;
  estUnpaidCommTotal: string = "$0.00";
  compensationToDate: string = "$0.00";
  showAcceleratorTooltipIndex = -1;
  acceleratorTotal: string = "$0.00";
  businessLineOptions: any = [
    {
      ID: 0,
      TEXT: "All"
    }
  ];
  activateSimMode: boolean = false;
  realizedProfitSimModeData: any = [];
  commissionSimModeData: any = [];
  totalCompensationSimModeData: any = [];
  commissionSimModeTotal: string = "$0.00";
  realizedProfitSimModeTotal: string = "$0.00";
  acceleratorSimModeTotal: string = "$0.00";
  totalCompensationSimModeTotal: string = "$0.00";
  compensationToDateSimMode: string = "$0.00";
  showSimModeModal: boolean = false;
  simModes: any = {
    manualEntry: true,
    unpaidInvoices: false,
    opportunities: false
  };
  unpaidInvData: any = [];
  unpaidInvTotal: string = "$0.00";
  opportunityData: any = [];
  opportunityTotal: string = "$0.00";
  remainingProfitSimModeTotal: string = "$0.00";
  periodCommTotal: string = "$0.00";
  unpaidCommTotal: string = "$0.00";
  selectedPayrollCycleMonthAsString: string = "";
  selectedAdjustmentAID: number = 0;
  selectedAdjustmentAccountName: string = "";
  accountAdjustmentData: any = [];
  remainingProfitTotalAmount: number = 0;
  remainingProfitSimModeTotalAmount: number = 0;
  selectedBusinessLineID: number = 0;
  baseData: any = [];
  baseTotal_Formatted: string = "$0.00";
  confirmLockedMonthModalVisible: boolean = false;
  lockedMonthInfoTooltipVisibleIndex: number = -1;
  selectedLockedMonth: number = 0;
  selectedLockedMonthAsString: string = "";
  monthData: any = [
    {
      MONTH: 1,
      MONTHASSTRING: 'January'
    },
    {
      MONTH: 2,
      MONTHASSTRING: 'February'
    },
    {
      MONTH: 3,
      MONTHASSTRING: 'March'
    },
    {
      MONTH: 4,
      MONTHASSTRING: 'April'
    },
    {
      MONTH: 5,
      MONTHASSTRING: 'May'
    },
    {
      MONTH: 6,
      MONTHASSTRING: 'June'
    },
    {
      MONTH: 7,
      MONTHASSTRING: 'July'
    },
    {
      MONTH: 8,
      MONTHASSTRING: 'August'
    },
    {
      MONTH: 9,
      MONTHASSTRING: 'September'
    },
    {
      MONTH: 10,
      MONTHASSTRING: 'October'
    },
    {
      MONTH: 11,
      MONTHASSTRING: 'November'
    },
    {
      MONTH: 12,
      MONTHASSTRING: 'December'
    }
  ]

  async created() {
    this.employeePlanId = this.$route.params.id ? this.$route.params.id : 0;
    await this.fetchData(true);
    await this.businessLineLoad();
  }

  async fetchData(isLoading: boolean = false) {
    if (isLoading) {
      this.loading = true;
    }
    try {
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "EmployeePlanView",
        compUUID: this.employeePlanId,
        action: "Detailed",
        isDetailsPage: true
      });

      const currentUserId = sessionStorage.getItem('userId');
      let hideCompPlanFlg: any = sessionStorage.getItem('hideCompPlanFlg');

      // convert hideCompPlanFlg to number
      if (hideCompPlanFlg !== null) {
        hideCompPlanFlg = +hideCompPlanFlg
      } else {
        hideCompPlanFlg = 0;
      }

      if (response.STATUS === 1) {
        this.details = response.EMPLOYEEPLANDETAIL;

        if (hideCompPlanFlg === 1) { // hide Comp Plan to this user
          if (currentUserId == response.EMPLOYEEPLANDETAIL.EMPLOYEEID) {
            this.$router.push({ name: "EmployeePlans" });
          }
        }

        this.empPlanAccountData = response.EMPLOYEELINKACCOUNT;
        this.realizedProfitData = response.REALIZEDPROFIT;
        this.realizedProfitTotal = response.REALIZEDPROFITTOTAL;
        this.remainingProfitTotal = response.REMAININGPROFITTOTAL;
        this.remainingProfitTotalAmount = response.REMAININGPROFITTOTALAMOUNT;
        this.realizedAccountProfitData = response.REALIZEDACCOUNTPROFIT;
        this.commissionData = response.COMMISSION;
        this.commissionTotal = response.COMMISSIONTOTAL;
        this.totalCompensationData = response.TOTALCOMPENSATION;
        this.totalCompensationTotal = response.TOTALCOMPENSATIONTOTAL;
        this.adjustmentData = response.ADJUSTMENTS;
        this.reimursementData = response.REIMURSEMENTS;
        this.adjustmentsTotal = response.ADJUSTMENTSTOTAL;
        this.reimursementsTotal = response.REIMURSEMENTSTOTAL;
        this.allTargetsTotal = response.ALLTARGETSTOTAL || 0;
        this.payrollBatchesData = response.PAYROLLBATCHESDETAIL || [];
        this.realizedProfitBatchesData = response.REALIZEDPROFITBATCHES || [];
        this.lockedMonthData = this.details.LOCKEDMONTHS || [];
        this.acceleratorData = response.ACCELERATORS || [];
        this.estUnpaidCommTotal = response.ESTUNPAIDCOMMTOTAL;
        this.compensationToDate = response.COMPTODATETOTAL;
        this.acceleratorTotal = response.ACCELERATORTOTAL;
        this.unpaidInvData = response.UNPAIDINVOICES || [];
        this.opportunityData = response.OPPORTUNITIES || [];
        this.unpaidInvTotal = response.UNPAIDINVTOTAL;
        this.opportunityTotal = response.OPPORTUNITYTOTAL;
        this.periodCommTotal = response.PERIODCOMMTOTAL;
        this.unpaidCommTotal = response.UNPAIDCOMMTOTAL;
        this.baseData = response.BASEDATA;
        this.baseTotal_Formatted = response.BASETOTAL_FORMATTED;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      setTimeout(() => this.loading = false, 3000);
    }
  }

  async businessLineLoad() {
    const response = await ApiHelper.callApi("post", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });

    if (response.STATUS === 1) {
      (response.businessLineOptions || []).map((item: any) => {
        this.businessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
      });
    }

  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }

  listPageRedirection() {
    this.$router.push({ name: "EmployeePlans" });
  }

  async reloadPlan(reloadState = false) {
    this.showCommTargetModal = false;
    this.showAdjustmentsModal = false;
    this.showPayrollBatchModal = false;
    this.showReimursementsModal = false;
    this.showAdjustmentMonthDropdown = false;
    this.showReimursementMonthDropdown = false;
    this.showAcceleratorsModal = false;
    this.realizedProfitMonth = 0;
    await this.fetchData();
    if (reloadState) {
      await this.loadEmployeePlanStats();
    }
  }

  async loadEmployeePlanStats() {
    const response = await ApiHelper.callApi("post", {
      controller: "Finances",
      FunctionName: "EmployeePlanStats",
    });

    if (response.STATUS == 1) {
      this.$employeePlanInfo.ITEMS = response.ITEMS || [];
      this.$employeePlanInfo.PERCENT = response.PERCENT || 0;
      this.$employeePlanInfo.PERCENT2 = response.PERCENT2 || 0;
      this.$employeePlanInfo.DOTTEDLINE = response.DOTTEDLINE || 0;
      this.$employeePlanInfo.BLUELINE = response.BLUELINE || 0;
      this.$employeePlanInfo.PERIOD_COMM = response.PERIOD_COMM || 0;
      this.$employeePlanInfo.PERIOD_COMM_FORMATTED = response.PERIOD_COMM_FORMATTED || '';
      this.$employeePlanInfo.UNPAID_COMM = response.UNPAID_COMM || 0;
      this.$employeePlanInfo.UNPAID_COMM_FORMATTED = response.UNPAID_COMM_FORMATTED || '';
    }
  }

  async lockEmpPlanMonth(month) {
    if (isNaN(month) || month > 12 || month < 1) {
      return;
    }
    if (!this.details.CSTATUS) {
      notifier.alert("This Compensation is Depreciated so you can't update Lock.");
      return;
    }
    let lock_month = [...this.lockedMonthData];
    let lockMonthIndex = lock_month.findIndex((m: any) => m === month);
    if (lockMonthIndex === -1) {
      lock_month.push(month);
    } else {
      lock_month.splice(lockMonthIndex, 1);
    }

    let lockedMonthData = {};

    if (lockMonthIndex === -1) {
      
      // Locked Month Realized Profit
      if (this.realizedProfitData.length) {
        let lockedMonthRealizedProfitData = this.realizedProfitData.find((item: any) => item.MONTH === month);
        if (lockedMonthRealizedProfitData) {
          lockedMonthData["realizedProfitData"] = lockedMonthRealizedProfitData;
        }
      }
      
      // Locked Month Realized Account Profit
      if (this.realizedAccountProfitData.length) {
        const lockedMonthRealizedAccountProfitData = this.realizedAccountProfitData.map((item: any) => {
          const adjustments = item.ADJUSTMENTS.find(adjustment => adjustment.MONTH === month);
          const profits = item.ACCOUNT_PROFIT.find(profit => profit.MONTH === month);
          
          return {
            ...item,
            ADJUSTMENTS: adjustments,
            ACCOUNT_PROFIT: profits
          };
        });
        lockedMonthData["realizedAccountProfitData"] = lockedMonthRealizedAccountProfitData;
      }

      // Locked Month Base
      lockedMonthData["Base"] = this.details.OTEBASE/12;

      // Locked Month Commission
      if (this.commissionData.length) {
        let lockedMonthCommissionData = this.commissionData.find((item: any) => item.MONTH === month);
        if (lockedMonthCommissionData) {
          lockedMonthData["commissionData"] = lockedMonthCommissionData;
        }
      }

    }

    let reqData = {};
    reqData["controller"]               = "Finances";
    reqData["FunctionName"]             = "EmployeePlanAdd";
    reqData["action"]                   = "updateLockedMonth";
    reqData["lockedMonths"]             = lock_month || [];
    reqData["compUUID"]                 = this.details.COMPUUID || '';
    reqData["lockedMonthData"]          = lockedMonthData;
    reqData["month"]                    = month;

    const response = await ApiHelper.callApi("post", reqData);

    if (response.STATUS === 1) {
      // this.lockedMonthData = lock_month;
      // let newCompToDate = 0;
      // let newCompToDateSimMode = 0;
      // this.totalCompensationData.map((item: any) => {
      //   if (item.AMOUNT && this.lockedMonthData.includes(item.MONTH)) {
      //     newCompToDate += parseFloat(item.AMOUNT.toFixed(2));
      //   }
      // });
      // this.compensationToDate = `$${this.formatter(newCompToDate)}`;
      // this.totalCompensationSimModeData.map((item: any) => {
      //   if (item.AMOUNT && this.lockedMonthData.includes(item.MONTH)) {
      //     newCompToDateSimMode += parseFloat(item.AMOUNT.toFixed(2));
      //   }
      // });
      // this.compensationToDateSimMode = `$${this.formatter(newCompToDateSimMode)}`;
      notifier.success(response.STATUSMESSAGE);
      await this.fetchData(false);
    } else {
      notifier.alert(response.STATUSMESSAGE);
    }
    this.selectedLockedMonth = 0;
    this.selectedLockedMonthAsString = '';
    this.confirmLockedMonthModalVisible = false;
  }

  formatter(amount) {
    // Use Intl.NumberFormat to format the total as a currency string
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(amount);
  }

  simMode() {
    this.activateSimMode = !this.activateSimMode;
    if (this.activateSimMode) {
      this.realizedProfitSimModeData = JSON.parse(JSON.stringify(this.realizedProfitData));
      this.commissionSimModeData = JSON.parse(JSON.stringify(this.commissionData));
      this.totalCompensationSimModeData = JSON.parse(JSON.stringify(this.totalCompensationData));
      this.realizedProfitSimModeTotal = this.realizedProfitTotal;
      this.remainingProfitSimModeTotal = this.remainingProfitTotal;
      this.remainingProfitSimModeTotalAmount = this.remainingProfitTotalAmount;
      this.commissionSimModeTotal = this.commissionTotal;
      this.totalCompensationSimModeTotal = this.totalCompensationTotal;
      this.compensationToDateSimMode = this.compensationToDate;
      this.acceleratorSimModeTotal = this.acceleratorTotal;
    }
  }

  updateSimMode(data) {
    if (typeof data.PROFIT != 'undefined' && typeof this.details.COMP_TARGET != 'undefined') {
      let commissionTotal = 0;
      let acceleratorTotal = 0;
      let totalCompensationTotal = 0;
      let compToDateTotal = 0;
      let commission = this.details.COMP_TARGET ? ((data.PROFIT/this.details.COMP_TARGET)*this.details.OTECOMM) : 0;
      commission = Math.floor(commission*100)/100;
      this.commissionSimModeData.map((item: any) => {
        if (item.MONTH === data.MONTH) {
          item.COMMISSION = commission;
          item.COMMISSION_FORMATTED = `$${this.formatter(commission)}`;
          item.ISCOMMAVAILABLE = true;
        }
        commissionTotal += (item.COMMISSION || 0);
      });
      this.commissionSimModeTotal = `$${this.formatter(commissionTotal)}`;
      let realizedProfitTotal = 0;
      this.realizedProfitSimModeData.map((item: any) => {
        realizedProfitTotal += item.PROFIT;
      });
      this.realizedProfitSimModeTotal = `$${this.formatter(realizedProfitTotal)}`;
      let remainingProfitTotal = (this.details.COMP_TARGET-realizedProfitTotal);
      this.remainingProfitSimModeTotal = `$${this.formatter(remainingProfitTotal)}`;
      this.remainingProfitSimModeTotalAmount = remainingProfitTotal;
      if (this.acceleratorData.length) {
        let acceleratorNewData = this.acceleratorData.map(({ACCELERATORPERCENTIMPACT,STOPACCELERATOR,...rest}) => ({...rest}));
        let realizedProfitAmount = 0;
        let TargetProfit = this.details.COMP_TARGET || 0;
        for (let index = 1; index <= 12; index++) {
          let acceleratorAmount = 0;
          let acceleratorAmountArr: any = [];
          let currentRealizedProfit = this.realizedProfitSimModeData.find(item => item.MONTH === index);
          if (currentRealizedProfit && currentRealizedProfit.PROFIT) {
            realizedProfitAmount += currentRealizedProfit.PROFIT;
          }
          let PercentOfTarget = 0;
          if (TargetProfit != 0) {
            PercentOfTarget = (realizedProfitAmount/TargetProfit) * 100;
          }
          acceleratorNewData.map((acceItem: any) => {
            let acce_impactBonus = !isNaN(acceItem.IMPACTBONUS) ? acceItem.IMPACTBONUS : 0;
            let calc_acceleratorAmount = 0;
            if (!isNaN(acceItem.THRESHOLD) && PercentOfTarget > acceItem.THRESHOLD && !isNaN(acceItem.CEILING) && PercentOfTarget < acceItem.CEILING && !acceItem.STOPACCELERATOR) {
              let currentPercentOfTarget = PercentOfTarget;
              if (acceItem.ACCELERATORPERCENTIMPACT && !isNaN(acceItem.ACCELERATORPERCENTIMPACT)) {
                currentPercentOfTarget = PercentOfTarget - acceItem.ACCELERATORPERCENTIMPACT;
                calc_acceleratorAmount = ((currentPercentOfTarget * TargetProfit)/100) * (acce_impactBonus/100);
              } else {
                calc_acceleratorAmount = (((currentPercentOfTarget - acceItem.THRESHOLD) * TargetProfit)/100) * (acce_impactBonus/100);
              }
              acceItem["ACCELERATORPERCENTIMPACT"] = PercentOfTarget;
            } else if (!isNaN(acceItem.THRESHOLD) && PercentOfTarget > acceItem.THRESHOLD && !isNaN(acceItem.CEILING) && PercentOfTarget >= acceItem.CEILING && !acceItem.STOPACCELERATOR) {
              if (acceItem.ACCELERATORPERCENTIMPACT && !isNaN(acceItem.ACCELERATORPERCENTIMPACT)) {
                calc_acceleratorAmount = (((acceItem.CEILING - acceItem.ACCELERATORPERCENTIMPACT) * TargetProfit)/100) * (acce_impactBonus/100);
              } else {
                calc_acceleratorAmount = (((acceItem.CEILING - acceItem.THRESHOLD) * TargetProfit)/100) * (acce_impactBonus/100);
              }
              acceItem["STOPACCELERATOR"] = true;
            }
            calc_acceleratorAmount = Math.floor(calc_acceleratorAmount*100)/100;
            acceleratorAmount += calc_acceleratorAmount;
            if (calc_acceleratorAmount != 0) {
              let tempAcceleratorStruct = {};
              tempAcceleratorStruct["QUALIFIERS"] = acceItem.QUALIFIERSASTEXT;
              tempAcceleratorStruct["CEILING"] = acceItem.CEILING;
              tempAcceleratorStruct["THRESHOLD"] = acceItem.THRESHOLD;
              tempAcceleratorStruct["IMPACTBONUS"] = acceItem.IMPACTBONUS;
              tempAcceleratorStruct["ACCELERATORAMOUNT"] = calc_acceleratorAmount;
              tempAcceleratorStruct["ACCELERATORAMOUNT_FORMATTED"] = `$${this.formatter(calc_acceleratorAmount)}`;
              acceleratorAmountArr.push(tempAcceleratorStruct);
              acceleratorTotal += calc_acceleratorAmount;
            }
          });
          let currentcommission = this.commissionSimModeData.find(item => item.MONTH === index);
          if (currentcommission) {
            currentcommission["ACCELERATORAMOUNT"] = acceleratorAmount;
            currentcommission["ACCELERATORAMOUNT_FORMATTED"] = `$${this.formatter(acceleratorAmount)}`;
            currentcommission["ACCELERATORS"] = acceleratorAmountArr;
          }
        }
      }
      this.acceleratorSimModeTotal = `$${this.formatter(acceleratorTotal)}`;
      this.totalCompensationSimModeData.map((compItem: any) => {
        let totalComp = !isNaN(this.details.OTEBASE) ? (this.details.OTEBASE/12) : 0;
        let structCommission = this.commissionSimModeData.find(commItem => commItem.MONTH === compItem.MONTH);
        let structAdjustment = this.adjustmentData.find(commItem => commItem.MONTH === compItem.MONTH);
        let structReimursement = this.reimursementData.find(commItem => commItem.MONTH === compItem.MONTH);

        if (structCommission) {
          totalComp += (structCommission.COMMISSION || 0);
          totalComp += typeof structCommission.ACCELERATORAMOUNT != 'undefined' ? parseFloat(structCommission.ACCELERATORAMOUNT.toFixed(2)) : 0;
        }
        if (structAdjustment) {
          totalComp += (structAdjustment.AMOUNT || 0);
        }
        if (structReimursement) {
          totalComp += (structReimursement.AMOUNT || 0);
        }
        compItem["AMOUNT"] = totalComp;
        compItem["AMOUNT_FORMATTED"] = `$${this.formatter(totalComp)}`;
        totalCompensationTotal += totalComp;
        if (this.lockedMonthData.includes(compItem.MONTH)) {
          compToDateTotal += parseFloat(totalComp.toFixed(2));
        }
      });
      this.totalCompensationSimModeTotal = `$${this.formatter(totalCompensationTotal)}`;
      this.compensationToDateSimMode = `$${this.formatter(compToDateTotal)}`;
    }
  }

  updateSimModal(data) {
    if (typeof data.manualEntry != 'undefined') {
      this.simModes.manualEntry = data.manualEntry;
    }
    if (typeof data.unpaidInvoices != 'undefined') {
      this.simModes.unpaidInvoices = data.unpaidInvoices;
    }
    if (typeof data.opportunities != 'undefined') {
      this.simModes.opportunities = data.opportunities;
    }
    this.showSimModeModal = false;
  }
}
