











































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { removeHTML } from "@/helpers/ApiHelper";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class StoreName extends TSXComponent<Props, Events> {
  $parent: any;
  companyFullName = "";
  companyShortName = "";

  async created() {
    await this.$parent.getSavedSettings();
    this.companyFullName = this.$parent.savedSettings.FullName || "";
    this.companyShortName = this.$parent.savedSettings.ShortName || "";
  }

  async collectData() {
    // remove html
    this.companyFullName = removeHTML(this.companyFullName);
    this.companyShortName = removeHTML(this.companyShortName);

    const valid = await this.$validator.validateAll();
    if (!valid) {
      return {
        status: 0
      };
    }

    return {
      status: 1,
      data: {
        companyFullName: this.companyFullName,
        companyShortName: this.companyShortName
      }
    };
  }

  blockSpace(e) {
    if (e.key === " ") {
      e.preventDefault();
    }
  }

  removeSpaces() {
    this.companyShortName = this.companyShortName.replace(/\s+/g, "");
  }
}
