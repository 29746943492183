









































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import axios from "axios";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import DropdownControl from "../components/DropdownControl.vue";
// import { USState } from "../repos/InvoiceAPIRepo";
import USState from "../static/USstates.json";
import DelayRemove from "./DelayRemove.vue";

interface addressObj {
	NAME?: string;
	ADDR1?: string;
	ADDR2?: string;
	CITY?: string;
	STATE?: string;
	ZIP?: string;
	ID?: number;
	ISDEFAULT?: number;
	ADDRTYPE?: number;
	ACCOUNTSADDRESS_ID?: string;
	ISDUPLICATE?: number;
	SELECTEDNETTERM?: number;
  isDeleted?: number;
}
interface usersObj {
	USERID: number;
	UFULLNAME?: string;
}
interface securityObj {
	SECURITYGROUPID: number;
	SECURITYGROUPNAME?: string;
}

interface Events {
	addrow: { index; pos };
	initialLoad: { index; addr };
	// checkName: { name; pos; addressDetails };
}

declare const $: any;
declare const dataURL: string;
declare const getRouteData: Function;
declare const htmlCheck: Function;
declare const htmlParse: Function;

@Component({
	inheritAttrs: false,
	components: {
		LaddaButton,
		ConfirmRemoveItemModal,
		DropdownControl,
		DelayRemove
	}
})
export default class CustomerDetailsTabAddresses extends TSXComponent<Events> {
	$route: any;
	loading = false;
	saving: boolean | string = false;
	confirmAddressUpdate = false;
	addressID = 0;
	stateList: any = [];
	selectedState: string[] = [];
	showShippingDelayRemove = -1;
	showShippingDelayRemoveIDs: any[] = [];
	showBillingDelayRemove = -1;
	showBillingDelayRemoveIDs: any[] = [];

	@Prop({ required: false, default: [] })
	addressItems!: addressObj[];

	@Prop({ required: false, default: [] })
	shippingAddress!: addressObj[];

	@Prop({ required: false, default: [] })
	billingAddress!: addressObj[];

	@Prop({ required: true })
	active!: boolean;

	@Prop({ required: true })
	selectedusers!: usersObj[];

	@Prop({ required: true })
	selectedgroups!: securityObj[];

	@Prop({ required: true })
	alias!: string;

	@Prop({ required: true })
	accname!: string;

	@Prop({ required: true })
	accid!: string;

	@Prop({ required: true })
	aid!: number;

  @Prop({ required: true })
	shippingAddressType!: number;

	@Prop({ required: true })
	billingAddressType!: number;

	@Prop({ required: true })
	allNetTerms!: any;

	@Prop({ required: false, default: "" })
  defaultNetTerm!: string;

	isActive = "shipping";
	isAddress = "Shipping Address";
	addressLoading = false;
	// shippingEdited = false;
	billingEdited = false;
	paging: any = {
		shipping: {
			pageNumber: 1,
			perPage: 15,
			loadingMore: false
		}
	};
	shippingAddressBk: any = null;

	$refs!: {
		drodown: DropdownControl;
	};
	// created() {
	//   }
	// mounted() {
	//   console.log("addressItems", this.addressItems.length);
	//   var $this = this;
	//   $.each(this.addressItems, function(i, val) {
	//     console.log("val", val.ADDRTYPE, val);
	//     if (val.ADDRTYPE == 1) {
	//       $this.shippingAddress.push(val);
	//     } else {
	//       $this.billingAddress.push(val);
	//     }
	//   });
	//   this.addRow(this.shippingAddress.length, 1);
	//   this.addRow(this.billingAddress.length, 0);

	// }

	mounted() {
		// backup shipping addr
		this.shippingAddressBk = JSON.parse(JSON.stringify(this.shippingAddress));

		// handle scroll
    if (["ViewCustomer"].includes(this.$route.name)) {
      window.addEventListener("scroll", this.handleScroll);
    }
	}

	get shippingEdited() {
		return JSON.stringify(this.shippingAddress) != JSON.stringify(this.shippingAddressBk);
	}

	addRow(index, from) {
		var item: any;
		if (!from) {
			if (index == this.billingAddress.length) {
				item = {
					NAME: "",
					ADDR1: "",
					ADDR2: "",
					CITY: "",
					STATE: "",
					ZIP: "",
					ID: 0,
					ISDEFAULT: 0,
					ADDRTYPE: 2,
					SELECTEDNETTERM: 30,
					ACCOUNTSADDRESS_ID: "",
          isDeleted: 0,
				};
				// this.$set(this.addressItems, index+1, item);

				// default net term
				if(this.defaultNetTerm) {
					const inList = this.allNetTerms.find(
						(item: any) => item.TERMNAME == this.defaultNetTerm
					);
					if(inList) {
						item.SELECTEDNETTERM = this.defaultNetTerm;
					}
				}

				this.billingAddress.push(item);
			}
		} else {
			if (index == this.shippingAddress.length) {
				item = {
					NAME: "",
					ADDR1: "",
					ADDR2: "",
					CITY: "",
					STATE: "",
					ZIP: "",
					ID: 0,
					ADDRTYPE: 1,
					ISDEFAULT: 0,
					SELECTEDNETTERM: 0,
					ACCOUNTSADDRESS_ID: ""
				};
				this.shippingAddress.push(item);
			}
		}
	}

	async validateBeforeSubmit(e) {
		e.preventDefault();
		e.stopPropagation();
		var result = await this.$validator.validateAll();
		if (!result) {
			this.saving = "error";
			return;
		}
		if ((!this.shippingEdited && this.isActive === 'shipping') || (!this.billingEdited && this.isActive === 'billing')) {
			return;
		}
		this.saving = true;
		var comInstance = this;
		var userList: number[] = [];
		var isMissingInfo = 0;
		if (comInstance.shippingAddress.length < 2) {
			if (
				comInstance.shippingAddress[0].ADDR1 == "" &&
				comInstance.shippingAddress[0].CITY == "" &&
				comInstance.shippingAddress[0].STATE == "" &&
				comInstance.shippingAddress[0].NAME == "" &&
				comInstance.shippingAddress[0].ZIP == "" &&
				comInstance.isActive == "shipping"
			) {
				isMissingInfo = 1;
			}
		}
		if (comInstance.billingAddress.length < 2) {
			if (
				comInstance.billingAddress[0].ADDR1 == "" &&
				comInstance.billingAddress[0].CITY == "" &&
				comInstance.billingAddress[0].STATE == "" &&
				comInstance.billingAddress[0].NAME == "" &&
				comInstance.billingAddress[0].ZIP == "" &&
				comInstance.isActive != "shipping"
			) {
				isMissingInfo = 1;
			}
		}
		if(this.isActive == "shipping") {
			var duplicateNameExist = comInstance.shippingAddress.find(o => o.ISDUPLICATE === 1);
		}
		else {
			var duplicateNameExist = comInstance.billingAddress.find(o => o.ISDUPLICATE === 1);
		}
		if (isMissingInfo) {
			notifier.alert("Please fill the Address Info!");
		} else if (duplicateNameExist) {
			notifier.alert("Address name already exists");
			this.saving = "error";
		} else {
			$.each(comInstance.selectedusers, function(i, val) {
				userList.push(val.USERID);
			});
			comInstance.saving = true;
			//detect selected security groups
			var sgList: number[] = [];
			$.each(comInstance.selectedgroups, function(i, val) {
				sgList.push(val.SECURITYGROUPID);
			});
			var finalitems:addressObj[] = [];
			if (this.isActive == "shipping") {
				finalitems = comInstance.shippingAddress;
			} else {
				finalitems = comInstance.billingAddress;
			}

			const response = await axios.post(dataURL + "?ReturnType=JSON", {
				controller: "Accounts",
				FunctionName: "Update",
				aID: this.aid,
				addressList: finalitems
			});

			if (response.data.ERROR) {
				// console.log(response.data.ERROR);
			}
			if (response.data.STATUSSUB == 3) {
				notifier.warning(response.data.MESSAGESUB);
			} else if (response.data.STATUS == 1) {
				// if (this.accid != comInstance.aid) {
				// 	comInstance.$router.push({
				// 		name: "ViewCustomer",
				// 		params: { id: comInstance.accid }
				// 	});
				// } else {
				var isShipping = 0;
				if (this.isActive == "shipping") {
					isShipping = 1;
					// this.shippingEdited = false;
					this.shippingAddressBk = JSON.parse(JSON.stringify(this.shippingAddress));
				} else {
					this.billingEdited = false;
				}
				this.$emit("initialLoad", 4, isShipping, async () => {
					if (this.isActive == "shipping") {
						// backup shipping addr
						await this.$nextTick();
						this.shippingAddressBk = JSON.parse(JSON.stringify(this.shippingAddress));
					}
				});
				// }
				comInstance.saving = false;
				notifier.success(response.data.MESSAGE);
				// router.push({ name: "Customers" });//go to customer list // As per DJ commennt to stay in same page.
			} else if (response.data.STATUS == 0) {
				comInstance.saving = "error";
				if (
					typeof response.data.ACCOUNTID_EXISTED != "undefined" &&
					response.data.ACCOUNTID_EXISTED
				) {
					// comInstance.errorMsg.forAccountID = response.data.MESSAGE;
				}
			}
		}
		if (comInstance.saving === true) {
			comInstance.saving = false;
		}
	}

	isRequired(i, mx) {
		if (!mx) {
			if (
				(this.billingAddress[i].NAME != "" ||
					this.billingAddress[i].ADDR1 != "" ||
					this.billingAddress[i].CITY != "" ||
					this.billingAddress[i].STATE != "" ||
					this.billingAddress[i].ZIP != "") &&
				this.isActive != "shipping"
			) {
				return "required";
			}
		} else {
			if (
				(this.shippingAddress[i].NAME != "" ||
					this.shippingAddress[i].ADDR1 != "" ||
					this.shippingAddress[i].CITY != "" ||
					this.shippingAddress[i].STATE != "" ||
					this.shippingAddress[i].ZIP != "") &&
				this.isActive == "shipping"
			) {
				return "required";
			}
		}
		return "";
	}

	updateDefault(index, mtype) {
		if (mtype == 1) {
			$.each(this.shippingAddress, function(i, val) {
				if (i == index) {
					val.ISDEFAULT = 1;
				} else {
					val.ISDEFAULT = 0;
				}
			});
		} else {
			$.each(this.billingAddress, function(i, val) {
				if (i == index) {
					val.ISDEFAULT = 1;
				} else {
					val.ISDEFAULT = 0;
				}
			});
		}
	}

	showShippingDelay(index) {
    	// this.showShippingDelayRemove = index;
    if(!this.showShippingDelayRemoveIDs.includes(index)) {
      this.showShippingDelayRemoveIDs.push(index);
    }
  }
  undoShippingDelay(index) {
    this.showShippingDelayRemoveIDs = this.showShippingDelayRemoveIDs.filter((id) => id != index);
  }

	showBillingDelay(index) {
    	// this.showBillingDelayRemove = index;
    if(!this.showBillingDelayRemoveIDs.includes(index)) {
      this.showBillingDelayRemoveIDs.push(index);
    }
  }
  undoBillingDelay(index) {
    this.showBillingDelayRemoveIDs = this.showBillingDelayRemoveIDs.filter((id) => id != index);
  }

	async removeItem(index, from) {
		var reqBody = {
			FunctionName: "Delete",
			from : 1,
			customerId : this.aid
		};
		if (this.isActive !== "shipping") {
			reqBody.from = 2;
		}
		this.saving = true;
		var isDelete = 0;
		reqBody["Controller"] = "Address";
		if (from) {
			this.showBillingDelayRemove = -1;
			isDelete = 1;
			if (this.billingAddress[index].ID) {
				reqBody["AccountsAddressID"] = this.billingAddress[
					index
				].ACCOUNTSADDRESS_ID;
				this.billingAddress[index].isDeleted = 1;
			}
			else {
				this.billingAddress.splice(index, 1);
			}
			// this.billingAddress.splice(index, 1);
			// this.isFormChanged(this.billingAddress);
		} else {
			this.showShippingDelayRemove = -1;
			isDelete = 1;
			if (this.shippingAddress[index].ID) {
				reqBody["AccountsAddressID"] = this.shippingAddress[
					index
				].ACCOUNTSADDRESS_ID;
			}
        	this.shippingAddress[index].isDeleted = 1;
			// this.shippingAddress.splice(index, 1);
			// this.isFormChanged(this.shippingAddress);
		}

		if (isDelete && reqBody["AccountsAddressID"] ) {
			const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

			if (response.data.ERROR) {
				this.saving = "error";
				throw new Error(response.data.ERROR);
			}
			if (response.data.STATUS == 0) {
				this.saving = "error";
				throw new Error(response.data.MESSAGE);
			}
			if (response.data.STATUS == 1) {
				this.saving = false;

				notifier.success(response.data.MESSAGE);
			}
			if (response.data.STATUS == 3) {
				this.saving = false;
				this.$emit("initialLoad", 4);
				notifier.warning(response.data.MESSAGE);
			}

			if(this.isActive == "shipping") {
				// backup shipping addr
				this.shippingAddressBk = JSON.parse(JSON.stringify(this.shippingAddress));
				this.handleScroll(null);
			}
		} else {
			this.saving = false;
		}
    if(this.isActive == "shipping") {
      this.undoShippingDelay(index);
      if(this.showShippingDelayRemoveIDs.length == 0) {
        this.shippingAddress = this.shippingAddress.filter((arr) => !arr.isDeleted);
        this.handleScroll(null);
      }
    } else {
      this.undoBillingDelay(index);
    }
	}

	htmlCheck(InputVal) {
		if (InputVal.length > htmlParse(InputVal).length) {
			return true;
		} else {
			return false;
		}
	}

	removeHTML(index, name, addr) {
		var htmlCount = 0;
		if (this.isActive != "billing") {
			if (htmlCheck(this.shippingAddress[index][name])) {
				this.shippingAddress[index][name] = htmlParse(
					this.shippingAddress[index][name]
				);
				htmlCount++;
			}
		} else {
			if (htmlCheck(this.billingAddress[index][name])) {
				this.billingAddress[index][name] = htmlParse(
					this.billingAddress[index][name]
				);
				htmlCount++;
			}
		}

		if (htmlCount != 0) {
			notifier.alert("HTML content found. This content has been removed!");
		}
	}
	async customerAddressUpdate(id) {
		this.confirmAddressUpdate = true;
		this.addressID = id;
		if (this.isActive == "shipping") {
			this.isAddress = "Billing Address";
		} else {
			this.isAddress = "Shipping Address";
		}
	}
	async updateAddress() {
		this.addressLoading = true;
		var customerAddressType = 1;
		var isbilling = 1;
		if (this.isActive == "shipping") {
			customerAddressType = 2;
			isbilling = 0;
		}
		const response = await axios.post(dataURL + "?ReturnType=JSON", {
			controller: "Address",
			FunctionName: "Update",
			addressType: customerAddressType,
			AccountsAddressID: this.addressID,
			customerId : this.aid,
			sourceAddres: isbilling?2:1
		});
		if (response.data.STATUS == 1) {
			notifier.success(response.data.MESSAGE);
			this.confirmAddressUpdate = false;
			this.$emit("initialLoad", 4);
		}else if (response.data.STATUS == 3) {
			this.confirmAddressUpdate = false;
			this.$emit("initialLoad", 4);
			notifier.warning(response.data.MESSAGE);
		} else {
			notifier.alert(response.data.MESSAGE);
		}
		this.addressLoading = false;
	}
	created() {
		for (const i in USState) {
			this.stateList.push({
				ID: USState[i].Abbr,
				TEXT: USState[i].Abbr,
				data: USState[i]
			});
		}
	}
	updateShippingState(selectedID, index) {
		if (this.isActive == "billing") {
			this.billingAddress[index].STATE = selectedID;
			this.addRow(index + 1, 0);
		} else {
			this.addRow(index + 1, 1);
			this.shippingAddress[index].STATE = selectedID;
		}
		this.iserror(index);
		// this.selectedState = [selectedID];
		// this.addr.STATE = selectedID;
	}
	iserror(i) {
		if (this.isActive == "shipping") {
			if (
				this.shippingAddress[i].STATE == "" &&
				i + 1 != this.shippingAddress.length
			) {
				return true;
			}
		} else {
			if (
				this.billingAddress[i].STATE == "" &&
				i + 1 != this.billingAddress.length
			) {
				return true;
			}
		}
		return false;
	}
	close(w){
		if(this.$refs.drodown[w].dropdownVisible){
			this.$refs.drodown[w].dropdownVisible = false;
		}
	}
	// async checkName(name, pos, addressDetails) {
	// 	var filteredAddress = addressDetails.filter(item => name != "" && item.NAME.toLowerCase() == name.toLowerCase());
	// 	if (filteredAddress.length > 1) {
	// 		addressDetails[pos].ISDUPLICATE = 1;
	// 		$(".errorMsg#NameCheck_" + pos).addClass("errorName");
	// 		$(".errorMsg#NameCheck_" + pos).text("The Name Exists Already");
	// 	}
	// 	else {
	// 		addressDetails[pos].ISDUPLICATE = 0;
	// 		$(".errorMsg#NameCheck_" + pos).removeClass("errorName error");
	// 		$(".errorMsg#NameCheck_" + pos).text("");
	// 	}
	// }

	async isFormChanged(items) {
		const result = await this.$validator.validateAll();
		 {
			for (const item of items) {
				if (
					(item.ADDRESSNAME != item.NAME && (item.ADDRESSNAME != undefined || item.NAME.length) && result) ||
					(item.ACCOUNTSADDRESS_ADDRESS1 != item.ADDR1 && (item.ACCOUNTSADDRESS_ADDRESS1 != undefined || item.ADDR1.length) && result) ||
					(item.ACCOUNTSADDRESS_ADDRESS2 != item.ADDR2 && (item.ACCOUNTSADDRESS_ADDRESS2 != undefined || item.ADDR2.length) && result) ||
					(item.ACCOUNTSADDRESS_CITY != item.CITY && (item.ACCOUNTSADDRESS_CITY != undefined || item.CITY.length) && result) ||
					(item.ACCOUNTSADDRESS_STATE != item.STATE && (item.ACCOUNTSADDRESS_STATE != undefined || item.STATE.length) && result) ||
					(item.ACCOUNTSADDRESS_ZIP != item.ZIP && (item.ACCOUNTSADDRESS_ZIP != undefined || item.ZIP.length) && result) ||
					(item.ACCOUNTDEFAULT != item.ISDEFAULT && item.NAME.length && result) ||
					(item.SELECTEDNETTERM != item.EXISTINGNETTERM && (item.SELECTEDNETTERM != undefined || item.SELECTEDNETTERM.length) && result)
				) {
					if (this.isActive === 'shipping') {
						// this.shippingEdited = true;
					} else {
						this.billingEdited = true;
					}
					break;
				} else if (this.isActive === 'shipping') {
					// this.shippingEdited = false;
				} else {
						this.billingEdited = false;
				}
			}
		}
	}

	handleScroll(e) {
    if (!["ViewCustomer"].includes(this.$route.name)) {
      return;
    }
		const addressContainer: any = $(this.$el).closest(".AddressContainer");
		const isShowingShippingTab = addressContainer.find(".addressNav .active.shipping").length;
		if(!addressContainer.length || !addressContainer.hasClass("active") || !isShowingShippingTab) {
			return;
		}

    let documentHeight = document.body.scrollHeight;
    let currentScroll = window.scrollY + window.innerHeight;
    let showingCnt = $(".acct-shipping-addrs > .list-item").length;
    if (showingCnt < this.paging.shipping.pageNumber * this.paging.shipping.perPage) {
      return;
    }

    if (currentScroll + 300 > documentHeight) {
      if (this.paging.shipping.pageNumber * this.paging.shipping.perPage < this.shippingAddress.length) {
        this.paging.shipping.loadingMore = true;
        setTimeout(() => {
          this.paging.shipping.pageNumber += 1;
          this.$nextTick().then(() => {
            this.paging.shipping.loadingMore = false;
          });
        }, 50);
      }
    }
  }
}
