import { render, staticRenderFns } from "./DuplicateCompPlanModal.vue?vue&type=template&id=067468d1&scoped=true"
import script from "./DuplicateCompPlanModal.vue?vue&type=script&lang=tsx"
export * from "./DuplicateCompPlanModal.vue?vue&type=script&lang=tsx"
import style0 from "./DuplicateCompPlanModal.vue?vue&type=style&index=0&id=067468d1&prod&scoped=true&lang=less"
import style1 from "./DuplicateCompPlanModal.vue?vue&type=style&index=1&id=067468d1&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067468d1",
  null
  
)

export default component.exports