




























































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl }
})
export default class Language extends TSXComponent<Props, Events> {
  $parent: any;
  country = "";
  defaultLanguage = "";
  currency = "";
  productSource = "";
  pSourceLocale = "";

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.country = saved.AccountCountry || "";
    this.defaultLanguage = saved.AccountLanguage || "";
    this.currency = saved.olmsCurrency || "";
    this.productSource = saved.ProductContentSource || "";
    this.pSourceLocale = saved.AccountCAPLocale || "";
  }

  collectData() {
    return {
      status: 1,
      data: {
        accountcountry: this.country,
        languagelocale: this.defaultLanguage,
        currency_locale: this.currency,
        contentsource: this.productSource,
        contentsourcelocale: this.pSourceLocale,
        hiddenStoreLanguage: this.$parent.savedSettings.StoreLanguageJSON,
        hiddenAccountLanguage: this.$parent.savedSettings.AccountLanguageJSON,
        hiddenAccountLocale: this.$parent.savedSettings.AccountLocaleJSON
      }
    };
  }
}
