















































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

declare const dataURL: string;

interface ItemProps {
  DESKTOPSCOUNT: number;
  ACCESSORIESCOUNT: number;
  MONITORSCOUNT: number;
  QUANTITY: number;
  ID: number;
  CATEGORYNAME: string;
  AID: number;
  EUNAME: string;
  ENDUSERFULLADDRESS: string;
  PURCHASEIDS: number;
}

@Component
export default class HardwareDevicesComponent extends Vue {
  @Prop() readonly item!: ItemProps;
  activePopover: string | null = null;
  activeHardwareData: any = [];
  isMouseOverPopover= false;
  loading = false;
  mouseoutTimeoutId: any = null;
  tooltipPosition : string = '';
  totalShow = 5;

  async mouseover(event: Event, type: string, euId: number, quantity: number) {
    if(!this.isMouseOverPopover) {
      if (this.mouseoutTimeoutId) {
        clearTimeout(this.mouseoutTimeoutId);
        this.mouseoutTimeoutId = null;
      }

      if(quantity != 0){
          this.activeHardwareData = [];
          this.totalShow = 5;
          this.activePopover = type;
          this.loading = true;
          var data = {};

          data = {
            controller: "Hardware",
            FunctionName: "DeviceList",
            type: type,
            euId,
            aId: this.item.AID || 0,
            euName: this.item.EUNAME || "",
            purchaseId: this.item.PURCHASEIDS || 0,
            euFullAddress: this.item.ENDUSERFULLADDRESS || "",
          };
          try {
              const response = await axios.post(dataURL + "?ReturnType=JSON", data);
              this.loading = false;
              this.activeHardwareData = response.data.HARDWARES;
              setTimeout(() => {
                this.checkOffset(event);
              }, 100);
          } catch (error) {
              console.error("Error fetching hardware data:", error);

              this.activeHardwareData = [];
          }
      }
      else{
          this.activePopover = null;
      }
    }
  }

  checkOffset(event: Event) {
    const target: any = event.target;
    let popoverBox;

    // Find the 'popover-box' element
    if (this.activePopover === 'all') {
      // For 'all', popover-box is the next sibling of the target
      popoverBox = target.nextElementSibling;
    } else {
      // For other types, popover-box is a child of the target
      popoverBox = target.querySelector('.popover-box');
    }

    if (popoverBox) {
      // Get the dynamic height of the popover-box
      const tooltipHeight = popoverBox.offsetHeight;
      const offset = 60; // Additional space buffer
      const targetRect = target.getBoundingClientRect();
      const targetBottomPosition = targetRect.bottom;
      const spaceBelow = window.innerHeight - targetBottomPosition;

      // Position the tooltip based on available space
      if (spaceBelow < tooltipHeight + offset) {
        this.tooltipPosition = 'top';
      } else {
        this.tooltipPosition = '';
      }
    } else {
      console.warn("Popover box not found");
    }
  }

  mouseout() {
    this.mouseoutTimeoutId = setTimeout(() => {
      if (!this.isMouseOverPopover) {
          this.activePopover = null;
      }
      this.mouseoutTimeoutId = null;
    }, 300);
  }

  popoverMouseover() {
    this.isMouseOverPopover = true;
    if (this.mouseoutTimeoutId) {
      clearTimeout(this.mouseoutTimeoutId);
      this.mouseoutTimeoutId = null;
    }
  }

  popoverMouseout() {
    this.isMouseOverPopover = false;
    this.mouseoutTimeoutId = setTimeout(() => {
      this.activePopover = null;
      this.mouseoutTimeoutId = null;
    }, 300);
  }

  getMoreDevices(items) {
    let ret = 0;
    for (const item of items.slice(5)) {
      ret += item.QTY || 0;
    }

    return ret;
  }

  showFullDevices() {
    this.totalShow = this.activeHardwareData.length;
  }
}
