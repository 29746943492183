import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { CoolSelectPlugin } from 'vue-cool-select'
import VeeValidate, { Validator } from "vee-validate";
import axios from "axios";
// import VueSelect from "vue-cool-select";
import VueNumeric from 'vue-numeric'
import vClickOutside from 'v-click-outside'
import VueMonthlyPicker from 'vue-monthly-picker'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import 'ladda/dist/ladda-themeless.min.css'

Vue.use(PerfectScrollbar)

Vue.use(vClickOutside)

import App from './App.vue'
import router from './router'
import store from './store'

import LineChart from './components/LineChart.vue'
import VueApexCharts from './components/VueApexCharts.vue'
import BarChart from './components/BarChart.vue'
import Footer from './components/Footer.vue'
import AutoComplete from './components/AutoComplete.vue'
import Loader from './components/Loader.vue'
Vue.component('apexchart', VueApexCharts)

import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
Vue.component('v-icon', Icon)

// GA
// import VueAnalytics from 'vue-analytics';
// Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: process.env.VUE_APP_GA_TRCKING_ID,
//   debug: {
//     enabled: false,
//     trace: true,
//     sendHitTask: true
//   },
//   router
// });

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_TRCKING_ID }
}, router);
const Handlebars = require('handlebars/runtime.js')

function dynamicPartial(template, context) {
  const partial = Handlebars.partials[template];
  if (!partial) {
    return "Partial not loaded";
  }

  const html = partial(context.data.root);

  return new Handlebars.SafeString(html);
}

function getValues(template, context) {
  if(context == "QOPEN"){
    return template["STATUSTEXT"];
  }
  else{
    return template[context];
  }
}

function ifCond(this: any, v1, operator, v2, options) {
  switch (operator) {
    case '==':
      return (v1 == v2) ? options.fn(this) : options.inverse(this);
    case '===':
      return (v1 === v2) ? options.fn(this) : options.inverse(this);
    case '||':
      return (v1 || v2) ? options.fn(this) : options.inverse(this);
    default:
      return options.inverse(this);
  }
}

Handlebars.registerHelper('dynamicPartial', dynamicPartial);
Handlebars.registerHelper('getIestedIterationValues', getValues);
Handlebars.registerHelper('ifCond', ifCond);
Handlebars.registerHelper("inc", function (value, options) {
  return parseInt(value) + 1;
});

Handlebars.registerHelper('if_eq', function (this: any, a, b, opts) {
  if (a === b)
    // Or === depending on your needs
    return opts.fn(this);
  else return opts.inverse(this);
});
Handlebars.registerHelper('if_gt', function (this: any, a, b, opts) {
  if (a > b)
    // Or === depending on your needs
    return opts.fn(this);
  else return opts.inverse(this);
});
Handlebars.registerHelper('if_lt', function (this: any, a, b, opts) {
  if (a < b)
    // Or === depending on your needs
    return opts.fn(this);
  else return opts.inverse(this);
});
Handlebars.registerHelper('if_neq', function (this: any, a, b, opts) {
  if (a === b)
    // Or === depending on your needs
    return opts.inverse(this);
  else return opts.fn(this);
});

Vue.component('vue-monthly-picker', VueMonthlyPicker)
Vue.component('line-chart', LineChart)
Vue.component('bar-chart', BarChart)
Vue.component('add-footer', Footer)
Vue.component('auto-complete', AutoComplete)
Vue.component('Loader', Loader)
Vue.filter('formatCurrency', function (value,isNeg) {
  value = +value
  if(isNeg){
    value = value * -1; 
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
})

Vue.config.productionTip = false

// declare const bootstrapVue: any
declare const VueAwesome: any
declare const VueECharts: any
declare const vuejsDatepicker: any
declare const AWN: any

declare const LoadData: any
declare const containerFunc: any
declare const containerFuncCustomer: any
declare const grabAddHardware: any
declare const ServicePublicFunc: any
declare const orderPublicFunc: any
declare const addNotes: any
declare const supportUpdates: any
declare const dropdownInit: any
declare let dataURL: any

var notifier = new AWN({
  position: "top",
  duration: 10000
});

Vue.use(VueNumeric)
Vue.use(CoolSelectPlugin)
Vue.use(BootstrapVue)
Vue.component('icon', VueAwesome);
Vue.use(VeeValidate, { fieldsBagName: 'formFields' });
// Vue.component('chart', VueECharts);
Vue.component('datepicker', vuejsDatepicker);
Vue.config.warnHandler = function (err, vm, info) {
  // console.log('Custom vue warn handler: ', err, vm.name, info);
};
VeeValidate.Validator.extend('currencyCheck', {
  getMessage: field => 'Please enter valid amount for ' + field + ' .',
  validate: value => {
    if (value <= 0) {
      return false
    }
    return true
  }
});
VeeValidate.Validator.extend('skucheck', {
  getMessage: field => 'Sku should not start Special characters .',
  validate: value => {
    if (value.length) {
      if (value.charAt(0) == "#" || value.charAt(0) == "-") {
        return false
      }
    }
    return true
  }
});
var options = {
  position: "top",
  duration: 3000
}

dataURL = process.env.VUE_APP_FRAMEWORK360_URL;
// check if selected an endpoint, override dataURL
try {
  let selectedEndpoint: any = localStorage.getItem("selectedEndpoint");
  if (selectedEndpoint) {
    selectedEndpoint = JSON.parse(selectedEndpoint);
    if (selectedEndpoint.EPURL) {
      dataURL = selectedEndpoint.EPURL
    }
  }
} catch (error) {
  // do nothing
}

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
}
const win: any = window
win.setCookie = setCookie
win.getCookie = getCookie
win.eraseCookie = eraseCookie

function loadSessionFromLocalStorage(dataSession) {
  // console.log("dataSession:" + JSON.stringify(dataSession));
  sessionStorage.setItem('winWidth', dataSession.winWidth);
  sessionStorage.setItem('winHeight', dataSession.winHeight);
  sessionStorage.setItem('sessionID', dataSession.sessionID);
  sessionStorage.setItem('expireIn', dataSession.expireIn);
  sessionStorage.setItem('userId', dataSession.userId);
  sessionStorage.setItem('SYSTEM', dataSession.SYSTEM);
  sessionStorage.setItem('userName', dataSession.userName);
  sessionStorage.setItem('userRole', dataSession.userRole);
  sessionStorage.setItem('currentUser', dataSession.currentUser);
  sessionStorage.setItem('accountName', dataSession.accountName);
  sessionStorage.setItem('maxRows', dataSession.maxRows);
  sessionStorage.setItem('accountId', dataSession.accountId);
}

// console.log("VAR360_SESSION_LOCAL: " + dataSessionLocal.sessionID);     
var sessionID = sessionStorage.getItem('sessionID');
// localStorage.setItem("rememberMe","0")
if (sessionID == "" || sessionID == null) {
  var localSession = getCookie('VAR360_SESSION_LOCAL');
  // console.log("VAR360_SESSION_LOCAL: " + localSession);
  if (localSession != "" && localSession != null) {
    const dataSessionLocal = JSON.parse(localSession);
    loadSessionFromLocalStorage(dataSessionLocal);
  }
}

axios.interceptors.request.use(
  function (config) {
    var tempsettingsDataObj = 0;
    try {
      tempsettingsDataObj = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
    } catch (e) {
      console.error(e)
    }

    if (tempsettingsDataObj != 0 && typeof config.data != 'undefined') {
      var tokenObj = {};
      // Commentted by Thanh: fixed temp session for dev purpose.
      tokenObj["session"] = sessionStorage.getItem("sessionID")
      tokenObj["userRole"] = sessionStorage.getItem('userRole')
      // config.data = JSON.stringify(
      //   Object.assign(tempsettingsDataObj, tokenObj)
      // );
      config.data = Object.assign(tempsettingsDataObj, tokenObj)

      // append controller/function
      const r: string[] = [];
      if((config.data.controller || "") != "") {
        r.push(config.data.controller);
      }else if((config.data.Controller || "") != "") {
        r.push(config.data.Controller);
      }
      if((config.data.FunctionName || "") != "") {
        r.push(config.data.FunctionName);
      }
      if(r.length && config.url) {
        config.url += `&r=${r.join("/")}`;
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  var session = sessionStorage.getItem('sessionID');
  if (
    (!session || response.data.SESSIONSTATUS === -1)
    && !window.location.href.includes('passwordChange')
    && !window.location.href.includes('signUp')
    && !window.location.href.includes('pay?')
    && !window.location.href.includes('payinvoice?')
    && !window.location.href.includes('deployschedule/')
    && !window.location.href.includes('deploy?')
    && !window.location.href.includes('outlook?')
  ) {
    sessionStorage.clear();
    router.push('/')
  }

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

new Vue({
  el: '#body-site',
  router,
  store,
  data: {
    items: [],
    tableColumns: [],
    details: {},
    saveFn: null,
    loading: true,
    csvDownLoadFn: null,
    pdfDownLoadFn: null,
    loadNextpage: null,
    suggestedProducts: {},
    suggestedCustomers: {},
    suggestedQuotes: {},
    headers: [],
    allHeaders: [],
    excelData: [],
    headerContent: false,
  },
  methods: {
    notification(method: any, message: any) {
      notifier[method](message);
    },
    getUserRole() {
      return sessionStorage.getItem('userRole');
    },
    getMaxRows() {
      return sessionStorage.getItem('maxRows');
    },
    isPortalCustomer() { // 1: Customer, 0: Supplier
      const portalInfoValue = localStorage.getItem('PORTALINFO');
      if (portalInfoValue) {
        try{
          const portalInfo = JSON.parse(portalInfoValue);

          if (+portalInfo.ISPORTALCUSTOMER === 0) {
            return 0;
          } else {
            return 1;
          }
        } catch(error) {
          return 1;
        }
      }

      return 1;
    }
  },
  watch: {
    '$route'(to, from) {
      var self: any = this;
      var session = sessionStorage.getItem('sessionID');
      if(localStorage.rememberMe == 1 && !session ){
        session = sessionStorage.getItem('rememberMesession');
      }
      if(!session && localStorage.rememberMe == 1 && localStorage.lastSession){
        sessionStorage.setItem('sessionID',  JSON.parse(localStorage.lastSession).sessionID);
        sessionStorage.setItem('winWidth', JSON.parse(localStorage.lastSession).winWidth);
        sessionStorage.setItem('winHeight',  JSON.parse(localStorage.lastSession).winHeight);
        sessionStorage.setItem('expireIn',  JSON.parse(localStorage.lastSession).expireIn);
        sessionStorage.setItem('userId', JSON.parse(localStorage.lastSession).userId);
        sessionStorage.setItem('SYSTEM', JSON.parse(localStorage.lastSession).SYSTEM);
        sessionStorage.setItem('userName', JSON.parse(localStorage.lastSession).userName);
        sessionStorage.setItem('userFname', JSON.parse(localStorage.lastSession).userFname);
        sessionStorage.setItem('userRole',  JSON.parse(localStorage.lastSession).userRole);
        sessionStorage.setItem('currentUser',  JSON.parse(localStorage.lastSession).currentUser);
        sessionStorage.setItem('domain',  JSON.parse(localStorage.lastSession).domain);
        sessionStorage.setItem('accountName', JSON.parse(localStorage.lastSession).accountName);
        sessionStorage.setItem('maxRows', JSON.parse(localStorage.lastSession).maxRows);
        sessionStorage.setItem('accountId', JSON.parse(localStorage.lastSession).accountId);
        //storage session local when open multi tab
        setCookie('VAR360_SESSION_LOCAL', localStorage.lastSession,30);
        session = sessionStorage.getItem('sessionID');
      }
      if (!session && to.name != 'passwordChange' && to.name != 'signUp' && to.name != 'Pay' && to.name != 'Deploy' && to.name != 'OutlookAddIn') {
        sessionStorage.clear();
        router.push('/').catch(err => { });
      }
      if(sessionStorage.pageDetails == undefined){
        var pageArchieve = {
          Quotes : 1,
          Orders : 1,
          Invoices : 1,
          Order : 1,
          paychecks : 1,
          users : 1,
          customers : 1,
          Supports : 1,
          securityGroups : 1,
          AgentReportsDetails : 1,
          indirectorders : 1,
          customerPortals : 1,
          AgentReports : 1,
          Cashflow : 1
        }
        sessionStorage.setItem('pageDetails',JSON.stringify(pageArchieve))
      }
      if (sessionStorage.filterDetails == undefined) {
        var pageFilters = {
          Quotes: {},
          Orders: {},
          Invoices: {},
          Opportunities: {}
        }
        sessionStorage.setItem('filterDetails', JSON.stringify(pageFilters))
      }
      // Reset data to avoid the temp rendering before ajax data fetch
      self.items = [];
      self.tableColumns = [];

      if (to.name != undefined) {
        // console.log('LoadData', to.name, self);
        LoadData(to.name, self);
        /* dropdownInit(this); */
      }
    }
  },
  mounted: function () {
    var self: any = this;
    var curRoute = self._route.name;
    var session = sessionStorage.getItem('sessionID');
    if(!session && localStorage.rememberMe == 1 && localStorage.lastSession){
      sessionStorage.setItem('winWidth', JSON.parse(localStorage.lastSession).winWidth);
      sessionStorage.setItem('winHeight',  JSON.parse(localStorage.lastSession).winHeight);
      sessionStorage.setItem('sessionID',  JSON.parse(localStorage.lastSession).sessionID);
      sessionStorage.setItem('expireIn',  JSON.parse(localStorage.lastSession).expireIn);
      sessionStorage.setItem('userId', JSON.parse(localStorage.lastSession).userId);
      sessionStorage.setItem('SYSTEM', JSON.parse(localStorage.lastSession).SYSTEM);
      sessionStorage.setItem('userName', JSON.parse(localStorage.lastSession).userName);
      sessionStorage.setItem('userFname', JSON.parse(localStorage.lastSession).userFname);
      sessionStorage.setItem('userRole',  JSON.parse(localStorage.lastSession).userRole);
      sessionStorage.setItem('currentUser',  JSON.parse(localStorage.lastSession).currentUser);
      sessionStorage.setItem('domain',  JSON.parse(localStorage.lastSession).domain);
      sessionStorage.setItem('accountName', JSON.parse(localStorage.lastSession).accountName);
      sessionStorage.setItem('maxRows', JSON.parse(localStorage.lastSession).maxRows);
      sessionStorage.setItem('accountId', JSON.parse(localStorage.lastSession).accountId);
      //storage session local when open multi tab
      setCookie('VAR360_SESSION_LOCAL', localStorage.lastSession,30);
      if (curRoute == undefined){
        curRoute = "Dashboard"
      }
    }
    if (!session && !window.location.href.includes('passwordChange') && !window.location.href.includes('signup') && !window.location.href.includes('pay?') && !window.location.href.includes('payinvoice?') && !window.location.href.includes('deploy?') && !window.location.href.includes('demo')
      && !window.location.href.includes('deployschedule/') && !window.location.href.includes('outlook?')
    ) {
      sessionStorage.clear();
      router.push('/').catch(err => { });
    }

    if (curRoute == undefined)
      curRoute = 'Login';

    LoadData(curRoute, self);
    containerFunc(this);
    containerFuncCustomer(this);
    // grabAddHardware(this);
    ServicePublicFunc(this);
    orderPublicFunc(this);
    addNotes(this);
    supportUpdates(this);
    /* dropdownInit(this); */
  },
  computed: {
    loginUserName() {
      return sessionStorage.getItem('userName');
    },
    loginUserId() {
      return sessionStorage.getItem('userId');
    },
    loginUserRole() {
      return sessionStorage.getItem('userRole');
    }
  },
  render: h => h(App)
})
