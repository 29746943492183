import { Order } from '../models/Order';
import { OrderViewDetailed } from '../models/OrderViewDetailed';
import axios from "axios";


declare const dataURL: string;
declare const getMaxRows: Function

export enum OrderStatus {
  Placed = 1,
  Received = 2,
  Shipped = 3,
  Delivered = 4,
  Paid = 5,
  Production = 6,
  Cancelled = 7,
  PartialPay = 10,
  Complete = 13,
  CancelledComplete = 14
}
export enum CustomerOrderStatus {
  Placed = 1,
  Received = 2,
  Shipped = 3,
  Delivered = 4,
  Paid = 5,
  Production = 6,
  Cancelled = 7,
  PartialPay = 10,
  Complete = 13
}

export type advance = {
  hideIncludeItems?: boolean,
  hideZeroPrice?: boolean,
  includePayment?: boolean,
  priceRolledUp?: boolean,
  showFees?: boolean
}

export type headersarr = {
  header: string,
  column: string,
  widthPercent: string
}

export type onExport = {
  templateid?: number,
  showOnExport?: number,
  exportPO?: number,
  saveAsDefault?: number,
  pricingid?: string,
  advanced?: advance,
  customMessages?: object,
  CustomDistyName?: string,
  CustomDistyAddr1?: string,
  CustomDistyAddr2?: string,
  CustomDistyCity?: string,
  CustomDistyState?: string,
  CustomDistyZip?: string,
  exportView?: string,
  hardwareCSVExport: boolean
  isDistyTemplate: boolean
  templateCustomDetails: object;
  selectedSources?: any;
}

export enum UnverifyStatus {
  Open = 1,
  Close = 0
}

export type Filters = {
  searchAll: string
  searchId: string
  searchName: string
  viewFrom?: string
  searchAccountName: string
  sProfitMin: string | undefined
  sProfitMax: string | undefined
  sGrandTotalMin?: string | undefined
  sGrandTotalMax?: string | undefined
  sTotalMin: string | undefined
  sTotalMax: string | undefined
  sDate: string | undefined
  eDate: string | undefined
  sStatus: OrderStatus[]
  resellerCheckList: (number | undefined)[]
  AccountTagID: number[]
  gTagIDs: number[]
  groupIDs: number[]
  selectedAccs: (number | undefined)[]
  unassigned?: boolean
  selectedUnverifyAccts?: string[]
  customerPO?: string,
  customerNumber?: string,
  customerName?: string,
  fromDate?: string,
  endDate?: string
  sSource: string[]
  sTaxMin?: string | undefined
  sTaxMax?: string | undefined
  DirectLinePerMin?: string | undefined
  DirectLinePerMax?: string | undefined
  InvoicedPerMin?: string | undefined
  InvoicedPerMax?: string | undefined
  accountTagName?: (string | undefined)[]
  selectedAccsName?: (string | undefined)[]
  groupName?: (string | undefined)[]
  filterProfit?: string | undefined
  filterTax?: string | undefined
  filterSubTotal?: string | undefined
  filterGrandTotal?: string | undefined
  filterDate?: string | undefined
  statusName?: (string | undefined)[]
  order?: string
  sourceName?: (string | undefined)[]
  resellerPo?: string
  indQuoteId?: string
  filterPaycheck?: string | undefined
  customDataOrderNumber?: string 
  filterInvoiced?: string | undefined
  endUserName?: string | undefined
  shippingName?: string | undefined
  shippingAddress1?: string | undefined
  bAddress?: string | undefined
  bState?: string | undefined
  orderVarCostTotalMin?: string | undefined
  orderVarCostTotalMax?: string | undefined
  filterOrderVarCost?: string | undefined
  deptName?: string | undefined
  ledgerBalanceTotalMin?: string | undefined
  ledgerBalanceTotalMax?: string | undefined
  filterLedgerBalance?: string | undefined
  unverifyStatus?: UnverifyStatus[]
  reason?: string | undefined
  contract: (Number | string)[]
  SSURL: (Number | string)[]
  searchSSURL: string
  contractName: string[]
  businessLineId: string[]
  businessLineName: string[]
  securityGroupId: string[]
  securityGroupName: string[]
  globalTagName: (string | undefined)[]
}

export enum SortField {
  Id=1,
  Name=2,
  Customer=3,
  Profit=7,
  GrandTotal=4,
  Date=5,
  Status=6,
  Order=8,
  Source=9,
  Tax=10,
  DirectLinePer=11,
  CustomerPO=12,
  CUSTOMERORDERNUMBER=13,
  Total=14,
  InvoicedPer=15,
  EndUserName=16,
  shippingName=17,
  shippingAddress1=18,
  OrderVarCost=19,
  DepartmentName=20,
  LedgerBalance=21,
  ContractNumber=22,
  SSURL=23,
  SecurityGroupCount=24,
  businessLineName=25,
  GTAGOPTIONNAMES=26,
  IndirectQuoteID=27,
  aName=28,
  TaxRate=29,
  BADDRESS=30,
  BSTATE=31
}

export type statusFilters = {
  endUser?: string;
  statusID: productStatus[];
}

export enum productStatus {
  Cancelled = 1,
  Returned = 2,
  Delivered = -1,
  Shipped = -2,
  NoStatus = 0,
  EstimatedShip = -3,
  Production = -4,
  Placed = -5
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Id]: 1 | 2,
    [SortField.Name]: 1 | 2,
    [SortField.Customer]: 1 | 2,
    [SortField.Profit]: 1 | 2,
    [SortField.GrandTotal]?: 1 | 2,
    [SortField.Date]: 1 | 2,
    [SortField.Status]: 1 | 2,
    [SortField.Order]: 1 | 2,
    [SortField.Source]: 1 | 2,
    [SortField.Tax]: 1 | 2,
    [SortField.DirectLinePer]: 1 | 2,
    [SortField.CustomerPO]: 1 | 2,
    [SortField.CUSTOMERORDERNUMBER]: 1 | 2,
    [SortField.Total]: 1 | 2,
    [SortField.InvoicedPer]: 1 | 2,
    [SortField.EndUserName]: 1 | 2,
    [SortField.shippingName]: 1 | 2,
    [SortField.OrderVarCost]: 1 | 2,
    [SortField.DepartmentName]: 1 | 2,
    [SortField.LedgerBalance]: 1 | 2,
    [SortField.ContractNumber]: 1 | 2,
    [SortField.businessLineName]: 1 | 2
  }
}

export enum DetailSortField {
  Type = 1,
  Status = 2,
  DueDate = 3,
  InvoiceDate = 4,
}

export class OrderAPIRepo {
  async find(filters: Filters, sort: Sort, pageNumber: number, view: string = 'CurrentUsers', selectedView = false, isstatusfilter: boolean = false, filterBtn = 'currentList', initialLoad: boolean = false, allowedFuns:any = {}): Promise<Order.Root> {
    const reqData: any = this.prepareFilteredData(filters, sort, view, isstatusfilter, initialLoad, [],allowedFuns);
    reqData["FunctionName"] = "List";
    reqData["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
    reqData["PageNumber"] = pageNumber.toString();
    reqData["selectedView"] = selectedView;
    reqData["initialLoad"] = initialLoad;

    if (filterBtn == "unverify") {
      reqData["FunctionName"] = "UnverifiedList";
      reqData["unassigned"] = filters.unassigned;
      reqData["selectedUnverifyAccts"] = filters.selectedUnverifyAccts;
      reqData["customerPO"] = filters.customerPO;
      reqData["customerNumber"] = filters.customerNumber;
      reqData["customerName"] = filters.customerName;
      reqData["fromDate"] = filters.fromDate;
      reqData["endDate"] = filters.endDate;
      reqData['unverifyStatus'] = "-1";
      if (typeof filters.unverifyStatus != "undefined" && filters.unverifyStatus.length) {
        reqData['unverifyStatus'] = filters.unverifyStatus.join(",");
      }
      reqData["reason"] = filters.reason;
    }

    if (typeof filters["viewFrom"] != "undefined") {
      reqData["viewFrom"] = filters["viewFrom"];
    }

    // const reqData: any = {
    //   "controller": "Orders",
    //   "FunctionName": "List",
    //   "maxRows": getMaxRows(),
    //   "search": filters.searchAll,
    //   "PageNumber": pageNumber.toString(),
    //   "purchaseID": filters.searchId,
    //   "view": view,
    //   "sPurchaseName": filters.searchName,
    //   "sAccountName": filters.searchAccountName,
    //   "selectedResellers": filters.resellerCheckList
    // }

    // reqData['maxRows'] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()

    // if (typeof filters.sProfitMin !== 'undefined') {
    //   reqData['sProfitMin'] = filters.sProfitMin
    // }
    // if (typeof filters.sProfitMax !== 'undefined') {
    //   reqData['sProfitMax'] = filters.sProfitMax
    // }
    // if (typeof filters.sGrandTotalMin !== 'undefined') {
    //   reqData['sGrandTotalMin'] = filters.sGrandTotalMin
    // }
    // if (typeof filters.sGrandTotalMax !== 'undefined') {
    //   reqData['sGrandTotalMax'] = filters.sGrandTotalMax
    // }
    // if (filters.eDate) {
    //   reqData['eDate'] = filters.eDate
    // }
    // if (filters.sDate) {
    //   reqData['sDate'] = filters.sDate
    // }
    // if (filters.eDate) {
    //   reqData['eDate'] = filters.eDate
    // }
    // if (filters.sStatus.length > 0) {
    //   reqData['status'] = filters.sStatus.join(',')
    // }

    // if (sort.field) {
    //   reqData["order"] = sort.field
    //   reqData["direction"] = sort.direction[sort.field].toString()
    // }
    // console.log('reqData', reqData)

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    // console.log('response.data', response.data)

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      // throw new Error(response.data.STATUSMESSAGE);
      return response.data
    }

    return response.data
  }

  async findOneDetails(purchaseID: string, exportObj?: any, sort?: any, lineItemFilters?: statusFilters, isLazyLoad: boolean = false, addedRows: number = 0): Promise<OrderViewDetailed.Root> {
    const reqData: any = {
      controller: "Orders",
      FunctionName: "View",
      purchaseID: purchaseID,
      displayOnExport: exportObj ? exportObj.showOnExport : 0,
      exportPO: exportObj ? exportObj.exportPO : 0,
      saveAsDefault: exportObj ? exportObj.saveAsDefault : 0,
      SPECIALPRICINGID: exportObj ? exportObj.pricingid : '',
      exportTemplate: exportObj ? exportObj.templateid : 0,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      includePayment: exportObj && exportObj.advanced && exportObj.advanced.includePayment ? exportObj.advanced.includePayment : false,
      Content: "Detailed",
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
      CustomDistyName: typeof exportObj != "undefined" && typeof exportObj.CustomDistyName != "undefined" ? exportObj.CustomDistyName : '',
      CustomDistyAddr1: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr1 != "undefined" ? exportObj.CustomDistyAddr1 : '',
      CustomDistyAddr2: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr2 != "undefined" ? exportObj.CustomDistyAddr2 : '',
      CustomDistyCity: typeof exportObj != "undefined" && typeof exportObj.CustomDistyCity != "undefined" ? exportObj.CustomDistyCity : '',
      CustomDistyState: typeof exportObj != "undefined" && typeof exportObj.CustomDistyState != "undefined" ? exportObj.CustomDistyState : '',
      CustomDistyZip: typeof exportObj != "undefined" && typeof exportObj.CustomDistyZip != "undefined" ? exportObj.CustomDistyZip : '',
      filterStatus: typeof lineItemFilters != "undefined" && typeof lineItemFilters.statusID != "undefined" ? lineItemFilters.statusID : "",
      filterEndUser: typeof lineItemFilters != "undefined" && typeof lineItemFilters.endUser != "undefined" ? lineItemFilters.endUser : "",
      detailsView: exportObj ? (exportObj.detailsView || "") : "",
      returnFewData: exportObj ? (exportObj.returnFewData || 0) : 0,
      doExport: exportObj && (exportObj.doExport || 0) ? 1 : 0,
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0,
      showFees: exportObj && exportObj.advanced && exportObj.advanced.showFees ? 1 : 0,
      isDistyTemplate: exportObj && exportObj.isDistyTemplate ? 1 : 0,
      isLazyLoad: isLazyLoad,
      addedRows: addedRows,
      templateCustomDetails: (typeof exportObj != "undefined" && typeof exportObj.templateCustomDetails != "undefined" ? exportObj.templateCustomDetails : {})
    }

    if (typeof sort != 'undefined' && sort.field) {
      reqData["ledgerOrder"] = sort.field;
      reqData["ledgerDirection"] = sort.direction[sort.field];
    }
    if(exportObj && (exportObj.selectedSources || []).length) {
      reqData["exportSelectedSources"] = exportObj.selectedSources;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async exportOrderBySelect(purchaseIDs: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<Order.Root> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "List";
    reqData["selectedID"] = [];
    reqData["ExportType"] = "HTML";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;
    if(isOrderStatus){
      reqData["FunctionName"] = "statusList";
      reqData["ExportType"] = "HTML";
    }
    // var params: any = {
    //   controller: "Orders",
    //   FunctionName: "List",
    //   selectedID: [],
    //   selectedAll: selectedAll,
    //   excludedIDs: excludedIDs
    // }

    if (purchaseIDs.length > 0) {
      reqData.selectedID = purchaseIDs;
    } else {
      reqData.getAll = 1;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async exportListsPDFFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "Export";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "PDF";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;
    reqData["isOrderStatus"] = isOrderStatus;
    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportListsCSVFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: headersarr[] = [], isOrderStatus = 0): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "Export";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;
    reqData["isOrderStatus"] = isOrderStatus;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportOrderDetailFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "ExportOrderDetail";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async reportOrderDetailFileUrl(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "ReportingOrderDetails";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportETS(ids: (number | undefined)[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false): Promise<string> {
    const reqData: any = this.prepareFilteredData(filters, sort, viewType);
    reqData["FunctionName"] = "ExportETS";
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsPDFFileUrl(purchaseID: string, exportObj: onExport): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Orders",
      Content: "Detailed",
      ExportType: "PDF",
      FunctionName: "ExportView",
      displayOnExport: exportObj.showOnExport,
      exportPO: exportObj.exportPO,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      includePayment: exportObj && exportObj.advanced && exportObj.advanced.includePayment ? exportObj.advanced.includePayment : false,
      purchaseID: purchaseID,
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
      CustomDistyName: typeof exportObj != "undefined" && typeof exportObj.CustomDistyName != "undefined" ? exportObj.CustomDistyName : '',
      CustomDistyAddr1: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr1 != "undefined" ? exportObj.CustomDistyAddr1 : '',
      CustomDistyAddr2: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr2 != "undefined" ? exportObj.CustomDistyAddr2 : '',
      CustomDistyCity: typeof exportObj != "undefined" && typeof exportObj.CustomDistyCity != "undefined" ? exportObj.CustomDistyCity : '',
      CustomDistyState: typeof exportObj != "undefined" && typeof exportObj.CustomDistyState != "undefined" ? exportObj.CustomDistyState : '',
      CustomDistyZip: typeof exportObj != "undefined" && typeof exportObj.CustomDistyZip != "undefined" ? exportObj.CustomDistyZip : '',
      exportView: exportObj.exportView || "",
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0,
      showFees: exportObj && exportObj.advanced && exportObj.advanced.showFees ? 1 : 0,
      isDistyTemplate: exportObj && exportObj.isDistyTemplate ? 1 : 0,
      templateCustomDetails: (typeof exportObj != "undefined" && typeof exportObj.templateCustomDetails != "undefined" ? exportObj.templateCustomDetails : {}),
      exportSelectedSources: exportObj && (exportObj.selectedSources || []).length ? exportObj.selectedSources : undefined
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsCSVFileUrl(purchaseID: string, exportObj: onExport): Promise<string> {
    const requestObj = {
      controller: "Orders",
      Content: "Detailed",
      ExportType: exportObj.hardwareCSVExport ? "hardwareCSV" : "CSV",
      FunctionName: "ExportView",
      displayOnExport: exportObj.showOnExport,
      exportPO: exportObj.exportPO,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      includePayment: exportObj && exportObj.advanced && exportObj.advanced.includePayment ? exportObj.advanced.includePayment : false,
      purchaseID: purchaseID,
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
      CustomDistyName: typeof exportObj != "undefined" && typeof exportObj.CustomDistyName != "undefined" ? exportObj.CustomDistyName : '',
      CustomDistyAddr1: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr1 != "undefined" ? exportObj.CustomDistyAddr1 : '',
      CustomDistyAddr2: typeof exportObj != "undefined" && typeof exportObj.CustomDistyAddr2 != "undefined" ? exportObj.CustomDistyAddr2 : '',
      CustomDistyCity: typeof exportObj != "undefined" && typeof exportObj.CustomDistyCity != "undefined" ? exportObj.CustomDistyCity : '',
      CustomDistyState: typeof exportObj != "undefined" && typeof exportObj.CustomDistyState != "undefined" ? exportObj.CustomDistyState : '',
      CustomDistyZip: typeof exportObj != "undefined" && typeof exportObj.CustomDistyZip != "undefined" ? exportObj.CustomDistyZip : '',
      exportView: exportObj.exportView || ""
    };
    const response = await axios.post(dataURL + "?ReturnType=JSON", requestObj);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async updatePoOrder(po: string, purchaseId: number, orderName: string, aID: number, confirmedDuplicatePO: boolean): Promise<Order.Root> {
    var params: any = {
      controller: "Orders",
      FunctionName: "Edit",
      mdEditPo: po,
      mdaID: aID,
      mdEditOrdername: orderName,
      purchaseID: purchaseId,
      confirmedDuplicatePO: confirmedDuplicatePO
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", params);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    // if (response.data.STATUS !== 1) {
    //   throw new Error(response.data.STATUSMESSAGE);
    // }

    return response.data
  }

  async saveIncludeInPayroll(purchaseID: number, includeInPayroll: boolean) {
    var ret = true;

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Orders",
      FunctionName: "SaveOrderParams",
      paramType: "includeInPayroll",
      purchaseID: purchaseID,
      includeInPayroll: includeInPayroll ? 1 : 0
    });

    if (response.data.ERROR) {
      ret = false;
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      ret = false;
      throw new Error(response.data.STATUSMESSAGE);
    }

    return ret;
  }

  prepareFilteredData(filters: any, sort: any, view: string = 'CurrentUsers', isStatus: boolean = false, initialLoad: boolean = false, orderAllStatus: number[] = [], allowedFuns:any = {}) {
    var ret = {
      "controller": "Orders",
      "search": filters.searchAll,
      "sPONumber": filters.searchId,
      "view": view,
      "sPurchaseName": filters.searchName,
      "sAccountName": filters.searchAccountName,
      "sAName": filters.searchAName || "",
      "selectedResellers": filters.resellerCheckList,
      "systems": initialLoad ? 'all' : (filters.SSURL || []).join(",") || '-1',
      "selectedTagIDs": filters.AccountTagID.join(","),
      "tagIDs": filters.AccountTagID.join(","),
      "selectedAccountDs": filters.selectedAccs.join(","),
      "orderAllStatus": orderAllStatus.join(",")
    }

    if (typeof filters.sProfitMin !== 'undefined') {
      ret['sProfitMin'] = filters.sProfitMin
    }
    if (typeof filters.sProfitMax !== 'undefined') {
      ret['sProfitMax'] = filters.sProfitMax
    }
    if (typeof filters.sGrandTotalMin !== 'undefined') {
      ret['sGrandTotalMin'] = filters.sGrandTotalMin
    }
    if (typeof filters.sGrandTotalMax !== 'undefined') {
      ret['sGrandTotalMax'] = filters.sGrandTotalMax
    }
    if (typeof filters.sTotalMin !== 'undefined') {
      ret['sTotalMin'] = filters.sTotalMin
    }
    if (typeof filters.sTotalMax !== 'undefined') {
      ret['sTotalMax'] = filters.sTotalMax
    }
    if (filters.eDate) {
      ret['eDate'] = filters.eDate
    }
    if (filters.sDate) {
      ret['sDate'] = filters.sDate
    }

    if (filters.endDate) {
      ret['endDate'] = filters.endDate
    }

    if (filters.order) {
      ret['purchaseID'] = filters.order
    }
    if (filters.resellerPo) {
      ret['resellerPo'] = filters.resellerPo
    }
    if (filters.indQuoteId) {
      ret['indQuoteId'] = filters.indQuoteId
    }

    if (filters.eDate) {
      ret['eDate'] = filters.eDate
    }
    if (filters.sStatus.length > 0) {
      ret['status'] = filters.sStatus.join(',')
    }
    ret['source'] = "";
    if (filters.sSource.length > 0) {
      if (filters.viewFrom != 'cDetails') {
        ret['source'] = filters.sSource.join(',')
      }
    }
    else if (!initialLoad && filters.sSource.length == 0) {
      ret['source'] = sessionStorage.getItem('userRole') === 'Customer' ? "" : "-1";
    }
    ret['businessLine'] = "";
    if (sessionStorage.getItem('userRole') == 'Reseller' && typeof filters.businessLineId != 'undefined') {
      if (filters.businessLineId.length > 0) {
        ret['businessLine'] = filters.businessLineId.join(',')
      }
      else if (!initialLoad && filters.businessLineId.length == 0) {
        ret['businessLine'] = "-1";
      }
    }
    ret['securityGroup'] = "";
    if (sessionStorage.getItem('userRole') == 'Reseller' && typeof filters.securityGroupId != 'undefined' && allowedFuns.Security_Groups) {
      if (filters.securityGroupId.length > 0) {
        ret['securityGroup'] = filters.securityGroupId.join(',')
      }
      else if (!initialLoad && filters.securityGroupId.length == 0) {
        ret['securityGroup'] = "-1";
      }
    }
    if ((isStatus && filters.sStatus.length == 0) || (!initialLoad && filters.sStatus.length == 0)) {
      ret['status'] = '-1';
    }
    if (typeof filters.sTaxMin !== 'undefined') {
      ret['sTaxMin'] = filters.sTaxMin
    }
    if (typeof filters.sTaxMax !== 'undefined') {
      ret['sTaxMax'] = filters.sTaxMax
    }
    if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field] ? sort.direction[sort.field].toString() : "1";
    }
    ret["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");
    ret["groupIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");

    if (typeof filters.DirectLinePerMin !== 'undefined') {
      ret['DirectLinePerMin'] = filters.DirectLinePerMin
    }
    if (typeof filters.DirectLinePerMax !== 'undefined') {
      ret['DirectLinePerMax'] = filters.DirectLinePerMax
    }
    if (filters.customDataOrderNumber) {
      ret['customDataOrderNumber'] = filters.customDataOrderNumber
    }
    if (typeof filters.InvoicedPerMin !== 'undefined') {
      ret['InvoicedPerMin'] = filters.InvoicedPerMin
    }
    if (typeof filters.InvoicedPerMax !== 'undefined') {
      ret['InvoicedPerMax'] = filters.InvoicedPerMax
    }
    if (typeof filters.orderVarCostTotalMin !== 'undefined') {
      ret['orderVarCostTotalMin'] = filters.orderVarCostTotalMin
    }
    if (typeof filters.orderVarCostTotalMax !== 'undefined') {
      ret['orderVarCostTotalMax'] = filters.orderVarCostTotalMax
    }
    if (filters.endUserName) {
      ret['endUserName'] = filters.endUserName
    }
    if (filters.shippingName) {
      ret['shippingName'] = filters.shippingName
    }
    if (filters.shippingAddress1) {
      ret['shippingAddress1'] = filters.shippingAddress1
    }
    if (filters.deptName) {
      ret['sDeptName'] = filters.deptName;
    }
    if (typeof filters.ledgerBalanceTotalMin !== 'undefined') {
      ret['ledgerBalanceTotalMin'] = filters.ledgerBalanceTotalMin
    }
    if (typeof filters.ledgerBalanceTotalMax !== 'undefined') {
      ret['ledgerBalanceTotalMax'] = filters.ledgerBalanceTotalMax
    }
    ret['contract'] = "";
    if (filters.contractName && filters.contractName.length) {
      if (filters.viewFrom != 'cDetails') {
        ret['contract'] = filters.contractName.join(',')
      }
    }
    else if (!initialLoad && filters.contractName && !filters.contractName.length) {
      ret['contract'] = "-1";
    }
    if (filters.bAddress) {
      ret['bAddress'] = filters.bAddress;
    }
    if ((filters.bState || []).length) {
      ret['bState'] = filters.bState.join(",");
    }

    return ret;
  }

  async findStatus(filters: any, sort: Sort, pageNumber: number, view: string = 'CurrentUsers', selectedView = false, isstatusfilter: boolean = false, filterBtn = 'currentList', initialLoad: boolean = false, orderAllStatus: number[] = []): Promise<Order.Root> {
    const reqData: any = this.prepareFilteredData(filters, sort, view, isstatusfilter, initialLoad, orderAllStatus);
    reqData["FunctionName"] = "statusList";
    reqData["maxRows"] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows();
    reqData["PageNumber"] = pageNumber.toString();
    reqData["selectedView"] = selectedView;

    // Override the source filter from prepareFilteredData
    if (!initialLoad && filters.sSource.length == 0) {
      reqData['source'] = "-1";
    }

    if (filterBtn == "unverify") {
      reqData["FunctionName"] = "UnverifiedList";
    }
    if((filters.statusImportId || 0) > 0) {
      reqData["statusImportId"] = filters.statusImportId;
    }
    if(filters.directionField && sort.field) {
      reqData["direction"] = sort.direction[filters.directionField] || 1;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      return response.data
    }
    return response.data
  }
}
