import axios from "axios";

declare const dataURL: string;
declare const getMaxRows: Function

export enum SpiffStatus {
    Open = 1,
    Processing = 2,
    Completed = 3,
    Expired = 4,
    Ignored = 5,
    Addons = 6
}

export enum SpiffSource {
    hpDirect = 1,
    indirect = 2,
}

export type Filters = {
  searchAll?: string
  searchSku: string
  searchAccountName?: string
  searchOrder: string
  spiffStartDate: string | undefined
  spiffEndDate: string | undefined
  invoiceStartDate: string | undefined
  invoiceEndDate: string | undefined
  sRewardMin: string | undefined
  sRewardMax?: string | undefined
  sQuantityMin: string | undefined
  sQuantityMax?: string | undefined
  sTotalMin: string | undefined
  sTotalMax?: string | undefined
  sStatus?: SpiffStatus[]
  unassigned?: boolean
  searchAccount?: string
  AccountTagID: number[]
  groupIDs: number[]
  spiffType: SpiffSource[]
  selectedAccs: (number | undefined)[]
  sSource: string[]
  aState: string[]
  gTagIDs: number[]
  accountTagName: (string | undefined)[]
  selectedAccsName: (string | undefined)[]
  groupName: (string | undefined)[]
  statusName: (string | undefined)[]
  sourceName: (string | undefined)[]
  filterSpiffDate?: string | undefined
  filterInvoiceDate?: string | undefined
  filterRewardPoints?: string | undefined
  filterQty?: string | undefined
  filterTotal?: string | undefined
  order?: string | (string | null)[]
  searchVarOrder: string
}

export enum SortField {
  Sku = 1,
  SpiffDate = 2,
  Order = 3,
  InvoiceDate = 4,
  Account = 5,
  Per = 6,
  Qty = 7,
  Total = 8,
  Status = 9,
  Type = 10,
  VarOrder = 11
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Sku]: 1 | 2,
    [SortField.SpiffDate]: 1 | 2,
    [SortField.Order]: 1 | 2,
    [SortField.InvoiceDate]: 1 | 2,
    [SortField.Account]: 1 | 2,
    [SortField.Per]: 1 | 2,
    [SortField.Qty]: 1 | 2,
    [SortField.Total]: 1 | 2,
    [SortField.Status]: 1 | 2,
    [SortField.Type]: 1 | 2
    [SortField.VarOrder]: 1 | 2
  }
}

export class SpiffAPIRepo {

    async spiffList(filters: Filters, sort: Sort, pageNumber: number, viewType: string, selectedAccts: any, externalId: string | (string | null)[], selectedView = false, stateList: any = [], initialLoad: boolean = false) {
        var params: any = {
            "controller": "Finances",
            "FunctionName": "Spiffs",
            "sSKU": filters.searchSku,
            "sAccountName": filters.searchAccountName,
            "PageNumber": pageNumber.toString(),
            "purchaseID": filters.searchOrder,
            "session": sessionStorage.getItem('sessionID'),
            "view": viewType,
            "externalId": externalId,
            "selectedAccts": selectedAccts,
            "selectedTagIDs": filters.AccountTagID.join(","),
            "groupIDs": filters.groupIDs.join(","),
            "selectedAccountDs": filters.selectedAccs.join(","),
            "unassigned": filters.unassigned,
            "selectedView": selectedView,
            "gTagIDs": filters.gTagIDs.join(","),
            "aState": filters.aState.length ? filters.aState.join(',') : "-1",
            "stateList": stateList.join(','),
            "sVarOrder": filters.searchVarOrder
        }
        params['maxRows'] = sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows()
        if (filters.spiffStartDate) {
          params['spiff_sDate'] = filters.spiffStartDate
        }
        if (filters.spiffEndDate) {
          params['spiff_eDate'] = filters.spiffEndDate
        }

        if (filters.invoiceStartDate) {
          params['invoice_sDate'] = filters.invoiceStartDate
        }
        if (filters.invoiceEndDate) {
          params['invoice_eDate'] = filters.invoiceEndDate
        }
        if (filters.sRewardMin !== 'undefined') {
          params['sRewardMin'] = filters.sRewardMin
        }
        if (filters.sRewardMax !== 'undefined') {
          params['sRewardMax'] = filters.sRewardMax
        }
        if (filters.sQuantityMin !== 'undefined') {
          params['sQuantityMin'] = filters.sQuantityMin
        }
        if (filters.sQuantityMax !== 'undefined') {
          params['sQuantityMax'] = filters.sQuantityMax
        }
        if (filters.sTotalMin !== 'undefined') {
          params['sTotalMin'] = filters.sTotalMin
        }
        if (filters.sTotalMax !== 'undefined') {
          params['sTotalMax'] = filters.sTotalMax
        }
        if (sort.field) {
          params["order"] = sort.field
          params["direction"] = sort.direction[sort.field].toString()
        }
        params['status'] = "";
        if (filters && filters.sStatus && filters.sStatus.length > 0 && !initialLoad) {
          params['status'] = filters.sStatus.join(',')
        }
        else if (filters && filters.sStatus && filters.sStatus.length == 0) {
          params['status'] = "-1"
        }
        if (filters && filters.spiffType && filters.spiffType.length > 0) {
          params['spiffTypes'] = filters.spiffType.join(',')
        }
        params['source'] = "";
        if (filters.sSource.length > 0) {
          params['source'] = filters.sSource.join(',')
        }
        else if (filters.sSource.length == 0 && !initialLoad) {
          params['source'] = "-1";
        }

        const response = await axios.post(dataURL + "?ReturnType=JSON", params);
        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        // if (!response.data.STATUS) {
        //     throw new Error(response.data.STATUSMESSAGE);
        // }
        return response.data
    }

    async updateSpiffStatus(spiffID: string, statusID: number) {
        var params: any = {
            "controller": "Finances",
            "FunctionName": "updateSpiffStatus",
            "spiffID": spiffID,
            "statusID": statusID
        }
        const response = await axios.post(dataURL + "?ReturnType=JSON", params);

        if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
        }
        if (!response.data.STATUS) {
            throw new Error(response.data.STATUSMESSAGE);
        }

        return response.data
    }

  async exportListsCSVFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: any = []): Promise<string> {
    var reqData = this.prepareFilteredData(filters, sort, viewType);
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["FunctionName"] = "ExportSpiff";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  prepareFilteredData(filters: any, sort: any, view: string = 'CurrentUsers', initialLoad: boolean = false) {
    var ret = {
      "controller": "Finances",
      "sSKU": filters.searchSku,
      "sAccountName": filters.searchAccountName,
      "purchaseID": filters.searchOrder,
      "session": sessionStorage.getItem('sessionID'),
      "view": view,
      "selectedTagIDs": filters.AccountTagID.join(","),
      "groupIDs": filters.groupIDs.join(","),
      "selectedAccountDs": filters.selectedAccs.join(","),
      "unassigned": filters.unassigned,
      "gTagIDs": filters.gTagIDs.join(",")
    }

    if (filters.spiffStartDate) {
      ret['spiff_sDate'] = filters.spiffStartDate
    }
    if (filters.spiffEndDate) {
      ret['spiff_eDate'] = filters.spiffEndDate
    }
    if (filters.invoiceStartDate) {
      ret['invoice_sDate'] = filters.invoiceStartDate
    }
    if (filters.invoiceEndDate) {
      ret['invoice_eDate'] = filters.invoiceEndDate
    }
    if (filters.sRewardMin !== 'undefined') {
      ret['sRewardMin'] = filters.sRewardMin
    }
    if (filters.sRewardMax !== 'undefined') {
      ret['sRewardMax'] = filters.sRewardMax
    }
    if (filters.sQuantityMin !== 'undefined') {
      ret['sQuantityMin'] = filters.sQuantityMin
    }
    if (filters.sQuantityMax !== 'undefined') {
      ret['sQuantityMax'] = filters.sQuantityMax
    }
    if (filters.sTotalMin !== 'undefined') {
      ret['sTotalMin'] = filters.sTotalMin
    }
    if (filters.sTotalMax !== 'undefined') {
      ret['sTotalMax'] = filters.sTotalMax
    }
    if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field].toString()
    }
    ret['status'] = "";
    if (filters && filters.sStatus && filters.sStatus.length > 0 && !initialLoad) {
      ret['status'] = filters.sStatus.join(',')
    }
    else if (filters && filters.sStatus && filters.sStatus.length == 0) {
      ret['status'] = "-1"
    }
    if (filters && filters.spiffType && filters.spiffType.length > 0) {
      ret['spiffTypes'] = filters.spiffType.join(',')
    }
    ret['source'] = "";
    if (filters.sSource.length > 0) {
      ret['source'] = filters.sSource.join(',')
    }
    else if (filters.sSource.length == 0 && !initialLoad) {
      ret['source'] = "-1";
    }

    return ret;
  }

}