import { render, staticRenderFns } from "./StatusCreate.vue?vue&type=template&id=6ef14248&scoped=true"
import script from "./StatusCreate.vue?vue&type=script&lang=tsx"
export * from "./StatusCreate.vue?vue&type=script&lang=tsx"
import style0 from "./StatusCreate.vue?vue&type=style&index=0&id=6ef14248&prod&scoped=true&lang=less"
import style1 from "./StatusCreate.vue?vue&type=style&index=1&id=6ef14248&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef14248",
  null
  
)

export default component.exports