
















































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { removeHTML } from "@/helpers/ApiHelper";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class CustomMessages extends TSXComponent<Props, Events> {
  $parent: any;
  custStart = "";
  custEnd = "";
  custOff = "";
  custCustomer = "";
  emailFooter = "";
  custPrint = "";
  perSession = 0;

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.custStart = this._f(saved.StartMessage || "");
    this.custEnd = this._f(saved.EndMessage || "");
    this.custOff = this._f(saved.SiteOffMessage || "");
    this.custCustomer = this._f(saved.custInstructions || "");
    this.emailFooter = this._f(saved.emailFooterMessage || "");
    this.custPrint = this._f(saved.PrintOutMessage || "");
    if (saved.StartMessagePerSession.indexOf("checked") != -1) {
      this.perSession = 1;
    }
  }

  collectData() {
    // remove html
    for (const key of [
      "custStart",
      "custEnd",
      "custOff",
      "custCustomer",
      "emailFooter",
      "custPrint"
    ]) {
      this[key] = removeHTML(this[key]);
    }

    return {
      status: 1,
      data: {
        perSession: this.perSession ? "on" : undefined,
        custStart: this._fs(this.custStart),
        custEnd: this._fs(this.custEnd),
        custOff: this._fs(this.custOff),
        custCustomer: this._fs(this.custCustomer),
        emailFooter: this._fs(this.emailFooter),
        custPrint: this._fs(this.custPrint)
      }
    };
  }

  _f(str) {
    return str.replace(/<br *\/?>/gi, "\n");
  }

  _fs(str) {
    return str.replace(/\r?\n/g, "\r\n");
  }
}
