

























































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

import Loader from './Loader.vue'

export interface Item {
  id: number;
  text: string;
}

interface Props {
  list: Item[];
  loading: boolean;
  max_rows: number;
  indexValue : number;
  users: string;
}

interface Events {}

const MAX_ROWS = 5

@Component({
  inheritAttrs: false,
  components: {
    Loader,
  }
})
export default class SecurityGroupRowTooltip extends TSXComponent<
  Props,
  Events
> {
  @Prop({ required: true })
  list!: Item[];

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: false })
  hasLeftPadding!: boolean;

  @Prop({ required: false,default:5 })
  max_rows!: number;

 @Prop({ required: true})
  indexValue!: number;
  
 @Prop({ required: false})
  users!: string;

 @Prop({ required: false, default: false})
  isSecurityGroup!: boolean;

 @Prop({ required: false, default: false})
 isTracking!: boolean;

  get renderlist(): Item[] {
    if (this.list.length <= this.max_rows || this.isTracking) {
      return this.list;
    }

    return this.list.slice(0, this.max_rows);
  }

  get moreMsg() {
    if (this.list.length <= this.max_rows) {
      return "";
    }

    return `+ ${this.list.length - this.max_rows} More`;
  }
}
