var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$parent.loading)?_c('div',[_c('div',{staticClass:"profile-row"},[_c('div',{staticClass:"half left"},[_c('DropdownControl',{attrs:{"id":"billingSelect","name":"billingSelect","options":[
          { ID: 0, TEXT: 'OFF' },
          { ID: 1, TEXT: 'ON' }
        ],"selectedIDs":[_vm.billingSelect],"hideSelectAll":true,"multiSelect":false,"hideSearch":true,"hideCheckMark":true},on:{"check":function (id) {
            _vm.billingSelect = id;
          }},model:{value:(_vm.billingSelect),callback:function ($$v) {_vm.billingSelect=$$v},expression:"billingSelect"}}),_vm._m(0)],1),((_vm.billingSelect || 0) == 1)?_c('div',{staticClass:"half left"},[_c('DropdownControl',{attrs:{"id":"billingRequiredSelect","name":"billingRequiredSelect","options":[
          { ID: 0, TEXT: 'OFF' },
          { ID: 1, TEXT: 'ON' }
        ],"selectedIDs":[_vm.billingRequiredSelect],"hideSelectAll":true,"multiSelect":false,"hideSearch":true,"hideCheckMark":true},on:{"check":function (id) {
            _vm.billingRequiredSelect = id;
          }},model:{value:(_vm.billingRequiredSelect),callback:function ($$v) {_vm.billingRequiredSelect=$$v},expression:"billingRequiredSelect"}}),_vm._m(1)],1):_vm._e()])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"up",attrs:{"for":"billingSelect"}},[_c('span',[_vm._v("Billing")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"up",attrs:{"for":"billingRequiredSelect"}},[_c('span',[_vm._v("Required?")])])}]

export { render, staticRenderFns }