import axios from "axios";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";

declare const dataURL: string;
declare const getMaxRows: Function;

export type Filters = {
	user?: string
	sPayId?: string
	accountID?: string
	aName?: string
	paycheckName?: string
	poNumber?: string
	saleNo?: string
	sTotalMin?: string | undefined
	sTotalMax?: string | undefined
	paycheckID?: string
	payDateRange?: {
		sDate?: string | undefined,
		eDate?: string | undefined,
	}
	createdDate?: {
		sDate?: string | undefined,
		eDate?: string | undefined,
	}
	paycheckStatus?: number[],
	AccountTagID?: number[],
	groupIDs?: number[],
	selectedAccs?: (number | undefined)[],
	batchID?: string,
	selectedAccsName?: (string | undefined)[]
	compTotalMin?: string | undefined
	compTotalMax?: string | undefined,
	headerGroupIDs?: number[],
}

export enum SortField {
	paycheckID = 1,
	User = 2,
	paycheckName = 3,
	dateRange = 4,
	createdDate = 5,
	commissionPercent = 6,
	payTotal = 7,
	status = 8,
	batchID = 9,
	compTotal = 10
}

export type Sort = {
	field: SortField | null | number
	direction: {
		[SortField.paycheckID]: number,
		[SortField.User]: number,
		[SortField.paycheckName]: number,
		[SortField.dateRange]: number,
		[SortField.createdDate]: number,
		[SortField.commissionPercent]: number,
		[SortField.payTotal]: number,
		[SortField.status]: number,
		[SortField.batchID]: number
	}
}

export enum DetailSortField {
	total = 1,
	earning = 2,
	comPercent = 3,
	paidTotal = 4,
	lineItems = 5,
	Id = 6,
	Name = 7,
	compTotal = 8
}

export class PaycheckAPIRepo {
	async find(filters: Filters, sort: Sort, pageNumber: number, viewType = "CurrentUsers", selectedView = false, activeTab = "Employee") {
		var requestData = {
			"controller": "Finances",
			"FunctionName": "payCheck",
			"pageNumber": pageNumber,
			"maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : getMaxRows(),
			"user": filters.user,
			"sPayId": filters.paycheckID,
			"paycheckName": filters.paycheckName,
			"payDateRange_sDate": filters.payDateRange ? filters.payDateRange.sDate : '',
			"payDateRange_eDate": filters.payDateRange ? filters.payDateRange.eDate : '',
			"createdDate_sDate": filters.createdDate ? filters.createdDate.sDate : '',
			"createdDate_eDate": filters.createdDate ? filters.createdDate.eDate : '',
			"paycheckStatus": filters.paycheckStatus ? filters.paycheckStatus.join(",") : '',
			"view": viewType,
			"selectedView": selectedView,
			"batchID": filters.batchID || "",
			"activeTab": activeTab
		}

		if (typeof filters.sTotalMin !== 'undefined') {
			requestData['sTotalMin'] = filters.sTotalMin
		}
		if (typeof filters.sTotalMax !== 'undefined') {
			requestData['sTotalMax'] = filters.sTotalMax
		}
		if (typeof filters.compTotalMin !== 'undefined') {
			requestData['compTotalMin'] = filters.compTotalMin
		}
		if (typeof filters.compTotalMax !== 'undefined') {
			requestData['compTotalMax'] = filters.compTotalMax
		}

		if (sort.field) {
			requestData["order"] = sort.field;
			requestData["direction"] = sort.direction[sort.field].toString();
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	async getDetails(pageNumber: number, userID: number, paycheckID: number, filters: any, sort: any, viewType: string, filterByAccount: string, isExport: boolean = false) {
		var requestData = {
			"controller": "Finances",
			"FunctionName": "payCheckview",
			"pageNumber": pageNumber,
			"maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : 15,
			"empId": userID,
			"payId": paycheckID,
			"filters": JSON.stringify(filters),
			"viewType": viewType,
			"export": isExport,
			"selectedTagIDs": filters.AccountTagID.join(","),
			"groupIDs": filters.groupIDs.join(","),
			"headerGroupIDs": filters.headerGroupIDs.join(","),
			"selectedAccountDs": filters.selectedAccs.join(","),
			"filterByAccount": filterByAccount
		}

		if (sort.field) {
			requestData["order"] = sort.field;
			requestData["direction"] = sort.direction[sort.field];
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data;
	}

	async getAccountDetails(pageNumber: number, batchID: number, filters: any, sort: any, viewType: string, filterByAccount: string, isExport: boolean = false) {
		var requestData = {
			"controller": "Finances",
			"FunctionName": "payCheckview",
			"pageNumber": pageNumber,
			"maxRows": sessionStorage.getItem('maxRows') ? sessionStorage.getItem('maxRows') : 15,
			"batchID": batchID,
			"filters": JSON.stringify(filters),
			"viewType": viewType,
			"export": isExport,
			"selectedTagIDs": filters.AccountTagID.join(","),
			"groupIDs": filters.groupIDs.join(","),
			"headerGroupIDs": filters.headerGroupIDs.join(","),
			"selectedAccountDs": filters.selectedAccs.join(","),
			"filterByAccount": filterByAccount
		}

		if (sort.field) {
			requestData["order"] = sort.field;
			requestData["direction"] = sort.direction[sort.field];
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data;
	}

	async updateNotes(paycheckID: number, pNote: string) {
		var ret = true;
		var requestData = {
			"controller": "Finances",
			"FunctionName": "UpdatePaycheckNotes",
			"paycheckID": paycheckID,
			"pNote": pNote
		}

		const response = await axios.post(dataURL + "?ReturnType=JSON", requestData);

		if (response.data.ERROR) {
			ret = false;
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			ret = false;
			throw new Error(response.data.STATUSMESSAGE);
		}

		return ret;
	}

	async updateStatus(id: string, index: string, invoiceIds?: string, groupId?: number, paidAmt?: string) {
		var params: any = {
			"controller": "Finances",
			"FunctionName": "payCheckUpdate",
			"payID": id,
			"Status": index,
			"PaidAmt": paidAmt,
			"invoiceIds": invoiceIds,
			"groupId": groupId
		}
		const response = await axios.post(dataURL + "?ReturnType=JSON", params);
		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (!response.data.STATUS) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		return response.data
	}

	async exportListsPDFFileUrl(userID: number, paycheckID: number, batchID: number): Promise<string> {
		const response = await axios.post(dataURL + "?ReturnType=JSON", {
			controller: "Finances",
			ExportType: "PDF",
			empId: userID,
			viewType: 'all_detail_rows',
			payId: paycheckID,
			FunctionName: "Export",
			batchID: batchID
		});

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async exportListsCSVFileUrl(userID: number, paycheckID: number, exportDirectPaycheck: boolean, batchID: number): Promise<string> {
		const response = await axios.post(dataURL + "?ReturnType=JSON", {
			controller: "Finances",
			ExportType: "CSV",
			empId: userID,
			viewType: 'all_detail_rows',
			payId: paycheckID,
			FunctionName: "Export",
			exportDirectPaycheck: exportDirectPaycheck,
			batchID: batchID
		});

		if (response.data.ERROR) {
			throw new Error(response.data.ERROR);
		}
		if (response.data.STATUS !== 1) {
			throw new Error(response.data.STATUSMESSAGE);
		}

		if (!response.data.S3URL) {
			throw new Error('Exported file not found')
		}

		return response.data.S3URL
	}

	async deleteCycles(groupId: (number | undefined)[]) {
		var reqData: any = {
			"controller": "Finances",
			"FunctionName": "paycheckDelete",
			"groupIds": groupId
		}
		const response = await ApiHelper.callApi("post", reqData);
		if (response.ERROR) {
			throw new Error(response.ERROR);
		}
		if (!response.STATUS) {
			throw new Error(response.STATUSMESSAGE);
		}

		return response
	}
}

