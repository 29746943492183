














































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl }
})
export default class OrderSettings extends TSXComponent<Props, Events> {
  $parent: any;
  requestTypeSelect = 0;
  autoReleaseSelect = 0;
  notifyEmail = false;

  async created() {
    await this.$parent.getSavedSettings();
    this.requestTypeSelect = this.$parent.savedSettings.SitActiveStatus || 0;
    if ((this.$parent.savedSettings.AutoEmailUpdate || 0) == 1) {
      this.notifyEmail = true;
    }

    this.autoReleaseSelect =
      (this.$parent.savedSettings.AutoRelease || "") == "On" ? 0 : 1;
  }

  collectData() {
    return {
      status: 1,
      data: {
        requestType: `${this.requestTypeSelect}`,
        autoRelease: `${this.autoReleaseSelect}`,
        autoEmailSelect: this.notifyEmail ? "1" : "0"
      }
    };
  }
}
