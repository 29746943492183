



































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import Datepicker from "./Datepicker.vue";
import HardwareDetails from "@/components/Hardware/HardwareDetails.vue";
import { ApiHelper } from "@/helpers/all";
import EndUserInfo from "@/components/Deployment/EndUserInfo.vue";

declare const $: any;
declare const dataURL: string;

interface Props {
  invoicesDepositId: number;
}

@Component({
  inheritAttrs: false,
  components: {
    EndUserInfo,
    HardwareDetails,
    LaddaButton,
    ConfirmRemoveItemModal,
    Datepicker,
  }
})
export default class DeploymentsAssetsIdentifiersAddModal extends TSXComponent<void> {
  @Prop({ required: true, default: 0 })
  assetId?: number;

  @Prop({ required: false, default: 0 })
  deploymentId?: number;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: false, default: "" })
  assetSKU!: string;

  @Prop({ required: false, default: "" })
  assetName!: string;

  @Prop({ required: false, default: 0 })
  endUserId?: number;

  @Prop({ required: false, default: "" })
  callFrom?: string;

  @Prop({ required: false, default: [] })
  euDetails?: any[];

  @Prop({
    required: true,
    default: {
      PURCHASEID: '',
      PRODUCTNAME: '',
      PRODUCTTYPE: '',
      SKU: ''
    }
  })
  productDetails?: any;

  @Prop({ required: false, default: 0 })
  aID?: number;

  loading = false;
  addLoading: any = false;
  details: any = {};
  identifierData: any = [];
  linkedHardwares: any = [];
  currentRole = sessionStorage.getItem("userRole");
  warningMessage = "";
  inputField = "";
  changedField = 0;

  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    $(this.$refs.modal).modal("show");
    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close", this.callFrom);
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;
      // get linked hardwares
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "GetLinkedHardwares",
        deploymentId: this.deploymentId,
        productId: this.assetId,
        endUserId: this.endUserId || 0
      });
      if (response.data.STATUS) {
        this.details = response.data;
        this.linkedHardwares = (response.data.ITEMS || []).map(item => ({
          ...item,
          ASSETNUMBERBK: item.ASSETNUMBER,
          ASSETTAGBK: item.ASSETTAG
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async updateIdentifer(type: string, item: any) {
    if (type == "assetNumber" && item.ASSETNUMBER != item.ASSETNUMBERBK) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        action: "updateAssetNumber",
        hardwareId: item.HARDWAREID,
        hardwareUUID: item.HARDWAREUUID,
        assetNumber: item.ASSETNUMBER,
        aID: this.aID || 0
      });
      if (response.data.STATUS == 1) {
        // eslint-disable-next-line require-atomic-updates
        item.ASSETNUMBERBK = item.ASSETNUMBER;
        notifier.success(response.data.STATUSMESSAGE);
      } else {
        const message = response.data.STATUSMESSAGE || "";
        if (message != "") {
          notifier.alert(message);
        }
      }
    } else if (type == "assetTag" && item.ASSETTAG != item.ASSETTAGBK) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        action: "updateAssetTag",
        hardwareId: item.HARDWAREID,
        hardwareUUID: item.HARDWAREUUID,
        assetTag: item.ASSETTAG,
        aID: this.aID || 0
      });
      if (response.data.STATUS == 1) {
        // eslint-disable-next-line require-atomic-updates
        item.ASSETTAGBK = item.ASSETTAG;
        notifier.success(response.data.STATUSMESSAGE);
      }
    } else if (type == "endUser" && item.ENDUSERID != item.ENDUSERIDBK) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        action: "updateEndUser",
        hardwareId: item.HARDWAREID,
        hardwareUUID: item.HARDWAREUUID,
        productId: item.PRODUCTID,
        deploymentId: this.deploymentId || 0,
        linkId: item.LINKID,
        endUserId: item.ENDUSERID
      });
      if (response.data.STATUS == 1) {
        // eslint-disable-next-line require-atomic-updates
        item.ENDUSERIDBK = item.ENDUSERID;
      } else {
        ApiHelper.showErrorMessage(response.data.STATUSMESSAGE || 'Cant update enduser');
      }
    }
  }

  async addIdentifier() {
    try {
      this.loading = true;
      // add hardwares
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "AddHardwares",
        hardwareID: "[Auto Generate]",
        deploymentId: this.deploymentId || 0,
        purchaseId: this.productDetails.PURCHASEID || 0,
        hardwareSKU: this.productDetails.SKU || '',
        hDescription: this.productDetails.PRODUCTNAME || '',
        categoryId: this.productDetails.CATEGORYID || 0,
        endUserId: this.endUserId || 0,
        aID: this.aID || 0
      });
      if (response.data.STATUS) {
        this.fetchData();
      } else {
        notifier.alert(response.data.STATUSMESSAGE)
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  modalHardwareDetails: {
    showIndex: string;
    show: boolean;
    isLoading: boolean;
    data: any;
    reset: Function;
  } = {
    show: false,
    showIndex: '',
    isLoading: false,
    data: {},
    reset: () => {
      this.modalHardwareDetails.data = {
        id: 0,
        name: "",
        imageURL: "",
        sku: "",
        statusName: "",
        statusId: 0,
        order: {
          purchaseId: 0,
          poId: "N/A"
        },
        customer: {
          aId: 0,
          aName: "N/A",
          accountId: ""
        },
        deployment: {
          deploymentId: 0,
          deploymentName: "N/A"
        },
        categoryId: 0,
        categoryName: "",
        carrier: "",
        trackingNumber: "N/A",
        endUser: {
          userId: 0,
          name: "N/A",
          addr: "",
          cityZip: ""
        },
        identifiers: [
          {
            name: "VAR360 Hardware ID",
            type: "hardware_id",
            value: "N/A"
          },
          {
            name: "Serial Number",
            type: "asset_number",
            value: ""
          },
          {
            name: "Asset Tag",
            type: "asset_tag",
            value: ""
          }
        ],
        logs: [],
        warranty: [
          {
            name: "Warranty Name",
            type: "warranty_name",
            value: "N/A"
          },
          {
            name: "Start Date",
            type: "start_date",
            value: ""
          },
          {
            name: "End Date",
            type: "end_date",
            value: ""
          },
          {
            name: "Last Checked",
            type: "last_checked",
            value: ""
          }
        ],
        warrantyResponse: ""
      };
    }
  };

    checkDuplicateExists(header, value, hardwareId, index) {
    if(value != '') {
      const response = axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Deployments",
        FunctionName: "UpdateHardware",
        header: header,
        value: value,
        action: "checkDuplicate"
      });
      response.then(
        response => {
          this.warningMessage = response.data.STATUSMESSAGE;
          this.inputField = response.data.header;
        }
      )
      this.changedField = index;
    }
  }

  async openDetailsModal(item) {
    if (this.modalHardwareDetails.showIndex == item.HARDWAREID) {
      this.modalHardwareDetails.showIndex = '';
      return false;
    }
    this.modalHardwareDetails.isLoading = true;
    this.modalHardwareDetails.showIndex = item.HARDWAREID;
    this.modalHardwareDetails.show = true;
    this.modalHardwareDetails.reset();
    const response = await ApiHelper.callApi("post", {
      controller: "Hardware",
      FunctionName: "View",
      Content: "Detailed",
      ObjID: item.HARDWAREID
    });
    if (response.STATUS != 1) {
      notifier.alert(response.STATUSMESSAGE);
      this.modalHardwareDetails.show = false;
      return;
    }

    const hardwareData = response.hardware || {};
    const hardwareLogs: any = response.hardwareLogs || [];
    // @Todo() Update product details data
    this.modalHardwareDetails.data = {
      ...this.modalHardwareDetails.data,
      id: hardwareData.HARDWAREID || 0,
      hardwareUUID: hardwareData.HARDWAREUUID || "",
      imageURL: hardwareData.IMAGEURL || "",
      name: hardwareData.HDESCRIPTION || "",
      sku: hardwareData.HARDWARESKU || "",
      statusName: hardwareData.HSTATUS || "",
      categoryId: hardwareData.CATEGORYID || 0,
      categoryName: hardwareData.CATEGORYNAME || "",
      order: {
        purchaseId: hardwareData.PURCHASEID || 0,
        poId: hardwareData.POID || "N/A"
      },
      customer: {
        aId: hardwareData.AID || 0,
        aName: hardwareData.ANAME || "N/A",
        accountId: hardwareData.ACCOUNTID || ""
      },
      deployment: {
        deploymentId: hardwareData.DEPLOYMENTID || 0,
        deploymentName: hardwareData.DEPLOYMENTNAME || "N/A"
      },
      endUser: {
        fname: hardwareData.UFNAME || "N/A",
        lname: hardwareData.ULNAME || "",
        phone: hardwareData.UPHONE || "",
        account: hardwareData.UACCOUNT || "",
        lastonline: hardwareData.LOGTS || "",
        address1: hardwareData.ADDRESS1 || "",
        address2: hardwareData.ADDRESS2 || "",
        city: hardwareData.CITY || "",
        state: hardwareData.STATE || "",
        zip: hardwareData.ZIPCODE || "",
        email: hardwareData.EMAIL || ""
      },
      carrier: hardwareData.CARRIER || "",
      trackingNumber: hardwareData.TRACKINGNUMBER || "N/A",
      warrantyResponse: hardwareData.WARRANTYRESPONSE || ""
    };
    // identifiers data
    for (const item of this.modalHardwareDetails.data.identifiers) {
      if (item.type == "hardware_id") {
        item.value = hardwareData.HARDWAREID || 0;
      } else if (item.type == "asset_number") {
        item.value = hardwareData.ASSETNUMBER || "";
      } else if (item.type == "asset_tag") {
        item.value = hardwareData.ASSETTAG || "";
      }
    }
    // logs/add desc
    for (const item of hardwareLogs) {
      if (item.LOGTYPE == 1) {
        item.desc = "Product was added";
      } else {
        item.desc = item.LOGDETAILS || "";
      }
    }
    // warranty data
    for (const item of this.modalHardwareDetails.data.warranty) {
      if (item.type == "warranty_name") {
        item.value = hardwareData.WARRANTYNAME || "";
      } else if (item.type == "start_date") {
        item.value = hardwareData.STARTDATEFORMATTED || "";
      } else if (item.type == "end_date") {
        item.value = hardwareData.WARRENTYEXPFORMATTED || "";
      } else if (item.type == "last_checked") {
        item.value = hardwareData.LASTCHECKEDFORMATTED || "";
      }
    }
    this.modalHardwareDetails.data.logs = hardwareLogs;
    this.modalHardwareDetails.isLoading = false;
    this.modalHardwareDetails.data.show = true;
  }

  getCityZip(hardwareData) {
    let ret: string[] = [];
    if (hardwareData.UCITY) {
      ret.push(hardwareData.UCITY);
    }
    if (hardwareData.USTATE || hardwareData.UZIP) {
      ret.push(
        `${hardwareData.USTATE || ""} ${hardwareData.UZIP || ""}`.trim()
      );
    }
    return ret.join(", ");
  }
}
