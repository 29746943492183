




































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { validateFn } from "../helpers";
import { notifier } from "../models/common";
import AccountMultiselect from "../components/AccountMultiselect.vue";
import EmployeeMultiselect from "../components/EmployeeMultiselect.vue";
import LaddaButton from "../components/LaddaButton.vue";
import TagMultiselect from "../components/TagMultiselect.vue";
import axios from "axios";
import PageTitle from "../components/pageTitle.vue";
import BusinessLineMultiselect from "../components/BusinessLineMultiselect.vue";
import FormFunctions from "@/components/Form/FormFunctions.vue";

@Component({
  inheritAttrs: false,
  components: {
    FormFunctions,
    AccountMultiselect,
    EmployeeMultiselect,
    LaddaButton,
    TagMultiselect,
    PageTitle,
    BusinessLineMultiselect
  }
})
export default class SecurityGroupsAdd extends TSXComponent<void> {
  userOptions: {
    EMPLOYEEID?: number;
    ENAME?: string;
    AID?: number;
  }[] = [];
  tagOptions: {
    TAGID?: number;
    NAME?: string;
  }[] = [];
  blockedTagOptions: {
    TAGID?: number;
    NAME?: string;
  }[] = [];
  securityBusinessLineArr: {
    ID?: number;
    BUSINESSLINENAME?: string;
  }[] = [];
  securityBlockedBusinessLineArr: {
    ID?: number;
    BUSINESSLINENAME?: string;
  }[] = [];
  functionOptions: {
    SECURITYFUCTIONNAME?: string;
    SECURITYFUNCTIONID?: number;
    AID?: number;
  }[] = [];
  selectedAccountIds: (number | undefined)[] = [];
  selectedUserIds: (number | undefined)[] = [];
  selectedTagIds: (number | undefined)[] = [];
  selectedBlockedTagIds: (number | undefined)[] = [];
  selectedBusinessLineIds: (number | undefined)[] = [];
  selectedBlockedBusinessLineIds: (number | undefined)[] = [];
  gname = "";
  saving = false;
  userAccList: {
    USERID?: number;
    AID?: number;
    ANAME?: string;
  }[] = [];
  userGrpList: {
    EMPLOYEEID?: number;
    AID?: number;
    ENAME?: string;
    USERID?: number;
  }[] = [];
  tagList: {
    CUSTOMFIELDOPTIONID?: number;
    CUSTOMFIELDOPTIONNAME?: string;
  }[] = [];
  accList: {
    // USERID?: number,
    AID?: number;
    // ANAME?: string,
  }[] = [];
  isDuplicate = 0;
  items = {
    USERACCOUNTLIST: this.userAccList,
    ACCOUNTLIST: this.accList,
    ACCOUNTTOTAL: 0,
    USERID: 0,
    USERGROUPLIST: this.userGrpList,
    TAGLIST: this.tagList,
    ALLOWTAGLIST: [
      {
        CUSTOMFIELDOPTIONID: 0,
        CUSTOMFIELDOPTIONNAME: ""
      }
    ],
    BLOCKEDTAGLIST: [
      {
        CUSTOMFIELDOPTIONID: 0,
        CUSTOMFIELDOPTIONNAME: ""
      }
    ],
    ALLOWBUSINESSLINES: [
      {
        ID: 0,
        BUSINESSLINENAME: ""
      }
    ],
    BLOCKEDBUSINESSLINES: [
      {
        ID: 0,
        BUSINESSLINENAME: ""
      }
    ],
    FUNCTIONGROUPCATEGORIES: []
  };
  selectedFunctionIds: (number | undefined)[] = [];
  aID = "";
  customer = "";
  groupname = "";
  loading = false;
  groupTypes: any = [];
  selectedGroupType = 1;

  async created() {
    var objId =
      this.$route.params.id && parseInt(this.$route.params.id) > 0
        ? this.$route.params.id
        : sessionStorage.getItem("userId");
    var selectedUserId =
      this.$route.params.userId && parseInt(this.$route.params.userId) != 0
        ? this.$route.params.userId
        : "0";
    var dataObj = {
      controller: "SecurityGroups",
      FunctionName: "Add",
      userId: objId,
      selectedUserId: selectedUserId
    };
    this.selectedAccountIds = [];

    var response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    // securityData.then(function (response, statusText, jqXHR) {
    try {
      if (response.data.STATUS == 1) {
        const resData = response.data || {};
        const FUNCTIONGROUPCATEGORIES = (
          resData.FUNCTIONGROUPCATEGORIES || []
        ).map(group => {
          group.checked = false;
          group.OPTIONS.map(item => {
            item.checked = false;
            return item;
          });
          return group;
        });
        this.groupTypes = resData.GROUPTYPEARRAY;
        this.items = {
          ...resData,
          FUNCTIONGROUPCATEGORIES: FUNCTIONGROUPCATEGORIES
        };
        this.selectedAccountIds = response.data.USERACCOUNTLIST.map(function (
          u
        ) {
          return u.AID;
        });
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      } else if (response.data.MESSAGE) {
        notifier.alert(response.data.MESSAGE);
      }
    } catch (e) {
      //handle error
      console.log("profile.js : getRouteData error : ", e);
    }
    // }).catch(function (error) {
    //     console.log('error', error);
    // });
  }
  securityBackButton() {
    this.$router.push({ name: "SecurityGroups" });
  }
  validateFn;
  updateAccountList(type, index, aid) {
    if (type == "delete") {
      this.$delete(this.items.USERACCOUNTLIST, index);
      this.$delete(this.selectedAccountIds, index);
      $(".addAccount option[val=" + aid + "]").attr("disabled", false);
      $(".addAccount option[val=" + aid + "]").removeClass("highlight");
    }
  }
  updateUserList(type, index, employid) {
    if (type == "delete") {
      this.$delete(this.userOptions, index);
      this.$delete(this.selectedUserIds, index);

      $(".addUser option[val=" + employid + "]").attr("disabled", false);
      $(".addUser option[val=" + employid + "]").removeClass("highlight");
    }
  }
  updateTagList(type, index, tagId) {
    if (type == "delete") {
      this.$delete(this.tagOptions, index);
      this.$delete(this.selectedTagIds, index);
      const removedTag = this.items.ALLOWTAGLIST.filter(
        tag => tag.CUSTOMFIELDOPTIONID == tagId
      );
      this.items.BLOCKEDTAGLIST.push(removedTag[0]);
      //Sort by default allow tag list
      this.sortByTags(this.items.BLOCKEDTAGLIST);
      $(".addUser option[val=" + tagId + "]").attr("disabled", false);
      $(".addUser option[val=" + tagId + "]").removeClass("highlight");
    }
  }
  updateBlockedTagList(type, index, tagId) {
    if (type == "delete") {
      this.$delete(this.blockedTagOptions, index);
      this.$delete(this.selectedBlockedTagIds, index);
      const removedTag = this.items.BLOCKEDTAGLIST.filter(
        tag => tag.CUSTOMFIELDOPTIONID == tagId
      );
      this.items.ALLOWTAGLIST.push(removedTag[0]);
      //Sort by default allow tag list
      this.sortByTags(this.items.ALLOWTAGLIST);
      $(".addUser option[val=" + tagId + "]").attr("disabled", false);
      $(".addUser option[val=" + tagId + "]").removeClass("highlight");
    }
  }
  updateBusinessLineList(type, index, businessLineId) {
    if (type == "delete") {
      this.$delete(this.securityBusinessLineArr, index);
      this.$delete(this.selectedBusinessLineIds, index);
      const removedBusinessLine = this.items.ALLOWBUSINESSLINES.filter(
        businessLine => businessLine.ID == businessLineId
      );
      this.items.BLOCKEDBUSINESSLINES.push(removedBusinessLine[0]);
      this.sortBusinessLines(this.items.BLOCKEDBUSINESSLINES);
    }
  }
  updateBlockedBusinessLineList(type, index, businessLineId) {
    if (type == "delete") {
      this.$delete(this.securityBlockedBusinessLineArr, index);
      this.$delete(this.selectedBlockedBusinessLineIds, index);
      const removedBusinessLine = this.items.BLOCKEDBUSINESSLINES.filter(
        businessLine => businessLine.ID == businessLineId
      );
      this.items.ALLOWBUSINESSLINES.push(removedBusinessLine[0]);
      this.sortBusinessLines(this.items.ALLOWBUSINESSLINES);
    }
  }
  updateFunctionList(type, index, funcid) {
    if (type == "delete") {
      this.$delete(this.functionOptions, index);
      $(".addFunc option[val=" + funcid + "]").attr("disabled", false);
      $(".addFunc option[val=" + funcid + "]").removeClass("highlight");
      $(
        ".optionGroup#" + $(".addFunc option[val=" + funcid + "]").attr("id")
      ).attr("disabled", false);
    }
  }
  async AddGroupName(groupName) {
    this.groupname = groupName.replace(/  +/g, " ");
    this.gname = this.groupname;
    const paramsQuery = {
      controller: "SecurityGroups",
      FunctionName: "groupCheck",
      name: this.gname
    };
    const response = await axios.post(
      dataURL + "?ReturnType=JSON",
      paramsQuery
    );

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS == 0) {
      // notifier.alert(response.data.MESSAGE);
      this.isDuplicate = 1;
      // throw new Error(response.data.STATUSMESSAGE);
    } else if (response.data.STATUS == 2) {
      notifier.warning(response.data.MESSAGE);
      this.isDuplicate = 1;
    } else {
      console.log("success", response.data.MESSAGE);
      this.isDuplicate = 0;
    }
  }
  onChangeAccount() {
    var included = 0;
    for (var i = 0; i < this.items.USERACCOUNTLIST.length; i++) {
      if (
        this.items.USERACCOUNTLIST[i].AID ==
        $(".addAccount option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.items.USERACCOUNTLIST.push({
        ANAME: $(".addAccount option:selected span").attr("data-name"),
        AID: $(".addAccount option:selected span").attr("data-id")
      });
    }
    // $(".addAccount option:selected").remove();
    // $(".addAccount option:first-child").prop('selected', true);
    const numb = $(".addAccount option:selected span").attr("data-id");
    $(".addAccount option[val=" + numb + "]").attr("disabled", true);
    $(".addAccount option[val=" + numb + "]").addClass("highlight");
    $(".addAccount option:first-child").prop("selected", true);
  }
  onChangeUser() {
    var included = 0;
    for (var i = 0; i < this.userOptions.length; i++) {
      if (
        this.userOptions[i].AID ==
        $(".addUser option:selected span").attr("data-id")
      ) {
        included = 1;
      }
    }

    if (included != 1) {
      this.userOptions.push({
        ENAME: $(".addUser option:selected span").attr("data-name"),
        EMPLOYEEID: $(".addUser option:selected span").attr("data-id")
      });
    }
    const numb = $(".addUser option:selected span").attr("data-id");
    $(".addUser option[val=" + numb + "]").attr("disabled", true);
    $(".addUser option[val=" + numb + "]").addClass("highlight");
    $(".addUser option:first-child").prop("selected", true);
  }
  onChangeFunc() {
    const functionOptions = this.functionOptions;
    const numb = $(".addFunc option:selected span").attr("data-id");
    const numbAll = $(".addFunc option:selected").attr("val");
    var included = 0;
    var securityId: number[] = [];
    functionOptions.forEach(element => {
      if (element.SECURITYFUNCTIONID)
        securityId.push(element.SECURITYFUNCTIONID);
    });
    if ($(".addFunc option:selected").hasClass("optionGroup")) {
      $("option span[data-group=" + numb + "]").each(function (
        this: Window,
        index,
        el
      ) {
        if (securityId.indexOf($(this).attr("data-id")) == -1) {
          functionOptions.push({
            SECURITYFUCTIONNAME: $(this).attr("data-name"),
            SECURITYFUNCTIONID: $(this).attr("data-id")
          });
        }
        $(this).parent().attr("disabled", true).addClass("highlight");
      });
      $(".addFunc option:first-child").prop("selected", true);
      $(".addFunc option[val=" + numbAll + "]").attr("disabled", true);
    } else {
      for (var i = 0; i < this.functionOptions.length; i++) {
        if (
          this.functionOptions[i].AID ==
          $(".addFunc option:selected span").attr("data-id")
        ) {
          included = 1;
        }
      }

      if (included != 1) {
        this.functionOptions.push({
          SECURITYFUCTIONNAME: $(".addFunc option:selected span").attr(
            "data-name"
          ),
          SECURITYFUNCTIONID: $(".addFunc option:selected span").attr("data-id")
        });
      }
      $(".addFunc option[val=" + numb + "]").attr("disabled", true);
      $(".addFunc option[val=" + numb + "]").addClass("highlight");
      $(".addFunc option:first-child").prop("selected", true);
      var catId = $(".addFunc option[val=" + numb + "]").attr("id");
      if (
        $("#" + catId + ".optionChild").length ==
        $("#" + catId + ".optionChild.highlight").length
      ) {
        $("#" + catId + ".optionGroup").attr("disabled", true);
      } else {
        $("#" + catId + ".optionGroup").attr("disabled", false);
      }
    }
  }

  validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      //make sure an account is selected
      if (this.aID == "" && this.customer != "") {
        $("#customer")
          .parent()
          .find(".errorMsg")
          .html("Please select a customer from suggested list.");
        result = false;
      }

      //validate data input
      if (
        parseFloat($("#cost_productName_1").val()) < 1 ||
        parseFloat($("#cust_cost_productName_1").val()) < 1 ||
        parseInt($("#quantity_productName_1").val()) < 1 ||
        $("#shipping_productName_1").val() == "" ||
        $("#tax_rate_productName_1").val() == "" ||
        $("#tax_productName_1").val() == ""
      ) {
        $("#errorMsgLineQuote").html(
          "Let's enter enough info about SKU, Product, Cost and Quality, ..."
        );
        result = false;
      }
      if (this.isDuplicate) {
        result = false;
        notifier.alert("The group name already Exists in the system");
      }
      if (result) {
        e.preventDefault();
        this.saving = true;
        return this.addSecurityGroup(this);
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }

  addSecurityGroup(container) {
    var comInstance = this;
    comInstance.saving = true;
    var objId =
      container.$route.params.id && container.$route.params.id > 0
        ? container.$route.params.id
        : sessionStorage.getItem("userId");

    let accountOptions = this.items.USERACCOUNTLIST;
    if(this.selectedGroupType == 2) {
      // is function group, empty selected accounts
      accountOptions = this.items.USERACCOUNTLIST = [];
      this.selectedAccountIds = [];
      this.items.ACCOUNTTOTAL = 0;
    }

    var dataObj = {
      controller: "SecurityGroups",
      FunctionName: "Add",
      userId: objId,
      AddGroup: true,
      accountOptions,
      userOptions: this.userOptions,
      functionOptions: this.functionOptions,
      gname: this.gname,
      tagOptions: this.tagOptions,
      blockedTagOptions: this.blockedTagOptions,
      gAllowedBusinessLine: this.securityBusinessLineArr,
      gBlockedBusinessLine: this.securityBlockedBusinessLineArr,
      selectedGroupTypeId: this.selectedGroupType
    };

    var securityData = getRouteData(dataObj);
    securityData
      .then(function (response, statusText, jqXHR) {
        try {
          if (response.data.STATUS == 1) {
            // container.items = response.data;
            notifier.success(response.data.MESSAGE);
            comInstance.$router.push({ name: "SecurityGroups" });
          } else if (response.data.ERROR) {
            console.log(response.data.ERROR);
          } else if (response.data.MESSAGE) {
            notifier.alert(response.data.MESSAGE);

            // container.notification('alert', response.data.MESSAGE);
          }
          comInstance.saving = false;
        } catch (e) {
          //handle error
          console.log("profile.js : getRouteData error : ", e);
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }
  toggleSelectedAccIds(userId, accountName) {
    const index = this.selectedAccountIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedAccountIds.push(userId);
      this.items.USERACCOUNTLIST.push({
        USERID: this.items.USERID,
        ANAME: accountName,
        AID: userId
      });
      this.items.ACCOUNTTOTAL = this.items.USERACCOUNTLIST.length;
      return;
    }

    this.selectedAccountIds.splice(index, 1);
    this.$delete(this.items.USERACCOUNTLIST, index);
  }
  selectAllAccs() {
    this.resetAccs();
    var thisUserID = this.items.USERID;
    var $this = this;
    var ids = this.items.ACCOUNTLIST.filter(u => u.AID != undefined);
    this.selectedAccountIds = ids.map(u => u.AID);

    $.each(this.items.ACCOUNTLIST, function (i, val) {
      $this.items.USERACCOUNTLIST.push({
        USERID: thisUserID,
        ANAME: val.ANAME,
        AID: val.AID
      });
    });
  }
  resetAccs() {
    this.selectedAccountIds = [];
    this.items.USERACCOUNTLIST = [];
  }
  // user multi Select
  toggleSelectedUserId(userId, name) {
    const index = this.selectedUserIds.findIndex(id => id === userId);
    if (index === -1) {
      this.selectedUserIds.push(userId);
      this.userOptions.push({
        EMPLOYEEID: userId,
        ENAME: name
      });
      return;
    }
    this.userOptions.splice(index, 1);
    this.selectedUserIds.splice(index, 1);
  }
  toggleSelectedTagId(tagId, name, selectedTag) {
    const index = this.selectedTagIds.findIndex(id => id === tagId);
    const blockedTagIndex = this.items.BLOCKEDTAGLIST.findIndex(
      (tag: any) => tag.CUSTOMFIELDOPTIONID === tagId
    );
    if (index === -1) {
      this.selectedTagIds.push(tagId);
      this.tagOptions.push({
        TAGID: tagId,
        NAME: name
      });
      if (blockedTagIndex > -1) {
        this.items.BLOCKEDTAGLIST.splice(blockedTagIndex, 1);
      }
      return;
    }
    this.selectedTagIds.splice(index, 1);
    this.tagOptions.splice(index, 1);
    this.items.BLOCKEDTAGLIST.push(selectedTag);
    //Sort by default blocked tag list
    this.sortByTags(this.items.BLOCKEDTAGLIST);
  }
  toggleSelectedBlockedTagId(tagId, name, selectedTag) {
    const index = this.selectedBlockedTagIds.findIndex(id => id === tagId);
    const allowTagIndex = this.items.ALLOWTAGLIST.findIndex(
      (tag: any) => tag.CUSTOMFIELDOPTIONID === tagId
    );
    if (index === -1) {
      this.selectedBlockedTagIds.push(tagId);
      this.blockedTagOptions.push({
        TAGID: tagId,
        NAME: name
      });
      if (allowTagIndex > -1) {
        this.items.ALLOWTAGLIST.splice(allowTagIndex, 1);
      }
      return;
    }
    this.selectedBlockedTagIds.splice(index, 1);
    this.blockedTagOptions.splice(index, 1);
    this.items.ALLOWTAGLIST.push(selectedTag);
    //Sort by default allow tag list
    this.sortByTags(this.items.ALLOWTAGLIST);
  }
  toggleSelectedBusinessLineId(businessLineId, name, selectedBusinessLine) {
    const index = this.selectedBusinessLineIds.findIndex(
      id => id === businessLineId
    );
    const blockedBusinessLineIndex = this.items.BLOCKEDBUSINESSLINES.findIndex(
      (businessLine: any) => businessLine.ID === businessLineId
    );
    if (index === -1) {
      this.selectedBusinessLineIds.push(businessLineId);
      this.securityBusinessLineArr.push({
        ID: businessLineId,
        BUSINESSLINENAME: name
      });
      if (blockedBusinessLineIndex > -1) {
        this.items.BLOCKEDBUSINESSLINES.splice(blockedBusinessLineIndex, 1);
      }
      return;
    }
    this.selectedBusinessLineIds.splice(index, 1);
    this.securityBusinessLineArr.splice(index, 1);
    this.items.BLOCKEDBUSINESSLINES.push(selectedBusinessLine);
    this.sortBusinessLines(this.items.BLOCKEDBUSINESSLINES);
  }
  toggleSelectedBlockedBusinessLineId(
    businessLineId,
    name,
    selectedBusinessLine
  ) {
    const index = this.selectedBlockedBusinessLineIds.findIndex(
      id => id === businessLineId
    );
    const allowBusinessLineIndex = this.items.ALLOWBUSINESSLINES.findIndex(
      (businessLine: any) => businessLine.ID === businessLineId
    );
    if (index === -1) {
      this.selectedBlockedBusinessLineIds.push(businessLineId);
      this.securityBlockedBusinessLineArr.push({
        ID: businessLineId,
        BUSINESSLINENAME: name
      });
      if (allowBusinessLineIndex > -1) {
        this.items.ALLOWBUSINESSLINES.splice(allowBusinessLineIndex, 1);
      }
      return;
    }
    this.selectedBlockedBusinessLineIds.splice(index, 1);
    this.securityBlockedBusinessLineArr.splice(index, 1);
    this.items.ALLOWBUSINESSLINES.push(selectedBusinessLine);
    this.sortBusinessLines(this.items.ALLOWBUSINESSLINES);
  }
  selectAllUsers() {
    this.resetUsers();
    var $this = this;
    this.selectedUserIds = this.items.USERGROUPLIST.map(u => u.USERID);
    $.each(this.items.USERGROUPLIST, function (i, val) {
      $this.userOptions.push({
        EMPLOYEEID: val.USERID,
        ENAME: `${val.UFNAME} ${val.ULNAME}`
      });
    });
  }
  resetUsers() {
    this.selectedUserIds = [];
    this.userOptions = [];
  }
  selectAllTags() {
    this.resetTags();
    var $this = this;
    this.selectedTagIds = this.items.ALLOWTAGLIST.map(
      u => u.CUSTOMFIELDOPTIONID
    );
    $.each(this.items.ALLOWTAGLIST, function (i, val) {
      $this.tagOptions.push({
        TAGID: val.CUSTOMFIELDOPTIONID,
        NAME: val.CUSTOMFIELDOPTIONNAME
      });
    });
    for (let i = 0; i < this.items.BLOCKEDTAGLIST.length; i++) {
      if (
        this.selectedTagIds.includes(
          this.items.BLOCKEDTAGLIST[i].CUSTOMFIELDOPTIONID
        )
      ) {
        this.$delete(this.items.BLOCKEDTAGLIST, i);
        i--;
      }
    }
  }
  resetTags() {
    var $this = this;
    $.each(this.items.ALLOWTAGLIST, function (index, value) {
      if ($this.selectedTagIds.includes(value.CUSTOMFIELDOPTIONID)) {
        $this.items.BLOCKEDTAGLIST.push(value);
      }
    });
    this.selectedTagIds = [];
    this.tagOptions = [];
    //Sort by default blocked tag list
    this.sortByTags(this.items.BLOCKEDTAGLIST);
  }
  selectAllBlockedTags() {
    this.resetBlockedTags();
    var $this = this;
    this.selectedBlockedTagIds = this.items.BLOCKEDTAGLIST.map(
      u => u.CUSTOMFIELDOPTIONID
    );
    $.each(this.items.BLOCKEDTAGLIST, function (i, val) {
      $this.blockedTagOptions.push({
        TAGID: val.CUSTOMFIELDOPTIONID,
        NAME: val.CUSTOMFIELDOPTIONNAME
      });
    });
    for (let i = 0; i < this.items.ALLOWTAGLIST.length; i++) {
      if (
        this.selectedBlockedTagIds.includes(
          this.items.ALLOWTAGLIST[i].CUSTOMFIELDOPTIONID
        )
      ) {
        this.$delete(this.items.ALLOWTAGLIST, i);
        i--;
      }
    }
  }
  resetBlockedTags() {
    var $this = this;
    $.each(this.items.BLOCKEDTAGLIST, function (index, value) {
      if ($this.selectedBlockedTagIds.includes(value.CUSTOMFIELDOPTIONID)) {
        $this.items.ALLOWTAGLIST.push(value);
      }
    });
    this.selectedBlockedTagIds = [];
    this.blockedTagOptions = [];
    //Sort by default allow tag list
    this.sortByTags(this.items.ALLOWTAGLIST);
  }
  sortByTags(tagList) {
    tagList.sort(function (t1, t2) {
      return (
        t1.CUSTOMFIELDOPTIONORDER - t2.CUSTOMFIELDOPTIONORDER ||
        t1.CUSTOMFIELDOPTIONNAME.localeCompare(t2.CUSTOMFIELDOPTIONNAME)
      );
    });
  }
  selectAllBusinessLine() {
    this.resetBusinessLine();
    var $this = this;
    this.items.ALLOWBUSINESSLINES.forEach(businessLine => {
      $this.selectedBusinessLineIds.push(businessLine.ID);
      $this.securityBusinessLineArr.push({
        ID: businessLine.ID,
        BUSINESSLINENAME: businessLine.BUSINESSLINENAME
      });
    });
    for (let i = 0; i < this.items.BLOCKEDBUSINESSLINES.length; i++) {
      if (
        this.selectedBusinessLineIds.includes(
          this.items.BLOCKEDBUSINESSLINES[i].ID
        )
      ) {
        this.$delete(this.items.BLOCKEDBUSINESSLINES, i);
        i--;
      }
    }
  }
  resetBusinessLine() {
    var $this = this;
    this.items.ALLOWBUSINESSLINES.forEach(businessLine => {
      if ($this.selectedBusinessLineIds.includes(businessLine.ID)) {
        $this.items.BLOCKEDBUSINESSLINES.push(businessLine);
      }
    });
    this.selectedBusinessLineIds = [];
    this.securityBusinessLineArr = [];
  }
  selectAllBlockedBusinessLine() {
    this.resetBlockedBusinessLine();
    var $this = this;
    this.items.BLOCKEDBUSINESSLINES.forEach(businessLine => {
      $this.selectedBlockedBusinessLineIds.push(businessLine.ID);
      $this.securityBlockedBusinessLineArr.push({
        ID: businessLine.ID,
        BUSINESSLINENAME: businessLine.BUSINESSLINENAME
      });
    });
    for (let i = 0; i < this.items.ALLOWBUSINESSLINES.length; i++) {
      if (
        this.selectedBlockedBusinessLineIds.includes(
          this.items.ALLOWBUSINESSLINES[i].ID
        )
      ) {
        this.$delete(this.items.ALLOWBUSINESSLINES, i);
        i--;
      }
    }
  }
  resetBlockedBusinessLine() {
    var $this = this;
    this.items.BLOCKEDBUSINESSLINES.forEach(businessLine => {
      if ($this.selectedBlockedBusinessLineIds.includes(businessLine.ID)) {
        $this.items.ALLOWBUSINESSLINES.push(businessLine);
      }
    });
    this.selectedBlockedBusinessLineIds = [];
    this.securityBlockedBusinessLineArr = [];
  }
  // FUNCTIONS
  updateSelectedFunctions() {
    this.functionOptions = [];
    this.items.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      if (cat.checked) {
        // this.functionOptions.push({
        //   SECURITYFUCTIONNAME: cat.SECURITYFUNCTIONCATEGORY,
        //   SECURITYFUNCTIONID: cat.TEMPLATEGROUP
        // });
      }
      cat.OPTIONS.map((item: any) => {
        if (item.checked) {
          this.functionOptions.push({
            SECURITYFUCTIONNAME: item.SECURITYFUCTIONNAME,
            SECURITYFUNCTIONID: item.SECURITYFUNCTIONID
          });
        }
      });
      cat.checked = cat.OPTIONS.find(item => !item.checked) ? false : true;
    });
  }
  toggleSelectedFunctionIds(funcId, funcName) {
    const selectedIds: any[] = [];
    this.items.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      if (cat.checked) {
        // selectedIds.push(cat.TEMPLATEGROUP);
      }
      cat.OPTIONS.map((item: any) => {
        if (item.checked) {
          selectedIds.push(item.SECURITYFUNCTIONID);
        }
      });
    });
    this.selectedFunctionIds = selectedIds;
    this.updateSelectedFunctions();
  }
  selectAllFunctions() {
    const selectedIds: any[] = [];
    this.items.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      cat.checked = true;
      // selectedIds.push(cat.TEMPLATEGROUP);
      cat.OPTIONS.map((item: any) => {
        item.checked = true;
        selectedIds.push(item.SECURITYFUNCTIONID);
      });
    });
    this.selectedFunctionIds = selectedIds;
    this.updateSelectedFunctions();
  }
  resetFunctions() {
    this.selectedFunctionIds = [];
    this.items.FUNCTIONGROUPCATEGORIES.map((cat: any) => {
      cat.checked = false;
      cat.OPTIONS.map((item: any) => {
        item.checked = false;
      });
    });
    this.updateSelectedFunctions();
  }

  sortBusinessLines(blArray: any) {
    blArray.sort((a: any, b: any) => {
      if (a.SORTINGBLID == b.SORTINGBLID) {
        return a.ID - b.ID;
      }
      return a.SORTINGBLID - b.SORTINGBLID;
    });
  }
}
