import { Quote } from '../models/Quote';
import { QuoteViewDetailed } from '../models/QuoteViewDetailed';
import axios from "axios";
import { notifier } from "@/models/common";

declare const dataURL: string;
declare const getMaxRows: Function

export enum QuoteStatus {
  Requested = 1,
  Assigned = 2,
  Created = 3,
  Ordered = 4,
}

export enum ResellerCheckList {
  'TestVAR' = 'Test VAR',
  'TestCustomer' = 'Test Customer',
  'Milestone' = 'Milestone'
}
export type advance = {
  hideIncludeItems?:boolean,
  hideZeroPrice?:boolean,
  priceRolledUp?: boolean
}

export type headersarr = {
  header: string,
  column: string,
  widthPercent: string
}

export type onExport = {
  templateid?: number,
  showOnExport?: number,
  saveAsDefault?: number,
  pricingid?: string,
  subTempName?: string,
  advanced?:advance,
  customMessages?: object,
  templateCustomDetails?: object;
  doExport?: number
}
export type Filters = {
  searchAll: string
  filterStatusName: (string | undefined)[]
  searchId: string
  searchName: string
  searchAccountName: string
  sDate: string | undefined
  eDate: string | undefined
  sGrandTotalMin: string | undefined
  sGrandTotalMax: string | undefined
  sTotalMin: string | undefined
  sTotalMax: string | undefined
  sProfitMin: string | undefined
  sProfitMax: string | undefined
  sStatus: QuoteStatus[]
  resellerCheckList: ResellerCheckList[]
  qOpenStatus: any,
  AccountTagID: any,
  sSource: string[],
  HpQuote: string,
  selectedAccs: (number | undefined)[]
  gTagIDs: number[]
  groupIDs: number[]
  accountTagName: (string | undefined)[]
  selectedAccountsName: (string | undefined)[]
  groupName: (string | undefined)[]
  filterTotal: string | undefined
  filterGrandTotal: string | undefined
  filterQuoteDate:string | undefined
  sourceName: (string | undefined)[]
  deptName: string | undefined
  businessLineId: string[]
  businessLineName: string[]
  quoteCreator: string
}

export enum SortField {
  Id = 1,
  Name = 2,
  Customer = 3,
  GrandTotal = 4,
  Date = 5,
  qOpenStatus = 6,
  Source = 7,
  Total = 8,
  DepartmentName = 9,
  system = 10,
  businessLineName = 11,
  quoteCreator = 12,
  aName = 13,
  profit = 14,
  varCost = 15
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Id]: 1 | 2,
    [SortField.Name]: 1 | 2,
    [SortField.Customer]: 1 | 2,
    [SortField.GrandTotal]: 1 | 2,
    [SortField.Date]: 1 | 2,
    [SortField.qOpenStatus]: 1 | 2,
    [SortField.Source]: 1 | 2,
    [SortField.Total]: 1 | 2,
    [SortField.DepartmentName]: 1 | 2,
    [SortField.system]: 1 | 2,
    [SortField.businessLineName]: 1 | 2,
    [SortField.quoteCreator]: 1 | 2,
    [SortField.profit]: 1 | 2,
    [SortField.varCost]: 1 | 2
  }
}

export class QuoteAPIRepo {
  async find(filters: Filters, sort: Sort, pageNumber: number, view: string = 'CurrentUsers', selectedView = false, selectedStatuses = false, initialLoad: boolean = false): Promise<Quote.Root> {
    //const searchAccountName = filters.resellerCheckList.length > 0 ? filters.resellerCheckList.toString() : filters.searchAccountName
    const qOpenStatusOne = !initialLoad && filters.qOpenStatus.length == 0 ? filters.qOpenStatus.push(-1) : filters.qOpenStatus

    const reqData: any = this.prepareFilteredData(filters, sort, view, initialLoad);
    reqData["FunctionName"] = "List";
    reqData["maxRows"] = (sessionStorage.getItem('maxRows') != '' ? sessionStorage.getItem('maxRows') : getMaxRows());
    reqData["PageNumber"] = pageNumber.toString();
    reqData["selectedView"] = selectedView;
    reqData["selectedStatuses"] = selectedStatuses;

    if (typeof filters["viewFrom"] != "undefined") {
      reqData["viewFrom"] = filters["viewFrom"];
    }
    console.log('reqData', reqData);

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    // console.log('response.data', response.data)

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async findOneDetails(quoteId: string, exportObj?: onExport): Promise<QuoteViewDetailed.Root> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      FunctionName: "View",
      ObjID: quoteId,
      displayOnExport: exportObj ? exportObj.showOnExport : 0,
      saveAsDefault: exportObj ? exportObj.saveAsDefault : 0,
      SPECIALPRICINGID: exportObj ? exportObj.pricingid : '',
      exportTemplate: exportObj ? exportObj.templateid : 0,
      Aztempname: exportObj ? exportObj.subTempName : "",
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      Content: "Detailed",
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
      templateCustomDetails: typeof exportObj != "undefined" ? exportObj.templateCustomDetails :{},
      doExport: exportObj && (exportObj.doExport || 0) ? 1 : 0,
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async exportQuoteBySelect(quoteIds: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders:headersarr[] = []): Promise<Quote.Root> {
    var reqData = this.prepareFilteredData(filters, sort, viewType);
    reqData["selectedID"] = [];
    reqData["FunctionName"] = "List";
    reqData["ExportType"] = "HTML";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;

    // var params: any = {
    //   controller: "Quotes",
    //   FunctionName: "List",
    //   selectedID: [],
    //   selectedAll: selectedAll,
    //   excludedIDs: excludedIDs
    // }

    if (quoteIds.length > 0) {
      reqData["selectedID"] = quoteIds;
    } else {
      reqData["getAll"] = 1;
    }
    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    return response.data
  }

  async exportListsPDFFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders:headersarr[] = []): Promise<string> {
    var reqData = this.prepareFilteredData(filters, sort, viewType);
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "PDF";
    reqData["FunctionName"] = "Export";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportListsCSVFileUrl(ids: string[], selectedAll = false, excludedIDs: any, filters: any, sort: any, viewType: any, selectedView = false, selectedHeaders: any = []): Promise<string> {
    var reqData = this.prepareFilteredData(filters, sort, viewType);
    reqData["selectedID"] = ids;
    reqData["ExportType"] = "CSV";
    reqData["FunctionName"] = "Export";
    reqData["selectedAll"] = selectedAll;
    reqData["excludedIDs"] = excludedIDs;
    reqData["selectedView"] = selectedView;
    reqData["selectedHeadersList"] = selectedHeaders;

    const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsPDFFileUrl(quoteId: string, exportObj: onExport): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      Content: "Detailed",
      ExportType: "PDF",
      FunctionName: "ExportView",
      displayOnExport: exportObj.showOnExport,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      Aztempname: exportObj.subTempName,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      ObjID: quoteId,
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {}),
      priceRolledUp: exportObj && exportObj.advanced && exportObj.advanced.priceRolledUp ? 1 : 0,
      templateCustomDetails: (typeof exportObj != "undefined" && typeof exportObj.templateCustomDetails != "undefined" ? exportObj.templateCustomDetails : {})
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async exportDetailsCSVFileUrl(quoteId: string, exportObj: onExport): Promise<string> {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Quotes",
      Content: "Detailed",
      ExportType: "CSV",
      FunctionName: "ExportView",
      displayOnExport: exportObj.showOnExport,
      saveAsDefault: exportObj.saveAsDefault,
      SPECIALPRICINGID: exportObj.pricingid,
      exportTemplate: exportObj.templateid,
      Aztempname: exportObj.subTempName,
      hideIncludeItems: exportObj && exportObj.advanced && exportObj.advanced.hideIncludeItems ? exportObj.advanced.hideIncludeItems : false,
      hidePriceZero: exportObj && exportObj.advanced && exportObj.advanced.hideZeroPrice ? exportObj.advanced.hideZeroPrice : false,
      ObjID: quoteId,
      customMessages: (typeof exportObj != "undefined" && typeof exportObj.customMessages != "undefined" ? exportObj.customMessages : {})
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if (!response.data.S3URL) {
      throw new Error('Exported file not found')
    }

    return response.data.S3URL
  }

  async removeByIds(quoteIds: string[], selectedAll = false, excludedIDs: any, aID = 0): Promise<number> {
    let successCount = 0

    //for (const quoteID of quoteIds) {
    const reqBody = {
      Controller: 'quotes',
      FunctionName: 'Delete',
      //quoteID,
      deleteQuote: 'true',
      selectedAll: selectedAll,
      excludedIDs: excludedIDs,
      selectedIDs: quoteIds.join(",")
    };
    if(aID > 0) {
      reqBody["customerAID"] = aID;
    }

    const response = await axios.post(dataURL + '?ReturnType=JSON', reqBody)
    if (response.data.STATUS !== 1) {
      if (response.data.ERROR || response.data.STATUSMESSAGE) {
        notifier.alert(response.data.ERROR || response.data.STATUSMESSAGE);
        return 0;
      }
      //continue
    }

    //successCount++
    //}

    //return successCount
    return quoteIds.length;
  }

  prepareFilteredData(filters: any, sort: any, view: string = 'CurrentUsers', initialLoad: boolean = false) {
    var ret = {
      "controller": "Quotes",
      "search": filters.searchAll,
      "sQuote": filters.searchId,
      "sQuoteName": filters.searchName,
      "view": view,
      "sAccountName": filters.searchAccountName,
      "sAName": filters.searchAName || "",
      "selectedResellers": filters.resellerCheckList,
      "systems": initialLoad && filters.SSURL.length == 0 ? 'all' : filters.SSURL.join(",") || '-1',
      "qOpenStatus": (filters.qOpenStatus.length == 1 && filters.qOpenStatus[0] == '6' ? '6' : filters.qOpenStatus.join(",")),
      "eDate": filters.eDate,
      "sDate": filters.sDate,
      "selectedTagIDs": filters.AccountTagID.join(","),
      "tagIDs": filters.AccountTagID.join(","),
      "selectedAccountDs": filters.selectedAccs.join(","),
      "sDeptName": filters.deptName
    }

    if (typeof filters.sGrandTotalMin !== 'undefined') {
      ret['sGrandTotalMin'] = filters.sGrandTotalMin
    }
    if (typeof filters.sGrandTotalMax !== 'undefined') {
      ret['sGrandTotalMax'] = filters.sGrandTotalMax
    }
    if (typeof filters.sProfitMin !== 'undefined') {
      ret['sProfitMin'] = filters.sProfitMin
    }
    if (typeof filters.sProfitMax !== 'undefined') {
      ret['sProfitMax'] = filters.sProfitMax
    }
    if (typeof filters.sTotalMin !== 'undefined') {
      ret['sTotalMin'] = filters.sTotalMin
    }
    if (typeof filters.sTotalMax !== 'undefined') {
      ret['sTotalMax'] = filters.sTotalMax
    }
    if (filters.sStatus.length > 0) {
      ret['status'] = filters.sStatus.join(',')
    }
    if (filters.HpQuote.length > 0) {
      ret['hpQuoteId'] = filters.HpQuote;
    }
    
    ret['source'] = "";
    if (filters.sSource.length > 0) {
      ret['source'] = filters.sSource.join(',')
    }
    else if (!initialLoad && filters.sSource.length == 0) {
      ret['source'] = sessionStorage.getItem('userRole') === 'Customer' ? "" : "-1";
    }
    ret['businessLine'] = "";
    if (sessionStorage.getItem('userRole') == 'Reseller' && typeof filters.businessLineId != 'undefined') {
      if (filters.businessLineId.length > 0) {
        ret['businessLine'] = filters.businessLineId.join(',')
      }
      else if (!initialLoad && filters.businessLineId.length == 0) {
        ret['businessLine'] = "-1";
      }
    }
    ret['quoteCreator'] = "";
    if ((sessionStorage.getItem('userRole') === 'Customer' && typeof filters.quoteCreator != 'undefined')) {
      if (filters.quoteCreator.length > 0) {
        ret['quoteCreator'] = filters.quoteCreator;
      }
    }
    if (sort.field) {
      ret["order"] = sort.field
      ret["direction"] = sort.direction[sort.field].toString()
    }
    ret["gTagIDs"] = (typeof filters["gTagIDs"] != "undefined" ? filters["gTagIDs"].join(",") : "");
    ret["groupIDs"] = (typeof filters["groupIDs"] != "undefined" ? filters["groupIDs"].join(",") : "");

    if (sessionStorage.getItem('userRole') == 'Reseller' && (filters.varUserIds || []).length) {
      ret["varUserIds"] = filters.varUserIds.join(",");
    }

    return ret;
  }
}
