import { render, staticRenderFns } from "./distributor.vue?vue&type=template&id=092b4901&scoped=true"
import script from "./distributor.vue?vue&type=script&lang=tsx"
export * from "./distributor.vue?vue&type=script&lang=tsx"
import style0 from "./distributor.vue?vue&type=style&index=0&id=092b4901&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092b4901",
  null
  
)

export default component.exports