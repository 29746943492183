
























































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { getTimezoneInfo } from "@/helpers/ApiHelper";
import axios from 'axios'

import Datepicker from '../components/Datepicker.vue'
import LaddaButton from '../components/LaddaButton.vue'
import {notifier, wait} from '../models/common'

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Datepicker,
    LaddaButton,
  },
})
export default class StatusCreate extends TSXComponent<void> {
  saving: boolean | 'error' = false
  statusIsPaid = false;
  StatusDetails: any
  $parent: any;
  sources:{
    VARSOURCE_ID:number,
    VARSOURCE_NAME:string
  }[] =[];

  $refs!: {
    modal: HTMLDivElement;
  };
  isStatusEdit = false;

  @Prop({ required: true,default: false })
  isConfirmed!: boolean;

  @Prop({ required: false, default: 0 })
  orderStatus?: number;

  @Prop({ required: false, default: {} })
  notesData?: any

  minDate: string = "";

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $('.datepicker-container.datepicker-dropdown.datepicker-top-left').addClass('hide');
    $(this.$refs.modal).modal("hide");
  }
  async created(){
    const timeInfo = await getTimezoneInfo();
    // Convert the time zone offset to the format ±hhmm 
    this.StatusDetails.date = timeInfo.formattedDate;
    this.minDate = timeInfo.formattedDate;
    this.StatusDetails.type = '';

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      FunctionName: "List",
      controller: "VARSources",
      subsystem: "VAR360",
      ReturnType: "json"
    });
    // default Note Only selected
    this.StatusDetails.type = '12';
    this.sources = response.data.VARSOURCES;
    this.isStatusEdit = Object.keys(this.notesData).length ? true : false;
    if (this.isStatusEdit) {
      if (this.notesData.id) {
        this.StatusDetails.id = this.notesData.id;
      }
      if (this.notesData.name) {
        this.StatusDetails.name = this.notesData.name;
      }
      if (this.notesData.type) {
        this.StatusDetails.type = this.notesData.type;
      }
      if (this.notesData.status) {
        this.StatusDetails.status = this.notesData.status;
      }
      if (this.notesData.date) {
        this.StatusDetails.date = this.notesData.date;
      }
    }
  }
  data() {    
      //get margin in number
      var tmpMargin = this.$parent.details.MARGIN;
      if(isNaN(tmpMargin)){
        tmpMargin = tmpMargin.replace(/[$,]/g, "");
        if(tmpMargin.indexOf("(") != -1 && tmpMargin.indexOf(")") != -1) {
          tmpMargin = "-" + tmpMargin.replace(/[()]/g, "");
        }
      }

      return {
        StatusDetails: {
          name: "",
          type: this.orderStatus ==7 ? 14 : 12,
          status: 0,
          date: "",
          profit: tmpMargin
        }
      }
  }

  async validateBeforeSubmit(e) {
      this.$validator.validateAll().then((result) => {
          if (result) {
              e.preventDefault();
                  return this.submit();
          }
          else {
              e.preventDefault();
              e.stopPropagation();
          }
      });
  }

  async submit() {  
    if (this.StatusDetails.name.length > htmlParse(this.StatusDetails.name).length) {
      notifier.alert("HTML content found. This content has been removed!");
      this.StatusDetails.name = htmlParse(this.StatusDetails.name);
    }

    if(!this.StatusDetails.name.trim().length){
      return false;
    }
    const statusDetails = this.StatusDetails
    
    try {
      this.$emit("submit", statusDetails);
    } catch (err) {
      this.saving = 'error'
      // console.log(err);
      notifier.alert("Create status error: " + err.message);
    }
  }

  async checkHTML() {
    // if (this.StatusDetails.name.length > htmlParse(this.StatusDetails.name).length) {
    //   notifier.alert("HTML content found. This content has been removed!");
    //   this.StatusDetails.name = htmlParse(this.StatusDetails.name);
    // }
  }
  checkStatus(event) {
    if(this.StatusDetails.type == 5){
      this.statusIsPaid = true;
      // $(".paidCheck").removeClass("displayNone");
      this.$parent.confirmStatusPaidVisible = true;
      this.$emit("close");
    }
    else{
      this.statusIsPaid = false;

      if (this.StatusDetails.type == 12) {
        this.StatusDetails.date = this.getEarlierDate(this.minDate, this.StatusDetails.date);
      }
      // $(".paidCheck").addClass("displayNone");
    }
  }

  getEarlierDate(date1, date2) {
    // Split the date strings into parts
    const [month1, day1, year1] = date1.split('/');
    const [month2, day2, year2] = date2.split('/');
    
    // Create new Date objects
    const parsedDate1 = new Date(year1, month1 - 1, day1);
    const parsedDate2 = new Date(year2, month2 - 1, day2);
    
    // Compare the dates and return the earlier one
    return parsedDate1 < parsedDate2 ? date1 : date2;
  }
}
