import axios from "axios";

import {User} from '../models/User';
import router from '../router'

declare const dataURL: string;
declare const getMaxRows: Function;
declare const $: any;

export enum UserActiveStatus {
  Active = 1,
  InActive = 0
}

export enum UserLevel {
  Admin = 1,
  General = 2,
  Support = 3
}

export type Filters = {
  sStatus: UserActiveStatus[],
  sLevels?: UserLevel[],
  searchAll? : string,
  searchName? : string,
  searchId? :string,
  statusName: (string | undefined)[]
}

export enum SortField {
  Id = 1,
  Name = 2,
  Percent = 3,
}

export type Sort = {
  field: SortField | null
  direction: {
    [SortField.Id]: 1 | 2,
    [SortField.Name]: 1 | 2,
    [SortField.Percent]: 1 | 2,
  }
}

export type UserFilters = {
  sStatus: UserActiveStatus[],
  sLevels?: UserLevel[],
  selectedSecurityGroups?: any[],
  searchAll?: string,
  searchName?: string,
  searchId?: string,
  statusName: (string | undefined)[],
  sDate: string;
  eDate: string;
  sLoginDate: string;
  eLoginDate: string;
  searchEmail: string;
  searchUserID: string;
}

export enum UserSortField {
  Name = 2,
  Email = 3,
  Date = 4,
  LoginDate = 5,
  Status = 6,
}

export type UserSort = {
  field: UserSortField | null
  direction: {
    [UserSortField.Name]: 1 | 2,
    [UserSortField.Email]: 1 | 2,
    [UserSortField.Date]: 1 | 2,
    [UserSortField.LoginDate]: 1 | 2,
    [UserSortField.Status]: 1 | 2,
  }
}

export class UserAPIRepo {

  async updateStatus(params: any) {
    const paramsQuery = {
            controller: "varUSers",
            FunctionName: "Update",
            subsystem: "VAR360",
            userid: params.selectedID,
            active: params.status,
            accountid: params.accountid
        };
    const response = await axios.post(dataURL + "?ReturnType=JSON", paramsQuery);

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    //return data
    return response.data;
  }

  async deleteUser(params: any) {
      const paramsQuery = {
            controller: "varUSers",
            FunctionName: "Delete",
            subsystem: "VAR360",
            userid: params.selectedID
        };
      const response = await axios.post(dataURL + "?ReturnType=JSON", paramsQuery);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      //return data
      return response.data;
  }

  async getAllowedFunctions() {
    var userID: any = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 0;
    var ret: {
      allowedFunctions: number[],
      systemGroups: string[],
      tabOptions: any[],
      customerPortalLogo: string,
      secondaryColor: string,
      primaryColor: string,
      isCustomer: boolean,
      isHideReseller: boolean,
      hideEmployeePlanNumbers: boolean,
      pinEmployeePlanStats: boolean,
      employeePlanIds: string,
      dueDateAlerts: boolean,
    } = {
      allowedFunctions: [],
      systemGroups: [],
      customerPortalLogo: '',
      primaryColor: '',
      secondaryColor: '',
      tabOptions: [],
      isCustomer: false,
      isHideReseller: false,
      hideEmployeePlanNumbers: false,
      pinEmployeePlanStats: false,
      employeePlanIds: '',
      dueDateAlerts: false,
    };
    if(userID > 0) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller          : "varUSers",
        FunctionName        : "View",
        subsystem           : "VAR360",
        userID              : userID,
        getAllowedFunctions : 1
      });
  
      if (response.data.ERROR) {
        // console.error('ERROR', response.data.ERROR);
        return false
      }
      if (response.data.STATUS !== 1) {
        console.error('STATUS', response.data.STATUSMESSAGE);
        return false
      }

      if(typeof response.data.ALLOWEDFUNCTIONS != "undefined") {
        $.each(response.data.ALLOWEDFUNCTIONS, function(i, val) {
          ret.allowedFunctions.push(parseInt(val));
        });
      }

      if(typeof response.data.SYSTEMGROUPS != "undefined") {
        $.each(response.data.SYSTEMGROUPS, function(i, val) {
          ret.systemGroups.push(val);
        });
      }

      try {
        const EMPLOYEEPLAN = JSON.parse(localStorage.getItem('EMPLOYEEPLAN') || '{}') || {};
        const HIDEEMPLOYEEPLANNUMBERS = !!EMPLOYEEPLAN.HIDEEMPLOYEEPLANNUMBERS || false;
        const PINEMPLOYEEPLANSTATS = !!EMPLOYEEPLAN.PINEMPLOYEEPLANSTATS || false;
        const EMPLOYEEPLANIDS = EMPLOYEEPLAN.EMPLOYEEPLANIDS || '';

        ret.pinEmployeePlanStats = PINEMPLOYEEPLANSTATS;
        ret.hideEmployeePlanNumbers = HIDEEMPLOYEEPLANNUMBERS;
        ret.employeePlanIds = EMPLOYEEPLANIDS;

        const ALERTCONFIG = JSON.parse(localStorage.getItem('ALERTCONFIG') || '{}') || {};
        const DUEDATEALERTS = !!ALERTCONFIG.DUEDATEALERTS || false;

        ret.dueDateAlerts = DUEDATEALERTS;
      } catch (e) {
        console.log('EMPLOYEEPLAN.e', e);
      }

      if(typeof response.data.SYSTEM != "undefined" && response.data.SYSTEM == 2) {
        ret.isCustomer = true;
        try {
          const PORTALINFO = JSON.parse(localStorage.getItem('PORTALINFO') || '{}') || {};
          const TABOPTIONS = JSON.parse(PORTALINFO.TABOPTIONS) || [];
          const CUSTOMERPORTALLOGO = PORTALINFO.CUSTOMERPORTALLOGO || '';
          const PRIMARYCOLOR = PORTALINFO.PRIMARYCOLOR || '';
          const SECONDARYCOLOR = PORTALINFO.SECONDARYCOLOR || '';
          ret.tabOptions = TABOPTIONS;
          ret.customerPortalLogo = CUSTOMERPORTALLOGO;
          ret.primaryColor = PRIMARYCOLOR;
          ret.secondaryColor = SECONDARYCOLOR;
        } catch (e) {
          console.log('TABOPTIONS.e', e);
          // ignore
        }

      }

      if(typeof response.data.NOMULTIRESELLERACCESS != "undefined" && parseInt(response.data.NOMULTIRESELLERACCESS)) {
        ret.isHideReseller = true;
      }
    }
    
    return ret;
  }

  async checkAccessData(to) {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller          : "varUSers",
      FunctionName        : "checkHasAccess",
      subsystem           : "VAR360",
      routeName           : to.name,
      params              : JSON.stringify(to.params),
      query               : JSON.stringify(to.query)
    });

    if (response.data.ERROR) {
      console.error('ERROR', response.data.ERROR);
      return false
    }
    if (response.data.STATUS !== 1) {
      console.error('STATUS', response.data.STATUSMESSAGE);
      return false
    }

    return response.data.checkAccessData;
  }

  async getResellers() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      "controller"    : "varUSers",
      "FunctionName"  : "View",
      "subsystem"   	: "VAR360",
      "getResellers"  : 1
    });

    if (response.data.ERROR) {
      console.error('ERROR', response.data.ERROR);
      return false
    }
    if (response.data.STATUS !== 1) {
      console.error('STATUS', response.data.STATUSMESSAGE);
      return false
    }

    return response.data;
  }  
}

