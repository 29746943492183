

































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import PhoneInput from "../components/PhoneInput.vue";
import LaddaButton from "../components/LaddaButton.vue";
import { notifier } from "../models/common";
import DelayRemove from "./DelayRemove.vue";
import ConfirmModalBox from "@/components/ConfirmRemoveItemModal.vue";

interface Events {
  addrow: { index; pos };
  checkEmail: { index; pos };
  initialLoad: { index };
  addDeptRow: { index };
}
interface contactObj {
  NAME?: string;
  MAIL?: string;
  PHONE?: string;
  ID?: number;
  TYPE?: string;
  ISDUPLICATE?: number;
  ACONTACTTYPE?: number;
  ACCOUNTCONTACTID?: number;
  ACONTACTPHONEEXT?:string;
  ISDUPLICATECONTACT?:number;
}
interface departmentObj {
  NAME?: string;
  ID?: number;
  ISDUPLICATE?: number;
}
interface usersObj {
  USERID: number;
  UFULLNAME?: string;
}
interface securityObj {
  SECURITYGROUPID: number;
  SECURITYGROUPNAME?: string;
}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    PhoneInput,
    LaddaButton,
    DelayRemove,
    ConfirmModalBox
  }
})
export default class CustomerDetailsTabContacts extends TSXComponent<Events> {
  @Prop({ required: false, default: [] })
  contactItems!: contactObj[];

  @Prop({ required: false, default: [] })
  departmentItems!: departmentObj[];
  saving: boolean | string = false;
  isActive:string = "contact";

  @Prop({ required: true })
  selectedusers!: usersObj[];

  @Prop({ required: true })
  active!: boolean;

  @Prop({ required: true })
  selectedgroups!: securityObj[];

  @Prop({ required: true })
  alias!: string;

  @Prop({ required: true })
  accname!: string;

  @Prop({ required: true })
  accid!: string;

  @Prop({ required: true })
  aid!: number;

  showContactDelayRemove= -1;
  showDepartmentDelayRemove= -1;
  contactEdited = false;
  departmentEdited = false;
  confirmedDuplicateContactVisible = false;
  duplicateContactItems: any = {};
  isFirstLoad = true;
  async mounted() {
    const result = await this.$validator.validateAll();
    setTimeout(() => {
      this.isFirstLoad = false;
    }, 100);
  }

  async validateBeforeSubmit(e) {
    $("#customerContacts input.initial.error").removeClass("initial");
    e.preventDefault();
    e.stopPropagation();
    const result = await this.$validator.validateAll();
    // this.$validator.validateAll().then(async result => {
    if (!result && (this.isActive != "department" || this.departmentItems.length == 1)) {
      return;
    }
    if ((!this.contactEdited && this.isActive === 'contact') || (!this.departmentEdited && this.isActive === 'department')) {
      return;
    }
    if (this.isActive == "department") {
      await this.saveDepartment();
    } else {
      await this.saveContact();
    }
    // });
  }

  async saveContact() {
    var comInstance = this;
    var userList: number[] = [];
    var isMissingInfo = 0;
    var isDuplicateExist = 0;
    var isDuplicateContactExist = 0;
    this.contactEdited = false;

    var DuplicateEmailExist = this.contactItems.find(o => o.ISDUPLICATE === 1);
    if (DuplicateEmailExist) {
      isMissingInfo = 1;
      isDuplicateExist = 1;
    }
    if (isMissingInfo) {
      if (isDuplicateExist) {
        notifier.alert("Duplicate Emails are found!");
      } else if (isDuplicateContactExist) {
        notifier.alert("Duplicate Contacts are found!");
      } else {
        notifier.alert("Please fill the Contact Info!");
      }
    } else {
      comInstance.saving = true;
      $.each(comInstance.selectedusers, function(i, val) {
        userList.push(val.USERID);
      });

      //detect selected security groups
      var sgList: number[] = [];
      $.each(comInstance.selectedgroups, function(i, val) {
        sgList.push(val.SECURITYGROUPID);
      });
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "Update",
        aID: comInstance.aid,
        contactsList: comInstance.contactItems
      });
      // return getRouteData({

      // }).then(function(response, statusText, jqXHR) {
      if (response.data.ERROR) {
        // console.log(response.data.ERROR);
      }
      if (response.data.STATUSSUB == 3) {
        notifier.warning(response.data.MESSAGESUB);
      } else if (response.data.STATUS == 1) {
        // if (
        //   comInstance.$parent.currACCID != comInstance.$parent.currentCustomID
        // ) {
        //   comInstance.$router.push({
        //     name: "ViewCustomer",
        //     params: { id: comInstance.$parent.currACCID }
        //   });
        // } else {
        // comInstance.initialLoad(3);
        comInstance.$emit("initialLoad", 3);
        // }
        comInstance.saving = false;
        notifier.success(response.data.MESSAGE);
        // router.push({ name: "Customers" });//go to customer list // As per DJ commennt to stay in same page.
      } else if (response.data.STATUS == 0) {
        comInstance.saving = "error";
        if (
          typeof response.data.ACCOUNTID_EXISTED != "undefined" &&
          response.data.ACCOUNTID_EXISTED
        ) {
          // console.log("Error", response.data);
        }
      }
      // });
    }
  }

  async saveDepartment() {
    var comInstance = this;
    var isMissingInfo = 0;
    var isDuplicateExist = 0;
    this.departmentEdited = false;

    var DuplicateEmailExist = comInstance.departmentItems.find(o => o.ISDUPLICATE === 1);
    if (DuplicateEmailExist) {
      isMissingInfo = 1;
      isDuplicateExist = 1;
    }
    if (isMissingInfo) {
      if (isDuplicateExist) {
        notifier.alert("Duplicate Names are found!");
      } else {
        notifier.alert("Please fill the Department Info!");
      }
    } else {
      comInstance.saving = true;

      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Contacts",
          FunctionName: "UpdateDepartment",
          aID: comInstance.aid,
          departmentsList: comInstance.departmentItems
        });

        if (response.data.ERROR) {
          throw new Error(response.data.ERROR);
        }
        if (response.data.STATUS !== 1) {
          throw new Error(response.data.STATUSMESSAGE);
        }

        if (response.data.STATUS == 1) {
          comInstance.$emit("initialLoad", 3);
          comInstance.saving = false;
          notifier.success(response.data.STATUSMESSAGE);
        }

      }catch (err) {
        // console.log(err.message);
      }finally {
        comInstance.saving = false;
      }
    }
  }

  updateDefaultContact(index, type) {
    $.each(this.contactItems, function(i, val) {
      if (i == index) {
        val.ISDEFAULT = 1;
      } else if((type == val.ACONTACTTYPE || val.ACONTACTTYPE == 3 || type == 3) && val.ACONTACTTYPE != 2 ) {
        val.ISDEFAULT = 0;
      }
    });
  }

  showContactDelay(index) {
    this.showContactDelayRemove = index;
  }

  showDepartmentDelay(index) {
    this.showDepartmentDelayRemove = index;
  }

  async removeItem(index, from) {
    this.showContactDelayRemove = -1;
    var reqBody = {
      FunctionName: "Delete"
    };
    var isDelete = 0;

    $(".EmailCheck").text("");
    if (this.contactItems[index].ID) {
      reqBody["Controller"] = "Contacts";
      reqBody["accountcontactID"] = this.contactItems[index].ACCOUNTCONTACTID;
      isDelete = 1;
    }
    this.contactItems.splice(index, 1);
    if (isDelete) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.MESSAGE);
      }
      if (response.data.STATUS == 1) {
        notifier.success(response.data.MESSAGE);
        this.isFormChanged(this.contactItems);
      }
    }
  }
  countryChanged(obj,pos){
    if (typeof obj.countryCode != 'undefined' && typeof obj.countryCallingCode != 'undefined') {
      this.contactItems[pos].ACONTACTPHONEEXT = obj.countryCode+' '+'+'+obj.countryCallingCode;
    }
  }
  isContactsRequired(i) {
    if (
      this.contactItems[i].NAME != "" &&
      (
        (
          this.contactItems[i].MAIL != "" &&
          this.contactItems[i].PHONE != ""
        )
        || [2,3].includes(parseInt(`${this.contactItems[i].ACONTACTTYPE}`))
      ) &&
      this.contactItems[i].TYPE != "" &&
      this.isActive == "contact"
    ) {
      return "required";
    }
    return "";
  }
  getExt(e){
    let ext = 'US';
    if(e != undefined){
      ext = e.split(' ')[0]
    }
    if(ext === '+[object') {
      ext = 'US';
    }
    return ext;
  }
  async checkName(name, pos) {
    var filteredAddress = this.departmentItems.filter((item: any) => name != "" && item.NAME.toLowerCase() == name.toLowerCase());
    if (filteredAddress.length > 1) {
      this.departmentItems[pos].ISDUPLICATE = 1;
      $(".errorMsg#NameCheck_" + pos).addClass("errorName");
      $(".errorMsg#NameCheck_" + pos).text("The Name Exists Already");
    }
    else {
      this.departmentItems[pos].ISDUPLICATE = 0;
      $(".errorMsg#NameCheck_" + pos).removeClass("errorName error");
      $(".errorMsg#NameCheck_" + pos).text("");
    }
  }
  checkContact(name, checkNew, contactItem) {
    if (!checkNew) {
      let filteredContact = this.contactItems.filter((item: any) => name.length && item.NAME.toLowerCase() == name.toLowerCase());
      if (filteredContact.length > 1) {
        this.confirmedDuplicateContactVisible = true;
        this.duplicateContactItems = contactItem;
      } else {
        this.confirmedDuplicateContactVisible = false;
      }
    }
  }
  addDuplicateContact() {
    this.duplicateContactItems.ISDUPLICATECONTACT = 1;
    this.confirmedDuplicateContactVisible = false;
  }
  removeDuplicateContact() {
    if (!this.duplicateContactItems.ISDUPLICATECONTACT) {
      this.duplicateContactItems.NAME = "";
    }
    this.confirmedDuplicateContactVisible = false;
  }
  async removeDeptItem(index) {
    this.showDepartmentDelayRemove = -1;
    this.departmentEdited = false;
    var reqBody = {
      FunctionName: "UpdateDepartment"
    };
    var isDelete = 0;

    if (this.departmentItems[index].ID) {
      reqBody["Controller"] = "Contacts";
      reqBody["accountDepartmentID"] = this.departmentItems[index].ID;
      reqBody["action"] = "delete";
      isDelete = 1;
    }
    this.departmentItems.splice(index, 1);
    if (isDelete) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqBody);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
      }
    }
  }

  async isFormChanged(items) {
    if(this.isActive === 'contact') {
      const result = await this.$validator.validateAll();
      for (const item of items) {
        let typeName = '';
        switch (parseInt(item.ACONTACTTYPE)) {
          case 1:
            typeName = 'End User';
            break;
          case 2:
            typeName = 'Invoices';
            break;
          case 3:
            typeName = 'All';
            break;
        }
        if (
          (item.ACONTACTNAME != item.NAME && (item.ACONTACTNAME != undefined || item.NAME.length) && result) ||
          (item.ACONTACTEMAIL != item.MAIL && (item.ACONTACTEMAIL != undefined || item.MAIL.length) && result) ||
          (item.ACONTACTPHONE != item.PHONE && (item.ACONTACTPHONE != undefined || item.PHONE.length) && result) ||
          (item.TYPE != typeName && item.ACONTACTTYPE != undefined && result) ||
          (item.ACCOUNTDEFAULT != item.ISDEFAULT && item.NAME.length && result)
        ) {
          this.contactEdited = true;
          item.ISDUPLICATECONTACT = 0;
          break;
        } else {
          this.contactEdited = false;
        }
      }
    } else if (this.isActive === 'department') {
      for (const item of items) {
        if (item.ADEPARTMENTNAME != item.NAME && (item.ADEPARTMENTNAME != undefined || item.NAME.length)) {
          this.departmentEdited = true;
          break;
        } else {
          this.departmentEdited = false;
        }
      }
    }
  }

  changesInvDefault(contact) {
    contact.ISDEFAULT = !contact.ISDEFAULT;
  }
}
