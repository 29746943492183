


















import {Component as TSXComponent} from "vue-tsx-support";
import {Component} from "vue-property-decorator";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {}
})

export default class Catalogs extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $userFunctions: any;
  loading = false;
  message = "";
  uploading: any = false;
  pageNumber = 1;
  details: any = {};
  contracts: any = [];
  currentRole = sessionStorage.getItem("userRole");

  async created() {

  }
}
