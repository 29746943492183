





















































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import DropdownControl from "@/components/DropdownControl.vue";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: { DropdownControl }
})
export default class TemplateSettings extends TSXComponent<Props, Events> {
  $parent: any;
  templateParamNamesData: any = [];
  defaultStoreTemplate = "";
  quoteFormatTemplate = "";
  mailerTemplate = "";

  async created() {
    await this.$parent.getSavedSettings();
    const saved = this.$parent.savedSettings;
    this.templateParamNamesData = saved.templateParamNamesData || [];
    for (const item of this.templateParamNamesData) {
      const selected = (item.templateOptionSelectedData || []).length
        ? item.templateOptionSelectedData[0].OLMS_ACCOUNTTEMPLATEPARAM_VALUE
        : item.currentTemplateOptionData[0].OLMS_TEMPLATEOPTION_VALUE;
      this.$set(item, "selected", selected);
    }
    this.defaultStoreTemplate = saved.CurrentTemplateID || "";
    this.quoteFormatTemplate = saved.quoteFormatTemplate || "";
    this.mailerTemplate = saved.mailerTemplate || "";
  }

  collectData() {
    // collect template setting options
    const templateSettingOptions = {};
    for (const item of this.templateParamNamesData) {
      templateSettingOptions[
        `templateSettingOption${item.OLMS_TEMPLATEPARAM_ID}`
      ] = `${item.selected}`;
    }

    return {
      status: 1,
      data: {
        defaultStoreTemplate: this.defaultStoreTemplate,
        ...templateSettingOptions,
        quoteFormatTemplate: this.quoteFormatTemplate,
        mailerTemplate: this.mailerTemplate
      }
    };
  }
}
