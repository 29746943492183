




















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class _2faf extends TSXComponent<Props, Events> {
  $parent: any;
  enable2FAF = false;

  async created() {
    await this.$parent.getSavedSettings();
    this.enable2FAF = this.$parent.savedSettings.enable2FAF || 0 ? true : false;
  }

  collectData() {
    return {
      status: 1,
      data: {
        ACT: "Update2FAF",
        enable2FAF: this.enable2FAF ? 1 : 0
      }
    };
  }
}
