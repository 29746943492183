














































































































































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import axios from "axios";

import LaddaButton from "../components/LaddaButton.vue";
import { notifier, wait } from "../models/common";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import QuantityReductionModal from "@/components/Order/QuantityReductionModal.vue";
import { getEUAddress } from "@/helpers/ApiHelper";

declare const dataURL: string;
declare const $: any;
declare const LoadData: any;

interface Props {
  purchaseID: number;
  isMultiEndUsers: boolean;
  splitBySubOrderId: any;
}

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    ConfirmRemoveItemModal,
    QuantityReductionModal
  },
  methods: {
    getEUAddress
  }
})
export default class CancelOrderModal extends TSXComponent<void> {
  @Prop({ required: true, default: 0 })
  purchaseID?: number;

  @Prop({ required: false, default: 0 })
  aID?: number;

  @Prop({ required: false, default: {} })
  lineItems!: any;

  @Prop({ required: false, default: {} })
  details?: any;

  @Prop({ required: false, default: false })
  isMultiEndUsers?: boolean;

  @Prop({ required: false, default: [] })
  splitBySubOrderId?: any;

  @Prop({ required: false, default: [] })
  hardwareItems?: any;

  saving: boolean | "error" = false;
  loading: boolean | "error" = false;
  isRemoving: boolean | "error" = false;
  isConfirm: boolean | "error" = false;
  reasonCancel = "";
  confirmCancellation = false;
  cancelReturnId = 0;
  cancelReturnStatus = 0;
  cancelReturnProduct = "";
  cancelReturnSku = "";
  cancelReturnMessage = "";
  $parent: any;
  checkAllCancel = false;
  checkAllReturn = false;
  confirmOrderCancelVisible = false;
  showQuantityReductionModal = false;

  $refs!: {
    modal: HTMLDivElement;
  };

  confirmOrderRemove = false;
  isDialogBox = true;
  disableButton = true;
  selectedLineItems: object[] = [];
  orderLineItems: object[] = [];
  selectedLineItemHardwares: object[] = [];
  deletedHardwareItems: object[] = [];

  created() {
    let tmpSubOrderId = 0;
    const subOrders = this.splitBySubOrderId || [];
    this.orderLineItems = this.lineItems.map((row, index) => {
      const subOrderId = row.SUBORDERID || 0;
      let euInfo = null;
      if (tmpSubOrderId != subOrderId) {
        const findSubOrder = subOrders.find(
          item => item.subOrderId == subOrderId
        );
        if (findSubOrder) {
          euInfo = findSubOrder.euInfo || {};
        }
      }
      tmpSubOrderId = subOrderId;
      return { ...row, id: index, euInfo };
    });
    this.disableCancelRemoveBtn();
    this.uncheckAllProduct();
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async validateBeforeSubmit(e) {
    this.$validator.validateAll().then(result => {
      if (result) {
        this.confirmOrderCancelVisible = true;
        // return this.submit();
      }
    });
  }

  async submit(options: any) {
    if (this.reasonCancel != "") {
      var removedHTMLContent = htmlParse(this.reasonCancel);
      if (this.reasonCancel.length > removedHTMLContent.length) {
        notifier.alert("HTML content found. This content has been removed!");
        this.reasonCancel = removedHTMLContent;
      }
    }

    try {
      this.saving = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "orders",
        FunctionName: "orderStatus",
        Id: this.purchaseID,
        type: 7,
        source: 0,
        name: "Order Cancellation",
        Status_Details: this.reasonCancel,
        deleteHardwareLines: options.customCheckboxVal || false
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      this.saving = false;
      await wait(1000);

      $(this.$refs.modal).modal("hide");
      this.$emit("submit");
    } catch (err) {
      this.saving = "error";
      notifier.alert(err.message);
    }
  }

  cancelLineItems(selectedLineItems) {
    this.cancelReturnMessage = "Cancel/Return";
    selectedLineItems.forEach(undo => {
      if (undo.undoCanceledItems == true) {
        this.cancelReturnMessage = "undo the Cancel/Return";
      }
    });
    var productNames = [];
    productNames = selectedLineItems.map(product => {
      return `<strong>${product.POPRODPARTIALQUANTITY}</strong> quantity of the ${product.PRODUCTNAME}`;
    });
    this.cancelReturnProduct = productNames.join(", ");
    this.confirmCancellation = true;
  }

  cancelProduct(
    poID,
    status,
    productName,
    skuName,
    StatusID,
    checkAll = false,
    poProdPartialQuantity,
    poProdQuantity,
    data
  ) {
    var index = this.selectedLineItems.length
      ? this.selectedLineItems.findIndex(function(el) {
          return el["POLIID"] == poID;
        })
      : -1;
    if (index != -1) {
      this.selectedLineItems.splice(index, 1);
    }
    var selectedItems = {};
    selectedItems["POLIID"] = poID;
    selectedItems["POPRODPARTIALQUANTITY"] = poProdPartialQuantity;
    selectedItems["POPRODDESC"] = productName;
    selectedItems["POPRODSKU"] = skuName;
    selectedItems["POPRODQUANTITY"] = data.POPRODQUANTITY_BK || poProdQuantity;
    selectedItems["NEWPOPRODQUANTITY"] = poProdQuantity;
    selectedItems["CANCELLEDRETURNEDPRODUCTGRANDPARENTIDS"] = data.CANCELLEDRETURNEDPRODUCTGRANDPARENTIDS || poID;
    if (status == 1) {
      this.orderLineItems.filter(function(el: any) {
        if (el.POLIID == poID && !el.CANCELLED) {
          el["CANCELLED"] = true;
          el["RETURNED"] = false;
          selectedItems["STATUSID"] = 1;
        } else if (el.POLIID == poID && el.CANCELLED) {
          el["CANCELLED"] = false;
        }
        if (el.POLIID == poID && !el.CANCELLED && !el.RETURNED) {
          selectedItems["STATUSID"] = 0;
        }
        selectedItems["CANCELLED"] = el.CANCELLED;
        return;
      });
    } else if (status == 2) {
      this.orderLineItems.filter(function(el: any) {
        if (el.POLIID == poID && !el.RETURNED) {
          el["RETURNED"] = true;
          el["CANCELLED"] = false;
          selectedItems["STATUSID"] = 2;
        } else if (el.POLIID == poID && el.RETURNED) {
          el["RETURNED"] = false;
        }
        if (el.POLIID == poID && !el.CANCELLED && !el.RETURNED) {
          selectedItems["STATUSID"] = 0;
        }
        selectedItems["RETURNED"] = el.RETURNED;
        return;
      });
    }
    selectedItems["PRODUCTNAME"] = productName + " " + skuName;
    if (StatusID != selectedItems["STATUSID"]) {
      selectedItems["undoCanceledItems"] = false;
      if (StatusID != 0 && selectedItems["STATUSID"] == 0) {
        selectedItems["undoCanceledItems"] = true;
      }
      if (selectedItems["STATUSID"] == 0) {
        selectedItems["POPRODPARTIALQUANTITY"] = poProdQuantity;
      }
      this.selectedLineItems.push(selectedItems);
    }
    this.selectedLineItemHardwares = [];
    let selectedLineItemHardwares = [];
    let checkDuplicateHardwares = [];
    this.selectedLineItems.filter((t: any) => t.STATUSID).map((item: any) => {
      let selectedHardware = this.hardwareItems.find((hItem: any) => hItem.POLIIDS.includes(item.POLIID) || hItem.POLIIDS.includes(item.CANCELLEDRETURNEDPRODUCTGRANDPARENTIDS));
      if (selectedHardware && selectedHardware.hardwares && !checkDuplicateHardwares.includes(selectedHardware.POLIIDS || 0)) {
        selectedLineItemHardwares.push(...selectedHardware.hardwares);
        checkDuplicateHardwares.push(selectedHardware.POLIIDS || 0);
      }
    });
    this.selectedLineItemHardwares = selectedLineItemHardwares;
    this.disableCancelRemoveBtn();
    if (checkAll) {
      this.uncheckAllProduct();
    }
  }

  cancelAllProduct(status) {
    var self = this;
    if (status === 1) {
      this.checkAllCancel = !this.checkAllCancel;
      if (this.checkAllReturn) {
        this.checkAllReturn = false;
      }
      if (Object.keys(this.orderLineItems).length) {
        this.orderLineItems.forEach(function(val: any) {
          val.CANCELLED = 1;
          if (self.checkAllCancel) {
            val.CANCELLED = 0;
          }
          self.cancelProduct(
            val.POLIID,
            1,
            val.POPRODDESC,
            val.POPRODSKU.toUpperCase(),
            val.STATUSID,
            false,
            val.POPRODPARTIALQUANTITY,
            val.POPRODQUANTITY,
            val
          );
        });
      }
    } else {
      this.checkAllReturn = !this.checkAllReturn;
      if (this.checkAllCancel) {
        this.checkAllCancel = false;
      }
      if (Object.keys(this.orderLineItems).length) {
        this.orderLineItems.forEach(function(val: any) {
          val.RETURNED = 1;
          if (self.checkAllReturn) {
            val.RETURNED = 0;
          }
          self.cancelProduct(
            val.POLIID,
            2,
            val.POPRODDESC,
            val.POPRODSKU.toUpperCase(),
            val.STATUSID,
            false,
            val.POPRODPARTIALQUANTITY,
            val.POPRODQUANTITY,
            val
          );
        });
      }
    }
  }

  uncheckAllProduct() {
    const checkedCancelCount = this.orderLineItems.filter(
      (item: any) => item.CANCELLED
    );
    const checkedReturnCount = this.orderLineItems.filter(
      (item: any) => item.RETURNED
    );
    this.checkAllCancel = false;
    this.checkAllReturn = false;
    if (checkedCancelCount.length == this.orderLineItems.length) {
      this.checkAllCancel = true;
    }
    if (checkedReturnCount.length == this.orderLineItems.length) {
      this.checkAllReturn = true;
    }
  }

  disableCancelRemoveBtn() {
    var disableCount = 0;
    this.orderLineItems.forEach((lineItem: any) => {
      if (lineItem.CANCELLED || lineItem.RETURNED) {
        disableCount++;
      }
    });
    if (this.orderLineItems.length == disableCount) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  async cancelReturnOrder() {
    try {
      this.isConfirm = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "orders",
        FunctionName: "cancelproduct",
        Id: this.purchaseID,
        lineItems: this.selectedLineItems,
        deletedHardwareItems: this.deletedHardwareItems,
        accountid: this.aID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }
      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        this.$emit("orderLineItems");
      }
    } catch (err) {
      this.isConfirm = "error";
      notifier.alert(err.message);
    } finally {
      this.isConfirm = false;
      this.$emit("close");
    }
  }

  removeSelected() {
    this.confirmOrderRemove = true;
    this.isDialogBox = false;
  }

  async deleteOrder(options: any) {
    // this.isRemoving = true;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        session: sessionStorage.getItem("sessionID"),
        userRole: sessionStorage.getItem("userRole"),
        Controller: "orders",
        FunctionName: "Delete",
        deletePurchase: "true",
        deletePurchaseFile: "true",
        selectedAll: false,
        excludedIDs: [],
        selectedIDs: this.$route.params.id,
        customerAID: this.aID,
        deleteHardwareLines: options.customCheckboxVal || false
      });

      if (response.data.ERROR) {
        notifier.alert(response.data.ERROR);
        this.loading = "error";
        return false;
      }

      if (response.data.STATUS == 1) {
        this.loading = false;
        this.$router.push({ name: "Orders" });
      }
    } catch (err) {
      this.loading = "error";
    } finally {
      // this.confirmRemoveModalVisible = false;
    }
  }

  async checkHTML() {}

  findEUNameInSS(subOrderId) {
    if (!subOrderId) return "";
    const ssIndex = this.splitBySubOrderId.findIndex(
      item => item.subOrderId == subOrderId
    );
    if (ssIndex == -1) return "";

    return `End User ${ssIndex + 1}`;
  }

  confirmQuantityReduction(data) {
    if (data.selectedLineItems) {
      this.selectedLineItems = data.selectedLineItems;
      this.deletedHardwareItems = (data.deletedHardwareItems || []);
      this.showQuantityReductionModal = false;
      this.cancelLineItems(this.selectedLineItems);
    }
  }
}
