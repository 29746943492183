




















































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

interface Props {
  status: InvoiceStatusValue;
  isConfirmed: boolean;
  orderHasPaymentInfo?: boolean;
  invoiceID?: string;
  purchaseID?: number;
}

interface Events {
  onChangeStatus: InvoiceStatusValue;
}

export enum InvoiceStatusValue {
  Sent = 0,
  Paid = 1,
  Created = 2
}

@Component({
  inheritAttrs: false,
  components: {}
})
export default class SpiffStatusComponent extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  status!: InvoiceStatusValue;

  @Prop({ required: true, default: "open" })
  statustext!: string;

  @Prop({ required: true })
  isConfirmed!: boolean;

  @Prop({ required: false, default: false })
  orderHasPaymentInfo!: boolean;

  @Prop({ required: false, default: "" })
  invoiceID!: string;

  @Prop({ required: false, default: 0 })
  purchaseID!: number;

  @Prop({ required: false, default: false })
  isDeletedAccount!: boolean;

  @Prop({ required: false, default: [
    {
      "Status_ID": 0,
      "StatusName": 'Sent',
      "StatusColor": 'purple'
    },
    {
      "Status_ID": 1,
      "StatusName": 'Paid',
      "StatusColor": 'green'
    },
    {
      "Status_ID": 2,
      "StatusName": 'Created',
      "StatusColor": 'blue'
    }
  ] })
  statuses!: any[];

  popoverVisible = false;
  optionsVisible = false;

  get statusText() {
    const status =  this.status === 0
      ? "Sent"
      : this.status === 1
      ? "Paid"
      : this.status === 3
      ? "Partial Pay"
      : this.status === 2
      ? "Created"
      : "Created";

      return status
  }

  mouseover() {
    if (this.optionsVisible) {
      return;
    }

    this.popoverVisible = true;
  }
  hideTooltip() {
    this.optionsVisible = false;
  }
  mouseout() {
    this.popoverVisible = false;
  }

  click() {
    this.popoverVisible = false;

    if(!(this.status == 1 || this.status == 3 && this.orderHasPaymentInfo))
      this.optionsVisible = !this.optionsVisible;
  }

  clickOutsideOptionsBox() {
    if (this.optionsVisible) {
      this.optionsVisible = false;
    }
  }

  itemClick(status: InvoiceStatusValue) {
    this.$emit("changeStatus", status);
    this.optionsVisible = false;
  }
}
