import { render, staticRenderFns } from "./StandardProductsToolTip.vue?vue&type=template&id=db858cf0&scoped=true"
import script from "./StandardProductsToolTip.vue?vue&type=script&lang=tsx"
export * from "./StandardProductsToolTip.vue?vue&type=script&lang=tsx"
import style0 from "./StandardProductsToolTip.vue?vue&type=style&index=0&id=db858cf0&prod&scoped=true&lang=less"
import style1 from "./StandardProductsToolTip.vue?vue&type=style&index=1&id=db858cf0&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db858cf0",
  null
  
)

export default component.exports