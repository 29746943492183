




































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import LaddaButton from "../components/LaddaButton.vue";
import PageTitle from "../components/pageTitle.vue";
import SelectTagControl from "@/components/SelectTagControl.vue";
import axios from "axios";
import { notifier } from "../models/common";

declare const getImageRouteData: any;

@Component({
  inheritAttrs: false,
  components: {
    SelectTagControl,
    LaddaButton,
    PageTitle
  }
})
export default class HardwareAddNew extends TSXComponent<void> {

  assetNumber = '';
  sku = '';
  category = '';
  price = '';
  warrantyExp = '';
  description = '';
  addToCustomerId = '';

  images = {};
  randId = Date.now();

  productDetails: {
    [key: string]: {
      detail?: string;
      description?: string;
    };
  } = {};

  categoryList: any = [];
  accountList: any = [];

  loading: boolean = false;

  async created() {
    const productDetail: {
      [key: string]: {
        detail?: string;
        description?: string;
      };
    } = {};
    for (let i = 1; i <= 2; i++) {
      productDetail["product_" + i] = {
        detail: '',
        description: ''
      };
    }

    this.productDetails = productDetail;

    await Promise.all([this.getCategoryList(), this.getAccountList()]);
    this.initDatePicker();
  }

  initDatePicker() {
    let html = "";
    html += '<div id="datepicker" class="datepicker-container">';
    html +=
      '<div class="datepicker-panel" data-view="years picker"><ul class="fr-current"><li data-view="years prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="years current"></li><li data-view="years next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="years"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="months picker"><ul class="fr-current"><li data-view="year prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="year current"></li><li data-view="year next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="months"></ul></div>';
    html +=
      '<div class="datepicker-panel" data-view="days picker"><ul class="fr-current"><li data-view="month prev"><img src="images/arrow-prev.svg" alt="Icon Prev" /></li><li data-view="month current"></li><li data-view="month next"><img src="images/arrow-next.svg" alt="Icon Next" /></li></ul><ul data-view="week"></ul><ul data-view="days"></ul></div>';
    html += "</div>";

    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      zIndex: 2048,
      daysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      template: html
    });

    const self = this;
    $(document).on("change", "#warrantyExp", e => {
      self.warrantyExp = $("#warrantyExp")
        .val()
        .trim();
    });
  }

  fileToBlobUrl(file) {
    return URL.createObjectURL(file);
  }

  async getCategoryList() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "Category",
        action: "getHardwareCategories"
      });

      if (response.data.STATUS == 1) {
        this.categoryList = response.data.CATEGORY.map((categ) => ({
          ID: categ.CATEGORYID,
          TEXT: categ.CATEGORYNAME
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getAccountList() {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Queries",
        FunctionName: "GetAllAccounts",
        subsystem: "Helpers"
      });

      if (response.data.ACCOUNTLIST) {
        const accountList = response.data.ACCOUNTLIST;

        this.accountList = accountList.map((account) => ({
          ID: account.AID,
          TEXT: account.ANAME
        }));
      }

    } catch (err) {
      console.log(err);
    }
  }

  chooseCategory(categoryId, rowIdx) {
    this.category = categoryId;
  }

  chooseAccount(accountId, rowIdx) {
    this.addToCustomerId = accountId;
  }

  checkErrorMsg(errorKey) {
    return this.$validator.errors.first(errorKey);
  }

  onFileChange(event) {
    const vm = this;
    this.images["image_" + this.randId] = event.target.files[0];
    this.$forceUpdate();
    vm.randId = Date.now();
    // this.$refs.inputUpload.value = null;
    $("#input-upload").val("");
  }

  removeImage(index) {
    delete this.images[index];
    const imageDom = document.getElementById("list_" + index);
    if (imageDom) {
      imageDom.remove();
    }
  }

  addProductDetailsRow(index) {
    const propertyLength = Object.keys(this.productDetails).length;
    if (index == "product_" + propertyLength) {
      this.productDetails["product_" + (propertyLength + 1)] = {
        detail: '',
        description: ''
      };
      this.$forceUpdate();
    }
  }

  async addHardware() {
    this.loading = true;

    try {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      const requestObj = {
        Controller: 'Hardware',
        FunctionName: 'AddNew',
        hardwareID: '[Auto Generate]',
        assetNumber: this.assetNumber,
        hardwareSKU: this.sku,
        category: this.category,
        price: this.price,
        warrantyExp: this.warrantyExp,
        hDescription: this.description,
        userID: sessionStorage.getItem("userId"),
        addToCustomerId: this.addToCustomerId,
        uploadPath: "Inbound/apiFiles/"
      }

      // product images
      let imgCount = 0;
      for (const imgKey in this.images) {
        imgCount++;
        requestObj['hardwareImage' + imgCount] = this.images[imgKey];
      }

      requestObj['hImagesLength'] = imgCount;

      // product details
      let pdCount = 0;
      for (const pdKey in this.productDetails) {
        if (this.productDetails[pdKey].detail && this.productDetails[pdKey].description) {
          pdCount++;
          requestObj['productDetail' + pdCount] = this.productDetails[pdKey].detail;
          requestObj['productDescription' + pdCount] = this.productDetails[pdKey].description;
        }
      }
      requestObj['hLines'] = pdCount;

      const response = await getImageRouteData(requestObj);

      if (response.data.STATUS === 1) {
        this.$router.push({ name: "Hardware" });
      } else if (response.data.ERROR) {
        console.log(response.data.ERROR);
      } else if (response.data.STATUSMESSAGE) {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (error) {
      console.log('error ', error);
    } finally {
      this.loading = false;
    }
  }

}
