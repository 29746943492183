
























































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import PageTitle from "@/components/pageTitle.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "@/models/common";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    PageTitle,
    DropdownControl
  }
})
export default class StoreDetails extends TSXComponent<void> {
  loading = false;
  isActive = "settings";
  settingItems: any = [];
  storeId = 0;
  storeInfo: any = {};
  accountInfo: any = {};
  settingsCards: any = [];
  selectedSetting = "";
  substore = 0;
  savedSettings: any = {};
  saving = false;
  $allowedFunctions: any;
  $userFunctions: any;

  async created() {
    this.storeId = parseInt(this.$route.params.id) || 0;
    await this.fetchData();
  }

  //go to search page
  gotoSearch = function (this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  listPageRedirection() {
    this.$router.push({ name: "CustomerPortals", query: { tab: "2" } });
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async toggleActive(div) {
    this.isActive = div;
    this.selectedSetting = "";
  }

  async fetchData() {
    if (!this.storeId) return;

    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "GetStoreInfo",
        storeId: this.storeId
      });

      if (response.data.STATUS == 1) {
        this.storeInfo = response.data.storeInfo || {};
        this.accountInfo = response.data.accountInfo || {};
        this.settingsCards = response.data.settingsCards || [];
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async selectSetting(item) {
    if (!item) {
      this.selectedSetting = "";
      return;
    }

    this.selectedSetting = item.DATASET || "";
  }

  loadSettingComponent(name: string) {
    return () => import(`@/components/Store/Settings/${name}.vue`);
  }

  get settingComponent() {
    return this.loadSettingComponent(this.selectedSetting);
  }

  get currentSettingItem() {
    if (!this.selectedSetting) {
      return {};
    }

    const inList = this.settingsCards.find(
      item => (item.DATASET || "") == this.selectedSetting
    );
    return inList || {};
  }

  async getSavedSettings() {
    // get saved settings
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "GetSettingCardContent",
        storeId: this.storeId,
        selectedSetting: this.selectedSetting
      });

      if (response.data.STATUS == 1) {
        this.savedSettings = response.data.savedSettings || {};
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async saveSettings(moreParams = {}) {
    const settingComponentRef: any = this.$refs.settingComponentRef;
    if (!settingComponentRef || !settingComponentRef.collectData) return;

    const collectData = await settingComponentRef.collectData();
    if (!(collectData.status || 0)) {
      return;
    }

    try {
      this.saving = true;
      const data = collectData.data || {};
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Stores",
        FunctionName: "SaveSettingCardContent",
        storeId: this.storeId,
        selectedSetting: this.selectedSetting,
        data: {
          ...data,
          ...moreParams
        }
      });

      if (response.data.STATUS == 1) {
        let message = response.data.STATUSMESSAGE || "";
        const moreInfo = response.data.moreInfo || {};
        if (message) {
          if (this.selectedSetting != "cart-experience") {
            notifier.success(message);
          } else {
            // cart exp change
            if ((moreInfo.error || "") != "") {
              message = moreInfo.error;
              if ((moreInfo.LRs || "") != "") {
                message += ` (${moreInfo.LRs})`;
              }

              settingComponentRef.confirmUpdateMessage = message;
              settingComponentRef.confirmUpdateVisible = true;
            } else {
              notifier.success(message);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.saving = false;
    }
  }

  getFullURL(url) {
    return url.startsWith("http:") || url.startsWith("https:")
      ? url
      : `https://${url}`;
  }

  get allowEdit() {
    return (
      (this.storeInfo.STOREURL || "") &&
      this.$allowedFunctions.includes(this.$userFunctions.EditPortalsStores)
    );
  }
}
