











































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import LaddaButton from "@/components/LaddaButton.vue";
import DropdownControl from "@/components/DropdownControl.vue";
import { notifier } from "@/models/common";
import { ApiHelper } from "@/helpers/all";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl
  }
})
export default class AddNetTermModal extends TSXComponent<void> {
  $refs!: {
    modal: HTMLDivElement;
  };

  @Prop({ required: false, default: {} })
  selectedTerm!: any;

  $validator: any;
  saving: boolean | "error" = false;
  $parent: any;
  termDays: string = "";
  termName = "";
  // termType = 1; // 1: term days (numeric), 2: term name (string)

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  created() {
    this.termName = this.selectedTerm.TERMNAME || "";
    this.termDays = this.selectedTerm.DAYS || "";
  }

  async save() {
    let valid = await this.$validator.validateAll();

    if (!valid) {
      return;
    }

    const inList = this.$parent.netTerms.find(
      (item: any) =>
        item.TERMNAME.toLowerCase() == this.termName.toLowerCase() &&
        item.TERMID != (this.selectedTerm.TERMID || 0)
    );
    if (inList) {
      notifier.alert("Term already exists");
      valid = false;
    }

    if (!valid) {
      return;
    }

    try {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        controller: "Helpers",
        FunctionName: "NetTerms",
        action: this.isUpdate ? "update" : "add",
        termDays: this.termDays,
        termName: this.termName,
        termId: this.selectedTerm.TERMID || 0
        // termType: this.termType
      });

      if (response.STATUS === 1) {
        this.saving = false;
        this.$parent.netTerms = response.netTerms || [];
        notifier.success(response.STATUSMESSAGE);
        this.$emit("close");
      } else {
        this.saving = "error";
        const errMessage = response.STATUSMESSAGE;
        if (errMessage) {
          notifier.alert(errMessage);
        }
      }
    } catch (err) {
      this.saving = "error";
      console.log(err);
    }
  }

  get isUpdate() {
    return (this.selectedTerm.TERMNAME || "") != "";
  }
}
