






















































































































































































































































































































































































































































































































































































































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { ErrorBag } from "vee-validate";
import axios from "axios";
import directives from "../helpers/directives";
import QuoteCreate from "../pages/QuoteCreate.vue";
import ProductCatTooltip from "../components/ProductCatTooltip.vue";
import OrderTaxRateTooltip from "../components/OrderTaxRateTooltip.vue";
import ConfirmYesNoTooltip from "../components/ConfirmYesNoTooltip.vue";
import { dollarFormat, getEUAddress } from "@/helpers/ApiHelper";
import BuildHeaderTooltip from "@/components/BuildHeaderTooltip.vue";

@Component({
  inheritAttrs: false,
  components: {
    QuoteCreate,
    ProductCatTooltip,
    OrderTaxRateTooltip,
    ConfirmYesNoTooltip,
    BuildHeaderTooltip
  },
  directives,
  methods: {
    dollarFormat,
    getEUAddress
  }
})
export default class CreateDirect extends TSXComponent<void> {
  $parent: any;
  @Prop({ required: false, default: 0 })
  items!: string;

  taxRateVisible = false;
  buildHeaderTooltipIndex = -1;

  data() {
    // $(".estPercent").removeClass("displayNone");

    return {
      itemNames: this.$parent.itemNames
    };
  }
  async childValidation() {
    const valid = await this.$validator.validateAll();
    return valid;
  }

  saveBuildHeader(data, item) {
    item.buildHeader = data.buildHeader || '';
    this.buildHeaderTooltipIndex = -1;
  }
}
