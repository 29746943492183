





















































































































































































import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

import LaddaButton from "@/components/LaddaButton.vue";
import { notifier, wait } from "@/models/common";
import DropdownControl from "@/components/DropdownControl.vue";
import { ApiHelper } from "@/helpers/all";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import axios from "axios";

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    ConfirmRemoveItemModal
  }
})
export default class RefreshStore extends TSXComponent<void> {
  saving: boolean | "error" = false;
  updateNotes: string = "";
  $parent: any;

  @Prop({ required: false, default: "" })
  selectedIDs!: Array<any>;

  @Prop({ required: false, default: [] })
  selectedItems!: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  storeSKUs: any = [];
  uniqueSKUs: any = [];
  skuAssociations: any = {};
  newTR = "";
  newStoreSKUs: any = [];
  aID: number = 0;
  isDisabled: boolean = true;
  confirmDeleteSkuAssociationsModalVisible: boolean = false;
  deletedSku: string = "";

  async created() {
    // Remove duplicate SKU
    this.selectedItems = this.selectedItems.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.SKU === item.SKU
      ))
    );
    this.getUniqueSKUs();
    // get available skus in smartstore
    try {
      this.loading = true;
      const orderIds = [
        ...new Set(this.selectedItems.map(item => item.PURCHASEID))
      ];
      const aId = [...new Set(this.selectedItems.map(item => item.AID))];
      if (aId.length > 1) {
        notifier.alert("Please select SKUs of same account");
        this.$emit("close");
        return;
      }

      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "GetSmartStoreSKUs",
        orderIds: orderIds.join(","),
        aId: aId[0]
      });
      const message = response.data.MESSAGE || "";
      this.aID = response.data.aID || 0;
      if (response.data.STATUS == 1) {
        this.storeSKUs = response.data.products || [];
        this.skuAssociations = response.data.associatedSKUs || {};
        this.newStoreSKUs = (response.data.products || []).map(
          tmp => ({
            ID: tmp.OLMS_PRODUCT_SKU,
            TEXT: `${tmp.OLMS_PRODUCT_SKU} - ${tmp.OLMS_PRODUCT_NAME}`,
            DATA: tmp
          })
        );
        this.isDisabled = false;
      } else {
        notifier.alert(message || "Something went wrong");
        if (response.data.STATUS === 2) {
          this.$emit("isAllowAccountConfig", {
            configAccountID: response.data.CONFIGACCOUNTID || ""
          });
        } else {
          this.$emit("close");
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  getUniqueSKUs() {
    this.uniqueSKUs = [
      ...new Set(
        this.selectedItems.map(item => {
          return {
            sku: item.SKU,
            id: item.HARDWAREID,
            name: item.HDESCRIPTION
          };
        })
      )
    ];
    this.uniqueSKUs.forEach(item => {
      this.$set(this.skuAssociations, item.sku, "");
    });
  }

  updateSkuAssociation(sku, associatedSku) {
    this.$set(this.skuAssociations, sku, associatedSku);
  }

  async updateAll() {
    try {
      this.loading = true;
      if (this.aID) {
        const response = await ApiHelper.callApi("post", {
          controller        : "Customers",
          FunctionName      : "UpdateAccountParams",
          aID               : this.aID,
          associatedSKUs    : this.skuAssociations,
          action            : "saveSkuAssociations"
        });
  
        if (response.STATUS === 1) {
          // this.$emit("close");
          // const response2 = await axios.post("https://dev-us.com/Framework/?ReturnType=JSON", {
          //   controller: "Carts",
          //   FunctionName:"CartAction",
          //   SaveFunctionType: "ProductAddUpdate",
          //   associatedSKUs: this.skuAssociations
          // }, {
          //   headers: {
          //     'Content-Type': 'application/json'
          //     // any other headers you might need
          //   }
          // });
        } else {
          // Handle non-200 responses here, if necessary
          console.error("Non-200 response received:", response);
        }
      }

      // validate and prepare request data
      let isValid = true;
      const products: any = [];
      for (const item of this.uniqueSKUs) {
        const ssSKU = this.skuAssociations[item.sku] || "";
        const ssProduct = this.storeSKUs.find(
          item => item.OLMS_PRODUCT_SKU == ssSKU
        );
        if (ssSKU == "" || !ssProduct) {
          isValid = false;
          break;
        }

        products.push({
          OLMS_PRODUCT_ID: ssProduct.OLMS_PRODUCT_ID,
          OLMS_PRODUCT_SKU: ssProduct.OLMS_PRODUCT_SKU,
          quantity: 1
        });
      }

      if (!isValid) {
        notifier.alert("Please select Store SKU");
        return;
      }

      // try to create a SS TR
      const result = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "ExternalSends",
        FunctionName: "CreateSmartStoreTR",
        orderIds: this.selectedItems.map(item => item.PURCHASEID).join(","),
        products
      });
      const message = result.data.MESSAGE || "";
      if (result.data.STATUS == 1) {
        // success
        // this.$emit("close");
        this.$emit("resetSelectedIds");

        this.newTR = result.data.newTR || "";
        if (this.newTR) {
          notifier.success(`Created SS TR ${this.newTR}`);
        }
      } else {
        notifier.alert(message || "Something went wrong");
      }
    } catch (error) {
      // Handle errors from the axios request here
      console.error("Error with request:", error);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async deleteSkuAssociations() {
    if (this.deletedSku) {
      this.$delete(this.skuAssociations, this.deletedSku);
      // Update SKU Associations
      if (this.aID) {
        const response = await ApiHelper.callApi("post", {
          controller        : "Customers",
          FunctionName      : "UpdateAccountParams",
          aID               : this.aID,
          deletedSku        : this.deletedSku,
          action            : "deleteSkuAssociations"
        });

        if (response.STATUS === 1) {
          notifier.success("SKU deleted successfully");
        } else {
          notifier.alert("Something went wrong");
        }
      }
      this.confirmDeleteSkuAssociationsModalVisible = false;
      this.deletedSku = '';
    }
  }
}
