





















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";
import { removeHTML } from "@/helpers/ApiHelper";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class StorePassphrase extends TSXComponent<Props, Events> {
  $parent: any;

  async created() {
    await this.$parent.getSavedSettings();
  }

  collectData() {
    this.$parent.savedSettings.OLMSAccountDetails_PassPhrase = removeHTML(
      this.$parent.savedSettings.OLMSAccountDetails_PassPhrase
    );
    return {
      status: 1,
      data: {
        passphrase: this.$parent.savedSettings.OLMSAccountDetails_PassPhrase
      }
    };
  }
}
