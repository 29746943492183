import Vue from "vue";
import { routerData } from "@/router";
import { ApiHelper } from "@/helpers/all";
import axios from "axios";
import moment from "moment";
import { DStatusType } from "./Types/StatusTypes";

declare const AWN: any;

export enum NotificationTypes {
  NewPendingQuotes = 1,
  NewOrder = 2,
  InvoicePaid = 3
}

export enum VarSource {
  HPDirect = 1,
  HPDirectOSSAuto = 17
}

export const loginCheck = () => {
  return sessionStorage.getItem("userRole");
};

export const loginCustomerName = () => {
  return sessionStorage.getItem("accountName");
};

export const getRangeFilter = (min, max) => {
  const arr: string[] = [];
  if (min) {
    arr.push(`Min: ${min}`);
  }
  if (max) {
    arr.push(`Max: ${max}`);
  }
  return arr.join("; ");
};

export const getDateRangeFilter = (from, to) => {
  const arr: string[] = [];
  if (from) {
    arr.push(`From: ${moment(from).format("MM/DD/YYYY")}`);
  }
  if (to) {
    arr.push(`To: ${moment(to).format("MM/DD/YYYY")}`);
  }
  return arr.join("; ");
};

export const colsResizeable = (
  options: { ms?: number; selector?: string } = { ms: 0, selector: "" }
) => {
  setTimeout(() => {
    const table = !options.selector
      ? $(".page-list .page-list-container > table")
      : $(options.selector);
    if (!table.length) return;

    table.find("thead th").data("sorter", false);
    table
      .tablesorter({
        headerTemplate: "",
        widgets: ["resizable"],
        widgetOptions: {
          resizable: true,
          resizable_addLastColumn: true
        }
      })
      .on("resizableComplete", function(event) {
        if($(".devices").length){
          if($(".devices").width() < 210){
            $(".tablesorter tr").each(function(i, obj) {
              $(obj).find("#detailed").parent().parent().removeClass('w-100');
              $(obj).find("#grouped").removeClass("d-none").addClass("d-flex");
              $(obj).find("#detailed").addClass("d-none").removeClass("d-flex");
              $(obj).find("#grouped").parent().removeClass("w-100");
            });
          }
          else{
            $(".tablesorter tr").each(function(i, obj) {
              $(obj).find("#grouped").addClass("d-none").removeClass("d-flex");
              $(obj).find("#detailed").parent().parent().addClass('w-100');
              $(obj).find("#detailed").removeClass("d-none").addClass("d-flex");
              $(obj).find("#grouped").parent().addClass("w-100");
            });
          }
        }
        // var resizable_vars = this.config.widgetOptions.resizable_vars;
        // var saved = {
        //   // resizable_vars.storedSizes is an empty array when no resizing was done
        //   // otherwise it contains each column width (padding & border width not included) in pixels
        //   columnWidths: resizable_vars.storedSizes,
        //   // overall table width
        //   tableWidth: resizable_vars.tableWidth
        // };
      })
      .find("thead")
      .unbind("contextmenu");
  }, options.ms);

  // apply hover events on resizable handle item
  $(document).off("mouseenter", ".tablesorter-resizable-handle");
  $(document).on("mouseenter", ".tablesorter-resizable-handle", e => {
    const table = $(e.target)
      .closest(".tablesorter-resizable-container")
      .next();
    if (table.length) {
      table.find("thead").addClass("hover");
    }
  });

  $(document).off("mouseleave", ".tablesorter-resizable-handle");
  $(document).on("mouseleave", ".tablesorter-resizable-handle", e => {
    const table = $(e.target)
      .closest(".tablesorter-resizable-container")
      .next();
    if (table.length) {
      table.find("thead").removeClass("hover");
    }
  });
};

export const colsResizeableUpdate = (
  options: { ms?: number; selector?: string } = { ms: 0, selector: "" }
) => {
  setTimeout(() => {
    const table = !options.selector
      ? $(".page-list .page-list-container > table")
      : $(options.selector);
    if (!table.length) return;

    table.trigger("resizableUpdate");
    if($(".devices").length){
      if($(".devices").width() < 210){
        $(".tablesorter tr").each(function(i, obj) {
          $(obj).find("#detailed").parent().parent().removeClass('w-100');
          $(obj).find("#grouped").removeClass("d-none").addClass("d-flex");
          $(obj).find("#detailed").addClass("d-none").removeClass("d-flex");
          $(obj).find("#grouped").parent().removeClass("w-100");
        });
      }
      else{
        $(".tablesorter tr").each(function(i, obj) {
          $(obj).find("#grouped").addClass("d-none").removeClass("d-flex");
          $(obj).find("#detailed").parent().parent().addClass('w-100');
          $(obj).find("#detailed").removeClass("d-none").addClass("d-flex");
          $(obj).find("#grouped").parent().addClass("w-100");
        });
      }
    }
  }, options.ms);
};

export const colsResizeableReset = (
  options: { ms?: number; selector?: string } = { ms: 0, selector: "" }
) => {
  const table = !options.selector
    ? $(".page-list .page-list-container > table")
    : $(options.selector);
  if (!table.length) return;

  table.trigger("resizableReset");
};

export const dollarFormat = (
  value: number,
  useK = false,
  options = { useBrackets: false }
) => {
  const configOptions = options || {};
  const useBrackets = configOptions.useBrackets || false;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  if (!useK) {
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  } else {
    let newVal = value;
    if (newVal >= 1000 || newVal <= -1000) {
      newVal = newVal / 1000;
      if (useBrackets) {
        return value < 0
          ? `(${formatter.format(0 - newVal) + "k"})`
          : formatter.format(newVal) + "k";
      }
      return formatter.format(newVal) + "k";
    }
    if (useBrackets) {
      return value < 0
        ? `(${formatter.format(0 - value)})`
        : formatter.format(value);
    }
    return formatter.format(value);
  }
};

export const getNumberFormat = (value: number) => {
  const formatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 });
  return value < 0
      ? `(${formatter.format(0 - value)})`
      : formatter.format(value);
};

export const setDataLoading = (loading: boolean) => {
  Vue.set(routerData.isDataLoading, "value", loading);
  if (!loading) {
    $(".logo.logo-inactive").removeClass("opacity-0");
    setTimeout(() => {
      $(".logo.logo-inactive").addClass("opacity-0");
    }, 1000);
  }
};

export const callApi = async (
  method: "post" | "get" | "put" | "delete" | "patch",
  data: any = {},
  params?: any,
  load?: boolean
) => {
  let apiRoute = dataURL + "?ReturnType=JSON";
  if (load) {
    ApiHelper.setDataLoading(true);
  }
  try {
    const result = await axios({
      method: method,
      url: apiRoute,
      data: JSON.stringify(data),
      params: params,
      headers: {
        "Content-Type": "application/json"
      }
    });
    ApiHelper.setDataLoading(false);
    return result.data;
  } catch (error) {
    // stop loader function
    ApiHelper.setDataLoading(false);
    const errorResponse = error.response || {};
    const errorData = errorResponse.data || {};
    const errorMessage =
      errorData.message || error.message || JSON.stringify(error);
    return {
      STATUS: errorResponse.status || 0,
      STATUSMESSAGE:
        errorMessage ||
        errorResponse.statusText ||
        "Something was wrong. Please try it later.",
      data: error
    };
  }
};
export const showSuccessMessage = (
  message,
  title = "",
  options: {
    duration?: number;
    btnLabel?: string;
    callbackFn?: any;
  } = {}
) => {
  const notifier = new AWN({
    labels: {
      success: title
    }
  });
  notifier.options.position = "bottom-left";
  notifier.options.duration = options.duration || 3000;
  const tmpButton = new Date().getTime();
  const btnLabel = options.btnLabel || "";
  const callbackFn =
    options.callbackFn ||
    (() => {
      console.log("nothing");
    });
  notifier.success(`<div>
     <div class="success-message">${message}</div>
     ${
       btnLabel
         ? `
     <div>
        <a class="btn-more btn-${tmpButton}">${btnLabel}</a>
     </div>`
         : ``
     }
  </div>`);
  $(document).on("click", ".btn-more.btn-" + tmpButton, e => {
    callbackFn();
  });
};
export const showErrorMessage = (
  message,
  title = "",
  options: {
    duration?: number;
    btnLabel?: string;
    callbackFn?: any;
  } = {}
) => {
  const notifier = new AWN({
    labels: {
      alert: title
    }
  });
  notifier.options.position = "bottom-left";
  notifier.options.duration = options.duration || 3000;

  const tmpButton = new Date().getTime();
  const btnLabel = options.btnLabel || "";
  const callbackFn =
    options.callbackFn ||
    (() => {
      console.log("nothing");
    });
  notifier.alert(`<div>
     <div class="success-message">${message}</div>
     ${
       btnLabel
         ? `
     <div>
        <a class="btn-more btn-${tmpButton}">${btnLabel}</a>
     </div>`
         : ``
     }
  </div>`);
  $(document).on("click", ".btn-more.btn-" + tmpButton, e => {
    callbackFn();
  });
};

import USState from "../static/USstates.json";
export const getCountryStates = async (country = "United States") => {
  const states: any[] = [];
  for (const i in USState) {
    states.push({
      name: USState[i].Abbr,
      state_code: USState[i].Abbr
    });
  }
  return states;
  /*return await fetch("https://countriesnow.space/api/v0.1/countries/states", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ country })
  })
    .then(response => response.json())
    .then(data => {
      return data.data.states;
    })
    .catch(error => {
      return [];
    });*/
};

export const getOrderStatusText = (statusId: number) => {
  let ret = "";
  switch (statusId) {
    case 1:
      ret = "Placed";
      break;
    case 3:
      ret = "Shipped";
      break;
    case 4:
      ret = "Delivered";
      break;
    case 6:
      ret = "Production";
      break;
    case 7:
      ret = "Cancelled";
      break;
  }
  return ret;
};

export const getStatusTypes = async deploymentId => {
  let ret = {
    statusTypes: [],
    unallocatedProducts: [],
    receivePercent: 0,
    schedulePercent: 0,
    buildPercent: 0,
    packagePercent: 0,
    deployPercent: 0,
    validatePercent: 0,
    deploymentPercent: 0
  };
  const response = await axios.post(dataURL + "?ReturnType=JSON", {
    controller: "Deployments",
    FunctionName: "GetStatusTypes",
    deploymentId
  });
  if (response.data.STATUS == 1) {
    ret.unallocatedProducts = response.data.unallocatedProducts || [];
    ret.statusTypes = (response.data.statusTypes || []).map(item => ({
      groupId: item.GROUPID,
      groupEst: moment(item.GROUPESTFORMATTED || "").isValid()
        ? moment(item.GROUPESTFORMATTED).format("MM/DD/YY")
        : "",
      groupEstFull: item.GROUPESTFORMATTED || "",
      id: item.STATUSTYPEID,
      estFull: item.ESTFORMATTED || "",
      est: moment(item.ESTFORMATTED || "").isValid()
        ? moment(item.ESTFORMATTED).format("MM/DD/YY")
        : "",
      title: item.STATUSTYPENAME,
      percent: parseInt(item.CURRENTPERCENT),
      total: parseInt(item.TOTALUPDATES)
    }));

    // calculate category percent
    // plan
    ret.receivePercent = calculateCategoryPercent("receive", ret.statusTypes);
    ret.schedulePercent = calculateCategoryPercent("schedule", ret.statusTypes);
    // build
    ret.buildPercent = calculateCategoryPercent("build", ret.statusTypes);
    ret.packagePercent = calculateCategoryPercent("package", ret.statusTypes);
    // deliver
    ret.deployPercent = calculateCategoryPercent("deploy", ret.statusTypes);
    ret.validatePercent = calculateCategoryPercent("validate", ret.statusTypes);

    // calculate deployment percent
    let totalPercent =
      ret.receivePercent +
      ret.schedulePercent +
      ret.buildPercent +
      ret.packagePercent +
      ret.deployPercent +
      ret.validatePercent;
    ret.deploymentPercent = parseFloat(totalPercent.toFixed(2)) / 6;
    ret.deploymentPercent = Math.round(ret.deploymentPercent);
  }

  return ret;
};

export const calculateCategoryPercent = (type = "", statusTypes) => {
  let ret = 0;
  let totalPercent = 0;
  let totalCnt = 0;
  let relatedIds: number[] = [];
  switch (type) {
    case "receive":
      relatedIds = [DStatusType.Receive];
      break;
    case "schedule":
      relatedIds = [DStatusType.Allocate, DStatusType.CompleteScheduling];
      break;
    case "build":
      relatedIds = [DStatusType.Imaging, DStatusType.Tagging];
      break;
    case "package":
      relatedIds = [DStatusType.Packaging];
      break;
    case "deploy":
      relatedIds = [DStatusType.Deploy];
      break;
    case "validate":
      relatedIds = [DStatusType.SignOff];
      break;
  }
  if (!relatedIds.length) return 0;

  for (const item of statusTypes) {
    if (relatedIds.includes(item.id)) {
      totalCnt += 1;
      totalPercent += item.percent || 0;
    }
  }

  if (totalCnt > 0) {
    ret = totalPercent / totalCnt;
  }

  return parseInt(ret.toFixed(2));
};

export const updateDeploymentPercent = async deploymentId => {
  const ret = await ApiHelper.getStatusTypes(deploymentId);
  const deploymentPercent = ret.deploymentPercent || 0;
  await axios.post(dataURL + "?ReturnType=JSON", {
    controller: "Deployments",
    FunctionName: "UpdateDeploymentPercent",
    deploymentId,
    deploymentPercent
  });

  return deploymentPercent;
};

export const getTrackingLink = (carrier, trackingNumber) => {
  if (!carrier || !trackingNumber) return "";
  let ret = "";
  switch (carrier.toLowerCase().trim()) {
    case "fedex ground":
    case "fedex ltl priority":
    case "federal express corporation":
      ret = `https://www.fedex.com/fedextrack/?cntry_code=us&tracknumbers=${trackingNumber}`;
      break;
    case "eagle global logistics":
      ret = `http://www.cevalogistics.com/ceva-trak?sv=${trackingNumber}`;
      break;
    case "ups":
    case "ups ground":
      ret = `http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
      break;
  }

  return ret;
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePrice = (price: string) => {
  const number = Number.parseFloat(price);
  return !!number;
};

export const validateDate = (dateString: string) => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthLength[1] = 29;
  }
  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export const cleanString = input => {
  let output = "";
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127) {
      output += input.charAt(i);
    }
  }
  return output;
};

export const getRandomNumber = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getEUAddress = (
  addr1 = "",
  addr2 = "",
  city = "",
  state = "",
  zip = "",
  country = ""
) => {
  // 1234 Something Dr. Caslte Rock, CO 80104
  const ret: string[] = [];
  if (addr1.trim() != "" || addr2.trim() != "" || city.trim() != "") {
    const addr: string[] = [];
    if (addr1.trim() != "") {
      addr.push(addr1.trim());
    }
    if (addr2.trim() != "") {
      addr.push(addr2.trim());
    }
    ret.push(`${addr.join(", ")} ${city.trim()}`);
  }
  if (state.trim() != "" || zip.trim() != "") {
    ret.push(`${state.trim()} ${zip.trim()}`.trim());
  }
  if (country.trim() != "") {
    ret.push(country.trim());
  }

  return ret.join(", ");
};

export const priceTypeStr = (
  priceFormatted = "",
  acctPriceTypeStr = "",
  customPriceTypeStr = undefined,
  isTitlePrice = false
) => {
  if (
    (acctPriceTypeStr == "" && customPriceTypeStr === undefined) ||
    priceFormatted === "" ||
    (typeof priceFormatted === "string" && priceFormatted.indexOf("check") != -1) ||
    priceFormatted === "$0.00"
  ) {
    return priceFormatted;
  }


  let finalPriceTypeStr: any = acctPriceTypeStr;
  if (customPriceTypeStr != undefined) {
    finalPriceTypeStr = customPriceTypeStr;
  }

  return `${priceFormatted} ${
    finalPriceTypeStr != ""
      ? isTitlePrice
        ? finalPriceTypeStr
        : `<span class="pt-indicator ml-1">${finalPriceTypeStr}</span>`
      : ""
  }`;
};

export const getCatName = (item: any) => {
  let ret = "";

  if (item.ISCONFIG || 0) {
    ret = "";
  } else if (item.CATEGORYNAME || "") {
    ret = `Category: ${item.CATEGORYNAME}`;
  } else {
    ret = "Category not available";
  }

  if (ret.length) {
    ret += ", ";
  }
  if (item.PRODUCTLINENAME || "") {
    ret += `Product Line: ${item.PRODUCTLINENAME.toUpperCase()}`;
  } else {
    ret += "Product Line not available";
  }

  return ret;
};


export const getHeightAlerts = () => {
  const heightAlerts = sessionStorage.getItem("heightAlerts") || '';
  return Number.parseInt(heightAlerts, 10) || 400;
};

export const saveHeightAlerts = (height) => {
  const heightAlerts = height || 0;
  sessionStorage.setItem('heightAlerts', height + '');
  return heightAlerts;
};

export const getPinAlerts = () => {
  return sessionStorage.getItem("pinAlerts") || '';
};

export const togglePinAlerts = () => {
  const pinAlerts = ApiHelper.getPinAlerts();
  const pinValue = pinAlerts ? '' : '1';
  sessionStorage.setItem('pinAlerts', pinValue);
  return pinValue;
};

export const saveCacheAlerts = (items, alerts, activeTab, searchLogsFilters, logsSort, searchAlertsFilters, alertsSort, isChecked, AlertsFilters, logsFilters) => {
  sessionStorage.setItem('cacheAlerts',
    JSON.stringify({
      items, alerts, activeTab, searchLogsFilters, logsSort, searchAlertsFilters, alertsSort, isChecked, AlertsFilters, logsFilters
    })
  );
}

export const getCacheAlerts = () => {
  const cacheAlerts = sessionStorage.getItem("cacheAlerts") || '{}';
  const data = JSON.parse(cacheAlerts) || {};
  return {
    items: data.items || [],
    alerts: data.alerts || [],
    logs: data.logs || [],
    activeTab: data.activeTab || '',
    searchLogsFilters: data.searchLogsFilters || {
      Id: "",
      Details: "",
      Customer: "",
      User: "",
      sDate: undefined,
      eDate: undefined
    },
    logsSort: data.logsSort || {
      field: 0,
      direction: {
        Id: 1,
        Details: 1,
        Customer: 1,
        User: 1,
        DueDate: 1
      }
    },
    searchAlertsFilters: data.searchAlertsFilters || {
      Id: "",
      Customer: "",
      Name: "",
      Type: "",
      User: "",
      sDate: undefined,
      eDate: undefined
    },
    alertsSort: data.alertsSort || {
      field: 0,
      direction: {
        Id: 1,
        Customer: 1,
        User: 1,
        DueDate: 1
      }
    },
    isChecked: data.isChecked || false,
    AlertsFilters: data.AlertsFilters || {
      Id: "",
      Customer: "",
      Name: "",
      Type: "",
      User: "",
      sDate: undefined,
      eDate: undefined,
      alertsType: ['Quotes', 'Orders']
    },
    logsFilters: data.logsFilters || {
      Id: "",
      Details: "",
      Customer: "",
      User: "",
      sDate: undefined,
      eDate: undefined
    }
  };
};

export const getInitials = (
  name: string = ""
) => {
  const words = name.split(" ");
  let initials = "";
  for (let i = 0; i < Math.min(2, words.length); i++) {
    initials += words[i].charAt(0);
  }
  return initials.toUpperCase();
}

export const isPriceExceeded = (item: any) => {
  const findPriceExceeded = (item.contractPrice || []).find((priceItem) => {
    return priceItem.CONTRACTPRICE < item.customerPrice;
  });
  return !!findPriceExceeded;
}

export const isPriceListExceeded = (item: any) => {
  const findPriceListExceeded = (item.contractPrice || []).find((priceItem) => {
    return priceItem.LISTPRICE < item.customerPrice;
  });
  return !!findPriceListExceeded;
}

export const getDueDateAlerts = () => {
  return Number.parseInt(sessionStorage.getItem("dueDateAlerts") || '', 10) || 0;
};

export const saveDueDateAlerts = (value) => {
  sessionStorage.setItem('dueDateAlerts', value);
  return value;
};

export const getVarTemplate = () => {
  return localStorage.VARtemplate || "minimal_outline";
};

export const checkTooltip = (element, totalRowCount) => {
  const tooltipHeight = $(element).offset().top - $(window).scrollTop();
  const container = $(window).height();
  const bottom = container - tooltipHeight;
  
  if (bottom <= tooltipHeight && totalRowCount > 3) {
    if (!$(element).parent().hasClass("topTooltip")) {
      $(element).parent().addClass("topTooltip");
    }
  } else {
    $(element).parent().removeClass("topTooltip");
  }
};

export const getTimezoneInfo = async () => {
  let dataObj = {
    controller: "Queries",
    FunctionName: "timezone",
    subsystem: "Helpers"
  };

  const timezoneResponse = await callApi('post', dataObj);
  const dynamicTzo = timezoneResponse.DYNAMIC_TZO || -7 * 60;

  // Get the current UTC time
  const now = moment.utc();
  // Apply the offset in minutes
  const localTime = now.add(dynamicTzo, 'minutes');
  // Format the date in MM/DD/YYYY
  const formattedDate = localTime.format('MM/DD/YYYY');

  return {
    dynamicTzo,
    formattedDate,
    localTime
  }
}

export const yearOptions = () => {
  const currentYear: number = new Date().getFullYear();
  const options: number[] = [];
  // Add previous year, current year, and 5 years after the current year
  for (let i = -1; i <= 5; i++) {
    options.push(currentYear + i);
  }
  return options;
}

export const getDefaultDataURL = () => {
  return process.env.VUE_APP_FRAMEWORK360_URL;
}

export const showErrorPage = (redirectName = "", query = {}) => {
  if (redirectName == "") return;

  Vue.set(routerData.errorData, 'message', 'Item not exist or you have no access to this.')
  Vue.set(routerData.errorData, 'redirectName', redirectName);
  Vue.set(routerData.errorData, 'redirectQuery', query);
  const win = (window as any)
  win.errorData = routerData.errorData
}

export const removeHTML = (txt) => {
  if (htmlCheck(txt)) {
    txt = htmlParse(txt);
  }

  return txt;
}