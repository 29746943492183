




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import { PaycheckAPIRepo,Filters, DetailSortField } from "../repos/PaycheckAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import PCDetailsPaidTotalTooltip from "../components/PCDetailsPaidTotalTooltip.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import HPAgentDetailsLinkAccountTooltip from "../components/HPAgentDetailsLinkAccountTooltip.vue";
import AccountLinkTooltip from "../components/AccountLinkTooltip.vue";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import InfoTooltip from "../components/InfoTooltip.vue";
import CustomerAdd from "../components/HPAgentDetailsCustomerAddModal.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import PageTitle from '../components/pageTitle.vue';
import { colsResizeable, getRangeFilter } from "@/helpers/ApiHelper";
import ListHeaderActions from "@/components/ListHeaderActions.vue";
import { ApiHelper } from "@/helpers/all";

declare function require(params: any): any;
const template = require("../templates/payChecks/payCheckList.handlebars");

declare const $: any;
declare const dataURL: string;
const paycheckRepo = new PaycheckAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    FilterCurrencyBox,
    PCDetailsPaidTotalTooltip,
    HPAgentDetailsLinkAccountTooltip,
    ConfirmRemoveItemModal,
    ExportModal,
    CustomerSearchTooltip,
    InfoTooltip,
    CustomerAdd,
    AccountLinkTooltip,
    PageTitle,
    ListHeaderActions
  },
  methods: {
    getRangeFilter: (min, max) => {
      return getRangeFilter(min, max);
    }
  }
})
export default class PaycheckDetail extends TSXComponent<void> {
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  currentRole = sessionStorage.getItem("userRole");
  exportModalVisible = false;
  loading: string | boolean = false;
  paycheckDetail = [];
  selectedID = [];
  pageNumber = 1;
  totalPages = 1;
  currentPage = 1;
  total = 0;
  totalAll = 0;
  userID = 0;
  paycheckID = 0;
  userInfo = {};
  totalEarn = 0;
  totalEarn_4 = 0;
  subTotalEarn = 0;
  subTotalEarn_4 = 0;
  currentCustAddDetail = [];
  confirmPaycheckRemove = false;
  removePaycheckId = 0;
  removePaycheckName = "";
  currentCustIndex = -1;
  paycheckOverview: any = {
    GENERATEDDATE_FORMATTED: "",
    STATUS: 0,
    STATUSTEXT: "",
    PNOTE: ""
  };
  filters: Filters = {
    accountID: "",
    aName: "",
    poNumber: "",
    saleNo: "",
    sTotalMin: "",
    sTotalMax: "",
    compTotalMin: "",
    compTotalMax: "",
    AccountTagID: [],
    groupIDs: [],
    selectedAccs: [],
    selectedAccsName: [],
    headerGroupIDs: []
  };
  searchFilters: Filters = {
    accountID: "",
    aName: "",
    poNumber: "",
    saleNo: "",
    sTotalMin: "",
    sTotalMax: "",
    compTotalMin: "",
    compTotalMax: "",
    AccountTagID: [],
    groupIDs: [],
    selectedAccs: [],
    selectedAccsName: [],
    headerGroupIDs: []
  };
  isSearching = false;
  sort = {
    field: "",
    direction: {
      [DetailSortField.total]: 1,
      [DetailSortField.earning]: 1,
      [DetailSortField.comPercent]: 1,
      [DetailSortField.paidTotal]: 1,
      [DetailSortField.lineItems]: 1,
      [DetailSortField.compTotal]: 1,
      [DetailSortField.Id]: 1,
      [DetailSortField.Name]: 1
    }
  };
  pNote = "";
  prevNote = "";
  viewType = "all_detail_rows"; //viewType = all_detail_rows | account_grouped
  filterByAccount = "";
  acctInfoTooltipVisibleIndex = -1;
  grouppedBySubAcctsIndex = -1;
  linkAccountTooltipVisible1 = -1;
  accountTags: any = [];
  accountTagsList: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: (number|undefined)[] = [];
  searchAccTxt = "";
  $route: any;
  $router: any;
  totalPaychecksId  = 0;
  removePaycheckGroupId = 0;
  exportDirectPaycheck = false;
  totalEarnings = 0;
  batchID = 0;
  deleteLoading: string | boolean = false;
  CurrentView = "Groups";
  hasChangedGroupFilters = false;
  searchGroups: string = "";

  async created() {
    if (typeof this.$route.params.empid != "undefined") {
      this.userID = parseInt(this.$route.params.empid);
    }

    if (typeof this.$route.params.id != "undefined") {
      this.paycheckID = parseInt(this.$route.params.id);
    }

    if (typeof this.$route.params.batchID != "undefined") {
      this.batchID = parseInt(this.$route.params.batchID);
    }

    //set viewType
    if (typeof this.$route.query.viewType == "undefined") {
      this.$router.replace({ query: { tab: this.$route.query.tab, viewType: this.viewType } });
    } else {
      this.viewType = this.$route.query.viewType.toString();
    }

    //set filterByAccount
    if (typeof this.$route.query.filterByAccount != "undefined") {
      this.filterByAccount = this.$route.query.filterByAccount.toString();
      this.searchFilters.accountID = this.filterByAccount;
    }

    if (typeof this.$route.query.SourceID != "undefined") {
      this.filters["SourceID"] = this.$route.query.SourceID;
    }
    if (typeof this.$route.query.StatementNumber != "undefined") {
      this.filters["StatementNumber"] = this.$route.query.StatementNumber;
    }

    if (((this.userID >= 0 || this.userID == -1) && this.paycheckID > 0) || this.batchID) {
      await this.fetchData();
    }
    await this.accsLoad();
  }

  async fetchData() {
    this.loading = true;
    this.hasChangedGroupFilters = false;
    try {
      if (this.batchID) {
        var ret = await paycheckRepo.getAccountDetails(
          this.pageNumber,
          this.batchID,
          this.filters,
          this.sort,
          this.viewType,
          this.filterByAccount
        );
      } else {
        var ret = await paycheckRepo.getDetails(
          this.pageNumber,
          this.userID,
          this.paycheckID,
          this.filters,
          this.sort,
          this.viewType,
          this.filterByAccount
        );
      }
      this.paycheckDetail = ret.PAYCHECKDETAIL;
      this.userInfo = ret.USERINFO;
      this.totalEarn = ret.TOTALEARN_FORMATTED;
      this.totalEarn_4 = ret.TOTALEARN_FORMATTED_4;
      this.subTotalEarn = ret.SUBTOTALEARN_FORMATTED;
      this.subTotalEarn_4 = ret.SUBTOTALEARN_FORMATTED_4;
      this.totalEarnings = ret.TOTALEARNIGS_FORMATTED;
      this.paycheckOverview = ret.PAYCHECKOVERVIEW;
      this.currentPage = ret.CURRENTPAGE;
      this.totalPages = ret.TOTALPAGES;
      this.total = ret.TOTAL;
      this.isSearching = this.isSearch();
      this.totalAll = typeof ret.TOTALALL != "undefined" ? ret.TOTALALL : 0;
      this.pNote = this.paycheckOverview.PNOTE;
      this.prevNote = this.paycheckOverview.PNOTE;
      if (this.searchTagsTxt == "") {
        this.accountTags = ret.accountTags;
        this.accountTagsList = ret.accountTags;
      }
      this.totalPaychecksId = ret.PAYCHECKSGROUPIDTOTAL;

      //assign paycheck status text
      switch (this.paycheckOverview.STATUS) {
        case 0:
          this.paycheckOverview.STATUSTEXT = "Pending";
          break;
        case 1:
          this.paycheckOverview.STATUSTEXT = "Paid";
          break;
        case 2:
          this.paycheckOverview.STATUSTEXT = "Cancelled";
          break;
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }

    colsResizeable({ms: 500});
  }
  listPageRedirection() {
    if (this.viewType == "all_detail_rows" && typeof this.$route.query.filterByAccount != "undefined") {
      this.viewBy('account_grouped');
    } else {
      this.$router.push({ name: "Payroll", query: { tab: this.$route.query.tab } });
    }
  }

  loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }

    if (this.pageNumber <= this.totalPages && this.pageNumber >= 1) {
      this.fetchData();
    }
  }

  //filters
  searchByAccountID() {
    this.pageNumber = 1;
    this.searchFilters.accountID = this.filters.accountID;
    this.fetchData();
  }
  searchByAccountName() {
    this.pageNumber = 1;
    this.searchFilters.aName = this.filters.aName;
    this.fetchData();
  }
  searchByPONumber() {
    this.pageNumber = 1;
    this.searchFilters.poNumber = this.filters.poNumber;
    this.fetchData();
  }
  searchBySaleNo() {
    this.pageNumber = 1;
    this.searchFilters.saleNo = this.filters.saleNo;
    this.fetchData();
  }

  //reset filters
  resetSearchByAccountID() {
    this.pageNumber = 1;
    this.filters.accountID = "";
    this.searchFilters.accountID = "";
    if (this.filterByAccount) {
      this.$router.replace({ query: { tab: this.$route.query.tab, viewType: this.viewType } });
      this.filterByAccount = "";
    }
    this.fetchData();
  }
  resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.aName = "";
    this.searchFilters.aName = "";
    if (allowFetchData) {
      this.fetchData();
    }
  }
  resetSearchByPONumber() {
    this.pageNumber = 1;
    this.filters.poNumber = "";
    this.searchFilters.poNumber = "";
    this.fetchData();
  }
  resetSearchBySaleNo() {
    this.pageNumber = 1;
    this.filters.saleNo = "";
    this.searchFilters.saleNo = "";
    this.fetchData();
  }
  resetSearchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = "";
    this.filters.sTotalMax = "";
    this.searchFilters.sTotalMin = "";
    this.searchFilters.sTotalMax = "";
    this.fetchData();
  }
  searchByCompTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.compTotalMin = this.searchFilters.compTotalMin;
    this.filters.compTotalMax = this.searchFilters.compTotalMax;
    this.fetchData();
  }
  searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.searchFilters.sTotalMin = this.filters.sTotalMin;
    this.searchFilters.sTotalMax = this.filters.sTotalMax;
    this.fetchData();
  }

  isSearch() {
    var ret = false;
    if (
      this.filters.accountID != "" ||
      this.filters.aName != "" ||
      this.filters.poNumber != "" ||
      this.filters.sTotalMin != "" ||
      this.filters.sTotalMax != "" ||
      this.filters.saleNo != "" || 
      (this.filters.AccountTagID && this.filters.AccountTagID.length) ||
      (this.filters.groupIDs && this.filters.groupIDs.length) ||
      (this.filters.headerGroupIDs && this.filters.headerGroupIDs.length) ||
      (this.filters.selectedAccs && this.filters.selectedAccs.length)
    ) {
      ret = true;
    }

    return ret;
  }

  //sort
  sortingById() {
    this.sortBy(DetailSortField.Id);
  }
  sortingByName() {
    this.sortBy(DetailSortField.Name);
  }
  sortByTotal() {
    this.sortBy(DetailSortField.total);
  }
  sortByEarning() {
    this.sortBy(DetailSortField.earning);
  }
  sortByComPercent() {
    this.sortBy(DetailSortField.comPercent);
  }
  sortingByCompTotal() {
    this.sortBy(DetailSortField.compTotal);
  }
  sortByPaidTotal() {
    this.sortBy(DetailSortField.paidTotal);
  }
  sortByLineItems() {
    this.sortBy(DetailSortField.lineItems);
  }
  sortBy(field) {
    this.sort.field = field;
    this.sort.direction[this.sort.field] =
      this.sort.direction[this.sort.field] == 1 ? 2 : 1;
    this.fetchData();
  }

  //paycheck note
  async updateNotes() {
    if (htmlCheck(this.pNote)) {
      this.pNote = htmlParse(this.pNote);
    }
    if (this.pNote != this.prevNote) {
      //do update
      // this.loading = true;
      try {
        var ret = await paycheckRepo.updateNotes(this.paycheckID, this.pNote);
        if (ret == true) {
          this.prevNote = this.pNote;
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        this.loading = false;
      }
    }

    $(".notes.entry, .notes.textarea").toggle(200);
  }

  //view by
  viewBy(viewType) {
    this.pageNumber = 1;
    if (
      this.viewType != viewType ||
      (this.viewType == "all_detail_rows" && (typeof this.$route.query.filterByAccount != "undefined" || typeof this.$route.query.SourceID != "undefined"))
    ) {
      this.viewType = viewType;
      this.$router.replace({ query: { tab: this.$route.query.tab, viewType: this.viewType } });

      //reset
      //this.paycheckDetail = [];
      this.filterByAccount = "";
      this.sort.field = "";
    }

    this.resetViewFilters();
    this.fetchData();
  }
  //reset all filter values
  resetViewFilters() {
    this.filters = {
      accountID: "",
      aName: "",
      poNumber: "",
      saleNo: "",
      sTotalMin: "",
      sTotalMax: "",
      AccountTagID: [],
      groupIDs: [],
      selectedAccs: [],
      headerGroupIDs: this.filters.headerGroupIDs
    };
    this.searchFilters = {
      accountID: "",
      aName: "",
      poNumber: "",
      saleNo: "",
      sTotalMin: "",
      sTotalMax: "",
      AccountTagID: [],
      groupIDs: [],
      selectedAccs: [],
      headerGroupIDs: this.filters.headerGroupIDs
    };
  }

  viewAllDetailsWithFilter(accountID) {
    this.pageNumber = 1;
    this.viewType = "all_detail_rows";
    this.filterByAccount = accountID;
    this.searchFilters.accountID = this.filterByAccount;
    //this.paycheckDetail = [];//reset

    this.fetchData();
  }

  viewAllDetailsByDisty(item) {
    this.pageNumber = 1;
    this.viewType = "all_detail_rows";
    this.filters["SourceID"] = item.SOURCEID;
    if(typeof item.STATEMENTNUMBER != "undefined" && item.STATEMENTNUMBER != "") {
      this.filters["StatementNumber"] = item.STATEMENTNUMBER;
    }
    this.fetchData();
  }

  async updateStatus(pStatus) {

    try {
      this.loading = true;
      var ret = await paycheckRepo.updateStatus(
        this.paycheckID.toString(),
        pStatus,
        this.paycheckOverview.INDIRECTORDERIDS,
        this.paycheckOverview.PG_GROUPID
      );
      if (ret.STATUS == 1) {
        notifier.success(
          `Payroll #${this.$route.params.id}'s Status Updated Successfully`
        );

        this.paycheckOverview.STATUS = pStatus;
        $(".qStatus .dropdown-menu li a").removeClass("active");
        $(".qStatus .dropdown-menu li a[value='" + pStatus + "']").addClass(
          "active"
        );
        this.paycheckOverview.STATUSTEXT = $(
          ".qStatus .dropdown-menu li a[value='" + pStatus + "']"
        )
          .text()
          .toLowerCase();
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  paidTotalTTVisibleIndex = -1;
  paidTotalTTLoading = false;
  paidTotalTTList = [];
  /* hover event for paidTotal column */
  async showTooltipPaidTotal(item, index) {
    var tooltipHeight = $("#id" + index).offset().top - $(window).scrollTop();
    var bottom = $("#id" + index).offset().top - $(window).scrollTop() + 180;
    var container = $("body").height();
    bottom = container - bottom;
    if (
      bottom <= tooltipHeight &&
      $("#list-content-container .list-item").length > 3
    ) {
      if (
        !$("#id" + index)
          .parent()
          .hasClass("topTooltip")
      ) {
        $("#id" + index)
          .parent()
          .addClass("topTooltip");
      }
    } else {
      $("#id" + index)
        .parent()
        .removeClass("topTooltip");
    }

    //Tmp allow to show tooltip of Paid paycheck
    // if(this.paycheckOverview.STATUS == 1) {
    //   return;
    // }
    if (item.ACCOUNTMAPPED != 1) {
      //don't show if not linked
      return;
    }

    if (this.paidTotalTTVisibleIndex === index) return;
    if (!item.MC_CDETAIL_ACCOUNTID) return;

    this.paidTotalTTVisibleIndex = index;

    try {
      this.paidTotalTTLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Finances",
        FunctionName: "GetAssginedPercent",
        accountID: item.MC_CDETAIL_ACCOUNTID,
        paycheckID: this.paycheckID,
        employeeId: this.userID,
        batchID: this.batchID
      });

      if (response.data.STATUS == 1) {
        this.paidTotalTTList = response.data.ASSIGNEDPERCENTARR;
      }
    } catch (err) {
      notifier.alert(err.message);
      this.paidTotalTTVisibleIndex = -1;
    } finally {
      this.paidTotalTTLoading = false;
    }
  }

  linkAccountTooltipVisible = -1;
  async linkAccount(linkAccountId: string, acc: any) {
    this.linkAccountTooltipVisible = -1;
    this.currentCustIndex = -1;
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "InsertAccountLinking",
        aID: acc.AID,
        LinkAccountId: linkAccountId
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      await this.fetchData();
      notifier.success(
        "Linked " + linkAccountId + " to " + acc.ACCOUNTID + " - " + acc.ANAME
      );
    } catch (err) {
      console.log(err.message);
      notifier.alert("search accounts fail: " + err.message);
    } finally {
      this.loading = false;
    }
  }
  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }
  async deletePaycheck() {
    this.deleteLoading = true;
    try {
      if (this.batchID) {
        let cyclesID = [this.removePaycheckGroupId];
        const response: any = await paycheckRepo.deleteCycles(
          cyclesID
        );

        if (response.ERROR) {
          notifier.alert(response.ERROR);
          return false;
        }

        if (response.STATUS == 1) {
          notifier.success(response.STATUSMESSAGE);
          this.deleteLoading = false;
          this.$router.push({
            name: "Payroll"
          });
        }
        if (response.STATUS == 2) {
          this.deleteLoading = "error";
          notifier.alert(response.STATUSMESSAGE);
        }
      } else {
        const response = await ApiHelper.callApi("post", {
          Controller      : "Finances",
          FunctionName    : "paycheckDelete",
          paycheckId      : this.removePaycheckId
        });
        if (response.ERROR) {
          notifier.alert(response.ERROR);
          return false;
        }

        if (response.STATUS == 1) {
          notifier.success(response.STATUSMESSAGE);
          this.deleteLoading = false;
          this.$router.push({
            name: "Payroll"
          });
        }
        if (response.STATUS == 2) {
          this.deleteLoading = "error";
          notifier.alert(response.STATUSMESSAGE);
        }
      }

    } catch (err) {
      // console.log(err.message);
    } finally {
      this.deleteLoading = false;
      this.removePaycheckId = 0;
      this.confirmPaycheckRemove = false;
    }
  }
  async performExport(formats: ExportFormat[],customtemplateId,saveAsDefaultTemplate,displayOnExport,bigdeal,exportPO,aztempidx,advancedFilters,customMessages,exportOrderDetail,reportingOrderDetail,exportDirectPaycheckDetail) {
    const exportFns: Function[] = [];
    this.exportDirectPaycheck = exportDirectPaycheckDetail;

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    // if (exportDirectPaycheckDetail) {
    //   exportFns.push(await this.csvDownLoadFn());
    // }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }
  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await paycheckRepo.exportListsPDFFileUrl(
        this.userID,
        this.paycheckID,
        this.batchID
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await paycheckRepo.exportListsCSVFileUrl(
        this.userID,
        this.paycheckID,
        this.exportDirectPaycheck,
        this.batchID
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }
  async htmlDownLoadFn() {
    const html = await this.selectedQuoteHtml("html");
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      if (!html) return;
      var fileNameExport =
        "PayrollList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async selectedQuoteHtml(from: string): Promise<string | false> {
    try {
      if (this.batchID) {
        var payChecks = await paycheckRepo.getAccountDetails(
          this.pageNumber,
          this.batchID,
          this.filters,
          this.sort,
          this.viewType,
          this.filterByAccount,
          true
        );
      } else {
        var payChecks = await await paycheckRepo.getDetails(
          this.pageNumber,
          this.userID,
          this.paycheckID,
          this.filters,
          this.sort,
          this.viewType,
          this.filterByAccount,
          true
        );
      }
      if (from == "print") {
        payChecks["type"] = "print";
      }
      return template(payChecks);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async print() {
    this.loading = true;
    const html = await this.selectedQuoteHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }

  rowsGrouppedBySubAccts = [];
  rowsGrouppedLoading = false;
  async showRowsGrouppedBySubAcct(index, item) {
    if (this.grouppedBySubAcctsIndex == index) {
      this.grouppedBySubAcctsIndex = -1;
      return;
    }

    this.grouppedBySubAcctsIndex = index;
    this.linkAccountTooltipVisible1 = -1;
    this.rowsGrouppedBySubAccts = [];
    try {
      this.rowsGrouppedLoading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Finances",
        FunctionName: "GetRowsGrouppedBySubAccts",
        userID: this.userID,
        paycheckID: this.paycheckID,
        accountID: item.MC_CDETAIL_ACCOUNTID,
        aID: item.AID,
        batchID: this.batchID
      });

      if (response.data.STATUS == 1) {
        this.rowsGrouppedBySubAccts = response.data.ROWSGROUPPEDBYSUBACCTS;
      }
    } catch (err) {
      console.log(err.message);
      this.grouppedBySubAcctsIndex = -1;
    } finally {
      this.rowsGrouppedLoading = false;
    }
  }

  hideRowsGroupped() {
    this.grouppedBySubAcctsIndex = -1;
    this.linkAccountTooltipVisible1 = -1;
  }

  async accountLink(subAccount: string, acc: any) {
    this.linkAccountTooltipVisible1 = -1;
    this.loading = true;

    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Accounts",
        FunctionName: "SetParentAccount",
        subAccount: subAccount,
        parentAID: acc.AID,
        parentAccountID: acc.ACCOUNTID
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      this.grouppedBySubAcctsIndex = -1;
      this.linkAccountTooltipVisible1 = -1;
      await this.fetchData();
      notifier.success(
        "Set " +
          acc.ACCOUNTID +
          " - " +
          acc.ANAME +
          " as parent of " +
          subAccount
      );
    } catch (err) {
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }
  resetSelected() {
    this.selectedID = [];
    // this.selectedAll = false;
    // this.excludedIDs = [];
  }

  //filter by Tags
  async filterByTag() {
    this.resetSelected();
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
		this.filters.AccountTagID = [];
		this.accountTags.forEach(function (val: any) {
			if ($this.filters.AccountTagID && !isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
				$this.filters.AccountTagID.push(val.ACCOUNTTAGID);
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
    this.filters.groupIDs = [];
		this.accountTags.forEach(function (val: any) {
			if ($this.filters.groupIDs && !isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    this.filters.groupIDs = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.accountTagsList;

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1
      );
    }

    this.accountTags = options;
  }

  toggleActive(div) {
    this.isActive = div;
  }
  filterAccsInBox() {
    let options = this.varAccsList;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName) {
    this.resetSelected();
    this.pageNumber = 1;
    if (
      accountsName != undefined &&
      this.filters.selectedAccsName != undefined
    ) {
      const accountsIndex = this.filters.selectedAccsName.findIndex(
        accountsValue => accountsValue === accountsName
      );
      if (accountsIndex === -1) {
        this.filters.selectedAccsName.push(accountsName);
      } else {
        this.filters.selectedAccsName.splice(accountsIndex, 1);
      }
    }
    await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccs.map(val => val.AID);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if (allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          view: this.viewType,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
        if (response.data.ERROR || !response.data.ACCOUNTS) {
          console.log("error", response.data.ERROR);
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }

  getFilterValue(header) {
    let filter = "";
    let searchKey: string | undefined = "";
    switch (header) {
      case "CUSTOMER":
      {
        const arr: string[] = [];
        if(this.searchFilters.aName) {
          arr.push(`Customer: ${this.searchFilters.aName}`);
        }
        if (this.filters.AccountTagID && this.filters.AccountTagID.length) {
          const selectedTags = this.accountTags
            .filter(
              t =>
                !(t.ISGROUP || false) &&
                t.ACCOUNTTAGID > 0 &&
                this.filters.AccountTagID &&
                this.filters.AccountTagID.includes(t.ACCOUNTTAGID)
            )
            .map(t => t.TAGNAME);
          const selectedTagNames = [...selectedTags];
          arr.push(`Customer Tags: ${selectedTagNames.join(", ")}`);
        }
        if (this.filters.selectedAccs && this.filters.selectedAccs.length) {
          arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
        }
        if (this.filters.groupIDs && this.filters.groupIDs.length) {
          const selectedGroups = this.accountTags
            .filter(
              t =>
                (t.ISGROUP || false) &&
                this.filters.groupIDs &&
                this.filters.groupIDs.includes(t.ACCOUNTTAGID)
            )
            .map(t => t.TAGNAME);
          arr.push(`Customer Groups: ${selectedGroups.join(", ")}`);
        }
        searchKey = arr.join("; ");
        break;
      }
      default:
        // nothing
        break;
    }
    return (
      (filter ? `${filter}` : "") +
      (searchKey ? (filter ? " - " : "") + searchKey : "")
    );
  }

  async resetColumn(header) {
    switch (header) {
      case "CUSTOMER":
        this.resetSearchByAccountName(false);
        this.resetAcc(false);
        this.resetTag(false);
        await this.fetchData();
        break;
      default:
        break;
    }
  }

  async checkCompGen(item) {
    let isCompGen = !item.ISCOMPGEN;
    if (item.PAYCHECKID) {
      const response = await ApiHelper.callApi("post", {
        controller: "Finances",
        FunctionName: "updatePaycheck",
        action: "updateCompGen",
        isCompGen: isCompGen,
        paycheckID: item.PAYCHECKID,
        PaycheckLineId: item.PAYCHECKLINEID
      });
      if (response.STATUS === 1) {
        // eslint-disable-next-line require-atomic-updates
        item.ISCOMPGEN = isCompGen;
        notifier.success(response.STATUSMESSAGE);
      } else {
        notifier.alert(response.STATUSMESSAGE);
      }
    }
  }

  getCurrentViewTitle() {
		if ((this.filters.headerGroupIDs && this.filters.headerGroupIDs.length)) {
			var fulltitle:any = [];

			var $this = this;
			this.accountTags.filter(item => item.ISGROUP).forEach(function (val: any) {
				if (!isNaN(val.ACCOUNTTAGID)) {
					if ($this.filters.headerGroupIDs && $this.filters.headerGroupIDs.includes(val.ACCOUNTTAGID)) {
						fulltitle.push(val.TAGNAME);
					}
				}
			});
			return fulltitle.join(", ");
		}

		return "";
	}

  async selectView(item, e) {
    e.stopPropagation();
	}

  filterByGroups() {
    this.hasChangedGroupFilters = true;
    if (
      (this.filters.headerGroupIDs && this.filters.headerGroupIDs.length)
    ) {
      this.CurrentView = this.getCurrentViewTitle();
    } else {
      this.CurrentView = "Groups";
    }
  }

  searchByGroups() {
    return this.accountTags.filter(item => item.ISGROUP && item.TAGNAME.toLowerCase().search(this.searchGroups.toLowerCase()) != -1);
  }

  async checkAllGroups() {
    this.filters.headerGroupIDs = this.accountTags.filter(item => item.ISGROUP).map(val => val.ACCOUNTTAGID);
    this.CurrentView = this.getCurrentViewTitle();
    this.hasChangedGroupFilters = false;
    await this.fetchData();
  }

  async resetGroups() {
    this.filters.headerGroupIDs = [];
    this.CurrentView = "Groups";
    this.hasChangedGroupFilters = false;
    await this.fetchData();
  }

  clickOutsideGroups() {
    this.searchGroups = "";
  }

}
