
















































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { notifier, downloadFile, printHtml } from "../models/common";
import Pagination from "../components/Pagination.vue";
import FileOptionsModal from "../components/FileOptionsModal.vue";

declare const dataURL: string;
declare const getMaxRows: Function;

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    FileOptionsModal,
    Pagination
  }
})
export default class Files extends TSXComponent<void> {
  loading = false;
  pageNumber =
    sessionStorage.pageDetails &&
    JSON.parse(sessionStorage.pageDetails)["files"]
      ? JSON.parse(sessionStorage.pageDetails)["files"]
      : 1;

  details: {
    CURRENTPAGE: number;
    TOTALPAGES: number;
  } = {
    CURRENTPAGE: 1,
    TOTALPAGES: 1
  };
  // details: any = [];
  files = [];
  fileoption = -1;
  quoteTypes: any = [
    { id: 1, name: "Distribution Quote" },
    { id: 2, name: "RFQ Docs" },
    { id: 3, name: "Supporting Emails" },
    { id: 4, name: "Quote" },
    { id: 5, name: "Other" }
  ];
  oppTypes: any = [
    { id: 1, name: "Purchase Order" },
    { id: 2, name: "Web Confirmation" },
    { id: 3, name: "Order Confirmation" },
    { id: 4, name: "Shipment Confirmation" },
    { id: 6, name: "Order PO" },
    { id: 7, name: "Invoice PDF" },
    { id: 8, name: "HP status file" },
    { id: 9, name: "Distribution Quote" },
    { id: 10, name: "RFQ Docs" },
    { id: 11, name: "Supporting Emails" },
    { id: 12, name: "Quote" },
    { id: 5, name: "Other" }
  ];

  async created() {
    await this.fetchFiles();
  }

  async fetchFiles() {
    this.loading = true;
    var dataObj = {
      controller: "Files",
      FunctionName: "List",
      pageNumber: this.pageNumber,
      maxRows: 15
    };
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    if (response.data.STATUS) {
      this.loading = false;
      this.files = response.data.FILES || [];
      this.details = response.data;
    } else if (response.data.ERROR) {
      console.log(response.data.ERROR);
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    return response.data;
  }

  loadPage(pageNumber: number, type) {
    if (type === "prev") {
      pageNumber = pageNumber - 1;
    } else if (type === "next") {
      pageNumber = pageNumber + 1;
    }

    if (
      sessionStorage.pageDetails != undefined &&
      JSON.parse(sessionStorage.pageDetails)["files"]
    ) {
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["files"] = pageNumber;
      sessionStorage.setItem("pageDetails", JSON.stringify(oldData));
    }

    if (pageNumber <= this.details.TOTALPAGES && pageNumber >= 1) {
      this.pageNumber = pageNumber;
      this.fetchFiles();
    }
  }

  getTypeName(item, itemType) {
    let ret = "";
    if (itemType == 2) {
      // quote file types
      const inList = this.quoteTypes.find(t => t.id == item.TYPE);
      if (inList) {
        ret = inList.name;
      }
    } else if (itemType == 3) {
      // opportunities file types
      const inList = this.oppTypes.find(t => t.id == item.TYPE);
      if (inList) {
        ret = inList.name;
      }
    }

    return ret;
  }
}
