






































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component } from "vue-property-decorator";
import directives from "@/helpers/directives";
import Datepicker from "@/components/Datepicker.vue";
declare const $: any;
import { v4 as uuidv4 } from "uuid";
import { ApiHelper } from "@/helpers/all";
import moment from "moment";
import { notifier } from "@/models/common";
import { dollarFormat } from "@/helpers/ApiHelper";
import Loader from "@/components/Loader.vue";
import ModalAddDeploymentNote from "@/components/Deployment/ModalAddDeploymentNote.vue";
import ModalAddDeploymentScheduleGroupUserNote from "@/components/Deployment/ModalAddDeploymentScheduleGroupUserNote.vue";
import CalendarView from "@/components/CalendarView.vue";

interface Props {}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    ModalAddDeploymentNote,
    ModalAddDeploymentScheduleGroupUserNote,
    Datepicker,
    CalendarView,
    Loader
  },
  methods: {},
  directives
})
export default class DeploymentSchedule extends TSXComponent<Props, Events> {
  pageData = {
    hasError: false,
    isLoading: false
    // grandTotal: "",
    // taxTotal: "",
    // subTotal: "",
    // priceType: ""
  };
  deploymentId = 0;
  isSingleView = false;
  selectedUserId: any = 0;
  selectedUserName = "";
  selectedUserFullName = "";
  selectedGroupName = "";
  formData = {
    controls: {
      endUser: {
        label: "Name",
        value: ""
      },
      location: {
        label: "Location",
        value: ""
      },
      date: {
        label: "Selected Date",
        value: "",
        formatted: "",
        completedDateFormatted: ""
      },
      products: []
    }
    // summary: {
    //   subTotal: "",
    //   taxTotal: "",
    //   grandTotal: "",
    //   priceType: ""
    // }
  };
  scheduleDate = "";
  initDate = "";
  datePickerKey = uuidv4();
  available: any = {
    start: "",
    end: "",
    daysOfWeek: [],
    duration: {
      hours: "",
      startTime: "",
      startTimePeriod: "",
      startTimeFull: "",
      endTime: "",
      endTimePeriod: "",
      endTimeFull: "",
      full: ""
    }
  };
  disabledDates: string[] = [];
  exceptions: any = [];
  criteriaFilters: any = [];
  isOverride = false;
  blockTimeGroups: any = [];
  timeSlots: any = [];
  selectedTimeSlots: any = [];
  selectedTimeSlotsAll: any = [];
  euDetails: any[] = [];
  timeId = "";
  isCompleted = false;
  availabilitiesList: any = [];
  availabilityId = 0;
  fitAvailabilities: any = [];
  saving = false;
  timeInfo: any = null;
  deploymentParams: any = {};
  activeView: string = "schedule";

  modalAddScheduleGroupUserNote = {
    show: false,
    data: {
      deploymentId: 0,
      scheduleGroupId: 0,
      userId: 0,
      userFullName: "",
      note: ""
    }
  };

  $refs!: {
    calendarView: CalendarView
  }

  async created() {
    this.selectedUserId =
      Number.parseInt(this.$route.params.userId || "", 10) || 0;
    if (this.selectedUserId) {
      this.isSingleView = true;
    }

    this.fetchData();
  }

  async fetchData() {
    this.pageData.isLoading = true;
    const query = this.$route.query;
    const lockUnscheduledUsers = query.lockUnscheduledUsers || 0;
    const isShowAll = "isShowAll" in query ? query.isShowAll : 1;
    const euFilters = { ...query };
    delete euFilters.isShowAll;
    delete euFilters.lockUnscheduledUsers;

    const response = await ApiHelper.callApi("post", {
      controller: "Deployments",
      FunctionName: "portalView",
      deploymentUUID: this.$route.params.uuid || "",
      varId: this.$route.params.aID,
      lockUnscheduledUsers,
      isShowAll,
      euFilters
    });
    if (response.STATUS == 1) {
      this.availabilitiesList = response.availabilitiesList || [];
      this.deploymentParams = JSON.parse(
        response.deploymentDetails.DEPLOYMENTPARAMS || {}
      );

      // let gTotal = 0;
      // let gSubTotal = 0;
      // const gTax = 0;
      this.euDetails = (response.EUDETAILS || []).map(euItem => {
        const PRODUCTS = euItem.PRODUCTS || [];

        // let total = 0;
        // let subTotal = 0;
        let quantity = 0;
        PRODUCTS.map(aItem => {
          quantity = quantity + aItem.ALLOCATED || 0;
          // aItem.TOTAL = aItem.POCUSTOMERPRICE * aItem.ALLOCATED;
          // aItem.TOTALFORMATTED = ApiHelper.dollarFormat(aItem.TOTAL);
          // total = total + aItem.TOTAL || 0;
          // gTotal = gTotal + aItem.TOTAL || 0;
          // aItem.SUBTOTAL = aItem.POCUSTOMERPRICE * aItem.ALLOCATED;
          // aItem.SUBTOTALFORMATTED = ApiHelper.dollarFormat(aItem.SUBTOTAL);
          // subTotal = subTotal + aItem.SUBTOTAL || 0;
          // gSubTotal = gSubTotal + aItem.SUBTOTAL || 0;
          return aItem;
        });
        euItem.PRODUCTS = PRODUCTS;
        // euItem.TOTAL = total;
        // euItem.TOTALFORMATTED = ApiHelper.dollarFormat(total);
        // euItem.SUBTOTAL = subTotal;
        // euItem.SUBTOTALFORMATTED = ApiHelper.dollarFormat(subTotal);
        euItem.QUANTITY = quantity;
        // this.pageData.priceType = euItem.ACCTPRICETYPESTR || "";
        return euItem;
      });
      // this.pageData.grandTotal = ApiHelper.dollarFormat(gTotal);
      // this.pageData.subTotal = ApiHelper.dollarFormat(gSubTotal);
      // this.pageData.taxTotal = ApiHelper.dollarFormat(gTax);

      this.deploymentId = response.DEPLOYMENTID || 0;
      if (this.euDetails.length) {
        let endUserId = 0;
        if (this.selectedUserId) {
          endUserId = this.selectedUserId;
        } else {
          const firstEu: any = this.euDetails[0] || {};
          endUserId = firstEu.USERID;
        }

        // this.available = response.availability.available || {};
        // this.exceptions = response.availability.exceptions || [];
        this.blockTimeGroups = response.blockTimeGroups || [];
        this.selectedTimeSlotsAll = response.selectedTimeSlots || [];
        // this.selectedTimeSlots = (response.selectedTimeSlots || []).filter(
        //   item => (item.ENDUSERID || 0) != endUserId
        // );

        // this.timeSlots = (this.available.duration.timeSlots || []).map(
        //   item => ({
        //     id: uuidv4(),
        //     picked: false,
        //     ...item
        //   })
        // );

        // // specify init dates
        // const startDate = this.available.start || "";
        // if (startDate) {
        //   this.initDate = startDate;
        // }
        await this.changeEndUser(endUserId);
      }
    } else {
      ApiHelper.showErrorMessage(
        response.STATUSMESSAGE || "Something was wrong"
      );
    }
    this.pageData.isLoading = false;
  }

  resetCurrentAvailability() {
    this.available = {};
    this.exceptions = [];
    this.timeSlots = [];
    this.scheduleDate = "";
    this.isOverride = false;
    this.initDate = "";
    this.timeId = "";
    this.fitAvailabilities = [];
  }

  loadAvailability(type = "byEUId", id) {
    // reset
    this.resetCurrentAvailability();

    if (type == "byEUId") {
      this.availabilityId = 0;

      if (!id || !this.availabilitiesList.length) return;

      const eu = this.euDetails.find((item: any) => item.USERID == id);
      if (!eu) return;

      // specify related availabilities based on criteria
      for (const item of this.availabilitiesList) {
        item.disabled = false;
        if (!item.criteriaFilters.length) continue;

        let matchCnt = 0;
        for (const filter of item.criteriaFilters) {
          if ((filter.value || []).length && filter.value[0] != "") {
            let dataKey = `CUSTOMDATA${filter.customFieldId}`;
            if (
              [
                "requestName",
                "submitterName",
                "submitterEmail",
                "leaseCoordinator"
              ].includes(filter.customFieldKey)
            ) {
              dataKey = filter.customFieldKey.toUpperCase();
            }
            const dataVal = eu[dataKey] || "";

            if (dataVal && filter.value.includes(dataVal)) {
              matchCnt += 1;
            }
          }
        }

        // allow selecting if match with all filters
        if (matchCnt == item.criteriaFilters.length) {
          item.disabled = false;
          this.fitAvailabilities.push(item.available.availabilityId);
        } else {
          item.disabled = true;
        }
      }
      if (this.fitAvailabilities.length) {
        // auto select first one in the list
        this.availabilityId = this.fitAvailabilities[0];
      }

      // if cannot specify a related availability based on criteria, try to set first one in the list
      if (!this.availabilityId && this.availabilitiesList.length) {
        // this.availabilityId = this.availabilitiesList[0].available.availabilityId;
        this.availabilityId = 0;
        // auto select an availability has no any criteria
        const availabilitiesNoCriteria = this.availabilitiesList.filter(
          item => !(item.criteriaFilters || []).length
        );
        if (availabilitiesNoCriteria.length) {
          this.availabilityId =
            availabilitiesNoCriteria[0].available.availabilityId;
        }
      }
    }

    // assign available/ exceptions/ criteria filters
    const related = this.availabilitiesList.find(
      item => item.available.availabilityId == this.availabilityId
    );
    if (related) {
      this.available = related.available || {};
      this.exceptions = related.exceptions || [];
      this.criteriaFilters = related.criteriaFilters || [];
      if (this.available.duration) {
        this.timeSlots = (this.available.duration.timeSlots || []).map(
          item => ({
            id: uuidv4(),
            picked: false,
            isPast: false,
            ...item
          })
        );
      }
      // specify init dates
      const startDate = this.available.start || "";
      if (startDate) {
        this.initDate = startDate;
      }
    }
  }

  changingEndUser = false;
  async changeEndUser(userId) {
    this.changingEndUser = true;
    // this.isLock = false;
    // reset
    this.availabilityId = 0;
    this.initDate = "";
    this.scheduleDate = "";

    this.selectedUserId = userId;
    const findEu: any = this.euDetails.find(
      (item: any) => item.USERID == userId
    );
    if (findEu) {
      this.formData.controls.endUser.value = findEu.USERID || "";
      this.selectedUserName = this.getEuName(findEu.FIRSTNAME, findEu.LASTNAME);
      this.selectedUserFullName = [
        findEu.FIRSTNAME || "",
        findEu.LASTNAME || ""
      ].join(" ");
      this.selectedGroupName = findEu.SCHEDULEGROUPNAME || "";
      this.formData.controls.location.value = findEu.EUADDRESS || "";
      const arrProducts = findEu.PRODUCTS || [];
      const parentProducts = arrProducts.filter(
        item => item.POINCLUDED == 0 || (item.POINCLUDED == 1 && !item.ISCONFIG)
      );
      parentProducts.map(item => {
        // const subProducts = arrProducts.filter((subItem) => item.POLIID == subItem.PARENTLIID);
        // item.subProducts = subProducts;
        // item.poCustomerPriceFormatted = dollarFormat(item.POCUSTOMERPRICE);
        const configs = item.CONFIGS || [];
        for (const t of configs) {
          // t.poCustomerPriceFormatted = dollarFormat(t.POCUSTOMERPRICE);
          t.ALLOCATED = item.ALLOCATED;
          // t.TOTAL = t.POCUSTOMERPRICE * t.ALLOCATED;
          // t.TOTALFORMATTED = ApiHelper.dollarFormat(t.TOTAL);
          // t.SUBTOTAL = t.POCUSTOMERPRICE * t.ALLOCATED;
          // t.SUBTOTALFORMATTED = ApiHelper.dollarFormat(t.SUBTOTAL);
        }
        item.subProducts = configs;
        return item;
      });
      this.formData.controls.products = parentProducts;

      if (findEu.SCHEDULEGROUPID > 0) {
        const params = JSON.parse(findEu.PARAMS || "{}");
        const groupUserNotes = params.groupUserNotes || [];
        const userNote = groupUserNotes.find(gun => gun.USERID === userId);

        this.modalAddScheduleGroupUserNote.data = {
          deploymentId: this.deploymentId,
          scheduleGroupId: findEu.SCHEDULEGROUPID,
          userId: userId,
          userFullName: this.selectedUserFullName,
          note: userNote ? userNote.NOTE : ""
        };
      }
    }

    const scheduleATimeRes = await ApiHelper.callApi("post", {
      controller: "Deployments",
      FunctionName: "portalGetScheduleATime",
      deploymentId: this.deploymentId || 0,
      endUserId: userId,
      varId: this.$route.params.aID
    });
    if (scheduleATimeRes.STATUS == 1) {
      // update blockTimeGroups, and selectedTimeSlotsAll
      this.blockTimeGroups = scheduleATimeRes.blockTimeGroups || [];
      this.selectedTimeSlotsAll = scheduleATimeRes.selectedTimeSlots || [];
      // this.availabilitiesList = scheduleATimeRes.availabilitiesList || [];
      // ignore selected timeSlots of this end user
      this.selectedTimeSlots = this.selectedTimeSlotsAll.filter(
        item => (item.ENDUSERID || 0) != userId
      );

      const scheduleATime = scheduleATimeRes.scheduleATime || [];
      const timeInfo = scheduleATime.find(item => item.ENDUSERID == userId);
      if (timeInfo) {
        this.timeInfo = timeInfo;
        this.availabilityId = timeInfo.AVAILABILITYID || 0;
        if (this.availabilityId) {
          this.loadAvailability("byAvailabilityId", this.availabilityId);
        }

        // apply if selected a schedule date
        this.scheduleDate = timeInfo.SCHEDULEDATE;
        this.isOverride = timeInfo.ISOVERRIDE || 0 ? true : false;
        this.initDate = this.scheduleDate;
        this.pageData.isLoading = true;
        setTimeout(() => {
          this.pageData.isLoading = false;
        }, 100);
        // datePicker.setDate(this.scheduleDate);

        // if selected a time slot
        const selectedArr: any = [];
        const startFull = `${timeInfo.STARTTIME} ${timeInfo.STARTTIMEPERIOD}`;
        const endFull = `${timeInfo.ENDTIME} ${timeInfo.ENDTIMEPERIOD}`;
        if (startFull) {
          selectedArr.push(startFull);
        }
        if (endFull) {
          selectedArr.push(endFull);
        }
        const selectedText = selectedArr.join(" - ");
        const inList = this.timeSlots.find(
          item => item.text.toUpperCase() == selectedText.toUpperCase()
        );
        if (inList) {
          this.timeId = inList.id;
          this.isCompleted = true;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }

      if (this.initDate) {
        this.scheduleDate = this.initDate;
      }
      if (this.scheduleDate) {
        const selectedDate = moment(this.scheduleDate);
        if (selectedDate.isValid()) {
          this.formData.controls.date.completedDateFormatted = selectedDate.format(
            "dddd MMMM D, YYYY"
          );
        }
        this.dayChange(this.scheduleDate);
      }
      // change to completed status if eu in a group
      if (findEu.SCHEDULEGROUPNAME) {
        this.isCompleted = true;
      }
    }

    // load availabilty by endUserId based on end user criteria if not saved before
    if (!this.availabilityId) {
      this.loadAvailability("byEUId", userId);
      if (this.availabilityId && this.initDate) {
        // after detect an availability, and has a init date, auto set schedule date
        this.scheduleDate = this.initDate;
        this.dayChange(this.scheduleDate);
      }
    }
    this.updateDatePicker();
    this.changingEndUser = false;
  }

  updateScheduleGroupUserNote({ scheduleGroupId, userId, note }) {
    this.modalAddScheduleGroupUserNote.show = false;
    this.modalAddScheduleGroupUserNote.data.note = note;
    // set updated note to schedule group user
    const findEuIndex: any = this.euDetails.findIndex(
      (item: any) => item.USERID == userId
    );

    if (findEuIndex >= 0) {
      const params = JSON.parse(this.euDetails[findEuIndex].PARAMS || "{}");
      const groupUserNotes = params.groupUserNotes || [];

      let foundUserNote = groupUserNotes.find(gun => gun.USERID === userId);

      if (foundUserNote) {
        foundUserNote.NOTE = note;
      } else {
        groupUserNotes.push({
          USERID: userId,
          NOTE: note
        });
      }

      params.groupUserNotes = groupUserNotes;

      this.euDetails[findEuIndex].PARAMS = JSON.stringify(params);
    }
  }

  async addSchedule(data: any = {}, editedfrom: string = 'schedule') {
    let valid = true;
    let message = "";

    if (editedfrom == 'calendar') {
      this.scheduleDate = data.selectedDate;
      this.selectedUserId = data.endUserId;
    }
    if (!this.scheduleDate) {
      message = "Please select a date";
      valid = false;
    }

    let selectedTime: any = {};
    if (editedfrom == 'calendar') {
      selectedTime = data.timeSlot;
    } else {
      selectedTime = this.timeSlots.find(item => item.id == this.timeId);
    }

    if (!selectedTime) {
      message = "Please select time";
      valid = false;
    }
    // make sure scheduleDate is not in disabledDates list
    if (this.disabledDates.includes(this.scheduleDate)) {
      message = "Selected date is disabled";
      valid = false;
    }

    if (!valid) {
      ApiHelper.showErrorMessage(message);
      return;
    }

    try {
      this.saving = true;
      const response = await ApiHelper.callApi("post", {
        Controller: "Deployments",
        FunctionName: "portalUpdateScheduleATime",
        deploymentId: this.deploymentId,
        availabilityId: this.availabilityId,
        varId: this.$route.params.aID,
        endUserId: this.selectedUserId,
        timeInfo: {
          scheduleDate: this.scheduleDate,
          startTime: selectedTime.start,
          endTime: selectedTime.end,
          startTimePeriod: selectedTime.startTimePeriod,
          endTimePeriod: selectedTime.endTimePeriod,
          isOverride: this.isOverride ? 1 : 0
        }
      });

      if (response.STATUS == 1) {
        this.saving = false;
        // update for currentItem
        // this.currentItem.SCHEDULEDATE = this.scheduleDate;
        // this.currentItem.ISOVERRIDE = this.isOverride ? 1 : 0;
        // this.currentItem.STARTTIME = selectedTime.start;
        // this.currentItem.STARTTIMEPERIOD = selectedTime.startTimePeriod;
        // this.currentItem.ENDTIME = selectedTime.end;
        // this.currentItem.ENDTIMEPERIOD = selectedTime.endTimePeriod;

        const findEndUser: any = this.euDetails.find(
          (item: any) => item.USERID == this.selectedUserId
        );
        if (findEndUser) {
          findEndUser.SCHEDULEDATE = this.scheduleDate;
          findEndUser.SCHEDULEDATEFORMATTED = moment(this.scheduleDate).format("YYYY-MM-DD");
          findEndUser.STARTTIME = selectedTime.start;
          findEndUser.STARTTIMEFORMATTED = moment(selectedTime.start + " " + selectedTime.startTimePeriod, 'hh:mm A').format("HH:mm:ss");
          findEndUser.ENDTIME = selectedTime.end;
          findEndUser.ENDTIMEFORMATTED = moment(selectedTime.end + " " + selectedTime.endTimePeriod, 'hh:mm A').format("HH:mm:ss");
          findEndUser.STARTTIMEPERIOD = selectedTime.startTimePeriod;
          findEndUser.ENDTIMEPERIOD = selectedTime.endTimePeriod;

          const selectedDate = moment(this.scheduleDate);
          if (selectedDate.isValid()) {
            this.formData.controls.date.completedDateFormatted = selectedDate.format(
              "dddd MMMM D, YYYY"
            );
          }
        }
        this.isCompleted = true;
        if (editedfrom == 'calendar') {
          this.$refs.calendarView.showCalendar();
        }
        ApiHelper.showSuccessMessage(response.STATUSMESSAGE);
      } else {
        this.saving = false;
        ApiHelper.showErrorMessage(
          response.STATUSMESSAGE || "Something was wrong"
        );
      }
    } catch (err) {
      this.saving = false;
      ApiHelper.showErrorMessage("Something was wrong");
      console.log(err);
    }
  }

  datePickerFilter(date, view) {
    if (view == "day") {
      const datePicker: any = this.$refs.datePicker;
      const dateFormatted = datePicker.formatDate(date);
      let valid = true;

      // set available days based on "days of week" availability settings
      if ((this.available.daysOfWeek || []).length) {
        const dayMap = {
          "0": "sun",
          "1": "mon",
          "2": "tue",
          "3": "wed",
          "4": "thu",
          "5": "fri",
          "6": "sat"
        };
        if (!this.available.daysOfWeek.includes(dayMap[`${date.getDay()}`])) {
          this.disabledDates.push(dateFormatted);
          valid = false;
        }
      }
      if (!valid) {
        // disable selection this day
        return false;
      }

      // not allow to schedule in the past
      const scheduleDateInt = moment(date).format("YYYYMMDD");
      const todayInt = moment().format("YYYYMMDD");

      if (todayInt > scheduleDateInt) {
        valid = false;
        this.disabledDates.push(dateFormatted);
      }

      if (!valid) {
        // disable selection this day
        return false;
      }

      // disable selection based on exception settings of availability
      if (this.exceptions.length) {
        for (const item of this.exceptions) {
          if (
            (item.betweenTimes.start && !item.betweenTimes.end) ||
            (item.betweenTimes.end && !item.betweenTimes.start)
          ) {
            // ignore case just set a start, or end time
            continue;
          }

          // disable selecting this day if availability time is between this exception time range
          if (
            this.available.duration &&
            item.betweenTimes.start &&
            item.betweenTimes.end
          ) {
            const timeFormat = "hh:mm A";
            const eStartTime = moment(
              `${item.betweenTimes.startFull}`.toUpperCase(),
              timeFormat
            );
            const eEndTime = moment(
              `${item.betweenTimes.endFull}`.toUpperCase(),
              timeFormat
            );
            const availableStartTime = moment(
              `${this.available.duration.startTimeFull}`.toUpperCase(),
              timeFormat
            );
            const availableEndTime = moment(
              `${this.available.duration.endTimeFull}`.toUpperCase(),
              timeFormat
            );

            if (
              eStartTime.isValid() &&
              eEndTime.isValid() &&
              availableStartTime.isValid() &&
              availableEndTime.isValid() &&
              availableStartTime.isSameOrAfter(eStartTime) &&
              availableStartTime.isSameOrBefore(eEndTime) &&
              availableEndTime.isSameOrAfter(eStartTime) &&
              availableEndTime.isSameOrBefore(eEndTime)
            ) {
              // check if need to disable this day at next step
            } else {
              // allow selecting this day
              continue;
            }
          }

          // if has a date range exception
          const startDate = item.start || "";
          let endDate = item.end || "";
          if (startDate && !endDate) {
            // set exception in a day (just using startDate)
            endDate = startDate;
          }
          if (
            new Date(dateFormatted) >= new Date(startDate) &&
            new Date(dateFormatted) <= new Date(endDate)
          ) {
            this.disabledDates.push(dateFormatted);
            valid = false;
            break;
          }
        }
        if (!valid) {
          // disable selection this day
          return false;
        }
      }

      // disable selection based on blockTimeGroups if turn off "override" option
      if (!this.isOverride) {
        const availabilityId = this.availabilityId || 0;
        let blockTimeGroupsByAvailability = this.blockTimeGroups;
        if (availabilityId) {
          blockTimeGroupsByAvailability = this.blockTimeGroups.filter(
            t => (t.AVAILABILITYID || 0) == availabilityId
          );
        }
        for (const item of blockTimeGroupsByAvailability) {
          if (!item.STARTDATE || !item.ENDDATE) {
            continue;
          }
          if (
            new Date(dateFormatted) >= new Date(item.STARTDATE) &&
            new Date(dateFormatted) <= new Date(item.ENDDATE)
          ) {
            valid = false;
            this.disabledDates.push(dateFormatted);
            break;
          }
        }
        if (!valid) {
          // disable selection this day
          return false;
        }
      }
    }
  }

  dayChange(selected) {
    const selectedDate = moment(selected);
    if (selectedDate.isValid()) {
      this.formData.controls.date.value = selectedDate.format("yyyy-mm-dd");
      this.formData.controls.date.formatted = selectedDate.format(
        "dddd MMMM D, YYYY"
      );
    }

    const availabilityId = this.availabilityId || 0;
    let selectedTimeSlotsByAvailability = this.selectedTimeSlots || [];
    if (availabilityId) {
      selectedTimeSlotsByAvailability = selectedTimeSlotsByAvailability.filter(
        t => (t.AVAILABILITYID || 0) == availabilityId
      );
    }

    // duration start/ end time from availability
    if (this.available.duration) {
      // not allow to schedule in the past
      const scheduleDateInt = moment(selected).format("YYYYMMDD");
      const todayInt = moment().format("YYYYMMDD");
      let isPast = false;
      if (todayInt > scheduleDateInt) {
        isPast = true;
      }

      for (const item of this.timeSlots) {
        item.picked = false;
        item.isPast = false;

        // not allow to schedule in the past
        if (isPast) {
          item.isPast = true;
        }

        // check in selected time slots
        const inList = selectedTimeSlotsByAvailability.filter(
          t =>
            t.SELECTEDTEXT.toUpperCase() == item.text.toUpperCase() &&
            new Date(selected) >= new Date(t.STARTDATE) &&
            new Date(selected) <= new Date(t.ENDDATE)
        );

        if (inList.length) {
          // need to check if has spots setting
          // if no spots setting, or selected time slots number is over the spots setting
          const spots = parseInt(this.available.spots || 0);
          if (!spots || (spots && inList.length >= spots)) {
            item.picked = true;
          }
        }

        // check exception time slots
        if (!item.picked && this.exceptions.length) {
          const timeFormat = "hh:mm A";
          const startTime = moment(
            `${item.start} ${item.startTimePeriod}`,
            timeFormat
          );
          const endTime = moment(
            `${item.end} ${item.endTimePeriod}`,
            timeFormat
          );

          for (const t of this.exceptions) {
            const eStartDate = t.start || "";
            let eEndDate = t.end || "";
            if (eStartDate && !eEndDate) {
              // set exception in a day (just using startDate)
              eEndDate = eStartDate;
            }
            if (
              !(
                new Date(selected) >= new Date(eStartDate) &&
                new Date(selected) <= new Date(eEndDate)
              )
            ) {
              // ignore if not in exception date range
              continue;
            }

            if (t.betweenTimes.start == "" && t.betweenTimes.end == "") {
              // ignore if exception has not a time range
              continue;
            }

            const eStartTime = moment(
              `${t.betweenTimes.startFull.toUpperCase()}`,
              timeFormat
            );
            const eEndTime = moment(
              `${t.betweenTimes.endFull.toUpperCase()}`,
              timeFormat
            );
            if (!eStartTime.isValid() || !eEndTime.isValid()) {
              continue;
            }

            // disable picking if time slot is in exception time range
            // case time slot is in exception time range
            if (
              startTime.isSameOrAfter(eStartTime) &&
              startTime.isSameOrBefore(eEndTime) &&
              endTime.isSameOrAfter(eStartTime) &&
              endTime.isSameOrBefore(eEndTime)
            ) {
              item.picked = true;
              break;
            }
            // case exception time range is in time slot range (between start and end of time slot)
            if (
              !item.picked &&
              (eStartTime.isSameOrAfter(startTime) &&
                eStartTime.isSameOrBefore(endTime)) &&
              (eEndTime.isSameOrAfter(startTime) &&
                eEndTime.isSameOrBefore(endTime))
            ) {
              item.picked = true;
              break;
            }
          }
        }
      }
    }
  }

  overrideChange() {
    if (!this.isOverride && this.timeId) {
      const selected = this.timeSlots.find(item => item.id == this.timeId);
      if (selected && selected.picked) {
        // if picked for other user, reset the selection
        this.timeId = "";
      }
    }
    this.updateDatePicker();
  }

  updateDatePicker() {
    const datePicker: any = this.$refs.datePicker;
    if (!datePicker) return;

    datePicker.destroy();
    this.disabledDates = [];
    if (this.scheduleDate) {
      this.initDate = this.scheduleDate;
    }
    this.datePickerKey = uuidv4();
    this.$nextTick().then(() => {
      // unselect time slot if the selected date in disabled dates list
      if (this.scheduleDate && this.disabledDates.includes(this.scheduleDate)) {
        this.scheduleDate = "";
        this.timeId = "";
      }
      if (
        !this.scheduleDate &&
        this.initDate &&
        !this.disabledDates.includes(this.initDate)
      ) {
        this.scheduleDate = this.initDate;
      }
    });
  }

  getCriteriaValues(endUserId = 0) {
    const id =
      this.isSingleView && this.selectedUserId
        ? this.selectedUserId
        : endUserId;
    if (!id) return "";

    let ret = [];
    const inList: any = this.euDetails.find((item: any) => item.USERID == id);
    if (inList) {
      ret = (inList.CRITERIA || [])
        .filter(item => item.CUSTOMVALUE != "")
        .map(item => `${item.CUSTOMFIELDNAME}: ${item.CUSTOMVALUE}`);
    }

    const splitter = this.isSingleView ? ", " : " | ";
    return ret.join(splitter);
  }

  // isLockForm() {
  //   return (
  //     (!this.availabilitiesList.length ||
  //       !this.availabilitiesList.find(item => !item.disabled)) &&
  //     !this.pageData.isLoading
  //   );
  // }

  isLockedSchedule(availabilityId = 0) {
    let ret = false;
    const lockedSchedule = this.deploymentParams.lockedSchedule || 0;
    const lockSelect = this.deploymentParams.lockSelect || 0;
    const lockAvailability =
      (this.deploymentParams.lockAvailability || "") != ""
        ? this.deploymentParams.lockAvailability.split(",")
        : [];
    const lockOnDate = this.deploymentParams.lockOnDate || 0;
    const lockDate = this.deploymentParams.lockDate || "";
    if (
      lockedSchedule &&
      (!lockSelect || lockAvailability.includes(`${availabilityId}`))
    ) {
      // set lock, need to check if lock now or on a specific date onward
      if (
        !lockOnDate ||
        (lockDate &&
          moment(lockDate).isValid() &&
          new Date(moment().format("MM/DD/YYYY")) >=
            new Date(moment(lockDate).format("MM/DD/YYYY")))
      ) {
        // detect locked schedule
        ret = true;
      }
    }

    return ret;
  }

  modalAddNote = {
    show: false
  };

  getEuName(firstName, lastName) {
    const names: string[] = [];
    if (lastName) {
      names.push(lastName);
    }
    if (firstName) {
      names.push(firstName);
    }
    return names.join(", ");
  }

  get hasScheduledATime() {
    if (!this.selectedUserId) return false;
    let ret = false;
    const eu: any = this.euDetails.find(
      (item: any) => item.USERID == this.selectedUserId
    );
    if (!eu) {
      return false;
    }
    if ((eu.SCHEDULEDATE || "") != "") {
      ret = true;
    }

    return ret;
  }
}
