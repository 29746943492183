

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import { QuoteAPIRepo } from "@/repos/QuoteAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "@/models/common";
import { microserviceQBOURL } from "@/models/envVars";
import ExportModal, { ExportFormat } from "@/components/ExportModal.vue";
import { UserAPIRepo } from "@/repos/UserAPIRepo";
import ConfirmRemoveItemModal from "@/components/ConfirmRemoveItemModal.vue";
import draggable from "vuedraggable";
import GlobalTagSelect from "@/components/GlobalTagSelect.vue";
import directives from "@/helpers/directives";
import {
  dollarFormat,
  getCatName,
  getEUAddress,
  priceTypeStr
} from "@/helpers/ApiHelper";
import PageTitle from "@/components/pageTitle.vue";
import ApiExternal from "@/components/ApiExternal.vue";
import { ApiHelper } from "@/helpers/all";
import ModalEditQuoteDataField from "@/components/Quote/ModalEditQuoteDataField.vue";
import SubQuoteEndUserCustomData from "@/components/Quote/SubQuoteEndUserCustomData.vue";
import ProductCatTooltip from "@/components/ProductCatTooltip.vue";

declare const $: any;
declare const dataURL: string;
declare const Stripe: any;
declare function require(params: any): any;
declare const htmlCheck: Function;
declare const htmlParse: Function;
declare const getRouteData: Function;

const Handlebars = require("handlebars/runtime.js");
const quoteRepo = new QuoteAPIRepo();
const userRepo = new UserAPIRepo();

@Component({
  inheritAttrs: false,
  components: {
    ExportModal,
    ConfirmRemoveItemModal,
    draggable,
    GlobalTagSelect,
    PageTitle,
    ApiExternal,
    ModalEditQuoteDataField,
    SubQuoteEndUserCustomData,
    ProductCatTooltip
  },
  directives,
  methods: {
    getEUAddress,
    priceTypeStr,
    getCatName
  }
})
export default class QuoteDetails extends TSXComponent<void> {
  $root: any;
  loading = false;
  exportModalVisible = false;
  currentRole = sessionStorage.getItem("userRole");
  quoteTemplates: {
    TEMPLATEID?: number | undefined;
    TEMPLATELOCATION?: string | undefined;
    ACCOUNTID?: number | undefined;
    TEMPLATEDESC?: string | undefined;
    TEMPLATENAME?: string | undefined;
    TEMPLATETYPE?: number | undefined;
    PREVIEWIMAGE?: string | undefined;
    TEMPLATE_SYSTEM?: number | undefined;
  }[] = [];
  customerQuotetemplate: number | undefined = 0;
  selectedQuoteContracts = "";
  bigDealId: string | undefined = "";
  quoteItems: any = [];
  details: any = {
    QUOTEDETAILS: {
      QUOTEID: "",
      QDATETIME: "",
      QOPEN: "",
      SPECIALPRICINGID: "",
      CUSTOMER_PAYMENT: false
    },
    TOTALAMOUNTFORMATTED: "",
    TOTALALERTS: "1",
    VARCONTACT: {
      ContactName: ""
    }
  };
  quoteId = 0;
  onExport = {
    templateid: 0,
    saveAsDefault: 0,
    showOnExport: 0,
    pricingid: "",
    subTempName: "",
    advanced: {},
    customMessages: {}
  };
  confirmRemoveModalVisible = false;
  globalTags: any = [];
  stripeObj: any = {};
  stripeToken = "";
  stripeLast4 = "";
  cardName = "";
  cardNumber = "";
  expMoth = "";
  expyear = "";
  cvc = "";
  cardpo = "";
  stripeError = 0;
  cardUpdated = 0;
  poPay = 0;
  creditCardSec = 1;
  poNumberSec = 0;
  poNumberError = 0;
  PaymentText = "";
  useCCPayment = false;
  customSourceData = 0;
  orderContractNumber: any = [];
  orderContractsID: number[] = [];
  selectedOrderContracts = "";
  NASPOcontract = "";
  quoteBigDealNumber = "";
  quoteParams: any = {};
  paymentConfirmID = "";
  paymentCaptured = 0;
  stripeCustomerId = "";
  stripeCardId = "";
  departmentId = "";
  hpQuoteNumber = "";
  agency = "";
  programOffice = "";
  deliveryCenter = "";
  bigDealNumber = "";

  apiExternalModalVisible = false;
  apiLoading = false;
  pType = "quotes";
  showModalEditQuoteDataField = false;
  splitBySubQuoteId: any = [];
  euCustomDataVisible = false;
  selectedSubQuote: any = {};
  approveEmail: any = "";
  productCatTTVisibleIndex = "";
  acctPriceType = 0;
  acctPriceTypeStr = "";
  customPriceTypeStr: string | undefined = undefined;
  configCategory: any = {};

  isPortalCustomer: number = 1;

  handleEmailUpdate(updatedEmail) {
    this.approveEmail = updatedEmail;
  }

  saveAsDefaultView = {};

  async created() {
    this.isPortalCustomer = this.$root.isPortalCustomer();
    this.quoteId = parseInt(this.$route.params.id);

    const PORTALINFO = JSON.parse(localStorage.getItem('PORTALINFO') || '{}') || {};
    const TABOPTIONS = JSON.parse(PORTALINFO.TABOPTIONS || "[]") || [];
    const optionConfig = TABOPTIONS.find((item) => item.key == 'Quote_Details');
    if(optionConfig) {
      (optionConfig.options || []).map((item) => {
        this.saveAsDefaultView[item.key] = item.checked ? 1 : 0;
      });
    }

    if (this.$route.query.previewhtml) {
      const html = await this.quoteDetailsHtml();
      if (!html) return;

      // replace entire page html
      document.open();
      document.write(html);
      document.close();
    }
    // if (this.currentRole != "Reseller") {
    //   this.stripeLoad();
    // }
    this.getQuotes();

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.configCategory = response.data.configCategory || {};
  }
  listPageRedirection() {
    this.$router.push({ name: "Quotes" });
  }
  async getQuotes() {
    this.loading = true;
    // var apiObj = {
    //   controller: "Quotes",
    //   Content: "Detailed",
    //   ObjID: this.quoteId,
    //   FunctionName: "View"
    // };
    // var self = this;
    const response: any = await quoteRepo.findOneDetails(this.$route.params.id);
    this.acctPriceType = response.acctPriceType || 0;
    this.acctPriceTypeStr = response.acctPriceTypeStr || "";
    if ("customPriceTypeStr" in response) {
      this.customPriceTypeStr = response.customPriceTypeStr;
    }
    const quoteParams = JSON.parse(response.QUOTEDETAILS.QUOTEPARAMS || "{}");
    this.quoteParams = quoteParams;
    const expandDecimal = quoteParams.expandDecimal || false;
    this.quoteItems = response.QUOTEITEMS || [];
    this.splitBySubQuoteId = response.splitBySubQuoteId || [];
    // this.quoteItems = this.quoteItems.map(item => {
    //   let date = new Date(this.details.QUOTEDETAILS.QDATETIMEFORMATTED);
    //   item.QDATETIMEFORMATTED = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
    //   return item
    // });

    // console.dir(this.details);

    if (!expandDecimal) {
      // change to 2 decimals if "Expand Decimal" is not set
      // normal quote
      this.quoteItems = this.quoteItems.map(item => {
        if ((item.isInclude || 0) == 0) {
          const customerPrice = parseFloat(item.QCUSTOMERPRICE.toFixed(2));
          item.QCUSTOMERPRICE = customerPrice;
          item.QCUSTOMERPRICEFORMATTED = dollarFormat(customerPrice);
        }
        return item;
      });

      // multi quotes
      this.splitBySubQuoteId = this.splitBySubQuoteId.map(item => {
        // check items in subset
        item.items = item.items.map(t => {
          if ((t.isInclude || 0) == 0) {
            const customerPrice = parseFloat(t.QCUSTOMERPRICE.toFixed(2));
            t.QCUSTOMERPRICE = customerPrice;
            t.QCUSTOMERPRICEFORMATTED = dollarFormat(customerPrice);
          }
          return t;
        });
        return item;
      });
    }

    this.details = {
      ...response,
      saveAsDefaultQuoteView : this.saveAsDefaultView,
    };
    this.quoteBigDealNumber =
      typeof this.details["quoteBigDealNumber"] != "undefined"
        ? this.details["quoteBigDealNumber"]
        : "";
    if (this.currentRole == "Customer") {
      await $.getScript("https://js.stripe.com/v2/", function(
        data,
        textStatus,
        jqxhr
      ) {}).fail(function(jqxhr, settings, exception) {
        console.log("Stripe load failes");
      });
      if (this.details.StripePublicKey != "") {
        Stripe.setPublishableKey(this.details.StripePublicKey);
      }
    }
    this.loading = false;
    this.customerQuotetemplate = response.AccountQuoteTemplateId;
    this.quoteTemplates = response.TEMPLATES || [];
    this.bigDealId = response.QUOTEDETAILS!.SPECIALPRICINGID || "";
    //Contract Number
    this.NASPOcontract =
      typeof this.details.QUOTEDETAILS.NASPOCONTRACT != "undefined"
        ? this.details.QUOTEDETAILS.NASPOCONTRACT
        : "";
    if (
      typeof this.details.QUOTEDETAILS.CUSTOMDATACONTRACTNUMBER !=
        "undefined" &&
      this.details.QUOTEDETAILS.CUSTOMDATACONTRACTNUMBER != ""
    ) {
      this.bigDealId = this.details.QUOTEDETAILS.CUSTOMDATACONTRACTNUMBER;
    } else {
      this.bigDealId = this.NASPOcontract;
    }
    this.PaymentText = this.details.PaymentText || "";
    this.useCCPayment = !!this.details.PaymentLast4 || false;
    this.paymentConfirmID = this.details.PaymentConfirmID || "";
    this.paymentCaptured = this.details.captured || 0;
    this.stripeCustomerId = this.details.stripeCustomerId || "";
    this.stripeCardId = this.details.stripeCardId || "";
    this.loading = false;
  }
  async quoteDetailsHtml(): Promise<string | false> {
    try {
      const quoteDetails: any = await quoteRepo.findOneDetails(
        this.$route.params.id,
        {
          ...this.onExport,
          doExport: 1
        }
      );

      // const quoteParams = JSON.parse(
      //   quoteDetails.QUOTEDETAILS.QUOTEPARAMS || "{}"
      // );
      // const expandDecimal = quoteParams.expandDecimal || false;
      // if (!expandDecimal) {
      //   // change to 2 decimals if "Expand Decimal" is not set
      //   quoteDetails.QUOTEITEMS = (quoteDetails.QUOTEITEMS || []).map(item => {
      //     if ((item.isInclude || 0) == 0) {
      //       const customerPrice = parseFloat(item.QCUSTOMERPRICE.toFixed(2));
      //       item.QCUSTOMERPRICE = customerPrice;
      //       item.QCUSTOMERPRICEFORMATTED = dollarFormat(customerPrice);
      //     }
      //     return item;
      //   });
      // }

      const tempPosition1 = quoteDetails.TemplateSetting.PartialPosition1;
      const tempPosition2 = quoteDetails.TemplateSetting.PartialPosition2;
      if (tempPosition1.length > 0) {
        tempPosition1.forEach(item => {
          var quoteDetails_position1 = require("@/templates/quotes/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, quoteDetails_position1);
        });
      }
      if (tempPosition2.length > 0) {
        tempPosition2.forEach(item => {
          var quoteDetails_position2 = require("@/templates/quotes/partials/" +
            item.partialname +
            ".handlebars");
          Handlebars.registerPartial(item.partialname, quoteDetails_position2);
        });
      }

      if (quoteDetails.TemplateSetting.TemplateName != "") {
        const mainTemplate = quoteDetails.TemplateSetting.TemplateName.split(
          "/"
        );
        var template = require("@/templates/quotes/" +
          mainTemplate[mainTemplate.length - 1]);
        const html = template(quoteDetails);
        return html;
      } else {
        return "";
      }
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }

  async htmlDownLoadFn() {
    const html = await this.quoteDetailsHtml();
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      if (!html) return;
      var fileNameExport = `QuoteExport_${this.$route.params.id}.html`;
      downloadFile(fileNameExport, html);
    };
  }

  async print() {
    const html = await this.quoteDetailsHtml();
    if (!html) return;

    printHtml(html);
  }

  async exportPDF() {
    let fileUrl = "";
    try {
      fileUrl = await quoteRepo.exportDetailsPDFFileUrl(
        this.$route.params.id,
        this.onExport
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async exportCSV() {
    let fileUrl = "";
    try {
      fileUrl = await quoteRepo.exportDetailsCSVFileUrl(
        this.$route.params.id,
        this.onExport
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    this.loading = false;
    this.exportModalVisible = false;
    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  /* REQUEST WHITE GLOVE AJAX CALL */
  async requestWhiteGlove() {
    this.apiLoading = true;
    const dataObj = {
      controller: "ExternalSends",
      FunctionName: "smartstores",
      ObjID: this.$route.params.id,
      userRole: sessionStorage.getItem("userRole"),
      aID: this.details["QUOTEDETAILS"]["AID"],
      approverEmail: this.approveEmail
    };
    // var self = this;
    const response = await axios.post(dataURL + "?ReturnType=JSON", dataObj);
    const message = response.data.MESSAGE || "";
    if (response.data.STATUS == 1) {
      // notifier.success("JSON Data Pulled for Quote "+response.data.QUOTEDETAILS.QUOTEID);
      /* self.details.orderStatus.push({
      Id: response.data.OSID,
      Status_Details: response.data.STATRETURN[0].STATUSDETAILS,
      dateTime: response.data.STATRETURN[0].STATDATE,
      emailed: response.data.STATRETURN[0].EMAILED,
      fullName: response.data.STATRETURN[0].USERNAME,
      invoiceID: response.data.STATRETURN[0].INVID,
      number: response.data.STATRETURN[0].REQUESTID,
      showOrderProfit: 0,
      sortTime: response.data.STATRETURN[0].SORTDATE,
      source: response.data.STATRETURN[0].STATSOURCE,
      sourceName: "Manual",
      status: response.data.STATRETURN[0].STATTYPE,
      type: response.data.STATRETURN[0].TYPENAME
      }); */
      /* self.reloadDetails(); */
      if (message) {
        notifier.success(message);
      }
      const LR = response.data.LR || "";
      if (LR) {
        this.quoteParams.SyncSmartStore = 1;
        this.quoteParams.LR = LR;
      }
    } else {
      notifier.alert(message || "Something went wrong");
    }
    this.apiLoading = false;
    this.apiExternalModalVisible = false;
  }

  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for/quotes"
    });
  }

  async updateStatus(type: string) {
    $(".qStatus .dropdown-menu li a").removeClass("active");
    $(".qStatus .dropdown-menu li a[value='" + type + "']").addClass("active");
    this.details.QUOTEDETAILS.STATUS = $(
      ".qStatus .dropdown-menu li a[value='" + type + "']"
    )
      .text()
      .toLowerCase();

    try {
      // this.loading = true;
      var response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "UpdateQuoteStatus",
        selectedIDs: this.$route.params.id,
        qopen: type
      });
      if (response.data.STATUS) {
        notifier.success(
          `Quote ${this.$route.params.id}'s Status Updated Successfully`
        );
        this.details.QUOTEDETAILS.QOPEN = type;
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  showNoteUpdate() {
    if (this.$route.params.id) {
      $(".notes textarea").val(this.details.QUOTEDETAILS.QNOTES);
    }
    $(".notes textarea").focus();
  }

  async updateNotes() {
    const notes = $.trim($(".notes textarea").val());
    var htmlCount = 0;

    if (htmlCheck(notes)) {
      $(".notes textarea").val(htmlParse(notes));
      htmlCount++;
    }
    if (notes != undefined) {
      if (notes != this.details.QUOTEDETAILS.QNOTES) {
        getRouteData({
          controller: "Quotes",
          FunctionName: "UpdateQuoteNotes",
          ObjID: this.$route.params.id,
          Notes: notes
        }).then(function(response, statusText, jqXHR) {});
      }
    } else {
      if (this.details.QUOTEDETAILS.QNOTES != "") {
        $("#idNoteLabel").html(this.details.QUOTEDETAILS.QNOTES);
      }
    }
    this.addRemoveNote();
  }

  async validateCardName() {
    var self = this;
    const nameOnCard = $.trim($("#cc-name").val());

    if (htmlCheck(nameOnCard)) {
      self.cardName = htmlParse(nameOnCard);
      notifier.alert("HTML content found. This content has been removed!");
    }
  }

  addRemoveNote() {
    if ($.trim($(".notes textarea").val())) {
      this.details.QUOTEDETAILS.QNOTES = $.trim($(".notes textarea").val());
      $(".notes.entry").html(
        "<span class='note-label'>Note:</span> " +
          $.trim($(".notes textarea").val())
      );

      if ($(".notes.entry").hasClass("empty")) {
        $(".notes.entry").removeClass("empty");
      }
    } else {
      if (
        this.details.QUOTEDETAILS.QNOTES == "" ||
        $.trim($(".notes textarea").val()) == ""
      ) {
        $(".notes.entry").html("+ Add Note");
        $(".notes textarea").val("");

        if (!$(".notes.entry").hasClass("empty")) {
          $(".notes.entry").addClass("empty");
        }
      }
    }
    $(".notes.entry, .notes.textarea").toggle(200);
  }

  async performExport(
    formats: ExportFormat[],
    templateId,
    updateDefault,
    showOnExport,
    pricingid,
    exportpo?,
    azTempname = "",
    advanced?,
    customMessages = {}
  ) {
    this.onExport = {
      templateid: templateId,
      saveAsDefault: updateDefault ? 1 : 0,
      showOnExport: showOnExport ? 1 : 0,
      pricingid: pricingid,
      subTempName: azTempname,
      advanced: advanced,
      customMessages: customMessages
    };
    // this.loading = true;

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.exportCSV());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.exportPDF());
    }

    exportFns.forEach(fn => fn());
    // this.getQuotes();
    const response: any = await quoteRepo.findOneDetails(this.$route.params.id);
    this.customerQuotetemplate = response.AccountQuoteTemplateId;
    this.details.AccountQuoteAztempname = response.AccountQuoteAztempname || "";
    this.loading = false;
  }

  async removeSelected() {
    var refModal: any = this.$refs.confirmRemoveModal;
    refModal.deleting = true;
    try {
      const successCount = await quoteRepo.removeByIds(
        [this.quoteId.toString()],
        false,
        [],
        this.details["QUOTEDETAILS"]["AID"]
      );
      if (successCount > 0) {
        this.$router.push({ name: "Quotes" });
      }
    } catch (err) {
      refModal.deleting = "error";
    } finally {
      refModal.deleting = false;
      this.confirmRemoveModalVisible = false;
    }
  }

  // convertToOrder() {
  //   $("#convertToOrder")
  //     .get(0)
  //     .click();
  // }

  notHPSource() {
    if (
      this.details.QUOTEDETAILS.SOURCE != 0 &&
      this.details.QUOTEDETAILS.SOURCE != 1
    ) {
      return true;
    }
    return false;
  }
  selectingDisty(disty) {
    if (
      disty.toLowerCase() ==
      this.details.QUOTEDETAILS.VARSOURCE_NAME.toLowerCase()
    ) {
      return true;
    }
    return false;
  }
  getDistyTitle(item) {
    var ret = item.Disty + " Price: ";
    if (item.DistyPrice == 0) {
      ret += "Not Available";
    } else {
      ret += item.DistyPrice_Format;
    }

    ret += "\nAvailability: ";

    if (item.DistyAvailability == 0) {
      ret += "Not Available";
    } else {
      ret += item.DistyAvailability_Format + " units";
    }

    return ret;
  }

  customDataVisible = false;
  fullCustomData: object[] = [];
  customDataLoading = false;
  customDataIndex = -1;
  async showCustomData() {
    if (!this.customDataVisible) {
      this.customDataLoading = true;
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          controller: "Helpers",
          FunctionName: "CustomData",
          DataType: "2",
          Id: this.$route.params.id,
          contractDropdown: 26,
          aId: this.details.QUOTEDETAILS.AID,
          ignoreFieldIds: "26"
        });

        if (response.data.STATUS == 1) {
          this.fullCustomData = [];
          for (var val of response.data.CUSTOMDEFINITION) {
            let tmpValue = response.data.CUSTOMDATA.filter(
              tmp => tmp.CUSTOMFIELDID == val.CUSTOMFIELDID
            );
            this.fullCustomData.push({
              CUSTOMFIELDID: val.CUSTOMFIELDID,
              CUSTOMFIELDNAME: val.CUSTOMFIELDNAME,
              CUSTOMVALUE: tmpValue.length ? tmpValue[0].CUSTOMVALUE : "",
              CUSTOMID: tmpValue.length ? tmpValue[0].CUSTOMID : 0,
              CUSTOMDTYPE: val.CUSTOMDTYPE || 1,
              OPTIONS: val.OPTIONS || []
            });
            this.orderContractNumber = response.data.customContractNumber;
            const selectedContracts = response.data.selectedContractData;
            if (selectedContracts.length) {
              this.selectedQuoteContracts =
                  selectedContracts[0].CUSTOMFIELDOPTIONNAME;
              this.orderContractsID = selectedContracts.map(
                  (val: any) => val.CUSTOMFIELDOPTIONID
              );
            }
          }

          this.globalTags = response.data.globalTags;
          this.selectedGlobalTags = response.data.selectedGlobalTags.map(
            (val: any) => ({
              CUSTOMFIELDOPTIONID: parseInt(val.CUSTOMFIELDOPTIONID),
              CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
            })
          );
          this.selectedGlobalTagsID = this.selectedGlobalTags.map(
            (val: any) => val.CUSTOMFIELDOPTIONID
          );
        }
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.customDataLoading = false;
      }
    }
    this.customDataVisible = !this.customDataVisible;
  }

  showCustomEditBox(index) {
    this.customDataIndex = index;

    setTimeout(() => {
      $(".custom-data-txt")
        .focus()
        .select();
    }, 100);
  }
  hideCustomEditBox() {
    this.customDataIndex = -1;
  }

  showSourceCustomEditBox(val) {
    this.customSourceData = val;
    setTimeout(() => {
      $(".custom-data-source")
        .focus()
        .select();
    }, 100);
  }
  hideSourceCustomEditBox() {
    this.customSourceData = -1;
  }

  async updateCustomData(item) {
    //remove HTML
    if ((item.CUSTOMDTYPE || 1) != 3) {
      item.CUSTOMVALUE = this.removeHTML(item.CUSTOMVALUE);
    }

    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customID: item.CUSTOMID,
        customFieldID: item.CUSTOMFIELDID,
        customValue: item.CUSTOMVALUE,
        Id: this.$route.params.id,
        qQuoteID:
          item.CUSTOMFIELDNAME.toLowerCase() == "source quote id" ? 1 : 0,
        bigDealNo:
          item.CUSTOMFIELDNAME.toLowerCase() == "big deal number" ? 1 : 0,
        fieldTypeId: 2
      });

      if (response.data.STATUS == 1) {
        this.customDataIndex = -1;
        if (item.CUSTOMVALUE == "") {
          //delete this item
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = 0;
        } else if (typeof response.data.NEWCUSTOMID != "undefined") {
          // eslint-disable-next-line require-atomic-updates
          item.CUSTOMID = response.data.NEWCUSTOMID;
        }
        notifier.success(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  async updateCustomSoureData(source) {
    //remove HTML
    source = this.removeHTML(source);
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        customSourceName: source,
        isQuoteDetail: true,
        Id: this.$route.params.id
      });

      if (response.data.STATUS == 1) {
        this.customSourceData = -1;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  selectedGlobalTagsID: number[] = [];
  selectedGlobalTags: object[] = [];
  toggleSelectedGlobalTags(selectedID: number, tagName) {
    const index = this.selectedGlobalTagsID.findIndex(id => id === selectedID);
    if (index === -1) {
      this.selectedGlobalTagsID.push(selectedID);
      this.selectedGlobalTags.push({
        CUSTOMFIELDOPTIONID: selectedID,
        CUSTOMFIELDOPTIONNAME: tagName
      });
      this.saveGlobalTag();
      return;
    }

    this.selectedGlobalTagsID.splice(index, 1);
    this.$delete(this.selectedGlobalTags, index);
    this.saveGlobalTag();
  }

  selectAllGlobalTags() {
    this.resetGlobalTags();
    this.selectedGlobalTagsID = this.globalTags.map(
      (val: any) => val.CUSTOMFIELDOPTIONID
    );
    this.selectedGlobalTags = this.globalTags.map((val: any) => ({
      CUSTOMFIELDOPTIONID: val.CUSTOMFIELDOPTIONID,
      CUSTOMFIELDOPTIONNAME: val.CUSTOMFIELDOPTIONNAME
    }));
    this.saveGlobalTag();
  }

  resetGlobalTags() {
    this.selectedGlobalTags = [];
    this.selectedGlobalTagsID = [];
    this.saveGlobalTag();
  }

  updateGlobalTagList(type, index, tagID) {
    if (this.selectedGlobalTagsID && type == "delete") {
      this.$delete(this.selectedGlobalTagsID, index);
      this.$delete(this.selectedGlobalTags, index);
    }
    this.saveGlobalTag();
  }

  async saveGlobalTag() {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        optionIDs: this.selectedGlobalTagsID.join(","),
        Id: this.$route.params.id,
        action: "save_globalTags",
        fieldTypeId: 2
      });
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  async stripeTest() {
    var $form = $("#posttodetailpop");
    var $this = this;
    var stripeResponseHandler = function(status, response) {
      //Stripe token failure...
      if (response.error) {
        // console.log("response error",response)
        var msg = "card name";
        if (response.error.param == "number") {
          $this.stripeError = 2;
          msg = "card number";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
          msg = "month";
        } else if (response.error.param == "exp_year") {
          $this.stripeError = 4;
          msg = "year";
        } else if (response.error.param == "cvc") {
          $this.stripeError = 5;
          msg = "cvc";
        } else if (response.error.param == "exp_month") {
          $this.stripeError = 3;
        } else {
          $this.stripeError = 1;
        }
        notifier.alert("verification failed due to invalid " + msg);
      } else {
        //Stripe token success!!...
        // console.log("response token",response.id)
        // console.log("response last4",response.card.last4)
        // console.log("response success",response)
        notifier.success("Card details verified successfully..");
        $this.stripeToken = response.id;
        $this.stripeLast4 = response.card.last4;
        $this.stripeError = 0;
        $this.cardUpdated = 0;
      }
    };
    // card = elements.create('card');
    // card.mount($form);
    Stripe.card.createToken($form, stripeResponseHandler);
  }

  async stripeLoad() {
    //Stripe.js
    await $.getScript("https://js.stripe.com/v2/", function(
      data,
      textStatus,
      jqxhr
    ) {
      //Loaded
      // let stripe = Stripe(`pk_test_mFNJZvmWx82AbSnQFLGfuCLf`);
      let stripe = Stripe.setPublishableKey(`pk_test_mFNJZvmWx82AbSnQFLGfuCLf`);
    }).fail(function(jqxhr, settings, exception) {
      console.log("stripe load failes");
    });
  }

  async updateCustomerQuote(submitFrom) {
    var purchased = false;
    this.loading = true;
    try {
      var payObj = {
        controller: "Orders",
        FunctionName: "OrderFromRequestQuote",
        aID: this.details.QUOTEDETAILS.AID,
        fieldId: 1,
        quoteId: this.quoteId
      };
      if (submitFrom == "fromPO" && this.cardpo != "") {
        payObj["ponumber"] = this.cardpo;
        payObj["fieldId"] = 2;
      } else {
        payObj["token"] = this.stripeToken;
        payObj["last4"] = this.stripeLast4;
        payObj["cardName"] = this.cardName;
      }
      if (this.poNumberSec == 1 && this.cardpo.length == 0) {
        this.poNumberError = 1;
        return false;
      } else {
        this.poNumberError = 0;
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", payObj);
      if (response.data.STATUS == 1) {
        purchased = true;
        notifier.success(response.data.STATUSMESSAGE);
        // this.$router.push({ name: "Orders" });
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }

    if (purchased) {
      this.getQuotes();
    }
  }
  async Popurchase() {
    if (this.cardpo != "") {
      this.poPay = 1;
    }
  }

  async purchaseOrder() {
    if (this.stripeToken == "") {
      $(".creditCard").removeClass("active");
      this.creditCardSec = 0;
      $(".poOrder").addClass("active");
      this.poNumberSec = 1;
    }
  }

  async openPortal(UUID) {
    let template = "";

    if (localStorage.VARtemplate) {
      template = localStorage.VARtemplate;
    }

    const currentUser = JSON.parse(
      sessionStorage.getItem("currentUser") || "{}"
    );
    const accountId = currentUser[0]["ACCOUNTID"];
    const routeData = this.$router.resolve({
      name: "Pay",
      query: { aID: accountId, UUID: UUID, template: template }
    });
    window.open(routeData.href, "_blank");
  }

  async creditCardOrder() {
    $(".poOrder").removeClass("active");
    this.poNumberSec = 0;
    this.poPay = 0;
    $(".creditCard").addClass("active");
    this.creditCardSec = 1;
  }
  //Select Conract Number
  toggleSelectedContractNumber(selectedID: number, contractName) {
    const index = this.orderContractsID.findIndex(id => id === selectedID);
    let bigDealId = this.NASPOcontract;
    const contractGTags = this.globalTags.filter(
      tmp => tmp.CUSTOMFIELDOPTIONNAME == contractName
    );
    if (index === -1) {
      this.orderContractsID = [selectedID];
      this.selectedOrderContracts = contractName;
      let tmpContractObj = this.orderContractNumber.find(
        tmp => tmp.CUSTOMFIELDOPTIONID == selectedID
      );
      if (
        typeof tmpContractObj != "undefined" &&
        typeof tmpContractObj.CUSTOMFIELDOPTIONDESC != "undefined"
      ) {
        bigDealId = tmpContractObj.CUSTOMFIELDOPTIONDESC;
      }
      //Add Related Global Tags
      const selectedGTags = this.selectedGlobalTags.findIndex(
        (tmp: any) =>
          tmp.CUSTOMFIELDOPTIONNAME.toUpperCase() == contractName.toUpperCase()
      );
      if (selectedGTags == -1 && contractGTags.length) {
        this.selectedGlobalTagsID.push(contractGTags[0].CUSTOMFIELDOPTIONID);
        this.selectedGlobalTags.push({
          CUSTOMFIELDOPTIONID: contractGTags[0].CUSTOMFIELDOPTIONID,
          CUSTOMFIELDOPTIONNAME: contractGTags[0].CUSTOMFIELDOPTIONNAME
        });
      }
      this.saveContractNumber(bigDealId);
      this.saveGlobalTag();
      return;
    }
    this.orderContractsID.splice(index, 1);
    this.selectedOrderContracts = "";
    this.saveContractNumber(bigDealId);
    //Remove Related Global Tags
    if (contractGTags.length) {
      const tagIndex = this.selectedGlobalTagsID.findIndex(
        id => id === contractGTags[0].CUSTOMFIELDOPTIONID
      );
      this.selectedGlobalTagsID.splice(tagIndex, 1);
      this.selectedGlobalTags.splice(tagIndex, 1);
      this.saveGlobalTag();
    }
  }

  //Save Contract Number
  async saveContractNumber(bigDealId = "") {
    this.customDataLoading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Helpers",
        FunctionName: "CustomDataUpdate",
        optionIDs: this.selectedOrderContracts,
        Id: this.$route.params.id,
        fieldId: this.orderContractNumber[0].CUSTOMFIELDID,
        action: "save_ContractNumber",
        fieldTypeId: 2
      });
      if (response.data.STATUS === 1 && bigDealId != "") {
        this.bigDealId = bigDealId;
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.customDataLoading = false;
    }
  }

  getStatusParams(statuses) {
    const ids = statuses.split(",");
    return ids
      .map(id => {
        return "sStatus=" + id;
      })
      .join("&");
  }

  get noPaymentConfirmId() {
    return this.paymentConfirmID == "" || this.paymentConfirmID == "0";
  }

  get seletedTemplateDetail() {
    const aztempname = this.details.AccountQuoteAztempname || "";
    const templatesList = this.details.TemplateSetting.QuoteTemplates || [];
    if (aztempname != "" && templatesList.length) {
      const inList = templatesList.find(item => item.Name == aztempname);
      if (inList) {
        return aztempname;
      }
    }

    return "0";
  }

  activeRow(index) {
    $("[data-quoteliid='" + index + "']").toggleClass("active");
  }

  get isMultiEndUsers() {
    // check data of splitBySubQuoteId
    if (
      this.splitBySubQuoteId.length >= 1 &&
      this.splitBySubQuoteId[0].subQuoteId > 0
    ) {
      return true;
    }

    return false;
  }

  showEUCustomData(ss) {
    this.selectedSubQuote = ss;
    this.euCustomDataVisible = true;
  }

  showProductCatTT(item, index, e) {
    if (this.productCatTTVisibleIndex === `${index}-${item.QUOTELIID}`) {
      return;
    }
    this.productCatTTVisibleIndex = `${index}-${item.QUOTELIID}`;
  }

  async updateItemCategory(info) {
    const isConfig = info.selectedItem.ISCONFIG || 0;
    const quoteLiId = info.selectedItem.QUOTELIID || 0;
    if (!quoteLiId) return;

    let item: any = null;

    // specify related item
    if (!this.isMultiEndUsers) {
      item = this.quoteItems[info.index];
    } else {
      for (const ss of this.splitBySubQuoteId) {
        for (const li of ss.items) {
          if (li.QUOTELIID == quoteLiId) {
            item = li;
            break;
          }
        }
        if (item) {
          break;
        }
      }
    }

    if (!item) return;

    // update item category
    item.QCATEGORY = info.ItemCategory;
    item.CATEGORYNAME = info.ItemCategoryName;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Quotes",
        FunctionName: "UpdateItemCategory",
        quoteId: this.$route.params.id,
        quoteLiId,
        catId: info.ItemCategory,
        isConfig
      });
    } catch (err) {
      console.log(err);
    } finally {
      //
    }
  }

  updateCustomPriceType(options) {
    const priceType = options.priceType || 0;
    if (priceType == 0) {
      this.customPriceTypeStr = "";
    } else if (priceType == 1) {
      this.customPriceTypeStr = "/yr";
    } else if (priceType == 2) {
      this.customPriceTypeStr = "/mo";
    }
    this.$forceUpdate();
  }

  isFee(item) {
    return item.isFee || item.isContractFee || false;
  }

  categoryImgLink(item) {
    const checkSku = item.sku != "" && !this.isFee(item);
    if (checkSku && (item.ItemCategory || (item.ISCONFIG || 0))) {
      const configCategoryId = this.configCategory.CATEGORYID || 0;
      if (
        (configCategoryId > 0 && configCategoryId == item.ItemCategory) ||
        (item.ISCONFIG || 0)
      ) {
        // specify image for config item
        return require("@/assets/images/config-cat-ico.svg");
      }

      return require("@/assets/images/category_icon.svg");
    } else {
      return require("@/assets/images/category-not-available.svg");
    }
  }

  lastGroupItem(item, index, items: any = []) {
    if (!item || !item.QINCLUDED) {
      return false;
    }

    // check if next item is a base, then this is last item
    const nextItem = !this.isMultiEndUsers
      ? this.quoteItems[index + 1]
      : items[index + 1];
    if (nextItem && !nextItem.QINCLUDED) {
      return true;
    }

    return false;
  }
}
