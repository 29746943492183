
















































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import LaddaButton from "./LaddaButton.vue";
import { notifier, wait } from "../models/common";
import axios from "axios";
import DropdownControl from "./DropdownControl.vue";
import { getEUAddress } from "../helpers/ApiHelper";
import ConfirmRemoveItemModal from "./ConfirmRemoveItemModal.vue";

declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    LaddaButton,
    DropdownControl,
    ConfirmRemoveItemModal
  },
  methods: {
    getEUAddress
  }
})
export default class AssignToDeploymentModal extends TSXComponent<void> {
  @Prop({ required: false, default: "" })
  selectedIDs!: any;

  @Prop({ required: false, default: [] })
  selectedItems!: any;

  @Prop({ required: false, default: [] })
  categories!: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  loading = false;
  saving: boolean | "error" = false;
  $parent: any;
  editData: any = [];
  confirmUpdateModalVisible = false;
  deploymentId = 0;
  deployments: any = [];
  skusList: any = [];
  deploymentData: any = [];

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  async created() {
    this.editData = [...this.selectedItems].map(item => ({
      ...item,
      // deployment productId - purchaseId - deployment endUserId`,
      euAddr: getEUAddress(
        item.ADDR1,
        item.ADDR2,
        item.CITY,
        item.EUSTATE,
        item.ZIP,
        item.COUNTRY || ""
      ),
      deployedSKU: "",
      mapTo: {}
    }));
    const aIds = [...new Set(this.editData.map(item => item.AID))];
    if (!aIds.length) {
      notifier.alert("Account Not Found");
      this.$emit("close");
      return;
    }

    // prevent selecting hardware of different accounts
    if (aIds.length > 1) {
      notifier.alert("Selected hardware of different accounts");
      this.$emit("close");
      return;
    }

    // get related deployments
    const skus = this.editData.map(item => item.SKU.split("#")[0]);
    try {
      this.loading = true;
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        Controller: "Hardware",
        FunctionName: "AssignToOrderDeployment",
        action: "getDeploymentData",
        skus: skus.join(","),
        aId: aIds[0]
      });
      if (response.data.STATUS == 1) {
        this.deploymentData = response.data.deploymentData || [];
        this.deployments = (response.data.deployments || []).map(item => ({
          ID: item.DEPLOYMENTID,
          TEXT: `#${item.DEPLOYMENTID} - ${item.DEPLOYMENTNAME}`,
          data: item
        }));

        if (!this.deployments.length) {
          // notify not found related deployments for this mapping
          notifier.alert("No Related Deployments Found For Selected SKUs");
          return;
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  get editedLines() {
    return this.editData.filter(item => (item.deployedSKU || "") != "");
  }

  async associate(force = false) {
    // validate
    if (!this.editedLines.length) {
      return;
    }

    if (!force) {
      // confirm update
      this.confirmUpdateModalVisible = true;
      return;
    }

    try {
      this.saving = true;
      const links: any = this.editedLines.map(item => {
        const deployedSKU = this.skusList
          .filter(t => t.ID == item.deployedSKU)
          .map(t => ({
            sku: t.data.SKU,
            deploymentId: t.data.DEPLOYMENTID,
            productId: t.data.PRODUCTID,
            dEndUserId: t.data.DENDUSERID,
            euId: t.data.EUID
          }));

        return {
          aId: item.AID,
          hardwareId: item.HARDWAREID,
          hardwareUUID: item.HARDWAREUUID,
          deployedSKU: deployedSKU[0],
          mapTo: item.mapTo
        };
      });
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "Hardware",
        FunctionName: "AssignToOrderDeployment",
        action: "associate",
        links
      });

      if (response.data.STATUS == 1) {
        this.saving = false;

        // success
        this.$emit("close");
        this.$emit("resetSelectedIds");
        notifier.success("Associated successfully");
      } else {
        this.saving = "error";
      }
    } catch (err) {
      console.log(err);
      this.saving = "error";
    }
  }

  syncHardwareData(item) {
    // find and sync data for hidden lines
    const key = `${item.SKU}_${item.POID || item.EXTERNALPOID || ""}`;
    const hiddenItems = this.editData.filter(
      t => `${t.SKU}_${t.POID}`.toLowerCase() == key.toLowerCase() && !t.show
    );
  }

  get totalShow() {
    const showItems = this.editData.filter(t => t.show || false);
    return showItems.length;
  }

  isTooltipTop(item, field) {
    let ret = false;
    if (this.totalShow > 5 && this.totalShow - (item.index || 0) < 4) {
      ret = true;
    }

    return ret;
  }

  selectDeployment(id) {
    this.deploymentId = id;
    // product sku of this deployment
    this.skusList = this.deploymentData
      .filter(item => item.DEPLOYMENTID == this.deploymentId)
      .map(item => {
        const addr = getEUAddress(
          item.ADDR1,
          item.ADDR2,
          item.CITY,
          item.EUSTATE,
          item.ZIP,
          item.COUNTRY || ""
        );
        return {
          ID: `${item.PRODUCTID}-${item.PURCHASEID}-${item.DENDUSERID}`,
          TEXT: `${item.SKU} - #${item.PURCHASEID} - ${item.FULLNAME}`,
          HTML: `
            <div>
              ${item.SKU} - #${item.PURCHASEID} - <strong>${item.FULLNAME}</strong>
            </div>
            <div class="eu-addr textOverflow" title="${addr}">
              ${addr}
            </div>
          `,
          data: item
        };
      });

    // auto map the sku
    for (const item of this.editData) {
      item.mapTo = {}; // reset
      let hwSKU = item.SKU.toLowerCase();
      const inList = this.skusList.filter(t => {
        const sku = t.data.SKU.toLowerCase();
        return sku == hwSKU || sku.split("#")[0] == hwSKU.split("#")[0];
      });
      if (!inList.length) {
        continue;
      }

      // priority find deployed sku has same deployment end user name
      const matchEUName = inList.find(
        t => t.data.FULLNAME.toLowerCase() == item.EUNAME.toLowerCase()
      );
      const found = matchEUName ? matchEUName : inList[0];

      if (found) {
        item.deployedSKU = found.ID;

        // mapTo info
        this.setupMapTo(item, found);
      }
    }
  }

  setupMapTo(item, found) {
    item.mapTo.purchaseId = found.data.PURCHASEID;
    item.mapTo.productName = found.data.PRODUCTNAME;
    item.mapTo.deploymentId = found.data.DEPLOYMENTID;
    item.mapTo.firstName = found.data.FIRSTNAME;
    item.mapTo.lastName = found.data.LASTNAME;
    item.mapTo.addr1 = found.data.ADDR1;
    item.mapTo.addr2 = found.data.ADDR2;
    item.mapTo.city = found.data.CITY;
    item.mapTo.euState = found.data.EUSTATE;
    item.mapTo.zip = found.data.ZIP;
    item.mapTo.country = found.data.COUNTRY || "";
    item.mapTo.fullName = found.data.FULLNAME || "";
    item.mapTo.euAddr = getEUAddress(
      found.data.ADDR1,
      found.data.ADDR2,
      found.data.CITY,
      found.data.EUSTATE,
      found.data.ZIP,
      found.data.COUNTRY || ""
    );

    // add a flag to know if this line need to be updated
    item.needUpdate = false;
    if (item.mapTo.purchaseId && item.mapTo.purchaseId != item.PURCHASEID) {
      item.needUpdate = true;
    }
    if (item.mapTo.productName && item.mapTo.productName != item.HDESCRIPTION) {
      item.needUpdate = true;
    }
    if (
      item.mapTo.deploymentId &&
      item.mapTo.deploymentId != item.DEPLOYMENTID
    ) {
      item.needUpdate = true;
    }
    if (item.mapTo.fullName && item.mapTo.fullName != item.EUNAME) {
      item.needUpdate = true;
      item.logChangeEUName = true;
    }
    if (item.mapTo.euAddr && item.mapTo.euAddr != item.euAddr) {
      item.needUpdate = true;
    }
  }

  get allowSaving() {
    let ret = false;
    // at least there is a mapping in the list
    const inList = this.editData.find(item => (item.deployedSKU || "") != "");
    if (inList) {
      ret = true;
    }

    return ret;
  }

  getAssetNumber(item) {
    return [item.ASSETNUMBER || "", item.ASSETTAG || ""]
      .filter(t => t)
      .join(" / ");
  }

  selectDeployedSKU(id, item) {
    item.deployedSKU = id;
    item.mapTo = {}; // reset
    const found = this.skusList.find(t => t.ID == id);
    if (!found) return;

    this.setupMapTo(item, found);
  }
}
