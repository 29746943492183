


















































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {}
})

export default class QuantityReductionModal extends TSXComponent<void> {

  @Prop({ required: false, default: [] })
  selectedLineItems!: any;

  @Prop({ required: false, default: [] })
  selectedLineItemHardwares!: any;

  $refs!: {
    modal: HTMLDivElement;
  };

  data: any = []

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  async beforeDestroy() {
    $(this.$refs.modal).modal("hide");
  }

  selectHardwareDelete(item) {
    item.ISDELETED = !item.ISDELETED;
    this.$forceUpdate();
  }

  created() {
    this.data = JSON.parse(JSON.stringify(this.selectedLineItems));
    this.selectedLineItemHardwares.map((item: any) => {
      item["ISDELETED"] = false;
    });
  }

  close(){
    this.$emit('close');
  }

  confirm() {
    let deletedHardwareItems = this.selectedLineItemHardwares.filter((t:any) => t.ISDELETED);
    this.$emit("confirm", {
      selectedLineItems: this.data,
      deletedHardwareItems: deletedHardwareItems
    });
  }

  updateQty(item) {
    if (item.POPRODPARTIALQUANTITY > item.NEWPOPRODQUANTITY) {
      item.POPRODPARTIALQUANTITY = item.NEWPOPRODQUANTITY;
    } else if (item.POPRODPARTIALQUANTITY < 0 || !item.POPRODPARTIALQUANTITY) {
      item.POPRODPARTIALQUANTITY = 0;
    }
  }
}
