














































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { PaycheckResp } from "@/models/PaycheckResp";
import draggable from "vuedraggable";

interface Props {
  options: PaycheckResp.Tag[];
  selectedDataIds: number[];
  selectionLabel: string;
  disabled: boolean;
}

interface Events {}

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    draggable
  }
})
export default class MultiSelectDragAndDrop extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  options!: PaycheckResp.Tag[];

  @Prop({ required: true })
  selectedDataIds!: number[];

  @Prop({ required: false, default: 'Select Tag(s)' })
  selectionLabel?: string;

  @Prop({ required: false, default: 'Search Tags' })
  placeholder?: string;

  @Prop({ required: false, default: false })
  disabled?: boolean;

  @Prop({ required: false, default: [] })
  dragAndDropOrders!: any;

  dropdownVisible = false
  search = ''
  showOnlySelected: boolean = false

  get filteredOptions(): PaycheckResp.Tag[] {
    let options = this.options

    if (this.search) {
      options = options.filter((option) => option.CUSTOMFIELDOPTIONNAME.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }

    if (this.showOnlySelected) {
      options = options.filter((o) => this.selectedDataIds.indexOf(o.CUSTOMFIELDOPTIONID) !== -1)
      options = options.sort((a, b) => {
        return this.dragAndDropOrders.indexOf(a.columnKey) - this.dragAndDropOrders.indexOf(b.columnKey);
      });
    }

    return options
  }

  toggleDropdown() {
    if(!this.disabled) {
      this.dropdownVisible = !this.dropdownVisible
    }
  }

  clickOutside() {
    this.dropdownVisible = false
  }

  isChecked(dataId: number): boolean {
    const checked = !!this.selectedDataIds.find((id) => id === dataId)
    if (dataId === 0 && this.selectedDataIds.includes(dataId) && !checked) {
      return true
    }
    return checked
  }

  selectAll() {
    this.$emit('selectAll')
  }

  reset() {
    this.$emit('reset')
  }

  SelectTag(e){
    this.$emit('check', e.CUSTOMFIELDOPTIONID, e.CUSTOMFIELDOPTIONNAME)
    this.dropdownVisible = false
  }

  async handleListChange() {
    this.$forceUpdate();
    this.$emit("dragAndDrop", {
      'dragAndDropOrders': this.dragAndDropOrders
    });
  }
}
