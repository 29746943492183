















import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
  components: {}
})

export default class delayRemove extends TSXComponent<void> {
  @Prop({ required: false, default: 'delete' })
  action: string;

  countdown = 3;
  
  created() {
    this.delayCountDown();
  }

  undo() {
    this.$emit("undo");
  }

  delayCountDown() {
    var delay = setTimeout(() => {
      this.countdown--;
      if (this.countdown > 0) {
        this.delayCountDown();
      } else {
        this.$emit("remove");
      }
    }, 1000);
  }
}
