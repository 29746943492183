



































































































































































































































































































































































































































































































































































































import axios from "axios";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Pagination from "../components/Pagination.vue";
import {
  notifier,
  wait,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import LaddaButton from "../components/LaddaButton.vue";
import UserGenerateModal from "../components/UserGenerateModal.vue";
import PageTitle from "../components/pageTitle.vue";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import DropdownControl from "@/components/DropdownControl.vue";
import customerMultiselect from "@/components/customerMultiselect.vue";
import customizeModal from "@/components/customizeModal.vue";
import UserStatus, { UserStatusValue } from "../components/UserStatus.vue";
import {ApiHelper} from "@/helpers/all";

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    LaddaButton,
    UserGenerateModal,
    VSwatches,
    PageTitle,
    DropdownControl,
    customerMultiselect,
    customizeModal,
    UserStatus
  }
})
export default class CustomerPortals extends TSXComponent<void> {
  $allowedFunctions: any;
  $userFunctions: any;
  windowHeight: number = window.innerHeight;
  dynamicClasses: string = "short";
  selectedID: number[] = [];
  currentSelectedID: number[] = [];
  domainsList: any = [];
  currentRole = sessionStorage.getItem("userRole");
  ISSEARCH = 0;
  loading = false;
  saving: any = false;
  pageNumber = 1;
  items: any = [];
  details: any = {};
  sort = {
    field: null,
    direction: {
      accountID: 1,
      accountName: 1
    }
  };
  directionField = "";
  filters = {
    accountID: "",
    accountName: "",
    AccountTagID: []
  };
  accountTags: any = [];
  searchTagsTxt = "";
  viewType = "CurrentUsers";
  selectedView = false;
  portal: any = {
    currentUserID: 0,
    currentAcctName: "",
    accName: "",
    siteName: "",
    cName: "",
    emailAddress: "",
    usedDefault: 0
  };
  inEditMode = false;
  usersList = [];
  siteNameList: object[] = [];
  selectedSiteName: any = 0;
  currentSiteName = "";
  selectedAccts: any = [];
  defaultAID = 0;
  searchFilters = {
    accountID: "",
    accountName: "",
    AccountTagID: []
  };
  userGenerateModalVisible = false;
  noMultiReseller = 1;
  isPortalCustomer = 1; // 1: Customer, 0 Supplier
  disableClick = true;

  formData = {
    controls: {
      primaryColor: {
        name: "Primary Color",
        class: "PrimaryColor",
        value: "",
        limit: false,
        swatches: [
          ["#0073F9", "#285185", "#000000", "#A9A9A9"],
          ["#262220", "#6F4849", "#41403C", "#342628"],
          ["#541412", "#192F01", "#B74803", "#AA210F"]
        ]
      },
      secondaryColor: {
        name: "Secondary Color",
        class: "SecondaryColor",
        value: "",
        limit: false,
        swatches: [
          ["#000000", "#FFFFFF", "#0073f9", "#FF6C02"],
          ["#C34F5A", "#FFA500", "#FCD752", "#5A8100"],
          ["#178CA4", "#18B7BE", "#8CA9D3", "#E8ECEB"]
        ]
      },
      backgroundColor: {
        name: "Background Color",
        class: "BackgroundColor",
        value: "#ffffff",
        limit: true,
        swatches: [["#ffffff", "#eeeeee"]]
      }
    }
  };
  selectedCustomer: any = [];
  selectedDefaultCustomer: number[] = [];
  selectedAllCustomer: number[] = [];
  allCustomers: any = [
    {
      ID: 0,
      TEXT: ""
    }
  ];
  imageSrc = "";
  filePath = "";
  fileName = "";
  fileType = "";
  tabOptions = [
    {
      checked: true,
      name: "Dashboard",
      key: "Dashboard",
      imageSrc: "",
      optionTitle: "Graph Parameters",
      options: [
        {
          name: "Orders",
          key: "Orders",
          checked: true
        },
        {
          name: "Quotes",
          key: "Quotes",
          checked: true
        },
        {
          name: "Opportunities",
          key: "Opportunities",
          checked: true
        }
      ]
    },
    {
      checked: true,
      name: "Quotes",
      key: "Quotes",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Quote Details",
      key: "Quote_Details",
      imageSrc: "",
      optionTitle: "Quote Data Fields",
      options: [
        {
          name: "Global Tag",
          key: "globalTag",
          checked: true
        },
        {
          name: "Source",
          key: "source",
          checked: true
        },
        {
          name: "Quote ID",
          key: "QuoteID",
          checked: true
        },
        {
          name: "BusinessLine",
          key: "businessLine",
          checked: true
        },
        {
          name: "Contract Number",
          key: "contractNumber",
          checked: true
        },
        {
          name: "MDCPID",
          key: "MDCPID",
          checked: true
        },
        {
          name: "Opportunity",
          key: "opportunity",
          checked: true
        }
        // {
        //   name: "Custom Fields",
        //   key: "CustomFields",
        //   checked: true,
        // },
      ]
    },
    {
      checked: true,
      name: "Add Quote",
      key: "Add_Quote",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Request Quote",
      key: "Request_Quote",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Orders",
      key: "Orders",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Order Details",
      key: "Order_Details",
      imageSrc: "",
      optionTitle: "Order Data Fields",
      options: [
        {
          name: "Eu Name",
          key: "euName",
          checked: true
        },
        {
          name: "HP Invoice ID",
          key: "HPInvoiceID",
          checked: true
        },
        {
          name: "Disty Processed Id",
          key: "distyProcessedId",
          checked: true
        },
        {
          name: "PO Date",
          key: "poDate",
          checked: true
        },
        {
          name: "PO Description",
          key: "poDescription",
          checked: true
        },
        {
          name: "Source",
          key: "source",
          checked: true
        },
        {
          name: "Invoice Email",
          key: "invoiceEmail",
          checked: true
        },
        {
          name: "EU Phone",
          key: "euPhone",
          checked: true
        },
        {
          name: "Quote Id",
          key: "quoteId",
          checked: true
        },
        {
          name: "EU Email",
          key: "euEmail",
          checked: true
        },
        {
          name: "FL Only",
          key: "FLOnly",
          checked: true
        },
        {
          name: "Billing",
          key: "billing",
          checked: true
        },
        {
          name: "Business Line",
          key: "businessLine",
          checked: true
        },
        {
          name: "Shipping",
          key: "shipping",
          checked: true
        },
        {
          name: "Contract Number",
          key: "contractNumber",
          checked: true
        },
        {
          name: "PO Id",
          key: "poId",
          checked: true
        },
        {
          name: "MDCPID",
          key: "MDCPID",
          checked: true
        }
        // {
        //   name: "Custom Fields",
        //   key: "CustomFields",
        //   checked: true,
        // },
      ]
    },
    {
      checked: true,
      name: "Add Order",
      key: "Add_Order",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Order Status",
      key: "Order_Status",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Allow Customer Payments",
      key: "Customer_Payments",
      imageSrc: ""
    },
    {
      checked: true,
      name: "Hardware List",
      key: "Hardware_List",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Deployments",
      key: "Deployments",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Users",
      key: "Users",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Invoices",
      key: "Invoices",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Support",
      key: "Support",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Opportunities",
      key: "Opportunities",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Standards",
      key: "Standards",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Reports",
      key: "Reports",
      imageSrc: "",
      optionTitle: "",
      options: []
    },
    {
      checked: true,
      name: "Contracts",
      key: "Contracts",
      imageSrc: "",
      optionTitle: "",
      options: []
    }
  ];
  customizeModalVisible = false;
  customizeOptions: any = {};
  $router: any;
  $route: any;
  businessLineOptions: any = [];
  selectedBusinessLine: number[] = [-1];

  async created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.setBusinessLines();

    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      FunctionName: "ListExt",
      controller: "Accounts",
      queryType: "getDomainsList"
    });
    if (response.data.STATUS == 1) {
      this.domainsList = response.data.DOMAINS;
      if (this.domainsList.length) {
        this.portal.domain = this.domainsList[0].DOMAINNAME;
      }
    }

    if (!this.$route.params.id) {
      await this.fetchData();
      this.buildSiteNameList();
    } else {
      this.inEditMode = true;
      this.selectedSiteName = 0;
      await this.portalEdit(this.$route.params.id);
    }
    await this.initConfig();
    this.getOrderCustomFields().then((res1) => {
      // loaded order cf
    });
    this.getQuoteCustomFields().then((res1) => {
      // loaded quote cf
    });
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.windowHeight = window.innerHeight;
    if (this.windowHeight <= 1050) {
      this.dynamicClasses += " top";
    } else {
      this.dynamicClasses = "short";
    }
  }
  updateoptionValue(optionKey) {
    const externalOption = this.tabOptions.find(item => item.key === optionKey);
    const dashboardTab = this.tabOptions.find(item => item.key === "Dashboard");
    if (externalOption && dashboardTab) {
      const dashboardOption = dashboardTab.options.find(
        option => option.key === optionKey
      );
      if (dashboardOption) {
        dashboardOption.checked = externalOption.checked;
      }
    }
  }
  async fetchData() {
    this.loading = true;
    try {
      var reqData = {
        controller: "Customers",
        FunctionName: "List",
        sub: 2,
        maxRows:
          sessionStorage.getItem("maxRows") != ""
            ? sessionStorage.getItem("maxRows")
            : getMaxRows(),
        PageNumber: this.pageNumber,
        view: this.viewType,
        selectedView: this.selectedView,
        sAccountID: this.filters.accountID,
        sAccountName: this.filters.accountName,
        selectedTagIDs: this.filters.AccountTagID.join(","),
        checkCreatedPortal: true,
        inCreatePortalPage: true
      };
      if (this.sort.field) {
        reqData["order"] = this.sort.field;
        reqData["direction"] = this.sort.direction[this.directionField];
      }
      const response = await axios.post(dataURL + "?ReturnType=JSON", reqData);

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }
      if (response.data.STATUS !== 1) {
        throw new Error(response.data.STATUSMESSAGE);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        this.items = response.data.CUSTOMERS;
        this.allCustomers = this.items.map(item => ({
          ID: item.AID,
          TEXT: item.ANAME
        }));
        if (this.searchTagsTxt == "") {
          this.accountTags = this.details.accountTags;
        }
        if (
          !this.selectedView &&
          typeof this.details.userSelectedCurrentView != "undefined"
        ) {
          this.viewType = this.details.userSelectedCurrentView;
          this.selectedView = true;
        }
      }
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  //go to search page
  gotoSearch = function (this: any, searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path.toLowerCase()
    });
  };

  toggleCustomView(currStatus) {
    //this.selectedID = [];
    this.pageNumber = 1;
    this.viewType = currStatus;
    this.selectedView = true;
    this.fetchData();
  }

  createPortal() {
    if (this.selectedAllCustomer.length + this.selectedBusinessLine.length == 0)
      return;

    //remove HTML
    if (typeof this.portal.companyName != "undefined") {
      this.portal.companyName = this.removeHTML(this.portal.companyName);
    }
    if (typeof this.portal.contactName != "undefined") {
      this.portal.contactName = this.removeHTML(this.portal.contactName);
    }

    this.$validator.validateAll().then(async result => {
      if (result) {
        this.$validator.errors.clear();
        this.saving = true;
        try {
          const portalType = this.$route.query.type || "1";
          var reqData = {
            controller: "CustomerPortals",
            FunctionName: "CreatePortal",
            multiAccts: true,
            aID: this.selectedAllCustomer.join(","),
            accName: this.portal.companyName,
            siteName: this.portal.siteName.length
              ? this.portal.siteName
              : this.portal.companyName.replace(/[^a-zA-Z0-9]/gi, ""),
            cName: this.portal.contactName,
            emailAddress: this.portal.email,
            domain: this.getFullDomain(),
            defaultAID: this.selectedDefaultCustomer[0] || 0,
            noMultiReseller: this.noMultiReseller ? 1 : 0,
            isPortalCustomer: this.isPortalCustomer,
            secondaryColor: this.formData.controls.secondaryColor.value,
            primaryColor: this.formData.controls.primaryColor.value,
            filePath: this.filePath,
            fileName: this.fileName,
            type: portalType,
            fileType: this.fileType,
            businessLine:
              this.selectedBusinessLine.length > 0
                ? this.selectedBusinessLine[0]
                : -1
          };
          if (
            typeof this.$route.params.id != "undefined" &&
            parseInt(this.$route.params.id) > 0
          ) {
            reqData["isUpdate"] = true;
            reqData["accountID"] = this.$route.params.id;
            reqData["userID"] = this.portal.currentUserID;
          }
          let formData = new FormData();
          for (let key in reqData) {
            formData.append(key, reqData[key]);
          }
          formData.append("session", sessionStorage.getItem("sessionID") || "");
          formData.append("tabOptions", JSON.stringify(this.tabOptions));
          const response = await axios.post(
            dataURL + "?ReturnType=JSON",
            formData
          );
          if (response.data.ERROR) {
            throw new Error(response.data.ERROR);
          }

          if (response.data.STATUS == 1) {
            this.saving = false;
            if (
              typeof this.$route.params.id != "undefined" &&
              parseInt(this.$route.params.id) > 0
            ) {
              notifier.success(response.data.STATUSMSG);
            } else {
              this.$router.push({
                name: "CustomerPortals",
                query: { tab: portalType }
              });
            }
          } else {
            this.saving = "error";
            var tmpField = "";
            if (typeof response.data.EMAILEXISTED != "undefined") {
              tmpField = "emailAddress";
            }
            if (typeof response.data.SITENAMEEXISTED != "undefined") {
              tmpField = "selectedSiteName";
            }
            if (typeof response.data.COMPANYNAMEEEXISTED != "undefined") {
              tmpField = "companyName";
            }
            if (tmpField != "") {
              this.$validator.errors.add({
                field: tmpField,
                msg: response.data.STATUSMSG
              });
            }
          }
        } catch (err) {
          this.saving = "error";
        }
      }
    });
  }

  getFullDomain() {
    if (this.selectedSiteName == 0) {
      return (
        this.portal.companyName.replace(/[^a-zA-Z0-9]/gi, "") +
        "." +
        this.portal.domain
      );
    }
    return this.portal.siteName.replace(/\s/g, "") + "." + this.portal.domain;
  }

  async portalEdit(id) {
    this.loading = true;
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "CustomerPortals",
        FunctionName: "view",
        id: id
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        this.details = response.data;
        var portalInfo = response.data.PORTALINFO;
        if (portalInfo.DOMAIN != "") {
          let index = portalInfo.DOMAIN.indexOf(".");
          this.portal.siteName = portalInfo.DOMAIN.substr(0, index);
          this.portal.domain = portalInfo.DOMAIN.substr(index + 1);
        }
        this.portal.companyName = portalInfo.ACCOUNTNAME;
        this.portal.contactName = portalInfo.UFNAME + " " + portalInfo.ULNAME;
        this.portal.email = portalInfo.UEMAIL;
        this.portal.currentAcctName = portalInfo.ACCOUNTNAME;
        this.portal.currentUserID = portalInfo.USERID;
        this.portal.usedDefault = portalInfo.DEFAULTDOMAIN;
        // set color fields
        this.formData.controls.primaryColor.value =
          portalInfo.PRIMARYCOLOR || "";
        this.formData.controls.secondaryColor.value =
          portalInfo.SECONDARYCOLOR || "";
        this.imageSrc = portalInfo.CUSTOMERPORTALLOGO || "";
        if (portalInfo.TABOPTIONS && portalInfo.TABOPTIONS.length) {
          let tabOptions = JSON.parse(portalInfo.TABOPTIONS) || [];
          if (this.isArray(tabOptions)) {
            // this.tabOptions = tabOptions;


            tabOptions.map(item => {
              const currOption = this.tabOptions.find(
                  currItem => currItem.key == item.key
              );
              if (currOption) {

                const currItemOptions = currOption.options || [];
                item.options = (item.options || []).map(itemOption => {
                  const currItem = currItemOptions.find(
                      currItemOption => currItemOption.key == itemOption.key
                  );
                  if (!currItem) {
                    console.log('itemOption', itemOption);
                    currOption.options.push(itemOption);
                  }
                });
              } else {
                item.checked = false;
                return item;
              }
            });

            this.tabOptions.map(item => {
              const currOption = tabOptions.find(
                currItem => currItem.key == item.key
              );
              if (currOption) {
                item.checked = currOption.checked;
                item.imageSrc = currOption.imageSrc;
                const currItemOptions = currOption.options || [];
                item.options = (item.options || []).map(itemOption => {
                  const currItem = currItemOptions.find(
                    currItemOption => currItemOption.key == itemOption.key
                  );
                  if (currItem) {
                    itemOption.checked = currItem.checked || false;
                  }
                  return itemOption;
                });
              } else {
                item.checked = false;
                return item;
              }
            });
          }
        }
        //this.filters.accountID = response.data.selectedAccountIDs;
        if (response.data.selectedAIDs != "") {
          this.selectedID = response.data.selectedAIDs.split(",");
          this.selectedID = this.selectedID.map((val: any) => parseInt(val));
          this.currentSelectedID = this.selectedID;
        }

        this.noMultiReseller = parseInt(portalInfo.NOMULTIRESELLER);
        this.isPortalCustomer = parseInt(portalInfo.ISPORTALCUSTOMER);
        if (portalInfo.BUSINESSLINE > 0) {
          this.selectedBusinessLine = [portalInfo.BUSINESSLINE];
        } else {
          this.selectedBusinessLine = [-1];
        }

        this.items = response.data.CUSTOMERS;
        //this.details.TOTAL = this.selectedID.length;
        this.selectedAccts = response.data.selectedAccts;
        var defaultAct = this.selectedAccts.filter(val => val.ISDEFAULT == 1);
        if (defaultAct.length) {
          this.defaultAID = defaultAct[0].AID;
          this.selectedDefaultCustomer = [this.defaultAID];
        }
        this.allCustomers = this.items.map(item => ({
          ID: item.AID,
          TEXT: item.ANAME
        }));
        this.selectedCustomer = this.selectedAccts.map(item => ({
          ID: item.AID,
          TEXT: item.ANAME
        }));
        this.selectedAllCustomer = this.selectedAccts.map(item => item.AID);

        if (this.searchTagsTxt == "") {
          this.accountTags = response.data.accountTags;
          this.details["accountTags"] = response.data.accountTags;
        }
        this.usersList = response.data.USERS;

        this.selectedSiteName = 0;
        this.currentSiteName = this.portal.siteName;
        this.buildSiteNameList(portalInfo);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  listPageRedirection() {
    this.$router.push({ name: "CustomerPortals" });
  }

  buildSiteNameList(portalInfo: any = {}) {
    this.siteNameList = [
      {
        label: "Default (customer)",
        value: 0
      }
    ];
    if (
      typeof portalInfo.DEFAULTDOMAIN != "undefined" &&
      portalInfo.DEFAULTDOMAIN == 0
    ) {
      let siteName = portalInfo.DOMAIN.split(".")[0];
      this.selectedSiteName = siteName;
      this.siteNameList.push({
        label: siteName,
        value: siteName
      });
    }
    this.siteNameList.push({
      label: "OR...",
      value: 1
    });
  }

  selectedSiteNameChange() {
    if (this.selectedSiteName == 0) {
      //default
      this.portal.siteName = "";
    } else if (this.selectedSiteName == 1) {
      if (this.portal.usedDefault) {
        this.portal.siteName = "";
      } else {
        this.portal.siteName = this.currentSiteName;
      }
      //clear validate error for site name
      let tmpInd = this.$validator.errors.items.findIndex(
        val => val.field == "siteName"
      );
      this.$validator.errors.items.splice(tmpInd, 1);
      setTimeout(() => {
        $("#siteName").focus().select();
      }, 200);
    } else if (this.selectedSiteName != 1 && this.selectedSiteName != 0) {
      this.portal.siteName = this.currentSiteName;
    }
  }

  removeHTML(txt) {
    if (htmlCheck(txt)) {
      txt = htmlParse(txt);
    }

    return txt;
  }

  async userAdd(userVals) {
    try {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        controller: "CustomerPortals",
        FunctionName: "userAdder",
        emailAddress: userVals.uEmail,
        firstName: userVals.uFName,
        lastName: userVals.uLName,
        userState: userVals.userState,
        address: userVals.uAddress,
        city: userVals.uCity,
        Phone: userVals.uPhone,
        PhoneExt: userVals.uPhoneExt,
        zip: userVals.uZIP,
        accountID: this.$route.params.id,
        aID: this.selectedAllCustomer.join(","),
        defaultAID: this.selectedDefaultCustomer[0] || 0
      });

      if (response.data.ERROR) {
        throw new Error(response.data.ERROR);
      }

      if (response.data.STATUS == 1) {
        notifier.success(response.data.STATUSMESSAGE);
        await this.portalEdit(this.$route.params.id);
      } else {
        notifier.alert(response.data.STATUSMESSAGE);
      }
    } catch (err) {
      //console.log(err.message);
    } finally {
      this.userGenerateModalVisible = false;
    }
  }
  multiResellerChange() {
    this.noMultiReseller = this.noMultiReseller ? 0 : 1;
  }

  customerOrSupplierChange() {
    this.isPortalCustomer = this.isPortalCustomer === 1 ? 0 : 1;
  }

  // customer multi Select
  toggleSelectedCustomers(customerId, customerName) {
    const index = this.selectedAllCustomer.findIndex(id => id === customerId);
    const selectedCustomerIndex = this.selectedCustomer.findIndex(
      item => item.ID === customerId
    );
    if (index === -1) {
      this.selectedAllCustomer.push(customerId);
      this.selectedCustomer.push({
        ID: customerId,
        TEXT: customerName
      });
      return;
    }
    this.selectedAllCustomer.splice(index, 1);
    this.selectedCustomer.splice(selectedCustomerIndex, 1);
  }
  selectAllCustomers() {
    this.selectedAllCustomer = [];
    var $this = this;
    this.allCustomers.map(item => {
      $this.selectedAllCustomer.push(item.ID);
      const index = $this.selectedCustomer.findIndex(
        customerItem => customerItem.ID === item.ID
      );
      if (index === -1) {
        $this.selectedCustomer.push({
          ID: item.ID,
          TEXT: item.TEXT
        });
      }
    });
  }
  resetCustomers() {
    this.selectedAllCustomer = [];
    this.selectedCustomer = [];
    this.selectedDefaultCustomer = [];
  }
  updateCustomerList(index, customerId) {
    if (
      typeof this.selectedCustomer != "undefined" &&
      this.selectedCustomer.length
    ) {
      const selectedCustomerIndex = this.selectedAllCustomer.findIndex(
        id => id === customerId
      );
      this.$delete(this.selectedCustomer, index);
      this.$delete(this.selectedAllCustomer, selectedCustomerIndex);
      if (this.selectedDefaultCustomer.includes(customerId)) {
        this.selectedDefaultCustomer = [];
      }
    }
  }
  updateDefaultCustomer(selectedID) {
    this.selectedDefaultCustomer = [selectedID];
  }
  onFileChange(e) {
    const imageFile = e.target.files[0];
    this.imageSrc = URL.createObjectURL(imageFile);
    this.filePath = imageFile;
    this.fileName = imageFile.name.replace(/\s/g, "");
    this.fileType = imageFile.type;
  }
  onChangeImg() {
    $(this.$refs.logoUpload).click();
  }
  clickOutsidePrimaryColor() {
    if (
      $(this.$refs.primaryColor)
        .closest(".vue-swatches")
        .find(".vue-swatches__container")
        .is(":visible")
    ) {
      $(this.$refs.primaryColor).click().blur();
    }
  }
  clickOutsideSecondaryColor() {
    if (
      $(this.$refs.secondaryColor)
        .closest(".vue-swatches")
        .find(".vue-swatches__container")
        .is(":visible")
    ) {
      $(this.$refs.secondaryColor).click().blur();
    }
  }
  showCustomizeModal(data) {
    this.customizeOptions = data;
    this.customizeModalVisible = true;
  }
  async saveCustomize(data, imageSrc = "", options = []) {
    if (data.imageSrc.length) {
      this.tabOptions.map(item => {
        if (item.key == data.item.key) {
          item.imageSrc = data.imageSrc;
          item.options = options || [];
        }
      });
    }
    this.customizeModalVisible = false;
  }
  clearCustomize(data) {
    this.tabOptions.map(item => {
      if (item.key == data.item.key) {
        item.imageSrc = "";
      }
    });
  }
  isArray(item) {
    return (
      Array.isArray(item) && item.length > 0 && typeof item[0] === "object"
    );
  }

  async changeStatus(userid, status) {
    const statusIndex = status;

    try {
      this.loading = true;
      await axios.post(dataURL + "?ReturnType=JSON", {
        FunctionName: "Update",
        controller: "VARUsers",
        subsystem: "VAR360",
        userId: userid,
        active: status
      });
      const matchingUser: any = this.usersList.find(
        (user: any) => user.USERID === userid
      );
      if (matchingUser) {
        matchingUser.ACTIVE = status;
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
  }

  async setBusinessLines() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });

    this.businessLineOptions.push({
      ID: -1,
      TEXT: "All",
      data: {
        PARENTID: 0,
        ID: -1,
        BUSINESSLINENAME: "All"
      }
    });

    (response.data.businessLineOptions || []).map(item =>
      this.businessLineOptions.push({
        ID: item.ID,
        TEXT: item.BUSINESSLINENAME,
        data: item
      })
    );
  }

  updateBusinessLine(id) {
    this.selectedBusinessLine = id > 0 ? [id] : [-1];
  }

  get allowEdit() {
    return this.$allowedFunctions.includes(
      this.$userFunctions.EditPortalsStores
    );
  }

  cfIds: any = {};
  async initConfig() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.businessLineOptions = (response.data.businessLineOptions || []).map(
        item => ({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
    );
    this.cfIds = response.data.cfIds || {};
  }

  async getOrderCustomFields() {
    const ignoreFieldIds = [26];
    if ((this.cfIds.HPQuoteNoCFieldId || 0) > 0) {
      ignoreFieldIds.push(this.cfIds.HPQuoteNoCFieldId);
    }
    if ((this.cfIds.BigDealNoCFieldId || 0) > 0) {
      ignoreFieldIds.push(this.cfIds.BigDealNoCFieldId);
    }
    const response = await ApiHelper.callApi("post", {
      controller: "Helpers",
      FunctionName: "CustomData",
      DataType: "1",
      Id: 0,
      DropdownField: 25,
      contractDropdown: 26,
      orderCustomData: true,
      ignoreFieldIds: ignoreFieldIds.join(",")
    });
    const getConfigOption = this.tabOptions.find((item) => item.key == 'Order_Details');
    if(getConfigOption) {
      response.CUSTOMDEFINITION.map((item) => {
        const optionName = item.CUSTOMFIELDNAME;
        const optionKey = optionName.replaceAll(' ', '%');
        if(!(getConfigOption.options || []).find((tmp) => tmp.key == optionKey)) {
          getConfigOption.options.push({
            checked: false,
            key: optionKey,
            name: optionName,
          });
        }
      });
    }
  }
  async getQuoteCustomFields() {
    const ignoreFieldIds = [26];
    const response = await ApiHelper.callApi("post", {
      controller: "Helpers",
      FunctionName: "CustomData",
      DataType: "2",
      Id: 0,
      DropdownField: 25,
      contractDropdown: 26,
      orderCustomData: true,
      ignoreFieldIds: ignoreFieldIds.join(",")
    });
    const getConfigOption = this.tabOptions.find((item) => item.key == 'Quote_Details');
    if(getConfigOption) {
      response.CUSTOMDEFINITION.map((item) => {
        const optionName = item.CUSTOMFIELDNAME;
        const optionKey = optionName.replaceAll(' ', '%');
        if(!(getConfigOption.options || []).find((tmp) => tmp.key == optionKey)) {
          getConfigOption.options.push({
            checked: false,
            key: optionKey,
            name: optionName,
          });
        }
      });
    }
  }


}
