var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",staticClass:"modal hardware-update-fields hasBackground",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered dialogBox",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[(_vm.loading)?_c('Loader'):_vm._e(),[_c('perfect-scrollbar',{staticClass:"ps tab-edit-fields",attrs:{"options":{
              wheelPropagation: false,
              suppressScrollX: false,
              maxScrollbarLength: 50
            }}},[_c('table',{attrs:{"width":"100%"}},[_c('thead',[_c('tr',_vm._l((_vm.editFields),function(field,index){return _c('th',{key:index,staticClass:"list-tab"},[_c('div',{staticClass:"head-item"},[_c('span',{staticClass:"left textOverflow",attrs:{"title":field.text}},[_vm._v(" "+_vm._s(field.text)+" ")])])])}),0)]),_c('tbody',[_vm._l((_vm.editData),function(item,index){return [(item.show || false)?_c('tr',{key:index,attrs:{"data-group-length":_vm.getGroupLength(item)}},_vm._l((_vm.editFields),function(field,fieldIndex){return _c('td',{key:fieldIndex},[(field.id == 'SKU' || field.id == 'PURCHASEID')?_c('div',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item[field.id])+" ")]):(field.id == 'CATEGORYID')?_c('DropdownControl',{staticClass:"hardware-cats mt-0",class:{
                          'tooltip-top': _vm.isTooltipTop(item, field)
                        },attrs:{"options":_vm.categories,"selectedIDs":[item.CATEGORYID],"placeholderText":'Unknown',"hideSelectAll":true,"multiSelect":false,"hideSearch":true,"hideCheckMark":true,"name":"category","data-vv-as":"category","customWidth":180},on:{"check":function (id) {
                            item.CATEGORYID = id;
                            _vm.syncHardwareData(item);
                          }},model:{value:(item.CATEGORYNAME),callback:function ($$v) {_vm.$set(item, "CATEGORYNAME", $$v)},expression:"item.CATEGORYNAME"}}):(field.id == 'HDESCRIPTION')?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item.HDESCRIPTION),expression:"item.HDESCRIPTION",modifiers:{"trim":true}}],staticClass:"editableInput textOverflow",attrs:{"placeholder":"Description","type":"text","maxlength":"255","title":item.HDESCRIPTION},domProps:{"value":(item.HDESCRIPTION)},on:{"change":function($event){return _vm.syncHardwareData(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "HDESCRIPTION", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]):(field.id == 'STARTDATEFORMATTED')?_c('div',[_c('Datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({
                            required: false,
                            date_format: 'mm/dd/yyyy'
                          }),expression:"{\n                            required: false,\n                            date_format: 'mm/dd/yyyy'\n                          }"}],key:item.datePickerKey || index,staticClass:"editableInput",class:{
                            'border-danger':
                              _vm.errors.has(("startDate" + index)) ||
                              !_vm.isValidDate(item.STARTDATEFORMATTED) ||
                              item.invalidWarrantyRange ||
                              false
                          },attrs:{"name":("startDate" + index),"className":"warranty-datepicker","data-vv-as":"start date","placeholder":"Date","endDate":item.WARRENTYEXPFORMATTED},on:{"inputChange":function($event){return _vm.dayChange(item, index, 'start')},"selectedDay":function($event){return _vm.dayChange(item, index, 'start')}},model:{value:(item.STARTDATEFORMATTED),callback:function ($$v) {_vm.$set(item, "STARTDATEFORMATTED", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.STARTDATEFORMATTED"}})],1):(field.id == 'WARRENTYEXPFORMATTED')?_c('div',[_c('Datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({
                            required: false,
                            date_format: 'mm/dd/yyyy'
                          }),expression:"{\n                            required: false,\n                            date_format: 'mm/dd/yyyy'\n                          }"}],key:item.datePickerKey || index,staticClass:"editableInput",class:{
                            'border-danger':
                              _vm.errors.has(("endDate" + index)) ||
                              !_vm.isValidDate(item.WARRENTYEXPFORMATTED)
                          },attrs:{"name":("endDate" + index),"className":"warranty-datepicker","data-vv-as":"end date","placeholder":"Date","startDate":item.STARTDATEFORMATTED},on:{"inputChange":function($event){return _vm.dayChange(item, index, 'end')},"selectedDay":function($event){return _vm.dayChange(item, index, 'end')}},model:{value:(item.WARRENTYEXPFORMATTED),callback:function ($$v) {_vm.$set(item, "WARRENTYEXPFORMATTED", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.WARRENTYEXPFORMATTED"}})],1):(field.isCustomField || false)?_c('div',{attrs:{"data-fieldid":field.id}},[((field.customDType || 0) == 3)?_c('DropdownControl',{ref:("cd" + (field.id) + "_" + (item.index) + "_" + fieldIndex),refInFor:true,staticClass:"cd-select mt-0",class:{
                            'tooltip-top': _vm.isTooltipTop(item, field)
                          },attrs:{"options":field.options.map(function (t) { return ({
                              ID: t.TEXT,
                              TEXT: t.TEXT
                            }); }),"selectedIDs":[item[("CUSTOMDATA" + (field.id))]],"placeholderText":'-- Select --',"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"showTitleHover":true,"customWidth":230},on:{"check":function (id) {
                              item[("CUSTOMDATA" + (field.id))] =
                                id == '-- Select --' ? '' : id;
                              _vm.$forceUpdate();
                              _vm.syncHardwareData(item);
                            }}}):[(field.ISDATE || 0)?_c('div',[_c('Datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({
                                required: false,
                                date_format: 'mm/dd/yyyy'
                              }),expression:"{\n                                required: false,\n                                date_format: 'mm/dd/yyyy'\n                              }"}],staticClass:"editableInput",class:{
                                'border-danger':
                                  _vm.errors.has(
                                    ("cd" + (field.id) + "_" + index + "_" + fieldIndex)
                                  ) ||
                                  !_vm.isValidDate(item[("CUSTOMDATA" + (field.id))])
                              },attrs:{"name":("cd" + (field.id) + "_" + index + "_" + fieldIndex),"placeholder":field.text},on:{"inputChange":function (selected) {
                                  item[("CUSTOMDATA" + (field.id))] = selected;
                                  _vm.syncHardwareData(item);
                                },"selectedDay":function (selected) {
                                  item[("CUSTOMDATA" + (field.id))] = selected;
                                  _vm.syncHardwareData(item);
                                }},model:{value:(item[("CUSTOMDATA" + (field.id))]),callback:function ($$v) {_vm.$set(item, ("CUSTOMDATA" + (field.id)), (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item[`CUSTOMDATA${field.id}`]"}})],1):_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(item[("CUSTOMDATA" + (field.id))]),expression:"item[`CUSTOMDATA${field.id}`]",modifiers:{"trim":true}}],staticClass:"editableInput textOverflow",attrs:{"placeholder":field.text,"type":"text","maxlength":"70","title":item[("CUSTOMDATA" + (field.id))]},domProps:{"value":(item[("CUSTOMDATA" + (field.id))])},on:{"change":function($event){return _vm.syncHardwareData(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, ("CUSTOMDATA" + (field.id)), $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]],2):_vm._e()],1)}),0):_vm._e()]})],2)])]),_c('div',{staticClass:"mt-5"},[_c('LaddaButton',{staticClass:"teal attach-btn",attrs:{"type":"button","label":"Update","loading":_vm.saving,"disabled":_vm.saving == true || !_vm.allowSaving},on:{"click":function($event){return _vm.saveFields(false)}}}),_c('input',{staticClass:"btn btn-secondary ml-4",staticStyle:{"height":"46px","padding":"0 40px !important","border-radius":"5px !important"},attrs:{"type":"button","name":"cancel","value":"Cancel"},on:{"click":function($event){return _vm.$emit('close')}}})],1)]],2)])]),(_vm.confirmUpdateModalVisible)?_c('ConfirmRemoveItemModal',{attrs:{"title":"Confirmation","customMessage":((_vm.editedLines.length) + " number of fields will be updated")},on:{"close":function($event){_vm.confirmUpdateModalVisible = false},"confirm":function($event){_vm.confirmUpdateModalVisible = false;
      _vm.saveFields(true);}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Update Fields")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close","data-dismiss":"modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])}]

export { render, staticRenderFns }