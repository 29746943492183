














































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { HardwareDashboardAPIRepo } from "@/repos/HardwareAPIRepo";
import axios from "axios";
import MapComponent from "@/components/Map.vue";
import {
  colsResizeable,
  colsResizeableReset
} from "@/helpers/ApiHelper";
import draggable from "vuedraggable";

const hardwareRepo = new HardwareDashboardAPIRepo();

declare const $: any;

@Component({
  inheritAttrs: false,
  components: {
    MapComponent,
    draggable
  },
})
export default class HardwareDashboard extends TSXComponent<void> {
  $root: any;
  $allowedFunctions: any;
  $systemGroups: any;
  $userFunctions: any;
  currYear = new Date().getFullYear();
  loading = false;
  isInitialLoad = sessionStorage.getItem('isInitialLoad') ? true : false;
  CurrentView = "My Datapoints";
  role = sessionStorage.getItem("userRole");
  userRole = this.$root.getUserRole() === 'Reseller';	
  items: {
    hardwareLineChartData1?: null | number[];
    hardwareLineChartData2?: null | number[];
    sortingYear: number;
    hardwareLineChartData3?: null | number[];
  } = {
    hardwareLineChartData1: null,
    hardwareLineChartData2: null,
    sortingYear: this.currYear,
    hardwareLineChartData3: null
  };
  hardwareLineSeries = [
    {
      name: "Retired Hardwares",
      data: [28, 29, 33, 36, 32, 32]
    },
    {
      name: "Active Hardwares",
      data: [412, 141, 514, 158, 167, 173]
    },
    {
      name: "End Users",
      data: [412, 141, 514, 158, 167, 173]
    }
  ];
  threeYearsOldCnt: number = 0;
  fourYearsOldCnt: number = 0;
  fiveYearsOldCnt: number = 0;
  outOfWarrantyCnt: number = 0;
  threeYearsOldPercentage: number = 0;
  fourYearsOldPercentage: number = 0;
  fiveYearsOldPercentage: number = 0;
  outOfWarrantyPercentage: number = 0;
  endUserCnt: number = 0;
  categoryDetails: any[] = [];
  hardwareCnt: number = 0;
  retiredHardwareCnt: number = 0;
  allActiveHardwareCnt: number = 0;
  ApiMonths = [];
  fleetLocationExpanded: boolean = false;
  hardwareData: any = [];
  assetsData: any = [];
  listCustomFields: any = [];
  selectedHeaders: string[] = [];
  headerOrder = [
    "ENDUSER",
    "HDESCRIPTION",
    "SKU",
    "ASSETNUMBER",
    "CATEGORYNAME",
    "HSTATUS",
    "PURCHASEDON"
  ];
  pageHeaders: any = [
    { key: "ENDUSER", value: "End User" },
    { key: "HDESCRIPTION", value: "Description" },
    { key: "SKU", value: "Sku" },
    { key: "ASSETNUMBER", value: "Serial Number" },
    { key: "CATEGORYNAME", value: "Category" },
    { key: "HSTATUS", value: "Status" },
    { key: "PURCHASEDON", value: "Created Date" }
  ];
  searchHeader = "";

  @Watch("selectedHeaders")
  colsChange(val: any) {
    colsResizeable();
  }

  async created() {
    await this.fetchDashboardData(this.currYear);
  }

  get chartOptions() {
    return {
      chart: {
        height: "350px",
        defaultLocale: "en",
        type: "line",
        // stacked: true,
        events: {
          click: (event, chartContext, config) => {
            if (config.dataPointIndex >= 0 || config.seriesIndex >= 0) {
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              // this.saleRedirect(this.items.sortingYear, config.globals.categoryLabels[config.dataPointIndex], config.seriesIndex, config.dataPointIndex, config.globals.categoryLabels[config.dataPointIndex].split("-")[0])
            }
          }
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      // colors: ["#3c3564", "#0ea1ff", "#b7b6ba"],
      colors: this.userRole ? ["#b7b6ba", "#0ea1ff", "#31bcbc"] : ["#b7b6ba", "#0ea1ff", "#31bcbc"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
        dashArray: [7, 0, 0],
      },
      markers: {
        size: 6,
        fillOpacity: 0,
      },
      xaxis: {
        categories: this.categories(),
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          stroke: {
            width: 0,
          },
        },
        axisTicks: {
          show: true,
          height: 11,
        },
        labels: {
          show: true,
          style: {
            colors: "#656468",
            // fontFamily: 'Work Sans, sans-serif',
            fontWeight: 600,
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          formatter: (value) => {
            return this.ApiMonths[value - 1];
          },
        }
      },
      grid: { show: false },
      legend: {
        show: true,
        horizontalAlign: "center",
        onItemClick: {
          toggleDataSeries: true,
        },
        position: "top",
        markers: {
          width: 40,
          height: 15,
          radius: 0,
          strokeWidth: 3,
          strokeColor: ["#3c3564", "#0ea1ff", "#31bcbc"],
        },
      },
    }
  }

  @Watch("items", { deep: true })
  itemsUpdated(
    val: {
      hardwareLineChartData1: number[];
      hardwareLineChartData2: number[];
      hardwareLineChartData3: number[];
    },
    oldVal: string
  ) {
    try {
      // this.debounce(async function (val, oldVal) {
      // 	console.log('debounce watch');
      try {
        
        this.hardwareLineSeries[1].data = JSON.parse(
          JSON.stringify(val.hardwareLineChartData1)
        );
        this.hardwareLineSeries[0].data = JSON.parse(
          JSON.stringify(val.hardwareLineChartData2)
        );
        this.hardwareLineSeries[2].data = JSON.parse(
          JSON.stringify(val.hardwareLineChartData3)
        );
      } catch (e) {
        // console.log("error in chart watch", e);
      }
      // }, 200)
    } catch (e) {
      // console.log("exception", e);
    }
  }

  async fetchDashboardData(year) {
    this.loading = true;
    const response = await hardwareRepo.fetchData(year);
    if (response.STATUS === 1) {
      this.items["hardwareLineChartData1"] = response.HARDWARELINETHISYEAR || [];
      this.items["hardwareLineChartData2"] = response.RETIREDHARDWARELINETHISYEAR || [];
      this.items["hardwareLineChartData3"] = response.ENDUSERTHISYEAR || [];
      this.threeYearsOldCnt = response.THREEYEARSOLDCNT;
      this.fourYearsOldCnt = response.FOURYEARSOLDCNT;
      this.fiveYearsOldCnt = response.FIVEYEARSOLDCNT;
      this.outOfWarrantyCnt = response.OUTOFWARRANTYCNT;
      this.threeYearsOldPercentage = response.THREEYEARSOLDPERCENTAGE;
      this.fourYearsOldPercentage = response.FOURYEARSOLDPERCENTAGE;
      this.fiveYearsOldPercentage = response.FIVEYEARSOLDPERCENTAGE;
      this.outOfWarrantyPercentage = response.OUTOFWARRANTYPERCENTAGE;
      this.endUserCnt = response.ENDUSERCNT;
      this.categoryDetails = response.CATEGORYDETAILS || [];
      this.ApiMonths = response.MONTHNAMES || [];
      this.hardwareData = response.HARDWAREDATA || [];
      this.assetsData = response.HARDWAREDATA || [];
      this.hardwareCnt = response.HARDWARECNT;
      this.retiredHardwareCnt = response.RETIREDHARDWARECNT;
      this.allActiveHardwareCnt = response.ALLACTIVEHARDWARECNT;
      const savedHeaders = response.HARDWAREDASHBOARDHEADERS || [];     
      if (savedHeaders.length) {
        this.selectedHeaders = [];
        for (const item of savedHeaders) {
          const inList = this.pageHeaders.find(
            t => t.key.toLowerCase() == item.toLowerCase()
          );
          if (inList) {
            // if found in main columns list
            this.selectedHeaders.push(inList.key);
          }
        }
        if (!this.selectedHeaders.length) {
          this.setDefaultSelectedHeaders();
        }
      } else {
        this.setDefaultSelectedHeaders();
      }
      const savedSorting = response.HARDWAREDASHBOARDCOLUMNORDER || []; 
      
      if (savedSorting.length) {
        let newSorting: any = [];
        for (const header of savedSorting) {
          const inList = this.pageHeaders.find(item => item.key == header);
          if (inList) {
            newSorting.push(inList);
          }
        }
        const newItems = this.pageHeaders.filter(
          item => !savedSorting.includes(item.key)
        );
        newSorting = [...newSorting, ...newItems];
        this.pageHeaders = newSorting;

        // need to sort selectedHeaders
        this.sortSelectedHeader();
      }
    }
    this.loading = false;
}

  categories(){
		var monthNames;
		monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun"
		];
    if (this.ApiMonths.length) {
      monthNames = this.ApiMonths;
    }
		var fullMonths:string[] = [];
		for (const month in monthNames) {
			if (isNaN(this.items.sortingYear)) {
				fullMonths.push(monthNames[month]);
			} else {
				fullMonths.push(monthNames[month]);
			}
		}
		return fullMonths;
	}

  setDefaultSelectedHeaders() {
    this.selectedHeaders = [
      "ENDUSER",
      "SKU",
      "ASSETNUMBER"
    ];
  }

  removeColumn(header) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerOrder) {
      const valIdx: string = this.headerOrder[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader(header);
  }

  getListHeader(key: string) {
    let ret = "";
    if (key.indexOf("customData") != -1) {
      const fieldId = key.replace("customData", "");
      const inList = this.listCustomFields.find(
        item => item.CUSTOMFIELDID == fieldId
      );
      if (inList) {
        ret = inList.CUSTOMFIELDNAME || "";
      }
    } else {
      const inList = this.pageHeaders.find(
        item => item.key.toLowerCase() == key.toLowerCase()
      );
      if (inList) {
        ret = inList.value;
      }
    }
    return ret;
  }

  async addHeader(c) {
    const addedCol = this.selectedHeaders.includes(c);
    this.sortSelectedHeader();
    colsResizeableReset();
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "hardwareDashBoard"
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    if (addedCol && c.indexOf("customData") != -1) {
      // reload list if show custom fields on list
      await this.fetchDashboardData(this.currYear);
    }
    colsResizeable();
    if (!$("body").hasClass("expand-full")) {
      $(".tablesorter.hasResizable").attr(
        "data-width",
        $(".tablesorter.hasResizable").width()
      );
    }
    this.checkColsWidth();
  }

  checkColsWidth() {
    setTimeout(() => {
      if (
        (($(".tablesorter.hasResizable").attr("data-width") === "" &&
          $(".tablesorter.hasResizable").width() > 1440) ||
          $(".tablesorter.hasResizable").attr("data-width") > 1440) &&
        !$("body").hasClass("expand-full")
      ) {
        $("body").addClass("expand-full");
        $("span.expand-full").addClass("overflowed");
      } else if ($("body").hasClass("expand-full")) {
        if ($(".tablesorter.hasResizable").attr("data-width") > 1440) {
          $("body").addClass("expand-full");
          $("span.expand-full").addClass("overflowed");
        }
      }
    }, 1);
  }

  sortSelectedHeader() {
    const newSelectedHeaders: string[] = [];
    for (const item of this.pageHeaders) {
      if (!this.selectedHeaders.includes(item.key)) continue;
      newSelectedHeaders.push(item.key);
    }
    this.selectedHeaders = newSelectedHeaders;
    
  }

  async handleListChange(event) {
    this.sortSelectedHeader();
    const dataObj = {
      controller: "Users",
      FunctionName: "sortHardwareColumns",
      userid: sessionStorage.getItem("userId"),
      params: this.pageHeaders.map(item => item.key),
      from: "hadwareDashboard"
    };
      await axios.post(dataURL + "?ReturnType=JSON", dataObj);
  }

  searchByHeader() {
    let filteredHeader = this.pageHeaders.filter(
      (item: any) =>
        item.value.toLowerCase().search(this.searchHeader.toLowerCase()) != -1
    );
    
    return filteredHeader;
  }

  clickOutsideHeader() {
    this.searchHeader = "";
  }
    allowRemoveColumn(header) {
    let ret = false;
    if (
        ![
          "ENDUSER",
          "SKU",
          "ASSETNUMBER"
        ].includes(header)
      ) {
      ret = true;
    } 
    return ret;
  }

  isRecent(logts) {
    let logDate: any = new Date(logts);
    let now: any = new Date();
    let diffInHours = Math.abs(now - logDate) / 36e5; // divide by (1000*60*60) to get difference in hours
    return diffInHours < 8;
  }
}
