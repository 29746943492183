import { render, staticRenderFns } from "./ConfirmRemoveItemModal.vue?vue&type=template&id=88d600c6&scoped=true"
import script from "./ConfirmRemoveItemModal.vue?vue&type=script&lang=tsx"
export * from "./ConfirmRemoveItemModal.vue?vue&type=script&lang=tsx"
import style0 from "./ConfirmRemoveItemModal.vue?vue&type=style&index=0&id=88d600c6&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88d600c6",
  null
  
)

export default component.exports