








































































































import axios from "axios";
import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model, Watch } from "vue-property-decorator";
import { notifier } from "../models/common";

import Loader from './Loader.vue'
import UserSuggestInput from '../components/UserSuggestInput.vue'
import BusinessLineToolTip from "../components/BusinessLineToolTip.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";

declare const $: any;
declare const dataURL: string

interface Props {
  list: any;
  loading: boolean;
  accountID: string;
  aID: number;
  assignedPercent: number;
  cOverview: any;
  currentIndex: number;
  currentItem: object;
}

interface Events {}

@Component({
  inheritAttrs: true,
  components: {
    Loader,
    UserSuggestInput,
    BusinessLineToolTip,
    ConfirmRemoveItemModal
  }
})
export default class HPAgentDetailsRowTooltip extends TSXComponent<Props, Events> {
  @Prop({ required: true })
  list!: any;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  accountID!: "";

  @Prop({ required: false, default: 0 })
  aID!: number;

  @Prop({ required: false, default: 0 })
  assignedPercent!: number;

  @Prop({ required: false, default: {} })
  cOverview!: any;

  @Prop({ required: false, default: -1 })
  currentIndex!: number;

  @Prop({ required: false, default: {HASACCESS: 0} })
  currentItem!: object;

  @Prop({ required: false, default: false })
  isPaycheck!: object;

  @Prop({ required: false, default: false })
  isInvoiceCommision!: object;

  @Prop({ required: false, default: "" })
  invoiceId!: string;

  @Prop({ required: false, default: [] })
  remainingBusinessLineComm!: any;

  @Prop({ required: true })
  businessLineSwitch!: boolean;

  $parent: any;
  userID = 0;
  userName = "";
  userType = 1;
  commAvail = 0;
  remainingComm = 0;
  totalAllocation = 0;
  isDelete = false;

  calculatingCommisions = false
  businessLineToolTip = false;
  businessLineOptions:any = [];
  businessLineId = 0;
  businessLineName = "";
  userAvailable = false;
  oldBlineComm: any = [];
  parent:any = {};
  showEdit = false;
  confirmBLineToggle = false;
  bLineLoading = false;

  async created() {
    this.remainingComm = this.remainingBusinessLineComm.length ? this.remainingBusinessLineComm[0].REMAININGCOMM : 0;
    this.commAvail = this.remainingComm;
    this.parent = this.isPaycheck ? this.$parent.$parent : this.$parent;

    this.setBusinessLines();
  }

  beforeDestroy() {
    //case use function "Allocation other than 100%": detect case to reload data
    if(this.$parent.reloadedParent != undefined && !this.$parent.reloadedParent && this.commAvail === 0 && this.$parent.viewFilters.allrows.allocMin !== "" && this.$parent.viewFilters.allrows.allocMax !== "") {
      this.$parent.reloadedParent = true;
      this.$parent.allrows_searchByAlloc();
    }
  }

  customChange(e){
    if(e == null){
      this.userID = 0;
    }
  }

  async updateCustomer(data:any = {}) {
    var isFromupdateBlineID = true;
    if (Object.keys(data).length) {
      isFromupdateBlineID = false;
      this.userName = data.account.UFNAME + " " + data.account.ULNAME;
      this.userID = data.account.USERID;
      this.userType =
        typeof data.account.TYPE != "undefined" ? data.account.TYPE : 1;
    }

    var dataObj = {
      userID: this.userID,
      percentage: 0,
      type: this.userType,
      businessLineId: this.businessLineId,
      businessLineSwitch: this.businessLineSwitch
    };
    if(this.isInvoiceCommision) {
      dataObj["controller"] = "Invoices";
      dataObj["FunctionName"] = "InvoiceCommission";
      dataObj["action"] = "checkUserAvailable";
      dataObj["invoiceId"] = this.invoiceId;
    } else {
      dataObj["controller"] = "Users";
      dataObj["FunctionName"] = "AccountCommission";
      dataObj["from"] = "checkUserAvailable";
      dataObj["aID"] = this.aID;
      dataObj["accountID"] = this.accountID;
    }
    var self = this;
    await getRouteData(dataObj).then(function(response) {
      self.userAvailable = response.data.USERAVALIABLE;
      self.commAvail = response.data.USERSEXISTINGPER;
      if (!isFromupdateBlineID) self.businessLineId = response.data.BUSINESSLINEID;
      var userPer = response.data.USERPER;   
      if (self.userAvailable) {
        var minRemainingComm = 100;
        self.oldBlineComm = JSON.parse(JSON.stringify(self.remainingBusinessLineComm));

        // calculate the remaining commission for the update operation
        self.oldBlineComm.forEach((oldBLine:any) => {
          if (!self.businessLineId) {
            oldBLine.REMAININGCOMM = oldBLine.REMAININGCOMM + userPer;
            if (!oldBLine.ID) self.remainingComm = oldBLine.REMAININGCOMM;
          }
          else if (oldBLine.ID == self.businessLineId) {
            oldBLine.REMAININGCOMM = oldBLine.REMAININGCOMM + userPer;
            self.remainingComm = oldBLine.REMAININGCOMM;
          }
          // calculate the minimum remaining commission
          if (oldBLine.ID && minRemainingComm > oldBLine.REMAININGCOMM)  minRemainingComm = oldBLine.REMAININGCOMM;
        });

        // set minimum available commission to ALL type on updating commission;
        self.oldBlineComm.every((bline:any) => {
          if(!bline.ID) {
            bline.REMAININGCOMM = minRemainingComm;
            return false;
          }
          return true;
        })
      }
    });
  }

  async addCommission() {
    var result = true;
    var realCommAvail = this.commAvail;
    $(".quick-edit-comm span.left, .quick-edit-comm span.right, .edit-comm-txt").removeClass("custom-error");
    if(this.userID == 0) {
      result = false;
    }

    var existedLinkID: any = [];
    var val: any;
    if(this.list.length) {//check if existed in list
      for(val of this.list) {
        if(val.USERID == this.userID && val.TYPE == this.userType && typeof val.LINKPAYMENTID != "undefined") {
          existedLinkID.push(val.LINKPAYMENTID);
        }
      }
      if(existedLinkID.length) {//existed, re-calculate comm available
        let total = 0;
        this.list.forEach(function(val: any, index) {
          if(val.LINKPAYMENTID != existedLinkID[0]) {
            total += parseFloat(val.PERCENTAGE);
          }
        });
        total = parseFloat(total.toFixed(2));
        realCommAvail = parseFloat((100 - total).toFixed(2));
      }
    }

    if(isNaN(this.remainingComm) || this.remainingComm <= 0 || this.remainingComm <= 0.004) {
      if(this.userID > 0) {
        $(".quick-edit-comm span.right").addClass("custom-error");
      }
      result = false;
    }
    var data = {};
    if (this.isInvoiceCommision) {
      data = {
        Controller: "Invoices",
        FunctionName: "InvoiceCommission",
        invoiceId: this.invoiceId
      }
    }
    else {
     data = {
        controller: "Finances",
        FunctionName: "GetAssginedPercent",
        accountID:  this.accountID,
      }
    }
    const responsePrecent = await axios.post(dataURL + "?ReturnType=JSON", data);
    var balanComm = 100 - responsePrecent.data.TOTALPERCENT;
    var commissionwarn = false;
    // if(balanComm < this.remainingComm){
    //   result = false;
    //   $(".quick-edit-comm span.right").addClass("custom-error")
    //   commissionwarn = true;
    // }
    if(result) {
      result = this.checkBusinessComission(this.businessLineId, this.remainingComm);
    }
   
    if (result) {
      this.calculatingCommisions = true
      try {
        if(this.isInvoiceCommision) {
          if(this.list.length != responsePrecent.data.ALLOCATIONARR.length){
            commissionwarn = true;
          }

          const commissionIndex = this.list.findIndex((commission: any) => commission.USERID === this.userID);
          if (commissionIndex > -1) {
            this.list.splice(commissionIndex, 1);
          }
          this.businessLineOptions.forEach((e) => {
            if (e.ID == this.businessLineId) this.businessLineName = e.TEXT;
          } );
          const commDetails = {
            "USERID"            : this.userID,
            "FULLNAME"          : this.userName,
            "PERCENTAGE"        : this.remainingComm,
            "BUSINESSLINEID"    : this.businessLineId,
            "BUSINESSLINENAME"  : this.businessLineName
          }
          this.list.push(commDetails);
          const response  = await axios.post(dataURL + "?ReturnType=JSON", {
            "controller"        : "Invoices",
            "FunctionName"      : "InvoiceCommission",
            "invoiceId"         : this.invoiceId,
            "newCommArr"        : this.list,
            "aID"               : this.aID,
            "action"            : "saveOverRide"
          });
          this.updateInvoiceList();
        }
        else {
          if(this.list.length != responsePrecent.data.ASSIGNEDPERCENTARR.length){
            commissionwarn = true;
          }
          const response  = await axios.post(dataURL + "?ReturnType=JSON", {
            "controller"    : "Users",
            "FunctionName"  : "AccountCommission",
            "userID"        : this.userID,
            "aID"           : this.aID,   
            "percentage"    : this.remainingComm,
            "cs_id"         : this.cOverview.MC_CSTATEMENT_ID,
            "type"          : this.userType,
            "linkPaymentId" : existedLinkID.length ? existedLinkID[0] : 0,
            "businessLineId": this.businessLineId
          });
          if(response.data.STATUS == 1) {
            this.totalAllocation = response.data.AVGALLOCATION_FORMATTED;

            this.businessLineOptions.forEach((e) => {
              if (e.ID == this.businessLineId) this.businessLineName = e.TEXT;
            } );
            // this.updateList({
            //   "ACCOUNTID"        : this.accountID,
            //   "FULLNAME"         : this.userName,
            //   "PERCENTAGE"       : this.remainingComm,
            //   "ANAME"            : "",
            //   "USERID"           : this.userID,
            //   "TYPE"             : this.userType,
            //   "LINKPAYMENTID"    : existedLinkID.length ? existedLinkID[0] : response.data.LINKPAYMENTID,
            //   "BUSINESSLINENAME" : this.businessLineName
            // });
            this.updateAllocationList();
          }
        }
        //reset
        this.userName = "";
        this.userID = 0;
        this.userType = 1;
      } catch (err) {
        // console.log(err.message);
      } finally {
        this.calculatingCommisions = false;
        this.businessLineId = 0;
      }
    }
    if(commissionwarn){
      notifier.warning("Allocation cannot exceed 100%");
      this.$emit("reload")
    }
  }

  updateList(item) {
    var existed = false;
    var index = 0;
    var doDelete = (typeof item.remove != "undefined" && item.remove == true ? true : false);
    var total = 0;
    
    if(typeof item.PERCENTAGE != "undefined") {
      item.PERCENTAGE = parseFloat(item.PERCENTAGE);
      if(item.PERCENTAGE % 1 !== 0) {
        item.PERCENTAGE = (item.PERCENTAGE).toFixed(2);
      }
    }

    for(var i in this.list) {
      if(this.list[i].LINKPAYMENTID == item.LINKPAYMENTID) {//existed
        existed = true;
        if(doDelete) {//find index
          index = parseInt(i);
        }else {//update percentage
          this.list[i].PERCENTAGE = item.PERCENTAGE;
        }
        break;
      }
    }

    if(!existed) {//insert
      this.list.push(item);
    }

    if(doDelete) {
      this.$delete(this.list, index);
    }

    //calculate on new list
    this.list.forEach(function(val: any, index) {
      val.oldPercent = val.PERCENTAGE;
      total += parseFloat(val.PERCENTAGE);
    });
    total = parseFloat(total.toFixed(2));
    this.commAvail = parseFloat((100 - total).toFixed(2));
    this.remainingComm = this.commAvail;

    //updateAllocation
    if(this.$parent.updateAllocation){
      this.$parent.updateAllocation({
        accountID: this.accountID,
        allocation: total,
        totalAllocation: this.totalAllocation,
        currentIndex: this.currentIndex
      });
    } else{
      this.$emit("updateAllocation", {
        accountID: this.accountID,
        allocation: total,
        totalAllocation: this.totalAllocation,
        currentIndex: this.currentIndex
      });
    }
  }

  async updateInvoiceList() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Invoices",
      FunctionName: "InvoiceCommission",
      invoiceId: this.$route.params.id
    });
    if (response.data.STATUS == 1) {
      this.list = typeof response.data.ALLOCATIONARR != "undefined" ? response.data.ALLOCATIONARR : [];
      this.remainingBusinessLineComm = response.data.REMAININGBUSINESSLINECOMM;
      this.remainingComm = this.remainingBusinessLineComm.length ? this.remainingBusinessLineComm[0].REMAININGCOMM : 0;
      this.commAvail = this.remainingComm;
      let commPercent = 0;
      if(this.list.length) {
        for(var commission of this.list) {
          commPercent += parseFloat(commission.PERCENTAGE);
        }
      }

      this.$emit("updateAllocation", {
        accountID: this.accountID,
        allocation: commPercent,
        currentIndex: this.currentIndex
      });
      if(!commPercent) {
        this.$emit("close");
      }
    }
  } 

  async removeCommission(item, remainingComm) {
    this.calculatingCommisions = true
    this.isDelete = true;
    try {
      if(this.isInvoiceCommision) {
        const index = this.list.findIndex(com => com.USERID === item.USERID);
        this.list.splice(index, 1);
        const response  = await axios.post(dataURL + "?ReturnType=JSON", {
          "controller"        : "Invoices",
          "FunctionName"      : "InvoiceCommission",
          "invoiceId"         : this.invoiceId,
          "newCommArr"        : this.list,
          "aID"               : this.aID,
          "action"            : "saveOverRide",
          "businessLineId": item.BUSINESSLINEID
        });
      }
      else {
        const response  = await axios.post(dataURL + "?ReturnType=JSON", {
            "controller"    : "Users",
            "FunctionName"  : "AccountCommission",
            "userID"        : item.USERID,
            "aID"           : this.aID,
            "percentage"    : 0,
            "remainingComm" : this.commAvail,
            "cs_id"         : this.cOverview.MC_CSTATEMENT_ID,
            "type"          : item.TYPE,
            "linkPaymentId" : item.LINKPAYMENTID,
            "businessLineId": item.BUSINESSLINEID
          });
        if(response.data.STATUS == 1) {
          this.totalAllocation = response.data.AVGALLOCATION_FORMATTED;
          // this.updateList({
          //   "USERID"        : item.USERID,
          //   "remove"        : true,
          //   "TYPE"          : item.TYPE,
          //   "LINKPAYMENTID" : item.LINKPAYMENTID
          // });
        }
      }
      this.updateAllocationList();
    } catch (err) {
      // console.log(err.message);
    } finally {
      this.calculatingCommisions = false
    }
  }

  async updateCommission(item) {
    var result = true;
    $(".quick-edit-comm span.left, .quick-edit-comm span.right").removeClass("custom-error");
    $(".edit-comm-txt[data-linkPaymentId=" + item.LINKPAYMENTID + "]").removeClass("custom-error");

    if(item.PERCENTAGE <= 0 || isNaN(item.PERCENTAGE) || item.PERCENTAGE <= 0.004) {
      $(".edit-comm-txt[data-linkPaymentId=" + item.LINKPAYMENTID + "]").addClass("custom-error");
      result = false;
    }

    // //cannot over max available
    // var maxAvailable = 0;
    // this.list.forEach(function(val, index) {
    //   if(val.LINKPAYMENTID != item.LINKPAYMENTID) {
    //     maxAvailable += parseFloat(val.PERCENTAGE);
    //   }
    // });
    // maxAvailable = parseFloat((100 - maxAvailable).toFixed(2));
    // if(item.PERCENTAGE > maxAvailable) {
    //   $(".edit-comm-txt[data-linkPaymentId=" + item.LINKPAYMENTID + "]").addClass("custom-error");
    //   result = false;
    // }

    if(result) {
      var minRemainingComm = 100;
      this.oldBlineComm = JSON.parse(JSON.stringify(this.remainingBusinessLineComm));
      var userPer = item.oldPercent;

        // calculate the remaining commission for the update operation
        this.oldBlineComm.forEach((oldBLine:any) => {
          if (oldBLine.ID == item.BUSINESSLINEID) {
            oldBLine.REMAININGCOMM = oldBLine.REMAININGCOMM + userPer;
          }
        });

      result = this.checkBusinessComission(item.BUSINESSLINEID, item.PERCENTAGE, true);
    }

    if(result == true) {
      this.calculatingCommisions = true

      try {
        const response  = await axios.post(dataURL + "?ReturnType=JSON", {
            "controller"    : "Users",
            "FunctionName"  : "AccountCommission",
            "userID"        : item.USERID,
            "aID"           : this.aID,
            "percentage"    : item.PERCENTAGE,
            "cs_id"         : this.cOverview.MC_CSTATEMENT_ID,
            "type"          : item.TYPE,
            "linkPaymentId" : item.LINKPAYMENTID,
            "businessLineId": item.BUSINESSLINEID
          });
        if(response.data.STATUS == 1) {
          this.totalAllocation = response.data.AVGALLOCATION_FORMATTED;
          // this.updateList({
          //   "USERID"        : item.USERID,
          //   "PERCENTAGE"    : item.PERCENTAGE,
          //   "TYPE"          : item.TYPE,
          //   "LINKPAYMENTID" : item.LINKPAYMENTID
          // });
          this.updateAllocationList();
        }
      } finally {
        this.calculatingCommisions = false
      }
    } else {
      $(".edit-comm-txt[data-linkPaymentId=" + item.LINKPAYMENTID + "]").addClass("custom-error");
    }
  }

  checkPercent(item) {
    var domItem = $(".edit-comm-txt[data-linkPaymentId=" + item.LINKPAYMENTID + "]");
    if(domItem.hasClass("custom-error")) {
      item.PERCENTAGE = item.oldPercent;
      domItem.removeClass("custom-error");
    }
  }

  clickOutside() {
    if(!this.calculatingCommisions && !this.isDelete){
      this.$emit('close');
      // console.log("clickOutside",this.calculatingCommisions)
    }
    this.isDelete = false;
  }

  updatebusinessID(id) {
    this.businessLineId = id;
    this.updateCustomer();

    var bLineComm = this.userAvailable ? this.oldBlineComm : this.remainingBusinessLineComm;

    bLineComm.every((bLine: any) => {
      if (bLine.ID == id) {
        this.remainingComm = this.commAvail = bLine.REMAININGCOMM;
        return false;
      }
      return true;
    });
    this.$forceUpdate();
  }

  checkBusinessComission(id, comm, fromUpdate = false) {
    var result = true;
    var bLineComm = (this.userAvailable || fromUpdate) ? this.oldBlineComm : this.remainingBusinessLineComm;

    bLineComm.every((bLine:any) => {
      if (bLine.ID == id) {
        if (comm > bLine.REMAININGCOMM) {
          notifier.warning(
            `The Commission Percentage field must be less than ${bLine.REMAININGCOMM} for ${bLine.BUSINESSLINENAME}`
          );
          result = false;
          return false;
        }
      }
      return true;
    });
    return result;
  }

  async updateAllocationList() {
    const responselist = await axios.post(dataURL + "?ReturnType=JSON", {
      Controller: "Finances",
      FunctionName: "GetAssginedPercent",
      accountID: this.accountID
    });

    if (responselist.data.STATUS == 1) {
      this.list = this.parent.allocationTooltipList = responselist.data.ASSIGNEDPERCENTARR;
      this.list.forEach(function(val: any) {
        val.oldPercent = val.PERCENTAGE;
      });
      this.aID = responselist.data.aID;
      this.remainingComm = responselist.data.REMAININGCOMM;
      this.remainingBusinessLineComm = this.parent.remainingBusinessLineComm =  responselist.data.REMAININGBUSINESSLINECOMM;
      this.parent.businessLines = responselist.data.COMMISIONBLINES;

      //updateAllocation
      if(this.$parent.updateAllocation){
        this.$parent.updateAllocation({
          accountID: this.accountID,
          allocation: responselist.data.TOTALALLOCATION,
          totalAllocation: this.totalAllocation,
          currentIndex: this.currentIndex
        });
      } else{
        this.$emit("updateAllocation", {
          accountID: this.accountID,
          allocation: responselist.data.TOTALALLOCATION,
          totalAllocation: this.totalAllocation,
          currentIndex: this.currentIndex
        });
      }
    }
    this.checkEdit();
  }

  checkEdit() {
    let count = 0;
    this.remainingBusinessLineComm.forEach((bLine:any) => {
      if(bLine.REMAININGCOMM) {
        count++
      }
    });
    this.showEdit = count ? true : false;
  }

  async autoBusinessLineSwitch() {
    this.bLineLoading = true;
    this.businessLineSwitch = !this.businessLineSwitch;

    var dataObj = {
      action             : "businessLine_update",
      businessLineSwitch : this.businessLineSwitch
    }

    if(this.isInvoiceCommision) {
      dataObj["controller"] = "Invoices";
      dataObj["FunctionName"] = "InvoiceCommission";
      dataObj["invoiceId"] = this.invoiceId;
    } else {
      dataObj["controller"] = "Users";
      dataObj["FunctionName"] = "AccountCommission";
      dataObj["aID"] = this.aID;
    }

    const response = await axios.post(dataURL + "?ReturnType=JSON",
      dataObj
    );

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }
    
    if(response.data.STATUS == 1) {
      this.setBusinessLines();
      this.updateAllocationList();
      this.confirmBLineToggle = false;
      this.bLineLoading = false;
    }
  }

  async setBusinessLines() {
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "VARSources",
      FunctionName: "GlobalParams",
      subsystem: "VAR360",
      action: "list"
    });
    this.businessLineOptions = [];
    if(this.businessLineSwitch) {
      (response.data.businessLineOptions || []).map(item => (
        this.businessLineOptions.push({
          ID: item.ID,
          TEXT: item.BUSINESSLINENAME,
          data: item
        })
      ));
      this.businessLineId = 1;
    } else {
      this.businessLineOptions.push({
        ID: 0,
        TEXT: "ALL"
      });
      this.businessLineId = 0;
    }
    this.checkEdit();
  }
}
