





































































































































import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import { ApiHelper } from "@/helpers/all";
import { v4 as uuidv4 } from "uuid";

interface Props {}

interface Events {}

@Component({
  inheritAttrs: false,
  components: {
    Datepicker
  }
})

export default class CalendarView extends TSXComponent<Props, Events> {
  @Prop({ required: false, default: {} })
  scheduleDetails!: any;

  @Prop({ required: false, default:[] })
  timeSlots!: any[];

  @Prop({ required: false, default:{} })
  available!: any;

  @Prop({ required: false, default:[] })
  exceptions!: any[];

  @Prop({ required: false, default:0 })
  availabilityId!: number;

  @Prop({ required: false, default:0 })
  deploymentId!: number;

  editSchedule: boolean = false;
  scheduleDate: string = "";
  initDate: string = "";
  isOverride: boolean = false;
  timeId: string = "";
  saving: boolean = false;
  isCompleted: boolean = false;
  selectedTimeSlots: any = [];
  timeInfo: any = {};
  datePickerKey: string = uuidv4();

  mounted() {
    $(this.$refs.modal).modal("show");

    $(this.$refs.modal).on("hide.bs.modal", () => {
      this.$emit("close");
    });
  }

  created() {
    this.scheduleDate = this.scheduleDetails.SCHEDULEDATE;
  }

  getCriteriaValues(endUserId = 0) {
    if (!endUserId) return "";
    let ret = [];
    ret = (this.scheduleDetails.CRITERIA || []).filter(item => item.CUSTOMVALUE != "").map(item => `${item.CUSTOMFIELDNAME}: ${item.CUSTOMVALUE}`);

    return ret;
  }

  async toggleScheduleEdit() {
    this.editSchedule = !this.editSchedule;
    if (this.editSchedule) {
      await this.changeEndUser(this.scheduleDetails.USERID);
    }
  }

  async addSchedule() {
    const selectedTime = this.timeSlots.find(item => item.id == this.timeId);
    this.$emit("addSchedule", {
      endUserId: this.scheduleDetails.USERID, 
      timeSlot: selectedTime,
      selectedDate: this.scheduleDate
    });
  }

  async changeEndUser(userId) {
    const scheduleATimeRes = await ApiHelper.callApi("post", {
      controller: "Deployments",
      FunctionName: "portalGetScheduleATime",
      deploymentId: this.deploymentId || 0,
      endUserId: userId,
      varId: this.$route.params.aID
    });
    if (scheduleATimeRes.STATUS == 1) {
      this.selectedTimeSlots = scheduleATimeRes.selectedTimeSlots.filter(
        item => (item.ENDUSERID || 0) != userId
      );
      const scheduleATime = scheduleATimeRes.scheduleATime || [];
      const timeInfo = scheduleATime.find(item => item.ENDUSERID == userId);
      if (timeInfo) {
        this.timeInfo = timeInfo;
        this.availabilityId = timeInfo.AVAILABILITYID || 0;

        // apply if selected a schedule date
        this.scheduleDate = timeInfo.SCHEDULEDATE;
        this.isOverride = timeInfo.ISOVERRIDE || 0 ? true : false;
        this.initDate = this.scheduleDate;
        const selectedArr: any = [];
        const startFull = `${timeInfo.STARTTIME} ${timeInfo.STARTTIMEPERIOD}`;
        const endFull = `${timeInfo.ENDTIME} ${timeInfo.ENDTIMEPERIOD}`;
        if (startFull) {
          selectedArr.push(startFull);
        }
        if (endFull) {
          selectedArr.push(endFull);
        }
        const selectedText = selectedArr.join(" - ");
        const inList = this.timeSlots.find(
          item => item.text.toUpperCase() == selectedText.toUpperCase()
        );
        if (inList) {
          this.timeId = inList.id;
          this.isCompleted = true;
        } else {
          this.isCompleted = false;
        }
      } else {
        this.isCompleted = false;
      }

      if (this.initDate) {
        this.scheduleDate = this.initDate;
      }
    }
  }

  dayChange(selected) {
    this.$emit("dayChange", selected)
  }
}
