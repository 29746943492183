import { render, staticRenderFns } from "./ModalEditCommTarget.vue?vue&type=template&id=36ecb660&scoped=true"
import script from "./ModalEditCommTarget.vue?vue&type=script&lang=tsx"
export * from "./ModalEditCommTarget.vue?vue&type=script&lang=tsx"
import style0 from "./ModalEditCommTarget.vue?vue&type=style&index=0&id=36ecb660&prod&scoped=true&lang=less"
import style1 from "./ModalEditCommTarget.vue?vue&type=style&index=1&id=36ecb660&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ecb660",
  null
  
)

export default component.exports