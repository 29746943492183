











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component as TSXComponent } from "vue-tsx-support";
import SortIndicator from "../components/SortIndicator.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import Pagination from "../components/Pagination.vue";
declare function require(params: any): any;

import {
  InvoiceAPIRepo,
  Filters,
  Sort,
  SortField,
  InvoiceStatus,
  InvoiceReportStatus,
  invoiceTypeName
} from "../repos/InvoiceAPIRepo";
import {
  notifier,
  downloadFile,
  printHtml,
  downloadFileUrl
} from "../models/common";
import { Invoice } from "../models/invoice";
import ExportModal, { ExportFormat } from "../components/ExportModal.vue";
import FilterCurrencyBox from "../components/FilterCurrencyBox.vue";
import { UserAPIRepo } from "../repos/UserAPIRepo";
import CustomerSearchTooltip from "../components/CustomerSearchTooltip.vue";
import ConfirmRemoveItemModal from "../components/ConfirmRemoveItemModal.vue";
import InvoiceDepositModal from "../components/InvoiceDepositModal.vue";
import FilterModal from "../components/FilterModal.vue";
import ReportingStatusModal from "../components/ReportingStatusImport.vue";
import moment from "moment";
import {colsResizeable, colsResizeableReset, getDateRangeFilter, priceTypeStr} from "@/helpers/ApiHelper";
import FilterStateBox from "../components/FilterStateBox.vue";

const template = require("../templates/invoices/invoiceList.handlebars");

const invoiceRepo = new InvoiceAPIRepo();
const userRepo = new UserAPIRepo();

declare const $: any;
declare const dataURL: string;

@Component({
  inheritAttrs: false,
  components: {
    Pagination,
    SortIndicator,
    FilterCurrencyBox,
    ExportModal,
    CustomerSearchTooltip,
    ConfirmRemoveItemModal,
    InvoiceDepositModal,
    FilterModal,
	  ReportingStatusModal,
    FilterStateBox
  },
  methods: {
    priceTypeStr,
    getDateRangeFilter: (from, to) => {
      return getDateRangeFilter(from, to);
    }
  }
})
export default class Invoices extends TSXComponent<void> {
  selectedID: string[] = [];
  $allowedFunctions: any;
  $userFunctions: any;

  loading = false;
  removeLoading = false;
  confirmFileRemove = false;
  viewType = "CurrentUsers";
  currentRole = sessionStorage.getItem("userRole");
  showFilter = 0;
  removeInvoice = [];
  invoicesData: Invoice.Root | any = {};
  selectPageAll = false;
  newPageInvoice: (number | undefined)[] = [];
  totalInvoices: (string | undefined)[] = [];
  selectedPage = 0;
  filters: any = {
    fYear: undefined,
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    searchAName: "",
    sDate: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    eDate: undefined,
    AccountTagID: [],
    groupIDs: [],
    resellerCheckList: [],
    selectedAccs: [],
    sStatus: [],
    sTaxMin: undefined,
    sTaxMax: undefined,
    paidsDate: undefined,
    paideDate: undefined,
    InvoicedPercentMin: undefined,
    InvoicedPercentMax: undefined,
    InvoicedProfitMin: undefined,
    InvoicedProfitMax: undefined,
    // accountTagName: [],
    selectedAccsName: [],
    // groupName: [],
    filterGrandTotal: undefined,
    filterTax: undefined,
    filterInvoiceProfit: undefined,
    filterDueDate: undefined,
    filterPaidDate: undefined,
    statusName: [],
    filterInvoiced: undefined,
    invoiceStartDate: undefined,
    invoiceEndDate: undefined,
    filterInvoiceDate: undefined,
    searchMail: "",
    reportStatus: [],
    reportName: [],
    gTagIDs: [],
    sSource: [],
    sourceName: [],
    iType: [],
    typeName: [],
    from: undefined,
    deptName: undefined,
    endUserName: undefined,
    bAddress: "",
    searchPaymentRef: "",
    bState: "",
    iAddress: "",
    iState: "",
    UnpaidProfitMin: undefined,
    UnpaidProfitMax: undefined,
    PaidProfitMin: undefined,
    PaidProfitMax: undefined,
    taxableTotalMin: undefined,
    taxableTotalMax: undefined,
    filterTaxableTotal: undefined
  };
  sort: Sort = {
    field: null,
    direction: {
      [SortField.Id]: 1,
      [SortField.Name]: 1,
      [SortField.Customer]: 1,
      [SortField.Total]: 1,
      [SortField.Date]: 1,
      [SortField.Tax]: 1,
      [SortField.PaidDate]: 1,
      [SortField.InvoicedPercent]: 1,
      [SortField.InvoiceProfit]: 1,
      [SortField.InvoiceDate]: 1,
      [SortField.InvoiceMail]: 1,
      [SortField.InvoiceReport]: 1,
      [SortField.InvoiceType]: 1,
      [SortField.DepartmentName]: 1,
      [SortField.EndUserName]: 1,
      [SortField.bAddress]: 1,
      [SortField.bState]: 1,
      [SortField.iAddress]: 1,
      [SortField.iState]: 1,
      [SortField.PaymentRef]: 1,
      [SortField.taxableTotal]: 1
    }
  };

  position = {
    searchId : 1,
    searchName : 2,
    searchAccountName : 3,
    accountTagName : 4,
    selectedAccsName : 5,
    groupName : 6,
    filterGrandTotal : 7,
    filterTax : 8,
    filterInvoiceProfit : 9,
    filterInvoiceDate : 10,
    filterDueDate : 11,
    filterPaidDate : 12,
    statusName : 13,
    filterInvoiced : 14,
    searchMail : 15,
    reportName : 16,
    sourceName: 17,
    typeName: 18,
    searchPaymentRef: 19,
    iAddress: 23,
    iState: 24,
    filterTaxableTotal : 25
  };
  pageNumber = sessionStorage.pageDetails && JSON.parse(sessionStorage.pageDetails)["Invoices"] ? JSON.parse(sessionStorage.pageDetails)["Invoices"] : 1 ;
  items: Invoice.Invoices[] = [];

  exportModalVisible = false;
  reportingStatusModalVisible = false;
  customerTooltipVisibleIndex = -1;
  customerTooltipLoading = false;
  // confirmRemoveModalVisible = false
  selectedAll = false;
  excludedIDs: string[] = [];
  selectedView = false;
  accountTags: any = [];
  searchTagsTxt = "";
  isActive = "customerAccounts";
  varAccs: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  varAccsList: {
    AID?: number;
    APRIMID?: number;
    ACCOUNTID?: string;
    ANAME?: string;
    ACCOUNTALIAS?: string;
  }[] = [];
  selectedAccs: number[] = [];
  searchAccTxt = "";
  resellers: { ACCOUNTID?: number }[] = [];
  reportingDateCheck = 0;
  depositModalVisible = false;
  statusUncheckedAll = false;
  reportUncheckedAll = false;
  filterModalVisible = false;
  disableSelectedHeader = false;
  usedFilters = {
    searchId: {
			header: "Invoice ID",
			function: "resetSearchById"
		},
    searchName: {
			header: "Customer PO",
			function: "resetSearchByName"
		},
    searchAccountName: {
			header: "Customer",
			function: "resetSearchByAccountName"
		},
    selectedAccsName: {
			header: "Customer Accounts",
			function: "resetAcc"
		},
    accountTagName: {
			header: "Customer Tags",
			function: "resetTag"
		},
    groupName: {
			header: "Customer Groups",
			function: "resetTag"
		},
    filterGrandTotal: {
			header: "Grand Total",
			function: "resetSearchByTotal"
		},
    filterTax: {
			header: "Tax",
			function: "resetSearchByTax"
		},
    filterInvoiceProfit: {
			header: "Invoice Profit",
			function: "resetSearchByInvoiceProfit"
		},
    filterInvoiceDate: {
			header: "Invoice Date",
			function: "resetSearchByInvoiceDate"
		},
    filterDueDate: {
			header: "Due Date",
			function: "resetSearchByDate"
		},
    filterPaidDate: {
			header: "Paid Date",
			function: "resetSearchByPaidDate"
		},
    statusName: {
			header: "Status",
			function: "resetStatusList"
		},
    filterInvoiced: {
			header: "Invoiced %",
			function: "resetSearchByInvoicedPercent"
		},
    searchMail: {
			header: "Invoice Email",
			function: "resetSearchByMail"
		},
    reportName : {
			header: "Reported",
			function: "resetReporteList"
    },
    sourceName: {
      header: "Source",
      function: "resetSourceList"
    },
    endUserName: {
      header: "End User Name",
      function: "resetSearchByEndUserName"
    },
    deptName: {
      header: "Department Name",
      function: "resetSearchByDeptName"
    },
    typeName: {
      header: "Invoice Type",
      function: "resetInvoiceTypeList"
    },
    bAddress: {
      header: "Billing Address",
      function: "resetSearchBybAddress"
    },
    bState: {
      header: "Billing State",
      function: "resetSearchBybState"
    },
    iAddress: {
      header: "Shipping Address",
      function: "resetSearchByiAddress"
    },
    iState: {
      header: "Shipping State",
      function: "resetSearchByiState"
    },
    searchPaymentRef: {
      header: "Payment Reference",
      function: "resetSearchByPaymentRef"
    },
    filterTaxableTotal: {
      header: "Taxable Total",
      function: "resetFilterTaxableTotal"
    }
  }
  dueStartDate: (string | undefined);
  dueEndDate: (string | undefined);
  paidStartDate: (string | undefined);
  paidEndDate: (string | undefined);
  invStartDate: (string | undefined);
  invEndDate: (string | undefined);
  searchHeader = "";
  pageHeaders: any = {
    INVOICEID: "Invoice Id",
    POID: this.currentRole === "Customer" ? "Purchase Order" : "Customer PO",
    ACCOUNTNAME: this.currentRole === "Customer" ? "Reseller" : "Customer",
    INVOICETOTALFORMATTED: "Grand Total",
    INVOICETAX: "Tax",
    TOTALTAXABLE: "Taxable Total",
    INVOICEPROFIT: "Invoice Profit",
    IDATEFORMATTED: "Invoice Date",
    DUEDATE: "Due Date",
    INVOICEPAIDDATE: "Paid Date",
    STATUSASTEXT: "Status",
    INVOICEDPERCENT: "Invoiced %",
    INVOICEEMAIL: "Invoice Email",
    INVOICEREPORTED: "Reported",
    SOURCE: "Source",
    INVOICETYPE: "Invoice Type",
    ENDUSERNAME: "End User Name",
    DEPARTMENTNAME: "Department Name",
    BADDRESS: "Billing Address",
    BSTATE: "Billing State",
    IADDRESS: "Shipping Address",
    ISTATE: "Shipping State",
    PAYMENTID: "Payment Id",
    UNPAIDPROFITFORMATTED: "Unpaid Profit",
    PAIDPROFITFORMATTED: "Paid Profit",
    ACCOUNTNAMEFORCUSTOMER: "Customer"
  };
  headerInvoice = [
    "INVOICEID",
    "POID",
    "ACCOUNTNAME",
    "INVOICETOTALFORMATTED",
    "INVOICETAX",
    "TOTALTAXABLE",
    "INVOICEPROFIT",
    "IDATEFORMATTED",
    "DUEDATE",
    "INVOICEPAIDDATE",
    "STATUSASTEXT",
    "INVOICEDPERCENT",
    "INVOICEEMAIL",
    "INVOICEREPORTED",
    "SOURCE",
    "ENDUSERNAME",
    "DEPARTMENTNAME",
    "INVOICETYPE",
    "BADDRESS",
    "BSTATE",
    "IADDRESS",
    "ISTATE",
    "PAYMENTID",
    "UNPAIDPROFITFORMATTED",
    "PAIDPROFITFORMATTED"
  ];
  selectedHeaders = [
    "INVOICEID",
    "INVOICETOTALFORMATTED",
    "DUEDATE",
    "STATUSASTEXT",
    "ACCOUNTNAMEFORCUSTOMER"
  ];

  exportHeadersArr: {
    header: string;
    column: string;
    widthPercent: string;
  }[] = [];
  CurrentView = "My Invoices";
  usedFilterDetails: any = [];
  searchFilters: any = {
    fYear: undefined,
    from: undefined,
    searchAll: "",
    searchId: "",
    searchName: "",
    searchAccountName: "",
    searchAName: "",
    sDate: undefined,
    sTotalMin: undefined,
    sTotalMax: undefined,
    eDate: undefined,
    AccountTagID: [],
    groupIDs: [],
    resellerCheckList: [],
    selectedAccs: [],
    sStatus: [],
    sTaxMin: undefined,
    sTaxMax: undefined,
    paidsDate: undefined,
    paideDate: undefined,
    InvoicedPercentMin: undefined,
    InvoicedPercentMax: undefined,
    InvoicedProfitMin: undefined,
    InvoicedProfitMax: undefined,
    // accountTagName: [],
    selectedAccsName: [],
    groupName: [],
    filterGrandTotal: undefined,
    filterTax: undefined,
    filterInvoiceProfit: undefined,
    filterDueDate: undefined,
    filterPaidDate: undefined,
    statusName: [],
    filterInvoiced: undefined,
    invoiceStartDate: undefined,
    invoiceEndDate: undefined,
    filterInvoiceDate: undefined,
    searchMail: "",
    reportStatus: [],
    reportName: [],
    gTagIDs: [],
    sSource: [],
    sourceName: [],
    iType: [],
    typeName: [],
    iAddress: "",
    iState: "",
    searchPaymentRef: undefined,
    UnpaidProfitMin: undefined,
    UnpaidProfitMax: undefined,
    PaidProfitMin: undefined,
    PaidProfitMax: undefined,
    taxableTotalMin: undefined,
    taxableTotalMax: undefined,
    filterTaxableTotal: undefined
  };
  invoiceTypeUncheckedAll = false;

  resetFilters() {
    this.filters.from = undefined;
  }

  async created() {
    if (this.currentRole === "Reseller") {
      delete this.pageHeaders.ACCOUNTNAMEFORCUSTOMER;
      this.selectedHeaders = this.selectedHeaders.filter(t => t != "ACCOUNTNAMEFORCUSTOMER");
    }

    if (typeof this.$route.query.from === "string") {
      this.filters.from = this.$route.query.from|| undefined;
      const currentQueryParam = { ...this.$route.query };
      this.$delete(currentQueryParam, "from");
      this.$router.replace({ query: currentQueryParam });
    }
    if (typeof this.$route.query.fYear === "string") {
      this.filters.fYear = this.$route.query.fYear|| undefined;
    }
    if (typeof this.$route.query.invoiceStartDate === "string") {
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate = this.$route.query.invoiceStartDate|| undefined;
    }
    if (typeof this.$route.query.invoiceEndDate === "string") {
      this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate = this.$route.query.invoiceEndDate|| undefined;
    }
    if (typeof this.$route.query.tagIDs === "string") {
      this.filters.AccountTagID = [parseInt(this.$route.query.tagIDs)];
    } else if (Array.isArray(this.$route.query.tagIDs)) {
      this.filters.AccountTagID = this.$route.query.tagIDs.map(s =>
        parseInt(s || "0")
      );
    }
    // if (typeof this.$route.query.accountTagName === "string") {
    //   this.filters.accountTagName.push(this.$route.query.accountTagName);
    // } else if (Array.isArray(this.$route.query.accountTagName)) {
    //   this.$route.query.accountTagName.map(s => {
    //     this.filters.accountTagName.push(s || "");
    //   });
    // }
    if (typeof this.$route.query.gTagIDs === "string") {
      this.filters.gTagIDs = [parseInt(this.$route.query.gTagIDs)];
    } else if (Array.isArray(this.$route.query.gTagIDs)) {
      this.filters.gTagIDs = this.$route.query.gTagIDs.map(s =>
        parseInt(s || "0")
      );
    }
    // if (typeof this.$route.query.gTagName === "string") {
    //   this.filters.accountTagName.push(this.$route.query.gTagName);
    // } else if (Array.isArray(this.$route.query.gTagName)) {
    //   this.$route.query.gTagName.map(s => {
    //     this.filters.accountTagName.push(s || "");
    //   });
    // }
    if (typeof this.$route.query.groupTagIDs === "string") {
      this.filters.groupIDs = [parseInt(this.$route.query.groupTagIDs)];
    } else if (Array.isArray(this.$route.query.groupTagIDs)) {
      this.filters.groupIDs = this.$route.query.groupTagIDs.map(s =>
        parseInt(s || "0")
      );
    }
    // if (typeof this.$route.query.groupName === "string") {
    //   this.filters.groupName = [this.$route.query.groupName];
    // } else if (Array.isArray(this.$route.query.groupName)) {
    //   this.filters.groupName = this.$route.query.groupName.map(s =>
    //     (s || "")
    //   );
    // }
    if (typeof this.$route.query.viewType === "string") {
      this.selectedView = true;
      this.viewType = this.$route.query.viewType;
      this.CurrentView = this.viewType == "All" ? "Company Invoices" : "My Invoices";
    }
    if (typeof this.$route.query.sTotalMin === "string") {
      this.filters.sTotalMin = this.searchFilters.sTotalMin = this.$route.query.sTotalMin;
    }
    if (typeof this.$route.query.sTotalMax === "string") {
      this.filters.sTotalMax = this.searchFilters.sTotalMax = this.$route.query.sTotalMax;
    }
    if (typeof this.$route.query.sStatus === "string") {
      this.filters.sStatus = [parseInt(this.$route.query.sStatus)];
    } else if (Array.isArray(this.$route.query.sStatus)) {
      this.filters.sStatus = this.$route.query.sStatus.map(s =>
        parseInt(s || "0")
      );
    }
    if (typeof this.$route.query.sTaxMin === "string") {
      this.filters.sTaxMin = this.searchFilters.sTaxMin = this.$route.query.sTaxMin;
    }
    if (typeof this.$route.query.sTaxMax === "string") {
      this.filters.sTaxMax = this.searchFilters.sTaxMax = this.$route.query.sTaxMax;
    }
    if (typeof this.$route.query.InvoicedPercentMin === "string") {
      this.filters.InvoicedPercentMin = this.searchFilters.InvoicedPercentMin = this.$route.query.InvoicedPercentMin;
    }
    if (typeof this.$route.query.InvoicedPercentMax === "string") {
      this.filters.InvoicedPercentMax = this.searchFilters.InvoicedPercentMax = this.$route.query.InvoicedPercentMax;
    }
    if (typeof this.$route.query.InvoicedProfitMin === "string") {
      this.filters.InvoicedProfitMin = this.searchFilters.InvoicedProfitMin = this.$route.query.InvoicedProfitMin;
    }
    if (typeof this.$route.query.InvoicedProfitMax === "string") {
      this.filters.InvoicedProfitMax = this.searchFilters.InvoicedProfitMax = this.$route.query.InvoicedProfitMax;
    }
    if (typeof this.$route.query.UnpaidProfitMin === "string") {
      this.filters.UnpaidProfitMin = this.searchFilters.UnpaidProfitMin = this.$route.query.UnpaidProfitMin;
    }
    if (typeof this.$route.query.UnpaidProfitMax === "string") {
      this.filters.UnpaidProfitMax = this.searchFilters.UnpaidProfitMax = this.$route.query.UnpaidProfitMax;
    }
    if (typeof this.$route.query.PaidProfitMin === "string") {
      this.filters.PaidProfitMin = this.searchFilters.PaidProfitMin = this.$route.query.PaidProfitMin;
    }
    if (typeof this.$route.query.PaidProfitMax === "string") {
      this.filters.PaidProfitMax = this.searchFilters.PaidProfitMax = this.$route.query.PaidProfitMax;
    }
    if (typeof this.$route.query.action === "string" && this.$route.query.action == 'invoiceDeposits' && this.currentRole === "Reseller") {
      this.depositModalVisible = true;
    }

    await this.fetchData(1, true);
    this.accsLoad();

    if (this.currentRole === "Customer") {
      const response = await userRepo.getResellers();
      this.resellers = response.RESELLERS;
    }
  }
  
  @Watch("selectedHeaders")
  colsChange(val: any) {
    colsResizeable();
  }

  get query() {
    const query = Object.entries(this.filters).reduce((a, [k, v]) => {
      if (Array.isArray(v)) {
        return v.length === 0 ? a : { ...a, [k]: v };
      }

      return v == null || v === "" ? a : { ...a, [k]: v };
    }, {});
    return query;
  }
  // showConfirmRemoveItemModal() {
  //   this.confirmRemoveModalVisible = true
  // }
  hasChangedAccountFilters = false;
  hasChangedSourceFilters = false;
  hasChangedStatusFilters = false;
  hasChangedTypeFilters = false;

  async fetchData(load = 1, initialLoad: boolean = false) {
    this.hasChangedAccountFilters = false;
    this.hasChangedSourceFilters = false;
    this.hasChangedStatusFilters = false;
    this.hasChangedTypeFilters = false;
    await this.$router
      .replace({ name: this.$route.name, query: this.query })
      .catch(tmp => {
      });
    if (load) {
      this.loading = true;
    }
    try {
      // storing filter details in session for go back filter result
			if (JSON.parse(sessionStorage.filterDetails)["Invoices"]) {
				var exisitngFilterDetails = JSON.parse(sessionStorage.filterDetails)
				exisitngFilterDetails["Orders"] = {};
				exisitngFilterDetails["Quotes"] = {};
				exisitngFilterDetails["Invoices"] = this.filters;
				exisitngFilterDetails["Opportunities"] = {};
				if (this.query != "undefined" && Object.keys(this.query).length != 0) {
					sessionStorage.setItem('filterDetails',JSON.stringify(exisitngFilterDetails));
				}
      }
			if((this.query == "undefined" || (this.query != "undefined" && Object.keys(this.query).length == 0)) && Object.keys(JSON.parse(sessionStorage.filterDetails).Invoices).length != 0){
				this.filters = this.searchFilters = JSON.parse(sessionStorage.filterDetails).Invoices;
      }
      if (this.statusUncheckedAll) {
        this.filters.sStatus = [];
        this.filters.statusName = [];
      }
      if (this.reportUncheckedAll) {
        this.filters.reportStatus = [];
        this.filters.reportName = [];
      }
      if (this.invoiceTypeUncheckedAll) {
        this.filters.iType = [];
        this.filters.typeName = [];
      }
      if (this.filters.reportStatus.length == 0 && initialLoad) {
        this.filters.reportStatus = [0,1];
      }
      if (!this.filters.iType.length && initialLoad) {
        this.filters.iType = [1, 2, 3, 4, 5];
      }

      const invoicesData = await invoiceRepo.find(
        this.filters,
        this.sort,
        this.pageNumber,
        this.viewType,
        this.selectedView,
        initialLoad
      );
      if (this.filters.sStatus.length == 0 && initialLoad) {
        this.filters.sStatus = this.currentRole == "Reseller" ? [1, 2, 3, 0, 4] : [1, 0, 4, 2];
      }
      this.invoicesData = invoicesData;
      this.items = invoicesData.INVOICES;
      this.reportingDateCheck = this.invoicesData.REPORTINGDATECHECK;
      if (
        this.filters.eDate != undefined ||
        this.filters.sDate != undefined ||
        this.filters.sTotalMax != undefined ||
        this.filters.sTotalMin != undefined ||
        this.filters.searchAccountName != "" ||
        this.filters.searchAName != "" ||
        this.filters.searchAll != "" ||
        this.filters.searchId != "" ||
        this.filters.searchName != "" ||
        this.filters.AccountTagID.length != 0 ||
        this.filters.groupIDs.length != 0 ||
        this.filters.selectedAccs.length != 0 ||
        (this.filters.sStatus.length != 0 && this.filters.sStatus.length != 4) ||
        this.filters.sTaxMax != undefined ||
        this.filters.sTaxMin != undefined ||
        this.filters.taxableTotalMax != undefined ||
        this.filters.taxableTotalMin != undefined ||
        this.filters.paidsDate != undefined ||
        this.filters.paideDate != undefined ||
        this.filters.InvoicedPercentMin != undefined ||
        this.filters.InvoicedPercentMax != undefined ||
        this.filters.InvoicedProfitMin != undefined ||
        this.filters.InvoicedProfitMax != undefined ||
        this.filters.UnpaidProfitMin != undefined ||
        this.filters.UnpaidProfitMax != undefined ||
        this.filters.PaidProfitMin != undefined ||
        this.filters.PaidProfitMax != undefined ||
        this.filters.invoiceStartDate != undefined ||
        this.filters.invoiceEndDate != undefined ||
        this.filters.searchMail != "" ||
        this.filters.deptName != "" ||
        this.filters.endUserName != "" ||
        this.filters.reportStatus.length != 0 ||
        this.filters.gTagIDs.length != 0 ||
        this.filters.iType.length != 0 ||
        this.filters.bAddress.length ||
        this.filters.bState.length 
      ) {
        this.showFilter = 1;
      } else {
        this.showFilter = 0;
      }
      this.customerTooltipVisibleIndex = -1;
      if (this.searchTagsTxt == "") {
        this.accountTags = this.invoicesData.accountTags || [];
      }
      if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.groupIDs && this.filters.groupIDs.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length)) {
        this.selectedView = true;
        this.CurrentView = this.getCurrentViewTitle();
      } else {
        this.selectedView = false;
      }

      if (
        !this.selectedView &&
        typeof invoicesData["userSelectedCurrentView"] != "undefined"
      ) {
        this.selectedView = true;
        this.viewType = invoicesData["userSelectedCurrentView"];
        this.CurrentView = this.viewType == "All" ? "Company Invoices" : "My Invoices";
      }

      if(this.currentRole == "Reseller" && typeof this.invoicesData.sourcesObj != 'undefined' && this.filters.sSource.length == 0 && initialLoad) {
        this.filters.sSource = Object.keys(this.invoicesData.sourcesObj);
        this.filters.sourceName = Object.values(this.invoicesData.sourcesObj);
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.loading = false;
    }
    //var checkAllPages = this.newPageInvoice.includes(this.pageNumber);
    //if (checkAllPages)  {
      //this.totalInvoices = [];
      //for (var val of this.items) {
        //if (!this.excludedIDs.includes(val.INVOICEID)) {
        //	this.totalInvoices.push(val.INVOICEID);
        //}
      //}
      //var intersectingArrays = this.getArraysIntersection(this.totalInvoices, this.selectedID);
      //if (intersectingArrays.length == this.items.length) {
      //	this.invoicesCheckAll = true;
      //} else {
      //	this.invoicesCheckAll = false;
      //}
    //}
    //else {
    //	this.invoicesCheckAll = false;
    //}
    if (this.selectedAll === true) {
      this.selectAll(true);
    }
    
    this.totalInvoices = [];
    for (var val of this.items) {
      if (this.selectedID.includes(val.INVOICEID)) {
        this.totalInvoices.push(val.INVOICEID);
      }
    }
    if(this.items.length == this.totalInvoices.length) {
      this.invoicesCheckAll = true;
      this.selectPageAll = true;
    }
    else{
      this.invoicesCheckAll = false;
      this.selectPageAll = false;
    }
   
    if (this.filters.statusName.length == 0) {
      for(var statusID in this.filters.sStatus){
        if($.inArray(InvoiceStatus[this.filters.sStatus[statusID]], this.filters.statusName) == -1){
          let imvStatusName =  this.currentRole == "Customer" && this.filters.sStatus[statusID] == 0 ? "Pending" : InvoiceStatus[this.filters.sStatus[statusID]];
          this.filters.statusName.push(imvStatusName);
        }
      }
    }

    if (!this.filters.typeName.length) {
      for(var invoiceType in this.filters.iType){
        if($.inArray(invoiceTypeName[this.filters.iType[invoiceType]], this.filters.typeName) == -1){
          this.filters.typeName.push(invoiceTypeName[this.filters.iType[invoiceType]]);
        }
      }
    }

    if (this.filters.reportName.length == 0) {
      for(var statusID in this.filters.reportStatus){
        if($.inArray(InvoiceStatus[this.filters.reportStatus[statusID]], this.filters.reportName) == -1){
          this.filters.reportName.push(InvoiceReportStatus[this.filters.reportStatus[statusID]]);
        }
      }
    }

    if(this.filters['sDate'] != undefined){
      this.dueStartDate = moment(this.filters['sDate']).format("MM/DD/YYYY");
    }

    if(this.filters['eDate'] != undefined){
      this.dueEndDate = moment(this.filters['eDate']).format("MM/DD/YYYY");
    }

    if(this.filters['paidsDate'] != undefined){
      this.paidStartDate = moment(this.filters['paidsDate']).format("MM/DD/YYYY");
    }

    if(this.filters['paideDate'] != undefined){
      this.paidEndDate = moment(this.filters['paideDate']).format("MM/DD/YYYY");
    }

    if(this.filters['invoiceStartDate'] != undefined){
      this.invStartDate = moment(this.filters['invoiceStartDate']).format("MM/DD/YYYY");
    }

    if(this.filters['invoiceEndDate'] != undefined){
      this.invEndDate = moment(this.filters['invoiceEndDate']).format("MM/DD/YYYY");
    }

    if(this.filters['sTotalMin'] != undefined && this.filters['sTotalMax'] != undefined){
      this.filters['filterGrandTotal'] = '$' + this.filters['sTotalMin'] + ' - ' + this.filters['sTotalMax'];
    } else if(this.filters['sTotalMin'] != undefined) {
      this.filters['filterGrandTotal'] = '$' + this.filters['sTotalMin'] + '+';
    } else if(this.filters['sTotalMax'] != undefined){
      this.filters['filterGrandTotal'] = '$' + this.filters['sTotalMax'] + '-';
    }

    if(this.filters['sTaxMin'] != undefined && this.filters['sTaxMax'] != undefined){
      this.filters['filterTax'] = '$' + this.filters['sTaxMin'] + ' - ' + this.filters['sTaxMax'];
    } else if(this.filters['sTaxMin'] != undefined) {
      this.filters['filterTax'] = '$' + this.filters['sTaxMin'] + '+';
    } else if(this.filters['sTaxMax'] != undefined){
      this.filters['filterTax'] = '$' + this.filters['sTaxMax'] + '-';
    }

    if(this.filters['taxableTotalMin'] != undefined && this.filters['taxableTotalMax'] != undefined){
      this.filters['filterTaxableTotal'] = '$' + this.filters['taxableTotalMin'] + ' - ' + this.filters['taxableTotalMax'];
    } else if(this.filters['taxableTotalMin'] != undefined) {
      this.filters['filterTaxableTotal'] = '$' + this.filters['taxableTotalMin'] + '+';
    } else if(this.filters['taxableTotalMax'] != undefined){
      this.filters['filterTaxableTotal'] = '$' + this.filters['taxableTotalMax'] + '-';
    }

    if(this.filters['InvoicedProfitMin'] != undefined && this.filters['InvoicedProfitMax'] != undefined){
      this.filters['filterInvoiceProfit'] = '$' + this.filters['InvoicedProfitMin'] + ' - ' + this.filters['InvoicedProfitMax'];
    } else if(this.filters['InvoicedProfitMin'] != undefined) {
      this.filters['filterInvoiceProfit'] = '$' + this.filters['InvoicedProfitMin'] + '+';
    } else if(this.filters['InvoicedProfitMax'] != undefined){
      this.filters['filterInvoiceProfit'] = '$' + this.filters['InvoicedProfitMax'] + '-';
    }

    if(this.filters['UnpaidProfitMin'] != undefined && this.filters['UnpaidProfitMax'] != undefined){
      this.filters['filterUnpaidProfit'] = '$' + this.filters['UnpaidProfitMin'] + ' - ' + this.filters['UnpaidProfitMax'];
    } else if(this.filters['UnpaidProfitMin'] != undefined) {
      this.filters['filterUnpaidProfit'] = '$' + this.filters['UnpaidProfitMin'] + '+';
    } else if(this.filters['UnpaidProfitMax'] != undefined){
      this.filters['filterUnpaidProfit'] = '$' + this.filters['UnpaidProfitMax'] + '-';
    }

    if(this.filters['PaidProfitMin'] != undefined && this.filters['PaidProfitMax'] != undefined){
      this.filters['filterPaidProfit'] = '$' + this.filters['PaidProfitMin'] + ' - ' + this.filters['PaidProfitMax'];
    } else if(this.filters['PaidProfitMin'] != undefined) {
      this.filters['filterPaidProfit'] = '$' + this.filters['PaidProfitMin'] + '+';
    } else if(this.filters['PaidProfitMax'] != undefined){
      this.filters['filterPaidProfit'] = '$' + this.filters['PaidProfitMax'] + '-';
    }

    if(this.dueStartDate != undefined && this.dueEndDate != undefined){
      this.filters['filterDueDate'] = this.dueStartDate + ' - ' + this.dueEndDate;
    } else if(this.dueStartDate != undefined) {
      this.filters['filterDueDate'] = this.dueStartDate;
    } else if(this.dueEndDate != undefined){
      this.filters['filterDueDate'] = this.dueEndDate;
    }

    if(this.paidStartDate != undefined && this.paidEndDate != undefined){
      this.filters['filterPaidDate'] = this.paidStartDate + ' - ' + this.paidEndDate;
    } else if(this.paidStartDate != undefined) {
      this.filters['filterPaidDate'] = this.paidStartDate;
    } else if(this.paidEndDate != undefined){
      this.filters['filterPaidDate'] = this.paidEndDate;
    }

    if(this.invStartDate != undefined && this.invEndDate != undefined){
      this.filters['filterInvoiceDate'] = this.invStartDate + ' - ' + this.invEndDate;
    } else if(this.invStartDate != undefined) {
      this.filters['filterInvoiceDate'] = this.invStartDate;
    } else if(this.invEndDate != undefined){
      this.filters['filterInvoiceDate'] = this.invEndDate;
    }

    if(this.filters.InvoicedPercentMin != undefined && this.filters.InvoicedPercentMax != undefined) {
      this.filters['filterInvoiced'] = this.filters.InvoicedPercentMin + ' - ' + this.filters.InvoicedPercentMax;
    } else if(this.filters.InvoicedPercentMin != undefined) {
      this.filters['filterInvoiced'] = this.filters.InvoicedPercentMin + '+ ';
    } else if(this.filters.InvoicedPercentMax != undefined) {
      this.filters['filterInvoiced'] = this.filters.InvoicedPercentMax + '- ';
    }
    if (this.invoicesData.userInvoiceHeaders && this.invoicesData.userInvoiceHeaders.length) {
      this.selectedHeaders = this.invoicesData.userInvoiceHeaders;
    }
    this.usedFilterDetails = this.query;
  }

  get paginationVisible(): boolean {
    return this.totalPages !== 1;
  }
  async filterByReseller() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async checkAllResellerList() {
    var comInstance = this;
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.resellerCheckList = [];
    this.resellers.forEach(function(val) {
      comInstance.filters.resellerCheckList.push(val.ACCOUNTID);
    });
    await this.fetchData();
  }

  async resetResellerList() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.resellerCheckList = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }
  selectallStatus() {
    this.selectedID = this.invoicesData.invoiceIds;
    $(".actionsBtn")
      .removeClass("gray")
      .addClass("teal");
  }
  UnselectallStatus() {
    this.selectedID = [];
    $(".actionsBtn")
      .addClass("gray")
      .removeClass("teal");
  }
  get totalPages(): number {
    const TOTALPAGES = (this.$root as any).details.TOTALPAGES;
    if (!TOTALPAGES) {
      return 1;
    }

    return parseInt(TOTALPAGES);
  }

  get currentPage(): number {
    const CURRENTPAGE = (this.$root as any).details.CURRENTPAGE;
    if (!CURRENTPAGE) {
      return 1;
    }

    return parseInt(CURRENTPAGE);
  }

  async sortBy(field: SortField) {
    this.sort.field = field;
    this.sort.direction[field] = this.sort.direction[field] === 1 ? 2 : 1;

    for (const dirField in this.sort.direction) {
      if (dirField.toString() === field.toString()) {
        continue;
      }

      this.sort.direction[dirField] = 1;
    }
    await this.fetchData();
  }

  async sortingById() {
    this.sortBy(SortField.Id);
  }

  async sortingByName() {
    this.sortBy(SortField.Name);
  }

  async sortingByReseller() {
    this.sortBy(SortField.Customer);
  }

  async sortingByTotal() {
    this.sortBy(SortField.Total);
  }

  async sortingByDate() {
    this.sortBy(SortField.Date);
  }

  async sortingByTax() {
    this.sortBy(SortField.Tax);
  }

  async sortingByTaxableTotal() {
    this.sortBy(SortField.taxableTotal);
  }

  async sortingByPaidDate() {
    this.sortBy(SortField.PaidDate);
  }

  async sortingByInvoicedPercent() {
    this.sortBy(SortField.InvoicedPercent);
  }
  async sortingByInvoiceProfit() {
    this.sortBy(SortField.InvoiceProfit);
  }
  async sortingByInvoiceDate() {
    this.sortBy(SortField.InvoiceDate);
  }
  async sortingByInvoiceMail() {
    this.sortBy(SortField.InvoiceMail);
  }
  async sortingByInvoiceReport() {
    this.sortBy(SortField.InvoiceReport);
  }
  async sortingByInvoiceType() {
    this.sortBy(SortField.InvoiceType);
  }
  async sortingByDepartmentName() {
    this.sortBy(SortField.DepartmentName);
  }
  async sortingByEndUser() {
    this.sortBy(SortField.EndUserName);
  }
  async sortingBybAddress() {
    this.sortBy(SortField.bAddress);
  }
  async sortingBybState() {
    this.sortBy(SortField.bState);
  }
  async sortingByiAddress() {
    this.sortBy(SortField.iAddress);
  }
  async sortingByiState() {
    this.sortBy(SortField.iState);
  }
  async sortingByPaymentRef() {
    this.sortBy(SortField.PaymentRef);
  }
  async sortingByunpaidProfit() {
    this.sortBy(SortField.UnpaidProfit);
  }
  async sortingBypaidProfit() {
    this.sortBy(SortField.PaidProfit);
  }

  async searchChange(searchData) {
    this.selectedID = [];
    this.filters.searchAll = searchData;
    this.selectedAll = false;
    this.excludedIDs = [];
    await this.fetchData();
  }

  async searchByTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin;
    this.filters.sTotalMax = this.searchFilters.sTotalMax;
    await this.fetchData();
  }

  async resetSearchByTotal(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sTotalMin = this.searchFilters.sTotalMin = undefined;
    this.filters.sTotalMax = this.searchFilters.sTotalMax = undefined;
    this.filters.filterGrandTotal = undefined;
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByInvoicedPercent() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.InvoicedPercentMin = this.searchFilters.InvoicedPercentMin;
    this.filters.InvoicedPercentMax = this.searchFilters.InvoicedPercentMax;
    await this.fetchData();
  }

  async searchByInvoiceProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.InvoicedProfitMin = this.searchFilters.InvoicedProfitMin;
    this.filters.InvoicedProfitMax = this.searchFilters.InvoicedProfitMax;
    await this.fetchData();
  }
  async searchByUnpaidProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.UnpaidProfitMin = this.searchFilters.UnpaidProfitMin;
    this.filters.UnpaidProfitMax = this.searchFilters.UnpaidProfitMax;
    await this.fetchData();
  }
  async searchByPaidProfit() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.PaidProfitMin = this.searchFilters.PaidProfitMin;
    this.filters.PaidProfitMax = this.searchFilters.PaidProfitMax;
    await this.fetchData();
  }
  async hideTooltipCustomers() {
    this.customerTooltipVisibleIndex = -1;
  }
  async showTooltipCustomers(item, index) {
    if (!item.length) return;
    this.customerTooltipVisibleIndex = index;
    try {
      this.customerTooltipLoading = true;
      // const subAccounts = await CustomerRepo.findOneFewDetails(item.SECURITYGROUPID, FewDetailsJustGet.Users)
      this.customerTooltipLoading = false;
    } catch (err) {
      notifier.alert(err.message);
      this.customerTooltipVisibleIndex = -1;
    }
  }
  gotoSearch(searchData) {
    this.$router.push({
      path: "/search/" + searchData + "/for" + this.$route.path
    });
  }

  async resetSearchById(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchId = this.searchFilters.searchId = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchName = this.searchFilters.searchName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async searchById() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchId == "") {
      notifier.alert("Please Fill Invoice ID");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchId = this.searchFilters.searchId;
      await this.fetchData();
    }
  }

  async searchByName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchName == "") {
      notifier.alert("Please Fill Invoice Name");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchName = this.searchFilters.searchName;
      await this.fetchData();
    }
  }

  async searchByAccountName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchAccountName == "") {
      notifier.alert("Please Fill Customer Name");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchAccountName = this.searchFilters.searchAccountName;
      await this.fetchData();
    }
  }

  async searchByAName() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchAName == "") {
      notifier.alert("Please Fill Customer Name");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchAName = this.searchFilters.searchAName;
      await this.fetchData();
    }
  }

  async searchByDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      if(this.searchFilters.sDate == "") {
        this.filters.sDate = this.searchFilters.sDate = undefined;
        this.dueStartDate  = undefined;
      } else if(this.searchFilters.eDate == "") {
        this.filters.eDate = this.searchFilters.eDate = undefined;
        this.dueEndDate = undefined;
      }
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.sDate = this.searchFilters.sDate;
      this.filters.eDate = this.searchFilters.eDate
      await this.fetchData();
    }
  }

  async resetSearchByAccountName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByAName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchAName = this.searchFilters.searchAName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.sDate == undefined || this.searchFilters.sDate == "") &&
      (this.searchFilters.eDate == "" || this.searchFilters.eDate == undefined)
    ) {
      return;
    } else {
      this.filters.sDate = this.searchFilters.sDate = undefined;
      this.filters.eDate = this.searchFilters.eDate = undefined;
      this.filters.filterDueDate = undefined;
      this.dueStartDate = undefined;
      this.dueEndDate = undefined;
      this.selectedAll = false;
      this.excludedIDs = [];
      if(allowFetchData) {
        await this.fetchData();
      }
    }
  }

  async resetSearchByPaymentRef(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchPaymentRef = this.searchFilters.searchPaymentRef = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByPaymentRef() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchPaymentRef == "") {
      notifier.alert("Please Fill Payment Reference");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchPaymentRef = this.searchFilters.searchPaymentRef;
      await this.fetchData();
    }
  }

  async exportHeaders() {
    // var widthSizes = {
    //   "col-1": 1,
    //   "col-1-5": 1.5,
    //   "col-2": 2,
    //   "col-2-5": 2.5,
    //   "col-3": 3,
    //   "col-3-5": 3.5,
    //   "col-4": 4,
    //   "col-5": 5,
    //   "col-6": 6
    // };
    this.exportHeadersArr = [];
    const totalCols = this.selectedHeaders.length;
    let widthPercent = totalCols > 10 ? `${(100/totalCols).toFixed(4)}%` : "";
    for (const i in this.selectedHeaders) {
      // var cls = this.getColumnWidth(headers).replace(" tar", "");
      // cls = cls.replace(" lastSearchBox", "");
      // var width = (widthSizes[cls] * 100) / 12 + "%";

      const headers = this.selectedHeaders[i];
      let paddingRight = totalCols > 10 ? "3px" : "10px";
      if(totalCols >= 14) {
        // many cols, need to reduce more for padding right
        paddingRight = "3px";
      }
      if(parseInt(i) == totalCols - 1) {
        // no need add padding for last td
        paddingRight = "";
      }
      var tempObj = {
        header: headers,
        column: this.pageHeaders[headers],
        widthPercent,
        paddingRight
      };
      this.exportHeadersArr.push(tempObj);
    }
  }

  searchByHeader() {
    const filteredHeader = Object.fromEntries(Object.entries(this.pageHeaders)
            .filter(([key, value]) => value.toLowerCase().search(this.searchHeader.toLowerCase()) !== -1));

    return filteredHeader;
  }

  async loadPage(page: number, type: "prev" | "next" | "") {
    if (type === "prev") {
      this.pageNumber = this.pageNumber - 1;
    } else if (type === "next") {
      this.pageNumber = this.pageNumber + 1;
    } else {
      this.pageNumber = page;
    }
    if(sessionStorage.pageDetails != undefined && JSON.parse(sessionStorage.pageDetails)["Invoices"] ){
      var oldData = JSON.parse(sessionStorage.pageDetails);
      oldData["Invoices"] = this.pageNumber;
      sessionStorage.setItem('pageDetails',JSON.stringify(oldData))
    }

    if (
      this.pageNumber <= this.invoicesData.TOTALPAGES &&
      this.pageNumber >= 1
    ) {
      await this.fetchData();
    }
  }

  // async removeSelected() {
  //   this.confirmRemoveModalVisible = false;
  //   const successCount = await invoiceRepo.removeByIds(this.selectedID, this.selectedAll, this.excludedIDs)
  //   if (successCount > 0) {
  //     $(".actionsBtn").addClass('gray').removeClass('teal');
  //     notifier.success(`${successCount} Invoice(s) Deleted Successfully`)
  //     this.selectedID = [];
  //     this.selectedAll = false;
  //     this.excludedIDs = [];

  //     this.fetchData()
  //   }
  // }

  async pdfDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await invoiceRepo.exportListsPDFFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
    } catch (err) {
      notifier.alert("Export PDF error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async csvDownLoadFn() {
    let fileUrl;
    try {
      fileUrl = await invoiceRepo.exportListsCSVFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView
      );
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }

    return () => {
      downloadFileUrl(fileUrl);
    };
  }

  async htmlDownLoadFn() {
    const html = await this.selectedQuoteHtml("html");

    return () => {
      if (!html) return;
      var fileNameExport =
        "InvoiceList_" + Math.round(+new Date() / 1000) + ".html";
      downloadFile(fileNameExport, html);
    };
  }

  async agingReportDownLoadFn(exportInvoiceAgingDate) {
		let fileUrl;
		try {
			fileUrl = await invoiceRepo.exportInvoiceAgingReportFileUrl(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        exportInvoiceAgingDate
      );
		} catch (err) {
			notifier.alert("Export CSV error: " + err.message);
		}

		return () => {
			downloadFileUrl(fileUrl);
		};
	}

  async selectedQuoteHtml(from: string): Promise<string | false> {
    try {
      const invoiceList = await invoiceRepo.findByIDs(
        this.selectedID,
        this.selectedAll,
        this.excludedIDs,
        this.filters,
        this.sort,
        this.viewType,
        this.selectedView,
        this.exportHeadersArr
      );
      if (from == "print") {
        invoiceList["type"] = "print";
      }
      var invoiceTotalPrice = 0;
      if (invoiceList) {
        var invoiceData = invoiceList.INVOICES;
        invoiceData.forEach(item => {
          var qPrice = 0;
          if (
            !isNaN(item.ITOTALPRICE) &&
            parseFloat(item.ITOTALPRICE.toString()) >= 0
          ) {
            qPrice = parseFloat(item.ITOTALPRICE.toString());
          }
          invoiceTotalPrice = invoiceTotalPrice + qPrice;
        });
      }
      invoiceList.INVOICE_TOTALPRICE =
        "$" + invoiceTotalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      return template(invoiceList);
    } catch (err) {
      notifier.alert(err.message);
      return false;
    }
  }
  async AgingReports(){
    let fileUrl;
    try {
      fileUrl = await invoiceRepo.exportAgingReportCSVFileUrl();
    } catch (err) {
      notifier.alert("Export CSV error: " + err.message);
    }
    return downloadFileUrl(fileUrl);
  }

  async performExport(formats: ExportFormat[],customtemplateId,saveAsDefaultTemplate,displayOnExport,bigdeal,exportPO,aztempidx,advancedFilters,customMessages,exportOrderDetail,reportingOrderDetail,exportDirectPaycheckDetail,CustomDistyName,CustomDistyAddr1,CustomDistyAddr2,CustomDistyCity,CustomDistyState,CustomDistyZip,varSourceName,exportInvoiceAgingReport, exportLedgerAgingReport, exportInvoiceAgingDate) {
    this.exportModalVisible = false;
    this.loading = true;
    await this.exportHeaders();

    const exportFns: Function[] = [];

    if (formats.includes(ExportFormat.csv)) {
      exportFns.push(await this.csvDownLoadFn());
    }

    if (formats.includes(ExportFormat.html)) {
      exportFns.push(await this.htmlDownLoadFn());
    }

    if (formats.includes(ExportFormat.pdf)) {
      exportFns.push(await this.pdfDownLoadFn());
    }

    if (exportInvoiceAgingReport) {
      exportFns.push(await this.agingReportDownLoadFn(exportInvoiceAgingDate));
    }

    exportFns.forEach(fn => fn());

    this.loading = false;
  }

  async print() {
    this.loading = true;
    await this.exportHeaders();
    const html = await this.selectedQuoteHtml("print");
    this.loading = false;
    if (!html) return;

    printHtml(html);
  }
  /*async toggleCustomView(currStatus) {
    this.viewType = currStatus;
    this.selectedAll = false;
    this.excludedIDs = [];
    this.selectedID = [];
    this.pageNumber = 1;

    await this.fetchData();
    await this.accsLoad();
  }*/

  selectAll(selectedAll = true) {
    this.invoicesCheckAll = selectedAll;
    this.selectedAll = selectedAll;
    //this.excludedIDs = [];
    this.selectedID = [];
    this.showFilter = 0;
    if (this.selectedAll == true) {
      for (var val of this.items) {
        if (!this.excludedIDs.includes(val.INVOICEID)) {
          this.selectedID.push(val.INVOICEID);
        }
      }
    } else {
      //uncheck
      this.excludedIDs = [];
    }
  }
  getSelectedTotal(total) {
    var ret = 0;
    if (this.selectedAll) {
      ret = total - this.excludedIDs.length;
    } else {
      ret = this.selectedID.length;
    }

    return ret;
  }
  checkExcludedIDs(items, key, invoiceId) {
    if (this.selectedAll || this.selectPageAll) {
      for (var val of items) {
        if (!this.selectedID.includes(val[key])) {
          if (!this.excludedIDs.includes(val[key])) {
            this.excludedIDs.push(val[key]);
            this.invoicesCheckAll = false;
          }
        } else if (this.excludedIDs.includes(val[key])) {
          this.$delete(
            this.excludedIDs,
            this.excludedIDs.findIndex(id => id == val[key])
          );
        }
      }

      if (this.getSelectedTotal(this.invoicesData.TOTAL) == 0) {
        this.selectedAll = false;
        this.excludedIDs = [];
      }
    }
    if(invoiceId) {
      if (this.selectedID.includes(invoiceId)) {
        this.totalInvoices.push(invoiceId);
      }
      else {
        this.$delete(
          this.totalInvoices,
          this.totalInvoices.findIndex((id) => id == invoiceId)
        );
      }
    }
    if(this.items.length == this.totalInvoices.length && this.items.length !=0) {
      this.invoicesCheckAll = true;
      this.selectPageAll = true;
    }
    else{
      this.invoicesCheckAll = false;
      this.selectPageAll = false;
    }
  }

  resetSelected() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.pageNumber = 1;
  }

  //filter by Tags
  async filterByTag(tagName = '', groupName = '') {
    this.hasChangedAccountFilters = true;
    this.pageNumber = 1;
    if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.groupIDs && this.filters.groupIDs.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length)) {
      this.selectedView = true;
      this.CurrentView = this.getCurrentViewTitle();
    } else {
      this.selectedView = false;

      if(!this.$allowedFunctions.includes(this.$userFunctions.All_Milestone_Accounts)) {
        this.CurrentView = "My Invoices";
        this.viewType = "CurrentUsers";
      }

    }

    // if(tagName != "") {
		// 	const tagIndex = this.filters.accountTagName.findIndex(tagValue => tagValue === tagName);
		// 	if (tagIndex === -1) {
		// 		this.filters.accountTagName.push(tagName);
		// 	} else {
		// 		this.filters.accountTagName.splice(tagIndex, 1);
		// 	}
		// }

		// if(groupName != '') {
		// 	const groupIndex = this.filters.groupName.findIndex(groupValue => groupValue === groupName);
		// 	if (groupIndex === -1) {
		// 		this.filters.groupName.push(groupName);
		// 	} else {
		// 		this.filters.groupName.splice(groupIndex, 1);
		// 	}
		// }

    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllTag() {
    this.resetSelected();
    // this.filters.AccountTagID = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
		this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP == 'undefined') {
        if (typeof val.ISGLOBALTAG == 'undefined') {
          if($this.filters.AccountTagID != undefined 
            // && $this.filters.accountTagName != undefined
          ) {
            $this.filters.AccountTagID.push(val.ACCOUNTTAGID);
            // $this.filters.accountTagName.push(val.TAGNAME);
          }
        }
        else {
          $this.filters.gTagIDs.push(val.ACCOUNTTAGID);
          // if ($this.filters.accountTagName != undefined) {
          //   $this.filters.accountTagName.push(val.TAGNAME);
          // }
        }
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async checkAllGroupTag() {
    this.resetSelected();
    // this.filters.groupIDs = this.accountTags.map(val => val.ACCOUNTTAGID);
    var $this = this;
    this.filters.groupIDs = [];
    // this.filters.groupName = [];
		this.accountTags.forEach(function (val: any) {
			if (!isNaN(val.ACCOUNTTAGID) && typeof val.ISGROUP != 'undefined') {
				$this.filters.groupIDs.push(val.ACCOUNTTAGID);
				// $this.filters.groupName.push(val.TAGNAME);
			}
		});
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetTag(allowFetchData = true) {
    this.resetSelected();
    this.filters.AccountTagID = [];
    // this.filters.accountTagName = [];
    this.filters.groupIDs = [];
    this.filters.gTagIDs = [];
    // this.filters.groupName = [];
    this.pageNumber = 1;
    this.filterByTag();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  filterTagsInBox() {
    let options = this.invoicesData["accountTags"];
    this.pageNumber = 1;

    if (this.searchTagsTxt) {
      options = options.filter(
        option =>
          `${option.TAGNAME}`
            .toLowerCase()
            .indexOf(this.searchTagsTxt.toLowerCase()) !== -1 || ['CurrentUsers','All'].includes(option.ACCOUNTTAGID)
      );
    }

    this.accountTags = options;
  }

  toggleActive(div) {
    this.isActive = div;
  }

  filterAccsInBox() {
    let options = this.varAccsList;
    this.pageNumber = 1;

    if (this.searchAccTxt) {
      options = options.filter(
        option =>
          `${option.ANAME}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTALIAS}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1 ||
          `${option.ACCOUNTID}`
            .toLowerCase()
            .indexOf(this.searchAccTxt.toLowerCase()) !== -1
      );
    }

    this.varAccs = options;
  }
  async filterByAcc(accountsName: string) {
    this.hasChangedAccountFilters = true;
    if(accountsName != undefined) {
			const accountsIndex = this.filters.selectedAccsName.findIndex(accountsValue => accountsValue === accountsName);
			if (accountsIndex === -1) {
				this.filters.selectedAccsName.push(accountsName);
			} else {
				this.filters.selectedAccsName.splice(accountsIndex, 1);
			}
		}

    // this.resetSelected();
    // this.pageNumber = 1;
    // await this.fetchData();
  }
  async checkAllAcc() {
    this.resetSelected();
    this.filters.selectedAccs = this.varAccsList.map(val => val.AID);
    this.filters.selectedAccsName = this.varAccsList.map((val) => val.ANAME);
    this.pageNumber = 1;
    await this.fetchData();
  }
  async resetAcc(allowFetchData = true) {
    this.resetSelected();
    this.filters.selectedAccs = [];
    this.filters.selectedAccsName = [];
    this.pageNumber = 1;
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async accsLoad() {
    if (this.searchAccTxt == "") {
      try {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          FunctionName: "ListExt",
          controller: "Accounts",
          queryType: "top5UsedAcct",
          isInvoice: 1,
          view: this.viewType,
          accSearch: 1
        });
        if (response.data.STATUS) {
          this.varAccs = response.data.ACCOUNTS;
          this.selectedAccs = response.data.VARACCOUNTSIDSRR;
          this.varAccsList = response.data.ACCOUNTS;
        }
      } catch (err) {
        notifier.alert(err.message);
      } finally {
        this.loading = false;
      }
    }
  }
  async invoiceDelete(id) {
    this.confirmFileRemove = true;
    this.removeInvoice = id;
  }
  async deleteInvoice() {
    this.removeLoading = true;
    try {
      if (this.removeInvoice.length > 5) {
        notifier.alert("Don't allow to bulk delete more than 5 invoices");
        this.confirmFileRemove = false;
      } else {
        const response = await axios.post(dataURL + "?ReturnType=JSON", {
          Controller: "Invoices",
          FunctionName: "Delete",
          deleteInvoice: "true",
          selectedIDs: this.removeInvoice.toString()
        });
        if (response.data.STATUS) {
          notifier.success("Invoice deleted successfully");
          await this.fetchData(0);
          this.confirmFileRemove = false;
          this.removeInvoice = [];
          this.selectedID = [];
        }
      }
    } catch (err) {
      notifier.alert(err.message);
    } finally {
      this.removeLoading = false;
    }
  }

  // Status filter
  async checkStatusList(invoiceStatus: number, name: string) {
    this.hasChangedStatusFilters = true;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.statusUncheckedAll = false
    this.pageNumber = 1;
    const i = this.filters.sStatus.findIndex(s => s === invoiceStatus);
    const statusIndex = this.filters.statusName.findIndex(statusValue => statusValue === name);
    if (i === -1) {
      this.filters.sStatus.push(invoiceStatus);
    } else {
      this.filters.sStatus.splice(i, 1);
    }
    if(statusIndex === -1){
      this.filters.statusName.push(name);
    } else {
      this.filters.statusName.splice(statusIndex, 1);
    }
    if(this.filters.sStatus.length == 0){
      this.statusUncheckedAll = true;
    }

    // await this.fetchData(1);
  }

  async checkAllStatusList(allowFetchData = true) {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [1, 2, 3, 0, 4];
    this.pageNumber = 1;
    if(this.currentRole != 'Reseller' ){
      this.filters.sStatus = [1, 0, 4, 2];
    }
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async uncheckAllStatusList() {
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sStatus = [];
    this.filters.statusName = [];
    this.statusUncheckedAll = true;
    this.pageNumber = 1;
    await this.fetchData();
  }

  async resetStatusList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.sStatus = [];
    this.filters.statusName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.statusUncheckedAll = false;
    this.resetFilters();
    this.checkAllStatusList(allowFetchData);
    //await this.fetchData();
  }

  // Invoice Type filter
  async checkInvoiceTypeList(invoiceType: number, name: string) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.invoiceTypeUncheckedAll = false
    this.hasChangedTypeFilters = true;
    const i = this.filters.iType.findIndex(s => s === invoiceType);
    const statusIndex = this.filters.typeName.findIndex(typeValue => typeValue === name);

    if (i === -1) {
      this.filters.iType.push(invoiceType);
    } else {
      this.filters.iType.splice(i, 1);
    }
     if (statusIndex === -1) {
      this.filters.typeName.push(name);
    } else {
      this.filters.typeName.splice(statusIndex, 1);
    }
    if (this.filters.iType.length == 0) {
      this.invoiceTypeUncheckedAll = true;
    }

    // await this.fetchData(1);
  }

  async unCheckAllInvoiceTypeList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.iType = [];
    this.filters.typeName = [];
    this.invoiceTypeUncheckedAll = true;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async resetInvoiceTypeList(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.iType = [1, 2, 3, 4, 5];
    this.filters.typeName = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.invoiceTypeUncheckedAll = false;
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByPaidDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.paidsDate == undefined || this.searchFilters.paidsDate == "") &&
      (this.searchFilters.paideDate == "" || this.searchFilters.paideDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      if(this.searchFilters.paidsDate == "") {
        this.filters.paidsDate = this.searchFilters.paidsDate = undefined;
        this.paidStartDate  = undefined;
      } else if(this.searchFilters.paideDate == "") {
        this.filters.paideDate = this.searchFilters.paideDate = undefined;
        this.paidEndDate = undefined;
      }
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.paidsDate = this.searchFilters.paidsDate;
      this.filters.paideDate = this.searchFilters.paideDate;
      await this.fetchData();
    }
  }
  async resetSearchByPaidDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.paidsDate == undefined || this.searchFilters.paidsDate == "") &&
      (this.searchFilters.paideDate == "" || this.searchFilters.paideDate == undefined)
    ) {
      return;
    } else {
      this.filters.paidsDate = this.searchFilters.paidsDate = undefined;
      this.filters.paideDate = this.searchFilters.paideDate = undefined;
      this.filters.filterPaidDate = undefined;
      this.paidStartDate = undefined;
      this.paidEndDate = undefined;
      this.selectedAll = false;
      this.excludedIDs = [];
      if(allowFetchData) {
        await this.fetchData();
      }
    }
  }
  latePaymentDetails(invID,invPayID) {
    this.$router.push({
      name: "InvoiceDetails",
      params: { id: invID },
      query: {invoicePaymentID : invPayID}
    });
  }

  invoicesCheckAll = false;
  invoicesCheckAllChange() {
    this.invoicesCheckAll = !this.invoicesCheckAll;
    if (!this.invoicesCheckAll) {
      this.selectInPage(false);
    } else {
      this.selectInPage(true);
    }
  }
  selectInPage(selectedAllPage = true) {
    this.selectPageAll = selectedAllPage;
    this.showFilter = 0;
    if (this.selectPageAll == true) {
      this.selectedPage = this.pageNumber
      this.newPageInvoice.push(this.selectedPage);
      for (var val of this.items) {
        if(this.selectedID.indexOf(val.INVOICEID) == -1)  {
          this.selectedID.push(val.INVOICEID);
        }
        if(this.totalInvoices.indexOf(val.INVOICEID) == -1)  {
          this.totalInvoices.push(val.INVOICEID);
        }
        this.$delete(
          this.excludedIDs,
          this.excludedIDs.findIndex((id) => id == val.INVOICEID)
        );
      }
    } else {
      for (var val of this.items) {
        let selectedIDIndex = this.selectedID.indexOf(val.INVOICEID);
        let totalInvoicesIndex = this.totalInvoices.indexOf(val.INVOICEID);
        // this.selectedID.splice(selectedIDIndex, 1);
        this.totalInvoices.splice(totalInvoicesIndex, 1);
        if (selectedIDIndex != -1) {
          this.selectedID.splice(selectedIDIndex, 1);
        }
        if(!this.excludedIDs.includes(val.INVOICEID)) {
          this.excludedIDs.push(val.INVOICEID);
        }
      }
      let newPageInvoiceindex = this.newPageInvoice.indexOf(this.pageNumber)
      this.newPageInvoice.splice(newPageInvoiceindex, 1);
    }
  }
	getArraysIntersection(getInvoicesOne,getInvoicesTwo) {
		return getInvoicesOne.filter(function(n) { return getInvoicesTwo.indexOf(n) !== -1;});
  }
  validFilters(query: object, position: object) {
    var arr: any[] = [];
    query["statusName"] = query["statusName"] ? query["statusName"] : ["None"];
    query["reportName"] = query["reportName"] ? query["reportName"] : ["None"];
    query["sourceName"] = query["sourceName"] ? query["sourceName"] : ["None"];
    query["typeName"] = query["typeName"] ? query["typeName"] : ["None"];
    const queryFilters = Object.entries(query).reduce((a, [k, v]) => {
      if (k == "sStatus" || k == "statusName") {
        let statusLength = 0;
        if (this.currentRole == "Customer") {
          statusLength = 1;
        }
        return (v.length == (Object.keys(InvoiceStatus).length/2) - statusLength) ? a : { ...a, [k]: v };
      }
      if (k == "reportStatus" || k == "reportName") {
        return (v.length == (Object.keys(InvoiceReportStatus).length/2)) ? a : { ...a, [k]: v };
      }
      if ((k == "sSource" || k == "sourceName") && typeof this.invoicesData.sourcesObj != 'undefined') {
        return (v.length == Object.keys(this.invoicesData.sourcesObj).length) ? a : { ...a, [k]: v };
      }
      if ((k == "iType" || k == "typeName") && typeof invoiceTypeName != 'undefined') {
        return v.length == Object.keys(invoiceTypeName).length/2 ? a : { ...a, [k]: v };
      }
      return v == null || v === "" || k == "unassigned" ? a : { ...a, [k]: v };

    }, {});
    $.each(queryFilters, function(key,valueObj){
      if(position[key] != undefined) {
        let tempStruct: any = {};
        tempStruct[key] = valueObj;
        tempStruct["position"] = position[key];
        arr.push(tempStruct);
      }
    });
    var sortable = Object.entries(arr).sort(function(a, b) {
      return a[1].position - b[1].position;
    });

    var validFilters: any[] = [];
    for (var i in sortable) {
      let val = sortable[i];
      let lastItem = val[sortable[i].length - 1];
      let key = Object.keys(lastItem)[0];
      var value = Object.values(lastItem)[0];
      if (Array.isArray(value)) {
        value = value.join(" ,");
      }
      let tempFilterStruct: any = {};
      tempFilterStruct[key] = value;
      validFilters.push(tempFilterStruct);
    }
    return validFilters;
  }

  async searchByTax() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.sTaxMin = this.searchFilters.sTaxMin;
    this.filters.sTaxMax = this.searchFilters.sTaxMax;
    await this.fetchData();
  }

  async resetSearchByTax(allowFetchData = true) {
    this.filters.sTaxMin = this.searchFilters.sTaxMin = undefined;
    this.filters.sTaxMax = this.searchFilters.sTaxMax = undefined;
    this.filters.filterTax = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByTaxableTotal() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.taxableTotalMin = this.searchFilters.taxableTotalMin;
    this.filters.taxableTotalMax = this.searchFilters.taxableTotalMax;
    await this.fetchData();
  }

  async resetFilterTaxableTotal(allowFetchData = true) {
    this.filters.taxableTotalMin = this.searchFilters.taxableTotalMin = undefined;
    this.filters.taxableTotalMax = this.searchFilters.taxableTotalMax = undefined;
    this.filters.filterTaxableTotal = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async resetSearchByInvoiceProfit(allowFetchData = true) {
    this.filters.InvoicedProfitMin = this.searchFilters.InvoicedProfitMin = undefined;
    this.filters.InvoicedProfitMax = this.searchFilters.InvoicedProfitMax = undefined;
    this.filters.filterInvoiceProfit = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetSearchByUnpaidProfit(allowFetchData = true) {
    this.filters.UnpaidProfitMin = this.searchFilters.UnpaidProfitMin = undefined;
    this.filters.UnpaidProfitMax = this.searchFilters.UnpaidProfitMax = undefined;
    this.filters.filterUnpaidProfit = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetSearchByPaidProfit(allowFetchData = true) {
    this.filters.PaidProfitMin = this.searchFilters.PaidProfitMin = undefined;
    this.filters.PaidProfitMax = this.searchFilters.PaidProfitMax = undefined;
    this.filters.filterPaidProfit = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async resetSearchByInvoicedPercent(allowFetchData = true) {
    this.pageNumber = 1;
    this.filters.InvoicedPercentMin = this.searchFilters.InvoicedPercentMin = undefined;
    this.filters.InvoicedPercentMax = this.searchFilters.InvoicedPercentMax = undefined;
    this.filters.filterInvoiced = undefined;
    this.pageNumber = 1;
    this.resetSelected();
    if(allowFetchData) {
      await this.fetchData();
    }
  }
  async searchByInvoiceDate() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceStartDate == undefined || this.searchFilters.invoiceStartDate == "") &&
      (this.searchFilters.invoiceEndDate == "" || this.searchFilters.invoiceEndDate == undefined)
    ) {
      notifier.alert("Please Fill From or To Date");
    } else {
      if(this.searchFilters.invoiceStartDate == "") {
        this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate = undefined;
        this.invStartDate  = undefined;
      } else if(this.searchFilters.invoiceEndDate == "") {
        this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate = undefined;
        this.invEndDate = undefined;
      }
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate;
      this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate;
      await this.fetchData();
    }
  }
  async resetSearchByInvoiceDate(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    if (
      (this.searchFilters.invoiceStartDate == undefined || this.searchFilters.invoiceStartDate == "") &&
      (this.searchFilters.invoiceEndDate == "" || this.searchFilters.invoiceEndDate == undefined)
    ) {
      return;
    } else {
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate = undefined;
      this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate = undefined;
      this.filters.filterInvoiceDate = undefined;
      this.invStartDate = undefined;
      this.invEndDate = undefined;
      this.selectedAll = false;
      this.excludedIDs = [];
      if(allowFetchData) {
        await this.fetchData();
      }
    }
  }
  async searchByMail() {
    this.pageNumber = 1;
    this.selectedID = [];
    if (this.searchFilters.searchMail == "") {
      notifier.alert("Please Fill Invoice Mail");
    } else {
      this.selectedAll = false;
      this.excludedIDs = [];
      this.filters.searchMail = this.searchFilters.searchMail;
      await this.fetchData();
    }
  }
  async resetSearchByMail(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.searchMail = this.searchFilters.searchMail = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async checkAllReporteList(allowFetchData = true) {
    this.resetSelected();
    this.filters.reportStatus = [1, 0];
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async checkReporteList(invReportStatus: number, reportName: string) {
    this.pageNumber = 1;
    this.resetSelected();
    this.reportUncheckedAll = false
    const i = this.filters.reportStatus.findIndex(s => s === invReportStatus);
    const reportIndex = this.filters.reportName.findIndex(statusValue => statusValue === reportName);
    if (i === -1) {
      this.filters.reportStatus.push(invReportStatus);
    } else {
      this.filters.reportStatus.splice(i, 1);
    }
    if(reportIndex === -1){
      this.filters.reportName.push(reportName);
    } else {
      this.filters.reportName.splice(reportIndex, 1);
    }
    if(this.filters.reportStatus.length == 0){
      this.reportUncheckedAll = true;
    }
    await this.fetchData();
  }

  async uncheckAllReporteList() {
    this.resetSelected();
    this.pageNumber = 1;
    this.filters.reportStatus = [];
    this.filters.reportName = [];
    this.reportUncheckedAll = true;
    await this.fetchData();
  }

  async resetReporteList(allowFetchData = true) {
    this.resetSelected();
    this.filters.reportStatus = [];
    this.filters.reportName = [];
    this.reportUncheckedAll = false;
    this.checkAllReporteList(allowFetchData);
    this.pageNumber = 1;
    //await this.fetchData();
  }

  async uncheckSourceList() {
    this.resetSelected();
    this.filters.sSource = [];
    this.filters.sourceName = [];
    await this.fetchData();
  }

  async resetSourceList(allowFetchData = true) {
    this.resetSelected();
    this.filters.sSource = [];
    this.filters.sourceName = [];
    this.checkAllSourceList(allowFetchData);
  }

  async checkAllSourceList(allowFetchData = true) {
    this.resetSelected();
    this.filters.sSource = Object.keys(this.invoicesData.sourcesObj);
    this.filters.sourceName = Object.values(this.invoicesData.sourcesObj);
    if(allowFetchData) {
      await this.fetchData();
    }
  }

  async checkSourceList(status: string, name: string) {
    this.hasChangedSourceFilters = true;
    this.resetSelected();
    if(this.filters.sSource != undefined && this.filters.sourceName != undefined){
      const i = this.filters.sSource.findIndex((s) => s === status);
      const sourceIndex = this.filters.sourceName.findIndex((sourceValue) => sourceValue === name);
      if (i === -1) {
        this.filters.sSource.push(status);
      } else {
        this.filters.sSource.splice(i, 1);
      }
      if (sourceIndex === -1) {
        this.filters.sourceName.push(name);
      } else {
        this.filters.sourceName.splice(sourceIndex, 1);
      }
    }
    // await this.fetchData();
  }

  // getColumnWidth(headerName) {
  //   var headerCls = "";
  //   switch (this.selectedHeaders.length) {
  //     case 1:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-3";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-6";
  //           break;
  //         default:
  //           headerCls = "col-3 tar";
  //           break;
  //       }
  //       break;
  //     case 2:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-3";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-5";
  //           break;
  //         case "INVOICEID":
  //           headerCls = "col-3";
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           headerCls = "col-2";
  //           break;
  //         case "STATUSASTEXT":
  //           headerCls = "col-3";
  //           break;
  //         case "INVOICEPAIDDATE":
  //           headerCls = "col-3";
  //           break;
  //         default:
  //           headerCls = "col-2 tar";
  //           break;
  //       }
  //       break;
  //     case 3:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-4";
  //           break;
  //         case "INVOICEID":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "STATUSASTEXT":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "INVOICEPAIDDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         default:
  //           headerCls = "col-2 tar";
  //           break;
  //       }
  //       break;
  //     case 4:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-1-5";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-3";
  //           break;
  //         case "INVOICEID":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-3";
  //           }
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-3";
  //           }
  //           break;
  //         case "STATUSASTEXT":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-3";
  //           }
  //           break;
  //         case "INVOICEPAIDDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         default:
  //           headerCls = "col-1-5 tar";
  //           break;
  //       }
  //       break;
  //     case 5:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-2-5";
  //           break;
  //         case "INVOICEID":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-2";
  //           } else {
  //             headerCls = "col-2-5";
  //           }
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-2-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "STATUSASTEXT":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-2";
  //           } else {
  //             headerCls = "col-3";
  //           }
  //           break;
  //         case "INVOICEPAIDDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-2-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         default:
  //           headerCls = "col-2";
  //           break;
  //       }
  //       break;
  //     case 6:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICEID":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-1";
  //           }
  //           break;
  //         case "STATUSASTEXT":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-2-5";
  //           } else {
  //             headerCls = "col-3";
  //           }
  //           break;
  //         case "INVOICEPAIDDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         default:
  //           headerCls = "col-2";
  //           break;
  //       }
  //       break;
  //     case 7:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-1-5";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICEID":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-3";
  //           break;
  //         case "DUEDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1";
  //           } else {
  //             headerCls = "col-1-5";
  //           }
  //           break;
  //         case "STATUSASTEXT":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-2";
  //           }
  //           break;
  //         case "INVOICEPAIDDATE":
  //           if (this.selectedHeaders.includes("ACCOUNTNAME")) {
  //             headerCls = "col-1-5";
  //           } else {
  //             headerCls = "col-1";
  //           }
  //           break;
  //         default:
  //           headerCls = "col-1-5";
  //           break;
  //       }
  //       break;
  //     default:
  //       switch (headerName) {
  //         case "POID":
  //           headerCls = "col-1-5";
  //           break;
  //         case "INVOICETOTALFORMATTED":
  //           headerCls = "col-1";
  //           break;
  //         case "INVOICEID":
  //           headerCls = "col-1";
  //           break;
  //         case "ACCOUNTNAME":
  //           headerCls = "col-2-5";
  //           break;
  //         case "DUEDATE":
  //           headerCls = "col-1";
  //           break;
  //         case "STATUSASTEXT":
  //           headerCls = "col-2";
  //           break;
  //         case "INVOICEPAIDDATE":
  //           headerCls = "col-1-5";
  //           break;
  //         default:
  //           headerCls = "col-1-5";
  //           break;
  //       }
  //       break;
  //   }
  //   if (
  //     (this.selectedHeaders[this.selectedHeaders.length - 1] == headerName ||
  //       this.selectedHeaders[this.selectedHeaders.length - 2] == headerName)
  //   ) {
  //     headerCls += " lastSearchBox";
  //   }
  //   return headerCls;
  // }
  sorting(invoiceSort) {
    if (invoiceSort == "INVOICEID") {
      this.sortingById();
    } else if (invoiceSort == "POID") {
      this.sortingByName();
    } else if (invoiceSort == "ACCOUNTNAME") {
      this.sortingByReseller();
    } else if (invoiceSort == "INVOICETOTALFORMATTED") {
      this.sortingByTotal();
    } else if (invoiceSort == "INVOICETAX") {
      this.sortingByTax();
    } else if (invoiceSort == "TOTALTAXABLE") {
      this.sortingByTaxableTotal();
    }else if (invoiceSort == "INVOICEPROFIT") {
      this.sortingByInvoiceProfit();
    } else if (invoiceSort == "IDATEFORMATTED") {
      this.sortingByInvoiceDate();
    } else if (invoiceSort == "DUEDATE") {
      this.sortingByDate();
    } else if (invoiceSort == "INVOICEPAIDDATE") {
      this.sortingByPaidDate();
    } else if (invoiceSort == "INVOICEDPERCENT") {
      this.sortingByInvoicedPercent();
    } else if (invoiceSort == "INVOICEEMAIL") {
      this.sortingByInvoiceMail();
    } else if (invoiceSort == "INVOICETYPE"){
      this.sortingByInvoiceType();
    } else if (invoiceSort == "ENDUSERNAME"){
      this.sortingByEndUser();
    } else if (invoiceSort == "DEPARTMENTNAME"){
      this.sortingByDepartmentName();
    } else if (invoiceSort == "BADDRESS"){
      this.sortingBybAddress();
    } else if (invoiceSort == "BSTATE"){
      this.sortingBybState();
    } else if (invoiceSort == "IADDRESS"){
      this.sortingByiAddress();
    } else if (invoiceSort == "ISTATE"){
      this.sortingByiState();
    } else if (invoiceSort == "PAYMENTID"){
      this.sortingByPaymentRef();
    } else if (invoiceSort == "UNPAIDPROFITFORMATTED"){
      this.sortingByunpaidProfit();
    } else if (invoiceSort == "PAIDPROFITFORMATTED"){
      this.sortingBypaidProfit();
    } else if (invoiceSort == "ACCOUNTNAMEFORCUSTOMER"){
      this.sortBy(SortField.aName);
    } else {
      this.sortingByInvoiceReport();
    }
  }
  async addHeader() {
    colsResizeableReset();
    this.disableSelectedHeader = true;
    var sortedHeaders: string[] = [];
    for (var headerInvoice in this.headerInvoice) {
      var invoiceIndex: string = this.headerInvoice[headerInvoice];
      const selectedIndex = this.selectedHeaders.includes(invoiceIndex);
      if (selectedIndex) {
        sortedHeaders.push(invoiceIndex);
      }
    }
    this.selectedHeaders = sortedHeaders;
    const response = await axios.post(dataURL + "?ReturnType=JSON", {
      controller: "Users",
      FunctionName: "paramsUpdate",
      headers: this.selectedHeaders.join(","),
      from: "invoice",
    });

    if (response.data.ERROR) {
      throw new Error(response.data.ERROR);
    }
    if (response.data.STATUS !== 1) {
      throw new Error(response.data.STATUSMESSAGE);
    }

    if(!this.selectedHeaders.includes("POID")) {
      this.filters.searchName = this.searchFilters.searchName = "";
      // this.resetSearchByName();
    }
    if(!this.selectedHeaders.includes("ACCOUNTNAME")) {
      this.filters.searchAccountName = this.searchFilters.searchAccountName = "";
      // this.resetSearchByAccountName();
    }
    if(!this.selectedHeaders.includes("INVOICETAX")) {
      this.filters.sTaxMin = this.searchFilters.sTaxMin = undefined;
      this.filters.sTaxMax = this.searchFilters.sTaxMax = undefined;
      this.filters.filterTax = undefined;
      // this.resetSearchByTax();
    }

    if(!this.selectedHeaders.includes("TOTALTAXABLE")) {
      this.filters.taxableTotalMin = this.searchFilters.taxableTotalMin = undefined;
      this.filters.taxableTotalMax = this.searchFilters.taxableTotalMax = undefined;
      this.filters.filterTaxableTotal = undefined;
    }

    if(!this.selectedHeaders.includes("INVOICEPROFIT")) {
      this.filters.InvoicedProfitMin = this.searchFilters.InvoicedProfitMin = undefined;
      this.filters.InvoicedProfitMax = this.searchFilters.InvoicedProfitMax = undefined;
      this.filters.filterInvoiceProfit = undefined;
      // this.resetSearchByInvoiceProfit();
    }
    if(!this.selectedHeaders.includes("UNPAIDPROFITFORMATTED")) {
      this.filters.UnpaidProfitMin = this.searchFilters.UnpaidProfitMin = undefined;
      this.filters.UnpaidProfitMax = this.searchFilters.UnpaidProfitMax = undefined;
      this.filters.filterUnpaidProfit = undefined;
    }
    if(!this.selectedHeaders.includes("PAIDPROFITFORMATTED")) {
      this.filters.PaidProfitMin = this.searchFilters.PaidProfitMin = undefined;
      this.filters.PaidProfitMax = this.searchFilters.PaidProfitMax = undefined;
      this.filters.filterPaidProfit = undefined;
    }
    if(!this.selectedHeaders.includes("IDATEFORMATTED")) {
      this.filters.invoiceStartDate = this.searchFilters.invoiceStartDate = undefined;
      this.filters.invoiceEndDate = this.searchFilters.invoiceEndDate = undefined;
      this.filters.filterInvoiceDate = undefined;
      this.invStartDate = undefined;
      this.invEndDate = undefined;
      // this.resetSearchByInvoiceDate();
    }
    if(!this.selectedHeaders.includes("INVOICEPAIDDATE")) {
      this.filters.paidsDate = this.searchFilters.paidsDate = undefined;
      this.filters.paideDate = this.searchFilters.paideDate = undefined;
      this.filters.filterPaidDate = undefined;
      this.paidStartDate = undefined;
      this.paidEndDate = undefined;
      // this.resetSearchByPaidDate();
    }
    if(!this.selectedHeaders.includes("INVOICEDPERCENT")) {
      this.filters.InvoicedPercentMin = this.searchFilters.InvoicedPercentMin = undefined;
      this.filters.InvoicedPercentMax = this.searchFilters.InvoicedPercentMax = undefined;
      this.filters.filterInvoiced = undefined;
      // this.resetSearchByInvoicedPercent();
    }
    if(!this.selectedHeaders.includes("INVOICEEMAIL")) {
      this.filters.searchMail = this.searchFilters.searchMail = "";
      // this.resetSearchByMail();
    }
    if(!this.selectedHeaders.includes("INVOICEREPORTED")) {
      this.filters.reportName = [];
    }
    if(!this.selectedHeaders.includes("SOURCE")) {
      this.filters.sSource = Object.keys(this.invoicesData.sourcesObj);
      this.filters.sourceName = Object.values(this.invoicesData.sourcesObj);
    }

    if (!this.selectedHeaders.includes("ENDUSERNAME")) {
      this.filters.endUserName = this.searchFilters.endUserName = undefined;
    }

    if (!this.selectedHeaders.includes("DEPARTMENTNAME")) {
      this.filters.deptName = this.searchFilters.deptName = "";
    }

    if (!this.selectedHeaders.includes("BADDRESS")) {
      this.filters.bAddress = this.searchFilters.bAddress = "";
    }

    if (!this.selectedHeaders.includes("BSTATE")) {
      this.filters.bState = this.searchFilters.bState = "";
    }


    if(
      !this.selectedHeaders.includes("POID")
      || !this.selectedHeaders.includes("ACCOUNTNAME")
      || !this.selectedHeaders.includes("INVOICETAX")
      || !this.selectedHeaders.includes("TOTALTAXABLE")
      || !this.selectedHeaders.includes("INVOICEPROFIT")
      || !this.selectedHeaders.includes("IDATEFORMATTED")
      || !this.selectedHeaders.includes("INVOICEPAIDDATE")
      || !this.selectedHeaders.includes("INVOICEDPERCENT")
      || !this.selectedHeaders.includes("INVOICEEMAIL")
      || !this.selectedHeaders.includes("ENDUSERNAME")
      || !this.selectedHeaders.includes("DEPARTMENTNAME")
      || !this.selectedHeaders.includes("INVOICEREPORTED")
      || !this.selectedHeaders.includes("BADDRESS")
      || !this.selectedHeaders.includes("BSTATE")
    ) {
      this.pageNumber = 1;
      this.selectedID = [];
      this.selectedAll = false;
      this.excludedIDs = [];
      await this.fetchData();
    }
    this.disableSelectedHeader = false;
    colsResizeable();
  }
  
  getCurrentViewTitle() {
    if ((this.filters.AccountTagID && this.filters.AccountTagID.length) || (this.filters.groupIDs && this.filters.groupIDs.length) || (this.filters.gTagIDs && this.filters.gTagIDs.length)) {
      var fulltitle:any = [];

      var $this = this;
      (this.invoicesData.accountTags || []).forEach(function (val: any) {
        if (!isNaN(val.ACCOUNTTAGID)) {
          if (typeof val.ISGLOBALTAG == 'undefined' && typeof val.ISGROUP == 'undefined' && $this.filters.AccountTagID && $this.filters.AccountTagID.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
          if (typeof val.ISGLOBALTAG != 'undefined' && $this.filters.gTagIDs && $this.filters.gTagIDs.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
          if (typeof val.ISGROUP != 'undefined' && $this.filters.groupIDs && $this.filters.groupIDs.includes(val.ACCOUNTTAGID)) {
            fulltitle.push(val.TAGNAME);
          }
        }
      });
      return fulltitle.join(", ");
    }

    return "";
  }
  async selectView(item, e) {
    if (isNaN(item.ACCOUNTTAGID)) {
      this.filters.gTagIDs = [];
      this.filters.groupIDs = [];
      this.filters.AccountTagID = []; // reset
      this.CurrentView = item.TAGNAME;
      this.viewType = item.ACCOUNTTAGID;
      this.selectedView = true;
      await this.fetchData();
      await this.accsLoad();
    } else {
      e.stopPropagation();
    }
  }
  async clearFilter() {
    this.resetSearchById(false);
    this.resetSearchByName(false);
    this.resetSearchByAccountName(false);
    this.resetAcc(false);
    this.resetTag(false);
    this.resetSearchByTotal(false);
    this.resetSearchByTax(false);
    this.resetSearchByInvoiceProfit(false);
    this.resetSearchByInvoiceDate(false);
    this.resetSearchByDate(false);
    this.resetSearchByPaidDate(false);
    this.resetStatusList(false);
    this.resetSearchByInvoicedPercent(false);
    this.resetSearchByMail(false);
    this.resetReporteList(false);
    this.resetInvoiceTypeList(false);
    this.resetSearchByPaymentRef(false);
    this.filterModalVisible = false;
    await this.fetchData();
  }

  async save() {
    this.reportingStatusModalVisible = false;
    await this.fetchData();
  }

  removeColumn(header, x) {
    const sortedHeaders: string[] = [];
    for (const ts in this.headerInvoice) {
      const valIdx: string = this.headerInvoice[ts];
      const i = this.selectedHeaders.includes(valIdx);
      if (i && valIdx != header) {
        sortedHeaders.push(valIdx);
      }
    }
    this.selectedHeaders = sortedHeaders;
    this.addHeader();
  }

  getFilterValue(header) {
    let filter = '';
    let searchKey: string | undefined = '';
    switch (header) {
      case 'INVOICEID':
        searchKey = this.filters.searchId;
        break;
      case 'POID':
        searchKey =  this.filters.searchName;
        break;
      case 'ACCOUNTNAME':
        {
          const arr: string[] = [];
          if(this.filters.searchAccountName) {
             arr.push(`Customer: ${this.filters.searchAccountName}`);
          }
          if (this.filters.gTagIDs.length || this.filters.AccountTagID.length) {
            const selectedTags = (this.invoicesData.accountTags || [])
              .filter(
                t =>
                  !(t.ISGLOBALTAG || false) &&
                  !(t.ISGROUP || false) &&
                  t.ACCOUNTTAGID > 0 &&
                  this.filters.AccountTagID.includes(t.ACCOUNTTAGID)
              )
              .map(t => t.TAGNAME);
            const selectedGtags = (this.invoicesData.accountTags || [])
              .filter(
                t =>
                  (t.ISGLOBALTAG || false) &&
                  this.filters.gTagIDs.includes(t.GTAGID)
              )
              .map(t => t.TAGNAME);
            const selectedTagNames = [...selectedTags, ...selectedGtags];
            arr.push(`Customer Tags: ${selectedTagNames.join(", ")}`);
          }
          if(this.filters.selectedAccs.length) {
             arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
          }
          if (this.filters.groupIDs.length) {
            const selectedGroups = (this.invoicesData.accountTags || [])
              .filter(
                t =>
                  (t.ISGROUP || false) &&
                  this.filters.groupIDs.includes(t.ACCOUNTTAGID)
              )
              .map(t => t.TAGNAME);
            arr.push(`Customer Groups: ${selectedGroups.join(", ")}`);
          }
          searchKey = arr.join('; ');
          break;
        }
      case 'INVOICEEMAIL':
        searchKey = this.filters.searchMail;
        break;
      case 'INVOICETOTALFORMATTED':
        filter = this.getRangeFilter(this.filters.sTotalMin, this.filters.sTotalMax);
        break;
      case 'INVOICETAX':
        filter = this.getRangeFilter(this.filters.sTaxMin, this.filters.sTaxMax);
        break;
      case 'TOTALTAXABLE':
        filter = this.getRangeFilter(this.filters.taxableTotalMin, this.filters.taxableTotalMax);
        break;
      case 'INVOICEPROFIT':
        filter = this.getRangeFilter(this.filters.InvoicedProfitMin, this.filters.InvoicedProfitMax);
        break;
      case 'UNPAIDPROFITFORMATTED':
        filter = this.getRangeFilter(this.filters.UnpaidProfitMin, this.filters.UnpaidProfitMax);
        break;
      case 'PAIDPROFITFORMATTED':
        filter = this.getRangeFilter(this.filters.PaidProfitMin, this.filters.PaidProfitMax);
        break;
      case 'IDATEFORMATTED':
        filter = getDateRangeFilter(this.filters.invoiceStartDate, this.filters.invoiceEndDate);
        break;
      case 'DUEDATE':
        filter = getDateRangeFilter(this.filters.sDate, this.filters.eDate);
        break;
      case 'INVOICEPAIDDATE':
        filter = getDateRangeFilter(this.filters.paidsDate, this.filters.paideDate);
        break;
      case 'INVOICEDPERCENT':
        filter = this.getRangeFilter(this.filters.InvoicedPercentMin, this.filters.InvoicedPercentMax);
        break;
      case 'STATUSASTEXT':
        filter = this.getPreDefinedFilter(InvoiceStatus, this.filters.statusName);
        break;
      case 'INVOICEREPORTED':
        filter = this.getPreDefinedFilter(InvoiceReportStatus, this.filters.reportName);
        break;
      case 'SOURCE':
        if ( this.filters.sourceName && !this.filters.sourceName.length) filter = "None";
        else if (this.filters.sourceName && !((Object.keys(this.invoicesData.sourcesObj).length) == this.filters.sourceName.length))
          filter = this.filters.sourceName.join(', ');
        break;
      case 'INVOICETYPE':
        filter = this.getPreDefinedFilter(invoiceTypeName, this.filters.typeName);
        break;
      case "ENDUSERNAME":
        searchKey = this.filters.endUserName;
        break;
      case "DEPARTMENTNAME":
        searchKey = this.filters.deptName;
        break;
      case "BADDRESS":
        searchKey = this.filters.bAddress;
        break;
      case "BSTATE":
        searchKey = this.filters.bState;
        break;
      case "IADDRESS":
        searchKey = this.filters.iAddress;
        break;
      case "ISTATE":
        searchKey = this.filters.iState;
        break;
      case 'PAYMENTID':
        searchKey = this.filters.searchPaymentRef;
        break;
      case 'ACCOUNTNAMEFORCUSTOMER':
        {
          const arr: string[] = [];
          if(this.filters.searchAName) {
            arr.push(`Customer: ${this.filters.searchAName}`);
          }
          if(this.filters.selectedAccs.length) {
            arr.push(`Customer Accounts: ${this.filters.selectedAccsName}`);
          }
          searchKey = arr.join('; ');
          break;
        }
      default:
        // nothing
        break;
    }
    return (filter ? `${filter}` : '') + (searchKey ? (filter ? ' - ' : '') + searchKey : '');
  }

  getRangeFilter(min, max) {
    const arr: string[] = [];
    if (min) {
      arr.push(`Min: ${min}`);
    }
    if (max) {
      arr.push(`Max: ${max}`);
    }
    return arr.join('; ');
  }

  getPreDefinedFilter(status, filter) {
    if(!this.loading){
      let statusLength = 0;
      if(this.currentRole == "Customer") {
        statusLength = 1;
      }
      if (!filter.length) {
        return "None";
      } else if(!((Object.keys(status).length / 2) - statusLength == filter.length))
        return filter.join(', ');
    }
  }

  async resetColumn(header) {
    this.loading = true;
    switch (header) {
      case 'INVOICEID':
        this.resetSearchById();
        break;
      case 'POID':
        this.resetSearchByName();
        break;
      case 'ACCOUNTNAME':
        this.resetSearchByAccountName(false);
        this.resetTag(false);
        this.resetAcc(false);
        await this.fetchData();
        break;
      case 'ACCOUNTNAMEFORCUSTOMER':
        this.resetSearchByAName(false);
        this.resetAcc(false);
        await this.fetchData();
        break;
      case 'INVOICETOTALFORMATTED':
        this.resetSearchByTotal();
        break;
      case 'INVOICETAX':
        this.resetSearchByTax();
        break;
      case 'TOTALTAXABLE':
        this.resetFilterTaxableTotal();
        break;
      case 'INVOICEPROFIT':
        this.resetSearchByInvoiceProfit();
        break;
      case 'IDATEFORMATTED':
        this.resetSearchByInvoiceDate();
        break;
      case 'DUEDATE':
        this.resetSearchByDate();
        break;
      case 'INVOICEPAIDDATE':
        this.resetSearchByPaidDate();
        break;
      case 'STATUSASTEXT':
        this.resetStatusList();
        break;
      case 'INVOICEDPERCENT':
        this.resetSearchByInvoicedPercent();
        break;
      case 'INVOICEEMAIL':
        this.resetSearchByMail();
        break;
      case 'INVOICEREPORTED':
        this.resetReporteList();
        break;
      case 'SOURCE':
        this.resetSourceList();
        break;
      case 'INVOICETYPE':
        this.resetInvoiceTypeList();
        break;
      case "ENDUSERNAME":
        this.resetSearchByEndUserName();
        break;
      case "DEPARTMENTNAME":
        this.resetSearchByDeptName();
        break;
      case "BADDRESS":
        this.resetSearchBybAddress();
        break;
      case "BSTATE":
        this.resetSearchBybState();
        break;
      case "IADDRESS":
        this.resetSearchByiAddress();
        break;
      case "ISTATE":
        this.resetSearchByiState();
        break;
      case "PAYMENTID":
        this.resetSearchByPaymentRef();
        break;
      case "UNPAIDPROFITFORMATTED":
        this.resetSearchByUnpaidProfit();
        break;
      case "PAIDPROFITFORMATTED":
        this.resetSearchByPaidProfit();
        break;
      default:
        break;
    }
  }

  //Filter by department name
  async searchByDeptName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.deptName == "") {
      return;
    } else {
      this.filters.deptName = this.searchFilters.deptName;
      await this.fetchData();
    }
  }

  //Filter by department name
  async resetSearchByDeptName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.deptName = this.searchFilters.deptName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  //Filter by Billing Address
  async searchBybAddress() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (!(this.searchFilters.bAddress || "").length) {
      return;
    } else {
      this.filters.bAddress = this.searchFilters.bAddress;
      await this.fetchData();
    }
  }

  //Filter by Billing Address
  async resetSearchBybAddress(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.bAddress = this.searchFilters.bAddress = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  //Filter by Billing State
  async searchBybState(data) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.bState = data.selectedState;
    await this.fetchData();
  }

  //Filter by Billing STate
  async resetSearchBybState(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.bState = this.searchFilters.bState = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    
    let FilterStateBox: any = this.$refs.FilterStateBox;
    if (FilterStateBox.length) {
      FilterStateBox[0].selectedState = [];
    }
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  //Filter by Shipping Address
  async searchByiAddress() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (!(this.searchFilters.iAddress || "").length) {
      return;
    } else {
      this.filters.iAddress = this.searchFilters.iAddress;
      await this.fetchData();
    }
  }

  //Filter by Shipping Address
  async resetSearchByiAddress(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.iAddress = this.searchFilters.iAddress = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  //Filter by Shipping State
  async searchByiState(data) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    this.filters.iState = data.selectedState;
    await this.fetchData();
  }

  //Filter by Shipping STate
  async resetSearchByiState(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.iState = this.searchFilters.iState = "";
    this.selectedAll = false;
    this.excludedIDs = [];

    let FilterStateBox: any = this.$refs.FilterStateBox;
    if (FilterStateBox.length) {
      FilterStateBox[0].selectedState = [];
    }
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  async searchByEndUserName() {
    this.pageNumber = 1;
    this.selectedID = [];
    this.selectedAll = false;
    this.excludedIDs = [];
    if (this.searchFilters.endUserName == "") {
      return;
    } else {
      this.filters.endUserName = this.searchFilters.endUserName;
      await this.fetchData();
    }
  }

  async resetSearchByEndUserName(allowFetchData = true) {
    this.pageNumber = 1;
    this.selectedID = [];
    this.filters.endUserName = undefined;
    this.searchFilters.endUserName = "";
    this.selectedAll = false;
    this.excludedIDs = [];
    if (allowFetchData) {
      await this.fetchData();
    }
  }

  getInitials(name) {
    const words = name.split(" ");
    let initials = "";
    for (let i = 0; i < Math.min(2, words.length); i++) {
      initials += words[i].charAt(0);
    }
    return initials.toUpperCase();
  }
}
