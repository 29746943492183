






import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Props {}

interface Events {
  onClose: void;
}

declare const dataURL: string;
declare const $: any;

@Component({
  inheritAttrs: true,
  components: {}
})
export default class Distributor extends TSXComponent<Props, Events> {
  $parent: any;

  async created() {
    await this.$parent.getSavedSettings();
  }
}
