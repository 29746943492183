var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modal",staticClass:"modal",attrs:{"tabindex":"-1","id":"statusDialogbox"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered dialogBox",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[(_vm.loading)?_c('Loader'):[(_vm.newTR)?_c('div',{staticClass:"mb-3"},[_vm._v("SS TR: "+_vm._s(_vm.newTR))]):_vm._e(),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("SKU")]),_c('th',{staticClass:"text-center"},[_vm._v("Assignment")])])]),_c('tbody',_vm._l((_vm.uniqueSKUs),function(item){return _c('tr',{key:item.sku},[_c('td',[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"text-grey"},[_vm._v(_vm._s(item.sku))])]),_c('td',{staticStyle:{"width":"60%"}},[_c('DropdownControl',{staticClass:"productSku-control mt-0",attrs:{"spanTextClass":"d-block","options":_vm.newStoreSKUs,"selectedIDs":[_vm.skuAssociations[item.sku]],"placeholderText":'Product SKU',"hideSelectAll":true,"multiSelect":false,"hideCheckMark":true,"name":"productSku"},on:{"check":function (data) { return _vm.updateSkuAssociation(item.sku, data); }}})],1)])}),0)]),(_vm.aID)?[_c('button',{staticClass:"btn shadow-none h-auto d-flex collapsed",attrs:{"data-toggle":"collapse","data-target":"#collapse-associatedSKUs","aria-expanded":true,"aria-controls":"collapse-associatedSKUs"}},[_c('div',{staticClass:"d-flex flex-wrap align-items-center w-80"},[_c('span',{staticClass:"accordion-open"}),_c('span',[_vm._v("Show All")])])]),_c('perfect-scrollbar',{staticClass:"ps",attrs:{"options":{
                  wheelPropagation: false,
                  suppressScrollX: true
                }}},[_c('div',{staticClass:"collapse",attrs:{"id":"collapse-associatedSKUs"}},_vm._l((_vm.skuAssociations),function(value,key){return _c('div',{key:key,staticClass:"d-flex"},[_c('strong',[_vm._v(_vm._s(key))]),_vm._v(" : "+_vm._s(value)+" "),_c('img',{staticClass:"deleteIcon dltIcon",attrs:{"src":"images/close_search.png"},on:{"click":function () {
                        _vm.confirmDeleteSkuAssociationsModalVisible = true;
                        _vm.deletedSku = key;
                      }}})])}),0)])]:_vm._e()]],2),_c('div',{staticClass:"modal-footer d-flex justify-content-end"},[(_vm.newTR == '')?_c('button',{staticClass:"btn btn-primary m-0",attrs:{"type":"button","disabled":_vm.isDisabled},on:{"click":_vm.updateAll}},[_vm._v(" Next ")]):_c('button',{staticClass:"btn btn-primary m-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Close ")])])])]),(_vm.confirmDeleteSkuAssociationsModalVisible)?_c('ConfirmRemoveItemModal',{attrs:{"title":"Delete Confirmation","customMessage":("Are you sure you want to delete the SKU <b>" + _vm.deletedSku + "</b>?")},on:{"close":function () {
      _vm.confirmDeleteSkuAssociationsModalVisible = false;
      _vm.deletedSku = '';
    },"confirm":function($event){return _vm.deleteSkuAssociations()}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v("Retire and Reorder")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close","data-dismiss":"modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])}]

export { render, staticRenderFns }