import { render, staticRenderFns } from "./SecurityGroupAdd.vue?vue&type=template&id=f38df060&scoped=true"
import script from "./SecurityGroupAdd.vue?vue&type=script&lang=tsx"
export * from "./SecurityGroupAdd.vue?vue&type=script&lang=tsx"
import style0 from "./SecurityGroupAdd.vue?vue&type=style&index=0&id=f38df060&prod&lang=css"
import style1 from "./SecurityGroupAdd.vue?vue&type=style&index=1&id=f38df060&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f38df060",
  null
  
)

export default component.exports