var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutside),expression:"clickOutside"}],ref:"categoryContainer",staticClass:"popover-box",class:{
    nodataheight: !_vm.catList.length,
    invoice: _vm.isinvoice,
    'topCategoryTooltip': _vm.topCategoryTooltip,
    'is-config-item': _vm.isConfig
  }},[_c('div',{staticClass:"popover"},[_c('div',{staticClass:"arrow"}),_c('div',{staticClass:"popover-content skuTooltip d-flex",class:{ 'show-pl': _vm.showPL }},[_c('div',{staticClass:"popover-content-inner",class:{ 'w-50': _vm.showPL }},[(_vm.loading)?_c('Loader'):_c('div',{staticClass:"list-container"},[(!_vm.hideConfigToggle)?_c('div',{staticClass:"config-toggle bg-white"},[_c('label',{staticClass:"switch auto"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isConfig),expression:"isConfig"}],attrs:{"type":"checkbox","id":"togBtn"},domProps:{"checked":Array.isArray(_vm.isConfig)?_vm._i(_vm.isConfig,null)>-1:(_vm.isConfig)},on:{"change":[function($event){var $$a=_vm.isConfig,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isConfig=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isConfig=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isConfig=$$c}},_vm.configToggleChange]}}),_c('div',{staticClass:"slider round"})])]):_vm._e(),(
              _vm.currentItem.ItemCategory > 0 &&
                !_vm.isConfig &&
                _vm.currentItem.ItemCategoryName.toLowerCase() != 'config'
            )?_c('div',{staticClass:"bg-white d-flex",attrs:{"title":_vm.currentItem.ItemCategoryName}},[_c('span',{staticClass:"textOverflow"},[_vm._v(" Selected Category: "),_c('strong',[_vm._v(_vm._s(_vm.currentItem.ItemCategoryName))])]),(_vm.currentItem.ItemCategoryName.length)?_c('img',{staticClass:"deleteIcon pl-1",attrs:{"alt":"Remove Category","src":"images/close_search.png"},on:{"click":function($event){return _vm.deleteCategory()}}}):_vm._e()]):_vm._e(),(
              _vm.hideConfigToggle &&
                !(
                  _vm.currentItem.ItemCategory > 0 &&
                  !_vm.isConfig &&
                  _vm.currentItem.ItemCategoryName.toLowerCase() != 'config'
                )
            )?_c('div',{staticClass:"catEmptyHeight"}):_vm._e(),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],attrs:{"name":"search","id":"search","type":"text","placeholder":"Search Category","maxlength":"50","disabled":_vm.isConfig},domProps:{"value":(_vm.search)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();},"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value.trim()},function($event){return _vm.categoryLookup()}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"totalSearch",attrs:{"for":"search"}},[_c('span',[_vm._v(" Search Category "),[_vm._v("("+_vm._s(_vm.catList.length)+" TOTAL)")]],2)])]),(_vm.catList.length === 0)?_c('div',{staticClass:"msg noData d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"up"},[_vm._v("No Categories Found")])]):_vm._e(),_c('div',{staticClass:"list-data"},[(_vm.catList.length)?_c('perfect-scrollbar',{staticClass:"ps",attrs:{"options":{
                wheelPropagation: false,
                suppressScrollX: true,
                maxScrollbarLength: 50
              }}},[_c('ul',{staticClass:"list-group list-group-flush category-list-group"},_vm._l((_vm.catList),function(item,index){return _c('li',{key:index,staticClass:"list-group-item",attrs:{"title":item.CATEGORYNAME}},[_c('span',{class:_vm.isquote || _vm.isinvoice ? 'textOverflow' : '',on:{"click":function($event){return _vm.selectCategory(item)}}},[_vm._v(_vm._s(item.CATEGORYNAME))])])}),0)]):_vm._e()],1)])],1),(_vm.showPL)?_c('div',{staticClass:"popover-content-inner w-50",class:{
          'productLine-inner-content': !_vm.loading
        }},[(_vm.loading)?_c('Loader'):_c('div',{staticClass:"list-container list-container-pl"},[(
              !_vm.hideConfigToggle &&
                (_vm.currentItem.ItemCategory > 0 &&
                  !_vm.isConfig &&
                  _vm.currentItem.ItemCategoryName.toLowerCase() != 'config')
            )?_c('div',{staticClass:"plEmptyHeight"}):_vm._e(),_c('div',[_vm._v(" Selected PL: "),(_vm.currentItem.ItemPLName)?[_c('strong',{staticClass:"up"},[_vm._v(_vm._s(_vm.currentItem.ItemPLName))]),(_vm.currentItem.ItemPLName.length)?_c('img',{staticClass:"deleteIcon pl-1",attrs:{"alt":"Remove Product Line","src":"images/close_search.png"},on:{"click":function($event){return _vm.deletePL()}}}):_vm._e()]:_vm._e()],2),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.plSearch),expression:"plSearch",modifiers:{"trim":true}}],attrs:{"name":"plSearch","id":"plSearch","type":"text","placeholder":"Search Product Line","maxlength":"3"},domProps:{"value":(_vm.plSearch)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();},"input":[function($event){if($event.target.composing){ return; }_vm.plSearch=$event.target.value.trim()},function($event){return _vm.productLineLookup()}],"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"totalSearch",attrs:{"for":"plSearch"}},[_c('span',[_vm._v(" Search Product Line "),[_vm._v("("+_vm._s(_vm.plList.length)+" TOTAL)")]],2)])]),(_vm.plList.length === 0)?_c('div',{staticClass:"msg noData d-flex align-items-center justify-content-center"},[(_vm.showAddNewPL)?_c('span',{staticClass:"addNewPL",on:{"click":function () {
                  _vm.showAddPL = !_vm.showAddPL;
                  _vm.newPL = _vm.plSearch;
                }}},[_vm._v("+ Add PL")]):_c('span',{staticClass:"up"},[_vm._v("No Product Line Found")])]):_vm._e(),_c('div',{staticClass:"list-data"},[(_vm.plList.length)?_c('perfect-scrollbar',{staticClass:"ps",attrs:{"options":{
                wheelPropagation: false,
                suppressScrollX: true,
                maxScrollbarLength: 50
              }}},[_c('ul',{staticClass:"list-group list-group-flush up"},_vm._l((_vm.plList),function(item,index){return _c('li',{key:index,staticClass:"list-group-item",attrs:{"title":item.PRODUCTLINENAME}},[_c('span',{on:{"click":function($event){return _vm.selectProductLine(item)}}},[_vm._v(" "+_vm._s(item.PRODUCTLINENAME)+" ")])])}),0)]):_vm._e(),(!_vm.showAddNewPL)?_c('div',{staticClass:"cursor-pointer",on:{"click":function () {
                  _vm.showAddPL = !_vm.showAddPL;
                }}},[_c('strong',[_vm._v("+")]),_vm._v(" Add PL ")]):_vm._e(),(_vm.showAddPL)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pr-3 newPL-content"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newPL),expression:"newPL",modifiers:{"trim":true}}],attrs:{"type":"text","name":"newPL","id":"newPL","placeholder":"Product Line","maxlength":"3"},domProps:{"value":(_vm.newPL)},on:{"input":function($event){if($event.target.composing){ return; }_vm.newPL=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"newPL"}})]),_c('LaddaButton',{staticClass:"btn btn-primary",staticStyle:{"width":"27%","padding-left":"0px","padding-right":"0px","height":"23px !important"},attrs:{"type":"button","label":"Save","loading":_vm.saving,"disabled":!_vm.newPL.length},on:{"click":function($event){return _vm.savePL()}}})],1):_vm._e()],1)])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }