
















































































































































































































































































































































































































































































































































































import axios, { AxiosRequestConfig } from 'axios';
import { Component as TSXComponent } from "vue-tsx-support";
import { Route } from 'vue-router';
import { Component } from "vue-property-decorator";
import { notifier } from "../models/common";
import { ApiHelper } from "@/helpers/all";

const allMonths = [
  "Jan", // "January",
  "Feb", // "February",
  "Mar", // "March",
  "Apr", // "April",
  "May", // "May",
  "Jun", // "June",
  "Jul", // "July",
  "Aug", // "August",
  "Sep", // "September",
  "Oct", // "October",
  "Nov", // "November",
  "Dec", // "December",
];
var startPoint = new Date().getMonth();
var lastYear = [];
var Curryear = [];
var Currmon = [];
var lastmon = [];
for (var i = 0; i < 12; i++) {
  var mon = allMonths[i].substring(0,3);
  if(startPoint < i){
    lastYear.push(mon+", "+( new Date().getFullYear() - 1));
    lastmon.push(allMonths[i]);
  }
  else{
    Curryear.push(mon+", "+(new Date().getFullYear()));
    Currmon.push(allMonths[i]);
  }
}
const last12months = lastmon.concat(Currmon);

@Component({
  inheritAttrs: false,
  components: {}
})

export default class OutlookAddIn extends TSXComponent {
  // State variables
  $route!: Route;
  loggedIn = false;
  loggingIn = false;
  isProduction = true;
  analyzeToggle = false;
  timeoutId = null;
  btnLoading = false;
  loading = true;
  currentFunction = "";
  searchQuery = '';
  timeout = null;
  dropdownVisible = false;
  hoveringDropdown = false;
  userEdit = false;
  originalANAME = '';
  originalaID = 0;
  originalaccountID = 0;
  userInfo = {
    ACONTACTEMAIL: "",
    ACONTACTPHONE: "",
    ACCOUNTPARAMS: "",
    ACONTACTNAME: "",
    ACONTACTPHONEEXT: "",
    ANAME: "",
    ACCOUNTID: 0,
    AID: 0, 
    allowedAccounts: []
  };
  suggestedAccounts = [];
  searchResults = [];
  passwordResetOn = false;
  userRole = "";
  accountname = "";
  checkExistShow = false;
  defaultDomain = false;
  errorMessage = "";
  showForgotPwd = false;
  showrembMe = false;
  rememberMe = localStorage.getItem("rememberMe") === "1";
  check = false;
  password = '';
  confirmPassword = '';
  userEmail = '';
  passwordReset = false;
  form2FAFVisible = false;
  panelEmail = "";
  panelPassword = "";
  code2FA = [
    { id: 1, val: "" },
    { id: 2, val: "" },
    { id: 3, val: "" },
    { id: 4, val: "" },
    { id: 5, val: "" },
    { id: 6, val: "" }
  ];
  userToken2FAF = "";
  verify2FAFFail = false;
  currentUser;
  sortingYear = new Date().getFullYear();
  dataOrderTrend = {
    SALESTHISYEARFORMATTED: "$0.00",
    SALESTHISYEARTREND: 0,
    SALESTHISYEARTRENDPERCENT: "0%",
    COMMISSION: "$0.00",
    COMMISSIONTREND: 0,
    COMMISSIONTRENDPERCENT: "0%",
    USERSALESTHISYEARTRENDSTATUS: "",
    USERTOTALSALESSTATUS: "0%",
    USERPROFITTHISYEARTRENDSTATUS: "",
    USERTOTALPROFITSTATUS: "0%",
    MARGINTRENDPERCENT: "0%"
  };
  orderList = [];
  quoteList = [];
  oppList = [];
  salesLineSeries = [
    {
      name: "Prior Year Orders",
      data: [28, 29, 33, 36, 32, 32, 33],
    },
    {
      name: "This Year Orders",
      data: [412, 141, 514, 158, 167, 173, 183],
    }
  ];
  ApiMonths = [];
  isActive = "quotes";
  defaultApiURL = "";
  loginError = "";
  token = "";

  get chartOptions(){
    return {
      chart: {
        height: "160px",
        defaultLocale: "en",
        type: "line",
        // stacked: true,
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 100,
          },
        },
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      colors: ["#e1e1e1", "#0ea1ff", "#b7b6ba", "#31BCBC"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
        dashArray: [7, 0, 7, 0],
      },
      markers: {
        size: 4,
        fillOpacity: 0,
      },
      xaxis: {
        categories: this.categories(),
        tooltip: {
          enabled: false,
        },
        crosshairs: {       
          stroke: {
            width: 0,
          },
        },
        axisTicks: {
          show: true,
          height: 11,
        },
        labels: {
          show: true,
          style: {
            colors:"#656468",
            // fontFamily: 'Work Sans, sans-serif',
            fontWeight: 600,
            fontSize: "10px",
          },
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        x: {
          formatter: (value) => {
            if(isNaN(this.sortingYear)){
              // return "Month: " + last12months[value - 1];
              return last12months[value - 1];
            }
            else{
              // return "Month: " + allMonths[value - 1];
              return allMonths[value - 1];
            }
          },
        },
        y: {
          formatter: function (value) {
            var formatedAmount = value;
            if (!isNaN(value)) {
              value = value.toFixed(2);
              formatedAmount = "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            return formatedAmount;
          },
        },
      },
      grid: { show: false },
      legend: {
        show: false
      }
    }
  }

  // Lifecycle hook - Called when the component is created
  created() {
    console.log('loading outlook add-in');
    this.currentUser = this.$route.query.currentUser;
  }

  mounted() {
    this.defaultApiURL = dataURL;
    console.log('defaultApiURL', this.defaultApiURL);
    document.body.id = 'outlookAddin';
    this.initToken();
    // this.checkAutoAI(false);

    // Add message event listener for communication with Outlook add-in
    window.addEventListener('message', this.handleIframeMessage);

    // Send message to parent window to indicate add-in is created
    const iframe = window.parent;
    if (iframe) {
      iframe.postMessage({ action: 'outlook-addin-created' }, "*");
    }
  }

  // Lifecycle hook - Called before the component is destroyed
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('message', this.handleIframeMessage);
  }

  // Lifecycle hook - Called before the component is destroyed
  handleIframeMessage(event) {
    console.log('Received message from iframe:', event);

    // Check if the message is an object and has an action
    if (typeof event.data === 'object' && event.data.action === 'updateUI') {
      console.log('Received updateUI message:', event.data.data);
      
      // Extract data from the message
      const { email, name, token } = event.data.data;

      // Trigger fetchUserInfo with the received data
      this.fetchUserInfo(token, email, name);
    } else if (typeof event.data === 'object' && event.data.action === 'updateFrameworkUrl') {
      console.log('Received updateFrameworkUrl message:', event.data);
      this.updateFrameworkUrl(event.data.frameworkUrl);
    }
  }

  updateFrameworkUrl(frameworkUrl) {
     this.defaultApiURL = frameworkUrl;
  }

  async handleLogout() {
    console.log("Logging out, removing session token.");

    // Notify backend about logout
    if (this.token) {
      await axios.post(this.defaultApiURL + "/?ReturnType=JSON", {
        controller: "Email",
        FunctionName: "logout",
        subsystem: "External",
        session: 0,
        token: this.token,
      });
    }

    // Clear token from localStorage
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('sessionValid');

    this.loggedIn = false;
    this.token = null;
    this.panelEmail = "";
    this.panelPassword = "";

    // Notify parent window (Outlook Add-in)
    const message = {
      action: 'logout',
    };
    window.parent.postMessage(message, '*');
  }

  generateClick() {
    this.btnLoading = true;
    console.log("Creating quote");

    const message = {
      action: 'generateClick',
      aID: this.userInfo.AID,
      message: this.$route.query.message,
      emailRecipient: this.$route.query.email,
      function: this.currentFunction
    };
    
    window.parent.postMessage(message, '*');

    setTimeout(() => {
      this.btnLoading = false;
    }, 5000);
  }

  noThanks() {
    const revenueContainer = document.getElementById("revenueContainer");
    const printContainer = document.getElementById("analysisOutput");
    const svgElement = document.getElementById("print-button");

    revenueContainer.classList.remove("d-none");
    revenueContainer.classList.add("d-flex");

    printContainer.classList.remove("d-flex");
    printContainer.classList.add("d-none");

    svgElement.classList.remove("d-none");
    svgElement.classList.add("d-flex");
  }

  initToken() {
    // Check URL parameters first
    if (this.$route.query.token) {
      const tokenValue = Array.isArray(this.$route.query.token) 
        ? this.$route.query.token[0] 
        : this.$route.query.token;
      console.log("Token from route query:", tokenValue);
      localStorage.setItem('sessionToken', tokenValue || '');
      localStorage.setItem('sessionValid', '1');
      this.token = tokenValue;
    } else {
      // Check localStorage as fallback
      const storedToken = localStorage.getItem('sessionToken');
      if (storedToken) {
        console.log("Retrieved token from localStorage:", storedToken);
        this.token = storedToken;
      } else {
        this.token = "";
      }
    }
    this.sessionCheck();
  }

  async sessionCheck() {
    console.log("sessionCheck");
    console.dir(this.$route.query);
    console.dir(localStorage); 

    const sessionValid = localStorage.getItem('sessionValid');
    if (sessionValid === '1') {
        console.log("Session already validated, skipping check");
        this.loggedIn = true;

        // Get current mail item info and update user info
        const getCurrentItemPromise = new Promise((resolve, reject) => {
            window.parent.postMessage({ action: "requestCurrentItem" }, "*");
        });

        getCurrentItemPromise.then((item: any) => {
            const token = localStorage.getItem('token');
            if (token) {
                this.fetchUserInfo(token, item.from.emailAddress, item.from.displayName);
            }
        })
        .catch(error => console.error('Error getting mail item details:', error));

        return;
    }

    try {
      if (!this.token) {
        console.warn("No token available. Redirecting to login.");
        this.loggedIn = false;
        return;
      }

      // Verify session with the backend
      const response = await axios.post(this.defaultApiURL + "/?ReturnType=JSON", {
        controller: "Email",
        FunctionName: "outlookSession",
        subsystem: "External",
        session: 0,
        token: this.token,
        loggedInEmail: localStorage.getItem('loggedInEmail') || this.$route.query.email,
        loggedInName: localStorage.getItem('loggedInName') || this.$route.query.name
      });

      if (response.data.SESSIONSTATUS === 1 && response.data.RESPONSE !== "Session Timeout") {
        this.loggedIn = true;
        this.loading = false;
      } else {
        console.warn("Session invalid. Redirecting to login.");
        this.loggedIn = false;
        this.loading = false;
        localStorage.removeItem('sessionToken'); // Clear invalid token
        localStorage.removeItem('sessionValid');

        const loadingDiv = document.getElementById('loading');
        if (loadingDiv) {
          loadingDiv.style.display = 'none';
        }
      }
    } catch (err) {
      console.dir(err);
      console.error("Session validation error:", err.message);
      this.loggedIn = false;
      this.loading = false;
    } finally {
      // If the toggle is enabled, analyze the email after a 2-second delay
      if (this.analyzeToggle) {
        this.startDelayedAnalysis();
      }
    }
  }

  // Fetch user information from the server
  async fetchUserInfo(token = "", email = "", fullName = "") {
    try {
      // Prioritize the email and name passed to the function
      const requestEmail = email || this.$route.query.loggedInEmail || "";
      const requestName = fullName || this.$route.query.loggedInName || "";
      const routeToken = Array.isArray(this.$route.query.token) ? this.$route.query.token[0] : this.$route.query.token;
      const requestToken = token || routeToken || "";
      
      if (!requestToken) {
        console.error("No token provided for fetchUserInfo");
        this.loggedIn = false;
        return;
      }
      
      console.log("Making API call with token:", requestToken, requestToken ? "[VALID_TOKEN]" : "[NO_TOKEN]");

      const response = await axios.post(this.defaultApiURL + "?ReturnType=JSON", {
        controller: "Email",
        FunctionName: "index",
        subsystem: "External",
        isOutlookAddin: true,
        session: 0,
        token: String(requestToken), // Ensure token is a string value
        email: requestEmail,
        fullName: requestName,
        sortingYear: this.sortingYear,
        loggedInEmail: localStorage.getItem('loggedInEmail') || this.$route.query.email,
        loggedInName: localStorage.getItem('loggedInName') || this.$route.query.name
      });

      console.log("API Response:", response.data);

      if(response.data.ERROR && response.data.ERROR != ""){
        this.loggedIn = false;
      }
      else{
        var monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var salesLineChartData1 = [];
        var salesLineChartData2 = [];
        var salesPrevYear = [];
        var salesThisYear = [];

        if (response.data.contactInfo && response.data.contactInfo[0]) {
          this.userInfo = response.data.contactInfo[0];
          // Check if allowedAccounts exists and if the current account ID is included
          if (Array.isArray(this.userInfo.allowedAccounts) && this.userInfo.AID) {
            // Set ACCESS to true if the user's account ID is in the allowedAccounts array
            this.userInfo["ACCESS"] = this.userInfo.allowedAccounts.some(account => 
              parseInt(account.AID) === this.userInfo.AID
            );
          } else if (Array.isArray(this.userInfo.allowedAccounts)) {
            // User has allowed accounts but no current account assigned
            this.userInfo["ACCESS"] = false;
          } else {
            // If allowedAccounts doesn't exist, default to true for backward compatibility
            this.userInfo["ACCESS"] = true;
          }
          this.suggestedAccounts = response.data.suggestedAccounts || [];
          if (response.data.SALESTHISYEARFORMATTED) {
            this.dataOrderTrend.SALESTHISYEARFORMATTED = response.data.SALESTHISYEARFORMATTED;
          }
          if (response.data.SALESTHISYEARTREND) {
            this.dataOrderTrend.SALESTHISYEARTREND = response.data.SALESTHISYEARTREND;
          }
          if (response.data.SALESTHISYEARTRENDPERCENT) {
            this.dataOrderTrend.SALESTHISYEARTRENDPERCENT = response.data.SALESTHISYEARTRENDPERCENT;
          }
          if (response.data.COMMISSION) {
            this.dataOrderTrend.COMMISSION = response.data.COMMISSION;
          }
          if (response.data.COMMISSIONTREND) {
            this.dataOrderTrend.COMMISSIONTREND = response.data.COMMISSIONTREND;
          }
          if (response.data.COMMISSIONTRENDPERCENT) {
            this.dataOrderTrend.COMMISSIONTRENDPERCENT = response.data.COMMISSIONTRENDPERCENT;
          }
          if (response.data.USERSALESTHISYEARTRENDSTATUS) {
            this.dataOrderTrend.USERSALESTHISYEARTRENDSTATUS = response.data.USERSALESTHISYEARTRENDSTATUS;
          }
          if (response.data.USERTOTALSALESSTATUS) {
            this.dataOrderTrend.USERTOTALSALESSTATUS = response.data.USERTOTALSALESSTATUS;
          }
          if (response.data.USERPROFITTHISYEARTRENDSTATUS) {
            this.dataOrderTrend.USERPROFITTHISYEARTRENDSTATUS = response.data.USERPROFITTHISYEARTRENDSTATUS;
          }
          if (response.data.USERTOTALPROFITSTATUS) {
            this.dataOrderTrend.USERTOTALPROFITSTATUS = response.data.USERTOTALPROFITSTATUS;
          }
          if (response.data.MARGINTRENDPERCENT) {
            this.dataOrderTrend.MARGINTRENDPERCENT = response.data.MARGINTRENDPERCENT;
          }
          if (response.data.ORDERLIST) {
            this.orderList = response.data.ORDERLIST;
          }
          if (response.data.QUOTELIST) {
            this.quoteList = response.data.QUOTELIST;
          }
          if (response.data.OPPLIST) {
            this.oppList = response.data.OPPLIST;
          }
          if (response.data.MONTHNAMES) {
            monthNames = response.data.MONTHNAMES;
          }
          salesPrevYear = response.data.MONTHDETAILSLASTYEAR;
          salesThisYear = response.data.MONTHDETAILSTHISYEAR;
        }
        for (const month in monthNames) {
          for (const data in salesThisYear) {
            if (monthNames[month] == salesThisYear[data].MONTHSTRING) {
              salesLineChartData1[month] = salesThisYear[data].TOTALPRICE;
              break;
            } else {
              salesLineChartData1[month] = 0;
            }
          }

          for (const data in salesPrevYear) {
            if (monthNames[month] == salesPrevYear[data].MONTHSTRING) {
              salesLineChartData2[month] = salesPrevYear[data].TOTALPRICE;
              break;
            } else {
              salesLineChartData2[month] = 0;
            }
          }
        }
        this.salesLineSeries = [
          {
            name: "Prior Year Orders",
            data: salesLineChartData2,
          },
          {
            name: "This Year Orders",
            data: salesLineChartData1,
          }
        ];

        this.loggedIn = true;
      }
    } catch (err) {
      notifier.alert("error: " + err.message);
    } finally {
      this.loading = false;

      // If the toggle is enabled, analyze the email after a 2-second delay
      if (this.analyzeToggle) {
        this.startDelayedAnalysis();
      }
    }
  }

  // Function to start the 2-second timeout for email analysis
  startDelayedAnalysis() {
    // Clear any existing timeout to avoid running the function multiple times
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    // Set a new 2-second timeout
    this.timeoutId = setTimeout(() => {
      this.analyzeEmail();
    }, 2000);
  }

  async analyzeEmail() {
    const svgElement = document.getElementById("print-button");
    
    // Add the flashing class to start the effect
    svgElement.classList.add("flashing");

    try {
      const itemId = this.$route.query.itemId || "Item ID not available";
      const internetMessageId = this.$route.query.internetMessageId || "Internet Message ID not available";
      const messageBody = this.$route.query.message || "Message body not available";

      if (!itemId || !internetMessageId || !messageBody) {
        console.warn("One or more message details are missing.");
      } else {
        console.log(`Item ID: ${itemId}, Internet Message ID: ${internetMessageId}`);

        // Prepare the payload for the API call
        const response = await axios.post(this.defaultApiURL + "/?ReturnType=JSON", {
          controller: "Email",
          FunctionName: "ai",
          subsystem: "External",
          session: 0,
          token: String(this.$route.query.token || ""), // Ensure token is a string value
          isOutlookAddin: true,
          data: {
            something: "value",
            console: "verbose",
            request: messageBody,  // Send the message body here
            outputType: "table",
            analyzeonly: "true",
            aID: this.userInfo.AID
          }
        });

        console.dir(response);

        // Parse the response data if it's a string
        let responseData = typeof response.data.RETURNDATA === 'string' ? JSON.parse(response.data.RETURNDATA) : response.data.RETURNDATA;

        // Assuming the response contains `analysis` in the parsed data
        if (responseData && responseData.analysis) {
          console.log("Analysis received:", responseData.analysis);

          const printContainer = document.getElementById("analysisOutput");
          const printContainer_text = document.getElementById("analysisOutput_container");
          
          if(responseData.identifiedFunction) {
            const revenueContainer = document.getElementById("revenueContainer");
            const buttonGroup = document.getElementById("buttons-group");
            const button = document.getElementById("generateBtnLabel");
            const cancelBtn = document.querySelector(".cancelBtn");

            revenueContainer.classList.remove("d-flex");
            revenueContainer.classList.add("d-none");
            
            // Set the button label text to the value from responseData
            if(responseData.identifiedFunction != 'null'){
              this.currentFunction = responseData.identifiedFunction;
              button.textContent = responseData.button;
              button.classList.remove("d-none");
            }
            else {
              button.classList.add("d-none");
              cancelBtn.textContent = 'Thanks, please close.'
            }
            buttonGroup.classList.remove("d-none");
            buttonGroup.classList.add("d-flex");
          }

          printContainer_text.prepend(responseData.analysis);

          printContainer.classList.remove("d-none");
          printContainer.classList.add("d-flex");

        } else {
          console.warn("Analysis not found in the response.");
        }
      }
    } catch (error) {
      console.error("Error during analysis:", error);
    } finally {
      // Remove the flashing class to stop the effect
      svgElement.classList.remove("flashing");
      svgElement.classList.add("d-none");
    }
  }

  // Function to handle successful login
  async onLoginSuccess(token, email, name) {
    console.log("Login successful, handling token...");

    // Store the logged-in user info locally
    localStorage.setItem('loggedInEmail', email || '');
    localStorage.setItem('loggedInName', name || '');

    // Notify parent window (Outlook Add-in) to manage the token
    const message = {
      action: 'saveToken',
      token: token,
      email: email,
      name: name
    };
    window.parent.postMessage(message, '*');

    window.parent.postMessage({ action: "requestCurrentItem" }, "*");
    this.loggedIn = true;
  }

  // Handle login form submission
  async handleLogin(passwordResetOn) {
    this.loggingIn = true;
    this.loginError = "";

    if (passwordResetOn) {
      const dataObj = {
        controller: "Authentication",
        FunctionName: "passwordReset",
        DomainName: "Outlook",
        MailId: this.panelEmail,
      };
      axios.post(this.defaultApiURL + "/?ReturnType=JSON", JSON.stringify(dataObj))
        .then(response => {
          this.loggingIn = false;
          this.passwordResetOn = false;
        })
        .catch(error => {
          notifier.alert("Password reset error: " + error.message);
        });
    }
    else {
      const dataObj = {
        controller: "authentication",
        FunctionName: "login",
        DomainName: "Outlook",
        UserEmail: this.panelEmail,
        UserPass: this.panelPassword
      };

      console.log("Sending login request with data:", dataObj);

      // Send login data to ColdFusion for authentication
      axios.post(this.defaultApiURL + "/?ReturnType=JSON", JSON.stringify(dataObj))
        .then(response => {
          console.log("Received login response:", response);

          // Check if login is successful
          if (response.data.STATUS && !response.data.ERROR) {
            console.log("Login successful.", response.data);

            if (response.data.API_Return && response.data.API_Return !== "") {
              // Use the name from Outlook if available, otherwise fallback to login details
              const fullName = (response.data.LOGINDETAILS && response.data.LOGINDETAILS[0] 
              ? `${response.data.LOGINDETAILS[0].UFNAME} ${response.data.LOGINDETAILS[0].ULNAME}` 
              : "");

              this.onLoginSuccess(
                response.data.API_Return, 
                this.panelEmail, 
                fullName
              ); // Handle successful login
            }
          } else {
            this.loggingIn = false;
            this.handleLoginError(response.data); // Handle login error
          }
        })
        .catch(error => {
          notifier.alert("Login error: " + error.message);
        });
    }
  }

  // Handle search input with a delay
  onSearchInput() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.performSearch();
    }, 1000); // 1-second delay
  }

  // Perform the account search
  async performSearch() {
    try {
      const response = await axios.post(this.defaultApiURL + "/?ReturnType=JSON", {
        controller: "Email",
        FunctionName: "index",
        subsystem: "External",
        session: 0,
        token: String(this.$route.query.token || ""), // Ensure token is a string value
        isOutlookAddin: true,
        search: this.searchQuery
      });

      this.searchResults = response.data.suggestedAccounts.length ? response.data.suggestedAccounts : [];
      this.dropdownVisible = true;
    } catch (err) {
      notifier.alert("Search error: " + err.message);
    }
  }

  // Show the search results dropdown
  showDropdown() {
    this.dropdownVisible = true;
  }

  // Hide the search results dropdown
  hideDropdown() {
    this.dropdownVisible = false;
  }

  // Handle the selection of an account from the search results
  async selectAccount(account,userEdit) {
    let orgInfo;

    if(userEdit == true) {
      orgInfo = {
        aID: this.originalaID,
        accountID: this.originalaccountID
      }
    }

    // Ensure token is a string value
    const routeToken = Array.isArray(this.$route.query.token) ? this.$route.query.token[0] : this.$route.query.token;
    const token = localStorage.getItem('sessionToken') || routeToken || "";

    this.hideDropdown();
    try {
      const response = await axios.post(this.defaultApiURL + "/?ReturnType=JSON", {
        controller: "Email",
        FunctionName: "AddContact",
        subsystem: "External",
        session: 0,
        token: String(token), // Ensure token is a string value
        aContactName: this.userInfo.ACONTACTNAME,
        aContactEmail: this.$route.query.email,
        isOutlookAddin: true,
        accountID: account.ACCOUNTID,
        aID: account.AID,
        userEdit: userEdit,
        originalAccountInfo: orgInfo
      });

      console.dir(response.data);
      
      // Refresh user info with the new account data
      await this.fetchUserInfo(
        localStorage.getItem('sessionToken') || (Array.isArray(this.$route.query.token) ? this.$route.query.token[0] : this.$route.query.token) || "",
        this.$route.query.email,
        this.$route.query.name
      );
      
    } catch (err) {
      notifier.alert("error: " + err.message);
    }
  }

  // Handle clicks outside of the dropdown to close it
  handleOutsideClick(event) {
    if (!this.hoveringDropdown) {
      this.hideDropdown();
    }
  }

  // Indicate that the mouse is over the dropdown
  onDropdownMouseEnter() {
    this.hoveringDropdown = true;
  }

  // Indicate that the mouse is no longer over the dropdown
  onDropdownMouseLeave() {
    this.hoveringDropdown = false;
  }

  // Utility method to get initials from a name
  getInitials(name) {
    if (!name) return '';
    const nameParts = name.split(' ');
    return nameParts.map(part => part[0].toUpperCase()).join('');
  }

  categories(){
    var monthNames;
    monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if(this.ApiMonths.length && isNaN(this.sortingYear)){
      monthNames = this.ApiMonths;
    }
    var fullMonths = [];
    for (const month in monthNames) {
      if (isNaN(this.sortingYear)){
        fullMonths.push(monthNames[month]);
      }
      else{
        fullMonths.push(monthNames[month]);
      }
    }
    return fullMonths;
  }

  disassociate() {
    this.originalANAME = this.userInfo.ANAME;
    this.originalaID = this.userInfo.AID;
    this.originalaccountID = this.userInfo.ACCOUNTID;
    this.userInfo.ANAME = '';
    this.userEdit = true;
  }

  cancelEdit() {
    this.userEdit = false;
    this.userInfo.ANAME = this.originalANAME;
  }

  toggleActive(activeTab) {
    this.isActive = activeTab;
  }

  async checkAutoAI(update) {
    const response = await ApiHelper.callApi("post", {
      controller        : "Email",
      FunctionName      : "userParams",
      subsystem         : "External",
      isOutlookAddin    : true,
      session           : 0,
      update            : update,
      autoAI            : this.analyzeToggle,
      token             : this.$route.query.token || "",
      loggedInEmail     : localStorage.getItem('loggedInEmail') || this.$route.query.email,
      loggedInName      : localStorage.getItem('loggedInName') || this.$route.query.name
    });

    console.dir(response);

    this.analyzeToggle = response.PARAMS && response.PARAMS.AUTO_AI ? response.PARAMS.AUTO_AI : false;
  }

  handleLoginError(error) {
    console.log("Login error:", error);
    this.loginError = error.message || 'Login failed. Please try again.';
  }
}
