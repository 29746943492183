
















import { VNode } from "vue";
import { Component as TSXComponent } from "vue-tsx-support";
import { Component, Prop, Emit, Model } from "vue-property-decorator";
import axios from "axios";
import directives from "../helpers/directives";

import { InvoiceLineItem } from "../models/invoice";
import AutoSuggestInput, {
  AutosuggestSearch,
  Option
} from "./AutoSuggestInput.vue";
import { AccountList } from "../models/AccountList";

interface Props {
  defaultText?: string
  value: string
}

interface Events {
  onInput: string
  onSelection: {
    option: Option,
    stores: AccountList.StoreList,
  }
}

declare const dataURL: string;

@Component({
  inheritAttrs: true,
  components: {
    AutoSuggestInput
  },
  inject: ['$validator'],
  directives
})
export default class CustomerAutoSuggestInput extends TSXComponent<Props, Events> {

  @Prop({ required: false, type: String })
  defaultText?: string

  @Prop({ required: false, default : false })
  show?: boolean

  @Prop({ required: false, default: "Customer"})
  placeholderText?: string;

  @Prop({ required: false, default: "bottom" })
  tooltipPosition!: string;

  @Prop({ required: false, default: false})
  titles?: string;

  @Model('input', {
    type: String,
    required: true,
  })
  readonly value!: string

  options: Option[] = []
  searchData: AccountList.StoreList[] = []
  URL:string = this.$parent.$route.path;

  createOptions(storesList: AccountList.StoreList[]) {
    const options = storesList.map(
      (acc): Option => {
        return {
          value: acc.PORTALACCOUNTID.toString(),
          text: acc.ACCOUNTNAME
        };
      }
    )

    this.options = options
    this.searchData = storesList

    return {
      options,
    };
  }

  searchFn: AutosuggestSearch = async (
    search: string
  ): Promise<{ options: Option[] }> => {
    if (!search) {
      const response = await axios.post(dataURL + "?ReturnType=JSON", {
        "FunctionName": "List",
        "controller": "CustomerPortals",
        "type": "2",
        "queryType": "top5StoreAcct"
      });
      if (response.data.ERROR || !response.data.PORTALSARRAY) {
        return {
          options: []
        };
      }
      

      return this.createOptions(response.data.PORTALSARRAY)
    }

    const exportObj = {
      FunctionName: "List",
      controller: "CustomerPortals",
      getAll: 1,
      companyName: search,
      type: "2",
      queryType: "top5StoreAcct"
    };

    const response = await axios.post(dataURL + "?ReturnType=JSON", exportObj);
    if (response.data.ERROR || !response.data.PORTALSARRAY) {
      return {
        options: []
      };
    }
    
    return this.createOptions(response.data.PORTALSARRAY)
  };

  onInput(value: string) {
    this.$emit('input', value)

    if (!value) return

    const option = this.options.find((o) => o.value === value)
    if (!option) {
      return
    }
    
    const stores = this.searchData.find((item) => item.PORTALACCOUNTID.toString() === value)
    
    if (!stores) {
      return
    }
    this.$emit('selection', {
      option,
      stores,
    })
  }
}
